import styled from "styled-components";

export const Container = styled.div(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
  gap: theme.spacings.m,
  overflow: "hidden",
  borderRadius: theme.borders.radius.s,
  paddingLeft: theme.spacings.m,
  paddingRight: theme.spacings.m,
}));

export const Card = styled.div`
  height: auto;
  max-height: fit-content;
  width: 100%;
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.colors.neutral[90]};
  overflow: hidden scroll;
`;

export const Form = styled.div`
  width: 100%;
  padding: 20px 16px 20px 16px;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacings.xs2};
`;

export const FormCurrencyAmountContainer = styled.div(({ theme }) => ({
  display: "flex",
  float: "left",
  gap: theme.spacings.xs2,
  width: "100%",
}));

// TODO remover? não usado no ReimbursementForm.tsx
export const AmountContainer = styled.div`
  width: 100%;

  .helperText,
  p.warning {
    position: absolute;
    margin-top: 3px;
  }

  .MuiFilledInput-root {
    border-width: ${({ theme }) => theme.borders.width.xs2}!important;
  }

  .select-custom .select-field-custom-theme div.Mui-disabled {
    background-color: transparent !important;
  }
`;

export const CardTile = styled.div<{ bottomBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacings.xs3};
  border-bottom-width: ${({ bottomBorder }) => (bottomBorder ? "1px" : undefined)};
  border-bottom-style: solid;
  border-bottom-color: ${(props) => props.theme.colors.neutral[90]};
  padding-top: 20px;
  padding-bottom: ${(props) => props.theme.spacings.xs2};
  padding-left: 20px;
  padding-right: 20px;
`;
