import { useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  DataGrid,
  Skeleton,
  EmptyState,
  dayjs,
  Icons,
} from "@flash-tecnologia/hros-web-ui-v2";

import { ProfileTable } from "@components/ProfileTable";
import { TableTag } from "@components/TableTag";
import { FeedbackDrawer } from "@components/Drawers";

import { trpc } from "@api/client";

import { pageSizeOptions } from "@utils";

import { routes } from "@routes";

import {
  CellArea,
  DataGridContainer,
  StyledButton,
  StyledText,
  TagArea,
} from "./styled";

import type { TableGridCommonProps } from "@components/Table/types";

export const TableGrid = ({ data, rawData, loading }: TableGridCommonProps) => {
  const utils = trpc.useContext();

  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = useState({
    open: false,
    feedbackData: "",
    answering: false,
  });

  const columns = useMemo(
    () => [
      {
        Header: "Enviado para",
        disableSortBy: true,
        accessor: "to.name",
        Cell: ({ row: { original } }) => {
          const { to } = original;
          return (
            <CellArea>
              <ProfileTable name={to?.name} />
            </CellArea>
          );
        },
      },
      {
        Header: "Status da resposta",
        disableSortBy: true,
        accessor: "status",
        Cell: ({ row: { original } }) => {
          const { status } = original;

          const labels = {
            answered: { label: "Respondido", color: "green" },
            unanswered: { label: "Não respondido", color: "yellow" },
            draft: { label: "Rascunho", color: "info" },
          };

          return (
            <TagArea>
              <TableTag
                label={labels[status]?.label}
                variant={labels[status]?.color}
                hasDot={true}
              />
            </TagArea>
          );
        },
      },
      {
        Header: "Criado em",
        disableSortBy: true,
        accessor: "createdAt",
        Cell: ({ row: { original } }) => {
          const { createdAt } = original;
          return (
            <StyledText variant="body3">
              {dayjs(createdAt).format("DD/MM/YY")}
            </StyledText>
          );
        },
      },
      {
        Header: "Ação",
        disableSortBy: true,
        accessor: "action",
        sticky: "right",
        Cell: ({ row: { original } }) => {
          const { status } = original;

          const labels = {
            answered: {
              label: "Ver feedback",
              variant: "secondary",
              onClick: () => {
                setOpenDrawer({
                  open: true,
                  feedbackData: original,
                  answering: false,
                });
              },
            },
            unanswered: {
              label: "Responder",
              variant: "primary",
              onClick: () => {
                setOpenDrawer({
                  open: true,
                  feedbackData: original,
                  answering: true,
                });
              },
            },
            draft: {
              label: "Ver feedback",
              variant: "secondary",
              onClick: () => {
                setOpenDrawer({
                  open: true,
                  feedbackData: original,
                  answering: false,
                });
              },
            },
          };

          return (
            <StyledButton
              variant={labels[status]?.variant}
              size={"small"}
              onClick={labels[status]?.onClick}
              children={
                <>
                  {labels[status]?.label}
                  <Icons name="IconArrowRight" fill="transparent" size={19} />
                </>
              }
            />
          );
        },
      },
    ],
    [data]
  );

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!rawData.length)
    return (
      <EmptyState
        description={
          "Você ainda não enviou feedbacks sobre o ciclo atual aos seus liderados!"
        }
        buttonText={"Enviar feedback"}
        buttonProps={{
          size: "large",
          onClick: () => navigate(routes.PageSendFeedback),
        }}
      />
    );

  return (
    <DataGridContainer>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={data || []}
        initialState={{ pageSize: Number(10) }}
        emptyState={{
          isFiltered: false,
          message: "Nenhum feedback foi encontrado!",
        }}
        pageSizeOptions={pageSizeOptions}
      />
      <FeedbackDrawer
        isOpen={openDrawer.open}
        onClose={() => {
          setOpenDrawer({ open: false, feedbackData: "", answering: false });
          utils.performance.feedback.getEmployeeSentFeedbacksByCycleId.invalidate();
        }}
        feedbackData={openDrawer.feedbackData}
        permission={openDrawer.answering ? "answer" : "view"}
      />
    </DataGridContainer>
  );
};
