import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { StyledTitleTabBadge, StyledTitleTabWrapper } from './styles';

type TabTitleProps = {
  text: string;
  value?: number;
};

export const TabTitle = ({ text, value }: TabTitleProps) => {
  return (
    <StyledTitleTabWrapper>
      {text}
      {value && value > 0 ? (
        <StyledTitleTabBadge>
          <Typography variant="overline" weight={700}>
            {value}
          </Typography>
        </StyledTitleTabBadge>
      ) : null}
    </StyledTitleTabWrapper>
  );
};
