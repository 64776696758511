import { trpc } from "@api/client"
import {
  Button,
  Icons,
  Skeleton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import i18n from "@i18n"
import { Grid } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { api } from "../../../../../../api"
import {
  Banner,
  ModalConfirmation,
  SettingPageTemplate,
} from "../../../../../../components"
import { FlowSubcategory, IProposedLetter } from "../../../../../../types"
import { removeNullValues } from "../../../../../../utils"
import dispatchToast from "../../../../../../utils/dispatchToast"
import {
  FormBasicRef,
  FormProposedInfo,
} from "../../../components/FormProposedInfo"
import {
  MainContainer,
  StyledDescription,
  StyledSubtitle,
  Wrapper,
} from "../styles"
import { useTracking } from "@utils/useTracking"

interface ProposedInfoContentProps {
  flowId: string
  modelId?: string
  mode: "edit" | "create"
}

export const ProposedInfoContent = ({
  flowId,
  modelId,
  mode,
}: ProposedInfoContentProps) => {
  const [modalControl, setModalControl] = useState<{
    isOpen: boolean
    modalName?: string
  }>({
    isOpen: false,
  })

  const [isModalFooterLeaveLoading, setIsModalFooterLeaveLoading] =
    useState(false)

  const navigate = useNavigate()
  const tracking = useTracking()
  const formRef = useRef<FormBasicRef>(null)

  const [t] = useTranslation("translations", {
    keyPrefix: "page.settings.proposedLetterModel",
  })

  const { data: flow, isInitialLoading: isFlowLoading } =
    trpc.flow.getOnlyFlowById.useQuery(
      { flowId },
      {
        enabled: !!flowId,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        onError: (err) => {
          console.log(err)
          dispatchToast({
            content: "Houve um erro ao buscar dados do fluxo",
            type: "error",
          })
          navigate(`/flows/settings/${flowId}/proposedLetter`)
        },
      },
    )

  const {
    data: modelProposedLetter,
    isInitialLoading: isModelProposedLetterLoading,
  } = trpc.modelProposedLetter.getModelProposedLetterById.useQuery(
    { modelId: modelId || "" },
    {
      enabled: !!modelId,
      onError: (err) => {
        console.log(err)
        dispatchToast({
          content: "Houve um erro ao buscar o modelo de carta proposta",
          type: "error",
        })
        navigate(`/flows/settings/${flowId}/proposedLetter`)
      },
    },
  )

  const { mutateAsync: updateModelProposedLetter } = useMutation(
    api.mutation.hiring.modelProposedLetter.update,
  )

  const { refetch: getFlowById } = trpc.flow.getFlowById.useQuery(
    { flowId },
    {
      enabled: false,
      queryKey: ["flow.getFlowById", { flowId }],
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
    },
  )

  const handleSubmit = async (proposedLetter: IProposedLetter) => {
    try {
      if (!modelId) {
        throw new Error("Model ID não encontrado")
      }
      await updateModelProposedLetter({
        modelId,
        status: "published",
        proposedLetter,
      })
      dispatchToast({
        content: "Modelo de carta proposta atualizado com sucesso!",
        type: "success",
      })
      navigate(`/flows/settings/${flowId}/proposedLetter`)
    } catch (err: any) {
      console.error(err)
      dispatchToast({
        content: "Houve um erro ao atualizar o modelo de carta proposta",
        type: "error",
      })
    }
  }

  const isLoading = isFlowLoading || isModelProposedLetterLoading

  return (
    <SettingPageTemplate
      stepper={{
        steps: ["Informações básicas", "Carta proposta"],
        activeStep: 1,
      }}
      routes={[
        {
          label: "Configurações",
          route: `/flows/settings/${flowId}/proposedLetter`,
        },
        {
          label:
            mode === "create" ? t("createModelTitle") : t("editModelTitle"),
        },
      ]}
      footer={{
        cancelProps: {
          title: t("footerLeave"),
          callback: () => {
            setModalControl({
              isOpen: true,
              modalName: "footerLeave",
            })
          },
        },
        draftProps: {
          title:
            mode === "edit"
              ? t("footerLeaveAndSave")
              : t("footerLeaveAndSaveDraft"),
          disabled: false,
          callback: () => {
            setModalControl({
              isOpen: true,
              modalName: "footerLeaveAndSave",
            })
          },
        },
        goBackProps: {
          title: (
            <>
              <Icons name="IconArrowLeft" fill="transparent" />{" "}
              {t("footerGoBack")}
            </>
          ),
          callback: () => {
            navigate(
              `/flows/settings/${flowId}/proposedLetter/edit/basic-info?modelId=${modelId}`,
            )
          },
          loading: false,
          disabled: false,
        },
        confirmProps: {
          title: (
            <>
              {t("footerSubmit")}
              <Icons name="IconArrowRight" fill="transparent" />
            </>
          ),
          loading: false,
          disabled: false,
          callback: async () => {
            await formRef.current?.handleSubmit()
          },
        },
      }}
    >
      <MainContainer>
        <Wrapper>
          <Typography variant="headline6">
            {mode === "create" ? t("createModelTitle") : t("editModelTitle")}
          </Typography>
        </Wrapper>

        <Grid container spacing={2}>
          <Grid item sm={12} md={5} lg={4}>
            <StyledSubtitle variant="headline8">
              {t("proposedInfoTitle")}
            </StyledSubtitle>
            <StyledDescription variant="body3">
              {t("proposedInfoDescription")}
            </StyledDescription>

            <Button
              variant="primary"
              size="large"
              disabled={mode === "create"}
              onClick={() =>
                window.open(
                  `/flows/settings/${flowId}/proposedLetter/preview/${modelId}`,
                  "_blank",
                )
              }
              style={{ width: "100%" }}
            >
              <>
                {t("proposedInfoPreviewLabel")}
                <Icons name="IconFileSearch" fill="transparent" />
              </>
            </Button>
          </Grid>
          <Grid item sm={12} md={7} lg={8}>
            <Banner
              icon="IconInfoCircle"
              title={t("proposedInfoForm.bannerTitle")}
              message={t("proposedInfoForm.bannerDescription")}
              type="info"
              hasHideBanner={false}
              style={{ marginBottom: 40 }}
            />

            {!isLoading && modelId ? (
              <FormProposedInfo
                ref={formRef}
                modelId={modelId}
                companyId={flow?.companyId as string}
                flowType={flow?.subcategory as FlowSubcategory}
                data={modelProposedLetter?.proposedLetter}
                onSubmit={handleSubmit}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 40,
                }}
              >
                <Skeleton variant="rectangular" height={300} />
                <Skeleton variant="rectangular" height={300} />
                <Skeleton variant="rectangular" height={300} />
              </div>
            )}
          </Grid>
        </Grid>
      </MainContainer>

      <ModalConfirmation
        isOpen={modalControl.isOpen && modalControl.modalName === "footerLeave"}
        title={t("modalLeave.title")}
        description={t("modalLeave.description")}
        confirmLabel={t("modalLeave.confirmButton")}
        cancelLabel={t("modalLeave.cancelButton")}
        onClose={() =>
          setModalControl({
            isOpen: false,
            modalName: undefined,
          })
        }
        onConfirm={async () => {
          setModalControl({
            isOpen: false,
            modalName: undefined,
          })
          navigate(`/flows/settings/${flowId}/proposedLetter`)
        }}
        confirmIcon="IconArrowRight"
        variant="error"
      />

      <ModalConfirmation
        isLoading={isModalFooterLeaveLoading}
        isOpen={
          modalControl.isOpen && modalControl.modalName === "footerLeaveAndSave"
        }
        title={t("modalLeaveAndSave.title")}
        description={
          mode === "create"
            ? t("modalLeaveAndSave.descriptionDraft")
            : t("modalLeaveAndSave.description")
        }
        confirmLabel={
          mode === "create"
            ? t("modalLeaveAndSave.confirmButtonDraft")
            : t("modalLeaveAndSave.confirmButton")
        }
        cancelLabel={t("modalLeaveAndSave.cancelButton")}
        onClose={() =>
          setModalControl({
            isOpen: false,
            modalName: undefined,
          })
        }
        onConfirm={async () => {
          setIsModalFooterLeaveLoading(true)
          const flow = await getFlowById()
          tracking.trackEvent({
            name: `company_hiring_${
              flow.data?.subcategory || "clt"
            }_proposallettertemplate_complete_clicked`,
          })

          try {
            if (!modelId) {
              throw new Error("Model ID não encontrado")
            }
            const proposedLetterValues = removeNullValues(
              formRef.current?.getValues(),
            )

            const {
              hasntVariableSalary,
              variableSalary,
              salary,
              aboutCompany,
              benefits,
              ...rest
            } = proposedLetterValues

            const cleanedSalary = salary
              .replace(/[R$]|[.]/g, "")
              .replace(",", ".")
            const parsedSalary =
              cleanedSalary == "R$0,00" ? null : +cleanedSalary

            const proposedLetter: IProposedLetter = {
              ...rest,
              aboutCompany: aboutCompany || undefined,
              benefits: benefits || undefined,
              salary: parsedSalary,
              variableSalary: hasntVariableSalary ? undefined : variableSalary,
            }

            const status = modelProposedLetter
              ? modelProposedLetter.status
              : "draft"

            await updateModelProposedLetter({
              modelId,
              status,
              proposedLetter,
            })

            setIsModalFooterLeaveLoading(false)

            navigate(`/flows/settings/${flowId}/proposedLetter`)
          } catch (err: any) {
            console.error(err)
            dispatchToast({
              content: "Houve um erro ao atualizar o modelo de carta proposta",
              type: "error",
            })
            setModalControl({
              isOpen: false,
              modalName: undefined,
            })
            setIsModalFooterLeaveLoading(false)
          }
        }}
        confirmIcon="IconArrowRight"
        variant="default"
      />
    </SettingPageTemplate>
  )
}
