import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate } from "react-router-dom";

import { MAX_SLIDER_FIELD_VALUE, MIN_SLIDER_FIELD_VALUE } from "$frontend/components/molecules/SliderField/SliderField";
import { useStatementContext } from "$frontend/pages/Statement/context/StatementContext";
import { Routes } from "$frontend/routes";
import { DateRangeField, PageContainer, PageHeader } from "$molecules";
import {
  ConfirmationModalReclassifyTransaction,
  ExpenseFilterDrawer,
  ExpenseFiltersFields,
  MovementTable,
  MovementTableHandles,
  PendingExpenseGrid,
  PendingExpenseGridHandles,
} from "$organisms";
import { Expense, ExpenseType, Movement } from "$serverTypes";
import { GetStatementRequest } from "server/src/routers/corporate-card/statement";

export const StatementPage = () => {
  const { t } = useTranslation("translations", { keyPrefix: "pages.statement" });

  const { methods } = useStatementContext();
  const { control } = methods;
  const contextState = methods.watch();

  const movementTableRef = useRef<MovementTableHandles>(null);
  const pendingExpenseGridRef = useRef<PendingExpenseGridHandles>(null);
  const navigate = useNavigate();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedFiltersCount, setSelectedFiltersCount] = useState(0);
  const [isReclassifyModalOpen, setIsReclassifyModalOpen] = useState(false);
  const [selectedMovement, setSelectedMovement] = useState<Movement | null>(null);
  const [selectedExpense, setSelectedExpense] = useState<Expense | null>(null);

  const handleFilterChange = (selectedFilters: ExpenseFiltersFields) => {
    methods.setValue("filters.status", selectedFilters?.expenseStatus || []);
    methods.setValue("filters.transactionStatus", selectedFilters?.transactionStatus || []);
    methods.setValue("filters.categoryNanoIds", selectedFilters?.categoryNanoIds || []);
    methods.setValue("filters.valueRange", getValueRangeWithDefaults(selectedFilters?.valueRange));
    methods.setValue("pagination.pageNumber", 1);
  };

  function refetchAll() {
    pendingExpenseGridRef.current?.refetch();
    movementTableRef.current?.refetch();
  }

  const company = useSelectedCompany();
  useEffect(() => {
    refetchAll();
  }, [company.selectedCompany?.id]);

  function navigateToPendingItemExpense(expense: Expense) {
    navigate(`${Routes.STATEMENT_PENDING_ITEMS}?expenseId=${expense.id}`);
  }

  function navigateToPendingItemMovement(movement: Movement) {
    navigate({
      pathname: Routes.STATEMENT_PENDING_ITEMS,
      search: createSearchParams({
        expenseId: movement.expense?.id ?? "",
      }).toString(),
    });
  }

  // In ExpenseManagement's /transaction/search request, you either don't pass valueRange or you pass both values, min and max (can't pass one undefined and one number).
  function getValueRangeWithDefaults(
    valueRange: ExpenseFiltersFields["valueRange"],
  ): GetStatementRequest["valueRange"] {
    if (valueRange?.min === undefined && valueRange?.max === undefined) {
      return undefined;
    }
    return {
      min: valueRange.min ?? MIN_SLIDER_FIELD_VALUE,
      max: valueRange.max ?? MAX_SLIDER_FIELD_VALUE,
    };
  }

  return (
    <>
      <PageHeader title={t("title")}>
        <Controller
          name="filters.dateRange"
          control={control}
          render={({ field }) => (
            <DateRangeField
              {...field}
              onChange={(value) => {
                field.onChange(value);
                methods.setValue("pagination.pageNumber", 1);
              }}
            />
          )}
        />
      </PageHeader>
      <PageContainer>
        <PendingExpenseGrid
          ref={pendingExpenseGridRef}
          onPendingIssuesClicked={() => navigate(Routes.STATEMENT_PENDING_ITEMS)}
          onAddReceipt={navigateToPendingItemExpense}
          onAddCategory={navigateToPendingItemExpense}
          onSendToAccount={navigateToPendingItemExpense}
          onReclassify={(expense) => {
            setSelectedExpense(expense);
            setSelectedMovement(null);
            setIsReclassifyModalOpen(true);
          }}
        />

        <MovementTable
          ref={movementTableRef}
          dateRange={contextState.filters.dateRange}
          selectedFiltersCount={selectedFiltersCount}
          onFilterClick={() => setDrawerOpen(!drawerOpen)}
          onAddReceipt={navigateToPendingItemExpense}
          onSendToAccount={navigateToPendingItemMovement}
          onReclassify={(movement) => {
            setSelectedMovement(movement);
            setSelectedExpense(null);
            setIsReclassifyModalOpen(true);
          }}
          onVisualize={(movement) => navigate(Routes.MOVEMENT.replace(":expenseId", movement.expense?.id ?? ""))}
        />
      </PageContainer>
      <ExpenseFilterDrawer
        expenseType={ExpenseType.CORPORATE_CARD}
        isOpen={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onFilterCountChange={(count) => setSelectedFiltersCount(count)}
        onSubmit={(selectedFilters: ExpenseFiltersFields) => {
          handleFilterChange(selectedFilters);
          setDrawerOpen(false);
        }}
        filters={{
          expenseStatus: contextState.filters.status,
          categoryNanoIds: contextState.filters.categoryNanoIds,
          valueRange: contextState.filters.valueRange,
          transactionStatus: contextState.filters.transactionStatus,
        }}
        config={{
          expenseStatus: true,
          categoryNanoIds: true,
          valueRange: true,
          transactionStatus: true,
        }}
      />
      <ConfirmationModalReclassifyTransaction
        isOpen={isReclassifyModalOpen}
        transaction={selectedMovement?.transaction || selectedExpense?.transaction}
        onActionClick={refetchAll}
        onCloseClick={() => setIsReclassifyModalOpen(false)}
      />
    </>
  );
};
