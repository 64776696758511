import { ResignationStatus } from "@customTypes/resignation"
import { TagProps } from "@flash-tecnologia/hros-web-ui-v2"
import i18n from "@i18n"

const mapStatusToTags: Partial<Record<ResignationStatus, TagProps>> = {
  [ResignationStatus.EVALUATION_COMPLETED]: {
    variant: "success",
    children: i18n.t(`components.resignationStatusTag.${ResignationStatus.EVALUATION_COMPLETED}`),
  } as TagProps,
  [ResignationStatus.EVALUATION_IN_PROGRESS]: {
    variant: "info",
    children: i18n.t(`components.resignationStatusTag.${ResignationStatus.EVALUATION_IN_PROGRESS}`),
  } as TagProps,
  [ResignationStatus.EVALUATION_PENDING_INTERVIEW]: {
    variant: "error",
    children: i18n.t(`components.resignationStatusTag.${ResignationStatus.EVALUATION_PENDING_INTERVIEW}`),
  } as TagProps,
  [ResignationStatus.INSTRUCTIONS_COMPLETED]: {
    variant: "success",
    children: i18n.t(`components.resignationStatusTag.${ResignationStatus.INSTRUCTIONS_COMPLETED}`),
  } as TagProps,
  [ResignationStatus.INSTRUCTIONS_IN_PROGRESS]: {
    variant: "info",
    children: i18n.t(`components.resignationStatusTag.${ResignationStatus.INSTRUCTIONS_IN_PROGRESS}`),
  } as TagProps,
  [ResignationStatus.INSTRUCTIONS_PENDING_EMAIL]: {
    variant: "error",
    children: i18n.t(`components.resignationStatusTag.${ResignationStatus.INSTRUCTIONS_PENDING_EMAIL}`),
  } as TagProps,
  [ResignationStatus.EXIT_EXAM_AWAITING_PERSON_RESPONSE]: {
    variant: "info",
    children: i18n.t(`components.resignationStatusTag.${ResignationStatus.EXIT_EXAM_AWAITING_PERSON_RESPONSE}`),
  } as TagProps,
  [ResignationStatus.EXIT_EXAM_COMPLETED]: {
    variant: "success",
    children: i18n.t(`components.resignationStatusTag.${ResignationStatus.EXIT_EXAM_COMPLETED}`),
  } as TagProps,
  [ResignationStatus.EXIT_EXAM_PENDING_REQUEST]: {
    variant: "error",
    children: i18n.t(`components.resignationStatusTag.${ResignationStatus.EXIT_EXAM_PENDING_REQUEST}`),
  } as TagProps,
  [ResignationStatus.EXIT_EXAM_PENDING_VALIDATION]: {
    variant: "error",
    children: i18n.t(`components.resignationStatusTag.${ResignationStatus.EXIT_EXAM_PENDING_VALIDATION}`),
  } as TagProps,
  [ResignationStatus.INITIAL_INFO_COMPLETED]: {
    variant: "success",
    children: i18n.t(`components.resignationStatusTag.${ResignationStatus.INITIAL_INFO_COMPLETED}`),
  } as TagProps,
  [ResignationStatus.INITIAL_INFO_IN_APPROVAL]: {
    variant: "info",
    children: i18n.t(`components.resignationStatusTag.${ResignationStatus.INITIAL_INFO_IN_APPROVAL}`),
  } as TagProps,
  [ResignationStatus.INITIAL_INFO_PENDING_COMMUNICATION]: {
    variant: "error",
    children: i18n.t(`components.resignationStatusTag.${ResignationStatus.INITIAL_INFO_PENDING_COMMUNICATION}`),
  } as TagProps,
  [ResignationStatus.INITIAL_INFO_REJECTED]: {
    variant: "error",
    children: i18n.t(`components.resignationStatusTag.${ResignationStatus.INITIAL_INFO_REJECTED}`),
  } as TagProps,
  [ResignationStatus.SIGNATURE_PENDING_CONTRACT]: {
    variant: "error",
    children: i18n.t(`components.resignationStatusTag.${ResignationStatus.SIGNATURE_PENDING_CONTRACT}`),
  } as TagProps,
  [ResignationStatus.SIGNATURE_SIGNED]: {
    variant: "success",
    children: i18n.t(`components.resignationStatusTag.${ResignationStatus.SIGNATURE_SIGNED}`),
  } as TagProps,
}

type Params =
  | {
      status: Exclude<ResignationStatus, ResignationStatus.SIGNATURE_PENDING_SIGNATURE>
    }
  | {
      status: ResignationStatus.SIGNATURE_PENDING_SIGNATURE
      pendingSignaturesCount: number
    }

export const mapResignationStatusToTagProps = (params: Params): TagProps | undefined => {
  if (params.status === ResignationStatus.SIGNATURE_PENDING_SIGNATURE) {
    return {
      variant: "error",
      children: i18n.t(`components.resignationStatusTag.${ResignationStatus.SIGNATURE_PENDING_SIGNATURE}`, {
        pendingSignaturesCount: params.pendingSignaturesCount,
      }),
    } as TagProps
  }

  return mapStatusToTags[params.status]
}
