import React, { useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  PageHeader,
  PageContainer,
  Icons,
  Button,
  useMediaQuery,
  Typography,
  IconButton,
  Menu,
  MenuOption,
} from "@flash-tecnologia/hros-web-ui-v2"
import {
  FlexColumn,
  PageHeaderOptionsContainer,
  PageHeaderSubcontainer,
  MenuText,
} from "./styles"
import { globalContext } from "../../../context"
import { useNavigate } from "react-router"
import {
  RoundedSelect,
  CreateCandidateModal,
  ModalExportData,
  List,
  Kanban,
  SwitchButton,
} from "../../../components"
import useLocalStorage from "../../../hooks/useLocalStorage"
import dispatchToast from "../../../utils/dispatchToast"
import { ContentSkeleton, HeaderSkeleton } from "./components/Skeleton"
import { useSearchParams } from "react-router-dom"
import { trpc } from "@api/client"
import { ExportPayrollIntegrationReportModal } from "@components/screen/Home/ExportPayrollIntegrationReportModal"
import { checkUnleashToggles, useUnleashToggles } from "src/hooks"
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility"
import { PendoEnum } from "@utils/pendo"
import { useTracking } from "@utils/useTracking"

type FlowView = "list" | "kanban"

const HiringPage = () => {
  const { selectedCompany } = useSelectedCompany()
  const [selectedHiringFlow, setSelectedHiringFlow] = useLocalStorage<string>(
    "hiring-flow",
    "",
  )
  const [selectedFlowView, setSelectedFlowView] = useLocalStorage<FlowView>(
    "flow-view",
    "kanban",
  )
  const [createCandidateModal, setCreateCandidateModal] =
    useState<boolean>(false)
  const [exportDataModal, setExportDataModal] = useState<boolean>(false)
  const [
    isExportPayrollIntegrationReportModalVisible,
    setIsExportPayrollIntegrationReportModalVisible,
  ] = useState(false)

  const { companyId } = useContext(globalContext)
  const navigate = useNavigate()
  const isMobile = useMediaQuery("(max-width: 768px)")
  const [searchParams] = useSearchParams()

  const tracking = useTracking()

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const unleashToggles = useUnleashToggles({ companyId: selectedCompany.id })

  const isPayrollIntegrationReportUnleashToggleEnabled = useMemo(() => {
    return checkUnleashToggles(
      unleashToggles,
      "HROS_PAYROLL_INTEGRATION_REPORT",
    )
  }, [unleashToggles])

  useEffect(() => {
    const view = searchParams.get("view") as FlowView
    if (view) {
      setSelectedFlowView(view)
    }
  }, [searchParams])

  useEffect(() => {
    tracking.trackPage({ name: "hiring_home_kanban" })
    tracking.trackEvent({
      name:
        selectedFlowView === "kanban"
          ? "hiring_home_view_option_kanban"
          : "hiring_home_view_option_list",
    })
  }, [])

  const { data: flowList, isInitialLoading: isFlowListLoading } =
    trpc.flow.getFlowByCompanyId.useQuery(
      { companyId: companyId || "", category: "hiring" },
      {
        enabled: !!companyId,
        queryKey: [
          "flow.getFlowByCompanyId",
          { category: "hiring", companyId: companyId || "" },
        ],
        onSuccess: (data) => {
          if (!selectedHiringFlow) {
            setSelectedHiringFlow(data[0]._id)
            return
          }

          const hasHiringFlowOnList =
            data.filter((flow) => flow?._id === selectedHiringFlow).length > 0
          if (!hasHiringFlowOnList) {
            setSelectedHiringFlow(data[0]._id)
          }
        },
        refetchOnWindowFocus: false,
      },
    )

  const { data: bigNumbers } = trpc.flow.getFlowBigNumbers.useQuery(
    { flowId: selectedHiringFlow },
    {
      enabled: !!selectedHiringFlow,
      refetchOnWindowFocus: false,
    },
  )

  const {
    data: getFlowByIdData,
    refetch: refetchFlow,
    isInitialLoading: isFlowLoading,
  } = trpc.flow.getFlowById.useQuery(
    { flowId: selectedHiringFlow },
    {
      queryKey: ["flow.getFlowById", { flowId: selectedHiringFlow }],
      enabled: !!selectedHiringFlow,
      refetchOnWindowFocus: false,
    },
  )

  const isPayrollIntegrationReportEnabled =
    ["clt", "internship"].includes(getFlowByIdData?.subcategory || "") &&
    isPayrollIntegrationReportUnleashToggleEnabled

  const completedColumnId = useMemo(() => {
    if (getFlowByIdData?.subcategory === "clt") return "ajk_XIs6V1VlE_BqHtXuv"
    if (getFlowByIdData?.subcategory === "internship")
      return "X6K51-NNZZ8DXjjbCboWP"
    return undefined
  }, [getFlowByIdData])

  const flow = getFlowByIdData as unknown as any

  const menuOptions = [
    {
      onClick: async () => {
        tracking.trackEvent({
          name: "hiring_home_clicked_new_hiring_single_candidate_button",
        })
        setCreateCandidateModal(true)
      },
      children: (
        <div style={{ display: "flex" }}>
          <Icons
            name="IconUser"
            style={{ marginRight: "8px", fill: "transparent" }}
          />
          <MenuText variant="body3">Adicionar um candidato</MenuText>
        </div>
      ),
    },
    {
      onClick: async () => {
        tracking.trackEvent({
          name: "hiring_home_clicked_new_hiring_mass_candidate_button",
        })
        navigate(`/flows/hiring/addMassCandidates/${flow?._id}`)
      },
      children: (
        <div style={{ display: "flex" }}>
          <Icons
            name="IconUsers"
            style={{ marginRight: "8px", fill: "transparent" }}
          />
          <MenuText variant="body3">Adicionar vários candidatos</MenuText>
        </div>
      ),
    },
  ]

  const refreshBoard = () =>
    refetchFlow().finally(() =>
      dispatchToast({
        type: "success",
        content: t("board.refreshBoardSuccess"),
      }),
    )

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!flowList || isFlowListLoading ? (
        <HeaderSkeleton />
      ) : (
        <PageHeader>
          <FlexColumn style={{ gap: 24 }}>
            <PageHeaderSubcontainer>
              <Typography variant="headline6">{t("tab.titleLabel")}</Typography>
              <Menu
                anchorOrigin={{
                  horizontal: "left",
                  vertical: "bottom",
                }}
                transformOrigin={{
                  horizontal: "left",
                  vertical: "top",
                }}
                options={menuOptions}
                type="default"
              >
                <Button
                  size="medium"
                  variant="primary"
                  style={{
                    marginLeft: isMobile ? "0px" : "40px",
                    alignSelf: isMobile ? "center" : "flex-start",
                  }}
                >
                  <Typography variant="body3" style={{ fontWeight: 700 }}>
                    {t("tab.buttonLabel")}
                  </Typography>
                  <Icons name="IconPlus" style={{ fill: "#fff" }} />
                </Button>
              </Menu>
            </PageHeaderSubcontainer>

            <PageHeaderOptionsContainer>
              <RoundedSelect
                onSelectChange={(value) => setSelectedHiringFlow(value)}
                value={selectedHiringFlow}
                options={flowList?.map((flow) => ({
                  label: flow?.name,
                  value: flow?._id,
                }))}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 12,
                }}
              >
                <SwitchButton
                  selectedValue={selectedFlowView}
                  option1={{
                    icon: "IconLayoutKanban",
                    handleClick: () => {
                      tracking.trackEvent({
                        name: "hiring_home_clicked_kanban_view",
                      })
                      setSelectedFlowView("kanban")
                    },
                    value: "kanban",
                    ariaLabel: t("home.options.ariaLabelKanban"),
                  }}
                  option2={{
                    icon: "IconMenu2",
                    handleClick: () => {
                      tracking.trackEvent({
                        name: "hiring_home_clicked_list_view",
                      })
                      setSelectedFlowView("list")
                    },
                    value: "list",
                    ariaLabel: t("home.options.ariaLabelList"),
                  }}
                ></SwitchButton>
                <Menu
                  type="default"
                  options={[
                    {
                      children: (
                        <MenuOption
                          label={t("home.options.updateFlow")}
                          icon="IconRefresh"
                          onClick={refreshBoard}
                        />
                      ),
                    },
                    {
                      children: (
                        <MenuOption
                          label={t("home.options.exportData")}
                          icon="IconFileDownload"
                          onClick={() => setExportDataModal(true)}
                        />
                      ),
                    },
                    ...(isPayrollIntegrationReportEnabled
                      ? [
                          {
                            children: (
                              <MenuOption
                                label={t("home.options.exportIntegrationData")}
                                icon="IconFileDownload"
                                onClick={() =>
                                  setIsExportPayrollIntegrationReportModalVisible(
                                    true,
                                  )
                                }
                              />
                            ),
                          },
                        ]
                      : []),
                  ]}
                >
                  <IconButton
                    size="small"
                    variant="line"
                    icon="IconDotsVertical"
                  />
                </Menu>

                <div data-onboarding={PendoEnum.CONFIGURATION_BUTTON}>
                  <IconButton
                    size="small"
                    variant="line"
                    icon="IconSettings"
                    onClick={() => {
                      navigate(
                        `/flows/settings/${selectedHiringFlow}/proposedLetter`,
                      )
                    }}
                  />
                </div>
              </div>
            </PageHeaderOptionsContainer>
          </FlexColumn>
        </PageHeader>
      )}

      {isFlowLoading || !flow || !bigNumbers ? (
        <ContentSkeleton />
      ) : (
        <PageContainer style={{ paddingBottom: "20px" }}>
          {selectedFlowView === "kanban" ? (
            <Kanban flow={flow} bigNumbers={bigNumbers} />
          ) : (
            <List flow={flow} bigNumbers={bigNumbers} />
          )}

          {createCandidateModal ? (
            <CreateCandidateModal
              flow={flow}
              isOpen={createCandidateModal}
              handleClose={() => {
                setCreateCandidateModal(false)
              }}
            />
          ) : (
            <></>
          )}

          {exportDataModal && (
            <ModalExportData
              isOpen={exportDataModal}
              handleClose={() => setExportDataModal(false)}
              columns={flow?.columns || []}
            />
          )}

          {isExportPayrollIntegrationReportModalVisible &&
            completedColumnId && (
              <ExportPayrollIntegrationReportModal
                columnId={completedColumnId}
                handleClose={() =>
                  setIsExportPayrollIntegrationReportModalVisible(false)
                }
              />
            )}
        </PageContainer>
      )}
    </div>
  )
}

export default HiringPage
