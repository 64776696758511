import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
// import { useTranslation } from "react-i18next";
// import { EAccountingPendingStatus } from "@containers/Financial/hooks/dataSources/useAccountingPendingExpensesStatus";
// import { CardFilterSkeleton } from "$organisms/CardFilter/CardFilterSkeleton";

import { ShapeIcon, Spinner } from "$atoms";
import { getValueWithCurrency } from "$frontend/utils";
import {
  CircleDivider,
  Container,
  IconFolderContainer,
  Link,
  ReportsExpensesText,
  ReportsStatusText,
  ReportsStatusTitle,
  ReportsValueText,
  StatusDescriptionContainer,
  SubtitleBox,
} from "./styled";

type BigNumberProps = {
  isLoading?: boolean;
  amount?: number;
  title: string;
  details?: string;
  type: "review" | "show";
  icon: "IconCurrencyDollar" | "IconCheck" | "IconCashBanknote";
  onLinkClick?: () => void;
};

export const BigNumber = ({ isLoading, title, details, icon, amount, type, onLinkClick }: BigNumberProps) => {
  // const { t } = useTranslation();
  const linkText = type === "review" ? "Revisar" : "Mostrar";
  const linkIcon = type === "review" ? "IconChevronsDown" : "IconFilter";

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Container data-testid="big-number-card" className="big-number-card">
      <StatusDescriptionContainer>
        <ReportsStatusTitle>
          <ReportsStatusText variant="body4" weight={600}>
            {title}
          </ReportsStatusText>
        </ReportsStatusTitle>
        <IconFolderContainer>
          <ShapeIcon size="extra_small" icon={icon} />
        </IconFolderContainer>
      </StatusDescriptionContainer>
      <SubtitleBox>
        <ReportsExpensesText variant="headline8" weight={700}>
          {getValueWithCurrency({ value: amount })}
        </ReportsExpensesText>
        <CircleDivider />
        <ReportsValueText variant="body4" weight={600}>
          {details}
        </ReportsValueText>
      </SubtitleBox>
      <Link aria-label={linkText} variant="default" onClick={onLinkClick}>
        <>
          {linkText}
          <Icons name={linkIcon} size={16} />
        </>
      </Link>
    </Container>
  );
};
