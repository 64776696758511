import * as featureFlag from "@flash-tecnologia/feature-flags";
import React from "react";

import AuthService from "./AuthService";
import EnvService from "./EnvService";

const FEATURES = {
  example: "EXAMPLE",
};

export default class FeatureFlagService {
  static getFlag(flagName: keyof typeof FEATURES) {
    featureFlag.WithFlag({
      flagName: FEATURES[flagName],
    });
    return featureFlag.useFlag({
      flagName: FEATURES[flagName],
    });
  }
}

export function FeatureFlagProvider(props: { children: React.ReactNode }) {
  const employeeId = AuthService.useEmployeeId();
  const company = AuthService.useCompany();
  return (
    <featureFlag.FlagsProvider
      appName={`flash_os&environment=${EnvService.BUILD_ENV}`}
      token={EnvService.UNLEASH_TOKEN}
      url={EnvService.UNLEASH_URL}
      refreshIntervalSeconds={1800}
      initialContext={{
        userId: employeeId ?? "",
        properties: {
          employeeNanoId: employeeId ?? "",
          companyNanoId: company?.id ?? "",
          environment: EnvService.BUILD_ENV,
        },
      }}
    >
      {props.children}
    </featureFlag.FlagsProvider>
  );
}
