import { useEffect } from "react";

import {
  Carousel,
  LinkButton,
  Skeleton,
} from "@flash-tecnologia/hros-web-ui-v2";

import { StyledIcon, StyledText, StyledTitle, track } from "@utils";

import {
  Card,
  CircleContainer,
  Container,
  NumbersRow,
  SkeletonContainer,
} from "./styled";

import type { IdpManagerPeopleInsightsResult } from "../../types";

interface IBigNumbersProps {
  data: IdpManagerPeopleInsightsResult;
  isLoading: boolean;
  insightsFilter?: "not_created";
  onFilter: (status?: "not_created") => void;
}

export const BigNumbers = ({
  data,
  isLoading,
  insightsFilter,
  onFilter,
}: IBigNumbersProps) => {
  useEffect(() => {
    onFilter(undefined);
  }, []);

  const isNotCreated = insightsFilter === "not_created";

  if (isLoading) {
    return (
      <SkeletonContainer>
        <Skeleton variant="rounded" width={266} height={166} />
        <Skeleton variant="rounded" width={266} height={166} />
        <Skeleton variant="rounded" width={266} height={166} />
        <Skeleton variant="rounded" width={266} height={166} />
        <Skeleton variant="rounded" width={266} height={166} />
      </SkeletonContainer>
    );
  }

  return (
    <div style={{ marginBottom: "10px" }}>
      <Carousel
        slides={[
          <Card key={0} style={{ opacity: isNotCreated ? 0.5 : 1 }}>
            <Container>
              <CircleContainer setColor="secondary95">
                <StyledIcon
                  name="IconUser"
                  fill="none"
                  setColor="secondary50"
                  size={26}
                />
              </CircleContainer>
              <NumbersRow>
                <StyledTitle
                  setColor="neutral30"
                  variant="headline5"
                  children={data?.peopleWithIdp}
                />
                <StyledTitle
                  setColor="neutral50"
                  variant="body2"
                  children={`/${data?.totalPeople}`}
                />
              </NumbersRow>
              <StyledText
                variant="body3"
                setColor="neutral50"
                children={"Pessoas com PDI's criados"}
                weight={600}
              />
            </Container>
          </Card>,
          <Card key={1}>
            <CircleContainer setColor="error90">
              <StyledIcon
                name="IconUser"
                fill="none"
                setColor="error40"
                size={26}
              />
            </CircleContainer>
            <NumbersRow>
              <StyledTitle
                setColor="neutral30"
                variant="headline5"
                children={data?.peopleWithoutIdp}
              />
              <StyledTitle
                setColor="neutral50"
                variant="body2"
                children={`/${data?.totalPeople}`}
              />
            </NumbersRow>
            <StyledText
              variant="body3"
              setColor="neutral50"
              children={"Pessoas sem PDI's criados"}
              weight={600}
            />
            <LinkButton
              variant="default"
              children={isNotCreated ? "Remover filtro" : "Filtrar"}
              style={{ marginTop: "16px" }}
              onClick={() => {
                track({
                  name: "people_strategic_hr_performance_company_cycles_cycledetails_idps_bignumbers_filter_clicked",
                });
                const status = isNotCreated ? undefined : "not_created";

                onFilter(status);
              }}
            />
          </Card>,
          <Card key={2} style={{ opacity: isNotCreated ? 0.5 : 1 }}>
            <CircleContainer setColor="neutral90">
              <StyledIcon
                name="IconHistory"
                fill="none"
                setColor="neutral40"
                size={26}
              />
            </CircleContainer>
            <NumbersRow>
              <StyledTitle
                setColor="neutral30"
                variant="headline5"
                children={data?.createdIdps}
              />
              <StyledTitle
                setColor="neutral50"
                variant="body2"
                children={`/${data?.totalPeople}`}
              />
            </NumbersRow>
            <StyledText
              variant="body3"
              setColor="neutral50"
              children={"Não iniciados"}
              weight={600}
            />
          </Card>,
          <Card key={3} style={{ opacity: isNotCreated ? 0.5 : 1 }}>
            <CircleContainer setColor="info90">
              <StyledIcon
                name="IconLoader"
                fill="none"
                setColor="info40"
                size={26}
              />
            </CircleContainer>
            <NumbersRow>
              <StyledTitle
                setColor="neutral30"
                variant="headline5"
                children={data?.progressingIdps}
              />
              <StyledTitle
                setColor="neutral50"
                variant="body2"
                children={`/${data?.totalPeople}`}
              />
            </NumbersRow>
            <StyledText
              variant="body3"
              setColor="neutral50"
              children={"Em andamento"}
              weight={600}
            />
          </Card>,
          <Card key={4} style={{ opacity: isNotCreated ? 0.5 : 1 }}>
            <CircleContainer setColor="error90">
              <StyledIcon
                name="IconAlertTriangle"
                fill="none"
                setColor="error40"
                size={26}
              />
            </CircleContainer>
            <NumbersRow>
              <StyledTitle
                setColor="neutral30"
                variant="headline5"
                children={data?.delayedIdps}
              />
              <StyledTitle
                setColor="neutral50"
                variant="body2"
                children={`/${data?.totalPeople}`}
              />
            </NumbersRow>
            <StyledText
              variant="body3"
              setColor="neutral50"
              children={"Atrasados"}
              weight={600}
            />
          </Card>,
          <Card key={5} style={{ opacity: isNotCreated ? 0.5 : 1 }}>
            <CircleContainer setColor="success90">
              <StyledIcon
                name="IconCheck"
                fill="none"
                setColor="success40"
                size={26}
              />
            </CircleContainer>
            <NumbersRow>
              <StyledTitle
                setColor="neutral30"
                variant="headline5"
                children={data?.finishedIdps}
              />
              <StyledTitle
                setColor="neutral50"
                variant="body2"
                children={`/${data?.totalPeople}`}
              />
            </NumbersRow>
            <StyledText
              variant="body3"
              setColor="neutral50"
              children={"Concluídos"}
              weight={600}
            />
          </Card>,
        ]}
        gap={24}
        arrows="bottom"
        alignSlides="flex-start"
      />
    </div>
  );
};
