import { PillButton as PillButtonUI } from '@flash-tecnologia/hros-web-ui-v2';

import { IconProps } from '../Icon/Icon';

type Props = {
  /**
   * Render label with icon
   */
  label?: string;
  variant?: 'default' | 'success' | 'info' | 'error' | 'pink';
  /**
   * @default primary
   */
  type?: 'primary' | 'secondary';
  /**
   * Icon render
   */
  icon?: IconProps['name'];
  /**
   * Changes the position of the icon when there is a label
   * @default right
   */
  iconPosition?: 'left' | 'right';
  /**
   * @default medium
   */
  size: 'xs' | 'sm' | 'md' | 'lg';
  disabled?: boolean;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export function PillButton(props: Props) {
  return (
    <PillButtonUI
      {...props}
      variant={props.variant ?? 'default'}
      type={props.type ?? 'primary'}
      size={mappedSize[props.size ?? 'md']}
    />
  );
}

const mappedSize: Record<
  Props['size'],
  React.ComponentProps<typeof PillButtonUI>['size']
> = {
  xs: 'extra-small',
  sm: 'small',
  md: 'medium',
  lg: 'large',
} as const;
