import { Icons } from "@flash-tecnologia/hros-web-ui-v2"; //TODO passar pelo styled ou criar componente
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { trpc } from "$client";
import { handleTrcpError, useDisplayToast } from "$frontend/utils";
import { CategoryFormModal, DangerActionModal, SwitchCard } from "$molecules";
import { Category } from "$serverTypes";
import { useCategorySettingsContext } from "../hooks/useCategorySettingsContext";
import { CategoriesListLoadingSkeleton } from "./CategoriesListLoadingSkeleton";
import { Container } from "./styled";

export const CategoriesList = () => {
  const { isLoading, isFetching, categories } = useCategorySettingsContext();

  const { refetch } = useCategorySettingsContext();

  const { mutateAsync: editCategory } = trpc.category.patchCategory.useMutation();

  const { mutateAsync: deleteCategory, isLoading: isDeleteCatLoading } = trpc.category.deleteCategory.useMutation();

  const [categoryToEdit, setCategoryToEdit] = useState<Category | undefined>(undefined);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [categoryToDelete, setCategoryToDelete] = useState<Category | undefined>(undefined);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { displayToast } = useDisplayToast();
  const { t } = useTranslation("translations", {
    keyPrefix: "containers.category.categoryList",
  });

  const onToggleCategory = async (category: Category) => {
    const newStatus = category.status == "ACTIVE" ? "INACTIVE" : "ACTIVE";
    try {
      await editCategory({
        id: category.id,
        category: {
          name: category.name,
          status: newStatus,
        },
      });
      refetch();
    } catch (e) {
      displayToast({
        type: "error",
        title: t("toasts.toggleError.title"),
        description: t("toasts.toggleError.description"),
      });
    }
  };

  const onDeleteCategory = async (categoryId: string | undefined) => {
    if (!categoryId) {
      return;
    }

    try {
      await deleteCategory(categoryId);
      refetch();
    } catch (e) {
      const description = handleTrcpError(e, (errorCode) =>
        t(`toasts.deleteError.messages.${errorCode}`, {
          defaultValue: null,
        }),
      );

      displayToast({
        type: "error",
        title: t("toasts.deleteError.title"),
        description,
      });
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  async function onDoneEditingCategory(
    categoryToEdit: Category | undefined,
    editedCategory: Pick<Category, "name" | "externalId">,
  ) {
    if (!categoryToEdit) {
      return;
    }

    await editCategory({
      id: categoryToEdit.id,
      category: { ...categoryToEdit, ...editedCategory },
    });
  }

  if (isFetching && isLoading) {
    return <CategoriesListLoadingSkeleton />;
  }

  return (
    <>
      <Container>
        {categories?.map((category) => (
          <SwitchCard
            key={category.id}
            isActive={category.status === "ACTIVE"}
            label={category.name}
            onToggle={() => onToggleCategory(category)}
            onEdit={() => {
              setCategoryToEdit({ ...category });
              setIsEditModalOpen(true);
            }}
            onDelete={() => {
              setCategoryToDelete({ ...category });
              setIsDeleteModalOpen(true);
            }}
            isLoading={false}
            disableDelete={category.isDefault}
            disableDeleteReason={category.isDefault ? t("tooltips.cantDeleteDefaultCategories") : undefined}
          />
        ))}
      </Container>
      <CategoryFormModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onDone={(editedCategory) => onDoneEditingCategory(categoryToEdit, editedCategory)}
        category={categoryToEdit}
      />

      <DangerActionModal
        open={isDeleteModalOpen}
        headerIconLabel={t("deleteModal.headerIconLabel")}
        headerTitle={t("deleteModal.headerTitle")}
        headerSubtitle={t("deleteModal.headerSubtitle")}
        isLoading={isDeleteCatLoading}
        onActionClick={() => onDeleteCategory(categoryToDelete?.id)}
        onCloseClick={() => setIsDeleteModalOpen(false)}
        labelButtonAction={
          <>
            {t("deleteModal.confirmDeletion")}
            <Icons name="IconTrash" size={24} fill="transparent" />
          </>
        }
      />
    </>
  );
};
