import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 286px;
  padding-bottom: 20px;

  .recharts-wrapper .recharts-cartesian-grid-vertical {
    opacity: 0 !important;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const LabelsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 65px;
  margin-top: 25px;
`;

const BarInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export { Container, BarInfoContainer, LabelsContainer, TitleContainer };
