import { PillButton, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import { Container, SpanNumber, WarningBadgeContainer } from "./styled";

type PillButtonProps = React.ComponentProps<typeof PillButton>;

type PropsType = {
  /**
   *  Label of button
   */
  label?: string;

  /**
   *  Alert badge tooltip corresponding to the count of selected filters
   */
  alertCountFilters?: number;

  /**
   * Callback triggered when the filter button is clicked
   */
  onFilterClick?: () => void;

  /**
   * Icon of the button
   */
  icon?: PillButtonProps["icon"];

  /**
   * Variant of the button
   */
  variant?: PillButtonProps["variant"];

  /**
   * Size of the button
   */
  size?: PillButtonProps["size"];

  /**
   * Type of the button
   */
  type?: PillButtonProps["type"];
};

export const FilterButton = ({
  label,
  alertCountFilters,
  onFilterClick,
  icon = "IconFilter",
  variant = "default",
  size = "small",
  type = "primary",
}: PropsType) => {
  const { t } = useTranslation("translations", { keyPrefix: "molecules.filterButton" });

  return (
    <Container>
      <PillButton
        icon={icon}
        label={label ?? t("filters")}
        onClick={onFilterClick}
        variant={variant}
        size={size}
        type={type}
      />
      {!!alertCountFilters && (
        <Tooltip title={t("tooltip.tooltipTitle", { count: alertCountFilters })}>
          <WarningBadgeContainer>
            <SpanNumber>{alertCountFilters}</SpanNumber>
          </WarningBadgeContainer>
        </Tooltip>
      )}
    </Container>
  );
};
