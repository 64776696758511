import { ReactNode } from 'react';
import { StyledText, StyledTextContainer, StyledWarningCard } from './styles';

type WarningCardProps = {
  title: string;
  description: string;
  action?: ReactNode;
};

export const WarningCard = ({
  title,
  description,
  action,
}: WarningCardProps) => {
  return (
    <StyledWarningCard>
      <StyledTextContainer>
        <StyledText variant="body3" weight={600}>
          {title}
        </StyledText>
        <StyledText variant="body4" weight={400}>
          {description}
        </StyledText>
        {action}
      </StyledTextContainer>
    </StyledWarningCard>
  );
};
