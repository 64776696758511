import { trpc } from '@frontend/trpc';
import { QUERY_RETRY_CONFIG } from '@frontend/utils/constants';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

export function useGetBill(paymentMethod: 'BILLET' | 'PIX' = 'BILLET') {
  const retryConfig = QUERY_RETRY_CONFIG(paymentMethod);

  const mutation = trpc.company.billing.billInfo.useMutation({
    ...retryConfig,
    onError() {
      dispatchToast({
        content: 'Não foi possível baixar pdf. Tente novamente mais tarde.',
        type: 'error',
      });
    },
  });

  return {
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
  };
}
