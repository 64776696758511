import { trpc } from '@/api/client';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';

export const useGetReadyToHireOffers = () => {
  const { selectedCompany } = useSelectedCompany();
  const { data, isLoading } = trpc.getReadyToHireOffers.useQuery(
    { companyId: selectedCompany.id },
    { refetchOnWindowFocus: false },
  );

  return { readyToHireOffers: data, isLoading };
};
