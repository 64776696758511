import {
  IconTypes,
  Icons,
  IconsProps,
  LinkButton,
  Skeleton,
} from "@flash-tecnologia/hros-web-ui-v2"
import {
  FieldContainer,
  FieldDescription,
  FieldTitle,
  FieldWrapper,
  InfoWrapper,
  ListWrapper,
  SmallTag,
  StyledPOneLine,
} from "./styles"
import { Box } from "@components/common/atoms/Box"

export interface IInfoValue {
  value: string
  disableButton?: boolean
  buttonIcon?: IconsProps["name"]
  onClick?: () => void
}

const InfoValue: React.FC<IInfoValue> = ({
  value,
  onClick,
  buttonIcon,
  disableButton = false,
}) => (
  <Box alignItems="center">
    <LinkButton variant="secondary" onClick={onClick} disabled={disableButton}>
      <Box alignItems="center" gap="xs5">
        <StyledPOneLine numberOfLines={1}>
          <FieldDescription variant="body3">{value}</FieldDescription>
        </StyledPOneLine>
        {buttonIcon && !disableButton && (
          <Icons name={buttonIcon} size={16}></Icons>
        )}
      </Box>
    </LinkButton>
  </Box>
)

export type FlowType = "clt" | "pj" | "internship"

export interface InfoProps extends IInfoValue {
  text: string
  icon?: IconTypes
  tagValue?: string
  loading?: boolean
  order?: number
}

export type InfoPropsWithouIdx = Omit<InfoProps, "idx">

export type InfoByType = {
  [key in FlowType]: InfoPropsWithouIdx[]
}

export const Info = ({
  icon,
  text,
  value,
  tagValue,
  loading,
  onClick,
  buttonIcon,
  disableButton,
}: InfoProps) => {
  return (
    <FieldContainer>
      {icon && (
        <Icons
          name={icon}
          fill="transparent"
          style={{
            marginRight: 16,
          }}
        />
      )}
      <FieldWrapper>
        <StyledPOneLine numberOfLines={1}>
          <FieldTitle variant="caption">{text}</FieldTitle>
        </StyledPOneLine>

        {loading ? (
          <Skeleton variant="rectangular" width={"100%"} height={"20px"} />
        ) : (
          <InfoValue
            value={value}
            disableButton={disableButton}
            onClick={onClick}
            buttonIcon={buttonIcon}
          />
        )}

        {tagValue ? <SmallTag marginTop={3}>{tagValue}</SmallTag> : <></>}
      </FieldWrapper>
    </FieldContainer>
  )
}

export type InfoListProps = {
  data: InfoPropsWithouIdx[]
  loading?: boolean
}

export const CandidateInfoList = ({ data, loading }: InfoListProps) => {
  return (
    <ListWrapper>
      {data.map((info, index) => (
        <Info key={`card-info-${index}`} loading={loading} {...info} />
      ))}
    </ListWrapper>
  )
}

export { InfoWrapper }
