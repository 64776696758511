import { Box, Typography } from '@components';

type Props = {
  label?: string;
};

export function ErrorCard(props: Props) {
  return (
    <Box backgroundColor="neutral.95" padding="xs2" radius="m">
      <Typography.Body4 weight={700} color="neutral.30">
        {props.label ?? 'Ocorreu um erro ao carregar as informações'}
      </Typography.Body4>
    </Box>
  );
}
