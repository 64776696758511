import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { dayjs } from "@flash-tecnologia/hros-web-ui-v2";
import { trpc } from "@api/client";
import { Metrics } from "./styles";
import { FirstGraph } from "./graphs/FirstGraph";
import { ThirdGraph } from "./graphs/ThirdGraph";
import { graphs } from "./data";
import { useTheme } from "styled-components";
import { generateSurveyDataByModel } from "@components/EngagementForm/common/components";

const sortBySendDate = (data: any[]) =>
  data?.sort(
    (a, b) => new Date(a?.sendDate).getTime() - new Date(b?.sendDate).getTime()
  );

export default function ({ engagement, occurrences }) {
  const { id = "" } = useParams();
  const theme = useTheme() as any;

  const { data } = trpc?.getFeelingAnalytics?.useQuery({ engagementId: id });
  const sortedOccurrences = sortBySendDate(occurrences);

  const occurrencesIds = sortedOccurrences?.map((occ) => occ?._id);

  const result = data?.result?.filter((d) =>
    occurrencesIds?.includes(d.engagement_id)
  );

  const feelingModel = generateSurveyDataByModel("feeling");

  const second_graph_options = feelingModel[0].questions
    ?.find(
      (question) => question.referenceId === "feeling_survey_best_description"
    )
    ?.options?.map((option) => option?.value || "") || [""];

  const third_graph_options = feelingModel[0].questions
    ?.find(
      (question) => question.referenceId === "feeling_survey_what_was_good"
    )
    ?.options?.map((option) => option?.value || "") || [""];

  const fourth_graph_options = feelingModel[0].questions
    ?.find(
      (question) => question.referenceId === "feeling_survey_what_could_improve"
    )
    ?.options?.map((option) => option?.value || "") || [""];

  const graphsData = useMemo(() => {
    const dateLabel = (index: number) =>
      dayjs(sortedOccurrences?.[index]?.sendDate)?.format("DD/MM/YYYY");

    return {
      firstGraph: data
        ?.map((o) => {
          const date = dayjs(o?.send_at)?.format("DD/MM/YYYY");

          const average = o?.monitorings?.reduce((a, c) => {
            const questions = c?.sections[0]?.questions;

            if (questions?.length) {
              const v = questions[0]?.value;
              if (v) return a + parseInt(v) / o?.monitorings?.length;
            }

            return a;
          }, 0);

          return {
            name: date,
            line: average,
          };
        })
        ?.filter(({ line }) => line),
      secondGraph: data
        ?.map((o) => ({
          name: dayjs(o?.send_at)?.format("DD/MM/YYYY"),
          monitoringCount: o?.monitorings?.length || 0,
          Total:
            o?.tagSelectionCounter?.bestDescription?.reduce(
              (a, c) => a + c?.total || 0,
              0
            ) || 0,
          ...Object.fromEntries(
            second_graph_options?.map((category) => {
              return [
                category,
                o?.tagSelectionCounter?.bestDescription?.find(
                  (i) => i?.value === category
                )?.total || 0,
              ];
            })
          ),
        }))
        ?.filter(({ Total }) => Total),
      thirdGraph: data
        ?.map((o) => ({
          name: dayjs(o?.send_at)?.format("DD/MM/YYYY"),
          monitoringCount: o?.monitorings?.length || 0,
          Total:
            o?.tagSelectionCounter?.whatWasGood?.reduce(
              (a, c) => a + c?.total || 0,
              0
            ) || 0,
          ...Object.fromEntries(
            third_graph_options?.map((category) => {
              return [
                category,
                o?.tagSelectionCounter?.whatWasGood?.find(
                  (i) => i?.value === category
                )?.total || 0,
              ];
            })
          ),
        }))
        ?.filter(({ Total }) => Total),
      fourthGraph: data
        ?.map((o) => ({
          name: dayjs(o?.send_at)?.format("DD/MM/YYYY"),
          monitoringCount: o?.monitorings?.length || 0,
          Total:
            o?.tagSelectionCounter?.whatCouldImprove?.reduce(
              (a, c) => a + c?.total || 0,
              0
            ) || 0,
          ...Object.fromEntries(
            fourth_graph_options?.map((category) => {
              return [
                category,
                o?.tagSelectionCounter?.whatCouldImprove?.find(
                  (i) => i?.value === category
                )?.total || 0,
              ];
            })
          ),
        }))
        ?.filter(({ Total }) => Total),
    };
  }, [result, sortedOccurrences]);

  const graphsValues = graphs({
    theme,
    ...graphsData,
    second_graph_options,
    third_graph_options,
    fourth_graph_options,
  });

  return (
    <Metrics>
      <Grid
        item
        xs={12}
        sm={6}
        lg={6}
        gap={2}
        display={"flex"}
        flexDirection={"column"}
        padding="0 16px 16px 0"
      >
        <FirstGraph data={graphsValues?.[0]} />
        <ThirdGraph data={graphsValues?.[1]} />
        <ThirdGraph data={graphsValues?.[2]} />
        <ThirdGraph data={graphsValues?.[3]} />
      </Grid>
    </Metrics>
  );
}
