import { SetStateAction, useMemo } from "react";
import { cloneDeep, uniq } from "lodash-es";

import { Tag, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import { dispatchToast, track } from "@utils";

import type { BulkActionsCommonProps } from "@components/DataGrid/types";

import type { CycleIdpEmployees, IReminderModalProps } from "../../../types";

export const BulkActions = ({
  table,
  totalCount,
  totalSelected,
  data,
  setReminderModal,
}: BulkActionsCommonProps<CycleIdpEmployees> & {
  data: CycleIdpEmployees[];
  setReminderModal: (value: SetStateAction<IReminderModalProps>) => void;
}) => {
  const selectedIds = table.selected.selected
    .filter((item) => item.original.total === 0)
    .map((item) => item.id);

  const allSelected = table.selected.allSelected;
  const selectedEmpty = !table.selected.selected.length;

  const selectedTableIds = useMemo(() => {
    const clonedSelectedIds = uniq(cloneDeep(selectedIds));

    if (!allSelected) return clonedSelectedIds;

    if (selectedEmpty) {
      track({
        name: "people_strategic_hr_performance_company_cycles_cycledetails_feedbacks_selectall_clicked",
      });
      return uniq(
        cloneDeep(data?.filter(({ total }) => total === 0)).map((d) => d._id)
      );
    }

    const selectedItems = data.filter(
      (item) => !clonedSelectedIds.includes(item._id)
    );

    return uniq(selectedItems.map((d) => d._id));
  }, [table.selected]);

  return (
    <>
      <th style={{ padding: "24px" }}>
        <Tag variant={"primary"} as={"div"}>
          {totalSelected} de {totalCount} selecionado
          {totalSelected > 1 && "s"}
        </Tag>
      </th>

      <th style={{ padding: "24px" }}>
        <LinkButton
          variant="neutral"
          onClick={() => {
            if (!selectedTableIds?.length) {
              return dispatchToast({
                type: "error",
                content:
                  "O(s) colaborador(es) selecionado(s) já têm PDIs criados.",
              });
            }
            track({
              name: "people_strategic_hr_performance_company_cycles_cycledetails_idps_sendreminder_clicked",
            });
            setReminderModal({
              open: true,
              peopleToSend: selectedTableIds,
            });
          }}
        >
          Enviar lembrete às pessoas sem PDI
        </LinkButton>
      </th>
    </>
  );
};
