import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PageContainer } from '@flash-tecnologia/hros-web-ui-v2';
import { Header } from './components/Header';
import { Filters, Table } from './components/Table';
import { ProgressCards } from '../PageEmployeesWorksheet/components';
import { useSession } from '@/common/user';
import { Report } from './components/Report';
import { setInLS } from '@flash-tecnologia/hros-web-utility';
import { StatusEnum } from '@/common/types/StatusEnum';
import { useGetImport } from '../PageEmployeesWorksheet/hooks/useGetImport';
import { useGetEmployeesPaginated } from '@Hooks';

const STANDARD_FILTER_SELECTION = [
  StatusEnum.ACTIVE,
  StatusEnum.INVITATION_EXPIRED,
  StatusEnum.INVITATION_PENDING,
  StatusEnum.INVITATION_SCHEDULED,
  StatusEnum.INVITATION_SENT,
];

export const PageTable = () => {
  const state = useLocation() as any;
  const currentState = useMemo(() => state.state, [state]);
  const [showCard, setShowCard] = useState(true);
  const { companyId, userId } = useSession();

  const {
    fetch,
    data: { employees = [], metadata = { totalCount: 0, totalPages: 1 } } = {},
    isLoading,
  } = useGetEmployeesPaginated();

  const ONE_MINUTE = 60 * 1000;
  const { importProcess, importStatus, successRowsQt, failedRowsQt } =
    useGetImport({
      pollInterval: ONE_MINUTE,
    });

  const [filters, setFilters] = useState<Filters>({
    status: STANDARD_FILTER_SELECTION,
  });

  useEffect(() => {
    currentState?.openCard === undefined
      ? setShowCard(true)
      : setShowCard(currentState?.openCard);

    currentState?.openCard === undefined
      ? setShowCard(true)
      : setShowCard(currentState?.openCard);
  }, [currentState, importStatus]);

  return (
    <PageContainer style={{ minHeight: '100%' }}>
      <Header headerMenuDisabled={false} />
      <Report
        companyId={companyId}
        filters={filters}
        setFilters={setFilters}
        userId={userId}
      />

      {showCard && (
        <ProgressCards
          importProcess={importProcess}
          importStatus={importStatus}
          successRowsQt={successRowsQt}
          failedRowsQt={failedRowsQt}
          onCancel={async () => {
            setShowCard(false);
            setInLS({ key: 'importId', value: {} });
          }}
        />
      )}

      <Table
        companyId={companyId}
        fetchEmployees={fetch}
        totalEmployees={metadata?.totalCount || 0}
        loading={isLoading}
        employees={employees! as any}
        totalPages={metadata?.totalPages || 0}
        filters={filters}
        setFilters={setFilters}
      />
    </PageContainer>
  );
};
