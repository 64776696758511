import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  LinkButton,
  Radio,
  PageContainer,
} from '@flash-tecnologia/hros-web-ui-v2';

import { TXTReader } from '@Utils/TXTReader';

import {
  SeparatorHelperModal,
  HeaderHelperModal,
  CellHelperModal,
} from '../../../modals';

import { ButtonArea } from '../../../components';

import {
  MainContainer,
  LeftColumn,
  RightColumn,
  Card,
  TextContainer,
  ContentContainer,
  FlexContainer,
  StyledIcon,
  StyledTSelect,
  StyledTextField,
  SelectContainer,
  LeftDiv,
  InputContainer,
  RightTable,
  LeftTable,
  HeaderContainer,
  BodyContainer,
} from './styled';

import {
  HelperTextContainer,
  ErrorText,
  StyledText,
  StyledTitle,
} from 'src/common/styles/general-styles';
import { getWorksheetUrl } from '@/pages/PageEmployeesWorksheet/helpers/getWorksheetUrl';
import {
  EmployeesWorksheetSteps,
  ImportType,
} from '@/pages/PageEmployeesWorksheet/types';

const validationSchema = yup.object({
  hasHeaderRadio: yup.boolean().required('Esse dado deve ser indicado.'),
  hasntHeaderRadio: yup.boolean().required('Esse dado deve ser indicado.'),
  separator: yup.string().required('Este campo deve ser preenchido'),
  otherSeparator: yup.string().required('Este campo deve ser preenchido'),
  initialCell: yup.string().required('Este campo deve ser preenchido.'),
});

interface ITxtFilePageProps {
  operation: ImportType;
}

export const TxtFilePage = ({ operation }: ITxtFilePageProps) => {
  const navigate = useNavigate();
  const state = useLocation() as any;

  state.state === null &&
    (() =>
      navigate(
        getWorksheetUrl(operation, EmployeesWorksheetSteps.uploadFile),
      ))();

  const currentState = useMemo(() => state.state, [state]);

  const [openHeaderModal, setOpenHeaderModal] = useState(false);
  const [openSeparatorModal, setOpenSeparatorModal] = useState(false);
  const [hasOtherSeparator, setHasOtherSeparator] = useState(false);
  const [handleDataTable, setHandleDataTable] = useState([]);
  const [openCellModal, setOpenCellModal] = useState(false);
  const [handleCellValue, setHandleCellValue] = useState('');

  const [emptyCell, setEmptyCell] = useState(false);

  const formik = useFormik({
    initialValues: {
      hasHeaderRadio: false,
      hasntHeaderRadio: false,
      separator: '',
      otherSeparator: '',
      initialCell: '',
    },
    validationSchema: validationSchema,

    onSubmit: () => {
      navigate(
        getWorksheetUrl(operation, EmployeesWorksheetSteps.dataAssociation),
        {
          state: {
            ...currentState,
            dataTable: handleDataTable,
            hasHeader: formik.values.hasHeaderRadio,
            initialCell: formik.values.initialCell,
            defaultConfig: false,
            separator: formik.values.separator,
          },
        },
      );
    },
  });

  useEffect(() => {
    const hasHeader = formik.values.hasHeaderRadio;
    const hasntHeaderRadio = formik.values.hasntHeaderRadio;
    const initialCell = formik.values.initialCell;
    const separator = formik.values.separator;
    const otherSeparator = formik.values.otherSeparator;

    if (hasHeader === null || !initialCell || initialCell.length < 2) return;

    hasHeader !== hasntHeaderRadio &&
      (async () => {
        const hasHeader = formik.values.hasHeaderRadio;
        const initialCell = formik.values.initialCell;

        if (hasHeader === null || !initialCell || initialCell.length < 2)
          return;
        const cellColumn = initialCell.replace(/[^A-Za-z]/g, '');
        const cellLine = parseInt(initialCell.replace(/\D/g, ''));
        const columnToNumber = cellColumn.toLowerCase().charCodeAt(0) - 96;

        const result = (await TXTReader({
          droppedFiles: currentState.droppedFile,
          separator: separator === 'other' ? otherSeparator : separator,
        })) as any;
        setHandleDataTable(result);

        if (result.length < cellLine) {
          setHandleCellValue('');
          setEmptyCell(true);
          return;
        }

        const resultToArray = Object.values(result[cellLine - 1]);
        const cellValue = resultToArray[columnToNumber - 1];

        if (cellValue) {
          setEmptyCell(false);
          setHandleCellValue(cellValue.toString());
        } else {
          setHandleCellValue('');
          setEmptyCell(true);
        }
      })();
  }, [formik.values]);

  return (
    <>
      <PageContainer>
        <MainContainer>
          <LeftColumn>
            <StyledTitle
              setColor="secondary50"
              variant="headline7"
              children="Atributos do arquivo"
            />
            <StyledText
              setColor="neutral50"
              variant="body3"
              children="Precisamos entender sobre algumas características do seu arquivo antes de continuarmos com a importação. Complete os dados com atenção antes de prosseguir."
            />
          </LeftColumn>
          <RightColumn>
            <Card>
              <TextContainer>
                <StyledTitle
                  setColor="neutral30"
                  variant="headline8"
                  children="Tipo do separador"
                />
                <StyledText
                  setColor="neutral50"
                  variant="body4"
                  children="O separador de um arquivo pode ser representando por dois tipos de caracteres: vírgula ou ponto e vírgula. Esses caracteres delimitam campos e colunas do seu arquivo."
                />
              </TextContainer>

              <BodyContainer>
                <LeftDiv>
                  <SelectContainer>
                    <StyledTSelect
                      label="Selecione o caracter separador"
                      value={formik.values.separator}
                      options={[
                        {
                          label: 'Virgula (,)',
                          value: ',',
                          caption: 'Virgula (,)',
                        },

                        {
                          label: 'Ponto e Virgula (;)',
                          value: ';',
                          caption: 'Ponto e Virgula (;)',
                        },
                        {
                          label: 'Outro',
                          value: 'other',
                          caption: 'Outro',
                        },
                      ]}
                      onSelectChange={(_, option) => {
                        const value = option.value;

                        formik.handleChange({
                          target: { id: 'separator', value: option.value },
                        });
                        if (value !== 'other') {
                          setHasOtherSeparator(false);
                          formik.setFieldValue('otherSeparator', ' ');
                        } else {
                          setHasOtherSeparator(true);
                        }
                      }}
                      error={
                        formik.touched.separator &&
                        Boolean(formik.errors.separator)
                      }
                    />
                    {formik.touched.separator &&
                      Boolean(formik.errors.separator) && (
                        <HelperTextContainer>
                          <StyledIcon
                            size={15}
                            name="IconAlertCircle"
                            fill="none"
                            setColor="error50"
                          />
                          <ErrorText variant="body4">
                            {formik.errors.separator}
                          </ErrorText>
                        </HelperTextContainer>
                      )}
                  </SelectContainer>
                  <SelectContainer>
                    {hasOtherSeparator && (
                      <StyledTextField
                        label="Selecione o caracter separador"
                        type="text"
                        name="otherSeparator"
                        value={formik.values.otherSeparator}
                        onChange={(e: any) => {
                          const value = e.target.value.replace(/\s/g, '');

                          formik.handleChange({
                            target: { id: 'otherSeparator', value: value },
                          });
                        }}
                        error={
                          formik.touched.otherSeparator &&
                          Boolean(formik.errors.otherSeparator)
                        }
                      />
                    )}
                    {hasOtherSeparator &&
                      formik.touched.otherSeparator &&
                      Boolean(formik.errors.otherSeparator) && (
                        <HelperTextContainer>
                          <StyledIcon
                            size={15}
                            name="IconAlertCircle"
                            fill="none"
                            setColor="error50"
                          />
                          <ErrorText variant="body4">
                            {formik.errors.otherSeparator}
                          </ErrorText>
                        </HelperTextContainer>
                      )}
                  </SelectContainer>
                </LeftDiv>
              </BodyContainer>

              <LinkButton
                variant="default"
                onClick={() => {
                  setOpenSeparatorModal(true);
                }}
              >
                Como identificar a célula inícial dos dados?
                <StyledIcon
                  name="IconExternalLink"
                  fill="none"
                  style={{ marginLeft: '5px' }}
                  setColor="neutral50"
                />
              </LinkButton>
            </Card>

            <Card>
              <TextContainer>
                <StyledTitle
                  setColor="neutral30"
                  variant="headline8"
                  children="Cabeçalho do arquivo"
                />
                <StyledText setColor="neutral50" variant="body4" children="" />
              </TextContainer>
              <FlexContainer>
                <ContentContainer>
                  <Radio
                    size="small"
                    name="hasHeaderRadio"
                    checked={formik.values.hasHeaderRadio}
                    value={formik.values.hasHeaderRadio}
                    onChange={() => {
                      formik.setValues({
                        hasHeaderRadio: true,
                        hasntHeaderRadio: false,
                        separator: formik.values.separator || '',
                        otherSeparator: formik.values.otherSeparator || '',
                        initialCell: formik.values.initialCell || '',
                      });
                    }}
                    error={
                      formik.touched.hasHeaderRadio &&
                      Boolean(formik.errors.hasHeaderRadio)
                    }
                  />
                  <StyledTitle
                    setColor="neutral50"
                    variant="body4"
                    children="Sim, meu arquivo possui cabeçalho"
                  />
                </ContentContainer>
                <ContentContainer>
                  <Radio
                    size="small"
                    name="hasntHeaderRadio"
                    checked={formik.values.hasntHeaderRadio}
                    value={formik.values.hasntHeaderRadio}
                    onChange={() => {
                      formik.setValues({
                        hasHeaderRadio: false,
                        hasntHeaderRadio: true,
                        separator: formik.values.separator || '',
                        otherSeparator: formik.values.otherSeparator || '',
                        initialCell: formik.values.initialCell || '',
                      });
                    }}
                    error={
                      formik.touched.hasHeaderRadio &&
                      Boolean(formik.errors.hasHeaderRadio)
                    }
                  />
                  <StyledTitle
                    setColor="neutral50"
                    variant="body4"
                    children="Não, meu arquivo não possui cabeçalho"
                  />
                </ContentContainer>
              </FlexContainer>
              {formik.touched.hasHeaderRadio &&
                Boolean(formik.errors.hasHeaderRadio) &&
                Boolean(formik.errors.hasntHeaderRadio) && (
                  <HelperTextContainer>
                    <StyledIcon
                      size={15}
                      name="IconAlertCircle"
                      fill="none"
                      setColor="error50"
                    />
                    <ErrorText variant="body4">
                      Esse dado deve ser indicado.
                    </ErrorText>
                  </HelperTextContainer>
                )}
              <LinkButton
                variant="default"
                style={{ marginTop: '32px' }}
                onClick={() => setOpenHeaderModal(true)}
              >
                Como identificar se o arquivo possui cabeçalho?
                <StyledIcon
                  name="IconExternalLink"
                  fill="none"
                  style={{ marginLeft: '5px' }}
                />
              </LinkButton>
            </Card>
            <Card>
              <TextContainer>
                <StyledTitle
                  setColor="neutral30"
                  variant="headline8"
                  children="Início dos dados"
                />
                <StyledText
                  setColor="neutral50"
                  variant="body4"
                  children="Defina a célula onde se iniciam os dados dos usuários ou o próprio cabeçalho (se houver)."
                />
              </TextContainer>

              <HeaderContainer>
                <LeftTable isHeader={true}>
                  <StyledTitle
                    setColor="neutral30"
                    variant="body3"
                    children="Defina a célula inicial dos dados"
                  />
                </LeftTable>
                <RightTable>
                  <StyledTitle
                    setColor="neutral30"
                    variant="body3"
                    children="Conteúdo da célula selecionada"
                  />
                </RightTable>
              </HeaderContainer>
              <BodyContainer>
                <LeftTable isHeader={false}>
                  <InputContainer>
                    <div>
                      <div>
                        <StyledTextField
                          label="Indique a Célula"
                          name="initialCell"
                          value={formik.values.initialCell}
                          onChange={(e: any) => {
                            const value = e.target.value
                              .replace(/[^a-z0-9]/gi, '')
                              .trim()
                              .toUpperCase();

                            formik.handleChange({
                              target: { id: 'initialCell', value: value },
                            });
                          }}
                          error={
                            (formik.touched.initialCell &&
                              Boolean(formik.errors.initialCell)) ||
                            emptyCell
                          }
                        />
                        {formik.touched.initialCell &&
                          Boolean(formik.errors.initialCell) && (
                            <HelperTextContainer>
                              <StyledIcon
                                size={15}
                                name="IconAlertCircle"
                                fill="none"
                                setColor="error50"
                              />
                              <ErrorText variant="body4">
                                {formik.errors.initialCell}
                              </ErrorText>
                            </HelperTextContainer>
                          )}
                      </div>
                    </div>
                  </InputContainer>
                  {emptyCell && (
                    <HelperTextContainer>
                      <StyledIcon
                        size={15}
                        name="IconAlertCircle"
                        fill="none"
                        setColor="error50"
                      />
                      <ErrorText variant="body4">
                        Esta célula não possui conteúdo ou não existe em seu
                        arquivo.
                      </ErrorText>
                    </HelperTextContainer>
                  )}
                </LeftTable>

                <RightTable>
                  <StyledText
                    setColor="neutral50"
                    variant="body4"
                    children={handleCellValue}
                  />
                </RightTable>
              </BodyContainer>

              <LinkButton
                variant="default"
                onClick={() => {
                  setOpenCellModal(true);
                }}
              >
                Como identificar a célula inícial dos dados?
                <StyledIcon
                  name="IconExternalLink"
                  fill="none"
                  style={{ marginLeft: '5px' }}
                />
              </LinkButton>
            </Card>
          </RightColumn>
        </MainContainer>
      </PageContainer>
      <ButtonArea
        onForward={() => formik.handleSubmit()}
        onBackwards={() =>
          navigate(
            getWorksheetUrl(operation, EmployeesWorksheetSteps.uploadFile),
          )
        }
        onCancel={() => navigate('/employees')}
      />

      <HeaderHelperModal
        setOpen={openHeaderModal}
        onClose={() => setOpenHeaderModal(false)}
      />
      <CellHelperModal
        setOpen={openCellModal}
        onClose={() => setOpenCellModal(false)}
      />
      <HeaderHelperModal
        setOpen={openHeaderModal}
        onClose={() => setOpenHeaderModal(false)}
      />
      <SeparatorHelperModal
        setOpen={openSeparatorModal}
        onClose={() => setOpenSeparatorModal(false)}
      />
      <CellHelperModal
        setOpen={openCellModal}
        onClose={() => setOpenCellModal(false)}
      />
    </>
  );
};
