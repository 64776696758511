import { ModuleErrorBoundary } from '@atoms/ModuleErrorBoundary';
import { external } from '@flash-hros/web-expense-lifecycle';

export const CategoriesTab = () => {
  return (
    <ModuleErrorBoundary moduleName="expense-lifecycle" title="Erro ao tentar carregar as categorias de despesas">
      <external.ExternalRoot>
        <external.Categories />
      </external.ExternalRoot>
    </ModuleErrorBoundary>
  );
};
