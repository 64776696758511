import {
  Icons,
  LinkButton,
  Modal,
  Tag,
  TextField,
  Tooltip,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { GroupedBenefit } from '../../utils/group-benefits'
import { useEditDays } from './hooks/use-edit-days'
import {
  ContentTagStyled,
  EditDaysContainerStyled,
  WrapperTagsStyled,
} from './styles'

type EditDaysProps = {
  benefits: GroupedBenefit[]
}

export function EditDays({ benefits }: EditDaysProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const {
    creditDays,
    hasDailyBenefits,
    toggleModal,
    isModalOpen,
    dailyBenefits,
    daysInput,
    handleDaysInputChange,
    saveDays,
  } = useEditDays({ benefits })

  return (
    <EditDaysContainerStyled>
      <div>
        <Icons
          name="IconCalendarStats"
          color={theme.colors.neutral30}
          size={16}
        />

        <Typography variant="body4" color="neutral.30">
          {t('newOrder.orderSummary.creditDays.label', {
            creditDays,
          })}
        </Typography>
      </div>

      {hasDailyBenefits && (
        <LinkButton variant="neutral" onClick={toggleModal}>
          {t('newOrder.orderSummary.creditDays.btnEdit')}
        </LinkButton>
      )}

      <Modal.Root open={isModalOpen} onClose={toggleModal}>
        <>
          <Modal.Header title={t('newOrder.orderSummary.creditDays.title')} />

          <Modal.Content>
            <Typography variant="body4" color="neutral.30">
              {t('newOrder.orderSummary.creditDays.description')}
            </Typography>

            <WrapperTagsStyled itemCount={dailyBenefits.length}>
              {dailyBenefits.map((benefit, index) => (
                <Tooltip key={index} title={benefit.name} arrow placement="top">
                  <Tag variant="gray" as="span">
                    <ContentTagStyled>
                      <img src={benefit.emoji} alt={benefit.name} />{' '}
                      <span>{benefit.name}</span>
                    </ContentTagStyled>
                  </Tag>
                </Tooltip>
              ))}
            </WrapperTagsStyled>

            <TextField
              type="text"
              value={daysInput}
              onChange={handleDaysInputChange}
              label={t('newOrder.orderSummary.creditDays.inputLabel')}
              fullWidth
            />
          </Modal.Content>

          <Modal.Footer
            onConfirm={saveDays}
            cancelText={t('newOrder.orderSummary.creditDays.btnCancel')}
            confirmText={t('newOrder.orderSummary.creditDays.btnSave')}
          />
        </>
      </Modal.Root>
    </EditDaysContainerStyled>
  )
}
