import { dayjs, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { t } from 'i18next'
import React from 'react'
import { Grid } from 'src/components/grid'
import { OrderStatusEnum } from 'src/components/order-confirmation-card/components/header/components/tags/order-status-tag'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'
import { useTheme } from 'styled-components'

import { IOrderMethodConfirmation } from '../../order-method-confirmation.interface'
import { OrderHeader } from '../order-header'
import { OrderInfos } from '../order-infos/order-infos'
import { OrderLinks as OrderLinksComponent } from '../order-links'
import { OrderReminder } from '../order-reminder'
import { OrderTitle } from '../order-title'
import { PixDueDateInfo } from './components/pix-due-date-info'
import { PixPaymentInfo } from './components/pix-payment/pix-payment-info'

interface IPixConfirmationProps extends IOrderMethodConfirmation {
  dueDate: dayjs.Dayjs
  email: string
  pixCode?: string
}

export const PixConfirmation: React.FC<IPixConfirmationProps> = ({
  amount,
  depositDate,
  dueDate,
  email,
  pixCode,
  orderId,
  openNewTab,
  OrderLinks = OrderLinksComponent,
}) => {
  const theme = useTheme()

  const DueDateInfo: React.ReactElement<typeof PixDueDateInfo> = React.useMemo(
    () => <PixDueDateInfo dueDate={dueDate} />,
    [dueDate],
  )

  return (
    <Grid.Container container rowGap={{ xs: theme.spacings.s }}>
      <Grid.SideGrid item xs={12} xl={3} pr={{ xl: theme.spacings.s }}>
        <Typography variant="headline7" color="neutral.10">
          {t(`order.confirmation.sideGrid.${PaymentMethodEnum.PIX}.title`)}
        </Typography>
        <Typography variant="body3" color="neutral.20">
          {t(`order.confirmation.sideGrid.${PaymentMethodEnum.PIX}.subtitle`)}
        </Typography>
      </Grid.SideGrid>
      <Grid.CardGrid item xs={12} xl={9}>
        <OrderHeader
          paymentMethod={PaymentMethodEnum.PIX}
          status={OrderStatusEnum.PENDING}
        />
        <OrderTitle
          paymentMethod={PaymentMethodEnum.PIX}
          orderId={orderId}
          email={email}
        />
        <OrderInfos
          amount={amount}
          depositDate={depositDate}
          dueDateInfo={DueDateInfo}
        />

        <OrderLinks openNewTab={openNewTab} />

        {pixCode && <PixPaymentInfo code={pixCode} />}

        <OrderReminder
          depositDate={depositDate}
          paymentMethod={PaymentMethodEnum.PIX}
        />
      </Grid.CardGrid>
    </Grid.Container>
  )
}
