import styled from "styled-components";

export const PdfViewerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 20px;
  flex-direction: column;
`;
