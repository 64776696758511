import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 24px;
  background: #ffe0ef;
`;
