import { Icons as FlashIcons, IconsProps as FlashIconsProps } from "@flash-tecnologia/hros-web-ui-v2";
import { CustomIconStyle, DefaultIconStyle } from "./styled";
import { TypeVariant } from "$frontend/utils/enum";

type IconsProps = FlashIconsProps & {
  iconVariant?: TypeVariant;
};

export const Icons = ({ iconVariant = TypeVariant.DEFAULT, ...props }: IconsProps) => {
  const IconComponent = iconVariant === TypeVariant.CUSTOM ? CustomIconStyle : DefaultIconStyle;

  return (
    <IconComponent>
      <FlashIcons {...props} />
    </IconComponent>
  );
};
