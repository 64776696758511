import { GET_EMPLOYEE_LAST_INVITE } from '@/api';
import { getWorksheetUrl } from '@/pages/PageEmployeesWorksheet/helpers/getWorksheetUrl';
import {
  EmployeesWorksheetSteps,
  ImportType,
} from '@/pages/PageEmployeesWorksheet/types';
import { ModalCreateEmployee } from '@/pages/PageTable/components/ModalCreateEmployee';
import { useEmployeeAlreadyExistsModal } from '@/pages/PageTable/hooks/useEmployeeAlreadyExistsModal';
import { useRetryQuery } from '@/pages/PageTable/hooks/useRetryQuery';
import { useSuccessCreateEmployeeModal } from '@/pages/PageTable/hooks/useSuccessCreateEmployeeModal';
import { ModalAddEmployeeSuccessWithInvitationLink } from '@Components/ModalAddEmployeeSuccessWithInvitationLink';
import { ModalWarning } from '@Components/ModalWarning';
import { Icons, Menu } from '@flash-tecnologia/hros-web-ui-v2';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PermissionProfilesType } from 'src/pages/PageTable/types';
import { AddColabButton, Option } from './styled';

export const MenuAddColab = ({ disabled }: PermissionProfilesType) => {
  const navigate = useNavigate();
  const [openedModal, setOpenedModal] = useState(false);
  const { employeeAlreadyExistsModal, fallback } =
    useEmployeeAlreadyExistsModal();
  const { successCreateEmployeeModal } = useSuccessCreateEmployeeModal();

  const handleSuccessCreateEmployee = useCallback(() => {
    successCreateEmployeeModal.open();
  }, []);

  const onCloseEmployeeAlreadyExistsModal = useCallback(() => {
    employeeAlreadyExistsModal.close();
  }, []);

  const getLastInvitationIdPolling = useRetryQuery<
    { employeeId: string },
    { employee: { lastInvitation: { id: string } } }
  >({
    maxAttempts: 6,
    document: GET_EMPLOYEE_LAST_INVITE,
    interval: 2000,
    stopCondition: (data) => {
      return !!data?.employee?.lastInvitation;
    },
    onCompleted: handleSuccessCreateEmployee,
  });

  const options = [
    {
      key: 'one-employee',
      onClick: () => setOpenedModal(true),
      children: (
        <Option>
          <Icons name="IconUser" fill="transparent" />
          Apenas uma pessoa
        </Option>
      ),
    },
    {
      key: 'multiple-employees',
      onClick: () =>
        navigate(
          getWorksheetUrl(
            ImportType.insert,
            EmployeesWorksheetSteps.uploadFile,
          ),
        ),
      children: (
        <Option>
          <Icons name="IconUsers" fill="transparent" />
          Várias pessoas
        </Option>
      ),
    },
  ];

  return (
    <>
      <Menu
        disabled={disabled}
        type={'default'}
        options={options}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <AddColabButton disabled={disabled} variant="primary" size="large">
          Convidar membros da equipe
          <Icons name="IconUserPlus" fill="transparent" />
        </AddColabButton>
      </Menu>
      <ModalWarning
        isOpen={employeeAlreadyExistsModal.config.isOpen}
        loading={getLastInvitationIdPolling.loading}
        title={employeeAlreadyExistsModal.config.title}
        description={employeeAlreadyExistsModal.config.description}
        onClose={onCloseEmployeeAlreadyExistsModal}
        onSubmit={fallback!}
      />
      <ModalAddEmployeeSuccessWithInvitationLink
        isOpen={successCreateEmployeeModal.config.isOpen}
        title={successCreateEmployeeModal.config.title}
        description={successCreateEmployeeModal.config.description}
        showInvitationCode={
          successCreateEmployeeModal.config.showInvitationCode
        }
        onClose={() => successCreateEmployeeModal.close()}
        onSubmit={() => successCreateEmployeeModal.close()}
        invitationCode={
          getLastInvitationIdPolling.data?.employee?.lastInvitation?.id!
        }
      />
      <ModalCreateEmployee
        isOpen={openedModal}
        onClose={() => setOpenedModal(false)}
      />
    </>
  );
};
