export const enum Routes {
  ADMIN_CATEGORIES = "/expense-lifecycle/admin/categories",

  ADMIN_REIMBURSEMENTS = "/expense-lifecycle/admin/reimbursements",
  ADMIN_REIMBURSEMENT = "/expense-lifecycle/admin/reimbursement/:expenseId",
  ADMIN_REIMBURSEMENT_EDIT = "/expense-lifecycle/admin/reimbursement/edit/:expenseId",

  ADMIN_STATEMENT = "/expense-lifecycle/admin/statement",
  ADMIN_MOVEMENT = "/expense-lifecycle/admin/movement/:expenseId",
  ADMIN_MOVEMENT_EDIT = "/expense-lifecycle/admin/movement/edit/:expenseId",

  REIMBURSEMENTS = "/expense-lifecycle/reimbursements",
  REIMBURSEMENT = "/expense-lifecycle/reimbursement/:expenseId",
  REIMBURSEMENT_EDIT = "/expense-lifecycle/reimbursement/edit/:expenseId",
  REIMBURSEMENT_CREATE = "/expense-lifecycle/reimbursement/create",

  STATEMENT = "/expense-lifecycle/statement",
  MOVEMENT = "/expense-lifecycle/movement/:expenseId",
  STATEMENT_PENDING_ITEMS = "/expense-lifecycle/statement/pending-items",

  SUBSCRIPTION = "/expenseManagement/subscription",
}
