import styled from 'styled-components';
import { ComponentPadding, makePadding } from '../../utils/theme/paddings';

export const Container = styled.div<{ padding?: ComponentPadding }>`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  gap: ${({ theme }) => theme.spacings.s};
  padding: ${({ theme, padding }) =>
    padding ? makePadding(theme, padding) : 0};
`;
