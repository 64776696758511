import { colors } from '@flash-tecnologia/hros-web-ui-v2';
import styled, { css } from 'styled-components';

export const Button = styled.button<{ isLoading?: boolean; isDisabled?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: max-content auto max-content;
  gap: ${({ theme }) => theme.spacings.xs4};
  padding: ${({ theme }) => theme.spacings.xs2};
  align-items: center;
  text-align: left;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral[95]};
  }

  :not(:last-child) {
    border-bottom: ${({ theme }) =>
      `${theme.borders.width.xs2} solid ${colors.get('neutral.90')}`};
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      cursor: progress;
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
    `};
`;
