import React from "react"
import { ProgressBar, ErrorList } from "../components"
import {
  FooterButtonsType,
  massCandidateContext,
} from "../../AddMassCandidates"
import { useNavigate } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import dispatchToast from "../../../../utils/dispatchToast"
import { ModalConfirmation } from "../../../../components"
import { Grid } from "@mui/material"
import { trpc } from "@api/client"

type ImportingStates = "validation" | "importing" | "error" | "success"

type ImportingContent = {
  title: string
  description: string
  infoText?: string
}

const statesContent: { [key in ImportingStates]: ImportingContent } = {
  validation: {
    title: "Validação dos candidatos",
    description:
      "Vamos verificar todas as informações dos candidatos antes de começar o processo de cadastro.",
    infoText:
      "Você só pode interromper a ação enquanto a validação dos candidatos não estiver concluída.",
  },
  importing: {
    title: "Cadastro de candidatos",
    description:
      "Estamos quase lá, agora só falta finalizar o cadastro de candidatos :)",
  },
  success: {
    title: "Sucesso nas ações em lote!",
    description: "Candidatos cadastrados com sucesso.",
  },
  error: {
    title: "Erro na validação dos candidatos",
    description:
      "Não foi possível cadastrar os candidatos devido a inconsistências no arquivo.",
  },
}

export const Importing = () => {
  const [state, setState] = React.useState<ImportingStates>("validation")
  const [progressPercentage, setProgressPercentage] = React.useState<number>(0)
  const [modalConfirmation, setModalConfirmation] =
    React.useState<boolean>(false)

  const { flow, process, refetchProcess, setFooterButtons } =
    React.useContext(massCandidateContext)
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { mutateAsync: interruptProcess, isLoading: isInterruptingProcess } =
    trpc.massCandidate.interruptProcess.useMutation()

  const { mutateAsync: dismissProcess, isLoading: isDismissingProcess } =
    trpc.massCandidate.dismissProcess.useMutation()

  React.useEffect(() => {
    const twentySeconds = 20000 // cron from job fires each minute
    const interval = setInterval(() => {
      refetchProcess()
    }, twentySeconds)

    return () => {
      clearInterval(interval)
    }
  }, [])

  React.useEffect(() => {
    const stateForEachProcessStage: {
      [key in ImportingStates]: FooterButtonsType
    } = {
      validation: {
        primary: {
          icon: "IconArrowLeft",
          iconSide: "left",
          text: "Voltar para admissão",
          props: {
            onClick: () => navigate("/flows/hiring"),
          },
        },
        secondary: {
          icon: "IconX",
          iconSide: "left",
          text: "Interromper ação",
          props: {
            onClick: () => setModalConfirmation(true),
            loading: isInterruptingProcess,
          },
        },
      },
      importing: {
        primary: {
          icon: "IconArrowLeft",
          iconSide: "left",
          text: "Voltar para admissão",
          props: {
            onClick: () => navigate("/flows/hiring"),
          },
        },
        secondary: {
          icon: "IconX",
          iconSide: "left",
          text: "Interromper ação",
          props: {
            disabled: true,
          },
        },
      },
      error: {
        primary: {
          icon: "IconPlus",
          iconSide: "right",
          text: "Novo cadastro",
          props: {
            onClick: async () =>
              await handleDismissProcess(() =>
                queryClient.resetQueries(["mass-candidate-process", flow?._id]),
              ),
            disabled: isDismissingProcess,
          },
        },
        secondary: {
          icon: "IconArrowLeft",
          iconSide: "left",
          text: "Voltar para admissão",
          props: {
            onClick: () => navigate("/flows/hiring"),
          },
        },
      },
      success: {
        primary: {
          icon: "IconArrowLeft",
          iconSide: "left",
          text: "Voltar para admissão",
          props: {
            onClick: async () =>
              await handleDismissProcess(() => {
                queryClient.resetQueries(["mass-candidate-process", flow?._id])
                navigate("/flows/hiring")
              }),
          },
        },
        secondary: {
          icon: "IconX",
          iconSide: "left",
          text: "Interromper ação",
          props: {
            disabled: true,
          },
        },
      },
    }

    setFooterButtons(stateForEachProcessStage[state])
  }, [state])

  React.useEffect(() => {
    if (!process) return

    if (process.errorReport?.length > 0) {
      setState("error")
      setProgressPercentage(50)
      return
    }

    if (process.validationPercentage < 100) {
      if (process.validationPercentage <= 50) setProgressPercentage(25)
      else setProgressPercentage(50)
      return
    }

    if (process.validationPercentage === 100) {
      if (process.completionPercentage === 100) {
        setState("success")
        setProgressPercentage(100)
        return
      }

      setState("importing")
      if (process.completionPercentage <= 50) setProgressPercentage(75)
      else setProgressPercentage(90)
    }
  }, [process])

  async function handleInterruptProcess() {
    try {
      await interruptProcess({ flowId: flow._id })
      dispatchToast({
        content: "Processo interrompido com sucesso.",
        type: "success",
      })
      setModalConfirmation(false)
      queryClient.resetQueries(["mass-candidate-process", flow?._id])
    } catch (err: any) {
      console.log(err)
      dispatchToast({
        content: err.message,
        type: "error",
      })
    }
  }

  async function handleDismissProcess(callback?: any) {
    try {
      await dismissProcess({ processId: process?._id || "" })
      callback()
    } catch (err: any) {
      console.log(err)
      dispatchToast({
        content: err.message,
        type: "error",
      })
    }
  }

  const progressVariant = ["validation", "importing"].includes(state)
    ? "default"
    : (state as "error" | "success")

  return (
    <Grid item sm={12} md={7} lg={9}>
      <ProgressBar
        title={statesContent[state].title}
        description={statesContent[state].description}
        info={statesContent[state].infoText}
        variantType={progressVariant}
        value={progressPercentage}
      />

      {state === "error" &&
      process &&
      (process?.errorReport || [])?.length > 0 ? (
        <ErrorList
          errors={process.errorReport.map(
            (error) => `Linha ${error.line} - ${error.reason}`,
          )}
          style={{ marginTop: 20 }}
        />
      ) : (
        <></>
      )}

      <ModalConfirmation
        isOpen={modalConfirmation}
        isLoading={isInterruptingProcess}
        variant="error"
        title="Tem certeza que deseja interromper a ação?"
        description="A ação não poderá ser desfeita."
        cancelLabel="Cancelar"
        confirmLabel="Confirmar"
        confirmIcon="IconX"
        onClose={() => setModalConfirmation(false)}
        onConfirm={handleInterruptProcess}
      />
    </Grid>
  )
}
