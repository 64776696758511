import { Button, Icons, LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";

import { trpc } from "$client";
import { Routes } from "$frontend/routes";
import { handleTrcpError, useDisplayToast } from "$frontend/utils";
import { ExpenseFooterActions, FocusedContainer, FocusedHeader, ReimbursementForm } from "$molecules";
import { ExpenseStatus } from "$serverTypes";
import { ExpenseFormDto } from "./types";

export const ReimbursementCreatePage = () => {
  const [loading, setLoading] = useState(false);
  const { displayToast } = useDisplayToast();
  const { t } = useTranslation("translations", {
    keyPrefix: "pages.createReimbursement",
  });

  const methods = useForm<ExpenseFormDto>({
    defaultValues: {
      amount: undefined,
      attachments: undefined,
      comments: undefined,
      date: undefined,
      category: undefined,
    },
  });

  const navigate = useNavigate();
  const { colors } = useTheme();

  const { mutateAsync: createExpense, isSuccess } = trpc.expense.createExpense.useMutation();
  const { mutateAsync: updateExpenseStatus } = trpc.expense.updateSingleStatus.useMutation();
  useEffect(() => {
    methods.reset();
  }, [isSuccess]);

  async function onSubmit(formData: ExpenseFormDto, shouldSendToAccount: boolean, shouldNavigateBack: boolean) {
    if (!formData.date || isNaN(new Date(formData.date).getTime())) return;

    const expense = {
      ...formData,
      currency: "BRL",
      date: formData.date.toISOString(),
      establishment: formData.establishment
        ? {
            sessionToken: formData.establishment.sessionToken,
            placeId: formData.establishment.placeAutocomplete.placeId,
          }
        : undefined,
    };
    setLoading(true);
    try {
      const createdExpense = await createExpense(expense);
      if (shouldSendToAccount) {
        await updateExpenseStatus({
          expenseId: createdExpense.id,
          targetStatus: ExpenseStatus.PENDING_ACCOUNTING,
        });
      }

      displayToast({
        title: t("toasts.creationSuccess.title"),
      });

      if (shouldNavigateBack) {
        navigate(Routes.REIMBURSEMENTS);
      }
    } catch (e) {
      const description = handleTrcpError(e, (errorCode) =>
        t(`toasts.creationFailed.messages.${errorCode}`, {
          defaultValue: null,
        }),
      );

      displayToast({
        type: "error",
        title: t("toasts.creationFailed.title"),
        description,
      });
    } finally {
      setLoading(false);
    }
  }

  const header = (
    <FocusedHeader
      start={[
        <LinkButton variant="neutral" key={1} onClick={() => navigate(Routes.REIMBURSEMENTS)}>
          <Icons name="IconChevronLeft" />
          {t("back")}
        </LinkButton>,
      ]}
      middle={[
        <Typography variant="headline9" weight={700} variantColor={colors.neutral[30]}>
          {t("title")}
        </Typography>,
      ]}
      end={[
        <Button
          variant="secondary"
          variantType="neutral"
          size="small"
          key={1}
          onClick={() => {
            alert("TODO: Help button");
          }}
        >
          <Icons name="IconHelp" />
          {t("help")}
        </Button>,
      ]}
    ></FocusedHeader>
  );

  return (
    <FocusedContainer
      header={header}
      footer={
        <ExpenseFooterActions
          methods={methods}
          hasExpense={false}
          isLoading={loading}
          onAddAnother={(data) => onSubmit(data, true, false)}
          onContinueClick={(data) => onSubmit(data, true, true)}
          onQuitAndSave={(data) => onSubmit(data, false, true)}
        />
      }
    >
      <ReimbursementForm methods={methods} />
    </FocusedContainer>
  );
};
