import { Tag, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.xs4};
  padding: ${({ theme }) => `${theme.spacings.xs1} ${theme.spacings.xs2}`};
`

export const SummaryHeader = ({
  benefitsCount,
}: {
  benefitsCount?: number
}) => {
  const { t } = useTranslation()
  return (
    <Root>
      <Typography variant="body3" color="neutral.20" weight={700}>
        {t('order.checkout.orderSummary.title')}
      </Typography>
      <Tag variant="gray" as="span" size="extra-small">
        {t('order.checkout.orderSummary.itens', { count: benefitsCount })}
      </Tag>
    </Root>
  )
}
