import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";
import { ErrorBoundary } from "$molecules";
import * as external from "../src/external";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary() {
    return <ErrorBoundary moduleName="expense-lifecycle" printIsolatedError />;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;

export { external };
