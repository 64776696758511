import styled from "styled-components";

import { Typography, Icons } from "@flash-tecnologia/hros-web-ui-v2";

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const MetricsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  flex: 1;
  padding: 8px 16px;
`;

export const MainMetric = styled(Typography)`
  font-weight: 700;
  color: var(--color-neutral-40);
`;

export const MetricTitle = styled(Typography)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  color: var(--color-neutral-30);
`;

export const MetricSubTitle = styled(Typography)`
  color: var(--color-neutral-30);
  font-weight: 400;
`;

export const TooltipIcon = styled(Icons)`
  margin-left: 4px;
  color: #53464f;
`;

export const TooltipIconButton = styled.button`
  cursor: default;
  margin-left: 4px;
`;
