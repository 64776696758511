import { Button, Typography } from "$atoms";
import {
  ActionContainer,
  Container,
  ContainerIsolatedError,
  IconAlert,
  IsolatedErrorDescription,
  IsolatedErrorModuleName,
  IsolatedErrorTitle,
  Title,
} from "./styled";
import { useTranslation } from "react-i18next";

type ErrorBoundaryProps = {
  moduleName: string;
  title?: string;
  description?: string;
  printIsolatedError?: boolean;
};

export function ErrorBoundary(props: ErrorBoundaryProps) {
  const { t } = useTranslation("translations", { keyPrefix: "molecules.errorBoundary" });

  if (props.printIsolatedError)
    return (
      <ContainerIsolatedError>
        <IconAlert name="IconAlertTriangle" size={40} />
        <>
          {props.moduleName && (
            <IsolatedErrorModuleName variant="body4">
              {`${t("isolatedError.modulePrefix")} ${props.moduleName}`}
            </IsolatedErrorModuleName>
          )}

          <IsolatedErrorTitle variant="body3">{props.title || t("isolatedError.defaultTitle")}</IsolatedErrorTitle>

          <IsolatedErrorDescription variant="body4">
            {props.description || t("isolatedError.defaultDescription")}
          </IsolatedErrorDescription>
        </>
      </ContainerIsolatedError>
    );

  return (
    <Container>
      <>
        <Title variant="headline4">{props.title || t("defaultTitle")}</Title>

        <Typography weight={700} variant="body4">
          {t("pageLabel")} <code>{location.href}</code>
        </Typography>

        <Typography weight={700} variant="body4">
          {t("moduleLabel")} {props.moduleName}
        </Typography>
      </>

      <Typography variant="body2">{t("retryButton")}</Typography>

      <ActionContainer>
        <Button size="medium" variant="primary" onClick={() => location.reload()}>
          {t("retryButton")}
        </Button>
      </ActionContainer>
    </Container>
  );
}
