import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import ModalService from '@frontend/services/ModalService';
import { useApproveRequest } from '../data/useApproveRequest';

type Props = {
  orderIds: Array<string>;
  employee?: string;
};

function ApproveRequestModal(props: Props) {
  const modalController = ModalService.useModalController();
  const { loading, approval } = useApproveRequest({
    multiple: props.orderIds.length > 1,
    closeModal: modalController.remove,
  });

  return (
    <ConfirmationModal
      loading={loading}
      buttons={{
        confirm: {
          text: 'Confirmar aprovação',
          onClick: () => {
            approval({ orderIds: props.orderIds });
          },
        },
        cancel: {
          text: 'Cancelar',
          onClick: () => {
            modalController.remove();
          },
        },
      }}
      variant="success"
      text={{
        highlightedText: 'Atenção',
        title: `Tem certeza que deseja aprovar ${props.orderIds.length > 1 ? 'os pedidos selecionados?' : `o pedido de ${props.employee || ''}?`}`,
        body: `Após a sua aprovação, ${props.orderIds.length > 1 ? 'o pedido seguirá' : 'os pedidos seguirão'} para a revisão do depósito pela empresa.`,
      }}
      isOpen={modalController.visible}
      onClose={modalController.remove}
    />
  );
}

export default ModalService.create(ApproveRequestModal);
