import { TextFieldWithCheckBox } from '@/pages/PageTable/components/ModalCreateEmployee/hocs/InputFieldWithCheckBox';
import withAdditionalLabel from '@/pages/PageTable/components/ModalCreateEmployee/hocs/WithAdditionalLabel';
import {
  DatePicker,
  Divider,
  TextField,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { FormTypeEnum, useForm } from '../../context/FormContext';
import { CreateOptions } from './components/CreateOptions';
import { DocumentNumberField } from './components/DocumentNumberField';
import { ErrorCaption } from './components/ErrorCaption';
import { FormType } from './components/FormType';
import { InvitationCard } from './components/InvitationCard';
import { SelectDepartmentField } from './components/SelectDepartmentField';
import { SelectGroupField } from './components/SelectGroupField';
import { SelectManagerField } from './components/SelectManagerField';
import { SelectPermissionField } from './components/SelectPermissionField';
import { SelectRoleField } from './components/SelectRoleField';
import { WarningCard } from './components/WarningCard';
import {
  StyledAdditionalFieldsContainer,
  StyledContentContainer,
  StyledLabel,
  StyledLabelContainer,
  StyledRequiredContainer,
  StyledRequiredLabel,
  StyledRequiredSymbol,
  StyledTitleContainer,
} from './styles';

const TextFieldWithAdditionalLabel = withAdditionalLabel(TextField);
const TextFieldWithAdditionalLabelAndCheckBox = withAdditionalLabel(
  TextFieldWithCheckBox,
);

type CreateEmployeeModalContent = {
  onClose: () => void;
};

export const CreateEmployeeModalContent = ({
  onClose,
}: CreateEmployeeModalContent) => {
  const {
    errors,
    updateField,
    form,
    allFieldsDisabled,
    disableEmailField,
    setDisableEmailField,
    setErrors,
    formType,
    canMigrate,
    canRegister,
  } = useForm();

  return (
    <StyledContentContainer data-onboarding="employee-management-create-employee-modal-content">
      <FormType />
      <StyledTitleContainer>
        <Typography variant="headline9" weight={700}>
          Dados para envio do convite
        </Typography>
        <StyledRequiredContainer>
          <StyledRequiredSymbol>*</StyledRequiredSymbol>
          <StyledRequiredLabel variant="body4" weight={600}>
            Campos obrigatórios
          </StyledRequiredLabel>
        </StyledRequiredContainer>
      </StyledTitleContainer>
      <DocumentNumberField />
      {canMigrate && (
        <WarningCard
          title="Atenção! Esta pessoa já está cadastrada no grupo econômico"
          description="Este CPF já está vinculado a outra empresa do mesmo grupo econômico. Selecione o tipo de movimentação que você deseja:"
          action={<CreateOptions />}
        />
      )}

      {canMigrate === false && (
        <WarningCard
          title="O colaborador não pode ser migrado."
          description="Esse colaborador não pode ser migrado. Por favor, entre em contato com o suporte para mais informações sobre como proceder."
          action={<CreateOptions />}
        />
      )}

      {!canRegister === false && (
        <WarningCard
          title="O colaborador não pode ser registrado."
          description="O colaborador não pode ser cadastrado porque já há um colaborador com o mesmo CPF nesta empresa."
        />
      )}

      <TextFieldWithAdditionalLabel
        additionalLabel={
          errors.name && <ErrorCaption>{errors.name}</ErrorCaption>
        }
        fullWidth
        label="Nome Completo"
        placeholder="Nome Completo"
        required={!errors.name}
        onChange={(e) => updateField('name', e.target.value)}
        value={form.name}
        error={!!errors['name']}
        disabled={allFieldsDisabled}
      />
      <TextFieldWithAdditionalLabelAndCheckBox
        fullWidth
        additionalLabel={
          errors.email ? (
            <ErrorCaption>{errors.email}</ErrorCaption>
          ) : (
            <StyledLabelContainer>
              <StyledLabel variant="caption">
                Enviaremos o convite de primeiro acesso à plataforma e app da
                Flash para esse e-mail. Caso não possua o e-mail da pessoa,
                marque a opção correspondente e preencha o celular para que o
                convite seja enviado por SMS.
              </StyledLabel>
            </StyledLabelContainer>
          )
        }
        label="E-mail para envio do convite"
        disabled={disableEmailField || allFieldsDisabled}
        disableCheckbox={allFieldsDisabled}
        placeholder="E-mail"
        onCheckBoxChange={() => {
          setDisableEmailField((disableEmailField) => !disableEmailField);
          if (!disableEmailField) {
            updateField('email', '');
            setErrors((prevError) => ({
              ...prevError,
              email: undefined,
            }));
          }
        }}
        checkboxLabel="Não sei o e-mail"
        required={!disableEmailField && !errors.email}
        onChange={(e) => updateField('email', e.target.value)}
        value={form.email}
        error={!!errors['email']}
      />
      <TextFieldWithAdditionalLabel
        additionalLabel={
          errors.phone && <ErrorCaption>{errors.phone}</ErrorCaption>
        }
        fullWidth
        label="Celular"
        placeholder="Celular"
        disabled={allFieldsDisabled}
        required={disableEmailField && !errors.phone}
        imaskProps={{
          mask: '+55 00 90000-0000',
        }}
        onChange={(e) => updateField('phone', e.target.value)}
        value={form.phone}
        error={!!errors['phone']}
      />
      {formType === FormTypeEnum.FULL && (
        <StyledAdditionalFieldsContainer>
          <DatePicker
            value={form.admissionDate}
            disabled={allFieldsDisabled}
            label="Data de admissão"
            onDateChange={(value) =>
              updateField('admissionDate', value?.toISOString())
            }
          />
          <SelectRoleField />
          <SelectGroupField />
          <SelectDepartmentField />
          <TextField
            disabled={allFieldsDisabled}
            label="PIS"
            value={form.pis}
            onChange={(e) => updateField('pis', e.target.value)}
            imaskProps={{
              mask: '000.00000.00-0',
            }}
          />
          <SelectManagerField />
          <SelectPermissionField />
        </StyledAdditionalFieldsContainer>
      )}
      <Divider orientation="horizontal" />
      <InvitationCard />
    </StyledContentContainer>
  );
};
