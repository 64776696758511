import styled from "styled-components";

export const Metrics = styled.div`
  margin: 32px 0px 56px;
`;

export const CommentsWrapper = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const Feed = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: 400px;
  overflow: scroll;
`;
