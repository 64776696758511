import { TabProps } from '@flash-tecnologia/hros-web-ui-v2';

import * as SC from './styled';

type Props = {
  /**
   * Prop for the id attribute
   */
  id?: string;
  /**
   * List of tab names and content
   */
  items: TabProps['tabItens'];
  /**
   * Initial selected tab (index)
   */
  defaultTab?: number;
  /**
   * Current tab selected (index)
   */
  selected?: number;
  /**
   * Forms of presentation of Tabs
   */
  variant?: 'primary' | 'secondary';
  /**
   * Tab select event
   */
  onTabChanged?: (index: number) => void;
  /**
   * Type of content render on Primary variant
   * @type Type
   */
  type?: TabProps['type'];
  /**
   * If true, show border bottom
   */
  showBorderBottom?: boolean;
};

export function Tab(props: Props) {
  return <SC.Tab {...props} tabItens={props.items} />;
}
