import { ComponentProps } from 'react';
import { Button as UIButton, Icons } from '@flash-tecnologia/hros-web-ui-v2';

export type ButtonProps = {
  children: React.ReactNode;
  onClick(): void;
  /**
   * The variant to use. Primary is the default variant. Secondary is the outlined version.
   * @type 'primary' | 'secondary'
   */
  variant?: 'primary' | 'secondary';
  /**
   * Color to use in button variant.
   * @type 'default' | 'success' | 'info' | 'error' | 'neutral'
   */
  color?: 'default' | 'success' | 'info' | 'error' | 'neutral';
  /**
   * The size of the button.
   * @default 'medium'
   * @type 'small' | 'medium' | 'large'
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Whether the button is loading or not
   * @type boolean
   */
  loading?: boolean;
  /**
   * Minimum width of the button
   * @type string
   * @example 100px
   */
  minWidth?: string;
  /**
   * Maximum width of the button
   * @type string
   * @example 100px
   */
  maxWidth?: string;
  /**
   * Minimum height of the button
   * @type string
   * @example 100px
   */
  minHeight?: string;
  /**
   * Maximum height of the button
   * @type string
   * @example 100px
   */
  maxHeight?: string;
  /**
   * Icon to show in button
   * @example IconAdd
   */
  icon?: ComponentProps<typeof Icons>['name'];
  /**
   * Icon position in button
   * @default right
   */
  iconPosition?: 'left' | 'right';
};

export function Button(props: ButtonProps) {
  return (
    <UIButton {...props} variantType={props.color}>
      {props.icon && props.iconPosition === 'left' && <Icons name={props.icon} />}

      {props.children}

      {props.icon && props.iconPosition !== 'left' && <Icons name={props.icon} />}
    </UIButton>
  );
}
