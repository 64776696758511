import { Stepper, StepperProps } from "@flash-tecnologia/hros-web-ui-v2"; //TODO passar pelo styled ou criar componente

import { BreadcrumbItem, BreadcrumbPage } from "$organisms";
import { Body, Container, Content, FlexContainer, Footer, Header, Subtitle, Title } from "./styled";

type HeaderPageProps = {
  title?: string;
  subTitle?: string;
  breadcrumbItems?: BreadcrumbItem[];
  action?: React.ReactNode;
};
type BasePageProps = {
  children?: React.ReactNode;
  header?: HeaderPageProps;
  stepProps?: StepperProps;
  footer?: React.ReactNode;
};

export const BasePage = ({ header, children, footer, stepProps }: BasePageProps) => {
  return (
    <Container>
      <Content hasFooter={Boolean(footer)}>
        {(header?.breadcrumbItems || stepProps) && (
          <FlexContainer>
            <BreadcrumbPage items={header?.breadcrumbItems || []} />
            {stepProps && <Stepper {...stepProps} />}
          </FlexContainer>
        )}
        {(header?.title || header?.subTitle) && (
          <Header>
            <Container>
              <Title variant="headline6">{header?.title}</Title>
              <Subtitle variant="body3">{header?.subTitle}</Subtitle>
            </Container>
            <div>{header?.action && header.action}</div>
          </Header>
        )}
        <Body>{children}</Body>
      </Content>
      {footer && <Footer>{footer}</Footer>}
    </Container>
  );
};
