import { services } from "$frontend/utils/services";
import { Attachment } from "$serverTypes";
import { getAccessToken } from "@flash-tecnologia/hros-web-utility";
import EnvService from "../EnvService";
import { ReceiptApiException } from "./exceptions/receipt-api-exception";

const baseUrl = services.bff[EnvService.BUILD_ENV];

export const ReceiptApiClient = {
  uploadFile: async function (file: File): Promise<Attachment> {
    const res = await fetch(`${baseUrl}/api/receipt/upload`, {
      method: "POST",
      body: file,
      headers: {
        "X-Flash-Auth": `Bearer ${await getAccessToken()}`,
      },
    });
    if (res.status !== 200) {
      throw await ReceiptApiException.fromResponse(res);
    }

    return await res.json();
  },
  getFile: async function (attachment: Attachment) {
    const params = new URLSearchParams({
      ...attachment,
    });
    const res = await fetch(`${baseUrl}/api/receipt/data?${params.toString()}`, {
      method: "GET",
      headers: {
        "X-Flash-Auth": `Bearer ${await getAccessToken()}`,
      },
    });
    if (res.status !== 200) {
      throw await ReceiptApiException.fromResponse(res);
    }

    return await res.blob();
  },
};
