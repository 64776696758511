import { ErrorBoundary } from "$atoms";
import React from "react";

interface IModuleErrorBoundaryProps {
  children: React.ReactNode;
  moduleName?: string;
  title?: string;
  description?: string;
  printIsolatedError?: boolean;
}

interface IModuleErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  message?: string;
  stack?: string;
  page?: string;
  createdAt?: string;
}

export class ModuleErrorBoundary extends React.Component<IModuleErrorBoundaryProps, IModuleErrorBoundaryState> {
  constructor(props: IModuleErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): IModuleErrorBoundaryState {
    console.error({ error });

    return {
      hasError: true,
      error,
      message: error.message || "Nenhum erro listado",
      stack: error.stack,
      page: window.location.href,
      createdAt: new Date().toISOString(),
    };
  }

  componentDidCatch(error: Error) {
    this.setState({ hasError: true, error });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorBoundary
          moduleName={this.props.moduleName || "Default Module Name"}
          title={this.props.title}
          description={this.props.description}
          printIsolatedError={this.props.printIsolatedError}
        />
      );
    }

    return this.props.children;
  }
}

export default ModuleErrorBoundary;
