import Flex from '@frontend/components/frames/Flex';
import styled from 'styled-components';

export const Cell = styled.div`
  white-space: nowrap;
  display: flex;
  flex-direction: column;
`;

export const Column = styled.div`
  white-space: nowrap;
  display: flex;
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const FlexBorderTop = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

export const FlexBorderTopBottom = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

export const ApproverBox = styled(Flex)`
  width: max-content;
`;
