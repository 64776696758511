import { IconTypes } from "@flash-tecnologia/hros-web-ui-v2";
import { MenuGroupsProps, MenuProps } from "../../types";
import { getEmployeeMenu } from "./employeeMenu";
import { setUpEmployeeMenuForExpenseLifecycle } from "../setup/set-up-expense-lifecycle-menus";
import { MenuV2Arguments } from "../../types/menu-v2-arguments";

const menuV1ItemIsEnabled = (menuItem: MenuGroupsProps, menuName: string) => {
  return !!menuItem?.menus.find((menu) => menu.name === menuName);
};
const menuV1SubItemIsEnabled = (
  menuItem: MenuGroupsProps,
  menuName: string,
  submenuName: string
) => {
  return !!menuItem?.menus
    .find((menu) => menu.name === menuName)
    ?.subMenus?.find((submenu) => submenu.name === submenuName);
};

export const mapEmployeeMenuFromV1 = (
  menuV1Groups: MenuGroupsProps[],
  args: MenuV2Arguments
): {
  menus: (MenuGroupsProps | MenuProps)[];
  newProducts: { name: string; to: string }[];
} => {
  const employeeMenu = getEmployeeMenu();
  let employeeMenuButtons = employeeMenu.menuItems;
  const groupArray: (MenuGroupsProps | MenuProps)[] = [];
  employeeMenuButtons.home.active = true;
  employeeMenuButtons.profileSettings.active = true;
  employeeMenuButtons.cards.active = true;
  employeeMenuButtons.statement.active = menuV1SubItemIsEnabled(
    menuV1Groups[0],
    "myFlash.title",
    "myFlash.statement"
  );

  employeeMenuButtons.wallet.active = menuV1SubItemIsEnabled(
    menuV1Groups[0],
    "myFlash.title",
    "myFlash.wallet"
  );

  menuV1Groups.forEach((menuItem) => {
    switch (menuItem.id) {
      case "flash_expenses":
        employeeMenuButtons.expenseCorp.subMenus.analytics.active =
          menuV1ItemIsEnabled(menuItem, "expenses.analytics");

        employeeMenuButtons.expenseCorp.subMenus.accountability.active =
          menuV1ItemIsEnabled(menuItem, "expenses.expenses");

        employeeMenuButtons.expenseCorp.subMenus.advance.active =
          menuV1ItemIsEnabled(menuItem, "expenses.advances");

        var travelMenuV1 = menuItem.menus.find(
          (menu) => menu.name === "expenses.travel.title"
        );
        if (travelMenuV1?.route?.length) {
          //must show only main menu, not submenus
          employeeMenuButtons.expenseTravel.active = true;
          employeeMenuButtons.expenseTravel.route = travelMenuV1.route;
          Object.values(employeeMenuButtons.expenseTravel.subMenus).forEach(
            (submenu) => (submenu.active = false)
          );
        } else {
          //must show submenus without a route in main menu
          employeeMenuButtons.expenseTravel.active = false;
          employeeMenuButtons.expenseTravel.route = "";
          employeeMenuButtons.expenseTravel.subMenus.search.active =
            menuV1SubItemIsEnabled(
              menuItem,
              "expenses.travel.title",
              "expenses.travel.search"
            );

          employeeMenuButtons.expenseTravel.subMenus.requests.active =
            menuV1SubItemIsEnabled(
              menuItem,
              "expenses.travel.title",
              "expenses.travel.list"
            );

          employeeMenuButtons.expenseTravel.subMenus.approvals.active =
            menuV1SubItemIsEnabled(
              menuItem,
              "expenses.travel.title",
              "expenses.travel.approvals"
            );

          employeeMenuButtons.expenseTravel.subMenus.monitoring.active =
            menuV1SubItemIsEnabled(
              menuItem,
              "expenses.travel.title",
              "expenses.travel.monitoring"
            );

          employeeMenuButtons.expenseTravel.subMenus.reports.active =
            menuV1SubItemIsEnabled(
              menuItem,
              "expenses.travel.title",
              "expenses.travel.reports"
            );
        }

      case "flash_people":
        employeeMenuButtons.timeAndAttendance.subMenus.timeClock.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.timeAndAttendance.timeAndAttendance",
            "people.timeAndAttendance.clockin"
          );
        employeeMenuButtons.timeAndAttendance.subMenus.timeSheet.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.timeAndAttendance.timeAndAttendance",
            "people.timeAndAttendance.timeSheet"
          );
        employeeMenuButtons.timeAndAttendance.subMenus.workshift.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.timeAndAttendance.timeAndAttendance",
            "people.timeAndAttendance.workshift"
          );
        employeeMenuButtons.timeAndAttendance.subMenus.vacation.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.timeAndAttendance.timeAndAttendance",
            "people.timeAndAttendance.myVacations"
          );

        employeeMenuButtons.performance.subMenus.cycles.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.performance.performance",
            "people.performance.myCycles"
          );
        employeeMenuButtons.performance.subMenus.feedbacks.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.performance.performance",
            "people.performance.myFeedbacks"
          );
        employeeMenuButtons.performance.subMenus.idp.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.performance.performance",
            "people.performance.myIdp"
          );
        employeeMenuButtons.training.active = menuV1SubItemIsEnabled(
          menuItem,
          "people.lms.courses",
          "people.lms.myCourses"
        );
        employeeMenuButtons.communications.subMenus.companySurveys.active =
          menuV1SubItemIsEnabled(
            menuItem,
            "people.engagement.engagement",
            "people.engagement.mySurveys"
          );
        employeeMenuButtons.orgchart.active = menuV1ItemIsEnabled(
          menuItem,
          "people.orgchart"
        );
      default:
        break;
    }
  });

  if (args.isExpenseLifecycleEnabled) {
    setUpEmployeeMenuForExpenseLifecycle(
      employeeMenuButtons,
      args.companyPermissions
    );
  }
  // Showing new benefit statement when new SaaS is active
  employeeMenuButtons.statement.active = !args.isExpenseLifecycleEnabled;
  employeeMenuButtons.benefitStatement.active = args.isExpenseLifecycleEnabled;

  Object.values(employeeMenuButtons).forEach((menu) => {
    if ("subMenus" in menu) {
      const activeSubMenus = Object.values(menu.subMenus).filter(
        (submenu) => submenu.active
      );
      if (activeSubMenus.length) {
        groupArray.push({
          ...menu,
          icon: menu.icon as IconTypes,
          subMenus: activeSubMenus,
        });
      }
    }
    if (menu.active) {
      groupArray.push(menu as MenuProps);
    }
  });
  return { menus: groupArray, newProducts: employeeMenu.newProducts };
};

export type EmployeeMenuType = ReturnType<typeof mapEmployeeMenuFromV1>;
