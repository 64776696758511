import { useCallback, useMemo, useState } from 'react'

import { FilterKey } from '../../hooks/use-filters'

export type FilterOption = {
  id?: string
  name: string
  count: number
}

export type UseFilterContentParams = {
  id: FilterKey
  options: FilterOption[]
  selectedItems: string[]
  useId?: boolean
  handleToggleItem: (filterKey: FilterKey, value: string) => void
  selectAll: (
    filterKey: FilterKey,
    isSelected: boolean,
    useId: boolean,
    options: FilterOption[],
  ) => void
}

export function useFilterContent({
  handleToggleItem,
  selectAll,
  id,
  options,
  selectedItems,
  useId = true,
}: UseFilterContentParams) {
  const [searchTerm, setSearchTerm] = useState('')

  const filteredOptions = useMemo(() => {
    const regex = new RegExp(searchTerm.trim(), 'i')

    return options.filter((option) => regex.test(option.name))
  }, [options, searchTerm])

  const selectedNames = useMemo(() => {
    if (useId) {
      return selectedItems
        .map((id) => options.find((option) => option.id === id)?.name)
        .filter(Boolean)
    }

    return selectedItems
  }, [options, selectedItems, useId])

  const handleChangeSearchTerm = useCallback((value: string) => {
    setSearchTerm(value)
  }, [])

  const isOptionsSelected = useCallback(
    (name: string, optionId?: string) => {
      return useId && optionId
        ? selectedItems.includes(optionId)
        : selectedItems.includes(name)
    },
    [selectedItems, useId],
  )

  const toogleItemSelection = useCallback(
    (name: string, optionId?: string) => {
      handleToggleItem(id, useId && optionId ? optionId : name)
    },
    [handleToggleItem, id, useId],
  )

  const allSelected = useMemo(
    () =>
      options.every((option) => isOptionsSelected(option.name, option.id)) &&
      filteredOptions.length !== 0,
    [filteredOptions.length, isOptionsSelected, options],
  )

  const someSelected = useMemo(
    () =>
      filteredOptions.some(
        (option) => isOptionsSelected(option.name, option.id) && !allSelected,
      ),
    [allSelected, filteredOptions, isOptionsSelected],
  )

  const toggleSelectAll = useCallback(() => {
    selectAll(id, !allSelected, useId, filteredOptions)
  }, [allSelected, filteredOptions, id, selectAll, useId])

  return {
    selectedNames,
    isOptionsSelected,
    toogleItemSelection,
    allSelected,
    someSelected,
    toggleSelectAll,
    filteredOptions,
    handleChangeSearchTerm,
  }
}
