import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Home } from "../components/pages"

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/resignation" element={<Home />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
