import { Icon, IconProps, Typography } from '@components';

import * as SC from './styled';

type Props = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  highlight?: React.ReactNode;
  icon?: IconProps['name'];
};

export function DrawerHeader(props: Props) {
  return (
    <SC.Header gap={props.highlight ? 'xs2' : 'xs4'}>
      {props.icon && (
        <Icon
          name={props.icon}
          color="neutral.40"
          background="neutral"
          size={props.highlight ? 48 : 32}
        />
      )}

      <div>
        {props.highlight && (
          <Typography.Body4 color="neutral.40">{props.highlight}</Typography.Body4>
        )}

        <Typography.Headline7 color="neutral.10">{props.title}</Typography.Headline7>

        {props.subtitle && (
          <Typography.Body3 color="neutral.50">{props.subtitle}</Typography.Body3>
        )}
      </div>
    </SC.Header>
  );
}
