import { Typography } from '@components/Typography';
import styled, { css } from 'styled-components';

export const Label = styled(Typography.Body4)<{ isLineThrough?: boolean }>`
  ::first-letter {
    text-transform: uppercase;
  }
  ${({ isLineThrough }) =>
    isLineThrough &&
    css`
      text-decoration-line: line-through;
    `}
`;

export const TooltipContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs5};
  align-items: center;
  cursor: help;
`;
