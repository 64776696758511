import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  border-radius: 12px;
  border: 1px solid #d1c7ce;
`;

export const AgreementContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;
