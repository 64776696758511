import styled from 'styled-components';
import FlashLogo from '../../assets/flashlogo.svg';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  padding: ${({ theme }) => `${theme.spacings.xs2} ${theme.spacings.xs}`};
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings.xs};
  padding: ${({ theme }) => `${theme.spacings.xs2} ${theme.spacings.xs}`};

  @media screen and (max-width: 520px) {
    flex-direction: column-reverse;
  }
`;

export const FooterButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacings.xs};

  @media screen and (max-width: 520px) {
    flex-direction: column-reverse;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacings.xl3};
  flex-grow: 1;
  max-width: 1600px;
  width: 100%;
  max-width: 100%;
  justify-content: center;
  padding: 60px 30px;
  padding-bottom: ${({ theme }) => theme.spacings.m};

  @media screen and (max-width: 1000px) {
    gap: ${({ theme }) => theme.spacings.xl5};
  }

  @media screen and (max-width: 780px) {
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 420px) {
    padding: 30px;
  }

  @media screen and (min-width: 781px) {
    overflow: auto;
  }
`;

export const ImageColumn = styled.div`
  flex-shrink: 0;

  @media screen and (max-width: 780px) {
    display: none;
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
`;

interface BackgroundProps {
  backgroundUrl: string;
}

export const Image = styled.img<BackgroundProps>`
  background-image: url(${({ backgroundUrl }) => backgroundUrl});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: ${({ theme }) => theme.borders.radius.xl2};
  width: 419px;
  height: 416px;

  @media screen and (max-width: 1000px) {
    width: 319px;
    height: 316px;
  }

  @media screen and (max-width: 780px) {
    display: none;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 24px;

  @media screen and (max-width: 660px) {
    flex-direction: column;
    padding: 10px 24px;
    gap: 10px;
  }
`;

export const StyledLogo = styled(FlashLogo)`
  width: 115px;
`;
