import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'

import { IOrderSummaryFeeProps } from '../../order-summary.interface'
import { OrderSummary } from '../summary'

export const OrderSummaryFee = ({
  fees,
}: {
  fees: IOrderSummaryFeeProps[]
}) => {
  const { t } = useTranslation()
  return (
    <OrderSummary.Root>
      <OrderSummary.ListTitle>
        <Typography variant="headline8" color="neutral.20">
          {t('order.checkout.orderSummary.fees.title')}
        </Typography>
      </OrderSummary.ListTitle>
      <OrderSummary.List>
        {fees.map(({ value, key }) => (
          <OrderSummary.Item key={`fee-item-${key}`}>
            <OrderSummary.ItemRoot>
              <Icons name="IconReceiptTax" size={24} />
              <Typography variant="body4" color="neutral.30">
                {t(`order.checkout.orderSummary.fees.${key}`)}
              </Typography>
            </OrderSummary.ItemRoot>
            <Typography variant="body4" weight={700}>
              {t('order.checkout.orderSummary.fees.value', {
                value,
              })}
            </Typography>
          </OrderSummary.Item>
        ))}
      </OrderSummary.List>
    </OrderSummary.Root>
  )
}
