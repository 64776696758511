import { trpc } from "$client";
import { MAX_SLIDER_FIELD_VALUE, MIN_SLIDER_FIELD_VALUE } from "$frontend/components/molecules/SliderField/SliderField";
import { useStatementContext } from "$frontend/pages/Statement/context/StatementContext";

export const useStatementQuery = () => {
  const { methods } = useStatementContext();
  const contextState = methods.watch();

  const {
    data,
    isFetching,
    isError,
    refetch: refetchMovements,
  } = trpc.corporateCard.getStatement.useQuery(
    {
      pageSize: contextState.pagination.pageSize,
      pageNumber: contextState.pagination.pageNumber,
      dateRange: {
        from: contextState.filters.dateRange.from.toISOString(),
        to: contextState.filters.dateRange.to.toISOString(),
      },
      transactionStatus:
        contextState.filters.transactionStatus.length > 0 ? contextState.filters.transactionStatus : [],
      categoryNanoIds: contextState.filters.categoryNanoIds.length > 0 ? contextState.filters.categoryNanoIds : [],
      valueRange:
        contextState.filters.valueRange !== undefined
          ? {
              min: contextState.filters.valueRange?.min ?? MIN_SLIDER_FIELD_VALUE,
              max: contextState.filters.valueRange?.max ?? MAX_SLIDER_FIELD_VALUE,
            }
          : undefined,
      expenseStatus: contextState.filters.status.length > 0 ? contextState.filters.status : [],
    },
    {
      initialData: {
        movements: [],
        totalCount: 0,
      },
    },
  );

  return {
    movements: data.movements,
    totalCount: data.totalCount,
    isFetching: isFetching,
    isError,
    refetch: refetchMovements,
  };
};
