import { NEW_LOGIN_PASSWORD_IMAGE_URL } from '@/assets/imageConstants';
import { useFlag } from '@flash-tecnologia/feature-flags';
import {
  Button,
  Icons,
  LinkButton,
  PasswordField,
  TextField,
} from '@flash-tecnologia/hros-web-ui-v2';
import { setEventTracking } from '@utils/analytics/segment';
import { hideDocumentNumber } from '@utils/masks/hideDocumentNumber';
import { useState } from 'react';
import { useAuthNavigation } from 'src/routes';
import { Footer, Header } from '../../../../components/Common';
import { ModalFirstAccessFlash } from '../../../../components/Modals';
import {
  BottomContainer,
  ButtonsContainer,
  ContentContainer,
  Divider,
  FieldContainer,
  FieldsContainer,
  ForgotButtonsContainer,
  GreetingsText,
  LeftContainer,
  LinksContainer,
  PageContainer,
  RightContainer,
  StyledButton,
  SubTitle,
  TextsContainer,
  Title,
  Wrapper,
} from '../styles';
import { useLoginForm } from './hooks';

export const PasswordStep = () => {
  const navigate = useAuthNavigation();
  const [open, setOpen] = useState(false);
  const { form, isLoading } = useLoginForm();
  const ssoEnabled = useFlag({
    flagName: 'FLASH_OS_AUTH_SSO',
  });

  const handlePasswordRecovery = (documentNumber: string) => {
    setEventTracking('signin_login_forgotpassword_button_clicked', {
      document_number: hideDocumentNumber(documentNumber),
    });
    navigate('PASSWORD_RECOVERY');
  };

  const handleLoginRecovery = () => {
    setEventTracking('signin_login_forgotlogin_button_clicked');
    navigate('LOGIN_RECOVERY');
  };

  const handleHowToAccessMyAccount = () => {
    setEventTracking(
      'signin_login_password_howtoaccessmyaccount_button_clicked',
    );
    navigate('MIGRATION');
  };

  const handleGoToSSO = () => {
    setEventTracking('signin_login_sso_button_clicked');
    navigate('SSO');
  };

  return (
    <PageContainer>
      <Header />
      <ContentContainer>
        <Wrapper>
          <LeftContainer>
            <TextsContainer>
              <GreetingsText variant="body3">Login</GreetingsText>
              <Title variant="headline5">Acesse sua conta Flash</Title>
              <SubTitle variant="body3">
                Use seus dados de cadastro e senha para acessar sua conta Flash.
                Caso possua conta em mais de uma empresa, você poderá escolher
                qual quer acessar no passo seguinte.
              </SubTitle>
            </TextsContainer>
            <form onSubmit={form.handleSubmit}>
              <FieldsContainer>
                <FieldContainer>
                  <TextField
                    label="CPF"
                    id={'login'}
                    name={'login'}
                    autoComplete="off"
                    value={form.values.login}
                    fullWidth={true}
                    onChange={form.handleChange}
                    error={form.touched.login && Boolean(form.errors.login)}
                    helperText={form.touched.login && form.errors.login}
                  />
                </FieldContainer>
                <FieldContainer>
                  <PasswordField
                    label="Insira sua Senha"
                    id={'password'}
                    name={'password'}
                    autoComplete="off"
                    value={form.values.password}
                    onChange={form.handleChange}
                    error={
                      form.touched.password && Boolean(form.errors.password)
                    }
                    helperText={form.touched.password && form.errors.password}
                  />
                </FieldContainer>
              </FieldsContainer>
              <ButtonsContainer>
                <ForgotButtonsContainer>
                  <LinkButton
                    variant="neutral"
                    onClick={() => handlePasswordRecovery(form.values.login)}
                  >
                    <Icons name="IconKey" /> Esqueci minha senha
                  </LinkButton>
                  <LinkButton variant="neutral" onClick={handleLoginRecovery}>
                    <Icons name="IconUser" /> Esqueci meu login
                  </LinkButton>
                </ForgotButtonsContainer>
                <StyledButton
                  variant="primary"
                  size="large"
                  type={'submit'}
                  loading={isLoading}
                  disabled={isLoading}
                >
                  Acessar <Icons name="IconArrowRight" />
                </StyledButton>
              </ButtonsContainer>
            </form>
            <Divider />
            <BottomContainer ssoEnabled={ssoEnabled}>
              <LinksContainer>
                <LinkButton
                  variant="default"
                  onClick={handleHowToAccessMyAccount}
                  style={{ alignSelf: 'auto' }}
                >
                  Como acessar minha conta?
                </LinkButton>
                <LinkButton
                  variant="default"
                  onClick={() => setOpen(true)}
                  style={{ alignSelf: 'auto' }}
                >
                  É minha primeira vez usando Flash
                </LinkButton>
              </LinksContainer>
              {ssoEnabled && (
                <Button
                  variant="secondary"
                  size="large"
                  type="button"
                  onClick={handleGoToSSO}
                >
                  Acessar com SSO <Icons name="IconCloudLock" />
                </Button>
              )}
            </BottomContainer>
          </LeftContainer>
          <RightContainer imageUrl={NEW_LOGIN_PASSWORD_IMAGE_URL} />
        </Wrapper>
      </ContentContainer>
      <ModalFirstAccessFlash open={open} onClose={() => setOpen(false)} />
      <Footer />
    </PageContainer>
  );
};
