import { SurveyStore } from '@frontend/components/SurveyStore';

import { useCreateVirtualCardContext } from '../../data/useCreateVirtualCardContext';
import { FeedbackHeader } from './FeedBackHeader/FeedBackHeader';
import * as SC from './styled';
import { Summary } from './Summary';

export function CreateVirtualCardFeedback() {
  const context = useCreateVirtualCardContext();

  return (
    <SC.Container>
      <FeedbackHeader />

      <Summary />

      <SurveyStore
        title="Como você avalia a facilidade de uso da plataforma ao criar cartões virtuais?"
        type="create-virtual-card-v2"
        params={{
          cardCreated: context.error?.createdCard ? 'success' : 'error',
          linkPolicy: context.form.getValues('policyId')?.length
            ? context.error?.linkPolicy
              ? 'success'
              : 'error'
            : 'none',
          howManyCards: String(
            context.form.getValues('employeeIds')?.size ?? 'error',
          ),
        }}
      />
    </SC.Container>
  );
}
