import {
  Dot,
  Icons,
  IconTypes,
  LinkButton,
  ShapeIcon,
  ShapeIconOptions,
  Tooltip,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"; //TODO passar pelo styled ou criar componente
import { Controller, UseFormReturn } from "react-hook-form";
import { TFunction, useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import { ConfirmationModalReclassifyTransaction } from "$frontend/components/organisms";
import { getValueWithCurrency } from "$frontend/utils";
import {
  CategorySelect,
  ExpenseAmountTrailingContainer,
  ExpenseDescriptionInput,
  ExpenseStatusStepperCard,
  InfoCard,
  InfoCardStatus,
  ReceiptDropzone,
} from "$molecules";
import { ExpenseFormDto } from "$pages";
import { Expense, ExpenseStatus, ExpenseTransactionCardType, TransactionStatus } from "$serverTypes";
import { useState } from "react";
import {
  Card,
  CardTile,
  CardTitle,
  Container,
  Content,
  Form,
  FormContent,
  FormToggleContainer,
  InfoCardCaption,
  ReadonlyTextInput,
  StepperCard,
  WarningCard,
  WarningCardSubtitle,
} from "./styled";

type MovementFormProps = {
  isLoading: boolean;
  methods: UseFormReturn<ExpenseFormDto, Record<string, unknown>>;
  expense: Expense;

  formToggle: boolean;
  onFormToggle: (toggled: boolean) => void;
};

type Features = {
  enableForm?: boolean;
  showBalanceChange?: boolean;
  iconColor?: ShapeIconOptions;

  alertBox?: {
    color: string;
    iconVariant: ShapeIconOptions;
    icon: IconTypes;
    title: string;
    subtitle: string;
    bottomSubtitle?: string;
  };
};

function useFeatures(expense: Expense, t: TFunction<"translations", "molecules.movementForm">): Features {
  const { colors } = useTheme();
  const transaction = expense.transaction;
  const statusLabel = t(`expenseStatus.${expense.status}`);
  switch (expense.status) {
    case undefined:
    case ExpenseStatus.DRAFT:
      return { showBalanceChange: true };
    case ExpenseStatus.PENDING_ACCOUNTING:
      return { iconColor: "success" };
    case ExpenseStatus.PENDING_APPROVAL:
      return { iconColor: "success", enableForm: true };
    case ExpenseStatus.REQUIRE_CHANGES:
      return {
        showBalanceChange: true,
        enableForm: true,
        iconColor: "error",
        alertBox: {
          title: t("reviewedBy", { name: expense.accountingBy }),
          iconVariant: "error",
          icon: "IconX",
          color: colors.feedback.error[70],
          subtitle: t("reviewReason", { accountingComments: expense.accountingComments }),
        },
      };
    case ExpenseStatus.REJECTED:
      return {
        alertBox:
          transaction?.status == TransactionStatus.CANCELED
            ? {
                color: colors.neutral[80],
                iconVariant: "default",
                icon: "IconX",
                title: t("canceledTransaction.title"),
                subtitle: t("canceledTransaction.subtitle"),
                bottomSubtitle: t("canceledTransaction.bottomSubtitle"),
              }
            : {
                title: t("rejectedBy", { name: expense.accountingBy }),
                iconVariant: "negative",
                icon: "IconX",
                color: colors.feedback.negative[70],
                subtitle: t("rejectionReason", { accountingComments: expense.accountingComments }),
              },
      };
    case ExpenseStatus.FINISHED:
      return {};
  }
}

export const MovementForm = ({ isLoading, methods, expense, onFormToggle, formToggle }: MovementFormProps) => {
  const currentTransaction = expense?.transaction;
  const { t } = useTranslation("translations", { keyPrefix: "molecules.movementForm" });
  const [isReclassifyModalOpen, setIsReclassifyModalOpen] = useState(false);

  const { alertBox, enableForm, iconColor, showBalanceChange } = useFeatures(expense, t);
  const disableForm = !enableForm;

  const { colors } = useTheme();

  return (
    <Container>
      <Content>
        <Controller
          control={methods.control}
          name="attachments"
          rules={{ required: true }}
          render={({ field }) => (
            <ReceiptDropzone
              isLoading={isLoading}
              onChange={field.onChange}
              value={field.value ?? []}
              disabled={!formToggle}
            />
          )}
        />

        <FormContent>
          <Card>
            <CardTile bottomBorder={true}>
              <InfoCard
                leading={
                  <ShapeIcon
                    variant={(formToggle ? iconColor : undefined) ?? "default"}
                    stroke={(formToggle ? iconColor : undefined) ?? "default"}
                    name="IconReceipt"
                    size={40}
                  />
                }
                title={
                  <Typography variant="caption" variantColor={colors.neutral[40]}>
                    {t("cardTitle")}
                  </Typography>
                }
                subTitle={
                  <Tooltip title={currentTransaction?.description}>
                    <div>
                      <CardTitle weight={700} variant="headline8" variantColor={colors.neutral[30]}>
                        {currentTransaction?.description}
                      </CardTitle>
                    </div>
                  </Tooltip>
                }
                trailing={
                  <ExpenseAmountTrailingContainer>
                    <InfoCard
                      alignItems="flex-end"
                      title={
                        <Typography variant="caption" variantColor={colors.neutral[40]}>
                          {t("expenseAmount")}
                        </Typography>
                      }
                      subTitle={
                        <Typography weight={700} variant="headline8" variantColor={colors.neutral[30]}>
                          {getValueWithCurrency({
                            value: expense?.amount ?? 0,
                            currencyPrefix: expense?.currency,
                          })}
                        </Typography>
                      }
                    />
                  </ExpenseAmountTrailingContainer>
                }
              />
              <InfoCardStatus type={expense.type} status={expense.status} />
            </CardTile>
            <Form>
              {alertBox && (
                <WarningCard color={alertBox.color}>
                  <InfoCard
                    leading={
                      <ShapeIcon
                        name={alertBox.icon}
                        variant={alertBox.iconVariant}
                        stroke={alertBox.iconVariant}
                        size={32}
                      />
                    }
                    title={
                      <Typography weight={700} variant="body4" variantColor={colors.neutral[30]}>
                        {alertBox.title}
                      </Typography>
                    }
                    subTitle={
                      <WarningCardSubtitle>
                        <Typography weight={400} variant="caption" variantColor={colors.neutral[30]} tag="p">
                          {alertBox.subtitle}
                        </Typography>
                        {alertBox.bottomSubtitle && (
                          <Typography weight={700} variant="caption" variantColor={colors.neutral[30]} tag="p">
                            {alertBox.bottomSubtitle}
                          </Typography>
                        )}
                      </WarningCardSubtitle>
                    }
                  />
                </WarningCard>
              )}
              {formToggle ? (
                <>
                  <Controller
                    control={methods.control}
                    rules={{ required: true }}
                    name="category"
                    render={({ field }) => (
                      <CategorySelect
                        isLoading={isLoading}
                        onChange={(category) =>
                          field.onChange({
                            nanoId: category.id,
                            description: category.name,
                          })
                        }
                        value={field.value?.nanoId ?? ""}
                      />
                    )}
                  />

                  <Controller
                    control={methods.control}
                    name="comments"
                    render={({ field }) => (
                      <ExpenseDescriptionInput
                        isLoading={isLoading}
                        onChange={field.onChange}
                        value={field.value ?? ""}
                      />
                    )}
                  />
                </>
              ) : (
                <>
                  <InfoCard
                    leading={<ShapeIcon name="IconCategory" variant="neutral" stroke="neutral" size={32} />}
                    title={
                      <Typography weight={600} variant="caption" variantColor={colors.neutral[40]}>
                        {t("fields.category")}
                      </Typography>
                    }
                    subTitle={
                      <Typography weight={600} variant="body4" variantColor={colors.neutral[20]}>
                        {expense.category?.description}
                      </Typography>
                    }
                  />
                  <InfoCard
                    fullWidth
                    leading={<ShapeIcon name="IconQuote" variant="neutral" stroke="neutral" size={32} />}
                    title={
                      <Typography weight={600} variant="caption" variantColor={colors.neutral[40]}>
                        {t("fields.comments")}
                      </Typography>
                    }
                    subTitle={
                      <ReadonlyTextInput>
                        <Typography
                          weight={600}
                          variant="body4"
                          variantColor={colors.neutral[20]}
                          style={{ width: "100%" }}
                        >
                          {expense.comments}
                        </Typography>
                      </ReadonlyTextInput>
                    }
                  />
                </>
              )}
              {disableForm || formToggle ? undefined : (
                <FormToggleContainer>
                  <LinkButton variant="default" onClick={() => onFormToggle(!formToggle)}>
                    {t("edit")} <Icons name="IconPencil" />
                  </LinkButton>
                </FormToggleContainer>
              )}
            </Form>
          </Card>
          <Card>
            <CardTile bottomBorder={true}>
              <InfoCard
                leading={<ShapeIcon name="IconCalendarEvent" variant="neutral" stroke="neutral" size={32} />}
                title={
                  <Typography weight={600} variant="caption" variantColor={colors.neutral[40]}>
                    {t("expenseDate")}
                  </Typography>
                }
                subTitle={
                  <Typography weight={600} variant="body4" variantColor={colors.neutral[20]}>
                    {expense &&
                      t("expenseDateValue", {
                        date: new Date(expense.date).toLocaleDateString("pt-BR", {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        }),
                        time: new Date(expense.date).toLocaleTimeString("pt-BR", {
                          hour: "2-digit",
                          minute: "2-digit",
                        }),
                      })}
                  </Typography>
                }
              />
            </CardTile>
            <CardTile bottomBorder={true}>
              <InfoCard
                leading={<ShapeIcon name="IconCreditCard" variant="neutral" stroke="neutral" size={32} />}
                title={
                  <Typography weight={600} variant="caption" variantColor={colors.neutral[40]}>
                    {t("payment")}
                  </Typography>
                }
                subTitle={
                  <InfoCardCaption>
                    <Typography weight={600} variant="body4" variantColor={colors.neutral[20]}>
                      {t("corporative")}
                    </Typography>
                    <Dot variant="gray" />
                    <Typography weight={600} variant="body4" variantColor={colors.neutral[20]}>
                      {expense &&
                        t(`cardType.${expense.transaction?.cardType ?? ExpenseTransactionCardType.PLASTIC}`) +
                          (expense.transaction?.cardLastDigits ?? "")}
                    </Typography>
                  </InfoCardCaption>
                }
              />
            </CardTile>
            {showBalanceChange && (
              <CardTile bottomBorder={true}>
                <InfoCard
                  leading={<ShapeIcon name="IconRefreshAlert" variant="default" stroke="default" size={32} />}
                  title={
                    <Typography weight={600} variant="body4" variantColor={colors.neutral[20]}>
                      {t("balanceChange.title")}
                    </Typography>
                  }
                  subTitle={
                    <Typography weight={600} variant="caption" variantColor={colors.neutral[40]}>
                      {t("balanceChange.subTitle")}
                    </Typography>
                  }
                  trailing={
                    <LinkButton variant="default" onClick={() => setIsReclassifyModalOpen(true)}>
                      {t("balanceChange.submit")}
                    </LinkButton>
                  }
                />
              </CardTile>
            )}
          </Card>
          <StepperCard>
            <ExpenseStatusStepperCard status={expense.status} type={expense.type} />
          </StepperCard>
        </FormContent>
      </Content>
      <ConfirmationModalReclassifyTransaction
        isOpen={isReclassifyModalOpen}
        transaction={currentTransaction}
        onCloseClick={() => setIsReclassifyModalOpen(false)}
      />
    </Container>
  );
};
