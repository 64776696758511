import AppRouter from "./routes"
import { ThemeProvider } from "@flash-tecnologia/hros-web-ui-v2"
import { trpc, queryClient, createTrpcClient } from "./api/client"
import { QueryClientProvider } from "@tanstack/react-query"
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility"
import "../src/i18n"

export default function Root() {
  const { selectedCompany } = useSelectedCompany()
  return (
    <trpc.Provider client={createTrpcClient(selectedCompany.id)} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <AppRouter />
        </ThemeProvider>
      </QueryClientProvider>
    </trpc.Provider>
  )
}
