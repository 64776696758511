import { Button as DSButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Button = styled(DSButton)`
  border-radius: ${(props) => props.theme.spacings.m};
`;

export const PrimaryButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.secondary[95]};
`;

export const PrimaryButtonLabel = styled(Typography).attrs({ variant: "body4" })`
  color: ${(props) => props.theme.colors.secondary[50]};
`;
