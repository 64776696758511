import { Icons, LinkButton, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import {
  SummaryFooterContainerStyled,
  SummaryFooterItemStyled,
  SummaryFooterItemTextContainerStyled,
} from './styles'

export const SummaryFooter = ({
  employeesCount,
  showEmployeesOnClick,
  creditDays,
}: {
  employeesCount?: number
  showEmployeesOnClick?: () => void
  creditDays?: number
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <SummaryFooterContainerStyled>
      {creditDays && (
        <SummaryFooterItemStyled isBetween={false}>
          <Icons
            name="IconCalendarStats"
            color={theme.colors.neutral[30]}
            size={16}
          />

          <Typography variant="body4" color="neutral.30">
            {t('newOrder.orderSummary.creditDays.label', {
              creditDays,
            })}
          </Typography>
        </SummaryFooterItemStyled>
      )}

      <SummaryFooterItemStyled>
        <SummaryFooterItemTextContainerStyled>
          <Icons name="IconUserCircle" size={16} />

          <Typography variant="body4" color="neutral.30">
            {t(`order.checkout.orderSummary.employeesSelectedCount`, {
              count: employeesCount,
            })}
          </Typography>
        </SummaryFooterItemTextContainerStyled>

        <LinkButton
          variant="neutral"
          size="small"
          onClick={showEmployeesOnClick}
        >
          {t(`order.checkout.orderSummary.viewEmployeeSelected`)}
        </LinkButton>
      </SummaryFooterItemStyled>
    </SummaryFooterContainerStyled>
  )
}
