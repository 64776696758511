import styled from 'styled-components';
import { Icons, SelectField } from '@flash-tecnologia/hros-web-ui-v2';

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 23px;
  padding: 0 26px 80px 26px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  max-width: 326px;
  @media screen and (max-width: 1000px) {
    max-width: 500px;
  }
`;
const RightColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 40px;
`;

const Card = styled.div`
  width: 100%;
  max-width: 981px;

  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: 40px;
  border-radius: 10px;
  @media screen and (max-width: 890px) {
    padding: 20px;
    overflow: scroll;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  padding-bottom: 16px;
  margin-bottom: 20px;
  min-width: 610px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

const LineContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding-bottom: 16px;
  min-width: 610px;
`;

const FirstCell = styled.div`
  flex: 0.42;
`;
const SecondCell = styled.div`
  flex: 0.8;
`;
const ThirdCell = styled.div`
  flex: 0.7;
  margin-left: 5px;
`;

const StyledSelect = styled(SelectField)`
  && {
    div {
      display: flex;
      width: 100%;
      max-width: 310px;
      justify-content: flex-end;
      &.iCPAvv.Mui-focused .MuiAutocomplete-clearIndicator {
        display: none;
      }
      @media screen and (max-width: 520px) {
        max-width: 200px;
      }
    }
  }
`;
const ErrorContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 10px;
`;
const StyledIcon = styled(Icons)<{ setColor?: string }>`
  && {
    color: ${({ theme }) => theme.colors.feedback.error[50]};
  }
`;

export {
  MainContainer,
  LeftColumn,
  RightColumn,
  Card,
  LineContainer,
  HeaderContainer,
  FirstCell,
  SecondCell,
  ThirdCell,
  StyledSelect,
  StyledIcon,
  ErrorContainer,
};
