import { SurveyStore as UISurveyStore } from '@flash-tecnologia/hros-web-ui-v2';
import AuthService from '@frontend/services/AuthService';
import { TrackingService } from '@frontend/services/TrackingService';

export type SurveyStoreProps = {
  title: string;
  type: SurveyType;
  params?: Record<string, string>;
};

export function SurveyStore({ title, type, params }: SurveyStoreProps) {
  const company = AuthService.useCompany();
  const employeeId = AuthService.useEmployeeId();

  function handleFeedback(data: { rating: number; message: string }) {
    if (company?.id && employeeId) {
      TrackingService.track(
        type,
        {
          experienceRating: String(data.rating),
          message: data.message,
          ...params,
        },
        {
          employeeId,
          companyId: company.id,
        },
      );

      return true;
    }

    return false;
  }

  return (
    <UISurveyStore isLoading={false} title={title} onSubmit={handleFeedback} />
  );
}

type SurveyType =
  | 'account-management-v2-feedback'
  | 'activate-users-feedback'
  | 'add-flash-cash-feedback'
  | 'create-deposits'
  | 'create-deposits-from-spreadsheet'
  | 'create-virtual-card-v2'
  | 'policy-feedback'
  | 'report-feedback'
  | 'approvals-feedback'
  | 'statement-feedback';
