import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const ReimbursementsPageContainer = styled.div`
  width: 100%;
  height: auto;
`;

export const SummaryCards = styled.div`
  margin-bottom: ${(props) => props.theme.spacings.s};
`;

export const HeaderItemsContainers = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacings.xs2};
`;

// TODO: This is a temporary style of the button until we have the filter and data component finalized
export const NewReimbursementButton = styled(Button)`
  height: 56px;
`;
