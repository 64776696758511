import { Body, Container, Content, Leading, Trailing } from "./styled";

type InfoCardProps = {
  /** The main title element displayed in the card */
  title: JSX.Element;
  /** An optional subtitle element displayed below the title */
  subTitle?: JSX.Element;
  /** An optional element displayed in the leading section of the card */
  leading?: JSX.Element;
  /** An optional element displayed in the trailing section of the card */
  trailing?: JSX.Element;
  /** If true, the card will take up the full width of its container */
  fullWidth?: boolean;
  /** An optional prop to determine align-items of the Body component */
  alignItems?: "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
};

/** Component that implements the following layout:
 *
 *┌───────┌────────┌────────┐
 *│       │title   │        │
 *│leading├────────|trailing│
 *│       │subtitle│        │
 *└───────└────────└────────┘
 */
export const InfoCard = ({ title, subTitle, leading, trailing, fullWidth, alignItems }: InfoCardProps) => {
  return (
    <Container fullWidth={fullWidth}>
      <Content fullWidth={fullWidth}>
        {leading && <Leading>{leading}</Leading>}
        <Body alignItems={alignItems} fullWidth={fullWidth}>
          {title}
          {subTitle}
        </Body>
      </Content>
      {trailing && <Trailing>{trailing}</Trailing>}
    </Container>
  );
};
