import { PillButton, Tooltip, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "styled-components";

import { Loader } from "$atoms";
import { trpc } from "$client";
import { ExpenseFormDto } from "$frontend/pages";
import { Routes } from "$frontend/routes";
import { useDisplayToast } from "$frontend/utils";
import { Alignment } from "$frontend/utils/enum";
import {
  DangerActionModal,
  ExpenseFooterActions,
  ExtraButtonSize,
  ExtraButtonVariant,
  ExtraButtonVariantType,
  MovementForm,
  ReasonSelect,
  SuccessActionModal,
} from "$molecules";
import { BasePage } from "$organisms";
import { Expense, ExpenseStatus, ExpenseType } from "$serverTypes";
import { ButtonsContainer } from "./styles";

export type Features = {
  /** If true, show edit button for this expense status  */
  showEditButton?: boolean;

  /** If true, show delete button for this expense status  */
  showReopenButton?: boolean;
};

function useFeatures(expense: Expense | undefined): Features {
  if (!expense) return {};
  switch (expense.status) {
    case undefined:
    case ExpenseStatus.DRAFT:
      return { showEditButton: true };
    case ExpenseStatus.PENDING_ACCOUNTING:
      return { showEditButton: true };
    case ExpenseStatus.PENDING_APPROVAL:
      return { showEditButton: true };
    case ExpenseStatus.REQUIRE_CHANGES:
      return { showEditButton: true };
    case ExpenseStatus.REJECTED:
      return { showReopenButton: true };
    case ExpenseStatus.FINISHED:
      return { showReopenButton: true };
  }
}

export enum ModalName {
  EDIT = "edit",
  REJECT = "reject",
  REQUEST_REVIEW = "requestReview",
  APPROVE = "approve",
  REOPEN = "reopen",
}

export const AdminMovementPage = () => {
  const { expenseId } = useParams();

  const { data: expense, isFetching: isFetchingExpense } = trpc.expense.getExpenseById.useQuery(expenseId ?? "");

  const { mutateAsync: updateExpenseStatus, isLoading: isSendingExpenseToAccount } =
    trpc.expense.updateSingleStatus.useMutation();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openReopenModal, setOpenReopenModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openRequestReviewModal, setOpenRequestReviewModal] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [selectedReason, setSelectedReason] = useState<string>("");

  const navigate = useNavigate();

  const { t } = useTranslation("translations", { keyPrefix: "pages.adminMovement" });

  const { displayToast } = useDisplayToast();
  const { colors } = useTheme();
  const features = useFeatures(expense);
  const isLoading = isFetchingExpense || isSendingExpenseToAccount;

  const currentExpenseFormData = expense && {
    attachments: expense.attachments ?? [],
    category: expense.category ?? { nanoId: "", description: "" },
    comments: expense.comments ?? "",
    amount: expense.amount,
    date: new Date(expense.date),
  };

  const methods = useForm<ExpenseFormDto>({
    defaultValues: currentExpenseFormData,
    values: currentExpenseFormData,
  });

  async function handleModalAction(modalName: ModalName) {
    try {
      switch (modalName) {
        case ModalName.EDIT:
          navigate(Routes.ADMIN_MOVEMENT_EDIT.replace(":expenseId", expense?.id ?? ""));
          break;
        case ModalName.REJECT:
          if (selectedReason === "") {
            displayToast({
              type: "error",
              title: t(`toasts.rejectCommentRequired.title`),
              description: t(`toasts.rejectCommentRequired.description`),
            });
            return;
          }

          await updateExpenseStatus({
            expenseId: expense?.id ?? "",
            targetStatus: ExpenseStatus.REJECTED,
            accountingComments: selectedReason,
          });
          displayToast({
            title: t("toasts.rejectSuccess.title"),
          });
          break;
        case ModalName.REQUEST_REVIEW:
          if (selectedReason === "") {
            displayToast({
              type: "error",
              title: t(`toasts.requestReviewCommentRequired.title`),
              description: t(`toasts.requestReviewCommentRequired.description`),
            });
            return;
          }

          await updateExpenseStatus({
            expenseId: expense?.id ?? "",
            targetStatus: ExpenseStatus.REQUIRE_CHANGES,
            accountingComments: selectedReason,
          });
          displayToast({
            title: t("toasts.requestReviewSuccess.title"),
          });
          break;
        case ModalName.APPROVE:
          await updateExpenseStatus({
            expenseId: expense?.id ?? "",
            targetStatus: ExpenseStatus.FINISHED,
          });
          displayToast({
            title: t("toasts.approveSuccess.title"),
          });
          break;
        case ModalName.REOPEN:
          await updateExpenseStatus({
            expenseId: expense?.id ?? "",
            targetStatus: ExpenseStatus.PENDING_ACCOUNTING,
          });
          displayToast({
            title: t("toasts.reopenSuccess.title"),
          });
          break;
        default:
      }

      if (modalName !== ModalName.EDIT) {
        navigate(Routes.ADMIN_STATEMENT);
      }
    } catch (e) {
      displayToast({
        type: "error",
        title: t(`toasts.${modalName}Error.title`),
        description: t(`toasts.${modalName}Error.description`),
      });
    }
  }

  const footerContent = (() => {
    if (!expense) return undefined;

    switch (expense.status) {
      case ExpenseStatus.PENDING_ACCOUNTING:
      case ExpenseStatus.PENDING_APPROVAL:
        return (
          <ExpenseFooterActions
            methods={methods}
            hasExpense={!!expense}
            isLoading={isLoading}
            extraButtonsConfig={[
              {
                id: "reject",
                text: t("extraButtons.reject"),
                variant: ExtraButtonVariant.secondary,
                variantType: ExtraButtonVariantType.neutral,
                size: ExtraButtonSize.small,
                onClick: () => setOpenRejectModal(true),
                icon: "IconX",
                disabled: isLoading,
              },
              {
                id: "request-review",
                text: t("extraButtons.requestReview"),
                variant: ExtraButtonVariant.secondary,
                variantType: ExtraButtonVariantType.neutral,
                size: ExtraButtonSize.small,
                onClick: () => setOpenRequestReviewModal(true),
                icon: "IconAlertTriangle",
                disabled: isLoading,
              },
              {
                id: "approve",
                text: t("extraButtons.approve"),
                variant: ExtraButtonVariant.primary,
                variantType: ExtraButtonVariantType.success,
                size: ExtraButtonSize.small,
                onClick: () => setOpenApproveModal(true),
                icon: "IconCheck",
                disabled: isLoading,
              },
            ]}
          />
        );
      default:
        return undefined;
    }
  })();

  return (
    <>
      <BasePage
        header={{
          title: t("title"),
          action: (
            <ButtonsContainer>
              {features.showEditButton && (
                <Tooltip title={t("edit")}>
                  <div>
                    <PillButton
                      icon="IconPencil"
                      size="small"
                      variant="default"
                      onClick={() => setOpenEditModal(true)}
                      disabled={isLoading}
                    />
                  </div>
                </Tooltip>
              )}
              {features.showReopenButton && (
                <Tooltip title={t("reopen")}>
                  <div>
                    <PillButton
                      icon="IconRotateClockwise"
                      size="small"
                      variant="default"
                      onClick={() => setOpenReopenModal(true)}
                      disabled={isLoading}
                    />
                  </div>
                </Tooltip>
              )}
            </ButtonsContainer>
          ),
          breadcrumbItems: [
            {
              to: Routes.ADMIN_STATEMENT,
              label: t("breadcrumbs.previous"),
            },
            {
              to: "",
              label: t("breadcrumbs.current"),
            },
          ],
        }}
        footer={footerContent}
      >
        {expense ? (
          <MovementForm
            expense={expense}
            isLoading={isLoading}
            methods={methods}
            formToggle={false}
            onFormToggle={() => {
              return null;
            }}
          />
        ) : (
          <Loader show={isLoading} />
        )}
      </BasePage>

      {/* Modal Edit */}
      <DangerActionModal
        iconPosition={Alignment.left}
        headerIconLabel={t("modals.confirmationModalEditExpense.attention")}
        headerTitle={t("modals.confirmationModalEditExpense.popupTitle")}
        headerSubtitle={t("modals.confirmationModalEditExpense.popupSubtitle")}
        labelButtonAction={t("modals.confirmationModalEditExpense.confirm")}
        onActionClick={() => handleModalAction(ModalName.EDIT)}
        onCancelClick={() => setOpenEditModal(false)}
        onCloseClick={() => setOpenEditModal(false)}
        open={openEditModal}
      />

      {/* Modal Reopen */}
      <DangerActionModal
        iconPosition={Alignment.left}
        headerIconLabel={t("modals.confirmationModalReopenExpense.attention")}
        headerTitle={t("modals.confirmationModalReopenExpense.popupTitle")}
        headerSubtitle={t("modals.confirmationModalReopenExpense.popupSubtitle")}
        labelButtonAction={t("modals.confirmationModalReopenExpense.confirm")}
        onActionClick={() => handleModalAction(ModalName.REOPEN)}
        onCancelClick={() => setOpenReopenModal(false)}
        onCloseClick={() => setOpenReopenModal(false)}
        open={openReopenModal}
      />

      {/* Modal Reject */}
      <DangerActionModal
        iconPosition={Alignment.left}
        headerIconLabel={t("modals.confirmationModalRejectExpense.attention")}
        headerTitle={t("modals.confirmationModalRejectExpense.popupTitle")}
        labelButtonAction={t("modals.confirmationModalRejectExpense.confirm")}
        onActionClick={() => handleModalAction(ModalName.REJECT)}
        onCancelClick={() => setOpenRejectModal(false)}
        onCloseClick={() => setOpenRejectModal(false)}
        open={openRejectModal}
      >
        <Typography variant="body4" color={colors.neutral[40]}>
          {t("modals.confirmationModalRejectExpense.reasonLabel")}
        </Typography>
        <ReasonSelect
          onChange={(selectedReason) => setSelectedReason(selectedReason.name)}
          hideSelectField={false}
          error={false}
          type={expense?.type ?? ExpenseType.CORPORATE_CARD}
        />
      </DangerActionModal>

      {/* Modal Request Review */}
      <DangerActionModal
        iconPosition={Alignment.left}
        headerIconLabel={t("modals.confirmationModalRequestReview.attention")}
        headerTitle={t("modals.confirmationModalRequestReview.popupTitle")}
        headerSubtitle={t("modals.confirmationModalRequestReview.popupSubtitle")}
        labelButtonAction={t("modals.confirmationModalRequestReview.confirm")}
        onActionClick={() => handleModalAction(ModalName.REQUEST_REVIEW)}
        onCancelClick={() => setOpenRequestReviewModal(false)}
        onCloseClick={() => setOpenRequestReviewModal(false)}
        open={openRequestReviewModal}
      >
        <Typography variant="body4" color={colors.neutral[40]}>
          {t("modals.confirmationModalRequestReview.reasonLabel")}
        </Typography>
        <ReasonSelect
          onChange={(selectedReason) => setSelectedReason(selectedReason.name)}
          hideSelectField={true}
          error={false}
          type={ExpenseType.CORPORATE_CARD}
        />
      </DangerActionModal>

      {/* Modal Approve */}
      <SuccessActionModal
        iconPosition={Alignment.left}
        headerIconLabel={t("modals.confirmationModalApprove.attention")}
        headerTitle={t("modals.confirmationModalApprove.popupTitle")}
        labelButtonAction={t("modals.confirmationModalApprove.confirm")}
        onActionClick={() => handleModalAction(ModalName.APPROVE)}
        onCancelClick={() => setOpenApproveModal(false)}
        onCloseClick={() => setOpenApproveModal(false)}
        open={openApproveModal}
      />
    </>
  );
};
