import styled from 'styled-components';

export const Header = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  padding: ${({ theme }) => `${theme.spacings.xs2} ${theme.spacings.xs}`};
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  position: fixed;
  top: 50%;
  height: 100%;
  width: 100%;

  @media screen and (max-width: 1000px) {
    padding: 60px;
  }
  @media screen and (max-width: 780px) {
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 420px) {
    padding: 30px;
  }
`;
