import { Box, LinkButton, Tag, Typography } from '@components';
import { RouterOutputs } from '@services/corporateCard';

type AccountabilityCode =
  RouterOutputs['corporateStatement']['cardsStatement']['items'][number]['accountability']['code'];

type Props = {
  code?: AccountabilityCode;
  translatedLabel?: string;
  reportName?: string;
  reportUrl?: string;
};
export function Accountability(props: Props) {
  if (!props.code) {
    return <Typography.Body4>-</Typography.Body4>;
  }

  return (
    <Box display="flex" gap="xs4">
      <Tag hasLeftDotIcon variant={mappedAccountability[props.code].variant} size="xs">
        {props.translatedLabel ?? mappedAccountability[props.code].label}
      </Tag>

      {props.reportName && props.reportUrl && (
        <LinkButton href={props.reportUrl} target="_black" variant="neutral">
          {props.reportName}
        </LinkButton>
      )}
    </Box>
  );
}

const mappedAccountability: Record<
  AccountabilityCode,
  { label: string; variant: React.ComponentProps<typeof Tag>['variant'] }
> = {
  PENDING: {
    label: 'Pendente',
    variant: 'info',
  },
  OPEN: {
    label: 'Em aberto',
    variant: 'info',
  },
  PROCESSING: {
    label: 'Aguardando processamento',
    variant: 'info',
  },
  SUBMITTED: {
    label: 'Submetido',
    variant: 'info',
  },
  APPROVED: {
    label: 'Aprovado',
    variant: 'success',
  },
  REFUNDED: {
    label: 'Reembolsado',
    variant: 'success',
  },
  DISAPPROVED: {
    label: 'Reprovado',
    variant: 'error',
  },
  DRAFT: {
    label: 'Pendente',
    variant: 'gray',
  },
  REJECTED: {
    label: 'Reprovado',
    variant: 'red',
  },
  REQUIRE_CHANGE: {
    label: 'Em revisão',
    variant: 'yellow',
  },
  PENDING_APPROVAL: {
    label: 'Em aprovação',
    variant: 'purple',
  },
  PENDING_ACCOUNTING: {
    label: 'Em fechamento',
    variant: 'blue',
  },
  FINISHED: {
    label: 'Finalizada',
    variant: 'green',
  },
  NA: {
    label: 'N/A',
    variant: 'gray',
  },
};
