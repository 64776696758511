import { Box, Icon, IconProps, Typography } from '@components';

type Props = {
  label: string;
  description?: string;
  selected?: boolean;
  icon?: IconProps['name'];
};

export function MenuItem(props: Props) {
  return (
    <Box
      display="flex"
      gap="xs4"
      padding="xs4 xs3"
      alignItems="center"
      width="100%"
      backgroundColor={props.selected ? 'secondary.99' : undefined}>
      {props.icon && (
        <Icon
          name={props.icon}
          color={props.selected ? 'secondary.40' : 'neutral.30'}
          size={16}
        />
      )}

      <div>
        <Typography.Body4
          weight={600}
          color={props.selected ? 'secondary.40' : 'neutral.30'}>
          {props.label}
        </Typography.Body4>

        {props.description && (
          <Typography.Caption
            weight={600}
            color={props.selected ? 'secondary.40' : 'neutral.40'}>
            {props.description}
          </Typography.Caption>
        )}
      </div>

      {props.selected && <Icon name="IconCircleCheck" color="secondary.40" size={16} />}
    </Box>
  );
}
