import { Typography } from '@flash-tecnologia/hros-web-ui-v2'

export interface ITextSubtitle {
  children: string
}

export const TextSubtitle: React.FC<ITextSubtitle> = (props) => {
  return (
    <Typography variant="body3" color="neutral.50">
      {props.children}
    </Typography>
  )
}
