import { FocusedFlow } from '@frontend/components/FocusedFlow';
import ModalService from '@frontend/services/ModalService';
import { METADATA_UserAccounts, ROUTE_UserAccounts } from '../UserAccounts';
import {
  CreateVirtualCardProvider,
  useCreateVirtualCardContext,
} from './CreateVirtualCard/data/useCreateVirtualCardContext';
import { CreateVirtualCardForm } from './CreateVirtualCard/steps/01-form';
import { CreateVirtualCardFeedback } from './CreateVirtualCard/steps/02-feedback';

type Props = {
  employeeIds?: string[];
};

function CreateVirtualCard() {
  const context = useCreateVirtualCardContext();

  function renderContent() {
    switch (context.currentStep) {
      case 0:
        return <CreateVirtualCardForm />;

      case 1:
        return <CreateVirtualCardFeedback />;

      default:
        return <div>Erro ao carregar informações</div>;
    }
  }

  return (
    <FocusedFlow
      headerMetadata={{
        breadcrumbs: [
          {
            text: METADATA_UserAccounts.title,
            link: ROUTE_UserAccounts.buildPath({}),
          },
        ],
      }}
      content={renderContent()}
      footer={
        context.currentStep === 0
          ? {
              leftActions: {
                label: 'Cancelar',
                onClick: () => context.onClose(),
              },
              rightActions: [
                {
                  variant: 'primary',
                  label: context.loadingCreateCards
                    ? 'Criando cartões...'
                    : context.loadingLinkPolicy
                      ? 'Vinculando políticas...'
                      : 'Criar cartão virtual',
                  onClick: () => void context.onSubmit(),
                  isDisabled: false,
                  isLoading:
                    context.loadingCreateCards || context.loadingLinkPolicy,
                },
              ],
            }
          : {
              rightActions: [
                {
                  variant: 'primary',
                  label: 'Fechar',
                  onClick: () => context.onClose(),
                  isDisabled: false,
                  isLoading:
                    context.loadingCreateCards || context.loadingLinkPolicy,
                },
              ],
            }
      }
    />
  );
}

function CreateVirtualCardModal(props: Props) {
  return (
    <CreateVirtualCardProvider employeeIds={props.employeeIds}>
      <CreateVirtualCard />
    </CreateVirtualCardProvider>
  );
}

export default ModalService.create(CreateVirtualCardModal);
