import { colors, Icons, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import ModalService from '@frontend/services/ModalService';
import { useState } from 'react';
import ApproveRequestModal from '../../RequestBalanceSectionV2/modals/ApproveRequestModal';
import ReproveRequestModal from '../../RequestBalanceSectionV2/modals/ReproveRequestModal';
import ApproveDepositModal from '../modals/ApproveDepositModal';
import CancelRequestModal from '../modals/CancelRequestModal';
import RequestBalanceDrawer from '../RequestBalanceDrawer';
import * as styled from '../styled';
import { Rows } from './setup';

type ActionProps = {
  order: Rows;
  type: 'approval' | 'deposit';
};
export function ActionButtons({ order, type }: ActionProps) {
  const [isHover, setIsHover] = useState(false);
  switch (order.status.value) {
    case 'pending':
      if (!order.canApprove || type !== 'approval')
        return <DefaultAction order={order} />;

      return (
        <styled.Wrapper
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {isHover ? (
            <>
              <PillButton
                icon="IconX"
                label="Reprovar"
                size="extra-small"
                variant="error"
                onClick={() => {
                  ModalService.show(ReproveRequestModal, {
                    orderIds: [order._id],
                    employee: order.employee.name,
                  });
                }}
              />
              <PillButton
                icon="IconCheck"
                label="Aprovar"
                variant="success"
                size="extra-small"
                onClick={() => {
                  ModalService.show(ApproveRequestModal, {
                    orderIds: [order._id],
                    employee: order.employee.name,
                  });
                }}
              />
            </>
          ) : (
            <Flex radius="pill" borderWidth="xs2" padding="xs4" gap="xs4">
              <Icons
                name="IconX"
                size={16}
                color={colors.status.negative[40]}
              />
              <Icons
                name="IconCheck"
                size={16}
                color={colors.status.complete[40]}
              />
            </Flex>
          )}
        </styled.Wrapper>
      );
    case 'preview':
      if (type !== 'deposit') return <DefaultAction order={order} />;
      return (
        <styled.Wrapper
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <Flex radius="pill" borderWidth="xs2" padding="xs4" gap="xs4">
            <Icons
              name="IconX"
              size={16}
              onClick={() => {
                ModalService.show(CancelRequestModal, {
                  orderId: order._id,
                  employee: order.employee.name,
                });
              }}
              color={colors.status.negative[40]}
            />
            <Icons
              name="IconCheck"
              size={16}
              onClick={() => {
                ModalService.show(ApproveDepositModal, {
                  order: {
                    _id: order._id,
                    creditDate: order.creditDate,
                    employees: [order.employee],
                    value: order.value,
                  },
                });
              }}
              color={colors.status.complete[40]}
            />
          </Flex>
        </styled.Wrapper>
      );
    default:
      return <DefaultAction order={order} />;
  }
  return null;
}

type DefaultActionProps = {
  order: Rows;
};
function DefaultAction({ order }: DefaultActionProps) {
  return (
    <styled.Wrapper>
      <PillButton
        icon="IconInfoCircle"
        variant="default"
        size="extra-small"
        onClick={() => {
          ModalService.show(RequestBalanceDrawer, {
            order,
          });
        }}
      />
    </styled.Wrapper>
  );
}
