import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div<{ $isEmpty: boolean }>(({ theme, $isEmpty }) => ({
  borderRadius: theme.borders.radius.s,
  border: `${theme.borders.width.xs2} solid ${theme.colors.neutral[95]}`,
  backgroundColor: theme.colors.neutral[100],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "50px",
  width: "50px",

  ...(!$isEmpty && {
    "&:hover": {
      borderColor: theme.colors.secondary[90],
    },
  }),
}));

export const Content = styled.div<{ $isEmpty: boolean }>(({ theme, $isEmpty }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  position: "relative",

  ...(!$isEmpty && {
    transition: "transform 0.1s ease",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.03)",
      borderColor: theme.colors.secondary[70],
      ".icon-container": {
        color: theme.colors.secondary[70],
      },

      span: {
        marginTop: 0,
      },
    },
  }),
}));

export const ReceiptIcon = styled(Icons)<{ $isEmpty: boolean }>`
  color: ${({ theme, $isEmpty }) => theme.colors.neutral[$isEmpty ? 95 : 80]};
`;

//TODO remover? não utilizado no ExpenseReceipts.tsx
export const ReceiptEmptyIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.neutral[90]};
`;

export const NumberReceiptsContainer = styled.div`
  height: 16px;
  width: 16px;
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  border: ${({ theme }) => theme.borders.width.xs2} solid ${({ theme }) => theme.colors.neutral[80]};
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 4px;
  right: 4px;
`;

export const NumberReceiptsDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[70]};
  font-weight: 700 !important;
  line-height: 1px !important;
  margin-top: 1px;
`;

//TODO remover? não utilizado no ExpenseReceipts.tsx
export const Description = styled(Typography)`
  ${({ theme }) => theme.colors.neutral[40]};
  font-size: 12px;
`;
