import { OwnerForm } from './components/OwnerForm';
import { SignatureForm } from './components/SignatureForm';
import { DocumentForm } from './components/DocumentForm';
import { AdditionalInformationForm } from './components/AdditionalinformationForm';
import { useIsAdmin } from 'src/hooks/useIsAdmin';

export const Form = () => {
  const isAdmin = useIsAdmin();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        maxWidth: 'calc(100% - 138px)',
      }}
    >
      <OwnerForm />
      <DocumentForm />
      {isAdmin && <SignatureForm />}
      <AdditionalInformationForm />
    </div>
  );
};
