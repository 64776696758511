import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const PageHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacings.xs3} ${(props) => props.theme.spacings.xs};
`;

export const TitleSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: auto;
`;

export const PageTitle = styled(Typography).attrs({ variant: "headline7" })`
  color: ${({ theme }) => theme.colors.neutral[20]};
`;

export const PageSubtitle = styled(Typography).attrs({
  variant: "body4",
})`
  color: ${(props) => props.theme.colors.neutral[40]};
`;
