import NiceModal from '@ebay/nice-modal-react';
import { external } from '@flash-hros/corporate-card';
import { RouteComponent_Statement } from '@pages/statement';
import { BrowserRouter, Routes } from 'react-router-dom';

function AppRouter() {
  return (
    <BrowserRouter>
      <NiceModal.Provider>
        <external.ExternalRoot>
          <external.Version.Provider>
            <Routes>{RouteComponent_Statement()}</Routes>
          </external.Version.Provider>
        </external.ExternalRoot>
      </NiceModal.Provider>
    </BrowserRouter>
  );
}

export default AppRouter;
