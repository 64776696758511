import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ReportsPage } from "src/pages";
import { ReportPage } from "src/pages/ReportPage";
import { ReportsProvider } from "src/pages/ReportsPage/context/Reports.context";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/reports"
          element={
            <ReportsProvider>
              <ReportsPage />
            </ReportsProvider>
          }
        />
        <Route path="/reports/:id" element={<ReportPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
