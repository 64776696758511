import { useColumnContext } from ".."
import * as SC from "./styled"

export type HeaderProps = {
  children?: React.ReactNode
}
export const Header = ({ children }: HeaderProps) => {
  const { columnType } = useColumnContext()

  return <SC.HeaderWrapper columnType={columnType}>{children}</SC.HeaderWrapper>
}
