import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  ProductGroupTitleContainer,
  StyledPageContainer,
} from './cards-section.styles';

import { CardGroup, CardGroupProps } from './components/CardGroup';

export type CardsSectionProps = Array<{
  cardGroups: CardGroupProps[];
  categoryInfo?: {
    title: string;
    description: string;
  };
}>;

export const CardsSection = (props: { data: CardsSectionProps }) => {
  return (
    <StyledPageContainer>
      {props.data.map((item) => {
        if (item.cardGroups.every((cardGroup) => !cardGroup.cards.length)) {
          return null;
        }
        return (
          <>
            {item.categoryInfo && (
              <ProductGroupTitleContainer>
                <Typography
                  variantColor="var(--color-neutral-30)"
                  variant="headline7"
                  style={{ textAlign: 'center' }}
                >
                  {item.categoryInfo.title}
                </Typography>
                <Typography
                  variantColor="var(--color-neutral-30)"
                  variant="body4"
                  style={{ textAlign: 'center' }}
                >
                  {item.categoryInfo.description}
                </Typography>
              </ProductGroupTitleContainer>
            )}
            {item.cardGroups.map((cardGroup) => (
              <CardGroup {...cardGroup} />
            ))}
          </>
        );
      })}
    </StyledPageContainer>
  );
};
