import { colors } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  display: grid;
  grid-template-columns: 560px auto;
  gap: ${({ theme }) => theme.spacings.xs4};
  border-radius: ${({ theme }) => theme.borders.radius.l};
  background-color: ${colors.get('secondary.99')};

  @media screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const Content = styled.div`
  width: 100%;

  padding: ${({ theme }) => theme.spacings.s};
`;

export const HeaderContainer = styled.div`
  border-bottom: ${({ theme }) =>
    `${theme.borders.width.xs2} solid ${colors.get('secondary.95')}`};
  padding-bottom: ${({ theme }) => theme.spacings.s};
`;

export const Image = styled.img`
  width: 100%;
  max-width: 510px;
  height: auto;
  margin: auto;
`;
