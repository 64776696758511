import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CreditTypeEnum } from 'src/components/orders/components/pre-order-checkout-modal/content/credit-type'
import { useToast } from 'src/utils/hooks/useToast'

import { useEmployeeSelection } from '../../../steps/employee-selection/hooks/use-employee-selection'
import { Employee } from '../../../steps/employee-selection/types'
import { filterByCreditType } from '../../../steps/payment-setup/utils/filter-by-credit-type'
import { useNewOrderStore } from '../../../store/use-new-order-store'
import { groupBenefits } from '../utils/group-benefits'

export function useOrderSummary() {
  const { t } = useTranslation()
  const { setSelectedEmployees, selectedEmployees, isTopupCredit, creditDays } =
    useNewOrderStore()
  const { toastSuccess } = useToast()
  const { toggleEmployeeSelection } = useEmployeeSelection()

  const hasEmployeeSelected = useMemo(
    () => selectedEmployees.length > 0,
    [selectedEmployees.length],
  )

  const employeesWithoutAssign = useMemo(
    () =>
      selectedEmployees.filter((employee) => employee.benefits.length === 0),
    [selectedEmployees],
  )

  const filteredTopupEmployees = useMemo(
    () => filterByCreditType(selectedEmployees, CreditTypeEnum.TOPUP),
    [selectedEmployees],
  )

  const groupedBenefits = useMemo(() => {
    if (isTopupCredit) {
      return groupBenefits(filteredTopupEmployees, creditDays)
    }

    return groupBenefits(selectedEmployees, creditDays)
  }, [creditDays, filteredTopupEmployees, isTopupCredit, selectedEmployees])

  const total = useMemo(
    () => groupedBenefits.reduce((acc, benefit) => acc + benefit.amount, 0),
    [groupedBenefits],
  )

  const handleRemoveUnassignEmployees = useCallback(() => {
    const [employeesToUnselect, employeesToOrder] = selectedEmployees.reduce(
      ([employeeToUnselect, employeeToOrder], employee) => {
        const employees = employee.benefits.length
          ? employeeToOrder
          : employeeToUnselect

        employees.push(employee)

        return [employeeToUnselect, employeeToOrder]
      },
      [[] as Employee[], [] as Employee[]],
    )

    setSelectedEmployees(employeesToOrder)
    toastSuccess({
      title: t('newOrder.orderSummary.noEmployeeAssign.toastSuccess'),
    })

    employeesToUnselect.forEach(({ id }) => toggleEmployeeSelection(id))
  }, [
    selectedEmployees,
    setSelectedEmployees,
    toastSuccess,
    t,
    toggleEmployeeSelection,
  ])

  return {
    handleRemoveUnassignEmployees,
    employeesWithoutAssign,
    hasEmployeeSelected,
    groupedBenefits,
    total,
  }
}
