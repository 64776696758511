import { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useFormik } from "formik";

import { Grid } from "@mui/material";

import { PageTemplate } from "@components/PageTemplate";
import {
  ConfirmationModalV2,
  FirstFeedbackSentModal,
} from "@components/Modals";

import { routes } from "@routes";
import { trpc } from "@api/client";

import { SolicitationForm } from "./SolicitationForm";
import { SendFeedbackFormProps, validationSchema } from "./types";

import {
  StyledIcon,
  StyledText,
  StyledTitle,
  dispatchToast,
  getObjDiffs,
  getAccessTokenSync,
  track,
} from "@utils";

import { LeftContainer, MainContainer } from "./styled";

export const PageSendFeedback = () => {
  const navigate = useNavigate();

  const { requestId = "", _id = "", receiverId = "" } = useParams();
  const { pathname } = useLocation();

  const [isWithSaveOpen, setIsWithSaveOpen] = useState<boolean>(false);
  const [warningFeedback, setWarningFeedback] = useState<boolean>(false);
  const [isWithoutSaveOpen, setIsWithoutSaveOpen] = useState<boolean>(false);
  const [firstFeedbackOpen, setFirstFeedbackOpen] = useState<boolean>(false);
  const [removeAllCommsOpen, setRemoveAllCommsOpen] = useState<boolean>(false);

  const isManager = useMemo(
    () => !!pathname.match("/manage-feedbacks"),
    [pathname]
  );

  const routesBread = [
    {
      label: isManager ? "Gerenciar Feedbacks" : `Feedbacks`,
      route: isManager ? routes.PageManageFeedbacks : routes.PageMyFeedbacks,
    },
    {
      label: "Enviar novo feedback",
    },
  ];

  const {
    data: feedbackRequest,
    isFetching: requestLoading,
    isError: requestError,
  } = trpc.performance.feedback.getFeedbackRequestById.useQuery(
    { _id: requestId },
    {
      retry: false,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
      enabled: !!requestId,
      onError: (e: any) => {
        let message =
          "Erro ao buscar solicitação, favor tentar novamente mais tarde!";

        const notExistsError =
          e?.data?.error === "FEEDBACK_REQUEST_NOT_EXISTS_ERROR";

        if (notExistsError) message = "Solicitação de feedback não encontrada!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    }
  );

  const { data: employeeHierarchy, isLoading: employeeHierarchyLoading } =
    trpc.employee.getEmployeeHierarchy.useQuery(undefined, {
      retry: false,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    });

  const {
    data: feedback,
    isFetching: feedbackLoading,
    isError: feedbackError,
  } = trpc.performance.feedback.getFeedbackFull.useQuery(
    { _id },
    {
      retry: false,
      retryDelay: 3000,
      refetchOnWindowFocus: false,
      enabled: !!_id,
      onError: (e: any) => {
        let message =
          "Erro ao buscar feedback, favor tentar novamente mais tarde!";

        const notExistsError = e?.data?.error === "FEEDBACK_NOT_EXISTS_ERROR";

        if (notExistsError) message = "Feedback não encontrado!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    }
  );

  const { mutate: createDraft, isLoading: createDraftLoading } =
    trpc.performance.feedback.createFeedbackDraft.useMutation({
      onError: (e: any) => {
        let message =
          "Erro ao salvar rascunho, favor tentar novamente mais tarde!";

        const samePersonError = e?.data?.error === "FEEDBACK_SAME_PERSON_ERROR";
        const feedbackRequestNotExistsError =
          e?.data?.error === "FEEDBACK_REQUEST_NOT_EXISTS_ERROR";

        if (samePersonError)
          message = "Erro, destinatário é a mesma pessoa que está enviando!";

        if (feedbackRequestNotExistsError)
          message = "Erro, solicitação de feedback não encontrada!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  const { mutate: updateFeedback, isLoading: updateFeedbackLoading } =
    trpc.performance.feedback.updateFeedback.useMutation({
      onError: (e: any) => {
        let message =
          "Erro ao atualizar feedback, favor tentar novamente mais tarde!";

        const samePersonError = e?.data?.error === "FEEDBACK_SAME_PERSON_ERROR";
        const feedbackRequestNotExistsError =
          e?.data?.error === "FEEDBACK_REQUEST_NOT_EXISTS_ERROR";

        if (samePersonError)
          message = "Erro, destinatário é a mesma pessoa que está enviando!";

        if (feedbackRequestNotExistsError)
          message = "Erro, solicitação de feedback não encontrada!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  const { mutate: sendFeedback, isLoading: sendFeedbackLoading } =
    trpc.performance.feedback.sendFeedback.useMutation({
      onError: (e: any) => {
        let message =
          "Erro ao enviar feedback, favor tentar novamente mais tarde!";

        const samePersonError = e?.data?.error === "FEEDBACK_SAME_PERSON_ERROR";
        const feedbackRequestNotExistsError =
          e?.data?.error === "FEEDBACK_REQUEST_NOT_EXISTS_ERROR";

        if (samePersonError)
          message = "Erro, destinatário é a mesma pessoa que está enviando!";

        if (feedbackRequestNotExistsError)
          message = "Erro, solicitação de feedback não encontrada!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  const { mutate: sendFeedbackById, isLoading: sendFeedbackByIdLoading } =
    trpc.performance.feedback.sendFeedbackById.useMutation({
      onError: (e: any) => {
        let message =
          "Erro ao enviar feedback, favor tentar novamente mais tarde!";

        const samePersonError = e?.data?.error === "FEEDBACK_SAME_PERSON_ERROR";
        const feedbackRequestNotExistsError =
          e?.data?.error === "FEEDBACK_REQUEST_NOT_EXISTS_ERROR";

        if (samePersonError)
          message = "Erro, destinatário é a mesma pessoa que está enviando!";

        if (feedbackRequestNotExistsError)
          message = "Erro, solicitação de feedback não encontrada!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  const error = useMemo(
    () => requestError || feedbackError,
    [feedbackError, requestError]
  );

  const loading = useMemo(
    () => requestLoading || feedbackLoading || employeeHierarchyLoading,
    [requestLoading, feedbackLoading, employeeHierarchyLoading]
  );

  const formik = useFormik<SendFeedbackFormProps>({
    initialValues: {
      to: receiverId,
      type: "",
      requestMessage: "",
      message: "",
      evaluationId: "",
      companyValues: [],
      evaluationCriterials: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const isCreate = !!requestId || !_id;
      const isEdit = !!_id;

      const companyValues =
        values?.companyValues?.filter((c) => !!c.value) || [];

      const evaluationCriterials =
        values?.evaluationCriterials?.filter((e) => !!e.value) || [];

      if (isCreate) {
        sendFeedback(
          {
            requestId: requestId || undefined,
            to: formik.values.to,
            type: formik.values.type as any,
            message: formik.values.message,
            evaluationId: formik.values.evaluationId || undefined,
            companyValues: companyValues as any,
            evaluationCriterials: evaluationCriterials as any,
          },
          {
            onSuccess: () => {
              dispatchToast({
                type: "success",
                content: "Feedback enviado com sucesso!",
              });

              navigate(
                isManager ? routes.PageManageFeedbacks : routes.PageMyFeedbacks
              );
            },
          }
        );
      }

      if (isEdit) {
        const hasChangedAnyValue = getObjDiffs(values, {
          to: feedback?.feedback?.to?._id,
          type: feedback?.feedback?.type,
          message: feedback?.feedback?.message,
          evaluationId: feedback?.feedback?.evaluationId || "",
        });

        const hasCriterialChanged = getObjDiffs(
          { criterials: evaluationCriterials || [] },
          { criterials: feedback?.feedback?.evaluationCriterials || [] }
        );

        const isCompanyValuesEqual = companyValues.every((c) => {
          const companyValue = feedback?.feedback?.companyValues?.find(
            (value) => value._id === c._id
          );

          const isEqual = companyValue?.value === c.value;

          return companyValue && isEqual;
        });

        if (
          hasChangedAnyValue ||
          hasCriterialChanged ||
          !isCompanyValuesEqual
        ) {
          updateFeedback(
            {
              feedbackId: _id,
              params: {
                to: values.to,
                type: values.type as any,
                message: values.message || undefined,
                evaluationId: values.evaluationId || undefined,
                companyValues: companyValues as any,
                evaluationCriterials: evaluationCriterials as any,
              },
            },
            {
              onSuccess: () => {
                sendFeedbackById(
                  {
                    feedbackId: _id,
                  },
                  {
                    onSuccess: () => {
                      dispatchToast({
                        type: "success",
                        content: "Feedback enviado com sucesso!",
                      });

                      navigate(
                        isManager
                          ? routes.PageManageFeedbacks
                          : routes.PageMyFeedbacks
                      );
                    },
                  }
                );
              },
            }
          );

          return;
        }

        sendFeedbackById(
          {
            feedbackId: _id,
          },
          {
            onSuccess: () => {
              dispatchToast({
                type: "success",
                content: "Feedback enviado com sucesso!",
              });

              navigate(
                isManager ? routes.PageManageFeedbacks : routes.PageMyFeedbacks
              );
            },
          }
        );
      }
    },
  });

  useEffect(() => {
    if (!requestId) return;

    const accessToken = getAccessTokenSync();
    const requestFrom = feedbackRequest && feedbackRequest.from?._id;

    if (requestFrom && requestFrom !== accessToken?.employeeId) {
      dispatchToast({
        type: "error",
        content:
          "Solicitação de feedback vinculada a outro usuário, favor tentar novamente!",
      });

      navigate(isManager ? routes.PageManageFeedbacks : routes.PageMyFeedbacks);

      return;
    }

    if (feedbackRequest?.status === "sent") {
      dispatchToast({
        type: "error",
        content:
          "Solicitação de feedback já enviada, favor criar uma nova solicitação!",
      });

      navigate(isManager ? routes.PageManageFeedbacks : routes.PageMyFeedbacks);

      return;
    }

    formik.setValues({
      ...formik.values,
      type: feedbackRequest?.type || "",
      to: feedbackRequest?.to?._id || "",
      companyValues: feedbackRequest?.companyValues || [],
      requestMessage: feedbackRequest?.message || "",
    });
  }, [feedbackRequest]);

  useEffect(() => {
    if (!_id) return;

    const accessToken = getAccessTokenSync();
    const feedbackFrom = feedback?.feedback && feedback.feedback?.from?._id;

    if (feedbackFrom && feedbackFrom !== accessToken?.employeeId) {
      dispatchToast({
        type: "error",
        content: "Feedback vinculado a outro usuário, favor tentar novamente!",
      });

      navigate(isManager ? routes.PageManageFeedbacks : routes.PageMyFeedbacks);

      return;
    }

    if (["answered", "unanswered"].includes(feedback?.feedback?.status || "")) {
      dispatchToast({
        type: "error",
        content: "Feedback já enviado, favor iniciar um novo!",
      });

      navigate(isManager ? routes.PageManageFeedbacks : routes.PageMyFeedbacks);

      return;
    }

    formik.setValues({
      type: feedback?.feedback.type || "",
      to: feedback?.feedback?.to?._id || "",
      companyValues: feedback?.feedback?.companyValues || [],
      requestMessage: feedback?.request?.message || "",
      evaluationId: feedback?.feedback?.evaluationId || "",
      evaluationCriterials: feedback?.feedback?.evaluationCriterials || [],
      message: feedback?.feedback?.message || "",
    });
  }, [feedback]);

  const validateMandatoryFields = () => {
    let isFilled = true;

    if (!formik.values.to) {
      formik.setFieldTouched("to", true);
      isFilled = false;
    }

    if (!formik.values.type) {
      formik.setFieldTouched("type", true);
      isFilled = false;
    }

    return isFilled;
  };

  const saveAsDraft = () => {
    const isValid = validateMandatoryFields();

    if (!isValid) return;

    const isEdit = !!_id;

    const companyValues =
      formik.values?.companyValues?.filter((c) => !!c.value) || [];

    const evaluationCriterials =
      formik.values?.evaluationCriterials?.filter((e) => !!e.value) || [];

    if (isEdit) {
      const hasChangedAnyValue = getObjDiffs(formik.values, {
        to: feedback?.feedback?.to?._id,
        type: feedback?.feedback?.type,
        message: feedback?.feedback?.message,
        evaluationId: feedback?.feedback?.evaluationId || "",
      });

      const hasCriterialChanged = getObjDiffs(
        { criterials: evaluationCriterials || [] },
        { criterials: feedback?.feedback?.evaluationCriterials || [] }
      );

      const isCompanyValuesEqual = companyValues.every((c) => {
        const companyValue = feedback?.feedback?.companyValues?.find(
          (value) => value._id === c._id
        );

        const isEqual = companyValue?.value === c.value;

        return companyValue && isEqual;
      });

      if (!hasChangedAnyValue && !hasCriterialChanged && isCompanyValuesEqual) {
        setIsWithSaveOpen(false);
        navigate(
          isManager ? routes.PageManageFeedbacks : routes.PageMyFeedbacks
        );

        return;
      }

      updateFeedback(
        {
          feedbackId: _id,
          params: {
            to: formik.values.to,
            type: formik.values.type as any,
            message: formik.values.message || undefined,
            evaluationId: formik.values.evaluationId || undefined,
            companyValues: companyValues as any,
            evaluationCriterials: evaluationCriterials as any,
          },
        },
        {
          onSuccess: () => {
            setIsWithSaveOpen(false);
            navigate(
              isManager ? routes.PageManageFeedbacks : routes.PageMyFeedbacks
            );
          },
        }
      );
    } else {
      createDraft(
        {
          requestId: requestId || undefined,
          to: formik.values.to,
          type: formik.values.type as any,
          message: formik.values.message || undefined,
          evaluationId: formik.values.evaluationId || undefined,
          companyValues: companyValues as any,
          evaluationCriterials: evaluationCriterials as any,
        },
        {
          onSuccess: () => {
            setIsWithSaveOpen(false);
            navigate(
              isManager ? routes.PageManageFeedbacks : routes.PageMyFeedbacks
            );
          },
        }
      );
    }
  };

  const isEmployee = useMemo(
    () => !isManager && !employeeHierarchy?.isLeader,
    [employeeHierarchy, isManager]
  );

  useEffect(() => {
    isEmployee &&
      formik.handleChange({
        target: { id: "type", value: "voluntary" },
      });
  }, [isEmployee]);

  const sendOrUpdatedLoading =
    sendFeedbackByIdLoading || sendFeedbackLoading || updateFeedbackLoading;

  return (
    <PageTemplate
      routes={routesBread}
      footer={{
        cancelProps: {
          disabled: false,
          title: "Sair sem salvar",
          callback: () => {
            track({
              name: "performance_myfeedbacks_sendfeedback_exitwithoutsaving_clicked",
            });
            setIsWithoutSaveOpen(true);
          },
        },
        skipProps: {
          disabled: error || loading,
          title: "Sair e salvar rascunho",
          callback: () => {
            track({
              name: "performance_myfeedbacks_sendfeedback_exitandsavedraft_clicked",
            });
            const isFilled = validateMandatoryFields();
            if (isFilled) setIsWithSaveOpen(true);
          },
        },
        confirmProps: {
          disabled: error || loading || sendOrUpdatedLoading,
          title: (
            <>
              Enviar feedback
              <StyledIcon name="IconCheck" fill="transparent" />
            </>
          ),
          width: "250px",
          loading: sendOrUpdatedLoading,
          callback: async () => {
            track({
              name: "performance_myfeedbacks_sendfeedback_sendfeedback_clicked",
            });

            const errors = await formik.validateForm();

            if (Object.keys(errors).length) {
              dispatchToast({
                type: "error",
                content: "Favor preencher todos os campos obrigatórios!",
              });

              formik.handleSubmit();
              return;
            }

            setWarningFeedback(true);
          },
        },
      }}
    >
      <MainContainer>
        <StyledTitle
          setColor="neutral20"
          variant="headline6"
          children={"Enviar novo feedback"}
        />
      </MainContainer>
      <Grid container paddingBottom={"40px"} spacing={2}>
        <Grid
          item
          sm={12}
          md={5}
          lg={4}
          paddingRight={"24px"}
          style={{ width: "100%" }}
        >
          <LeftContainer>
            <StyledTitle
              setColor="secondary50"
              variant="headline7"
              children={"Configurações"}
              style={{ paddingBottom: "16px" }}
            />
            <StyledText
              setColor="neutral50"
              variant="body3"
              children={
                "Envie um feedback a um colega. Configure os aspectos avaliados e o conteúdo do feedback."
              }
              style={{ paddingBottom: "16px" }}
            />
          </LeftContainer>
        </Grid>
        <Grid item sm={12} md={7} lg={8} style={{ width: "100%" }}>
          <SolicitationForm
            loading={loading}
            isFromRequest={!!requestId || !!feedback?.request?._id}
            isCompanyValuesMandatory={
              !!feedbackRequest?.companyValues?.length ||
              !!feedback?.request?.companyValues?.length
            }
            formik={formik}
            hasReceiver={!!receiverId}
            isEmployee={isEmployee}
          />
        </Grid>
      </Grid>

      <ConfirmationModalV2
        open={warningFeedback}
        closeButton
        icon="IconInfoCircle"
        iconTitle="Atenção!"
        title="Tem certeza que deseja enviar o feedback?"
        subTitle={
          <StyledText variant="body4" setColor="neutral40">
            Os feedbacks corporativos não são anônimos, portanto o destinatário
            poderá ver quem os enviou.
            <br />
            <br />O <b>participante verá todos os feedbacks</b> após o envio,
            por isso, certifique-se de que o conteúdo seja construtivo e ajude
            no crescimento do colaborador.
          </StyledText>
        }
        cancel={{ title: "Voltar" }}
        confirm={{
          title: "Enviar feedback",
        }}
        isLoading={sendFeedbackLoading}
        onClose={() => setWarningFeedback(false)}
        onConfirm={() => formik.handleSubmit()}
        surveyId="modal_send_feedback_survey"
      />
      <ConfirmationModalV2
        open={isWithSaveOpen}
        closeButton
        icon="IconInfoCircle"
        title="Deseja sair e salvar o progresso do feedback?"
        subTitle="O conteúdo será salvo como rascunho e você poderá editar mais tarde."
        cancel={{ title: "Cancelar" }}
        confirm={{
          title: "Sair e salvar rascunho",
          icon: "IconArrowRight",
          iconColor: "neutral100",
        }}
        isLoading={createDraftLoading || updateFeedbackLoading}
        onClose={() => setIsWithSaveOpen(false)}
        onConfirm={() => saveAsDraft()}
      />
      <ConfirmationModalV2
        open={isWithoutSaveOpen}
        closeButton
        variantType="error"
        icon="IconAlertCircle"
        title="Deseja sair sem salvar o feedback?"
        subTitle="Você perderá todas as configurações do feedback."
        cancel={{ title: "Cancelar" }}
        confirm={{
          title: "Sair sem salvar",
        }}
        onClose={() => setIsWithoutSaveOpen(false)}
        onConfirm={() =>
          navigate(
            isManager ? routes.PageManageFeedbacks : routes.PageMyFeedbacks
          )
        }
      />
      <FirstFeedbackSentModal
        open={firstFeedbackOpen}
        isLoading={false}
        onClose={() => setFirstFeedbackOpen(false)}
        onConfirm={() => setFirstFeedbackOpen(false)}
        surveyId="modal_send_feedback_survey"
      />
      <ConfirmationModalV2
        open={removeAllCommsOpen}
        closeButton
        iconTitle="Atenção!"
        variantType="error"
        icon="IconAlertCircle"
        title="Tem certeza que deseja limpar e excluir todos os comentários?"
        subTitle="Todas as informações serão perdidas."
        cancel={{ title: "Cancelar" }}
        confirm={{
          title: "Limpar comentários",
          icon: "IconTrash",
        }}
        onClose={() => setRemoveAllCommsOpen(false)}
        onConfirm={() => console.log("remover todos os comentários")}
      />
    </PageTemplate>
  );
};
