import { isIncluded } from '@corporate-card/ts-utils/helper-functions/includes';
import Icon from '@frontend/components/Icon';
import AuthService from '@frontend/services/AuthService';
import FeatureFlagService from '@frontend/services/FeatureFlagService';
import { toCurrency } from '@frontend/utils/masks';
import { DateTime } from 'luxon';
import { UseFormReturn } from '../../../../controllers/form/useForm';
import useGetOrder from './useGetOrder';

type Input = {
  form: UseFormReturn;
};

export function useSummaryData(input: Input) {
  const formValues = input.form.watch();
  const result = input.form.result;
  const user = AuthService.useUser();

  const orderId =
    result?.deposit?.status === 'SUCCESS' ? result.deposit.orderId : undefined;
  const paymentMethod = formValues.paymentMethod;
  const orderData = useGetOrder({ orderId, paymentMethod });

  return {
    selectedPeople: formatEmployeeList(),
    /** Presentable details of the optional deposit (null if not requested) */
    deposit: depositDetails(),
    /** Downloadable link for PIX and Billet payments' PDF */
    downloadPdf: downloadPdf(),
    pixCode: orderData?.pixCode,
    barcode: orderData?.barcode,
  };

  /* ---------------------------- Selected people --------------------------- */
  function formatEmployeeList() {
    if (!formValues.deposits.length) return { formattedAsText: '' } as const;

    if (formValues.deposits.length === 1) {
      return {
        formattedAsText: formValues.deposits[0].name,
      } as const;
    }

    return {
      formattedAsText:
        `${formValues.deposits[0].name}, ${formValues.deposits[1].name}` +
        (formValues.deposits.length > 2
          ? ` + ${formValues.deposits.length - 2} pessoas`
          : ''),
    } as const;
  }

  /* -------------------------------- Deposit ------------------------------- */
  function depositDetails() {
    if (!result?.deposit || result.deposit.status === 'FAILED')
      return { status: 'FAILED' } as const;
    return {
      status: 'SUCCESS',
      creditDateDescription: creditDateDescription(),
      depositType: depositType(),
      depositAmount: {
        totalAmount: totalAmount(),
      },
      paymentDetails: {
        paymentStatus: paymentStatus(),
        paymentMethod: selectedPaymentMethod(),
      },
    } as const;
  }

  function creditDateDescription() {
    const creditDate = formValues.creditDate
      ? DateTime.fromJSDate(formValues.creditDate, {
          zone: 'America/Sao_Paulo',
        }).startOf('day')
      : null;

    if (!creditDate?.isValid) return null;

    return formValues.automatic
      ? `Todo dia ${creditDate.toFormat(
          'dd',
        )}, a partir de ${creditDate.toFormat('dd/MM/yyyy')}`
      : creditDate.toFormat('dd/MM/yyyy');
  }

  function depositType() {
    if (formValues.automatic) {
      return formValues.complementary
        ? 'Depósito automático complementar'
        : 'Depósito automático';
    }
    return formValues.expires
      ? 'Depósito único com expiração de saldo'
      : 'Depósito único';
  }

  function totalAmount() {
    const total = formValues.deposits.reduce(
      (total, deposit) => total + deposit.value,
      0,
    );
    return `${toCurrency(total)} total`;
  }

  function paymentStatus() {
    if (formValues.paymentMethod === 'FLASH_CASH') {
      return {
        label: 'Depósito agendado',
        variant: 'success',
      } as const;
    }
    return {
      label: 'Pagamento pendente',
      variant: 'primary',
    } as const;
  }

  function selectedPaymentMethod() {
    const paymentMethod = formValues.paymentMethod;
    switch (paymentMethod) {
      case 'FLASH_CASH':
        return {
          label: 'Flash Cash Corporativo',
          icon: 'IconWallet' satisfies IconName,
        } as const;
      case 'BILLET':
        return {
          label: 'Boleto',
          icon: 'BarcodeMethod' satisfies IconName,
        } as const;
      case 'PIX':
        return {
          label: 'Pix',
          icon: 'Pix' satisfies IconName,
        } as const;
      default:
        return null;
    }
  }

  /* ----------------------------- Download PDF ----------------------------- */
  function downloadPdf() {
    const featureFlag = FeatureFlagService.getFlag('displayCode');
    const paymentMethod = formValues.paymentMethod;
    if (!isIncluded(paymentMethod, ['BILLET', 'PIX'])) return null;
    const documentName = paymentMethod === 'BILLET' ? 'boleto' : 'código Pix';

    if (orderData.isError)
      return {
        disabled: true,
        isLoading: false,
        label: 'Confira seu e-mail',
        onClick: () => {
          void orderData.refetch();
        },
        emailText: `O ${documentName} foi enviado para o e-mail`,
        email: user?.email ?? 'cadastrado em seu usuário',
      };
    if (featureFlag) {
      return {
        disabled: false,
        isLoading: orderData.isLoading,
        label: null,
        onClick: null,
        emailText: `O ${documentName} foi enviado para o e-mail`,
        email: user?.email ?? 'cadastrado em seu usuário',
      };
    }
    return {
      disabled: false,
      isLoading: orderData.isLoading,
      label: paymentMethod === 'BILLET' ? 'Baixar Boleto' : 'Baixar código Pix',
      onClick: () => {
        window.open(orderData.pdfUrl, '_blank');
      },
      emailText: `O ${documentName} foi enviado para o e-mail`,
      email: user?.email ?? 'cadastrado em seu usuário',
    };
  }
}

type IconName = React.ComponentProps<typeof Icon>['name'];
