import styled from "styled-components";

export type FlexContainerStyleProps = {
  orientation: "horizontal" | "vertical";
};

export const FlexContainer = styled.div<FlexContainerStyleProps>`
  display: flex;
  flex-direction: ${(props) => (props.orientation === "horizontal" ? "row" : "column")};
  gap: ${(props) => props.theme.spacings.xs3};
`;
