import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import {
  Checkbox as CheckboxMui,
  CheckboxProps as CheckboxPropsMui,
  useTheme,
} from "@mui/material";

export type CheckboxProps = { variant?: "circular" } & CheckboxPropsMui;

export const CheckboxInternal = ({
  checked,
  onChange,
  defaultChecked,
  inputProps,
  indeterminate,
  disabled,
  sx,
  onBlur,
  onFocus,
  className,
  required,
  disableRipple,
  disableTouchRipple,
  disableFocusRipple,
  inputRef,
}: CheckboxProps) => {
  const theme = useTheme();

  return (
    <CheckboxMui
      checked={checked}
      onChange={onChange}
      defaultChecked={defaultChecked}
      inputProps={inputProps}
      indeterminate={indeterminate}
      disabled={disabled}
      required={required}
      disableRipple={disableRipple}
      disableTouchRipple={disableTouchRipple}
      disableFocusRipple={disableFocusRipple}
      inputRef={inputRef}
      sx={sx}
      onBlur={onBlur}
      onFocus={onFocus}
      checkedIcon={
        <Icons
          name="CheckboxCheckedIcon"
          size={24}
          color={disabled ? "#D1C7CE" : theme.colors.primary}
        />
      }
      icon={<Icons name="CheckboxIcon" size={24} />}
      className={`${className} checkbox-custom-theme`}
    />
  );
};
