import { Table } from '@flash-tecnologia/hros-web-ui-v2';
import { PaginationState } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination';
import {
  HeaderGroup,
  RowData,
} from '@flash-tecnologia/hros-web-ui-v2/dist/components/Table/shared/table.types';

type RowInput<TData extends RowData> = React.ComponentProps<
  typeof Table.Grid.Row<TData>
>;

type Props<TData extends RowData> = {
  actionMenuOnClick?: RowInput<TData>['actionMenuOnClick'];
  count?: number;
  loading: boolean;
  emptyState?: {
    emptyText: string;
    filteredEmptyText: string;
    isFiltered: boolean;
  };
  getHeaderGroups: () => HeaderGroup<TData>[];
  search?: JSX.Element;
  TableFilters?: React.ComponentProps<typeof Table.Filters>['filters'];
  pagination?: PaginationState;
  onPaginationChange?: (_: PaginationState) => void;
  rows: Readonly<Array<RowInput<TData>['row']>>;
  /**
   * Table size
   * @default modest
   */
  variant?: 'soft' | 'modest';
};

export default function BaseTable<TData extends RowData>(props: Props<TData>) {
  return (
    <Table.Root variant={props.variant}>
      {props.search && props.search}
      {!!props.TableFilters?.length && (
        <Table.Filters label="Filtrar por:" filters={props.TableFilters} />
      )}
      <Table.Grid.Root
        loading={props.loading}
        empty={{
          isFiltered: props.emptyState?.isFiltered,
          message:
            (props.emptyState?.isFiltered
              ? props.emptyState?.filteredEmptyText
              : props.emptyState?.emptyText) || '',
        }}
      >
        <Table.Grid.Header getHeaderGroups={props.getHeaderGroups} />
        {props.rows.map((row) => (
          <Table.Grid.Row
            key={row.id}
            row={row}
            actionMenuOnClick={props.actionMenuOnClick}
          />
        ))}
      </Table.Grid.Root>
      {props.pagination && !!props.count && (
        <Table.Pagination
          count={props.count}
          onPaginationChange={(pagination) =>
            props.onPaginationChange?.(pagination)
          }
          pagination={{
            pageNumber: props.pagination.pageNumber,
            pageSize: props.pagination.pageSize,
          }}
        />
      )}
    </Table.Root>
  );
}
