export class BilletUtilities {
  public getFormattedLine(barcode: string): string {
    const {
      agency,
      bank,
      account,
      generalVerifyingDigit,
      currency,
      ourNumber,
      amount,
      dueDate,
      F_1,
      F_2,
    } = this.parseBarcode(barcode);

    const field_1 = bank + currency + F_1 + agency.slice(0, 4);
    const vd_1 = this.getFieldVerifyingDigit(field_1);

    const field_2 = agency.slice(4, 5) + account;
    const vd_2 = this.getFieldVerifyingDigit(field_2);

    const field_3 = ourNumber + F_2;
    const vd_3 = this.getFieldVerifyingDigit(field_3);

    const field_4 = generalVerifyingDigit + dueDate + amount;
    const rawLine = field_1 + vd_1 + field_2 + vd_2 + field_3 + vd_3 + field_4;
    const formattedLine = `${rawLine.slice(0, 5)}.${rawLine.slice(
      5,
      10,
    )} ${rawLine.slice(10, 15)}.${rawLine.slice(15, 21)} ${rawLine.slice(
      21,
      26,
    )}.${rawLine.slice(26, 32)} ${rawLine.slice(32, 33)} ${rawLine.slice(33)}`;
    return formattedLine;
  }

  private getFieldVerifyingDigit(field: string): string {
    const sum = [...field]
      .reverse()
      .reduce((sum, currentValue, currentIndex) => {
        let aux = parseInt(currentValue) * (((currentIndex + 1) % 2) + 1);
        if (aux >= 10) aux = Math.trunc(aux / 10) + (aux % 10);
        sum = sum + aux;
        return sum;
      }, 0);
    const remainder = sum % 10;
    const verifyingDigit = remainder ? 10 - remainder : 0;
    return verifyingDigit.toString();
  }

  private parseBarcode(barcode: string): any {
    const bank = barcode.slice(0, 3);
    const currency = barcode.slice(3, 4);
    const generalVerifyingDigit = barcode.slice(4, 5);
    const dueDate = barcode.slice(5, 9);
    const amount = barcode.slice(9, 19);
    const F_1 = barcode.slice(19, 20);
    const agency = barcode.slice(20, 25);
    const account = barcode.slice(25, 34);
    const ourNumber = barcode.slice(34, 43);
    const F_2 = barcode.slice(43, 44);

    return {
      agency,
      bank,
      account,
      generalVerifyingDigit,
      currency,
      ourNumber,
      amount,
      dueDate,
      F_1,
      F_2,
    };
  }
}
