import { Icons, IconTypes } from '@flash-tecnologia/hros-web-ui-v2';
import { InboxMessageWithGroup } from '../../types';
import { IconContainer, IconDot } from './style';

export const Icon = ({
  type,
  acknowledged,
}: Pick<InboxMessageWithGroup, 'type' | 'acknowledged'>) => {
  let iconName: IconTypes = 'IconInfoSquareRounded';
  switch (type) {
    case 'info':
      iconName = 'IconInfoSquareRounded';
      break;
    case 'news':
      iconName = 'IconSpeakerphone';
      break;
    case 'partners':
      iconName = 'IconDiscountCheck';
      break;
    case 'security':
      iconName = 'IconShieldLock';
      break;
    case 'transactions':
      iconName = 'IconCreditCard';
      break;
    default:
      break;
  }
  return (
    <IconContainer>
      {!acknowledged ? <IconDot /> : null}
      <Icons name={iconName} color="var(--color-secondary-50)" />
    </IconContainer>
  );
};
