import { PillButton } from '@components/PillButton';
import styled from 'styled-components';

export const Button = styled(PillButton).attrs(() => ({
  size: 'xs',
}))`
  :hover {
    position: absolute;
    right: 0;
  }
`;
