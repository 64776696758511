import { createContext } from "react";
import { useCategory } from "../hooks/useCategoryData";
import { CategorySettingsContextProps } from "../types/category-settings.context";

export const CategorySettingsContext = createContext<CategorySettingsContextProps>({
  isLoading: false,
  isFetching: false,
  categories: [],
  refetch: async () => undefined,
});

export interface ISessionProviderProps {
  children: React.ReactNode;
}

export const CategorySettingsProvider = ({ children }: ISessionProviderProps) => {
  const { rows, isLoading, isFetching, refetch } = useCategory();

  return (
    <CategorySettingsContext.Provider
      value={{
        isLoading: isLoading,
        isFetching: isFetching,
        categories: rows,
        refetch: refetch,
      }}
    >
      {children}
    </CategorySettingsContext.Provider>
  );
};
