import { Typography } from '@flash-tecnologia/hros-web-ui-v2'

export interface ITextTitle {
  children: string
}

export const TextTitle: React.FC<ITextTitle> = (props) => {
  return (
    <Typography variant="headline8" color="neutral.20">
      {props.children}
    </Typography>
  )
}
