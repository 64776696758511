import { useEffect, useState } from 'react';

import { TableActions } from './components/TableActions';
import { TableGrid } from './components/TableGrid';
import { TableHeader } from './components/Header';
import { TableSearch } from './components/Search';
import { ActionsContainer, TableContainer } from './styled';
import { ITableProps } from '../../types';
import { FilterStatusEnum, StatusEnum } from '@/common/types/StatusEnum';
import { debounce } from 'lodash';

export interface Filters {
  status: StatusEnum[] | FilterStatusEnum[] | null;
}

export const Table = ({
  companyId,
  fetchEmployees,
  totalEmployees,
  loading,
  employees,
  filters,
  setFilters,
}: ITableProps) => {
  const [searchState, setSearchState] = useState('');

  const setDebouncedSearchState = debounce((value: string) => {
    setSearchState(value);
  }, 300);

  const [paginationState, setPaginationState] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const handlePageChange = ({
    pageNumber,
    pageSize,
  }: {
    pageNumber?: number;
    pageSize?: number;
  }) => {
    setPaginationState((prevState) => ({
      pageNumber: pageNumber || prevState.pageNumber,
      pageSize: pageSize || prevState.pageSize,
    }));
  };

  const handleFiltersChange = <TFilter extends keyof Filters>(
    filterName: TFilter,
    value: Filters[TFilter],
  ) => {
    setFilters((prev) => ({
      ...prev,
      [filterName]: value,
    }));
  };

  useEffect(() => {
    handlePageChange({ pageNumber: 1 });
  }, [searchState, filters]);

  useEffect(() => {
    fetchEmployees({
      companyId,
      pageNumber: paginationState.pageNumber,
      pageSize: paginationState.pageSize,
      status: filters.status! as any,
      search: searchState,
    });
  }, [searchState, filters, paginationState, companyId]);

  return (
    <TableContainer>
      <TableHeader totalCount={totalEmployees} loading={loading} />
      <TableSearch onChange={setDebouncedSearchState} />
      <ActionsContainer>
        <TableActions onChange={handleFiltersChange} filters={filters} />
      </ActionsContainer>
      <div data-onboarding="employee-management-employees-table">
        <TableGrid
          totalCount={totalEmployees}
          data={employees}
          loading={loading}
          pagination={paginationState}
          onPagination={setPaginationState}
        />
      </div>
    </TableContainer>
  );
};
