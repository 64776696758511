import { PageContainer as DSPageContainer } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledPageContainer = styled(DSPageContainer)`
  display: flex;
  flex-direction: column;
`;
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
  padding-bottom: ${({ theme }) => theme.spacings.xl5};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.xs};
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.borders.radius.m};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: ${({ theme }) => theme.spacings.xs2};
  cursor: pointer;
`;

export const ButtonContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const ButtonTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs5};
`;
