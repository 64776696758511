import styled from 'styled-components';

type Props = {
  direction: 'horizontal' | 'vertical';
};

export function Divider(props: Props) {
  return <SCDivider {...props} />;
}

const SCDivider = styled.div<Props>`
  width: ${({ direction }) => (direction === 'horizontal' ? '100%' : '1px')};
  height: ${({ direction }) => (direction === 'horizontal' ? '1px' : 'auto')};
  min-height: ${({ direction }) => (direction === 'horizontal' ? 'unset' : '100%')};
  background-color: ${({ theme }) => theme.colors.neutral[90]};
`;
