import Flex from '@frontend/components/frames/Flex';
import styled from 'styled-components';

export const Img = styled.img`
  width: '350px';
  border-radius: '12px';
`;

export const Box = styled(Flex)`
  width: 100%;
`;
