import { Dot } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

type ReimbursementStatusCardProps = {
  itemCount?: number;
  width?: string;
};

export const ReimbursementStatusCardContainer = styled.div<ReimbursementStatusCardProps>`
  display: flex;
  flex-wrap: wrap;
  width: ${(props) => props.width || "25%"};
  padding: ${(props) => props.theme.spacings.xs2} ${(props) => props.theme.spacings.xs1};
  min-height: 106px;
  border-right: 1px solid ${(props) => props.theme.colors.neutral[90]};

  /*
  TODO: This layout code needs to be refactored, we'll leave it like this for now
  */

  &:nth-child(4n) {
    border-right: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.xl}px) {
    width: 50%;
    border-bottom: 1px solid ${(props) => props.theme.colors.neutral[90]};

    &:nth-child(2n) {
      border-right: none;
    }

    &:nth-child(3n) {
      border-bottom: none;
    }

    &:nth-child(4n) {
      border-bottom: none;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    width: 100%;
    border-right: none;

    &:nth-child(3n) {
      border-bottom: 1px solid ${(props) => props.theme.colors.neutral[90]};
    }
  }
`;

export const ReimbursementStatusCardText = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: ${(props) => props.theme.spacings.xs4};
`;

export const Circle = styled(Dot)<{ margin?: string; size?: number }>`
  margin: ${(props) => props.margin || 0};
  width: ${(props) => (props.size ? `${props.size}px` : "auto")};
  height: ${(props) => (props.size ? `${props.size}px` : "auto")};
`;
