import styled from 'styled-components';

export const SecondaryBannerInnerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const SecondaryBannerContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 70%;
  padding: ${({ theme }) => theme.spacings.xs2};
  align-items: flex-start;
`;

export const BannerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: ${({ theme }) => theme.spacings.xs3};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  position: relative;
  overflow: hidden;
`;
