import { trpc } from '@api/client';

export const useGetFeed = (input: { cursor: string; limit: number }) => {
  const { isLoading, data } = trpc.getFeed.useQuery(
    {
      cursor: input.cursor,
      limit: input.limit,
    },
    {
      refetchOnWindowFocus: false,
      meta: {
        errorMessage: 'Erro ao buscar suas notificações',
      },
    },
  );

  return {
    data,
    isLoading,
  };
};
