import { StyledBox, StyledLink, StyledText, StyledTitle } from "./styled";

const ContractBox = ({ contractId = "", url, segment }) => {
  return (
    <StyledBox>
      <StyledTitle style={{ fontWeight: 700 }} variant="body3">
        Seu contrato
      </StyledTitle>
      <div>
        <StyledTitle
          style={{
            fontWeight: 700,
            display: "block",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "100px",
          }}
          variant="body3"
        >
          {contractId}
        </StyledTitle>
        <StyledText variant="body4">Código do contrato</StyledText>
      </div>
      <StyledLink
        variant="default"
        onClick={() => {
          segment();
          window.location.href = url;
        }}
      >
        Visualizar contrato
      </StyledLink>
    </StyledBox>
  );
};

export { ContractBox };
