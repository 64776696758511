import { dispatchToast } from "./dispatch-events";

type DisplayToastProps = {
  type?: "error" | "warning" | "success";
  title: string;
  description?: string;
};

export function useDisplayToast() {
  function displayToast({ type = "success", title, description }: DisplayToastProps) {
    dispatchToast({ content: title, description, type });
  }

  return { displayToast };
}
