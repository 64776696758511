import { getFromLS } from '@flash-tecnologia/hros-web-utility';
import AuthService from '@services/AuthService';

export function useVersion(feature: Feature) {
  const company = AuthService.useCompany();

  const key = `corporate-card-feature-versions-${company?.id}`;

  const versions = getFromLS(key);

  return {
    isNewVersion: versions?.[feature] ?? false,
  };
}
