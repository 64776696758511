import {
  Icons,
  TextField as DsTextField,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const MainContainer = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 535px;
  gap: ${({ theme }) => theme.spacings.m};
`;

export const ConfirmationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacings.m};

  @media screen and (min-width: 781px) {
    margin-bottom: ${({ theme }) => theme.spacings.m};
  }
`;

export const TermText = styled.span`
  color: ${({ theme }) => theme.colors.secondary[50]};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 781px) {
    padding-bottom: ${({ theme }) => theme.spacings.m};
  }
`;

export const TextField = styled(DsTextField)`
  .MuiFilledInput-root {
    // it replace the "transition: all 0.1s ease-in-out;" from ds
    // to avoid the focus pushing other elements
    // TODO: remove after fixing textfield cls in ds
    transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
  }
`;

export const StyledInput = styled(TextField)`
  margin-top: ${({ theme }) => theme.spacings.xs};
`;

export const RequiredText = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacings.xs2};
`;

export const HelperTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs5};
  padding-left: ${({ theme }) => theme.spacings.xs2};
`;

export const StyledIcon = styled(Icons)`
  && {
    color: ${({ theme }) => theme.colors.feedback.error.pure};
  }
`;

export const ErrorText = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.feedback.error.pure};
    font-size: 12px;
    font-weight: 700;
  }
`;
