import { NotificationCard as DSNotificationCard, IconsProps } from "@flash-tecnologia/hros-web-ui-v2";
import { ReactNode } from "react";

type TypeList = "info" | "error" | "success" | "gray";
type VariantList = "contained" | "outlined";

type NotificationCardProps = {
  type: TypeList;
  variant: VariantList;
  iconName: IconsProps["name"];
  isVertical?: boolean;
  showCloseButton?: boolean;
  autoClose?: boolean;
  onClose?: () => void;
  children: ReactNode;
};

export const NotificationCard = ({
  type,
  variant,
  iconName,
  isVertical,
  showCloseButton,
  autoClose,
  onClose,
  children,
}: NotificationCardProps) => {
  return (
    <DSNotificationCard.Root
      variant={variant}
      type={type}
      isVertical={isVertical}
      showCloseButton={showCloseButton}
      autoClose={autoClose}
      onClose={onClose}
    >
      <DSNotificationCard.Icon iconName={iconName} />
      <DSNotificationCard.WrapperTexts>
        <DSNotificationCard.Title>{children}</DSNotificationCard.Title>
      </DSNotificationCard.WrapperTexts>
    </DSNotificationCard.Root>
  );
};
