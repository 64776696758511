export type DateRange = {
  from: Date;
  to: Date;
};

const MILLISECONDS_IN_A_MONTH = 30 * 24 * 60 * 60 * 1000;

/** Gets a daterange starting `months` ago (default 3 months) */
export function getDateRange(months?: number) {
  return {
    from: new Date(new Date().getTime() - (months ?? 3) * MILLISECONDS_IN_A_MONTH),
    to: new Date(),
  } satisfies DateRange;
}
