import { LazyComponent } from '@components/LazyComponent';
import { PermissionsGuard } from '@components/PermissionGuard';
import { ROUTE_BalanceManagement } from '@pages/external';
import RouterService from '@services/RouterService';
import { Navigate, Route } from 'react-router-dom';

import {
  RouteComponent_CardStatements,
  RouteComponent_CardStatements_Index,
} from './CardStatements';
import { RouteComponent_FailedMovements } from './FailedMovements';
import { useVersion } from './hooks/useVersion';
import { RouteComponent_WalletStatements } from './WalletStatements/index';

function LazyPage() {
  return <LazyComponent factory={() => import('./Statement')} />;
}

export const ROUTE_Statement = RouterService.create(`${RouterService.BASE_PATH}`);

export function RouteComponent_Statement() {
  const statementVersion = useVersion('accountManagement');

  function resolveStatementElement() {
    if (statementVersion.isNewVersion) {
      return <LazyPage />;
    }

    return <Navigate to={ROUTE_BalanceManagement.buildPath({})} />;
  }

  return (
    <Route
      path={ROUTE_Statement.path}
      element={
        <PermissionsGuard
          permissions={[
            'expense_manage_corporate_card_balances',
            'expense_manage_corporate_cards',
          ]}>
          {resolveStatementElement()}
        </PermissionsGuard>
      }>
      {RouteComponent_CardStatements_Index}
      {RouteComponent_CardStatements}
      {RouteComponent_WalletStatements}
      {RouteComponent_FailedMovements}
    </Route>
  );
}
