import { DatePicker, SelectField, ShapeIcon, TextField, Typography } from "@flash-tecnologia/hros-web-ui-v2"; //TODO passar pelo styled ou criar componente
import { useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { useTheme } from "styled-components";

import { TextArea } from "$atoms";
import {
  CategorySelect,
  EstablishmentSelect,
  ExpenseAmountTrailingContainer,
  InfoCard,
  InfoCardStatus,
  ReceiptDropzone,
} from "$molecules";
import { ExpenseFormDto } from "$pages";
import { ExpenseStatus } from "$serverTypes";
import { Card, CardTile, Container, Form, FormCurrencyAmountContainer } from "./styled";

type ReimbursementFormProps = {
  methods: UseFormReturn<ExpenseFormDto, Record<string, unknown>>;
  expenseStatus?: ExpenseStatus;
};

export const ReimbursementForm = ({ methods, expenseStatus }: ReimbursementFormProps) => {
  const { t } = useTranslation("translations", {
    keyPrefix: "molecules.reimbursementForm",
  });

  const { colors } = useTheme();
  const [infoCardData, setInfoCardData] = useState({
    category: "",
    establishment: t("establishment"),
    amount: t("amount"),
  });

  const handleFormChange = (name: string, value: string) => {
    setInfoCardData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Container>
      <Controller
        control={methods.control}
        name="attachments"
        rules={{ required: true }}
        render={({ field }) => <ReceiptDropzone onChange={field.onChange} value={field.value ?? []} />}
      />
      <Card>
        <CardTile bottomBorder={true}>
          <InfoCard
            fullWidth={true}
            leading={<ShapeIcon variant={"neutral"} stroke={"neutral"} name="IconReceipt" size={40} />}
            title={
              <Typography variant="caption" variantColor={colors.neutral[40]}>
                {methods.getValues().category?.description ?? t("category")}
              </Typography>
            }
            subTitle={
              <Typography weight={700} variant="headline8" variantColor={colors.neutral[30]}>
                {infoCardData.establishment}
              </Typography>
            }
            trailing={
              <ExpenseAmountTrailingContainer>
                <InfoCard
                  fullWidth={true}
                  alignItems="flex-end"
                  title={
                    <Typography variant="caption" variantColor={colors.neutral[40]}>
                      {t("expenseAmount")}
                    </Typography>
                  }
                  subTitle={
                    <Typography weight={700} variant="headline8" variantColor={colors.neutral[30]}>
                      {infoCardData.amount}
                    </Typography>
                  }
                />
              </ExpenseAmountTrailingContainer>
            }
          />
          <InfoCardStatus status={expenseStatus ?? ExpenseStatus.DRAFT} />
        </CardTile>
        <Form>
          <FormCurrencyAmountContainer>
            <SelectField
              id="currency"
              disabled={true}
              value="BRL"
              options={[
                {
                  label: "R$ (BRL)",
                  value: "BRL",
                },
              ]}
              fullWidth={true}
              label={t("fields.currency")}
            />
            <Controller
              control={methods.control}
              name="amount"
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <NumericFormat
                  allowNegative={false}
                  error={fieldState.invalid}
                  prefix={"R$"}
                  customInput={TextField}
                  fullWidth={true}
                  required={false}
                  label={t("fields.amount")}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                  onFocus={(event) => event.target.select()}
                  defaultValue={field.value}
                  value={Number(field.value / 100).toFixed(2)}
                  onValueChange={(values) => {
                    if (values.floatValue) {
                      const floatValue = Math.trunc(values.floatValue * 100);
                      field.onChange(floatValue);
                    } else {
                      field.onChange(undefined);
                    }
                    handleFormChange("amount", values.formattedValue !== "" ? values.formattedValue : "R$0,00");
                  }}
                />
              )}
            />
          </FormCurrencyAmountContainer>
          <Controller
            control={methods.control}
            name="establishment"
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <EstablishmentSelect
                error={fieldState.invalid}
                value={field.value?.placeAutocomplete}
                onChange={(placeAutocomplete, sessionToken) => {
                  return field.onChange({
                    sessionToken,
                    placeAutocomplete: { ...placeAutocomplete },
                  });
                }}
              />
            )}
          />

          <Controller
            control={methods.control}
            name="date"
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              return (
                <DatePicker
                  id="date"
                  //NOTE: This is to force re-render when date is undefined
                  error={fieldState.invalid}
                  key={field.value ? "date" : "date-empty"}
                  onDateChange={(date) => {
                    if (date) {
                      field.onChange(date.toDate());
                    }
                  }}
                  label={t("fields.date")}
                  value={field.value}
                  fullWidth={true}
                />
              );
            }}
          />
          <Controller
            control={methods.control}
            rules={{ required: true }}
            name="category"
            render={({ field, fieldState }) => (
              <CategorySelect
                error={fieldState.invalid}
                onChange={(category) => {
                  field.onChange({
                    nanoId: category.id,
                    description: category.name,
                  });
                  handleFormChange("category", category.name);
                }}
                value={field.value?.nanoId ?? ""}
              />
            )}
          />
          <Controller
            control={methods.control}
            name="comments"
            render={({ field }) => (
              <TextArea
                id="description"
                aria-pressed
                placeholder={t("fields.description")}
                resizable
                maxLength={500}
                onChange={field.onChange}
                value={field.value ?? ""}
              />
            )}
          />
        </Form>
      </Card>
    </Container>
  );
};
