import { useTranslation } from "react-i18next";

import { AlertIconCircle } from "$frontend/components/molecules/ActionModal/styled";
import { Alignment } from "$frontend/utils/enum";
import { DangerActionModal } from "$molecules";
import { ExpenseType } from "$serverTypes";
import { Container, LabelButtonContent } from "./styled";

type ConfirmationModalEditExpenseProps = {
  /** If 'true' open the modal */
  isOpen: boolean;
  /** If 'true' the button shows loading status */
  isLoading?: boolean;
  /** Action event */
  onActionClick(): void;
  /** Close event */
  onCloseClick(): void;
  /** Expense type */
  type: ExpenseType;
  /** Hide modal subtitle  */
  hideSubtitle?: boolean;
  /** Include extra title warning  */
  extraTitle?: boolean;
  /** Is user admin (default false) */
  isAdmin?: boolean;
};

export const ConfirmationModalEditExpense = ({
  isOpen,
  isLoading,
  onActionClick,
  onCloseClick,
  type,
  isAdmin = false,
  hideSubtitle,
  extraTitle,
}: ConfirmationModalEditExpenseProps) => {
  const { t } = useTranslation("translations", { keyPrefix: `organisms.modals.confirmationModalEditExpense.${type}` });

  return (
    <Container>
      <DangerActionModal
        iconPosition={Alignment.left}
        headerIcon={<AlertIconCircle />}
        isLoading={isLoading}
        headerIconLabel={t("attention")}
        headerTitle={t("title")}
        headerSubtitle={hideSubtitle ? undefined : t(isAdmin ? "adminSubtitle" : "subtitle")}
        labelButtonAction={<LabelButtonContent>{t("confirm")}</LabelButtonContent>}
        onActionClick={onActionClick}
        onCancelClick={onCloseClick}
        onCloseClick={onCloseClick}
        open={isOpen}
      />
    </Container>
  );
};
