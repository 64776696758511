import styled from "styled-components";

export const ReimbursementContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.neutral[90]};
  border-radius: ${(props) => props.theme.spacings.xs4};
  border-radius: 0 0 ${(props) => props.theme.spacings.xs4} ${(props) => props.theme.spacings.xs4};
  overflow: hidden;
`;
