import React from "react"
import { StepsEnum, massSignatureContext } from "../../../MassSignature"
import { useNavigate } from "react-router-dom"
import { Container } from "./styles"
import { Signature, SignatureRef } from "../../../../../components"
import dispatchToast from "../../../../../utils/dispatchToast"
import { useQueryClient } from "@tanstack/react-query"
import { globalContext } from "../../../../../context"
import { uploadFile } from "../../../../../utils"
import { trpc } from "@api/client"

export const MassSignatureSignature = () => {
  const [isSigning, setIsSigning] = React.useState<boolean>(false)

  const { validCandidates, setFooter, setCurrentStep, confirmationData } =
    React.useContext(massSignatureContext)
  const { companyId, employeeId } = React.useContext(globalContext)

  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const signatureRef = React.useRef<SignatureRef>(null)

  const { mutateAsync: signManyContracts } =
    trpc.massSignature.signManyContracts.useMutation()

  React.useEffect(() => {
    setFooter({
      cancelButton: {
        text: "Sair sem salvar",
        onClick: () => navigate("/flows/hiring"),
      },
      secondaryButton: {
        text: "Voltar",
        icon: "IconArrowLeft",
        iconPosition: "left",
        onClick: () => setCurrentStep(StepsEnum.confirmation),
      },
      primaryButton: {
        text: "Continuar",
        icon: "IconArrowRight",
        iconPosition: "right",
        onClick: handleSubmit,
        disabled: isSigning,
        loading: isSigning,
      },
    })
  }, [signatureRef, isSigning])

  async function handleSubmit() {
    setIsSigning(true)
    try {
      const file = await signatureRef?.current?.getSignatureFile()
      if (!file) {
        dispatchToast({
          content: "Por favor preencha a assinatura",
          type: "error",
        })
        setIsSigning(false)
        return
      }

      const folder = `${companyId + "/mass-signature/" + employeeId}`
      const uploadedFile = await uploadFile(
        folder,
        "company_signature",
        file,
        "employee-pii",
      )

      const contractsSigned = await signManyContracts({
        flowCardIds: validCandidates.map((c) => c.flowCardId),
        signatureFile: {
          key: uploadedFile.key,
          path: uploadedFile.path,
          origin: "internal",
        },
        confirmationInfo: confirmationData,
      })

      queryClient.cancelQueries(["flows"])
      navigate("/flows/hiring")
      dispatchToast({
        content: `${contractsSigned.contractsSigned} assinaturas realizadas com sucesso!`,
        type: "success",
      })
    } catch (err: any) {
      setIsSigning(false)
      dispatchToast({
        content: err?.message ?? "Algo inesperado aconteceu",
        type: "error",
      })
    }
  }

  return (
    <Container>
      <Signature ref={signatureRef} />
    </Container>
  )
}
