import { IconButton, Modal } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: ${({ theme }) => theme.spacings.xs3};
  margin-top: -10px;
`;

export const Content = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs1};
  padding: ${({ theme }) =>
    `${theme.spacings.xs4} ${theme.spacings.m} ${theme.spacings.xs}`};

  @media screen and (max-height: 768px) {
    max-height: 600px;
    overflow: auto;
  }
`;
