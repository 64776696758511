import { Icons, IconsProps } from '@flash-tecnologia/hros-web-ui-v2';
import { IconContainer } from './round-icon.styles';

export const RoundIcon = (props: {
  iconName: IconsProps['name'];
  size: number;
  iconColor: string;
  backgroundColor: string;
}) => (
  <IconContainer style={{ backgroundColor: props.backgroundColor }}>
    <Icons
      name={props.iconName}
      size={props.size}
      color={props.iconColor}
      style={{ flexShrink: 0 }}
    />
  </IconContainer>
);
