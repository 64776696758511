import styled from "styled-components";

export const HeaderContainer = styled.div`
  margin: 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  @media screen and (min-width: 768px) {
    gap: 0;
    flex-direction: row;
  }
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
`;

export const Subtitle = styled.span`
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral[50]};
`;
