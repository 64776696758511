import styled from 'styled-components'

export const ContainerStyled = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;

  height: calc(100vh - 110px);
`

export const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;

  overflow-y: auto;

  gap: ${({ theme }) => theme.spacings.xs};
  margin-bottom: ${({ theme }) => theme.spacings.xs};
`

export const FooterStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral[100]};

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-top: ${({ theme }) => theme.borders.width.xs2} solid
    ${({ theme }) => theme.colors.neutral[90]};

  padding-left: ${({ theme }) => theme.spacings.m};
  padding-top: ${({ theme }) => theme.spacings.xs3};
  padding-right: ${({ theme }) => theme.spacings.m};
  padding-bottom: ${({ theme }) => theme.spacings.xs3};
`
