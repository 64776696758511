import { IconsProps } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import Icon from '@frontend/components/Icon';
import Typography from '@frontend/components/Typography';

type Props = {
  icon: IconsProps['name'];
  children: string;
};

export function FeatureHighlight(props: Props) {
  return (
    <Flex gap="xs5" align="center">
      <Icon
        name={props.icon}
        size={24}
        background="default"
        color="secondary_50"
      />

      <Typography.Caption color="neutral_40" weight={600}>
        {props.children}
      </Typography.Caption>
    </Flex>
  );
}
