import { Breadcrumbs, Icons } from "@flash-tecnologia/hros-web-ui-v2"; //TODO passar pelo styled ou criar componente
import { Link } from "react-router-dom";

import { BreadcrumbContainer, BreadcrumbTypography } from "./styled";

export type BreadcrumbItem = {
  to?: string;
  label: string;
};

type BreadcrumbPageProps = {
  items: BreadcrumbItem[];
};

export const BreadcrumbPage = ({ items }: BreadcrumbPageProps) => {
  return (
    <BreadcrumbContainer>
      <Breadcrumbs
        breadcrumbs={items.map((item, i) => (
          <div key={i}>
            {item.to ? (
              <Link to={item.to}>
                <BreadcrumbTypography>{item.label}</BreadcrumbTypography>
              </Link>
            ) : (
              <BreadcrumbTypography>{item.label}</BreadcrumbTypography>
            )}
          </div>
        ))}
        separator={<Icons name="IconChevronRight" fill={"transparent"} />}
      />
    </BreadcrumbContainer>
  );
};
