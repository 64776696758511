import { Skeleton } from "@mui/material";

import * as SC from "./styled";

export interface CategoryListSwitchCardSkeletonProps {
  printItems?: number;
}

export const CategoriesListLoadingSkeleton = ({ printItems = 10 }: CategoryListSwitchCardSkeletonProps) => {
  return (
    <SC.Container>
      {new Array(printItems).fill({}).map((_, key) => (
        <Skeleton key={key} height={56} width={"100%"} variant="rounded" />
      ))}
    </SC.Container>
  );
};
