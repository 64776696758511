import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 10px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #015fcb;
  background: #d4e8ff;
`;
