import { ThemesType } from '@flash-tecnologia/hros-web-ui-v2';
import useColors, { Color } from '@frontend/utils/theme/colors';
import { ComponentPadding, makePadding } from '@frontend/utils/theme/paddings';
import React from 'react';
import styled from 'styled-components';

type Props = {
  /** align-items */
  align?: 'start' | 'end' | 'center' | 'space-around' | 'space-between';
  /** flex-direction */
  direction?: 'row' | 'column';
  /** Gap between children
   * @default 0
   *
   * Options:
   *   - xs5: '4px'
   *   - xs4: '8px'
   *   - xs3: '12px'
   *   - xs2: '16px'
   *   - xs1: '20px'
   *   - xs: '24px'
   *   - s: '32px'
   *   - m: '40px'
   *   - l: '64px'
   *   - xl5: '80px'
   *   - xl4: '96px'
   *   - xl3: '120px'
   *   - xl2: '144px'
   *   - xl: '160px'
   */
  gap?: keyof ThemesType['spacings'];
  /** justify-content */
  justify?: 'start' | 'end' | 'center' | 'space-around' | 'space-between';
  /** padding */
  padding?: ComponentPadding;
  /** Custom styles */
  style?: React.CSSProperties;
  /** wrap */
  wrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  /** Children */
  children: React.ReactNode;
  /** Flex-grow */
  grow?: number;
  /** background-color */
  backgroundColor?: Color;
  /** border-width */
  borderWidth?: keyof ThemesType['borders']['width'];
  /** border-color */
  borderColor?: Color;
  /** border-radius */
  radius?: keyof ThemesType['borders']['radius'];
  /** height in pixels */
  height?: string;
};

const StyledFlex = styled.div<Props>(({ theme, ...props }) => {
  const colors = useColors();

  return {
    alignItems: props.align,
    display: 'flex',
    flexDirection: props.direction,
    flexWrap: props.wrap,
    gap: props.gap ? theme.spacings[props.gap] : 0,
    justifyContent: props.justify,
    padding: props.padding ? makePadding(theme, props.padding) : 0,
    flex: props.grow,
    ...(props.borderWidth && {
      border: `${theme.borders.width[props.borderWidth]} solid ${props.borderColor ?? theme.colors.neutral[90]}`,
    }),
    borderRadius: props.radius ? theme.borders.radius[props.radius] : undefined,
    backgroundColor: props.backgroundColor
      ? colors[props.backgroundColor]
      : undefined,
    borderColor: props.borderColor ? colors[props.borderColor] : undefined,
    height: props.height,
  };
});

const Flex = React.forwardRef<HTMLDivElement, Props>((props, ref) => (
  <StyledFlex ref={ref} {...props} />
));

Flex.displayName = 'Flex';

export default Flex;
