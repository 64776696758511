import Flex from '@frontend/components/frames/Flex';
import { cpfMask } from '@frontend/utils/masks';

import Icon from '@frontend/components/Icon';
import { SelectEmployees } from '@frontend/components/SelectEmployees';
import Typography from '@frontend/components/Typography';
import { Controller } from 'react-hook-form';
import { useCreateVirtualCardContext } from '../../../data/useCreateVirtualCardContext';

export function SelectEmployeesFormSection() {
  const context = useCreateVirtualCardContext();

  return (
    <>
      <Typography.Headline8 as="h2" color="neutral_20">
        Para quem você deseja criar um cartão virtual?
      </Typography.Headline8>

      <Flex direction="column" gap="xs3">
        <Controller
          control={context.form.control}
          name="employeeIds"
          render={(ctx) => (
            <SelectEmployees
              value={ctx.field.value}
              onChange={(newValue) => ctx.field.onChange(newValue)}
              errorMessage={ctx.fieldState.error?.message}
              employees={context.employees}
              renderEmployeeDescription={(user) => cpfMask(user.documentNumber)}
            />
          )}
        />

        <Flex align="center" gap="xs5">
          <Icon name="IconInfoCircle" size={12} color="neutral_40" />

          <Typography.Caption color="neutral_40">
            Cada pessoa selecionada terá um novo cartão virtual com as mesmas
            configurações.
          </Typography.Caption>
        </Flex>
      </Flex>
    </>
  );
}
