import { BannersLayout1 } from './components/Layout1';
import { BannersLayout2 } from './components/Layout2';
import { BannersLayout3 } from './components/Layout3';
import { BannerProps } from './components/banners-section.dto';

export interface BannersSectionProps {
  banners: BannerProps[];
}

type BannerLayout1Props = [BannerProps];
type BannerLayout2Props = [BannerProps, BannerProps];
type BannerLayout3Props = [BannerProps, BannerProps, BannerProps];

function isBannerLayout1Props(
  banners: BannersSectionProps['banners'],
): banners is BannerLayout1Props {
  return banners.length === 1;
}

function isBannerLayout2Props(
  banners: BannersSectionProps['banners'],
): banners is BannerLayout2Props {
  return banners.length === 2;
}

function isBannerLayout3Props(
  banners: BannersSectionProps['banners'],
): banners is BannerLayout3Props {
  return banners.length === 3;
}

export const BannersSection = (props: BannersSectionProps) => {
  if (isBannerLayout1Props(props.banners)) {
    return <BannersLayout1 banners={props.banners} />;
  }
  if (isBannerLayout2Props(props.banners)) {
    return <BannersLayout2 banners={props.banners} />;
  }
  if (isBannerLayout3Props(props.banners)) {
    return <BannersLayout3 banners={props.banners} />;
  }
  return null;
};
