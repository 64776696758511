import { useAddEmployees } from '../../hooks/use-add-employees'
import { CardRadioOptions } from '../card-radio-options'

export function ReactivateEmployees() {
  const { reactivateEmployees, handleReactivateEmployees } = useAddEmployees()

  return (
    <CardRadioOptions
      title="O que fazer com cadastros inativos"
      description="Defina o que fazer se uma ou mais pessoas da planilha estiverem desativadas dentro do grupo econômico."
      options={[
        {
          title: 'Não fazer nada',
          description: 'Manter o cadastro da pessoa inativo.',
          icon: 'IconWashDrycleanOff',
          checked: !reactivateEmployees,
          onClick: handleReactivateEmployees,
        },
        {
          title: 'Reativar cadastro das pessoas',
          description:
            'Essas pessoas terão o cadastro reativado e permanecerão vinculadas às empresas onde estão atualmente e terão seus dados atualizados com os dados presentes na planilha. Para editar os demais dados, é necessário atualizar via fluxo de edição de informações da pessoa na página de Equipe.',
          icon: 'IconUserExclamation',
          checked: reactivateEmployees,
          onClick: handleReactivateEmployees,
        },
      ]}
    />
  )
}
