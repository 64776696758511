import { colors, ThemesType } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

type SpacingToken = keyof ThemesType['spacings'] | 'none';
type RadiusToken = keyof ThemesType['borders']['radius'];
type BorderWidth = keyof ThemesType['borders']['width'];

type BaseProp<T extends string> =
  | T
  | `${T} ${T}`
  | `${T} ${T} ${T}`
  | `${T} ${T} ${T} ${T}`;

type Radius = BaseProp<RadiusToken>;
type Spacing = BaseProp<SpacingToken>;

type Padding = Spacing;
type Margin = Spacing;
type Gap = Spacing;

type Color = Parameters<typeof colors.get>[number];
type Align = 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'space-between';

type Props = {
  width?: React.CSSProperties['width'];
  /** display */
  display?: 'grid' | 'flex';
  /** flex-direction */
  flexDirection?: 'row' | 'column';
  /** grid-template-columns */
  gridTemplateColumns?: string;
  /** gap */
  gap?: Gap;
  /** align items */
  alignItems?: Align;
  /** justify-content */
  justifyContent?: Align;
  /** padding */
  padding?: Padding;
  /** margin */
  margin?: Margin;
  /** border-radius */
  radius?: Radius;
  /** border-width */
  borderWidth?: BorderWidth;
  /** border-color */
  borderColor?: Color;
  /** background-color */
  backgroundColor?: Color;
  children: React.ReactNode;
};

function makeSpacing(theme: ThemesType, spacing?: Spacing) {
  return spacing
    .split(' ')
    .map(token => theme.spacings[token] ?? 0)
    .join(' ');
}

function makeRadius(theme: ThemesType, radius?: Radius) {
  return radius
    .split(' ')
    .map(token => theme.borders.radius[token] ?? 0)
    .join(' ');
}

const BaseBox = styled.div<Props>(props => ({
  ...(props.width && { width: props.width }),
  ...(props.display && { display: props.display }),
  ...(props.flexDirection && { flexDirection: props.flexDirection }),
  ...(props.gridTemplateColumns && { gridTemplateColumns: props.gridTemplateColumns }),
  ...(props.gap && { gap: makeSpacing(props.theme, props.gap) }),
  ...(props.alignItems && { alignItems: props.alignItems }),
  ...(props.justifyContent && { justifyContent: props.justifyContent }),
  ...(props.padding && {
    padding: makeSpacing(props.theme, props.padding),
  }),
  ...(props.margin && {
    margin: makeSpacing(props.theme, props.margin),
  }),
  ...(props.radius && {
    borderRadius: makeRadius(props.theme, props.radius),
    overflow: 'hidden',
  }),
  ...(props.borderWidth && {
    borderWidth: props.theme.borders.width[props.borderWidth],
  }),
  ...(props.borderColor && {
    borderColor: colors.get(props.borderColor),
  }),
  ...(props.backgroundColor && {
    backgroundColor: colors.get(props.backgroundColor),
  }),
}));

function Box(props: React.ComponentProps<typeof BaseBox>) {
  return <BaseBox {...props} />;
}

function Bordered(props: Props) {
  return (
    <Box
      gap="xs3"
      padding="xs3 xs2"
      borderWidth="xs2"
      borderColor="neutral.90"
      radius="m"
      {...props}
    />
  );
}

Box.Bordered = Bordered;

export { Box };
