import { IconTypes, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { Grid } from 'src/components/grid'
import { RadioCard } from 'src/components/radio-card'
import { RadioCardTextTypeEnum } from 'src/components/radio-card/radio-card-text/radio-card-text'
import { useTheme } from 'styled-components'

type CardRadioOptionsType = {
  title: string
  description: string
  options?: {
    title: string
    description: string
    icon: IconTypes
    checked: boolean
    onClick: () => void
  }[]
}

export function CardRadioOptions({
  title,
  description,
  options,
}: CardRadioOptionsType) {
  const theme = useTheme()
  return (
    <Grid.CardGrid flexDirection="column" gap={theme.spacings.xs}>
      <Grid.Container gap={theme.spacings.xs5}>
        <Typography variant="headline8" weight={700} color="neutral.30">
          {title}
        </Typography>
        <Typography variant="body3" weight={400} color="neutral.50">
          {description}
        </Typography>
      </Grid.Container>
      {options &&
        options?.map(
          ({ title, description, icon, checked, onClick }, index) => (
            <RadioCard.Root
              checked={checked}
              onClick={onClick}
              disabled={false}
              key={index}
            >
              <RadioCard.Radio checked={checked} disabled={false} />
              <Grid.Container
                flexDirection="row"
                alignItems="center"
                gap={theme.spacings.s}
                minWidth="auto"
                alignContent="center"
                marginRight={theme.spacings.xs1}
              >
                <Grid.Item>
                  <RadioCard.Text
                    checked={checked}
                    disabled={false}
                    text={title}
                    textVariant="body3"
                    type={RadioCardTextTypeEnum.TITLE}
                  />
                  <RadioCard.Text
                    checked={checked}
                    disabled={false}
                    text={description}
                    textVariant="body4"
                  />
                </Grid.Item>
              </Grid.Container>
              <Grid.Container width="auto" minWidth="auto">
                <RadioCard.Icon
                  variant="pink"
                  size="medium"
                  icon={icon}
                  type="primary"
                />
              </Grid.Container>
            </RadioCard.Root>
          ),
        )}
    </Grid.CardGrid>
  )
}
