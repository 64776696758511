import { IconTypes, PillButton } from '@flash-tecnologia/hros-web-ui-v2'

type Variant = 'default' | 'success' | 'info' | 'error' | 'pink'
type Size = 'extra-small' | 'small' | 'medium' | 'large'
type Type = 'primary' | 'secondary'

export interface IRadioCardIcon {
  variant: Variant
  icon: IconTypes
  type: Type
  size?: Size
}

const RadioCardIcon: React.FC<IRadioCardIcon> = ({
  variant,
  icon,
  type,
  size = 'medium',
}) => <PillButton variant={variant} size={size} icon={icon} type={type} />

export default RadioCardIcon
