import { BrowserRouter, Routes, Route } from "react-router-dom";
import { InitialPage, CheckoutPageV2, RedirectPage } from "../pages";
import { PermissionsRoutesGuard } from "./permissionsRoutesGuard";
import { OpenRoutesGuard } from "./openRoutesGuard";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<OpenRoutesGuard />}>
          <Route path="/people/acquisition" element={<InitialPage />} />

          <Route
            path="/people/acquisition/redirect"
            element={<RedirectPage />}
          />
        </Route>
        <Route element={<PermissionsRoutesGuard />}>
          <Route
            path="/people/acquisition/checkout"
            element={<CheckoutPageV2 />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
