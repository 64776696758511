import { trpc } from '@api/client';

export const useGetUnacknowledgeInboxMessages = () => {
  const { isLoading, data } = trpc.getUnacknowledgedInboxMessages.useQuery(
    undefined,
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    data,
    isLoading,
  };
};
