import { useTranslation } from "react-i18next"
import { generatePath, useNavigate } from "react-router-dom"

import { Flow, ResignationCard } from "../../../../types"
import ProfilePicture from "../../ProfilePicture"

import {
  CandidateContainer,
  IconWrapper,
  InfoHeader,
  ProfileTitle,
} from "./styles"

import { IconButton } from "@flash-tecnologia/hros-web-ui-v2"

import { useMutation, useQueryClient } from "@tanstack/react-query"
import { externalRoutes } from "src/routes/supportedExternalRoutes"
import { request } from "../../../../api/client"
import { RESIGNATION_MOVE_COLUMN } from "../../../../api/mutations/resignation-card-move-column"
import dispatchToast from "../../../../utils/dispatchToast"
import { ModalConfirmation } from "@components/common/ModalConfirmation"
import { useState } from "react"
import { resignationColumnsId } from "src/mock/board"
import { CandidateInfoList, InfoWrapper } from "./CandidateInfoList"

const copyToClipboard = (value: string) => navigator.clipboard.writeText(value)

const ResignationInformationTab = ({
  card,
  flow,
}: {
  card: ResignationCard
  flow: Flow
}) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const [isArchiveModalOpen, setArchiveModalOpen] = useState(false)

  const { mutate: moveColumn, isLoading: isMoveColumnLoading } = useMutation(
    async ({ params }: { params: any }) => {
      await request(RESIGNATION_MOVE_COLUMN, { params })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries()
      },
    },
  )

  const redirect = "/flows/resignation"

  const employeeInfo: React.ComponentProps<typeof CandidateInfoList>["data"] = [
    {
      value: card.employee?.name || "Não cadastrado",
      text: t("personalInfo.name"),
      icon: "IconUser",
      onClick: () => copyToClipboard(card.employee?.name ?? ""),
      buttonIcon: "IconCopy",
    },
    {
      value:
        card.employee?.corporateEmail ||
        card.employee?.email ||
        "Não cadastrado",
      text: t("personalInfo.email"),
      icon: "IconMail",
      onClick: () =>
        copyToClipboard(
          (card.employee?.corporateEmail || card.employee?.email) ?? "",
        ),
      buttonIcon: "IconCopy",
    },
    {
      value:
        card.employee?.phoneNumber?.replace(
          /(\d{2})(\d{2})(\d{5})(\d{4})/,
          "+$1 ($2) $3-$4",
        ) || "Não cadastrado",
      text: t("personalInfo.phone"),
      icon: "IconPhone",
      onClick: () =>
        window.open(
          `https://api.whatsapp.com/send?phone=${card.employee?.phoneNumber?.replace(/\W+/g, "")}`,
          "_blank",
        ),
      disableButton: !card.employee?.phoneNumber,
      buttonIcon: "IconBrandWhatsapp",
    },
    {
      value:
        card.employee?.documentNumber?.replace(
          /(\d{3})(\d{3})(\d{3})(\d{2})/,
          "$1.$2.$3-$4",
        ) || "Não cadastrado",
      text: t("personalInfo.cpf"),
      icon: "IconId",
      onClick: () => copyToClipboard(card.employee?.documentNumber ?? ""),
      buttonIcon: "IconCopy",
    },
  ]

  const archiveCandidate = () => {
    if (!flow.columns) {
      dispatchToast({
        content: "Houve um problema ao arquivar o candidato",
        type: "warning",
      })
      return
    }
    const { _id: archivedColumn } =
      flow.columns.find((column) => column.name === "Arquivado") || {}

    if (!archivedColumn) {
      dispatchToast({
        content: "Houve um problema ao arquivar o candidato",
        type: "warning",
      })
      return
    }

    moveColumn(
      {
        params: {
          flowCardId: card._id,
          newColumnId: archivedColumn,
          newPosition: 0,
          version: card.version,
        },
      },
      {
        onSuccess: () => {
          dispatchToast({
            content: "Candidato arquivado com sucesso!",
            type: "success",
          })
          queryClient.invalidateQueries()
          navigate(redirect)
        },
      },
    )
  }

  const handleEditCandidate = () => {
    navigate(
      generatePath(externalRoutes.employeesProfile.path, {
        employeeId: card.employee?.id,
      }),
    )
  }

  const hideArchiveButton =
    resignationColumnsId.archived.includes(card.columnId) ||
    resignationColumnsId.done.includes(card.columnId)

  return (
    <CandidateContainer>
      <InfoHeader>
        <ProfilePicture
          userName={card.employee?.name || "Não cadastrado"}
          src={card.employee?.profilePicture}
          alt="Foto de perfil do usuario"
          style={{ marginTop: 30 }}
        />

        <ProfileTitle>{card.employee?.name}</ProfileTitle>

        <IconWrapper>
          <IconButton
            icon="IconPencil"
            variant="line"
            size="small"
            onClick={handleEditCandidate}
            style={{ margin: "0 5px" }}
          />
          {!hideArchiveButton && (
            <IconButton
              icon="IconArchive"
              variant="line"
              size="small"
              onClick={() => setArchiveModalOpen(true)}
              style={{ margin: "0 5px" }}
            />
          )}
          <IconButton
            icon="IconShare"
            variant="line"
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(window.location.href)
              dispatchToast({
                type: "success",
                content: "Link do candidato copiado",
              })
            }}
            style={{ margin: "0 5px" }}
          />
        </IconWrapper>
      </InfoHeader>

      <InfoWrapper>
        <CandidateInfoList data={employeeInfo} />
      </InfoWrapper>
      <ModalConfirmation
        isOpen={isArchiveModalOpen}
        isLoading={isMoveColumnLoading}
        variant="error"
        title={t("resignation.modalConfirmArchive.title")}
        description={t("resignation.modalConfirmArchive.description")}
        cancelLabel={t("resignation.modalConfirmArchive.button.cancel")}
        confirmLabel={t("resignation.modalConfirmArchive.button.submit")}
        confirmIcon="IconArchive"
        onClose={() => setArchiveModalOpen(false)}
        onConfirm={async () => archiveCandidate()}
      />
    </CandidateContainer>
  )
}

export default ResignationInformationTab
