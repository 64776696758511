import React, { useState } from "react"

import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { CandidateMenu, Commentary, StatusCard } from "../../common"
import CardChecklist from "../Checklist/CardChecklist"

import { Flow, FlowCategory, ResignationCard } from "../../../types"

import {
  Container,
  HeaderContainer,
  BodyContainer,
  HeaderWrapper,
  ContentWrapper,
  MenuWrapper,
  Content,
  ActionsWrapper,
  ChecklistPillButton,
  ResetPillButton,
} from "./styles"
import { ModalExportCandidateData } from "./ModalExportCandidateData"
import { resignationColumnsId } from "../../../mock/board"
import { getColumnStatusOptions } from "../../../mock/status"
import { ResetStepConfirmationModal } from "./ModalResetStep"

interface CardTemplate {
  card: ResignationCard
  flow: Flow
  children: React.ReactNode
}

type ResetStepState = {
  canReset: boolean
  isOpen: boolean
  confirmCallback?: () => Promise<void> | void
  isLoading?: boolean
}

export interface IResignationCardContext {
  setResetStep(params: Partial<ResetStepState>): void
}

export const resignationCardContext =
  React.createContext<IResignationCardContext>({
    setResetStep: () => {},
  })

const ResignationCardTemplate = ({ card, flow, children }: CardTemplate) => {
  const [modalExportData, setModalExportData] = useState<boolean>(false)
  const [displayChecklist, setDisplayChecklist] = useState<boolean>(true)
  const [resetStep, setResetStep] = useState<ResetStepState>({
    canReset: false,
    isOpen: false,
  })

  const columnInfo = flow.columns?.find(
    (column) => column._id === card.columnId,
  )
  const columnStatus = getColumnStatusOptions(card.columnId)
  const statusInfo = columnStatus.status.find(
    (status) => status.name === card.status,
  )

  if (!columnInfo) return null

  const inactivesChecklistColumns = [
    ...resignationColumnsId.done,
    ...resignationColumnsId.archived,
  ]
  const hasChecklist = !inactivesChecklistColumns.includes(card.columnId)
  const hasResetStep = [
    ...resignationColumnsId.exam,
    ...resignationColumnsId.signature,
  ].includes(card.columnId)

  const handleSetResetStep = (params: Partial<ResetStepState>) => {
    setResetStep((prev) => ({
      ...prev,
      ...params,
    }))
  }

  const contextValue: IResignationCardContext = {
    setResetStep: handleSetResetStep,
  }

  return (
    <Container>
      <HeaderContainer>
        <HeaderWrapper>
          <Typography variant="body3" style={{ color: "#83727D" }}>
            Fase atual
          </Typography>
          <Typography variant="headline5" style={{ fontWeight: 700 }}>
            {columnInfo.name}
          </Typography>

          <Typography
            variant="body3"
            style={{ color: "#83727D", fontWeight: 400, marginTop: 5 }}
          >
            {columnInfo.description}
          </Typography>

          {statusInfo && (
            <div style={{ marginTop: "16px" }}>
              <Typography
                variant="body4"
                style={{ color: "#83727D", marginTop: 10 }}
              >
                Status
              </Typography>

              <StatusCard
                backgroundColor={statusInfo.colors.background}
                textColor={statusInfo.colors.text}
                description={statusInfo.name}
                style={{ marginTop: 10 }}
              />
            </div>
          )}
        </HeaderWrapper>
      </HeaderContainer>

      <BodyContainer>
        <ContentWrapper>
          <ActionsWrapper>
            {hasChecklist ? (
              <ChecklistPillButton
                active={displayChecklist}
                onClick={() => setDisplayChecklist((prev) => !prev)}
              >
                <Icons
                  name="IconCheckbox"
                  fill="transparent"
                  style={{ marginRight: 5 }}
                />
                <Typography variant="body3" weight={700}>
                  Checklist
                </Typography>
              </ChecklistPillButton>
            ) : (
              <></>
            )}

            {hasResetStep ? (
              <ResetPillButton
                disabled={!resetStep.canReset}
                onClick={() => {
                  if (!resetStep.canReset) return
                  setResetStep((prev) => ({ ...prev, isOpen: true }))
                }}
              >
                <Icons
                  name="IconRotate2"
                  fill="transparent"
                  style={{ marginRight: 5 }}
                />
                <Typography variant="body3" weight={700}>
                  Reiniciar etapa
                </Typography>
              </ResetPillButton>
            ) : (
              <></>
            )}
          </ActionsWrapper>

          {hasResetStep && resetStep.isOpen && resetStep.confirmCallback ? (
            <ResetStepConfirmationModal
              isOpen={resetStep.isOpen}
              onClose={() =>
                setResetStep((prev) => ({ ...prev, isOpen: false }))
              }
              onConfirm={resetStep.confirmCallback}
              isLoading={resetStep.isLoading}
            />
          ) : (
            <></>
          )}

          {displayChecklist ? (
            <CardChecklist
              column={columnInfo}
              card={card}
              flowType={FlowCategory.RESIGNATION}
              style={{ marginBottom: 20 }}
            />
          ) : (
            <></>
          )}

          <resignationCardContext.Provider value={contextValue}>
            {children ? <Content>{children}</Content> : <></>}
          </resignationCardContext.Provider>

          <Commentary
            card={card}
            flowType={FlowCategory.RESIGNATION}
            style={{ marginTop: 20 }}
          />
        </ContentWrapper>

        <MenuWrapper>
          <CandidateMenu card={card} flow={flow} />
        </MenuWrapper>
      </BodyContainer>

      <ModalExportCandidateData
        isOpen={modalExportData}
        handleClose={() => setModalExportData(false)}
        candidateId={card.employeeId as string}
      />
    </Container>
  )
}

export default ResignationCardTemplate
