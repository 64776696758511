import { Action, ActionProps } from './Action';
import * as SC from './styled';

type Props = {
  actions: ActionProps[];
};

export function SectionAction(props: Props) {
  return (
    <SC.Container>
      {props.actions.map((action, index) => (
        <Action key={index} {...action} />
      ))}
    </SC.Container>
  );
}
