import { Loader } from "$atoms";
import { ModalContent } from "$frontend/components/molecules/Modal/components/ModalContent";
import { ModalFooter } from "$frontend/components/molecules/Modal/components/ModalFooter";
import { ModalHeader } from "$frontend/components/molecules/Modal/components/ModalHeader";
import { Modal as ModalUI } from "@flash-tecnologia/hros-web-ui-v2";
import { ReactNode } from "react";

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description?: string;
  loading?: boolean;
  footerProps?: {
    onConfirm: () => void;
    onCancel?: () => void;
    cancelText?: string;
    confirmText?: string;
    loading?: boolean;
  };
  children: ReactNode;
  buttonVariant?: "default" | "error" | "info" | "neutral" | "success";
};

export const Modal = ({
  isOpen,
  onClose,
  title,
  description,
  loading = false,
  footerProps,
  children,
  buttonVariant,
}: ModalProps) => {
  if (loading) return <Loader show={true} />;

  return (
    <ModalUI.Root open={isOpen} onClose={onClose} size="xs">
      <>
        <ModalHeader title={title} description={description} />
        <ModalContent>{children}</ModalContent>
        {footerProps && (
          <ModalFooter
            onClose={onClose}
            onConfirm={footerProps.onConfirm}
            onCancel={footerProps.onCancel}
            cancelText={footerProps.cancelText}
            confirmText={footerProps.confirmText}
            loading={footerProps.loading}
            buttonVariant={buttonVariant}
          />
        )}
      </>
    </ModalUI.Root>
  );
};
