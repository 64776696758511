import { PillButton, Tooltip } from '@flash-tecnologia/hros-web-ui-v2'
import { useState } from 'react'

import { EditAssignmentsModal } from '../../../../components/edit-assignments-modal'
import { EditAssignmentModeEnum } from '../../../../components/edit-assignments-modal/context'
import { Employee } from '../../types'

type ActionAssginemntEditCellProps = {
  employee: Employee
}

export function ActionAssginemntEditCell({
  employee,
}: ActionAssginemntEditCellProps) {
  const [isOpenModal, setIsOpenModal] = useState(false)

  return (
    <>
      <Tooltip title="Abrir atribuição de benefícios" placement="bottom-end">
        <div>
          <PillButton
            icon="IconEdit"
            size="small"
            variant="default"
            onClick={() => setIsOpenModal(true)}
          />
        </div>
      </Tooltip>

      {isOpenModal && (
        <EditAssignmentsModal
          mode={EditAssignmentModeEnum.SINGLE_EMPLOYEE}
          employees={[employee]}
          onClose={() => setIsOpenModal(false)}
        />
      )}
    </>
  )
}
