import * as yup from "yup";

import { removeHtmlTags } from "@utils";

import type { Feedback } from "server/src/services/feedback/types";

export type SendFeedbackFormProps = {
  to: string;
  type?: Feedback["type"] | "";
  requestMessage?: string;
  message: string;
  evaluationId?: string;
  companyValues: Feedback["companyValues"];
  evaluationCriterials: Feedback["evaluationCriterials"];
};

export const EDITOR_MAX_LENGTH = 3000;

export const validationSchema = yup.object({
  to: yup.string().required("Este campo deve ser preenchido"),
  message: yup
    .string()
    .test(
      "len",
      `O texto deve ter até ${EDITOR_MAX_LENGTH} caracteres`,
      (val) => removeHtmlTags(val).length <= EDITOR_MAX_LENGTH
    )
    .test("empty text editor", "Este campo deve ser preenchido", (val) =>
      Boolean(removeHtmlTags(val).trim().length)
    )
    .required("Este campo deve ser preenchido")
    .nullable(),
  requestMessage: yup.string().notRequired(),
  type: yup.string().required("Este campo deve ser preenchido"),
  evaluationId: yup.string().when("type", {
    is: (type) => type === "evaluation",
    then: (schema) => schema.required("Este campo deve ser preenchido"),
    otherwise: (schema) => schema.notRequired(),
  }),
  companyValues: yup.array(
    yup.object({
      _id: yup.string(),
      name: yup.string(),
      description: yup.string(),
      value: yup.string().required(),
    })
  ),
  evaluationCriterials: yup
    .array(
      yup.object({
        _id: yup.string(),
        name: yup.string(),
        description: yup.string(),
        value: yup.string().required("favor preencher a avaliação!"),
      })
    )
    .when("type", {
      is: (type) => type === "evaluation",
      then: (schema) => schema.min(1, "no_competence"),
      otherwise: (schema) => schema.notRequired(),
    }),
});
