import { CircularProgress } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ContentContainer, Header } from './styled';
import { useBasicDealData } from '../SignUp/hooks/use-basic-deal-data';
import LogoFlash from '../../assets/logo_flash.svg';
import { trackPage } from '@/utils';

export const Deal = () => {
  const { dealId } = useParams();
  const navigate = useNavigate();
  const { data, getBasicDealDataByHubspotId, isLoading } = useBasicDealData();

  useEffect(() => {
    trackPage('signup_deal_loading_page');

    if (dealId) {
      getBasicDealDataByHubspotId({ dealId });
    }
  }, []);

  const refToTop = useRef<HTMLInputElement>(null);

  useEffect(() => {
    refToTop.current?.scrollIntoView({ behavior: 'smooth' });
  });

  useEffect(() => {
    if (isLoading) return;

    if (data?.registrationNumber) {
      navigate(`/signup/hubspot/${dealId}/create-company`);
    } else {
      navigate(`/signup/self/error`);
    }
  }, [isLoading, data]);

  return (
    <div>
      <Header ref={refToTop}>
        <LogoFlash />
      </Header>

      <ContentContainer>
        <CircularProgress />
      </ContentContainer>
    </div>
  );
};
