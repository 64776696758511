import React from "react"
import { useFormik } from "formik"
import {
  DatePicker,
  Dayjs,
  dayjs,
  SelectField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import * as yup from "yup"
import { useTheme } from "styled-components"
import { ProbationOptions, ProbationPeriod } from "src/types"

const validationSchema = yup.object().shape({
  option: yup.string(),
  firstDate: yup.date(),
  secondDate: yup.date(),
})

type Option = { label: string; value: ProbationOptions }

const options: Option[] = [
  { label: "Sem periodo de experiencia", value: "no-period" },
  { label: "Customizado", value: "custom" },
  { label: "1 período de 30 dias", value: "one-period-30days" },
  { label: "1 período de 45 dias", value: "one-period-45days" },
  { label: "1 período de 60 dias", value: "one-period-60days" },
  { label: "1 período de 90 dias", value: "one-period-90days" },
  { label: "2 período de 30 dias", value: "two-period-30days" },
  { label: "2 período de 45 dias", value: "two-period-45days" },
  { label: "Períodos de 30 + 60 dias", value: "period-30-60" },
  { label: "Períodos de 60 + 30 dias", value: "period-60-30" },
]

function isDatesSumBiggerThanLimitPeriod(dates: Dayjs[], hiringDate: Dayjs) {
  const hiringDateLessOneDay = hiringDate.subtract(1, "day") // since hiring date count as first worked day
  const daysDifferences = dates.map((dateToSum) => {
    if (!dateToSum.isValid()) return 0
    const difference = dateToSum.diff(hiringDateLessOneDay, "day")
    return difference
  })
  return daysDifferences.some((days) => days > 90)
}

type Form = {
  option?: ProbationOptions
  firstDate?: string
  secondDate?: string
}

export type ProbationaryRef = ReturnType<typeof useFormik<Form>>

type ProbationaryPeriodProps = {
  hiringDate?: string | Date
  value?: ProbationPeriod
  onChangeCallback?: (values: Form) => void
  required?: boolean
  style?: React.CSSProperties
}

export const ProbationaryPeriod = React.forwardRef(
  (props: ProbationaryPeriodProps, ref: React.Ref<ProbationaryRef>) => {
    const [isEnable, setIsEnable] = React.useState<boolean>(
      dayjs(props.hiringDate ?? "").isValid(),
    )

    const theme = useTheme()
    const isFirstMount = React.useRef<any>(true)

    const formik = useFormik<Form>({
      initialValues: {
        option: props.value?.option
          ? options.find((option) => option.value === props.value?.option)
              ?.value
          : undefined,
        firstDate: props.value?.firstDate ?? undefined,
        secondDate: props.value?.secondDate ?? undefined,
      },
      validationSchema,
      validate: (values) => {
        let errors: any = {}
        const hiringDate = dayjs(props.hiringDate ?? null)
        const firstDate = dayjs(values.firstDate ?? null)
        const secondDate = dayjs(values.secondDate ?? null)

        if (props.hiringDate && props.required && !values.option)
          errors.option = "Campo obrigatório"
        if (values.option === "custom" && !firstDate.isValid())
          errors.firstDate =
            "Data de término do período de experiência é obrigatória."
        if (hiringDate.isSame(firstDate))
          errors.firstDate =
            "Data de término não pode ser igual à data de admissão"
        if (hiringDate.isSame(secondDate))
          errors.secondDate =
            "Data de término não pode ser igual à data de admissão"
        if (hiringDate.isAfter(firstDate))
          errors.firstDate = "Máximo de 90 dias da data prevista de admissão"
        if (hiringDate.isAfter(secondDate))
          errors.secondDate = "Máximo de 90 dias da data prevista de admissão"
        if (secondDate?.isValid() && secondDate.isSameOrBefore(firstDate))
          errors.secondDate =
            "Data do segundo término deve ser posterior ao primeiro término"
        if (
          isDatesSumBiggerThanLimitPeriod([firstDate, secondDate], hiringDate)
        ) {
          errors = {
            ...errors,
            firstDate: "Máximo de 90 dias da data prevista de admissão ",
            secondDate: "Máximo de 90 dias da data prevista de admissão ",
          }
        }

        return errors
      },
      onSubmit: async (values): Promise<Form> => values,
    })

    React.useImperativeHandle(ref, () => formik)

    React.useEffect(() => {
      setIsEnable(dayjs(props.hiringDate ?? "").isValid())
      formik.setValues(handleTreatValues(formik.values.option))
    }, [props.hiringDate])

    React.useEffect(() => {
      if (!props.onChangeCallback) return
      if (!isFirstMount.current) {
        if (!formik.values.option) {
          props.onChangeCallback({
            option: undefined,
            firstDate: undefined,
            secondDate: undefined,
          })
        }
        props.onChangeCallback(formik.values)
      } else isFirstMount.current = false
    }, [formik.values])

    function handleTreatValues(option: Form["option"]): Form {
      const hiringDate = dayjs(props.hiringDate)
      switch (option) {
        case "custom":
          return {
            option: "custom",
            firstDate: formik.values.firstDate,
            secondDate: formik.values.secondDate,
          }
        case "one-period-30days":
          return {
            option: "one-period-30days",
            firstDate: hiringDate.add(29, "day").toISOString(),
            secondDate: undefined,
          }
        case "one-period-45days":
          return {
            option: "one-period-45days",
            firstDate: hiringDate.add(44, "day").toISOString(),
            secondDate: undefined,
          }
        case "one-period-60days":
          return {
            option: "one-period-60days",
            firstDate: hiringDate.add(59, "day").toISOString(),
            secondDate: undefined,
          }
        case "one-period-90days":
          return {
            option: "one-period-90days",
            firstDate: hiringDate.add(89, "day").toISOString(),
            secondDate: undefined,
          }
        case "period-30-60":
          return {
            option: "period-30-60",
            firstDate: hiringDate.add(29, "day").toISOString(),
            secondDate: hiringDate.add(89, "day").toISOString(),
          }
        case "period-60-30":
          return {
            option: "period-60-30",
            firstDate: hiringDate.add(59, "day").toISOString(),
            secondDate: hiringDate.add(89, "day").toISOString(),
          }
        case "two-period-30days":
          return {
            option: "two-period-30days",
            firstDate: hiringDate.add(29, "day").toISOString(),
            secondDate: hiringDate.add(59, "day").toISOString(),
          }
        case "two-period-45days":
          return {
            option: "two-period-45days",
            firstDate: hiringDate.add(44, "day").toISOString(),
            secondDate: hiringDate.add(89, "day").toISOString(),
          }
        case "no-period":
        default:
          return {
            option: "no-period",
            firstDate: undefined,
            secondDate: undefined,
          }
      }
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: 24,
          ...props.style,
        }}
      >
        <div>
          <SelectField
            label="Período de experiência"
            fullWidth
            disabled={!isEnable}
            options={options}
            value={options.find(
              (option) => option.value === formik.values.option,
            )}
            onSelectChange={(_event, option) => {
              if (option.value !== "custom") {
                formik.setValues(handleTreatValues(option.value))
                return
              }

              formik.setFieldValue("option", option.value)
            }}
            error={Boolean(formik.errors.option)}
            helperText={Boolean(formik.errors.option) && formik.errors.option}
          />

          {!isEnable ? (
            <Typography
              variant="caption"
              variantColor={theme.colors.neutral[50]}
              style={{ marginLeft: 15 }}
            >
              Este campo só pode ser preenchido quando a data prevista de
              admissão estiver preenchida.
            </Typography>
          ) : (
            <></>
          )}
        </div>

        {formik.values.option === "custom" ? (
          <>
            <DatePicker
              id="firstDate"
              name="firstDate"
              label="Fim do primeiro período de experiência"
              placeholder="Fim do primeiro período de experiência"
              value={formik.values.firstDate}
              fromDate={dayjs(props.hiringDate)}
              error={Boolean(formik.errors.firstDate)}
              helperText={
                Boolean(formik.errors.firstDate) && formik.errors.firstDate
              }
              onDateChange={(value) => {
                if (
                  !dayjs(value).isValid() ||
                  formik.values.firstDate === value?.toISOString()
                )
                  return
                formik.setFieldValue("firstDate", value?.toISOString())
              }}
            />

            <DatePicker
              id="secondDate"
              name="secondDate"
              label="Fim do segundo período de experiência"
              placeholder="Fim do segundo período de experiência"
              value={formik.values.secondDate}
              fromDate={
                formik.values.firstDate
                  ? dayjs(formik.values.firstDate)
                  : dayjs(props.hiringDate)
              }
              error={Boolean(formik.errors.secondDate)}
              helperText={
                Boolean(formik.errors.secondDate) && formik.errors.secondDate
              }
              onDateChange={(value) => {
                if (
                  !dayjs(value).isValid() ||
                  formik.values.secondDate === value?.toISOString()
                )
                  return
                formik.setFieldValue("secondDate", value?.toISOString())
              }}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    )
  },
)
