import { ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import React from "react";
import { useTheme } from "styled-components";
import { Container } from "./styled";

type EmptyGroupListProps = {
  title: string;
  description: string;
};

export const EmptyGroupList: React.FC<EmptyGroupListProps> = ({ title, description }) => {
  const { colors } = useTheme();
  return (
    <Container>
      <ShapeIcon variant="neutral" name="IconZoomCancel" size={56} color={colors.neutral[40]} />
      <Typography variant="headline7" variantColor={colors.neutral[40]}>
        {title}
      </Typography>
      <Typography variant="body4" variantColor={colors.neutral[40]}>
        {description}
      </Typography>
    </Container>
  );
};
