import { PageContainer } from "@flash-tecnologia/hros-web-ui-v2";
import { Header } from "./components/Header";
import { useParams } from "react-router-dom";
import { Table } from "./components/Table";
import { trpc } from "src/api/client";
import { useState } from "react";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

export const ReportPage = () => {
  const { id } = useParams<{ id: string }>();
  const { selectedCompany } = useSelectedCompany();
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  if (!id) {
    return null;
  }

  const {
    data: report,
    isLoading: isReportLoading,
    error: reportError,
  } = trpc.findReportById.useQuery({
    reportId: id,
    companyId: selectedCompany.id,
  });

  const {
    data: queryResult,
    isLoading: isQueryLoading,
    error: queryError,
  } = trpc.queryReport.useQuery({
    reportId: id,
    page: pagination.pageNumber,
    pageSize: pagination.pageSize,
    companyId: selectedCompany.id,
  });

  if (reportError || queryError) {
    return <p>Error</p>; // Todo - Check error page provided by Design
  }

  return (
    <PageContainer>
      {!isReportLoading ? (
        <Header.Component
          title={report.title}
          subtitle={`Criado por ${report.owner}`}
        />
      ) : (
        <Header.Skeleton />
      )}
      <Table
        headers={report?.columns}
        data={queryResult?.data}
        isLoading={isQueryLoading || isReportLoading}
        pagination={pagination}
        onPagination={setPagination}
        total={queryResult?.total as number}
      />
    </PageContainer>
  );
};
