const errors = {
  genericError:
    'Todo mundo erra, dessa vez fomos nós. Tente novamente mais tarde.',
  actions: {
    toastDescription:
      'Tente novamente. Se o problema persistir, entre em contato com o suporte',
  },
  auth: {
    invalidEmployeeId: 'Não foi possível autenticar o usuário',
  },
  forms: {
    generic: {
      required: 'Campo obrigatório',
    },
    transactionPassword: {
      wrongPassword: 'Senha de transação inválida',
      wrongSize: 'A senha deve ter 4 dígitos',
    },
    date: {
      invalid: 'Data inválida',
      future: 'Data deve ser futura',
      tooFarInFuture: 'Data deve ser mais próxima',
    },
    amount: {
      noBalance: 'Saldo insuficiente.',
      minimumBilletValue: 'Valor mínimo R$ 10,00',
    },
  },
  cards: {
    password: {
      wrongPasswordToast: {
        content: 'Senha de transação inválida',
      },
      tooManyAttemptsToast: {
        content: 'Muitas tentativas incorretas',
        description:
          'Para proteger sua conta, desativamos temporariamente seu cartão. Altere sua senha pelo aplicativo para liberá-la.',
      },
    },
  },
} as const;

export default errors;
