import {
  Badge,
  Button,
  Icons,
  PillButton,
  Table,
  Unstable,
} from '@flash-tecnologia/hros-web-ui-v2'
import { useMemo } from 'react'

import { EditAssignmentsModal } from '../../components/edit-assignments-modal'
import { EditAssignmentModeEnum } from '../../components/edit-assignments-modal/context'
import { OrderSummary } from '../../components/order-summary'
import { Filters } from './components/filters'
import { EmployeeSelectionProvider } from './context'
import { useEmployeeSelection } from './hooks/use-employee-selection'
import { EmployeeSelectionContainerStyled } from './styles'

function EmployeeSelectionContent() {
  const {
    employees,
    employeeTotal,
    pagination,
    tableHeaderGroups,
    setPagination,
    handleSearch,
    employeeSelectedTotal,
    clearSelectedRows,
    selectedAllEmployees,
    isBulkUpdateModalOpen,
    closeBulkUpdateModal,
    openBulkUpdateModal,
    selectedEmployees,
    isLoading,
    isFilterDrawerOpen,
    closeFilterDrawer,
    openFilterDrawer,
    activeFiltersCount,
    redirectToEmployees,
    noEmployeesData,
  } = useEmployeeSelection()

  const renderEmpty = useMemo(() => {
    const message = noEmployeesData
      ? 'Nenhuma pessoa cadastrada'
      : 'Nenhum resultado encontrado'
    const isFiltered = !noEmployeesData
    const children = noEmployeesData ? (
      <Button variant="primary" onClick={redirectToEmployees}>
        Cadastrar pessoas <Icons name="IconArrowNarrowRight" />
      </Button>
    ) : null

    return {
      message,
      isFiltered,
      children,
    }
  }, [noEmployeesData, redirectToEmployees])

  return (
    <EmployeeSelectionContainerStyled>
      <Table.Root variant="soft">
        <Table.Content>
          <Table.FilterSearch
            labelSearch="Buscar por nome ou CPF"
            onSearch={(event) => handleSearch(event.target.value)}
          >
            <Badge
              color="neutral"
              variant="primary"
              content={activeFiltersCount}
            >
              <PillButton
                icon="IconFilter"
                label="Filtros"
                onClick={openFilterDrawer}
                variant="default"
                size="small"
                type="primary"
                disabled={isLoading || noEmployeesData}
              />
            </Badge>
          </Table.FilterSearch>

          <Table.Grid.Root loading={isLoading} empty={renderEmpty}>
            <Table.Grid.Header getHeaderGroups={tableHeaderGroups} />

            {employees.map((employee) => (
              <Table.Grid.Row key={employee.id} row={employee} />
            ))}
          </Table.Grid.Root>

          <Table.BulkActions
            open={employeeSelectedTotal > 0}
            total={employeeTotal}
            totalSelected={employeeSelectedTotal}
            onSelectAll={selectedAllEmployees}
            onClearSelection={clearSelectedRows}
          >
            <PillButton
              label="Editar valores"
              icon="IconEdit"
              size="extra-small"
              variant="pink"
              iconPosition="left"
              onClick={openBulkUpdateModal}
            />
          </Table.BulkActions>

          <Table.Pagination
            count={employeeTotal}
            onPaginationChange={setPagination}
            pagination={pagination}
            showItemRange
          />
        </Table.Content>
      </Table.Root>

      <OrderSummary />

      {isBulkUpdateModalOpen && (
        <EditAssignmentsModal
          onClose={closeBulkUpdateModal}
          mode={EditAssignmentModeEnum.MULTIPLE_EMPLOYEES}
          employees={selectedEmployees}
        />
      )}

      <Unstable.Drawer
        title="Filtros"
        open={isFilterDrawerOpen}
        onClose={closeFilterDrawer}
      >
        <Filters onCloseFilter={closeFilterDrawer} />
      </Unstable.Drawer>
    </EmployeeSelectionContainerStyled>
  )
}

export function EmployeeSelection() {
  return (
    <EmployeeSelectionProvider>
      <EmployeeSelectionContent />
    </EmployeeSelectionProvider>
  )
}
