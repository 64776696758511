import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { SkeletonLoader } from "$atoms";
import { trpc } from "$client";
import { CorporateCardEmptyStateSection } from "$frontend/components/organisms/CorporateCardEmptyStateSection";
import { ExpenseUserGroupList } from "$frontend/components/organisms/ExpenseUserGroupList/ExpenseUserGroupList";
import { SummaryCards } from "$frontend/pages/AdminReimbursements/styled";
import { useAdminStatementContext } from "$frontend/pages/AdminStatement/context/AdminStatementContext";
import { useGetSummaryWithRemainingAwaitingAccountCount } from "$hooks";
import { DateRangeField, NotificationCard, PageContainer, PageHeader } from "$molecules";
import { ExpenseChart, ExpenseFilterDrawer, ExpenseFiltersFields, ExpenseStatusCardList } from "$organisms";
import { ExpenseType } from "$serverTypes";
import { defaultStatus } from "$utils";
import { ExpenseCardsContainer, SummaryCardsContainer } from "./styled";

export const AdminStatementPage = () => {
  const { t } = useTranslation("translations", { keyPrefix: "pages.adminStatement" });
  const { methods } = useAdminStatementContext();
  const { control } = methods;

  const expenseType = ExpenseType.CORPORATE_CARD;

  const contextState = methods.watch();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedFiltersCount, setSelectedFiltersCount] = useState(0);

  const { data, isError, isFetching, refetch } = trpc.expense.admin.getExpensesGrouped.useQuery({
    employeeName: contextState.search,
    type: expenseType,
    status: contextState.filters.status.length > 0 ? contextState.filters.status : defaultStatus,
    transactionStatus: contextState.filters.transactionStatus.length > 0 ? contextState.filters.transactionStatus : [],
    categoryNanoIds: contextState.filters.categoryNanoIds.length > 0 ? contextState.filters.categoryNanoIds : [],
    startDate: contextState.filters.dateRange.from.toISOString(),
    endDate: contextState.filters.dateRange.to.toISOString(),
    minAmount: contextState.filters.valueRange.min,
    maxAmount: contextState.filters.valueRange.max,
    pageNumber: contextState.pagination.pageNumber,
    pageSize: contextState.pagination.pageSize,
  });

  const {
    data: summaryData,
    isFetching: isSummaryLoading,
    refetch: refetchSummary,
    remainingAwaitingAccountExpensesCount,
  } = useGetSummaryWithRemainingAwaitingAccountCount({
    status: defaultStatus,
    type: expenseType,
    dateRange: contextState.filters.dateRange,
  });

  // TODO: change this trpc request to a real one
  const { data: isEmptyState, isLoading } = trpc.expense.getEmptyState.useQuery(ExpenseType.CORPORATE_CARD);

  const handleFilterChange = (selectedFilters: ExpenseFiltersFields) => {
    methods.setValue("filters.status", selectedFilters?.expenseStatus || []);
    methods.setValue("filters.transactionStatus", selectedFilters?.transactionStatus || []);
    methods.setValue("filters.categoryNanoIds", selectedFilters?.categoryNanoIds || []);
    methods.setValue("filters.valueRange", selectedFilters?.valueRange || { min: undefined, max: undefined });
    methods.setValue("pagination.pageNumber", 1);
  };

  function refetchAll() {
    refetch();
    refetchSummary();
  }

  const company = useSelectedCompany();
  useEffect(() => {
    refetchAll();
  }, [company.selectedCompany?.id]);

  return (
    <>
      {isEmptyState && !isLoading ? (
        <CorporateCardEmptyStateSection />
      ) : (
        <>
          <PageHeader title={t("title")} subtitle={t("subtitle")}>
            <Controller
              name="filters.dateRange"
              control={control}
              render={({ field }) => (
                <DateRangeField
                  {...field}
                  showAlertBadge={remainingAwaitingAccountExpensesCount > 0}
                  alertBadgeTooltip={t("remainingExpensesWarning", { count: remainingAwaitingAccountExpensesCount })}
                  isLoading={isSummaryLoading}
                  onChange={(value) => {
                    field.onChange(value);
                    methods.setValue("pagination.pageNumber", 1);
                  }}
                />
              )}
            />
          </PageHeader>
          <PageContainer>
            {isError ? (
              <NotificationCard type="error" variant="contained" iconName="IconExclamationMark">
                {t("failedToGetMovements")}
              </NotificationCard>
            ) : (
              <>
                {isSummaryLoading ? (
                  <SummaryCards>
                    <SkeletonLoader isVisible={true} height={212} />
                  </SummaryCards>
                ) : (
                  <SummaryCardsContainer>
                    <ExpenseCardsContainer>
                      <ExpenseChart fullWidth={true} type={expenseType} summaries={summaryData?.summaries} />
                      <Controller
                        name="filters.status"
                        control={control}
                        render={({ field }) => (
                          <ExpenseStatusCardList
                            summaries={summaryData?.summaries}
                            status={field.value}
                            onStatusChange={(value) => {
                              field.onChange(value);
                            }}
                            type={expenseType}
                          />
                        )}
                      />
                    </ExpenseCardsContainer>
                    {/*<ExpenseCardsContainer>
                  <AccountBalanceCard
                    dateRange={{
                      initialDate: contextState.filters.dateRange.from,
                      endDate: contextState.filters.dateRange.to,
                    }}
                  />
                </ExpenseCardsContainer>*/}
                  </SummaryCardsContainer>
                )}

                <ExpenseUserGroupList
                  data={data?.employeesAndExpenses || []}
                  total={data?.totalEmployeesCount || 0}
                  isLoading={isFetching}
                  isSearchLoading={isSummaryLoading}
                  selectedFiltersCount={selectedFiltersCount}
                  groupPagination={contextState.pagination}
                  onGroupPaginationChange={(pagination) => {
                    methods.setValue("pagination", pagination, { shouldValidate: true });
                  }}
                  onActionComplete={refetchAll}
                  type={expenseType}
                  onFilterClick={() => setDrawerOpen(!drawerOpen)}
                  onSearch={(search) => {
                    methods.setValue("search", search || "");
                  }}
                />
              </>
            )}
          </PageContainer>
          <ExpenseFilterDrawer
            expenseType={ExpenseType.CORPORATE_CARD}
            isOpen={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            onFilterCountChange={(count) => setSelectedFiltersCount(count)}
            onSubmit={(selectedFilters: ExpenseFiltersFields) => {
              handleFilterChange(selectedFilters);
              setDrawerOpen(false);
            }}
            filters={{
              expenseStatus: contextState.filters.status,
              categoryNanoIds: contextState.filters.categoryNanoIds,
              valueRange: contextState.filters.valueRange,
              transactionStatus: contextState.filters.transactionStatus,
            }}
            config={{
              expenseStatus: true,
              categoryNanoIds: true,
              valueRange: true,
              transactionStatus: true,
            }}
          />
        </>
      )}
    </>
  );
};
