import { GroupedSummary } from "$frontend/components/organisms/ExpenseStatusCardList/types";
import { CombinedExpenseStatus, VariantType } from "$organisms";
import { ExpenseStatus, Summary } from "$serverTypes";
import { Theme } from "@mui/material";
import { DefaultTheme } from "styled-components";
export {
  CombinedExpenseStatus,
  VariantType,
  type GroupedSummary,
} from "$frontend/components/organisms/ExpenseStatusCardList/types";

export const expenseStatusVariants = {
  [CombinedExpenseStatus.DRAFT]: VariantType.PROGRESS,
  [CombinedExpenseStatus.DRAFT_OR_REQUIRE_CHANGES]: VariantType.SECONDARY,
  [CombinedExpenseStatus.PENDING_ACCOUNTING]: VariantType.BLUE,
  [CombinedExpenseStatus.FINISHED]: VariantType.GREEN,
  [CombinedExpenseStatus.REJECTED]: VariantType.NEGATIVE,
} satisfies Record<CombinedExpenseStatus, VariantType>;

export const getExpenseStatusTranslations = (t: (key: string) => string) => {
  return {
    [CombinedExpenseStatus.DRAFT]: t("status.open"),
    [CombinedExpenseStatus.DRAFT_OR_REQUIRE_CHANGES]: t("status.pending"),
    [ExpenseStatus.PENDING_ACCOUNTING]: t("status.awaitingAccounting"),
    [ExpenseStatus.FINISHED]: t("status.paid"),
    [ExpenseStatus.REJECTED]: t("status.rejected"),
  } satisfies Record<CombinedExpenseStatus, string>;
};

export const expenseStatusChartVariants = {
  [CombinedExpenseStatus.DRAFT]: "progress50",
  [CombinedExpenseStatus.DRAFT_OR_REQUIRE_CHANGES]: "neutral70",
  [CombinedExpenseStatus.PENDING_ACCOUNTING]: "info50",
  [CombinedExpenseStatus.FINISHED]: "success50",
  [CombinedExpenseStatus.REJECTED]: "negative50",
} satisfies Record<CombinedExpenseStatus, string>;

export function getColorFromTheme(theme: Theme | DefaultTheme, combinedStatuses: CombinedExpenseStatus[]): string[] {
  return combinedStatuses.map((status) => {
    const variant = expenseStatusChartVariants[status];
    return variant ? theme.colors[variant] : theme.colors.primary;
  });
}

export const combinedExpenseStatusMapping: Record<CombinedExpenseStatus, ExpenseStatus[]> = {
  [CombinedExpenseStatus.DRAFT]: [
    ExpenseStatus.DRAFT,
    ExpenseStatus.REQUIRE_CHANGES,
    ExpenseStatus.PENDING_APPROVAL,
    ExpenseStatus.PENDING_ACCOUNTING,
  ],
  [CombinedExpenseStatus.DRAFT_OR_REQUIRE_CHANGES]: [ExpenseStatus.DRAFT, ExpenseStatus.REQUIRE_CHANGES],
  [CombinedExpenseStatus.PENDING_ACCOUNTING]: [ExpenseStatus.PENDING_ACCOUNTING],
  [CombinedExpenseStatus.FINISHED]: [ExpenseStatus.FINISHED],
  [CombinedExpenseStatus.REJECTED]: [ExpenseStatus.REJECTED],
};

export function unifySummaries(summaries: Summary[], combinedStatuses: CombinedExpenseStatus[]) {
  const filteredStatuses = combinedStatuses || Object.keys(combinedExpenseStatusMapping);

  return filteredStatuses.map((combinedStatus) => {
    const status = combinedExpenseStatusMapping[combinedStatus as CombinedExpenseStatus];

    const filteredSummaries = summaries.filter((summary) => status.includes(summary.status));
    const count = filteredSummaries.reduce((acc, summary) => acc + summary.count, 0);
    const amount = filteredSummaries.reduce((acc, summary) => acc + summary.amount, 0);

    return { status: combinedStatus, count, amount } satisfies GroupedSummary;
  });
}
