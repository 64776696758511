import { Modal } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledModal = styled(Modal.Root)`
  & {
    .modal-content-area {
      padding: 0px !important;
    }
    .modal-container {
      border-radius: 16px !important;
    }
  }
`;

export { StyledModal };
