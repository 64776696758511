import React from "react";

import { Option } from "../Autocomplete";
import { OptionCaption, OptionContainer, OptionContent, OptionLabel } from "./styled";

export interface LoaderProps {
  renderOptionProps?: React.HTMLAttributes<HTMLLIElement>;
  option?: Option;
  icon?: JSX.Element;
}

export const RenderOption = ({ renderOptionProps, option, icon }: LoaderProps) => {
  return (
    <OptionContainer {...renderOptionProps} key={option?.value}>
      <OptionContent>
        {icon}
        <div>
          <OptionLabel variant="body3">{option?.label}</OptionLabel>
          {Boolean(option?.caption) && <OptionCaption variant="body3">{option?.caption}</OptionCaption>}
        </div>
      </OptionContent>
    </OptionContainer>
  );
};
