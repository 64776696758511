import { Icons } from "@flash-tecnologia/hros-web-ui-v2"
import dispatchToast from "../../../../utils/dispatchToast"
import { useState } from "react"
import { CopyBoard } from "./styles"

export const Clipboard = ({
  value,
  idx,
  icon: Icon = <CopyBoard />,
}: {
  value: string
  idx: number
  icon?: React.ReactNode
}) => {
  const [hasCopied, setHasCopied] = useState({ copied: false, index: 0 })
  const handleClick = () => {
    setHasCopied({ copied: true, index: idx })
    navigator.clipboard.writeText(value)
    dispatchToast({
      content: "Texto copiado",
      type: "success",
    })

    setTimeout(() => {
      setHasCopied({ copied: false, index: idx })
    }, 500)
  }

  return (
    <div role="presentation" onClick={handleClick}>
      {hasCopied?.copied && hasCopied?.index === idx ? (
        <Icons
          name="IconSquareCheck"
          fill="#01E0AD"
          style={{
            width: "13px",
            height: "14px",
            marginLeft: "7px",
            opacity: "0.5",
          }}
        />
      ) : (
        Icon
      )}
    </div>
  )
}

export default Clipboard
