import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const LabelButtonContent = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacings.xs4};
  align-items: center;
`;
