import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import * as SC from './styles';
import { BaseSection } from '../../components';
import { trackEvent } from '@/utils';

const FaqItems = [
  {
    title: 'Com quantos funcionários eu posso contratar a Flash?',
    answer:
      'Não há um número mínimo ou máximo de funcionários para contratação da Flash. Nossos produtos são para todas as empresas.',
  },
  {
    title: 'O que é o cartão Flash Benefícios?',
    answer:
      'O nosso rosinha é um cartão multibenefícios que pode ser usado com saldos exclusivos para uma determinada categoria ou saldos flexíveis. Os benefícios mais inovadores do mercado com total segurança jurídica.',
  },
  {
    title: 'Quais estabelecimentos aceitam o cartão Flash?',
    answer:
      'O cartão de benefícios da Flash tem a bandeira Visa e é aceito em mais de 4 milhões de estabelecimentos no Brasil e no mundo com a bandeira Visa. Lembrando que a disponibilidade depende das categorias de benefícios contratadas pela sua empresa.',
  },
  {
    title:
      'Como funciona a plataforma de gestão de despesas e viagens corporativas?',
    answer:
      'É simples. Você tem total controle da sua gestão financeira. Liberte-se das diversas planilhas, ferramentas e programas diferentes e foque apenas no crescimento da sua empresa.',
  },
  {
    title: 'O que é o cartão corporativo de gestão de despesas?',
    answer:
      'O cartão corporativo pode ser usado com o Cartão da Flash. Garanta transparência e controle em todas as transações. Programe e retire os saldos automaticamente da conta, receba alertas contra lançamentos irregulares e muito mais.',
  },
];

export const FaqSection = () => {
  const onItemClick = (item: string) => {
    trackEvent('signup_self_signup_landing_page_faq_item_clicked', {
      item,
    });
  };

  return (
    <BaseSection>
      <SC.SectionContent>
        <SC.TextContainer>
          <Typography variant="headline6">Ficou com alguma dúvida?</Typography>
          <Typography variant="body3" color="neutral.50">
            Confira as perguntas mais frequentes
          </Typography>
        </SC.TextContainer>
        <SC.FaqContainer>
          {FaqItems.map((item) => (
            <div
              key={item.title}
              onClick={() => onItemClick(item.title)}
              role="presentation"
            >
              <SC.Accordion
                variant="default"
                customHeader={
                  <Typography variant="headline8" color="neutral.20">
                    {item.title}
                  </Typography>
                }
              >
                <Typography variant="body3" color="neutral.50">
                  {item.answer}
                </Typography>
              </SC.Accordion>
            </div>
          ))}
        </SC.FaqContainer>
      </SC.SectionContent>
    </BaseSection>
  );
};
