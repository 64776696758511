import {
  Typography as FlashTypography,
  TypographyProps as FlashTypographyProps,
} from "@flash-tecnologia/hros-web-ui-v2";

import { TypeVariant } from "$utils";
import { CustomTypographyStyle, DefaultTypographyStyle } from "./styled";
type TypographyProps = FlashTypographyProps & {
  /**
   * Defines the style variant for the typography.
   * - `"default"`: Uses the default typography styling.
   * - `"custom"`: Applies custom typography styling.
   */
  textVariant?: TypeVariant;

  /** @deprecated this prop isn't working properly, use variantColor instead */
  color?: never;
};

export const Typography = ({ textVariant = TypeVariant.DEFAULT, ...props }: TypographyProps) => {
  const TypographyComponent = textVariant === TypeVariant.CUSTOM ? CustomTypographyStyle : DefaultTypographyStyle;

  return (
    <TypographyComponent>
      <FlashTypography {...props}>{props.children}</FlashTypography>
    </TypographyComponent>
  );
};
