import { useAddEmployees } from '../../hooks/use-add-employees'
import { CardRadioOptions } from '../card-radio-options'

export function MigrateEmployees() {
  const { migrateEmployees, handleMigrateEmployees } = useAddEmployees()

  return (
    <CardRadioOptions
      title="O que fazer com colaboradores já cadastrados no grupo econômico"
      description="Defina o que fazer se alguma pessoa da planilha já estiver cadastrada em outra empresa do grupo econômico."
      options={[
        {
          title: 'Duplicar cadastro',
          description:
            'A pessoa duplicada continuará na empresa atual e no CNPJ indicado na planilha.',
          icon: 'IconUsers',
          checked: !migrateEmployees,
          onClick: handleMigrateEmployees,
        },
        {
          title: 'Transferir a pessoa para a nova empresa',
          description:
            'Essas pessoas deixarão de estar vinculadas às empresas atuais e passarão a estar vinculadas apenas à nova empresa.',
          icon: 'IconSwitch3',
          checked: migrateEmployees,
          onClick: handleMigrateEmployees,
        },
      ]}
    />
  )
}
