import { FocusedFlow } from '@frontend/components/FocusedFlow';
import ModalService from '@frontend/services/ModalService';
import { z } from 'zod';
import { METADATA_UserAccounts, ROUTE_UserAccounts } from '../..';
import { DepositFormSchema } from './controllers/form/schema';
import { useFlowController } from './controllers/useFlowController';

type Props = {
  orderId: string;
  defaultValues: z.output<typeof DepositFormSchema>;
};

function BalanceRequestPayment(props: Props) {
  const flowController = useFlowController(props);
  return (
    <FocusedFlow
      headerMetadata={{
        breadcrumbs: [
          {
            text: METADATA_UserAccounts.title,
            link: ROUTE_UserAccounts.buildPath({}),
          },
          {
            text: 'Depósito via plataforma',
          },
        ],
      }}
      content={flowController.content}
      footer={{
        leftActions: flowController.leftButtons,
        rightActions: flowController.rightButtons,
      }}
    />
  );
}

export const MODAL_BalanceRequestPayment = ModalService.create(
  BalanceRequestPayment,
);
