import styled, { css } from "styled-components"

export const CardWrapper = styled.div<{ isDragging: boolean }>`
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  cursor: pointer;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacings.xs3};

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-color: ${({ theme }) => theme.colors.secondary[95]};
  }

  :last-child {
    margin-bottom: 0px;
  }

  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: ${({ theme }) => `${theme.colors.neutral[100]} ${theme.borders.width.xs2} solid`};

  padding: ${({ theme }) => `${theme.spacings.xs2} ${theme.spacings.xs3}`};

  display: flex;
  align-item: center;
  flex-direction: column;

  ${({ isDragging }) =>
    isDragging &&
    css`
      border-color: ${({ theme }) => theme.colors.secondary[70]};
      color: ${({ theme }) => theme.colors.neutral[100]};
      rotate: -3.1deg;
    `}
`
