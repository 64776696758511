import {
  candidateValidateDocument,
  changeEmployeeInOrgchart,
  changeMultipleEmployeeInOrgchart,
  checkLeaderByEmployeeId,
  checklistTemplateAdd,
  checklistTemplateNew,
  checklistTemplateRemove,
  checklistTemplateUpdate,
  checklistToggleHiring,
  checklistToggleResignation,
  checklistUpdateHiring,
  checklistUpdateResignation,
  createBoardReport,
  createHiringCard,
  createManyResignationCard,
  createModelProposedLetter,
  createResignationCard,
  deleteModelProposedLetter,
  dependentValidateDocument,
  duplicateModelProposedLetter,
  getMultipleS3File,
  getS3File,
  hiringChangeColumn,
  hiringPostComment,
  hiringUpdateCard,
  hiringUpdateCardStatus,
  replaceChildrenInOrgchart,
  resendAdmissionalExamRequest,
  resendDocumentsRequest,
  resetAso,
  resignationPostComment,
  resignationUpdateCard,
  sendAdmissionalExamRequest,
  sendDocumentsRequest,
  sendHiringNextColumn,
  updateModelProposedLetter,
  validateAsoDocument,
} from "./mutations"

const mutation = {
  hiring: {
    checklist: {
      templateNew: checklistTemplateNew,
      templateAdd: checklistTemplateAdd,
      templateRemove: checklistTemplateRemove,
      templateUpdate: checklistTemplateUpdate,
      toggleItem: checklistToggleHiring,
      updateItem: checklistUpdateHiring,
    },
    modelProposedLetter: {
      create: createModelProposedLetter,
      update: updateModelProposedLetter,
      duplicate: duplicateModelProposedLetter,
      delete: deleteModelProposedLetter,
    },
    card: {
      createCard: createHiringCard,
      updateCard: hiringUpdateCard,
      nextColumn: sendHiringNextColumn,
      postComment: hiringPostComment,
      updateStatus: hiringUpdateCardStatus,
      moveColumn: hiringChangeColumn,
      resetAso: resetAso,
    },
    sendAdmissionalExamRequest,
    resendAdmissionalExamRequest,
    candidateValidateDocument,
    dependentValidateDocument,
    sendDocumentsRequest,
    resendDocumentsRequest,
    createBoardReport,
    validateAdmissionalExamDocument: validateAsoDocument,
  },
  resignation: {
    checklist: {
      toggleItem: checklistToggleResignation,
      updateItem: checklistUpdateResignation,
    },
    card: {
      postComment: resignationPostComment,
      createCard: createResignationCard,
      createManyCard: createManyResignationCard,
      updateCard: resignationUpdateCard,
    },
  },
  helper: {
    getS3File,
    getMultipleS3File,
  },
  orgchart: {
    checkLeaderByEmployeeId,
    changeEmployeeInOrgchart,
    replaceChildrenInOrgchart,
    changeMultipleEmployeeInOrgchart,
  },
}

export const api = {
  mutation,
}
