import {
  Button,
  LinkButton,
  Modal,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { StyledModal } from "./styled";
import { useAcceptTerms } from "./hooks";

const ModalAcceptTerms = () => {
  const {
    isFetchingAcceptTerms,
    isModalOpen,
    termsData,
    onAcceptTerm,
    isLoadingAcceptTerms,
  } = useAcceptTerms();

  const onFinish = () => {
    if (!termsData) return;

    onAcceptTerm({ version: termsData?.version });
  };

  const onClose = () => {
    window.location.href = "/home";
  };

  if (isFetchingAcceptTerms) return <></>;

  return (
    <StyledModal open={isModalOpen} onClose={onClose}>
      <>
        <Modal.Content style={{ paddingInline: "40px" }}>
          <Typography
            variant="headline8"
            variantColor="neutral10"
            weight={700}
            style={{ marginBottom: "8px" }}
          >
            Termos e Condições
          </Typography>
          <Typography
            variant="body4"
            variantColor="neutral50"
            style={{ marginBottom: "24px" }}
          >
            Para usar nossos serviços, você deve ler atentamente e aceitar aos
            Termos e Condições
          </Typography>

          <Typography
            variant="headline9"
            variantColor="neutral10"
            style={{ marginBottom: "8px" }}
          >
            {termsData?.legalTitle}
          </Typography>
          <Typography variant="body4" variantColor="neutral40">
            {termsData?.legalText}
          </Typography>
        </Modal.Content>

        <Modal.Footer>
          <LinkButton variant="neutral" onClick={onClose}>
            Ver depois
          </LinkButton>
          <Button
            loading={isLoadingAcceptTerms}
            variant={"primary"}
            onClick={onFinish}
          >
            Concluir
          </Button>
        </Modal.Footer>
      </>
    </StyledModal>
  );
};

export { ModalAcceptTerms };
