const MATH_RANDOM_MAX_STR_SIZE = 6;

export function getRandomStr(length: number) {
  let str = "";
  for (let i = 0; i < Math.ceil(length / MATH_RANDOM_MAX_STR_SIZE); i++) {
    str += Math.random()
      .toString(36)
      .slice(2, MATH_RANDOM_MAX_STR_SIZE + 2);
  }
  return str.slice(0, length);
}
