import { formatNumberToTwoDecimals } from "@utils";
import { BoxContainer, LabelStyle, LabelLineContainer } from "./styled";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  LabelList,
  Tooltip,
} from "recharts";

export type TGraphTwoLinesOneBaseLine = {
  data: {
    name: string;
    line: number;
    defaultLine: number;
  }[];
  settings: {
    line: string;
    defaultLine: string;
    lineColor: string;
    defaultLineColor: string;
    isPercentage: boolean;
  };
};

export const GraphTwoLinesOneBaseLine = ({
  data,
  settings,
}: TGraphTwoLinesOneBaseLine) => {
  const renderCustomLineLabel = (props: any) => {
    const { x, y, value } = props;

    const getLabelWidth = (value: number) => {
      if (value < 1 && value > -1) {
        return 34;
      }

      return 34 + 10 * Math.floor(Math.log10(Math.abs(value)));
    };

    return (
      <g>
        <foreignObject
          x={x - 15}
          y={y - 30}
          width={getLabelWidth(value)}
          height={22}
        >
          <LabelLineContainer>
            <LabelStyle variant="caption">
              {formatNumberToTwoDecimals(value)}
              {settings.isPercentage && "%"}
            </LabelStyle>
          </LabelLineContainer>
        </foreignObject>
      </g>
    );
  };

  // const renderStyledLengend = (value: any) => {
  //   return (
  //     <LabelStyle
  //       tag="span"
  //       variant="body4"
  //       style={{ color: "var(--color-neutral-50)", fontWeight: 600 }}
  //     >
  //       {value}
  //     </LabelStyle>
  //   );
  // };

  const CustomTooltip = ({ payload, active }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#1D161B",
            padding: " 12px 16px",
            borderRadius: "8px",
          }}
        >
          <p className="label" style={{ color: "#fff", fontWeight: 700 }}>
            {`${payload[0].payload.name} `}
          </p>
          <p className="label" style={{ color: "#fff" }}>
            {`${payload[0].name} : ${payload[0].value}`}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <BoxContainer>
      <ResponsiveContainer maxHeight={340} height={220}>
        <ComposedChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" vertical={false} />
          <XAxis dataKey="name" />
          {/* <Legend formatter={renderStyledLengend} /> */}
          <YAxis
            hide={true}
            domain={[0, (dataMax: number) => `${dataMax + dataMax * 0.1}`]}
          />
          <Tooltip content={<CustomTooltip />} />

          <Line
            type="linear"
            dataKey="line"
            name={settings?.line}
            strokeWidth={2}
            stroke={settings?.lineColor}
          >
            <LabelList
              dataKey="line"
              position="top"
              content={renderCustomLineLabel}
            />
          </Line>

          {settings?.defaultLine && (
            <Line
              type="linear"
              dataKey="defaultLine"
              name={settings?.defaultLine}
              dot={false}
              strokeWidth={1}
              strokeDasharray={"3 3"}
              stroke={settings?.defaultLineColor}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </BoxContainer>
  );
};
