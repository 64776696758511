import styled from 'styled-components';

export const DescriptionContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  padding: ${({ theme }) => `${theme.spacings.xs4} ${theme.spacings.xs3}`};
  gap: ${({ theme }) => theme.spacings.xs};
  background-color: ${({ theme }) => theme.colors.neutral[95]};
`;
