import { useMutation } from "@apollo/client";
import { CompanyInput } from "@grapql-schema";
import { dispatchToast } from "@utils";
import { ErrorBoundary } from "@utils/ErrorBoundary";
import { UPDATE_COMPANY } from "src/api/mutations/updateCompany";
import {
  UpdateCompany,
  UpdateCompanyVariables,
} from "src/api/mutations/__generated__/UpdateCompany";

type HookResult = {
  updateCompany: (variables: UpdateCompanyVariables) => void;
  loading: boolean;
};

export const useUpdateCompany = (): HookResult => {
  const [updateCompanyMutation, { loading }] = useMutation<
    UpdateCompany,
    UpdateCompanyVariables
  >(UPDATE_COMPANY, {
    onCompleted: () => {
      dispatchToast({
        type: "success",
        content: "Dados da empresa atualizados com sucesso!",
      });
    },
    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: "error",
        content:
          "Ocorreu um erro ao editar os dados da empresa. Por favor, tente novamente mais tarde.",
      });
    },
    refetchQueries: ["GetCompany"],
    awaitRefetchQueries: true,
  });

  const updateCompany = (variables: UpdateCompanyVariables): void => {
    dispatchToast({
      type: "warning",
      content: "Atualizando dados da empresa...",
    });

    const data: CompanyInput = {
      ...variables.data,
      cityRegistration: variables.data?.cityRegistration || null,
      stateRegistration: variables.data?.stateRegistration || null,
    };

    if (variables.data?.address) {
      data.address = {
        ...variables.data.address,
        complement: variables.data.address?.complement || null,
      };
    }

    if (variables.data?.phone) {
      data.phone = variables.data.phone.replace(/[^0-9]/, "");
    }

    updateCompanyMutation({
      variables: {
        data,
      },
    });
  };

  return {
    updateCompany,
    loading,
  };
};
