import { PillButton, Table } from '@flash-tecnologia/hros-web-ui-v2';
import ModalService from '@frontend/services/ModalService';
import { BulkActions } from './BulkActions';
import { useSearchRequests } from './data/useSearchRequests';
import { EmptyState } from './EmptyState';
import RequestBalanceFilterDrawer from './RequestBalanceFilterDrawer';

type Props = {
  type: 'approval' | 'deposit';
};

export default function RequestBalanceSectionV2({ type }: Props) {
  const {
    data,
    filters,
    loading,
    setFilters,
    pagination,
    setPagination,
    setStringSearch,
    table,
    isFiltered,
  } = useSearchRequests({ type });

  function onShowFilters() {
    ModalService.show(RequestBalanceFilterDrawer, {
      filters,
      setFilters,
    });
  }

  function resolveVision() {
    if (filters.status && filters.status.length === 1) {
      return ['preview'].includes(filters.status[0]) ? 'PENDING' : 'ALL';
    }

    if (filters.approver?.status === 'PENDING') {
      return 'PENDING';
    }

    return 'ALL';
  }

  return (
    <>
      <Table.Root variant="soft">
        <Table.Content>
          <Table.FilterSearch
            onSearch={(e) => setStringSearch(e.target.value)}
            visions={[
              {
                id: 'PENDING',
                label: `${type === 'approval' ? 'Aprovação' : 'Depósito'} pendente`,
              },
              { id: 'ALL', label: 'Tudo' },
            ]}
            onChangeVision={(id) => {
              if (id === 'PENDING' && type === 'approval')
                setFilters({ approver: { status: 'PENDING' } });
              if (id === 'PENDING' && type === 'deposit')
                setFilters({ status: ['preview'] });
              if (id === 'ALL')
                setFilters({ status: undefined, approver: undefined });
            }}
            vision={resolveVision()}
          >
            <PillButton
              icon="IconFilter"
              label="Filtros"
              onClick={onShowFilters}
              variant="default"
              size="small"
              type="primary"
            />
          </Table.FilterSearch>

          {loading || table.rows.length > 0 ? (
            <>
              <Table.Grid.Root loading={loading}>
                <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
                {table.rows.map((row, index) => (
                  <Table.Grid.Row key={index + row.id} row={row} />
                ))}
              </Table.Grid.Root>
              <Table.Pagination
                count={data?.pagination?.totalItems}
                onPaginationChange={({ pageSize, pageNumber }) =>
                  setPagination({ ...pagination, pageSize, pageNumber })
                }
                showItemRange
                pagination={pagination}
                pageSizeOptions={[
                  {
                    label: '5 itens',
                    value: 5,
                  },
                  {
                    label: '10 itens',
                    value: 10,
                  },
                  {
                    label: '25 itens',
                    value: 25,
                  },
                ]}
              />
            </>
          ) : (
            <EmptyState type={type} isFiltered={isFiltered} />
          )}
        </Table.Content>
        <BulkActions table={table} />
      </Table.Root>
    </>
  );
}
