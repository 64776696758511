import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 100%;
  align-items: center;
  margin-top: 8px;
  margin-left: 16px;
  flex-wrap: wrap;
`;
