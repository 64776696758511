import { PillButton, Spinner, Toggle, Tooltip } from "@flash-tecnologia/hros-web-ui-v2"; //TODO passar pelo styled ou criar componente

import { Container, FormControlLabel, Label, LoaderContainer } from "./styled";

type SwitchCardProps = {
  isActive?: boolean;
  label: string;
  onToggle(): void;
  onEdit?(): void;
  onDelete?(): void;
  isLoading: boolean;
  disableDelete: boolean;
  disableDeleteReason?: string;
};

export const SwitchCard = ({
  isActive,
  label,
  onToggle,
  onEdit,
  onDelete,
  isLoading,
  disableDelete = false,
  disableDeleteReason,
}: SwitchCardProps) => {
  let actionsQuantity = 0;
  onEdit && actionsQuantity++;
  onDelete && actionsQuantity++;

  return (
    <Container isLoading={isLoading}>
      <FormControlLabel
        actions={actionsQuantity}
        control={
          isLoading ? (
            <LoaderContainer>
              <Spinner size={24} variant="primary" />
            </LoaderContainer>
          ) : (
            <Toggle checked={isActive} onChange={onToggle} />
          )
        }
        label={
          <Tooltip title={label}>
            <div>
              <Label isLoading={isLoading} variant="body3">
                {label}
              </Label>
            </div>
          </Tooltip>
        }
      />
      <div className="buttons-container">
        {onEdit && (
          <PillButton icon="IconPencil" size="small" variant="default" onClick={onEdit} disabled={isLoading} />
        )}
        {onDelete && (
          <Tooltip title={disableDeleteReason}>
            <div>
              <PillButton
                icon="IconTrash"
                size="small"
                variant="default"
                onClick={onDelete}
                disabled={disableDelete || isLoading}
              />
            </div>
          </Tooltip>
        )}
      </div>
    </Container>
  );
};
