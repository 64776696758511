import { trpc } from '@frontend/trpc';
import { QUERY_RETRY_CONFIG } from '@frontend/utils/constants';

type Input = {
  orderId?: string;
  paymentMethod?: 'BILLET' | 'PIX';
};

export default function useGetOrder(input: Input) {
  const retryConfig = QUERY_RETRY_CONFIG(input.paymentMethod ?? 'BILLET');
  const order = trpc.company.billing.paymentInfo.useQuery(input.orderId ?? '', {
    enabled: !!input.orderId,
    ...retryConfig,
  });

  return {
    barcode: order.data?.code,
    pixCode: order.data?.code,
    pdfUrl: order.data?.pdfLink,
    isLoading: order.isFetching,
    isError: order.isError,
    refetch: order.refetch,
  };
}
