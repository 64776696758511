import { ShapeIcon } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import Typography from '@frontend/components/Typography';

type Props = {
  type: 'approval' | 'deposit';
  isFiltered: boolean;
};

const data = {
  approval: {
    title: 'Nenhum pedido de saldo pendente',
    description:
      'Você poderá revisar aqui os pedidos de saldo corporativo que precisam da sua aprovação.',
    image:
      'https://public-images-flash.s3.sa-east-1.amazonaws.com/expense/corporate-card/request-balance/empty-state-approval.svg',
  },
  deposit: {
    title: 'Nenhum pedido de saldo pendente',
    description:
      'Você poderá revisar aqui os pedidos de saldo que já foram aprovados e que precisam que você conclua o depósito.',
    image:
      'https://public-images-flash.s3.sa-east-1.amazonaws.com/expense/corporate-card/request-balance/empty-state-deposit.svg',
  },
};

export function EmptyState({ type, isFiltered }: Props) {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      gap="xs"
      padding="xs"
    >
      {!isFiltered ? (
        <img src={data[type].image} alt="Nenhum pedido de saldo pendente" />
      ) : (
        <ShapeIcon
          stroke="neutral"
          variant="neutral"
          name="IconZoomCancel"
          size={64}
        />
      )}
      <Flex direction="column" align="center" justify="center">
        <Typography.Headline8 color="neutral_50">
          {!isFiltered && data[type].title}
          {isFiltered && 'Nenhum pedido de saldo encontrado'}
        </Typography.Headline8>
        <Typography.Body4 color="neutral_50">
          {!isFiltered && data[type].description}
          {isFiltered &&
            'Revise a busca, o período selecionado ou os filtros e tente novamente.'}
        </Typography.Body4>
      </Flex>
    </Flex>
  );
}
