import { ModuleErrorBoundary } from '@atoms/ModuleErrorBoundary';
import { external } from '@flash-hros/corporate-card';

export function BalanceApprovalTab() {
  const { component, label } = external.RequestBalanceApprovalTab();
  return {
    component: (
      <ModuleErrorBoundary
        moduleName="Cartão Corporativo"
        title="Erro ao tentar acessar os pedidos de saldo para aprovação">
        {component}
      </ModuleErrorBoundary>
    ),
    label,
  };
}
