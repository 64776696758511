import { z } from "zod";

const EnvSchema = z.object({
  BUILD_ENV: z.enum(["production", "staging", "development"]),
  SENTRY_DSN: z.string(),
  UNLEASH_URL: z.string(),
  UNLEASH_TOKEN: z.string(),
});

/** Environment variables. Used for secrets */
const EnvService = EnvSchema.parse({
  BUILD_ENV: process.env.BUILD_ENV,
  SENTRY_DSN: process.env.SENTRY_DSN,
  UNLEASH_URL: process.env.UNLEASH_URL,
  UNLEASH_TOKEN: process.env.UNLEASH_TOKEN,
});

export default EnvService;
