import { CopyButton } from '@frontend/components/CopyButton';
import Flex from '@frontend/components/frames/Flex';
import { BilletUtilities } from '@frontend/utils/billet';
import Barcode from 'react-barcode';

type Props = {
  barcode: string;
  width?: number;
  height?: number;
};

export function BarcodeGenerator({ barcode, width = 1, height = 50 }: Props) {
  const formattedLine = getFormattedLine(barcode);

  return (
    <Flex direction="column" align="center" justify="center">
      <Barcode
        value={formattedLine}
        width={width}
        height={height}
        displayValue={true}
        fontSize={16}
      />
      <Flex padding="xs4">
        <CopyButton
          textToCopy={formattedLine}
          copiedLabel="Código copiado!"
          defaultLabel="Copiar código de barras"
        />
      </Flex>
    </Flex>
  );
}

function getFormattedLine(barcode: string): string {
  const billetUtilities = new BilletUtilities();
  return billetUtilities.getFormattedLine(barcode);
}
