import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { dayjs, Radio, PageContainer } from '@flash-tecnologia/hros-web-ui-v2';
import { ButtonArea } from '../../components';
import {
  MainContainer,
  LeftColumn,
  RightColumn,
  Card,
  StyledIcon,
  FlexContainer,
  TextContainer,
  ContentContainer,
  DateContainer,
  StyledDatePicker,
  StyledTimePicker,
} from './styled';

import {
  HelperTextContainer,
  ErrorText,
  StyledText,
  StyledTitle,
} from 'src/common/styles/general-styles';
import { getWorksheetUrl } from '../../helpers/getWorksheetUrl';
import { EmployeesWorksheetSteps, ImportType } from '../../types';
import {
  CreateImportInput,
  useCreateImport,
} from '../../hooks/useCreateImport';
import { CreateImportPayloadBuilder } from '../../helpers/createImportPayloadBuilder';
import { ReactivateOptions, MigrateOptions } from './components';

const validationSchema = yup.object({
  scheduleInvite: yup.boolean().required('Este campo deve ser preenchido.'),
  sendInvite: yup.boolean().required('Este campo deve ser preenchido.'),
  date: yup.string().required('Por favor, selecione a hora do envio'),
});

interface IPermissionsProps {
  operation: ImportType;
}

interface INavigationState {
  droppedFile: any;
  fileData: any;
  fileName: string;
  fileType: string;
  initialCell: string;
  dataTable?: any;
  defaultConfig: boolean;
  simpleImport: boolean;
  separator: string;
  hasHeader?: boolean;
  cellColumn?: string;
  cellLine?: number;
  nameField?: string;
  documentField?: string;
  phoneField?: string;
  emailField?: string;
  registrationNumberField: string;
  fileDictionary: CreateImportInput['fileDictionary'];
}

export const Permissions = ({ operation }: IPermissionsProps) => {
  const [pickedDate, setPickedDate] = useState() as any;
  const navigate = useNavigate();
  const navigationState = useLocation() as any;
  const currentState: INavigationState = useMemo(
    () => navigationState.state,
    [navigationState],
  );

  useEffect(() => {
    if (!currentState) {
      navigate(getWorksheetUrl(operation, EmployeesWorksheetSteps.uploadFile));
    }
  }, []);

  const { isLoading, importId, createImportSheet } = useCreateImport();

  useEffect(() => {
    if (importId)
      navigate(
        getWorksheetUrl(operation, EmployeesWorksheetSteps.importStatus),
        { state: { refresh: true } },
      );
  }, [importId]);

  const formik = useFormik({
    initialValues: {
      scheduleInvite: false,
      sendInvite: false,
      notMigrate: true,
      migrate: false,
      notReactivate: true,
      reactivate: false,
      date: new Date(),
    },

    validationSchema: validationSchema,

    onSubmit: async () => {
      await handleSubmit();
    },
  });

  const handleSubmit = async () => {
    const { droppedFile, fileDictionary, separator } = currentState;
    const payload = new CreateImportPayloadBuilder()
      .withActionConfig({
        operation,
        migrate: formik.values.migrate,
        reactivate: formik.values.reactivate,
        scheduleInvite: formik.values.scheduleInvite,
        scheduleDate: formik.values.date,
      })
      .withFileDictionary(fileDictionary)
      .withSeparator(separator)
      .build(droppedFile);
    await createImportSheet(payload);
  };

  return (
    <>
      <PageContainer>
        <MainContainer>
          <LeftColumn>
            <StyledTitle
              setColor="secondary50"
              variant="headline7"
              children="Configurações"
            />
            <StyledText
              setColor="neutral50"
              variant="body3"
              children="Gerencie casos de cadastros duplicados e escolha como enviar os convites de primeiro acesso para as pessoas importadas, permitindo que elas criem suas contas e acessem a plataforma"
            />
          </LeftColumn>
          <RightColumn>
            <Card>
              <TextContainer>
                <StyledTitle
                  setColor="neutral30"
                  variant="headline8"
                  children="Envio do convite de primeiro acesso"
                />
                <StyledText
                  setColor="neutral50"
                  variant="body4"
                  children="Realize o envio do convite logo após a finalização do cadastro ou defina uma data e horário específicos. 
Através desse convite de primeiro acesso, a pessoa irá criar sua conta para utilizar o app e plataforma da Flash."
                />
              </TextContainer>
              <FlexContainer>
                <ContentContainer>
                  <Radio
                    size="small"
                    name="sendInvite"
                    checked={formik.values.sendInvite}
                    onChange={() => {
                      formik.setFieldValue('sendInvite', true);
                      formik.setFieldValue('scheduleInvite', false);
                      formik.setFieldValue('date', new Date());
                    }}
                    error={
                      formik.touched.sendInvite &&
                      Boolean(formik.errors.sendInvite)
                    }
                  />
                  <StyledTitle
                    setColor={
                      formik.touched.sendInvite &&
                      Boolean(formik.errors.sendInvite)
                        ? 'feedback50'
                        : 'neutral50'
                    }
                    variant="body4"
                    children="Enviar imediamente"
                  />
                </ContentContainer>
                <ContentContainer>
                  <Radio
                    size="small"
                    name="scheduleInvite"
                    checked={formik.values.scheduleInvite}
                    onChange={() => {
                      formik.setFieldValue('sendInvite', false);
                      formik.setFieldValue('scheduleInvite', true);
                      formik.setFieldValue('date', new Date());
                    }}
                    error={
                      formik.touched.scheduleInvite &&
                      Boolean(formik.errors.scheduleInvite)
                    }
                  />
                  <StyledTitle
                    setColor={
                      formik.touched.scheduleInvite &&
                      Boolean(formik.errors.scheduleInvite)
                        ? 'feedback50'
                        : 'neutral50'
                    }
                    variant="body4"
                    children="Agendar envio"
                  />
                </ContentContainer>
              </FlexContainer>
              {formik.touched.scheduleInvite &&
                Boolean(formik.errors.scheduleInvite) &&
                Boolean(formik.errors.sendInvite) && (
                  <HelperTextContainer>
                    <StyledIcon size={15} name="IconAlertCircle" fill="none" />
                    <ErrorText variant="body4">
                      Por favor, selecione uma opção.
                    </ErrorText>
                  </HelperTextContainer>
                )}
              {formik.values.scheduleInvite && (
                <DateContainer>
                  <StyledDatePicker
                    fromDate={dayjs().add(1, 'day').toDate()}
                    label={'Data de envio'}
                    value={pickedDate}
                    name={'date'}
                    onDateChange={(e) => {
                      setPickedDate(e);
                      formik.handleChange({
                        target: { id: 'date', value: e },
                      });
                    }}
                    error={formik.touched.date && Boolean(formik.errors.date)}
                  />

                  <StyledTimePicker
                    label={'Hora de envio'}
                    value={dayjs().hour(8).minute(0).second(0) as any}
                    onChange={() => {}}
                    disabled={true}
                  />
                </DateContainer>
              )}
            </Card>
            <MigrateOptions formik={formik} />
            <ReactivateOptions formik={formik} />
          </RightColumn>
        </MainContainer>
      </PageContainer>

      <ButtonArea
        onForward={() => {
          formik.handleSubmit();
        }}
        onCancel={() => navigate('/employees')}
        onBackwards={() => {
          if (!currentState.defaultConfig) {
            navigate(
              getWorksheetUrl(
                operation,
                EmployeesWorksheetSteps.dataAssociation,
              ),
              {
                state: {
                  ...currentState,
                },
              },
            );
          } else {
            navigate(
              getWorksheetUrl(operation, EmployeesWorksheetSteps.uploadFile),
            );
          }
        }}
        loading={isLoading}
      />
    </>
  );
};
