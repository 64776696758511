import { DateRangeField as FlashDateRangeField, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";

import { Skeleton } from "$atoms";
import { DateRange, getLastMinuteAndSecondOfDay } from "$utils";
import { Container, WarningBadge, WarningBadgeContainer } from "./styled";

type PropsType = {
  /** Label for the date range field (use default from i18n if undefined) */
  label?: string;
  /** Selected date range value */
  value: DateRange;
  /** Function to handle changes in the date range */
  onChange: (dateRange: DateRange) => void;
  /** Show alert badge on top right of the button  */
  showAlertBadge?: boolean;
  /** Alert badge tooltip (note that tooltip will only show if showAlertBadge is true) */
  alertBadgeTooltip?: string;
  /** Toggles loading state */
  isLoading?: boolean;
};

export const DateRangeField = ({ label, value, onChange, showAlertBadge, alertBadgeTooltip, isLoading }: PropsType) => {
  const { t } = useTranslation("translations", { keyPrefix: "molecules.dateRangeField" });

  if (isLoading) {
    return <Skeleton width={268} height={58} />;
  }

  return (
    <Container>
      <FlashDateRangeField
        label={label ?? t("label")}
        initialValue={value}
        onChange={(e) => {
          if (e?.from !== undefined && e?.to !== undefined)
            onChange({ from: e?.from, to: getLastMinuteAndSecondOfDay(e?.to) });
        }}
      />
      {showAlertBadge ? (
        <Tooltip title={alertBadgeTooltip}>
          <WarningBadgeContainer>
            <WarningBadge />
          </WarningBadgeContainer>
        </Tooltip>
      ) : undefined}
    </Container>
  );
};
