import { Tabs } from '@/components/Tabs';
import { UIEventHandler, useEffect, useRef, useState } from 'react';
import { InboxMessageList } from '../InboxMessageList';
import { TabTitle } from '@/components/Tabs/components/TabTitle';
import { StyledListContent, StyledTabContent } from './styles';
import { NotificationList } from '../../../NotificationList/components';
import { useGetFeed } from '@/hooks/use-get-feed';
import { NotificationProps } from '../../../NotificationList/types';
import { useGetTotalNewNotifications } from '@/hooks/use-get-total-new-notifications';
import { useGetUnacknowledgeInboxMessages } from '@/hooks/use-get-unacknowledged-inbox-messages';

type NotificationContentProps = {
  bgColor: string;
  inboxOptions?: {
    invertTitle: boolean;
    uniqueList: boolean;
    showAckAll: boolean;
  };
  notificationsOptions?: {
    showNotificationDetails: boolean;
  };
};

export const NotificationContent = ({
  bgColor,
  inboxOptions = {
    invertTitle: true,
    uniqueList: true,
    showAckAll: false,
  },
  notificationsOptions = { showNotificationDetails: false },
}: NotificationContentProps) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const [notifications, setNotifications] = useState<NotificationProps[]>([]);

  const [feedQuery, setFeedQuery] = useState<{
    cursor: string;
    limit: number;
  }>({
    cursor: '',
    limit: 10,
  });

  const [fetchInboxMessages, setFetchInboxMessages] = useState<boolean>(false);
  const [fetchNotification, setFetchNotification] = useState<boolean>(false);

  const onScroll: UIEventHandler<HTMLDivElement> = (event) => {
    const element = event.target as HTMLDivElement;
    const isBotton =
      Math.abs(
        element.scrollHeight - (element.scrollTop + element.clientHeight),
      ) <= 200;
    if (isBotton) {
      switch (selectedTab) {
        case 0:
          setFetchInboxMessages(true);
          break;
        case 1:
          setFetchNotification(true);
          break;
      }
      setFetchInboxMessages(true);
    }
  };
  const { data: totalNewNotifications } = useGetTotalNewNotifications();
  const { data: unacknowledgeInboxMessages } =
    useGetUnacknowledgeInboxMessages();

  const { isLoading: feedDataIsLoading, data: feedData } = useGetFeed({
    ...feedQuery,
  });
  useEffect(() => {
    if (feedData?.data?.length) {
      const newNotifications = feedData.data;
      setNotifications((oldValue) => [
        ...oldValue,
        ...newNotifications.filter(
          ({ id }) =>
            !notifications.find(
              ({ id: notificationId }) => notificationId === id,
            ),
        ),
      ]);
    }
  }, [feedData]);

  useEffect(() => {
    if (fetchNotification && !feedDataIsLoading && feedData?.data.length) {
      const { id } = feedData.data.slice(feedData.data.length - 1)[0];
      setFeedQuery({
        cursor: id,
        limit: 10,
      });
    }
    setFetchNotification(false);
  }, [fetchNotification]);

  return (
    <Tabs
      onTabChange={setSelectedTab}
      tabs={[
        {
          title: (
            <TabTitle
              text="Notificações da Flash"
              value={unacknowledgeInboxMessages || 0}
            />
          ),
          content: (
            <StyledTabContent bgColor={bgColor}>
              <StyledListContent
                listStyle={{
                  spacing_x: 0,
                  spacing_y: 16,
                  gap: 8,
                  padding_right: 24,
                  padding_left: 24.5,
                }}
                onScroll={onScroll}
              >
                <InboxMessageList
                  fetchInboxMessages={fetchInboxMessages}
                  setFetchInboxMessages={setFetchInboxMessages}
                  opt={inboxOptions}
                />
              </StyledListContent>
            </StyledTabContent>
          ),
        },
        {
          title: (
            <TabTitle
              text="Notificações da sua empresa"
              value={totalNewNotifications ?? 0}
            />
          ),
          content: (
            <StyledTabContent bgColor={bgColor}>
              <StyledListContent
                listStyle={{
                  spacing_x: 0,
                  spacing_y: 16,
                  gap: 8,
                  padding_right: 24,
                  padding_left: 24.5,
                }}
                onScroll={onScroll}
              >
                <NotificationList
                  isLoading={feedDataIsLoading}
                  notifications={notifications}
                  opt={notificationsOptions}
                />
              </StyledListContent>
            </StyledTabContent>
          ),
        },
      ]}
    />
  );
};
