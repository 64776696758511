import {
  Typography,
  Icons,
  IconsProps,
} from '@flash-tecnologia/hros-web-ui-v2';
import { TagContainer } from './tag.styles';

export interface TagProps {
  iconColor: string;
  iconName: string;
  variantColor: string;
  backgroundColor: string;
  text: string;
}

export const Tag = (props: TagProps) => {
  return (
    <TagContainer style={{ backgroundColor: props.backgroundColor }}>
      <Icons
        name={props.iconName as IconsProps['name']}
        size={16}
        color={props.iconColor}
      />
      <Typography
        variantColor={props.variantColor}
        variant="caption"
        style={{ textAlign: 'center' }}
      >
        {props.text}
      </Typography>
    </TagContainer>
  );
};
