import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createTRPCReact, httpBatchLink } from "@trpc/react-query";
import { getAccessToken } from "@flash-tecnologia/hros-web-utility";
import { useState } from "react";
import { TrpcAppRouter } from "server/src/routers/trpc";
import EnvService from "./EnvService";

export const trpc = createTRPCReact<TrpcAppRouter>();

const url = EnvService.BFF_URL;

export function TrpcProvider({ children }: { children: React.ReactNode }) {
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url,
          headers: async () => {
            const token = await getAccessToken();
            return { "x-flash-auth": `Bearer ${token}` };
          },
        }),
      ],
    })
  );

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
}
