import { Modal } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  && {
    .modal-container {
      border-radius: 16px;
      max-height: 100%;
      max-width: 800px;
    }
    .modal-content-area {
      padding: 0px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 40px;
  gap: 24px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0px 0px 28px;
  gap: 8px;
`;

export const Companies = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AgreementContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 40px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ebe6e9;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 12px 0px 12px;
`;
