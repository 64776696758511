import { Icons, Tooltip, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'

import { IOrderSummaryTotalAmountsProps } from '../../order-summary.interface'
import { OrderSummary } from '../summary'

export const OrderSummaryTotalAmount = ({
  totals,
}: {
  totals: IOrderSummaryTotalAmountsProps[]
}) => {
  const { t } = useTranslation()
  return (
    <OrderSummary.Root>
      <OrderSummary.ListTitle>
        <Typography variant="headline8" color="neutral.20">
          {t('order.checkout.orderSummary.totalAmount.title')}
        </Typography>
        <Tooltip
          title={t('order.checkout.orderSummary.totalAmount.tooltip')}
          arrow
          placement="right"
        >
          <div>
            <Icons name="IconInfoCircle" size={24} />
          </div>
        </Tooltip>
      </OrderSummary.ListTitle>
      <OrderSummary.List>
        {totals.map(({ value, key }) => (
          <OrderSummary.Item key={key}>
            <OrderSummary.ItemRoot>
              <Icons name="IconCreditCard" size={24} />
              <Typography variant="body4" color="neutral.30">
                {t(`order.checkout.orderSummary.totalAmount.${key}`)}
              </Typography>
            </OrderSummary.ItemRoot>
            <Typography variant="body4" weight={700}>
              {t('order.checkout.orderSummary.totalAmount.value', {
                value,
              })}
            </Typography>
          </OrderSummary.Item>
        ))}
      </OrderSummary.List>
    </OrderSummary.Root>
  )
}
