import { Skeleton as FlashSkeleton, SkeletonProps as FlashSkeletonProps } from "@flash-tecnologia/hros-web-ui-v2";
import { CustomSkeletonStyle, DefaultSkeletonStyle } from "./styled";

type SkeletonProps = FlashSkeletonProps & {
  /**
   * Defines the style variant for the skeleton.
   * - `"default"`: Uses the default skeleton styling.
   * - `"custom"`: Applies custom skeleton styling.
   */
  textVariant?: "default" | "custom";
};

export const Skeleton = ({ textVariant = "default", ...props }: SkeletonProps) => {
  const SkeletonComponent = textVariant === "custom" ? CustomSkeletonStyle : DefaultSkeletonStyle;

  return (
    <SkeletonComponent>
      <FlashSkeleton {...props}>{props.children}</FlashSkeleton>
    </SkeletonComponent>
  );
};
