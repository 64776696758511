import { Icons, Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;
const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

const StyledIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
  padding: 12px;
  background: rgba(254, 43, 143, 0.16);
  border-radius: 25px;
`;

const StyledTag = styled(Tag)`
  cursor: auto;
  padding: 0px 8px;
  pointer-events: none;
  white-space: pre;
`;

export { StyledTitle, StyledText, StyledIcon, StyledTag };
