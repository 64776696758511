import { Container, TabItemContainer } from './tab-selector.styles';
import {
  Typography,
  Icons,
  IconsProps,
} from '@flash-tecnologia/hros-web-ui-v2';
export interface TabSelectorProps {
  onSelectTab: (index: number) => void;
  tabs: TabItemProps[];
}

export interface TabItemProps {
  id: string;
  title: string;
  isSelected: boolean;
  icon: string;
}
export const TabSelector = (props: TabSelectorProps) => {
  return (
    <Container>
      {props.tabs.map((tab, index) => (
        <div key={tab.title}>
          <TabItemContainer
            isSelected={tab.isSelected}
            onClick={() => props.onSelectTab(index)}
          >
            <Icons
              name={tab.icon as IconsProps['name']}
              size={16}
              color={
                tab.isSelected
                  ? 'var(--color-secondary-50)'
                  : 'var(--color-neutral-40)'
              }
            />
            <Typography
              variant="headline9"
              variantColor={
                tab.isSelected
                  ? 'var(--color-secondary-50)'
                  : 'var(--color-neutral-40)'
              }
            >
              {tab.title}
            </Typography>
          </TabItemContainer>
        </div>
      ))}
    </Container>
  );
};
