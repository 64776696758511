import { useMemo, SetStateAction } from "react";

import { Tag, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledIcon, dispatchToast, track } from "@utils";
import { cloneDeep, uniq } from "lodash-es";

import type { BulkActionsCommonProps } from "@components/DataGrid/types";

import type { FeedbackParticipant } from "server/src/services/feedback/types";

type BulkActionsProps = BulkActionsCommonProps<FeedbackParticipant> & {
  data: FeedbackParticipant[];
  setBulkSelection: (
    value: SetStateAction<{
      isOpen: boolean;
      peopleToSend: string[];
    }>
  ) => void;
};

export const BulkActions = ({
  table,
  totalCount,
  totalSelected,
  data,
  setBulkSelection,
}: BulkActionsProps) => {
  const selectedIds = table.selected?.selected
    .filter((item) => item.original.status === "unsent")
    .map((item) => item.original.from?._id);

  const allSelected = table.selected.allSelected;

  const selectedEmpty = !table.selected.selected.length;

  const selectedLeaderIds = useMemo(() => {
    const clonedSelectedIds = uniq(cloneDeep(selectedIds));

    const dataCloned = cloneDeep(data);

    const dataFiltered = dataCloned.filter((item) => item.status === "unsent");

    if (!allSelected) return clonedSelectedIds;

    if (selectedEmpty) {
      track({
        name: "people_strategic_hr_performance_company_cycles_cycledetails_feedbacks_selectall_clicked",
      });
      return uniq(cloneDeep(dataFiltered).map((d) => d.from?._id));
    }

    const selectedItems = dataFiltered.filter(
      (item) => !clonedSelectedIds.includes(item._id)
    );

    return uniq(selectedItems.map((d) => d.from?._id));
  }, [table.selected]);

  return (
    <>
      <th style={{ padding: "24px" }}>
        <Tag variant={"primary"} as={"div"}>
          {totalSelected} de {totalCount} selecionado
          {totalSelected > 1 && "s"}
        </Tag>
      </th>

      <th style={{ padding: "24px" }}>
        <LinkButton
          variant="neutral"
          onClick={() => {
            if (!selectedLeaderIds.length) {
              dispatchToast({
                type: "error",
                content: "Todos os líderes selecionados já enviaram feedback.",
              });

              return;
            }

            track({
              name: "people_strategic_hr_performance_company_cycles_cycledetails_feedbacks_sendreminder_clicked",
            });
            setBulkSelection({
              isOpen: true,
              peopleToSend: selectedLeaderIds,
            });
          }}
        >
          Enviar lembrete à líderes com pendências
          <StyledIcon name="IconMail" setColor="neutral50" size={18} />
        </LinkButton>
      </th>
    </>
  );
};
