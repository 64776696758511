import React from "react";
import { PdfIframe } from "./styled";

export type PdfReaderProps = {
  /** File name or URL of the PDF to be displayed */
  filename: string;
  /** Alternative text for accessibility */
  title?: string;
};

/**
 * Component for displaying PDFs in an iframe.
 */
export const PdfReader: React.FC<PdfReaderProps> = ({ filename, title }) => {
  if (!filename) {
    console.warn("No PDF filename provided for PdfReader.");
    return null;
  }

  return <PdfIframe src={filename} title={title || "PDF Viewer"} />;
};
