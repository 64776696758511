import {
  ApolloLink,
  HttpLink,
  ApolloClient,
  InMemoryCache,
} from "@apollo/client"

import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"

import { getAccessToken, getFromLS } from "@flash-tecnologia/hros-web-utility"
import { Authentication } from "@flash-hros/auth-helper"

const { CognitoSignOut } = Authentication

export const makeApolloClient = (): any => {
  const httpLink = new HttpLink({
    // uri: `http://localhost:4002/graphql/hiring`,
    uri: `${process.env.GRAPH_BFF_URL}/graphql/hiring`,
  })

  const contextLink = setContext(async (_, { headers }) => {
    const accessToken = await getAccessToken()
    const { id: selectedCompanyId } = getFromLS("selectedCompany")

    const context = {
      headers: {
        ...headers,
        ...(accessToken && { 
          Authorization: accessToken,
          "x-flash-auth": `Bearer ${accessToken}`
        }),
        ...(selectedCompanyId && { "X-Selected-Company": selectedCompanyId }),
      },
    }
    return context
  })

  const authLink = onError(() => {})

  const client = new ApolloClient({
    link: ApolloLink.from([contextLink, authLink, httpLink]),
    cache: new InMemoryCache({ addTypename: true }),
    resolvers: {},
    connectToDevTools: true,
  })

  client.onClearStore(async () => {
    await CognitoSignOut({})
    return
  })

  return client
}
