import React from "react";
import { Caption, Container, Title } from "./styled";

export interface MessageOptionsProps {
  title?: string;
  caption?: string;
  image?: React.ReactNode;
}

export const AutocompleteMessageOptions = ({ title, caption, image }: MessageOptionsProps) => {
  return (
    <Container>
      {image}
      {title && <Title variant="body3">{title}</Title>}
      {caption && <Caption variant="caption">{caption}</Caption>}
    </Container>
  );
};
