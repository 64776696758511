import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import SelectField from '@frontend/components/inputs/SelectField';
import LinkButton from '@frontend/components/LinkButton';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import { AddFirstPolicy } from '@frontend/pages/Home/FlashCard/modals/LinkPolicyModal/AddFirstPolicy';
import { ROUTE_CompanyCustomization } from '@frontend/routes/ExternalRoutes';
import { Controller } from 'react-hook-form';
import { useCreateVirtualCardContext } from '../../../data/useCreateVirtualCardContext';

export function PoliciesFormSection() {
  const context = useCreateVirtualCardContext();
  const hasPolicies = context.policies.length > 0;

  return (
    <>
      <Flex justify="space-between" gap="xs3" wrap="wrap">
        <div>
          <Flex gap="xs4">
            <Typography.Headline8 as="h2" color="neutral_20">
              Política de uso do cartão
            </Typography.Headline8>

            <Tag variant="neutral" size="sm">
              Opcional
            </Tag>
          </Flex>

          <Typography.Body4 color="neutral_40">
            Vincule ao cartão corporativo uma política de uso definida pela
            empresa.
          </Typography.Body4>
        </div>

        <div>
          <LinkButton
            href={ROUTE_CompanyCustomization.buildPath(
              {},
              { tab: 'corporate-card' },
            )}
            icon="IconExternalLink"
            variant="default"
            target="_blank"
          >
            Ver políticas
          </LinkButton>
        </div>
      </Flex>

      {context.loadingPolicies ? (
        <Skeleton height={58} />
      ) : hasPolicies ? (
        <Controller
          control={context.form.control}
          name="policyId"
          render={({ field, fieldState }) => (
            <SelectField
              fullWidth
              label="Selecione uma política"
              {...field}
              options={context.policies}
              variant="filled"
              error={Boolean(fieldState.error)}
              helperText={fieldState.error?.message}
            />
          )}
        />
      ) : (
        <AddFirstPolicy onAddPolicyClick={() => null} />
      )}
    </>
  );
}
