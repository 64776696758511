import { CheckboxFilter } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import Drawer from '@frontend/components/modals/Drawer';
import TagFilters from '@frontend/components/TagFilters';
import { useGetEmployees } from '@frontend/pagesV2/user-accounts/UserAccounts/flows/Deposit/data/useGetEmployees';
import ModalService from '@frontend/services/ModalService';
import { useState } from 'react';
import { BalanceRequestFilter } from './data/useSearchRequests';

const statusOptions = [
  {
    label: 'Pendente',
    value: 'preview',
  },
  {
    label: 'Aguardando pagamento',
    value: 'waiting',
  },
  {
    label: 'Pago',
    value: 'paid',
  },
  {
    label: 'Reprovado',
    value: 'reproved',
  },
  {
    label: 'Expirado',
    value: 'expired',
  },
] as const;

export type Props = {
  filters: BalanceRequestFilter;
  setFilters(filters: BalanceRequestFilter): void;
};

function RequestBalanceFilterDrawer({ filters, setFilters }: Props) {
  const [selectedFilters, setSelectedFilters] = useState(filters);
  const employees = useGetEmployees();

  const controller = ModalService.useModalController();

  function handleClose() {
    setFilters(selectedFilters);
    controller.remove();
  }

  function getOptions() {
    return statusOptions.map((option) => {
      return {
        ...option,
        onChange: () => {
          const status = selectedFilters.status;
          if (status && status.includes(option.value)) {
            const newStatus = status.filter((s) => s !== option.value);
            setSelectedFilters({
              ...selectedFilters,
              status: newStatus,
            });
            return;
          }
          setSelectedFilters({
            ...selectedFilters,
            status: selectedFilters?.status
              ? [...selectedFilters.status, option.value]
              : [option.value],
          });
        },
        checked: !!selectedFilters?.status?.includes(option.value),
      };
    });
  }

  function getEmployeeOptions() {
    return employees?.employees.map((employee) => ({
      value: employee.value,
      label: employee.title,
      description: employee.description,
      onChange: () => {
        const employeeIds = selectedFilters.employeeId;
        if (employeeIds && employeeIds.includes(employee.value)) {
          const newEmployeeIds = employeeIds.filter(
            (id) => id !== employee.value,
          );
          setSelectedFilters({
            ...selectedFilters,
            employeeId: newEmployeeIds,
          });
          return;
        }
        setSelectedFilters({
          ...selectedFilters,
          employeeId: selectedFilters?.employeeId
            ? [...selectedFilters.employeeId, employee.value]
            : [employee.value],
        });
      },
      checked: !!selectedFilters?.employeeId?.includes(employee.value),
    }));
  }

  return (
    <Drawer
      visible={controller.visible}
      title="Filtros"
      close={handleClose}
      anchor="right"
    >
      <Flex direction="column" gap="xs">
        <CheckboxFilter
          subtitle="Depósito pendente, Pago, Reprovado"
          title="Status do pedido"
          onAllSelection={(checked) => {
            const status = checked
              ? statusOptions.map((status) => status.value)
              : [];
            setSelectedFilters({ ...selectedFilters, status });
          }}
          options={getOptions()}
        />
        <CheckboxFilter
          title="Solicitante"
          onAllSelection={(checked) => {
            const status = checked
              ? statusOptions.map((status) => status.value)
              : [];
            setSelectedFilters({ ...selectedFilters, status });
          }}
          options={getEmployeeOptions()}
        />
        <TagFilters.DateRange
          label="Data do pedido"
          asDrawerItem
          onChange={(range) =>
            setSelectedFilters({
              ...selectedFilters,
              createdAtStartDate: range?.from,
              createdAtEndDate: range.to,
            })
          }
        ></TagFilters.DateRange>
      </Flex>
    </Drawer>
  );
}

export default ModalService.create(RequestBalanceFilterDrawer);
