import styled from 'styled-components'

export const TagStackContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs4};

  > span {
    white-space: nowrap;
  }
`
