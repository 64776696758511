import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 24px 32px 24px;
  align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid #ebe6e9;
  width: 100%;
`;

export const MainCompanyInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
`;
