import ModalService from '@frontend/services/ModalService';
import React from 'react';
import DuplicateDepositModal from '../../../components/DepositMenu/DuplicateDepositModal';
import ConfirmDepositModal from '../components/ConfirmDepositModal/ConfirmDepositModal';
import { FileSubmission } from '../components/steps/01-FileSubmission';
import { Form } from '../components/steps/02-Form/Form';
import { Feedback } from '../components/steps/03-Feedback/Feedback';
import { useForm } from './form/useForm';

enum ActivateUsersStepsEnum {
  /** Requests the upload of a spreadsheet with all deposits defined */
  FILE_SUBMISSION = 1,
  /** Formulary for setting up the deposit */
  FORM = 2,
  /** Reports the result of the operation */
  FEEDBACK = 3,
}

export function useFlowController() {
  const [step, setStep] = React.useState(
    ActivateUsersStepsEnum.FILE_SUBMISSION,
  );
  const form = useForm({
    onSuccess: onSubmitSuccess,
    onAuthError: () => {
      ModalService.show(ConfirmDepositModal, {
        form,
        onConfirm: () => {
          void form.onSubmit();
        },
      });
    },
    onWarning: () => {
      const formData = form.getValues();

      ModalService.show(DuplicateDepositModal, {
        formData,
        onConfirm: () => {
          form.setValue('duplicateWarningConfirmation', true);
          void form.onSubmit();
        },
      });
    },
  });
  const modalController = ModalService.useModalController();
  const deposits = form.watch('deposits');

  return {
    content: getContent(),
    leftButtons: getLeftButtons(),
    rightButtons: getRightButtons(),
  };

  function getContent() {
    switch (step) {
      case ActivateUsersStepsEnum.FILE_SUBMISSION:
        return <FileSubmission form={form} />;
      case ActivateUsersStepsEnum.FORM:
        return <Form form={form} />;
      case ActivateUsersStepsEnum.FEEDBACK:
        return <Feedback form={form} />;
    }
  }

  function getLeftButtons() {
    switch (step) {
      case ActivateUsersStepsEnum.FILE_SUBMISSION:
      case ActivateUsersStepsEnum.FORM:
        return {
          label: 'Cancelar',
          onClick: () => {
            modalController.remove();
          },
          isDisabled: false,
        } as const;
      case ActivateUsersStepsEnum.FEEDBACK:
        return undefined;
    }
  }
  function getRightButtons() {
    switch (step) {
      case ActivateUsersStepsEnum.FILE_SUBMISSION:
        return [
          {
            label: 'Continuar',
            onClick: () => {
              setStep(ActivateUsersStepsEnum.FORM);
            },
            isDisabled: deposits?.length <= 0,
            isLoading: form.formState.isSubmitting,
            variant: 'primary',
          },
        ] as const;
      case ActivateUsersStepsEnum.FORM:
        return [
          {
            label: 'Voltar',
            onClick: () => {
              setStep(ActivateUsersStepsEnum.FILE_SUBMISSION);
            },
            isDisabled: false,
            isLoading: form.formState.isSubmitting,
            variant: 'secondary',
          },
          {
            label: 'Depositar',
            onClick: confirm,
            isDisabled: false,
            isLoading: form.formState.isSubmitting,
            variant: 'primary',
          },
        ] as const;
      case ActivateUsersStepsEnum.FEEDBACK:
        return [
          {
            label: 'Fechar',
            onClick: () => {
              modalController.remove();
            },
            isDisabled: false,
            isLoading: false,
            variant: 'primary',
          },
        ] as const;
    }
  }
  function confirm() {
    void form.onSubmit();
  }
  function onSubmitSuccess() {
    setStep(ActivateUsersStepsEnum.FEEDBACK);
  }
}
