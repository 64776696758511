import { Button, IconProps, LinkButton } from '@components';

import * as SC from './styled';

type ButtonAction = {
  description: string;
  isDisabled?: boolean;
  onClick?(): void;
  isLoading?: boolean;
  color?: 'default' | 'success' | 'info' | 'error' | 'neutral';
  variant?: React.ComponentProps<typeof Button>['variant'];
  /**
   * Icon to show in button
   * @example IconAdd
   */
  icon?: IconProps['name'];
};

type ModalFooter = {
  cancel?: Omit<ButtonAction, 'variant'>;
  primary?: ButtonAction;
  secondary?: ButtonAction;
};

export function ModalFooter(props: ModalFooter) {
  return (
    <SC.Container>
      {props?.cancel && (
        <LinkButton id="modal-cancel-button" {...props.cancel} variant="neutral">
          {props.cancel.description}
        </LinkButton>
      )}

      {(props?.primary || props?.secondary) && (
        <div className="modal-right-actions-container">
          {props?.secondary && (
            <Button
              variant="secondary"
              {...props.secondary}
              onClick={() => props?.secondary?.onClick?.()}>
              {props.secondary.description}
            </Button>
          )}

          {props?.primary && (
            <Button
              variant="primary"
              {...props.primary}
              onClick={() => props?.primary?.onClick?.()}>
              {props.primary.description}
            </Button>
          )}
        </div>
      )}
    </SC.Container>
  );
}
