import { tableControllers } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import Icon from '@frontend/components/Icon';
import Typography from '@frontend/components/Typography';
import { cpfMask } from '@frontend/utils/masks/formatCPF';

type Employee = {
  id: string;
  name: string;
  documentNumber: string;
};

export function useEmployeesTable(employees: Employee[], search: string) {
  function filterByNameAndDocumentNumber(employee: Employee) {
    return (
      employee.name.toLowerCase().includes(search.toLowerCase()) ||
      employee.documentNumber.includes(search)
    );
  }

  const filteredEmployee = employees.filter(filterByNameAndDocumentNumber);

  const table = tableControllers.useTableColumns({
    data: filteredEmployee,
    total: employees.length,
    columns: [
      {
        accessorKey: 'name',
        header: 'Pessoa',
        cell: (context) => {
          return (
            <>
              <Typography.Body3 color="neutral_40">
                {context.row.original.name}
              </Typography.Body3>

              <Flex gap="xs5">
                <Icon size={16} name="IconId" color="neutral_70" />

                <Typography.Body4 color="neutral_50">
                  {cpfMask(context.row.original.documentNumber)}
                </Typography.Body4>
              </Flex>
            </>
          );
        },
      },
    ],
    onPaginationChange: () => null,
    pagination: {
      pageNumber: 1,
      pageSize: employees.length,
    },
  });

  return table;
}
