import dayjs from 'dayjs';

/**
 * Format Date to DD/MM/YYYY
 *
 * Ex: 22/08/2016
 **/
function formatDate(date: Date) {
  return dayjs(date).format('DD/MM/YYYY');
}

/**
 * Format Date to DD/MM/YYYY HH:mm
 *
 * Ex: 22/08/2016 11:20
 **/
function formatWithHours(date: Date) {
  return dayjs(date).format('DD/MM/YYYY HH:mm');
}

/**
 * Format Date to DD/MM/YYYY às HH:mm
 *
 * Ex: 22/08/2016 às 11:20
 **/
function formatAtWithHours(date: Date) {
  return `${formatDate(date)} às ${formatHours(date)}`;
}

/**
 * Format Date to 'Repete todo dia DD
 *
 * Ex: Repete todo dia 10
 **/
function formatRepeatsEveryDay(date: Date) {
  return `Repete todo dia ${dayjs(date).format('DD')}`;
}

/**
 * Format Date to Repete todo dia DD
 *
 * Ex: Repete todo dia 10
 **/
function formatHours(date: Date) {
  return dayjs(date).format('HH:mm');
}

/**
 * Format Date period
 *
 * Ex: DD/MM/YYYY - DD/MM/YYYY
 **/
function formatPeriod(firstDate: Date, lastDate: Date) {
  return `${formatDate(firstDate)} - ${formatDate(lastDate)}`;
}

export const date = {
  format: formatDate,
  withHours: formatWithHours,
  atWithHours: formatAtWithHours,
  repeatsEveryDay: formatRepeatsEveryDay,
  hours: formatHours,
  period: formatPeriod,
};
