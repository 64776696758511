import {
  Button,
  Icons,
  SelectField,
  Skeleton,
  Toggle,
  Tooltip,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useContext, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  DocumentFieldControl,
  InstructionCard,
  ModalPreviewFields,
  PageTemplate,
} from "../../../../components"
import { globalContext } from "../../../../context"
import { IFieldConfig, IFormGroup } from "../../../../types"
import dispatchToast from "../../../../utils/dispatchToast"
import {
  Badge,
  BodyContainer,
  CustomButton,
  DocumentsContainer,
  FieldsContainer,
  FlexRow,
  HeaderContainer,
  HeaderWrapper,
  Title,
} from "./styled"
import { trpc } from "@api/client"

const ManageFields = () => {
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false)

  const { companyId = "" } = useContext(globalContext)
  const navigate = useNavigate()
  const { flowId, id: baseOrExtensionId, formGroupId } = useParams()

  const { mutateAsync: sendFormConfigExtension } =
    trpc.formConfig.sendFormConfigExtension.useMutation()

  const {
    data: getFormConfigByExtensionOrBaseData,
    isInitialLoading: isLoading,
    isRefetching,
    refetch,
  } = trpc.formConfig.getFormConfigByExtensionOrBase.useQuery(
    {
      id: baseOrExtensionId || "",
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        console.log(err)
        navigate("/flows/hiring")
        dispatchToast({
          type: "error",
          content: "Houve um problema ao buscar dados do documento",
        })
      },
    },
  )

  const formConfig = getFormConfigByExtensionOrBaseData as unknown as any

  const section = formConfig?.formGroups.find(
    (group) => group._id === formGroupId,
  )

  const filteredFormGroup = formConfig?.formGroups.filter(
    (doc) => doc.category === section?.category,
  )

  const isAllFieldsRequired = section?.fields?.every(
    (field) => field.fieldRule?.required,
  )

  const handleUpdateSection = (
    updatedFormGroup: IFormGroup,
    successMessage?: string,
  ) => {
    const groupIndex = formConfig?.formGroups.findIndex(
      (group) => group._id === updatedFormGroup._id,
    )
    if (!groupIndex || !formConfig || groupIndex < 0) return

    const updatedGroups = formConfig.formGroups.map((group) => ({
      ...group,
      description: group.description || "",
    }))
    updatedGroups?.splice(groupIndex, 1, updatedFormGroup)

    sendFormConfigExtension(
      {
        companyId,
        baseId: formConfig?._id as string,
        name: formConfig?.name,
        formGroups: updatedGroups,
      },
      {
        onSuccess: (response) => {
          dispatchToast({
            type: "success",
            content: successMessage || "Campo atualizado com sucesso!",
          })
          const { _id: newExtension } = response
          if (baseOrExtensionId !== newExtension) {
            navigate(
              `/flows/settings/${flowId}/documents/${newExtension}/section/${formGroupId}`,
            )
            return
          }
          refetch()
        },
        onError: (err: any) => {
          console.log(err)
          dispatchToast({
            type: "error",
            content: "Houve um erro ao atualizar campo",
          })
        },
      },
    )
  }

  const handleChangeAllFieldRequired = (_, checked) => {
    const updatedFields = section?.fields?.map((field) => ({
      ...field,
      fieldRule: {
        ...field.fieldRule,
        required: checked,
        fieldId: field.fieldRule?.fieldId ?? "",
      },
    }))

    if (!updatedFields || !section) return

    const updatedFormGroup: IFormGroup = {
      ...section,
      fields: updatedFields,
    }

    const message = checked
      ? "Campos requeridos marcados com sucesso!"
      : "Campos requeridos desmarcados com sucesso!"

    handleUpdateSection(updatedFormGroup, message)
  }

  const handleUpdateField = (updatedField: IFieldConfig, message: string) => {
    const indexFieldToUpdate = section?.fields?.findIndex(
      (field) => field.customFieldId === updatedField.customFieldId,
    )
    if (!indexFieldToUpdate || indexFieldToUpdate < 0)
      throw new Error("Field not found on section, please contact support.")

    const updatedFields = section?.fields ? [...section?.fields] : []
    updatedFields.splice(indexFieldToUpdate, 1, updatedField)
    if (!section) return

    const updatedFormGroup: IFormGroup = {
      ...section,
      fields: updatedFields,
    }

    handleUpdateSection(updatedFormGroup, message)
  }

  const handleChangeSectionEnabled = () => {
    if (!section) return

    const updatedFormGroup: IFormGroup = {
      ...section,
      enabled: !section?.enabled,
    }

    const message = section?.enabled
      ? "Seção ocultada com sucesso!"
      : "Seção habilitada com sucesso!"

    handleUpdateSection(updatedFormGroup, message)
  }

  return (
    <PageTemplate
      history={[
        { name: "Admissão", href: "/flows/hiring" },
        { name: "...", href: `/flows/settings/${flowId}/documents/list` },
        { name: section?.title || "" },
      ]}
    >
      <HeaderContainer>
        {isLoading || isRefetching ? (
          <Skeleton variant="rectangular" width={"100%"} height={60} />
        ) : (
          <>
            <HeaderWrapper>
              <Title variant="headline6">{section?.title}</Title>
              {section?.type === "base" && (
                <HeaderWrapper>
                  <Badge>Seção padrão</Badge>

                  <Tooltip
                    title="Seções padrão não são editáveis."
                    placement="right-start"
                  >
                    <span>
                      <Icons
                        name="IconInfoCircle"
                        size={14}
                        fill="transparent"
                      />
                    </span>
                  </Tooltip>
                </HeaderWrapper>
              )}
            </HeaderWrapper>

            <Button
              variant="secondary"
              size="large"
              onClick={() => setIsPreviewOpen(true)}
            >
              Pré-visualizar
              <Icons name="IconExternalLink" fill="transparent" />
            </Button>
          </>
        )}
      </HeaderContainer>

      <BodyContainer>
        <DocumentsContainer>
          <Title variant="headline7" style={{ marginBottom: 16 }}>
            Seções
          </Title>
          {isLoading || isRefetching ? (
            <Skeleton variant="rectangular" width={"100%"} height={500} />
          ) : (
            <SelectField
              label="Selecionar seção"
              value={formGroupId || undefined}
              onSelectChange={(_, { value }) => {
                navigate(
                  `/flows/settings/${flowId}/documents/${formConfig?._id}/section/${value}`,
                )
              }}
              options={
                filteredFormGroup?.map((section) => ({
                  label: section?.title,
                  value: section?._id,
                })) || []
              }
            />
          )}
        </DocumentsContainer>

        <FieldsContainer>
          <Title variant="headline7" style={{ marginBottom: 16 }}>
            Campos
          </Title>
          {isLoading || isRefetching ? (
            <Skeleton variant="rectangular" width={"100%"} height={500} />
          ) : (
            <>
              <FlexRow
                style={{ justifyContent: "space-between", marginBottom: 16 }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Toggle
                    checked={isAllFieldsRequired}
                    onChange={handleChangeAllFieldRequired}
                  />
                  <Typography variant="body3" style={{ marginLeft: "10px" }}>
                    Manter todos os campos e anexos obrigatórios
                  </Typography>
                </div>

                <CustomButton onClick={handleChangeSectionEnabled}>
                  <Icons
                    name={section?.enabled ? "IconEyeOff" : "IconEye"}
                    fill="transparent"
                    style={{ marginRight: 4 }}
                  />
                  {section?.enabled ? (
                    <span>Ocultar seção</span>
                  ) : (
                    <span>Habilitar seção</span>
                  )}
                </CustomButton>
              </FlexRow>
              {formConfig && section && (
                <InstructionCard
                  flowId={flowId as string}
                  formConfig={formConfig}
                  section={section}
                  style={{
                    marginTop: 32,
                    marginBottom: 24,
                  }}
                />
              )}

              {section?.fields?.map((field, idx) => (
                <DocumentFieldControl
                  key={idx}
                  field={field}
                  handleUpdateField={handleUpdateField}
                />
              ))}

              {section && formConfig && (
                <ModalPreviewFields
                  isOpen={isPreviewOpen}
                  handleClose={() => setIsPreviewOpen(false)}
                  flowId={flowId as string}
                  formConfig={formConfig}
                  section={section}
                />
              )}
            </>
          )}
        </FieldsContainer>
      </BodyContainer>
    </PageTemplate>
  )
}

export default ManageFields
