import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const EmployeeNameContainer = styled.div`
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const SummaryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacings.xs5};
`;

export const AccordionTotals = styled.div`
  color: ${({ theme }) => theme.colors.neutral[20]};
`;
