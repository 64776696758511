import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  padding-top: ${({ theme }) => theme.spacings.xs};
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const TableContainer = styled.div`
  height: max-content;
  overflow: hidden;
  border: ${({ theme }) =>
    `${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`};
  border-radius: ${({ theme }) => theme.borders.radius.m};

  div:first-child {
    margin-bottom: 0;
  }
`;
