import { Highlight, IconProps, Spacer, Typography } from '@components';

import * as SC from './styled';

type ModalHeaderProps = {
  bgImageUrl?: string;
  title: string;
  subtitle?: string;
  highlight?: {
    description: string;
    size?: 'default' | 'compact';
    /**
     * @default announcement
     */
    variant?: 'announcement' | 'success' | 'warning';
    icon?: IconProps['name'];
  };
};

export function ModalHeader(props: ModalHeaderProps) {
  return (
    <div>
      {props.bgImageUrl && <SC.BgImage src={props.bgImageUrl} />}

      <SC.TitleContainer>
        {props.highlight && (
          <>
            <Highlight
              size={props.highlight.size}
              variant={props.highlight.variant ?? 'announcement'}
              icon={props.highlight.icon}>
              {props.highlight.description}
            </Highlight>

            <Spacer y="xs2" />
          </>
        )}

        <Typography.Headline8 color="neutral.10">{props.title}</Typography.Headline8>

        <Spacer y="xs4" />

        {props.subtitle && (
          <>
            <Typography.Body4 color="neutral.40">{props.subtitle}</Typography.Body4>

            <Spacer y="xs2" />
          </>
        )}
      </SC.TitleContainer>
    </div>
  );
}
