import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  Image,
  MainBannerContainer,
  MainBannerContentContainer,
} from './main-banner.styles';
import { useTranslation } from 'react-i18next';

export const MainBanner = () => {
  const [t] = useTranslation('translations', {
    keyPrefix: 'appStore.mainBanner',
  });

  return (
    <MainBannerContainer>
      <Image src="https://images.flashapp.com.br/flash-os/acquisition/app-store/banners/highlights/highlights-left.png" />
      <MainBannerContentContainer>
        <Typography
          variantColor="var(--color-secondary-50)"
          variant="headline8"
          style={{ textAlign: 'center' }}
        >
          {t('header')}
        </Typography>
        <Typography
          variantColor="var(--color-secondary-50)"
          variant="headline6"
          style={{ textAlign: 'center' }}
        >
          {t('title')}
        </Typography>
        <Typography
          variantColor="var(--color-secondary-50)"
          variant="body3"
          style={{ textAlign: 'center' }}
        >
          {t('description')}
        </Typography>
      </MainBannerContentContainer>
      <Image src="https://images.flashapp.com.br/flash-os/acquisition/app-store/banners/highlights/highlights-right.png" />
    </MainBannerContainer>
  );
};
