import { Menu, PillButton } from '@components';

export type FileType = 'CSV' | 'PDF' | 'XLSX';

type Props = {
  isLoading: boolean;
  onClick(fileType: FileType): void;
};

export function DownloadFile(props: Props) {
  const fileOptions: React.ComponentProps<typeof Menu>['options'] = [
    {
      label: 'CSV',
      onClick: () => props.onClick('CSV'),
      icon: 'IconFileTypeCsv',
    },
    {
      label: 'XLSx',
      onClick: () => props.onClick('XLSX'),
      icon: 'IconFileTypeXls',
    },
    {
      label: 'PDF',
      onClick: () => props.onClick('PDF'),
      icon: 'IconFileTypePdf',
    },
  ];

  return (
    <Menu options={fileOptions}>
      <PillButton
        size="sm"
        icon="IconFileExport"
        label="Exportar"
        iconPosition="left"
        loading={props.isLoading}
      />
    </Menu>
  );
}
