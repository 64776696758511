import Button from '@frontend/components/Button';
import Flex from '@frontend/components/frames/Flex';
import Icon from '@frontend/components/Icon';
import LinkButton from '@frontend/components/LinkButton';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import { MODAL_ActivateUsers } from '@frontend/pagesV2/user-accounts/UserAccounts/flows/ActivateUsers/ActivateUsers';
import ModalService from '@frontend/services/ModalService';
import { FeatureHighlight } from '../FeatureHighlight';
import { MODAL_WarningToRegisterEmployee } from '../WarningToRegisterEmployeeModal';
import * as SC from './styled';

type Props = {
  hasEmployeesRegistered: boolean;
};

export function EnableCorporateCard(props: Props) {
  function onClickToActiveCorporateCard() {
    if (props.hasEmployeesRegistered) {
      ModalService.show(MODAL_ActivateUsers, {});
      return;
    }

    ModalService.show(MODAL_WarningToRegisterEmployee, {});
  }

  return (
    <SC.Container>
      <SC.Content>
        <SC.HeaderContainer>
          <Typography.Headline8 color="neutral_30">
            Você está prestes a ter a melhor experiência de controle e gestão de
            cartões corporativos.
          </Typography.Headline8>

          <Spacer y="xs1" />

          <Flex gap="xs2">
            <FeatureHighlight icon="IconUserDollar">
              Depósitos inteligentes
            </FeatureHighlight>

            <FeatureHighlight icon="IconCreditCard">
              Políticas de uso
            </FeatureHighlight>

            <FeatureHighlight icon="IconFileInvoice">
              Acompanhamento de despesas
            </FeatureHighlight>
          </Flex>
        </SC.HeaderContainer>

        <Spacer y="xs" />

        <div>
          <Flex align="center" gap="xs5">
            <Icon name="IconCheckbox" color="neutral_50" size={16} />

            <Typography.Headline10 color="neutral_30">
              Passo 1
            </Typography.Headline10>
          </Flex>

          <Typography.Body4 color="neutral_40">
            Comece selecionando as pessoas da equipe que utilizarão o cartão
            corporativo.
          </Typography.Body4>
        </div>

        <Spacer y="xs2" />

        <Flex align="center" gap="xs2">
          <Button
            icon="IconClick"
            iconPosition="left"
            variant="primary"
            size="small"
            onClick={onClickToActiveCorporateCard}
          >
            Ativar uso corporativo
          </Button>

          <LinkButton
            id="watch-corporate-intro"
            icon="IconPlayerPlay"
            iconPosition="left"
            onClick={() => null}
            variant="default"
          >
            Assistir introdução
          </LinkButton>
        </Flex>
      </SC.Content>

      <SC.Image src="https://images.flashapp.com.br/expense/corporate-card/balance-and-cards.png" />
    </SC.Container>
  );
}
