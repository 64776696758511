import { useVersion } from '@frontend/components/VersionCallout/context';
import RouterService from '@frontend/services/RouterService';
import { Navigate, Route } from 'react-router-dom';
import BasePath from '../../../routes/BasePath';
import Deposits from './Deposits';

export const ROUTE_Deposit = RouterService.create(
  `${BasePath.relativePath}/transactions/deposits`,
);

export const RouteComponent_Deposit = () => {
  const context = useVersion('accountManagement');

  return (
    <Route
      path={ROUTE_Deposit.path}
      element={
        context.isNewVersion ? (
          <Navigate to="/expense/statement/cards" />
        ) : (
          <Deposits />
        )
      }
    />
  );
};

// /expense/statement/cards
