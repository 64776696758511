import { useMutation, useQueryClient } from "@tanstack/react-query"
import { api } from "../../../../api"
import { Leader } from "../../../../types"
import dispatchToast from "../../../../utils/dispatchToast"
import * as yup from "yup"
import { useFormik } from "formik"
import { Flow } from "bff/src/types/hiring"
import {
  GenericModal,
  LeaderAndFollowers,
  ProbationaryPeriod,
  ProbationaryRef,
  TextFieldCheckbox,
} from "src/components/common"
import { FieldContainer } from "./styles"
import {
  DatePicker,
  dayjs,
  SelectField,
  TextField,
} from "@flash-tecnologia/hros-web-ui-v2"
import {
  CnpjMask,
  CpfMask,
  EmailMask,
  parseDayjsToDateString,
  PhoneMask,
  validarCNPJ,
  validateCPF,
  validateEmail,
  validatePhone,
} from "src/utils"
import { useTranslation } from "react-i18next"
import React from "react"
import { useTracking } from "@utils/useTracking"

const validationSchema = yup.object({
  name: yup.string().required("Campo obrigatório"),
  cpf: yup.string().length(14, "Número de CPF precisa conter 12 numeros"),
  cnpj: yup.string().notRequired().nullable(),
  nationality: yup.string().required("Campo obrigatório"),
  socialName: yup.string(),
  legalName: yup.string().notRequired().nullable(),
  email: yup.string().required("Campo obrigatório"),
  phone: yup.string().when("isPhoneNotRequired", {
    is: false,
    then: (schema) => schema.required("Campo obrigatório"),
    otherwise: (schema) => schema.notRequired(),
  }),
  isPhoneNotRequired: yup.bool(),
  leader: yup.object().required("Campo obrigatório").nullable(),
  followers: yup.array().notRequired(),
  hiringDate: yup
    .date()
    .typeError("Favor selecionar uma data válida")
    .notRequired(),
})

type CandidateForm = {
  email: string
  phone: string
  nationality: string
  documentNumber?: string
  cnpj?: string
  legalName?: string
  socialName?: string
  name: string
  leader?: Leader
  followers?: { label: string; value: string }[]
  isPhoneNotRequired: boolean
  hiringDate?: string
}

interface CreateCandidateModal {
  isOpen: boolean
  handleClose: () => void
  flow: Flow
}

export const CreateCandidateModal = ({
  isOpen,
  handleClose,
  flow,
}: CreateCandidateModal) => {
  const queryClient = useQueryClient()
  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const probationPeriodRef = React.useRef<ProbationaryRef>(null)

  const { mutateAsync: createCard, isLoading: isCreating } = useMutation(
    api.mutation.hiring.card.createCard,
  )

  const tracking = useTracking()

  const formik = useFormik<CandidateForm>({
    initialValues: {
      name: "",
      documentNumber: "",
      cnpj: "",
      nationality: "",
      socialName: "",
      legalName: "",
      email: "",
      phone: "",
      isPhoneNotRequired: false,
      leader: undefined,
      followers: undefined,
      hiringDate: undefined,
    },
    validationSchema: validationSchema,
    validate: async (values) => {
      const errors: any = {}

      if (values.email.length > 1 && !validateEmail(values.email))
        errors.email = t("addCandidateForm.errorValidate")
      if (values.documentNumber && !validateCPF(values.documentNumber))
        errors.documentNumber = t("addCandidateForm.errorValidate")
      if (values.phone.length > 1 && !validatePhone(values.phone))
        errors.phone = t("addCandidateForm.errorValidate")

      if (flow.subcategory === "pj") {
        if (!values.cnpj || !validarCNPJ(values.cnpj))
          errors.cnpj = t("addCandidateForm.errorValidate")
        if (!values.legalName)
          errors.legalName = t("addCandidateForm.errorValidate")
      }

      if (
        probationPeriodRef.current?.values.option !== "no-period" &&
        !values.hiringDate
      )
        errors.hiringDate = t("addCandidateForm.errorRequired")

      const probationErrors = await probationPeriodRef?.current?.validateForm()
      return { ...errors, ...probationErrors }
    },
    onSubmit: async (values) => {
      try {
        if (!probationPeriodRef.current) throw new Error("Form not defined")
        const probationPeriod: typeof probationPeriodRef.current.values =
          await probationPeriodRef?.current?.submitForm()

        let body: Parameters<typeof createCard>[0]["candidateInfo"] = {
          name: values.name,
          phone: values.phone,
          socialName: values.socialName,
          email: values.email.toLowerCase(),
          documentNumber: values.documentNumber?.replace(/\D/g, ""),
          leader: {
            _id: values.leader?._id ?? "",
            name: values.leader?.name ?? "",
          },
          nationality: values.nationality,
          proposalLetterInfo: {
            hiringDate: values.hiringDate,
          },
          probationPeriod: {
            option: probationPeriod.option,
            firstDate: probationPeriod.firstDate,
            secondDate: probationPeriod.secondDate,
          },
        }

        if (flow.subcategory === "pj") {
          body = {
            ...body,
            cnpj: values.cnpj?.replace(/[./-]/g, ""),
            legalName: values.legalName,
          }
        }

        await createCard({
          flowId: flow._id,
          columnId: flow.columns[0]._id,
          followers: values.followers
            ? values.followers.map((option) => ({
                _id: option.value,
                name: option.label,
              }))
            : undefined,
          candidateInfo: body,
        })

        queryClient.invalidateQueries()
        dispatchToast({
          type: "success",
          content: "Candidato adicionado com sucesso.",
        })
        handleClose()
      } catch (err) {
        console.error(err)
        dispatchToast({
          type: "error",
          content:
            "Sentimos muito, houve um erro do nosso lado ao criar o novo candidato.",
        })
      }
    },
  })

  return (
    <GenericModal
      isOpen={isOpen}
      handleClose={handleClose}
      title="Cadastro de pessoa"
      description="Deixe seus talentos voarem na velocidade da luz ⚡"
      actionButtons={[
        {
          variant: "link",
          description: "Cancelar",
          handleAction: handleClose,
        },
        {
          variant: "primary",
          description: "Cadastrar",
          handleAction: () => {
            tracking.trackEvent({
              name: "hiring_home_clicked_create_single_candidate_button",
            })
            formik.handleSubmit()
            if (!formik.isValid) {
              dispatchToast({
                content: "Verifique os campos do formulário",
                type: "error",
              })
            }
          },
          disabled: isCreating,
          isLoading: isCreating,
        },
      ]}
    >
      <FieldContainer>
        <SelectField
          label={"Vinculo empregaticio"}
          fullWidth={true}
          disabled={true}
          value={flow.subcategory}
          options={[
            { label: "CLT", value: "clt" },
            { label: "PJ", value: "pj" },
            { label: "Estágio", value: "internship" },
          ]}
        />
      </FieldContainer>

      <FieldContainer>
        <TextField
          type={"text"}
          label={"Nome Completo"}
          placeholder={t("addCandidateForm.fullNamePlaceholder")}
          name={"name"}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name?.toString()}
          fullWidth={true}
        />
      </FieldContainer>

      <FieldContainer>
        <TextField
          type={"text"}
          label={"Nome Social"}
          placeholder={t("addCandidateForm.socialNamePlaceholder")}
          name={"socialName"}
          value={formik.values.socialName}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name?.toString()}
          fullWidth={true}
        />
      </FieldContainer>

      <FieldContainer>
        <SelectField
          name={"nationality"}
          label={"Nacionalidade"}
          fullWidth={true}
          onSelectChange={(e, { value }) =>
            formik.handleChange({
              target: { name: "nationality", value },
            })
          }
          error={
            formik.touched.nationality && Boolean(formik.errors.nationality)
          }
          helperText={
            formik.touched.nationality && formik.errors.nationality?.toString()
          }
          value={formik.values.nationality}
          options={[
            { label: "Brasileiro", value: "brazilian" },
            { label: "Estrangeiro", value: "foreigner" },
          ]}
        />
      </FieldContainer>

      <FieldContainer>
        <TextField
          type={"text"}
          label={"CPF"}
          placeholder={t("addCandidateForm.cpfPlaceholder")}
          name={"documentNumber"}
          value={formik.values.documentNumber}
          onChange={(e) => {
            if (e.target.value.length === 15) return

            const value = CpfMask(e.target.value)
            formik.handleChange({
              target: { name: "documentNumber", value },
            })
          }}
          error={
            formik.touched.documentNumber &&
            Boolean(formik.errors.documentNumber)
          }
          helperText={
            formik.touched.documentNumber &&
            formik.errors.documentNumber?.toString()
          }
          fullWidth={true}
        />
      </FieldContainer>

      {flow.subcategory === "pj" ? (
        <>
          <FieldContainer>
            <TextField
              type={"text"}
              label={"CNPJ"}
              placeholder={t("addCandidateForm.cnpjPlaceholder")}
              name={"cnpj"}
              value={formik.values.cnpj}
              onChange={(e) => {
                if (e.target.value.length === 19) return

                const value = CnpjMask(e.target.value)
                formik.handleChange({
                  target: { name: "cnpj", value },
                })
              }}
              error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
              helperText={formik.touched.cnpj && formik.errors.cnpj?.toString()}
              fullWidth={true}
            />
          </FieldContainer>

          <FieldContainer>
            <TextField
              type={"text"}
              label={"Razão Social"}
              placeholder={t("addCandidateForm.legalNamePlaceholder")}
              name={"legalName"}
              value={formik.values.legalName}
              onChange={formik.handleChange}
              error={
                formik.touched.legalName && Boolean(formik.errors.legalName)
              }
              helperText={
                formik.touched.legalName && formik.errors.legalName?.toString()
              }
              fullWidth={true}
            />
          </FieldContainer>
        </>
      ) : (
        <></>
      )}

      <FieldContainer>
        <TextField
          type={"text"}
          label={"E-mail"}
          placeholder={t("addCandidateForm.emailPlaceholder")}
          name={"email"}
          value={formik.values.email}
          onChange={(e) => {
            const value = EmailMask(e.target.value)
            formik.handleChange({
              target: { name: "email", value },
            })
          }}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email?.toString()}
          fullWidth={true}
        />
      </FieldContainer>

      <FieldContainer>
        <TextFieldCheckbox
          type={"text"}
          label={"Telefone"}
          placeholder={t("addCandidateForm.phonePlaceholder")}
          name={"phone"}
          value={formik.values.phone}
          onChange={(e) => {
            if (e.target.value.length === 15) return

            const value = PhoneMask(e.target.value)
            formik.handleChange({
              target: { name: "phone", value },
            })
          }}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone?.toString()}
          fullWidth={true}
          checkbox={{
            text: "Não informar",
            name: "isPhoneNotRequired",
            value: formik.values.isPhoneNotRequired,
            onClick: (checked) =>
              formik.handleChange({
                target: { name: "isPhoneNotRequired", value: checked },
              }),
          }}
        />
      </FieldContainer>

      <FieldContainer>
        <DatePicker
          id="hiringDate"
          name="hiringDate"
          label="Data prevista para admissão"
          placeholder="Data prevista para admissão"
          value={
            formik.values.hiringDate
              ? parseDayjsToDateString(dayjs(formik.values.hiringDate))
              : ""
          }
          disabled={isCreating}
          error={formik.touched.hiringDate && Boolean(formik.errors.hiringDate)}
          helperText={formik.touched.hiringDate && formik.errors.hiringDate}
          onBlur={formik.handleBlur}
          onDateChange={(value) => {
            if (!dayjs(value).isValid()) return
            formik.handleChange({
              target: { name: "hiringDate", value },
            })
          }}
        />
      </FieldContainer>

      <FieldContainer>
        <ProbationaryPeriod
          ref={probationPeriodRef}
          hiringDate={formik.values.hiringDate}
        />
      </FieldContainer>

      <LeaderAndFollowers
        leader={{
          value: formik.values.leader,
          handleChange: (value) => {
            formik.handleChange({
              target: { name: "leader", value },
            })
          },
          error: formik.touched.leader && Boolean(formik.errors.leader),
          helperText: (formik.touched.leader && formik.errors.leader) || "",
        }}
        followers={{
          value: formik.values.followers,
          handleChange: (value) => {
            formik.handleChange({
              target: { name: "followers", value },
            })
          },
          error: formik.touched.followers && Boolean(formik.errors.followers),
          helperText: formik.touched.followers
            ? formik.errors.followers?.toString()
            : undefined,
        }}
      />
    </GenericModal>
  )
}
