import styled from "styled-components";

export const Container = styled.div<{ zoom: boolean }>(({ zoom }) => ({
  position: "relative",
  overflow: "hidden",
  cursor: zoom ? "zoom-out" : "zoom-in",
}));

export const Image = styled.img<{ zoom: boolean; x: number; y: number }>(({ x, y, zoom }) => ({
  width: "100%",
  height: "100%",
  transform: zoom ? `scale(2) translate(50%, 50%) translate(-${x}px, -${y}px)` : "scale(1)",
}));
