import { IconsProps } from '@flash-tecnologia/hros-web-ui-v2';
import Icon from '@frontend/components/Icon';
import Typography from '@frontend/components/Typography';
import * as SC from './styled';

type Props = {
  title: string;
  description: string;
  icon: IconsProps['name'];
};

export function HighlightCard(props: Props) {
  return (
    <SC.Container>
      <Icon
        name={props.icon}
        color="secondary_50"
        background="default"
        size={40}
      />

      <div>
        <Typography.Headline9 color="neutral_20" center>
          {props.title}
        </Typography.Headline9>

        <Typography.Body4 color="neutral_40" center>
          {props.description}
        </Typography.Body4>
      </div>
    </SC.Container>
  );
}
