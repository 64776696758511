import { CREATE_ACCOUNT_IMAGE_URL } from '@/assets/imageConstants';
import { Footer, Header } from '@components/Common';
import { Icons, LinkButton, TextField } from '@flash-tecnologia/hros-web-ui-v2';
import { useLoginContext } from '../../loginContext';
import {
  ButtonsContainer,
  ContentContainer,
  FieldContainer,
  FormContainer,
  GreetingsText,
  LeftContainer,
  PageContainer,
  RequiredText,
  RightContainer,
  StyledButton,
  SubTitle,
  TextsContainer,
  Title,
  Wrapper,
} from '../styles';
import { useUpdatePhoneNumberForm } from './hooks';

export const UpdatePhoneNumberStep = () => {
  const { form, isLoading } = useUpdatePhoneNumberForm();
  const { setStep } = useLoginContext();

  const onGoBack = () => {
    setStep('VERIFY_DATA_LANDING');
  };

  return (
    <PageContainer>
      <Header />
      <ContentContainer>
        <Wrapper>
          <LeftContainer>
            <TextsContainer>
              <GreetingsText variant="body3">Cadastro de celular</GreetingsText>
              <Title variant="headline5">
                Para começar, informe um número válido de celular
              </Title>
              <SubTitle variant="body3">
                Cadastrar um número de celular válido é importante nos casos de
                recuperação de acesso e para manter a comunicação com a empresa
                onde você trabalha.
              </SubTitle>
            </TextsContainer>

            <RequiredText variant="body3">* campo obrigatório</RequiredText>

            <form onSubmit={form.handleSubmit}>
              <FormContainer>
                <FieldContainer>
                  <TextField
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Celular *"
                    autoComplete="off"
                    value={form.values.phoneNumber}
                    onChange={form.handleChange}
                    onBlur={() => form.setTouched({ phoneNumber: true })}
                    imaskProps={{
                      mask: '(00) 00000-0000',
                    }}
                    error={
                      form.touched.phoneNumber && !!form.errors.phoneNumber
                    }
                    helperText={
                      form.touched.phoneNumber && form.errors.phoneNumber
                    }
                  />
                </FieldContainer>
              </FormContainer>

              <ButtonsContainer>
                <LinkButton variant="default" onClick={onGoBack}>
                  Voltar
                </LinkButton>
                <StyledButton
                  variant="primary"
                  size="large"
                  type="submit"
                  loading={isLoading}
                  disabled={
                    !form.dirty ||
                    !form.isValid ||
                    !!form.errors.phoneNumber ||
                    isLoading
                  }
                >
                  Continuar <Icons name="IconArrowRight" />
                </StyledButton>
              </ButtonsContainer>
            </form>
          </LeftContainer>
          <RightContainer imageUrl={CREATE_ACCOUNT_IMAGE_URL} />
        </Wrapper>
      </ContentContainer>
      <Footer />
    </PageContainer>
  );
};
