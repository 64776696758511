import { Icons, ShapeIcon, Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import { trpc } from "$client";
import { ReclassifyResponse } from "$frontend/components/organisms/ConfirmationModalReclassifyTransaction/types";
import { getValueWithCurrency } from "$frontend/utils";
import { Alignment } from "$frontend/utils/enum";
import { DangerActionModal, InfoCard, Modal, SuccessActionModal } from "$molecules";
import { ExpenseTransaction } from "$serverTypes";
import {
  Card,
  CardChanges,
  CardContainerChanges,
  CardTile,
  CardTileChanges,
  Container,
  Content,
  Explanation,
  LabelButtonContent,
  PriceTag,
  PriceTagContainer,
  TitleWithIcon,
} from "./styled";

type ConfirmationModalReclassifyTransactionProps = {
  isOpen: boolean;
  transaction: ExpenseTransaction | undefined;
  onCloseClick: () => void;
  onActionClick?: () => void;
};

export const ConfirmationModalReclassifyTransaction = ({
  isOpen,
  transaction,
  onCloseClick,
  onActionClick,
}: ConfirmationModalReclassifyTransactionProps) => {
  const { t } = useTranslation("translations", {
    keyPrefix: "organisms.modals.confirmationModalReclassifyTransaction",
  });

  const { colors } = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [previewData, setPreviewData] = useState<ReclassifyResponse | null>(null);

  const { mutateAsync: updatePayment } = trpc.corporateCard.updatePayment.useMutation();

  const payload = useMemo(() => {
    if (isOpen && transaction?.id) {
      return {
        transactionId: transaction.id,
        preview: true,
      };
    }
    return null;
  }, [isOpen, transaction?.id]);

  useEffect(() => {
    if (payload) {
      setIsLoading(true);
      updatePayment(payload)
        .then((data) => {
          const reclassifyData = data as ReclassifyResponse;
          setPreviewData(reclassifyData);
        })
        .catch(() => {
          setIsErrorModalOpen(true);
          onCloseClick();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [payload, updatePayment, onCloseClick]);

  const handleConfirm = async () => {
    if (!transaction?.id) return;

    setIsLoading(true);

    const payload = {
      transactionId: transaction.id,
      preview: false,
    };

    try {
      await updatePayment(payload);
      setIsSuccessModalOpen(true);
      onCloseClick();
    } catch (error) {
      setIsErrorModalOpen(true);
      onCloseClick();
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuccessModalClose = () => {
    onActionClick?.();
    setIsSuccessModalOpen(false);
  };

  const currentDebitData = previewData?.currentDebitData || [];
  const previewDebitData = previewData?.previewDebitData || [];

  const currentDebit = currentDebitData.length > 0 ? currentDebitData[0] : null;
  const previewDebit = previewDebitData.length > 0 ? previewDebitData[0] : null;

  const beforeChangeCurrentBalance = currentDebit ? currentDebit.balance + currentDebit.amount : 0;
  const beforeChangeNewBalance = previewDebit && currentDebit ? previewDebit.balance + currentDebit.amount : 0;
  const afterChangeCurrentBalance = currentDebit ? currentDebit.balance : 0;
  const afterChangeNewBalance = previewDebit ? previewDebit.balance : 0;

  return (
    <Container>
      <Content>
        <Modal
          isOpen={isOpen}
          onClose={onCloseClick}
          title={t("title")}
          description={t("reclassifyDescription")}
          loading={isLoading}
          footerProps={{
            onConfirm: handleConfirm,
            onCancel: onCloseClick,
            confirmText: t("buttons.confirm"),
            cancelText: t("buttons.cancel"),
            loading: isLoading,
          }}
        >
          <Card>
            <CardTile bottomBorder={true}>
              <InfoCard
                fullWidth={true}
                leading={<ShapeIcon variant={"default"} stroke={"default"} name="IconReceipt" size={40} />}
                title={
                  <Typography variant="caption" variantColor={colors.neutral[40]}>
                    {t("titleCardLabels.establishment")}
                  </Typography>
                }
                subTitle={
                  <Typography weight={700} variant="headline9" variantColor={colors.neutral[30]}>
                    {transaction?.description}
                  </Typography>
                }
                trailing={
                  <div>
                    <InfoCard
                      title={
                        <Typography variant="caption" variantColor={colors.neutral[40]}>
                          {t("titleCardLabels.expenseAmount")}
                        </Typography>
                      }
                      subTitle={
                        <Typography weight={700} variant="headline9" variantColor={colors.neutral[30]}>
                          -{" "}
                          {getValueWithCurrency({
                            value: transaction?.amount || 0,
                            currencyPrefix: transaction?.currency || "BRL",
                          })}
                        </Typography>
                      }
                    />
                  </div>
                }
              />
            </CardTile>
            <CardTile>
              <InfoCard
                fullWidth={true}
                leading={<ShapeIcon variant={"neutral"} stroke={"neutral"} name="IconCreditCard" size={40} />}
                title={
                  <Typography variant="caption" variantColor={colors.neutral[40]}>
                    {t("titleCardLabels.payment")}
                  </Typography>
                }
                subTitle={
                  <Typography weight={700} variant="headline10" variantColor={colors.neutral[30]}>
                    {t("titleCardLabels.corporateBalance")} {" · "}
                    {t(`titleCardLabels.cardType.${transaction?.cardType}`)} {transaction?.cardLastDigits || "****"}
                  </Typography>
                }
              />
            </CardTile>
          </Card>

          <Explanation>
            <Typography variant="headline9" variantColor={colors.neutral[40]}>
              {t("explanationTitle", { benefitName: previewDebit?.name })}
            </Typography>
            <Typography variant="body4" weight={400} variantColor={colors.neutral[40]}>
              {t("explanationBody", { benefitName: previewDebit?.name })}
            </Typography>
          </Explanation>

          <CardChanges>
            <CardContainerChanges>
              <CardTileChanges>
                <InfoCard
                  title={
                    <TitleWithIcon>
                      <Typography variant="headline9" variantColor={colors.neutral[40]}>
                        {t("contentCardLabels.corporate")}
                      </Typography>
                    </TitleWithIcon>
                  }
                  subTitle={
                    <>
                      <Typography variant="caption" variantColor={colors.neutral[40]}>
                        {t("contentCardLabels.currentBalance")}:{" "}
                        {getValueWithCurrency({ value: afterChangeCurrentBalance, currencyPrefix: "BRL" })}
                      </Typography>
                    </>
                  }
                  trailing={
                    <div>
                      <ShapeIcon variant={"transparent"} stroke={"neutral"} name="IconBriefcase" size={34} />
                    </div>
                  }
                />
              </CardTileChanges>
              <CardTileChanges>
                <InfoCard
                  title={
                    <Typography weight={400} variant="caption" variantColor={colors.neutral[30]}>
                      {t("contentCardLabels.balanceAfterChange")}
                    </Typography>
                  }
                  subTitle={
                    <>
                      <Typography weight={700} variant="headline9" variantColor={colors.neutral[30]}>
                        {getValueWithCurrency({ value: beforeChangeCurrentBalance, currencyPrefix: "BRL" })}
                      </Typography>
                    </>
                  }
                />
              </CardTileChanges>
            </CardContainerChanges>

            <PriceTagContainer>
              <PriceTag>
                <Tag variant="yellow">
                  {"- " +
                    getValueWithCurrency({
                      value: transaction?.amount || 0,
                      currencyPrefix: transaction?.currency || "BRL",
                    })}
                  <Icons className="shape-icon" fill="transparent" stroke="neutral" name="IconArrowRight" size={20} />
                </Tag>
              </PriceTag>
            </PriceTagContainer>

            <CardContainerChanges>
              <CardTileChanges>
                <InfoCard
                  title={
                    <TitleWithIcon>
                      <Typography variant="headline9" variantColor={colors.neutral[40]}>
                        {previewDebit?.name}
                      </Typography>
                    </TitleWithIcon>
                  }
                  subTitle={
                    <>
                      <Typography variant="caption" variantColor={colors.neutral[40]}>
                        {t("contentCardLabels.currentBalance")}:{" "}
                        {getValueWithCurrency({ value: beforeChangeNewBalance, currencyPrefix: "BRL" })}
                      </Typography>
                    </>
                  }
                  trailing={
                    <div>
                      <ShapeIcon variant={"transparent"} stroke={"neutral"} name="IconReceipt" size={34} />
                    </div>
                  }
                />
              </CardTileChanges>
              <CardTileChanges>
                <InfoCard
                  title={
                    <Typography weight={400} variant="caption" variantColor={colors.neutral[30]}>
                      {t("contentCardLabels.balanceAfterChange")}
                    </Typography>
                  }
                  subTitle={
                    <>
                      <Typography weight={700} variant="headline9" variantColor={colors.neutral[30]}>
                        {getValueWithCurrency({
                          value: afterChangeNewBalance,
                          currencyPrefix: "BRL",
                        })}
                      </Typography>
                    </>
                  }
                />
              </CardTileChanges>
            </CardContainerChanges>
          </CardChanges>
        </Modal>

        {isSuccessModalOpen && (
          <SuccessActionModal
            isLoading={isLoading}
            headerIconLabel={t("successModal.iconLabel")}
            headerTitle={t("successModal.headerTitle")}
            headerSubtitle={t("successModal.headerSubtitle", { benefitName: previewDebit?.name })}
            labelButtonAction={
              <LabelButtonContent>
                <div>{t("successModal.buttons.confirm")}</div>
              </LabelButtonContent>
            }
            onActionClick={handleSuccessModalClose}
            onCloseClick={handleSuccessModalClose}
            open={isSuccessModalOpen}
            iconPosition={Alignment.left}
            showCancelButton={false}
          />
        )}

        {isErrorModalOpen && (
          <DangerActionModal
            headerIcon={<ShapeIcon variant={"error"} stroke={"error"} name="IconX" size={48} />}
            isLoading={isLoading}
            headerIconLabel={t("warningModal.iconLabel")}
            headerTitle={t("warningModal.headerTitle")}
            headerSubtitle={t("warningModal.headerSubtitle")}
            labelButtonAction={
              <LabelButtonContent>
                <div>{t("warningModal.buttons.confirm")}</div>
                <Icons name="IconCheck" fill="transparent" size={24} />
              </LabelButtonContent>
            }
            onActionClick={() => setIsErrorModalOpen(false)}
            onCloseClick={() => setIsErrorModalOpen(false)}
            open={isErrorModalOpen}
            iconPosition={Alignment.left}
            showCancelButton={false}
          />
        )}
      </Content>
    </Container>
  );
};
