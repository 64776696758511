import { trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import { useState } from 'react';

export function useApprovalType() {
  const [approvalType, setApprovalType] = useState<'CUSTOM' | 'AUTO'>();
  const { data, isFetching, refetch } =
    trpc.corporateCard.approvalFlow.approvalFlowEnable.useQuery(undefined, {
      onSuccess: (data) => {
        setApprovalType(data.isEnabled ? 'CUSTOM' : 'AUTO');
      },
    });
  const { mutate } =
    trpc.corporateCard.approvalFlow.setApprovalFlow.useMutation({
      onSuccess: () => {
        void refetch();
        dispatchToast({
          content: 'Fluxo de aprovação alterado com sucesso!',
          type: 'success',
        });
      },
      onError: () => {
        dispatchToast({
          content: 'Não foi possível alterar o fluxo de aprovação.',
          description:
            'Tente novamente e, caso o erro persista, entre em contato com o nosso atendimento.',
          type: 'error',
        });
      },
    });

  const saveApproval = () => {
    mutate({
      enable: approvalType === 'CUSTOM',
    });
  };

  return {
    approvalType,
    isLoading: isFetching,
    setApprovalType,
    saveApproval,
    customEnable: data?.isEnabled,
  };
}
