import { useEffect } from "react";
import { LinkButton, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledIcon, StyledText, StyledTitle, track } from "@utils";

import {
  Card,
  ContentContainer,
  MainContainer,
  StyledAvatar,
  TextRow,
} from "./styled";

import type { ManagerFeedbacksInsights } from "server/src/services/feedback/types";

interface IBigNumbersProps {
  insights?: ManagerFeedbacksInsights;
  insightsFilter?: "unsent";
  isLoading: boolean;
  onFilter: (status?: "unsent") => void;
}

export const BigNumbers = ({
  insights,
  insightsFilter,
  isLoading,
  onFilter,
}: IBigNumbersProps) => {
  useEffect(() => {
    onFilter(undefined);
  }, []);

  if (isLoading)
    return (
      <MainContainer>
        <Skeleton variant="rounded" width={"100%"} height={166} />
        <Skeleton variant="rounded" width={"100%"} height={166} />
        <Skeleton variant="rounded" width={"100%"} height={166} />
      </MainContainer>
    );

  return (
    <MainContainer>
      <Card>
        <StyledAvatar setColor="secondary">
          <StyledIcon name="IconMailOpened" setColor="secondary50" size={24} />
        </StyledAvatar>

        <div>
          <StyledText
            variant="body3"
            children="Taxa de líderes que enviaram feedback"
            setColor="neutral40"
            weight={600}
          />
          <StyledTitle
            variant="body1"
            setColor="neutral40"
            children={
              insights?.sentFeedbackPercent
                ? insights?.sentFeedbackPercent + `%`
                : " - "
            }
          />
        </div>
      </Card>
      <Card style={{ opacity: insightsFilter === "unsent" ? 0.5 : 1 }}>
        <StyledAvatar setColor="success">
          <StyledIcon name="IconCheck" setColor="success40" size={24} />
        </StyledAvatar>

        <div>
          <StyledText
            variant="body3"
            children="Líderes que enviaram feedback"
            setColor="neutral40"
            weight={600}
          />
          <TextRow>
            <StyledTitle
              variant="body1"
              setColor="neutral30"
              children={insights?.sentFeedbackCount || 0}
            />
            <StyledTitle
              variant="body2"
              setColor="neutral40"
              children={`/${insights?.totalLeaders || 0}`}
            />
          </TextRow>
        </div>
      </Card>
      <Card>
        <StyledAvatar setColor="error">
          <StyledIcon name="IconAlertTriangle" setColor="error40" size={24} />
        </StyledAvatar>
        <ContentContainer>
          <div>
            <StyledText
              variant="body3"
              children="Líderes que não enviaram feedback"
              setColor="neutral40"
              weight={600}
            />
            <TextRow>
              <StyledTitle
                variant="body1"
                setColor="neutral30"
                children={insights?.unsentFeedbackCount || 0}
                weight={700}
              />
              <StyledTitle
                variant="body2"
                setColor="neutral40"
                children={`/${insights?.totalLeaders || 0}`}
                weight={600}
              />
            </TextRow>
          </div>
          <LinkButton
            variant="default"
            size="large"
            style={{ alignSelf: "flex-start" }}
            children={
              insightsFilter === "unsent" ? "Remover filtro" : "Filtrar"
            }
            onClick={() => {
              track({
                name: "people_strategic_hr_performance_company_cycles_cycledetails_feedbacks_bignumbers_filter_clicked",
              });
              const status = insightsFilter === "unsent" ? undefined : "unsent";

              onFilter(status);
            }}
          />
        </ContentContainer>
      </Card>
    </MainContainer>
  );
};
