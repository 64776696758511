import EnvService from '@frontend/services/EnvService';

export function QUERY_RETRY_CONFIG(paymentMethod: 'BILLET' | 'PIX' = 'BILLET') {
  if (paymentMethod === 'PIX') {
    return {
      retryDelay: 2000,
      retry: EnvService.BUILD_ENV === 'production' ? 5 : 10,
    };
  }
  return {
    retryDelay: 5000,
    retry: EnvService.BUILD_ENV === 'production' ? 5 : 10,
  };
}
