import { useMemo } from "react";

import {
  Icons,
  Menu,
  PillButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import { TOption } from "./types";
import { Option } from "./styled";
import { routes } from "@routes";
import { useNavigate, useParams } from "react-router-dom";

interface OptionsProps {
  _id: string;
  row: any;
}

export const Options = ({ _id, row }: OptionsProps) => {
  const { evaluationId = "" } = useParams();

  const navigate = useNavigate();

  const options: TOption[] = useMemo(() => {
    return [
      {
        key: "people-detail",
        onClick: () =>
          navigate(
            routes.PagePersonDetails("evaluation-details", _id, evaluationId)
          ),
        children: (
          <Option>
            <Icons name="IconUser" fill="transparent" />
            <Typography variant="body3" style={{ fontWeight: 600 }}>
              Ver detalhes da pessoa
            </Typography>
          </Option>
        ),
      },
      // {
      //   key: "export",
      //   onClick: () => {
      //     console.log("");
      //   },
      //   children: (
      //     <Option>
      //       <Icons name="IconCopy" fill="transparent" />
      //       <Typography variant="body3" style={{ fontWeight: 600 }}>
      //         Exportar relatório
      //       </Typography>
      //     </Option>
      //   ),
      // },
    ] as any;
  }, [_id]);

  return (
    <>
      <Menu
        type={"select"}
        options={options}
        disableAutoFocusItem={true}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <PillButton
          size="small"
          variant="default"
          type="secondary"
          icon="IconDotsVertical"
        />
      </Menu>

      {row.canExpand ? (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? (
            <PillButton
              size="small"
              variant="default"
              type="secondary"
              icon="IconChevronUp"
            />
          ) : (
            <PillButton
              size="small"
              variant="default"
              type="secondary"
              icon="IconChevronDown"
            />
          )}
        </span>
      ) : (
        <PillButton
          size="small"
          variant="default"
          type="secondary"
          icon="IconChevronDown"
          disabled={true}
        />
      )}
    </>
  );
};
