import { CombinedExpenseStatus, PendingExpenseAction } from "$organisms";
import { ExpenseStatus, ExpenseTransactionCardType, ExpenseType } from "$serverTypes";
import { TransactionStatus } from "server/src/services/expense-management-service/expense-management-service-types";
import { TranslationKey } from "./type";

const common = {
  expenseStatusReimbursements: {
    [ExpenseStatus.DRAFT]: "Pending",
    [ExpenseStatus.REQUIRE_CHANGES]: "Under Review",
    [ExpenseStatus.PENDING_APPROVAL]: "Awaiting Approval",
    [ExpenseStatus.PENDING_ACCOUNTING]: "In Payment",
    [ExpenseStatus.FINISHED]: "Reimbursed",
    [ExpenseStatus.REJECTED]: "Rejected",
  } satisfies Record<ExpenseStatus, string>,
  expenseStatusCorporateCard: {
    [ExpenseStatus.DRAFT]: "Pending",
    [ExpenseStatus.REQUIRE_CHANGES]: "Under Review",
    [ExpenseStatus.PENDING_APPROVAL]: "Awaiting Approval",
    [ExpenseStatus.PENDING_ACCOUNTING]: "In Finalization",
    [ExpenseStatus.FINISHED]: "Completed",
    [ExpenseStatus.REJECTED]: "Rejected",
  } satisfies Record<ExpenseStatus, string>,
  transactionStatus: {
    [TransactionStatus.AUTHORIZED]: "Authorized",
    [TransactionStatus.COMPLETED]: "Confirmed",
    [TransactionStatus.SETTLED]: "Authorized",
    [TransactionStatus.CANCELED]: "Cancelled",
    [TransactionStatus.REVERTED]: "Reverted",
    [TransactionStatus.PROCESSING]: "Processing",
  } satisfies Record<TransactionStatus, string>,
};

export default {
  translations: {
    pages: {
      categoriesList: {
        title: "Categories",
        subtitle: "Define the expense categories that you want to be available for posting expenses.",
        headerAction: "Create category",
        create: "Create",
      },
      createReimbursement: {
        back: "Back to Reimbursements",
        title: "Request reimbursement",
        help: "Help Center",
        toasts: {
          creationFailed: {
            messages: {},
            title: "Error creating reimbursement request",
          },
          creationSuccess: {
            title: "Reimbursement submitted successfully!",
          },
        },
      },
      editReimbursement: {
        back: "Back to Reimbursements",
        title: "Edit reimbursement",
        help: "Help Center",
        toasts: {
          failedEdit: {
            messages: {},
            title: "Error editing reimbursement",
          },
          successEdit: {
            title: "Reimbursement submitted successfully!",
          },
        },
      },
      reimbursements: {
        title: "Reimbursements",
        subtitle: "Create and track your reimbursement requests.",
        addReimbursement: "Request reimbursement",
        failedToGetReimbursements: "Error fetching reimbursements",
        toasts: {
          addSuccess: {
            title: "Reimbursement submitted successfully!",
          },
          addError: {
            title: "Error requesting reimbursement(s)",
            description: "Please try again later.",
          },
          deleteSuccess: {
            title: "Reimbursement(s) deleted successfully!",
          },
          deleteError: {
            title: "Error deleting reimbursement(s)",
            description: "Please try again later.",
          },
        },
      },
      reimbursementsSummary: {
        title: "Reimbursements",
        subtitle: "Mark expenses as reimbursed and track ongoing reimbursements",
        failedToGetReimbursements: "Error fetching reimbursements",
        remainingExpensesWarning_one:
          "You have one outstanding refund from before the selected period. Adjust the filter to view them.",
        remainingExpensesWarning_other:
          "You have {{count}} outstanding refunds from before the selected period. Adjust the filter to view them.",
      },
      adminReimbursement: {
        breadcrumbs: {
          current: "Reimbursement Details",
          previous: "Reimbursements",
        },
        title: "Reimbursement Details",
        edit: "Edit",
        reopen: "Reopen",
        extraButtons: {
          reject: "Reject",
          requestReview: "Request Review",
          markAsReimbursed: "Mark as Reimbursed",
        },
        modals: {
          confirmationModalEditExpense: {
            attention: "Attention",
            popupTitle: "Are you sure you want to edit the reimbursement?",
            popupSubtitle: "It is still the responsibility of whoever made the expense.",
            confirm: "Confirm",
          },
          confirmationModalReopenExpense: {
            attention: "Attention",
            popupTitle: "Are you sure you want to reopen the reimbursement?",
            popupSubtitle: "Reopening will reset it to the 'in payment' status.",
            confirm: "Confirm Reopen",
          },
          confirmationModalRejectExpense: {
            attention: "Attention",
            popupTitle: "Are you sure you want to reject the reimbursement?",
            confirm: "Confirm Rejection",
            reasonLabel: "Select a reason for reject",
          },
          confirmationModalRequestReview: {
            attention: "Attention",
            popupTitle: "Are you sure you want to send the reimbursement for review?",
            popupSubtitle: "The requester will receive it as a pending item to review.",
            confirm: "Request Review",
            reasonLabel: "Explain the reason for the review",
          },
          confirmationModalMarkAsReimbursed: {
            attention: "Attention",
            popupTitle: "Are you sure you want to mark the expense as reimbursed?",
            confirm: "Confirm Reimbursement",
          },
        },
        toasts: {
          reopenSuccess: {
            title: "Reimbursement successfully reopened!",
          },
          reopenError: {
            title: "Error reopening reimbursement",
            description: "Please try again in a few moments.",
          },
          rejectSuccess: {
            title: "Reimbursement successfully rejected!",
          },
          rejectError: {
            title: "Error rejecting reimbursement",
            description: "Please try again in a few moments.",
          },
          rejectCommentRequired: {
            title: "Error rejecting reimbursement",
            description: "Please select a reason",
          },
          requestReviewSuccess: {
            title: "Review successfully requested!",
          },
          requestReviewError: {
            title: "Error requesting review",
            description: "Please try again in a few moments.",
          },
          requestReviewCommentRequired: {
            title: "Error requesting review",
            description: "Please select a reason",
          },
          markAsReimbursedSuccess: {
            title: "Reimbursement successfully marked as reimbursed!",
          },
          markAsReimbursedError: {
            title: "Error marking as reimbursed",
            description: "Please try again in a few moments.",
          },
        },
      },
      adminEditReimbursement: {
        back: "Back to Reimbursements",
        title: "Edit reimbursement",
        help: "Help Center",
        toasts: {
          failedEdit: {
            messages: {},
            title: "Error editing reimbursement",
          },
          successEdit: {
            title: "Reimbursement edited successfully!",
          },
        },
      },
      adminStatement: {
        title: "Corporate card closure",
        subtitle: "Approve the financial statements made and monitor those in progress.",
        failedToGetMovements: "Error fetching transactions",
        summaryCardHeader: "Card accounts",
        remainingExpensesWarning_one:
          "You have one outstanding expense report from before the selected period. Adjust the filter to view them.",
        remainingExpensesWarning_other:
          "You have {{count}} outstanding expense reports from before the selected period. Adjust the filter to view them.",
      },
      adminMovement: {
        breadcrumbs: {
          current: "Movement Details",
          previous: "Corporate Card Closing",
        },
        title: "Movement Details",
        edit: "Edit",
        reopen: "Reopen",
        extraButtons: {
          reject: "Reject",
          requestReview: "Request Review",
          approve: "Approve",
        },
        modals: {
          confirmationModalEditExpense: {
            attention: "Attention",
            popupTitle: "Are you sure you want to edit the expense report?",
            popupSubtitle: "It is still the responsibility of whoever made the expense.",
            confirm: "Confirm",
          },
          confirmationModalReopenExpense: {
            attention: "Attention",
            popupTitle: "Are you sure you want to reopen the expense report?",
            popupSubtitle: "Reopening will return it to the 'in completion' status",
            confirm: "Confirm",
          },
          confirmationModalRejectExpense: {
            attention: "Attention",
            popupTitle: "Are you sure you want to reject the expense report?",
            confirm: "Confirm",
            reasonLabel: "Select a reason for rejection",
          },
          confirmationModalRequestReview: {
            attention: "Attention",
            popupTitle: "Are you sure you want to send the expense report for review?",
            popupSubtitle: "The person who requested the expense report will receive it as a pending item to review.",
            confirm: "Request Review",
            reasonLabel: "Explain the reason for the review",
          },
          confirmationModalApprove: {
            attention: "Attention",
            popupTitle: "Are you sure you want to mark the expense as reimbursed?",
            confirm: "Confirm",
          },
        },
        toasts: {
          reopenSuccess: {
            title: "Expense report reopened successfully!",
          },
          reopenError: {
            title: "Error reopening expense report",
            description: "Please try again in a few moments.",
          },
          rejectSuccess: {
            title: "Expense report rejected successfully!",
          },
          rejectError: {
            title: "Error rejecting reimbursement",
            description: "Please try again in a few moments.",
          },
          rejectCommentRequired: {
            title: "Error rejecting reimbursement",
            description: "Please select a reason",
          },
          requestReviewSuccess: {
            title: "Review requested successfully!",
          },
          requestReviewError: {
            title: "Error requesting review",
            description: "Please try again in a few moments.",
          },
          requestReviewCommentRequired: {
            title: "Error requesting review",
            description: "Please select a reason",
          },
          approveSuccess: {
            title: "Expense report finalized successfully!",
          },
          approveError: {
            title: "Error finalizing expense report",
            description: "Please try again in a few moments.",
          },
        },
      },
      adminEditMovement: {
        back: "Back",
        title: "Edit moviment",
        help: "Help Center",
        toasts: {
          failedEdit: {
            messages: {},
            title: "Error editing movement",
          },
          successEdit: {
            title: "Movement edited successfully!",
          },
        },
      },
      statement: {
        title: "Corporate Card Statement",
      },
      pendingsItems: {
        back: "Back",
        title: "Review pending items",
        help: "Help center",
        close: "Close",
        exitAndSave: "Exit and save changes",
        skip: "Skip expense",
        fields: {
          currency: "currency",
          amount: "amount",
          date: "date",
          category: "category",
          description: "description",
          attachments: "receipt",
          establishment: "establishment",
        },
        submit: {
          onLoading: "Submitting account",
          description: "Submitting account ({{submittedCount}} of {{totalExpenses}})",
        },
        toasts: {
          failedEdit: {
            messages: {},
            title: "Failed to edit request",
          },
          successEdit: {
            title: "Success!",
            description: "Account statement saved successfully!",
          },
          successSubmit: {
            title: "Success!",
            description: "Account statement submitted successfully!",
          },

          requiredField: {
            title: "Required fields",
            description_one: "Fill in the {{fields}} field to report",
            description_other: "Fill in the fields {{fields, list(style: 'long'; type: 'conjunction';)}} to report",
          },
        },
      },
      visualizeMovement: {
        title: "Movement detail",
        titleEdit: "Edit movement",
        back: "Back",
        help: "Help center",
        cancel: "Cancel",
        submit: {
          save: "Save",
          sendExpenseToAccount: "Submit to account",
        },
        toasts: {
          failedEdit: {
            title: "Unable to save the expense",
            description: "Please try again in a few moments.",
          },
          successEdit: {
            title: "Expense saved successfully!",
          },
          failedSendingToAccount: {
            title: "Unable to send the expense for approval",
            description: "Please try again in a few moments.",
          },
          successSendingToAccount: {
            title: "Expense sent for approval successfully!",
          },
          requiredField: {
            title: "Required fields",
            description: "Fill in the required field to save the expense",
          },
        },
      },
      visualizeReimbursement: {
        breadcrumbs: {
          current: "Reimbursement detail",
          previous: "Reimbursements",
        },
        title: "Reimbursement detail",
        titleEdit: "Edit reimbursement",
        edit: "Edit reimbursement",
        delete: "Delete reimbursement",
        back: "Back",
        help: "Help center",
        cancel: "Cancel",
        submit: {
          save: "Save",
          sendExpenseToAccount: "Send to payment",
        },
        toasts: {
          failedEdit: {
            title: "Unable to save reimbursement",
            description: "Please try again in a few moments.",
          },
          successEdit: {
            title: "Reimbursement saved successfully!",
          },
          failedSendingToAccount: {
            title: "Unable to send the reimbursement for approval",
            description: "Please try again in a few moments.",
          },
          successSendingToAccount: {
            title: "Reimbursement sent for approval successfully!",
          },
          requiredField: {
            title: "Required fields",
            description: "Fill in the required field to save the reimbursement",
          },
          deleteSuccess: {
            title: "Reimbursement deleted successfully!",
          },
          deleteError: {
            title: "Error deleting reimbursement",
            description: "Please try again later.",
          },
        },
      },
    },
    containers: {
      category: {
        categoryList: {
          toasts: {
            toggleError: {
              title: "Error when enabling/disabling category",
              description: "Try again later",
            },
            deleteError: {
              title: "Error when deleting category",
              messages: {
                EXPENSES_EXISTS_WITH_CATEGORY: "There are expenses using this category",
              },
            },
          },
          deleteModal: {
            headerIconLabel: "Warning!",
            headerTitle: "Are you sure you want to delete this category?",
            headerSubtitle: "All data related to it will be lost and this action cannot be undone.",
            confirmDeletion: "Confirm deletion",
          },
          tooltips: {
            cantDeleteDefaultCategories: "Can't delete default categories",
          },
        },
      },
    },
    organisms: {
      modals: {
        defaultModal: {
          cancel: "Cancel",
          confirm: "Confirm",
        },
        confirmationModalDeleteExpense: {
          attention: "Attention!",
          pluralForm: {
            popupTitle: "Are you sure you want to delete the {{expensesSelected}} selected reimbursements?",
            popupSubtitle: "This action cannot be undone.",
            confirm: "Confirm deletions",
          },
          singularForm: {
            popupTitle: "Are you sure you want to delete the reimbursement?",
            popupSubtitle: "This action cannot be undone.",
            confirm: "Confirm deletion",
          },
        },
        confirmationModalEditExpense: {
          [ExpenseType.REIMBURSEMENT]: {
            attention: "Attention!",
            title: "Are you sure you want to edit the reimbursement?",
            extraTitle: "Are you sure you want to edit the refund?",
            subtitle:
              "After editing a reimbursement that is awaiting approval, the approval process will be restarted.",
            adminSubtitle: "It is still the responsibility of the person who made the expense.",
            confirm: "Confirm",
          },
          [ExpenseType.CORPORATE_CARD]: {
            attention: "Attention!",
            title: "Are you sure you want to edit the statement of accounts?",
            subtitle:
              "When editing the statement of accounts under approval, it will need to go through the approval flow again.",
            adminSubtitle: "It is still the responsibility of the person who made the expense.",
            confirm: "Confirm",
          },
        },
        confirmationModalReclassifyTransaction: {
          title: "Change purchase balance",
          titleCardLabels: {
            establishment: "Establishment",
            payment: "Payment",
            expenseAmount: "Expense amount",
            corporateBalance: "Corporate balance",
            cardType: {
              [ExpenseTransactionCardType.PLASTIC]: "Physical",
              [ExpenseTransactionCardType.VIRTUAL]: "Virtual",
            } satisfies Record<ExpenseTransactionCardType, string>,
          },
          explanationTitle: "What will happen to the balances?",
          explanationBody:
            "The purchase amount will be deducted from the corporate balance and will consume the {{benefitName}} balance.",
          contentCardLabels: {
            corporate: "Corporate",
            currentBalance: "Current Balance",
            balanceAfterChange: "Balance After Change",
          },
          buttons: {
            cancel: "Cancel",
            confirm: "Confirm",
          },
          successModal: {
            iconLabel: "All set!",
            headerTitle: "Purchase balance successfully changed!",
            headerSubtitle: "The purchase has been changed from the corporate balance to the {{benefitName}} balance.",
            buttons: {
              confirm: "Approve",
            },
          },
          warningModal: {
            iconLabel: "Attention!",
            headerTitle: "Unable to change purchase balance",
            headerSubtitle: "Please try again in a few moments.",
            buttons: {
              confirm: "Ok, got it",
            },
          },
        },
      },
      expenseUserGroupList: {
        emptyState: {
          title: "No results found",
          description: "Please review your search or filters and try again.",
        },
      },
      listAccordions: {
        filters: "Filters",
        orderBy: "Order by",
        searchByTeamMember: "Search by team member",
      },
      expenseTable: {
        emptyState: "No reimbursement requests registered",
        filters: "Filters",
        searchByCategory: "Search by category",
        pageSizeFormat: "{{value}} Items",
        noCategory: "Uncategorized",
        transactionStatus: common.transactionStatus,
        toasts: {
          undeletableExpensesSelected: {
            title: "Error",
            description: "Non-deletable expenses are selected",
          },
        },
        columns: {
          date: "Date",
          description: {
            [ExpenseType.REIMBURSEMENT]: "Description",
            [ExpenseType.CORPORATE_CARD]: "Movement",
          } satisfies Record<ExpenseType, string>,
          amount: "Amount",
          receipt: "Receipt",
          status: "Status",
          actions: "Actions",
        },
        actions: {
          edit: "Edit",
          delete: "Delete",
          visualize: "View details",
          requestReview: "Request Review",
          checkmark: {
            [ExpenseType.REIMBURSEMENT]: "Mark as reimbursed",
            [ExpenseType.CORPORATE_CARD]: "Approve",
          } satisfies Record<ExpenseType, string>,
          verticalDotsDropdown: {
            tooltip: "",
            visualize: {
              [ExpenseType.REIMBURSEMENT]: "View reimbursement details",
              [ExpenseType.CORPORATE_CARD]: "View expense details",
            } satisfies Record<ExpenseType, string>,
            edit: {
              [ExpenseType.REIMBURSEMENT]: "Edit reimbursement",
              [ExpenseType.CORPORATE_CARD]: "Edit expense",
            } satisfies Record<ExpenseType, string>,
            reject: {
              [ExpenseType.REIMBURSEMENT]: "Reject reimbursement",
              [ExpenseType.CORPORATE_CARD]: "Reject expense",
            } satisfies Record<ExpenseType, string>,
          },
          reopen: "Reopen",
          comingSoon: "Coming soon!",
        },
        visions: {
          open: "Open",
          finished: "Finished",
          all: "All",
        },
      },
      expenseUserGroupRow: {
        [ExpenseType.REIMBURSEMENT]: {
          emptyState: {
            buttonText: "Add Expense",
            description: "The employee has no linked expenses",
          },
          actions: {
            check: "Mark everything as reimbursed",
            comingSoon: "Coming soon!",
            options: "Options",
          },
          infoCard: {
            pendingExpenses_one: "{{count}} expenses",
            pendingExpenses_other: "{{count}} expense",
            totalOpen: "Total open",
            awaitAccounting: common.expenseStatusReimbursements.PENDING_ACCOUNTING,
            awaitApproval: common.expenseStatusReimbursements.PENDING_APPROVAL,
          },
          expenseStatus: common.expenseStatusReimbursements,
        },
        [ExpenseType.CORPORATE_CARD]: {
          emptyState: {
            buttonText: "",
            description: "The employee has no outstanding payments",
          },
          actions: {
            check: "Approve all",
            comingSoon: "Coming Soon!",
            options: "Options",
          },
          infoCard: {
            pendingExpenses_one: "{{count}} expense",
            pendingExpenses_other: "{{count}} expenses",
            totalOpen: "Total spent",
            awaitAccounting: common.expenseStatusCorporateCard.PENDING_ACCOUNTING,
            awaitApproval: "In installment",
          },
          expenseStatus: common.expenseStatusCorporateCard,
        },
      },
      expenseFilterDrawer: {
        filters: "Filters",
        submit: "Filter",
        reset: "Reset filters",
        noCategory: "Uncategorized",
        fields: {
          dateRange: {
            title: "Range",
            presets: {
              lastMonth_one: "Last month",
              lastMonth_other: "Last {{count}} months",
            },
          },
          corporateCardStatus: {
            title: "Expense status",
            labels: common.expenseStatusCorporateCard,
          },
          reimbursementsStatus: {
            title: "Reimbursements status",
            labels: common.expenseStatusReimbursements,
          },
          transactionStatus: {
            title: "Movement status",
            labels: common.transactionStatus,
          },
          category: {
            title: "Category",
          },
          amountRange: {
            title: "Amount",
          },
        },
      },
      movementTable: {
        tabs: {
          allMovements: "All transactions",
          ins: "Incomes",
          outs: "Expenses",
        },
        emptyState: "No registered transactions",
        addReceipt: "Add receipt",
        filters: "Filters",
        pageSizeFormat: "{{value}} Items",
        noCategory: "Uncategorized",
        failedToGetMovements: "Error retrieving transactions",
        cardType: {
          [ExpenseTransactionCardType.PLASTIC]: "Physical",
          [ExpenseTransactionCardType.VIRTUAL]: "Virtual",
        },
        expenseStatus: common.expenseStatusCorporateCard,
        cardLastDigits: "Ending {{cardLastDigits}}",
        transactionStatus: common.transactionStatus,
        columns: {
          date: "Date",
          description: "Transaction",
          amount: "Amount",
          payment: "Payment",
          status: "Expense status",
          receipt: "Receipt",
          actions: "Actions",
        },
        actions: {
          sendToAccount: {
            enabled: "Submit expense",
            disabled: "Purchase not yet confirmed. Wait for confirmation to submit expenses",
          },
          empty: {
            tooltip: "Deposits and refunds do not require reporting",
          },
          canceledTransaction: {
            tooltip: "Canceled transactions do not require reporting",
          },
          reclassify: "Change balance",
          visualize: "Visualize",
        },
      },
      pendingExpenseGrid: {
        failedToGetPendencies: "Failed to load pending items",
        totalAmount: "Total amount",
        amount: "Amount",
        emptyState: "No pending expenses",
        reviewPendingIssues: "Review pending items",
        cardType: {
          [ExpenseTransactionCardType.PLASTIC]: "Physical card",
          [ExpenseTransactionCardType.VIRTUAL]: "Virtual card",
        } satisfies Record<ExpenseTransactionCardType, string>,
        cardLastDigits: "Ending {{cardLastDigits}}",
        expensePendingAction: {
          [PendingExpenseAction.CATEGORY_MISSING]: "Category missing",
          [PendingExpenseAction.MULTIPLE_ITEMS_MISSING]: "Items missing",
          [PendingExpenseAction.PENDING_SUBMIT]: "Pending submission",
          [PendingExpenseAction.RECEIPT_MISSING]: "Receipt missing",
        } satisfies Record<PendingExpenseAction, string>,
        actionDetails: {
          title: {
            [PendingExpenseAction.CATEGORY_MISSING]: "Category",
            [PendingExpenseAction.MULTIPLE_ITEMS_MISSING]: "Receipt",
            [PendingExpenseAction.PENDING_SUBMIT]: "Expense",
            [PendingExpenseAction.RECEIPT_MISSING]: "Receipt",
          } satisfies Record<PendingExpenseAction, string>,
          subtitle: {
            [PendingExpenseAction.CATEGORY_MISSING]: "Add category",
            [PendingExpenseAction.MULTIPLE_ITEMS_MISSING]: "Add receipt",
            [PendingExpenseAction.PENDING_SUBMIT]: "Submit expense",
            [PendingExpenseAction.RECEIPT_MISSING]: "Add receipt",
          } satisfies Record<PendingExpenseAction, string>,
        },
        actions: {
          sendToAccount: "Submit expenses",
          reclassify: "Change balance",
        },
        pendingExpenses_zero: "No pending expenses",
        pendingExpenses_one: "{{count}} Pending expense",
        pendingExpenses_other: "{{count}} Pending expenses",
        pageSizeFormat: "{{value}} Items",
      },
      expenseSummaryChart: {
        [ExpenseType.REIMBURSEMENT]: {
          title: "Total Refunds",
          expenses_zero: "No Refunds",
          expenses_one: "{{count}} Refunds",
          expenses_other: "{{count}} Refunds",
        },
        [ExpenseType.CORPORATE_CARD]: {
          cardTitle: "Card Accounting",
          title: "Total Expenses Spent",
        },
      },
      expenseSummaryStatusCard: {
        [ExpenseType.REIMBURSEMENT]: {
          expenses_zero: "No Refunds",
          expenses_one: "{{count}} Refunds",
          expenses_other: "{{count}} Refunds",
          single: "Refunds",
          multiple: "Refunds",
          statusCard: {
            removeFilter: "Remove Filter",
            applyFilter: "Filter",
          },
          status: {
            awaitingAccounting: common.expenseStatusReimbursements[ExpenseStatus.PENDING_ACCOUNTING],
            awaitingApproval: common.expenseStatusReimbursements[ExpenseStatus.PENDING_APPROVAL],
            paid: common.expenseStatusReimbursements[ExpenseStatus.FINISHED],
            pending: "In installment",
            rejected: common.expenseStatusCorporateCard[ExpenseStatus.REJECTED],
          },
          tooltip: {
            [CombinedExpenseStatus.DRAFT_OR_REQUIRE_CHANGES]:
              "Reimbursement requests pending with the person who made the expense.",
            [CombinedExpenseStatus.PENDING_ACCOUNTING]: "Reimbursements awaiting approval by the company.",
            [CombinedExpenseStatus.FINISHED]: "Reimbursements completed.",
            [CombinedExpenseStatus.REJECTED]: "Reimbursements rejected.",
          },
        },
        [ExpenseType.CORPORATE_CARD]: {
          statusCard: {
            removeFilter: "Remove Filter",
            applyFilter: "Filter",
          },
          status: {
            awaitingAccounting: common.expenseStatusCorporateCard[ExpenseStatus.PENDING_ACCOUNTING],
            awaitingApproval: common.expenseStatusCorporateCard[ExpenseStatus.PENDING_APPROVAL],
            paid: common.expenseStatusCorporateCard[ExpenseStatus.FINISHED],
            pending: `${common.expenseStatusReimbursements[ExpenseStatus.DRAFT]}/${common.expenseStatusReimbursements[ExpenseStatus.REQUIRE_CHANGES]}`,
            rejected: common.expenseStatusCorporateCard[ExpenseStatus.REJECTED],
          },
          tooltip: {
            OPEN_OR_REQUIRED_CHANGES: "Account settlements pending with the person who made the expense.",
            AWAIT_ACCOUNTING: "Account settlements awaiting approval by the company.",
            PAID: "Account settlements completed.",
            REJECTED: "Expenses rejected.",
          },
        },
      },
      expensesStatusUpdateModal: {
        variants: {
          [ExpenseType.REIMBURSEMENT]: {
            [ExpenseStatus.FINISHED]: {
              headerTitle_one: "Are you sure you want to mark the expense as reimbursed?",
              headerTitle_other: "Are you sure you want to mark all {{count}} expenses as reimbursed?",
              buttonLabel: "Confirm reimbursement",
              toasts: {
                failed: {
                  title_one: "Failed to mark the expense as reimbursed",
                  title_other: "Failed to mark the expenses as reimbursed",
                },
                success: {
                  title_one: "Expense successfully marked as reimbursed!",
                  title_other: "Expenses successfully marked as reimbursed!",
                },
              },
            },
            [ExpenseStatus.REQUIRE_CHANGES]: {
              headerTitle_one: "Are you sure you want to send the reimbursement for review?",
              headerTitle_other: "Are you sure you want to send all {{count}} reimbursements for review?",
              headerSubtitle: "The person requesting the reimbursement will receive it as a pending item to review.",
              buttonLabel: "Confirm",
              reasonLabel_one: "Explain the review reason",
              reasonLabel_other: "Explain the review reasons",
              toasts: {
                failed: {
                  title_one: "Failed to send the reimbursement to review",
                  title_other: "Failed to send the reimbursements to review",
                },
                success: {
                  title_one: "Reimbursement successfully sent for review!",
                  title_other: "Reimbursements successfully sent for review!",
                },
              },
            },
            [ExpenseStatus.REJECTED]: {
              headerTitle_one: "Are you sure you want to reject the reimbursement?",
              headerTitle_other: "Are you sure you want to reject {{count}} reimbursements?",
              buttonLabel: "Reject",
              reasonLabel_one: "Select a reason for disapproval",
              reasonLabel_other: "Select a reason for disapprovals",
              toasts: {
                failed: {
                  title_one: "Failed to reject the reimbursement",
                  title_other: "Failed to reject the reimbursements",
                },
                success: {
                  title_one: "Reimbursement successfully marked as rejected!",
                  title_other: "Reimbursements successfully marked as rejected!",
                },
              },
            },
            [ExpenseStatus.PENDING_ACCOUNTING]: {
              headerTitle_one: "Are you sure you want to reopen the reimbursement?",
              headerTitle_other: "Are you sure you want to reopen {{count}} reimbursements?",
              headerSubtitle_one: "Reopening will reset it to the 'in payment' status.",
              headerSubtitle_other: "Reopening will reset it to the 'in payment' status.",
              buttonLabel: "Reopen",
              toasts: {
                failed: {
                  title_one: "Failed to reopen the reimbursement",
                  title_other: "Failed to reopen the reimbursements",
                },
                success: {
                  title_one: "Reimbursement successfully reopened!",
                  title_other: "Reimbursements successfully reopened!",
                },
              },
            },
          },
          [ExpenseType.CORPORATE_CARD]: {
            [ExpenseStatus.FINISHED]: {
              headerTitle_one: "Are you sure you want to approve the expense report?",
              headerTitle_other: "Are you sure you want to approve the {{count}} expense reports?",
              buttonLabel: "Confirm",
              toasts: {
                failed: {
                  title_one: "Failed to finalize the expense report",
                  title_other: "Failed to finalize the expense reports",
                },
                success: {
                  title_one: "Expense report successfully finalized!",
                  title_other: "Expense reports successfully finalized!",
                },
              },
            },
            [ExpenseStatus.REQUIRE_CHANGES]: {
              headerTitle_one: "Are you sure you want to send the expense report for review?",
              headerTitle_other: "Are you sure you want to send all {{count}} expense reports for review?",
              headerSubtitle: "The person requesting the expense report will receive it as a pending item to review.",
              buttonLabel: "Confirm",
              reasonLabel_one: "Explain the review reason",
              reasonLabel_other: "Explain the review reasons",
              toasts: {
                failed: {
                  title_one: "Failed to send the expense report to review",
                  title_other: "Failed to send the expense reports to review",
                },
                success: {
                  title_one: "Expense report successfully sent for review!",
                  title_other: "Expense reports successfully sent for review!",
                },
              },
            },

            [ExpenseStatus.REJECTED]: {
              headerTitle_one: "Are you sure you want to reject the expense report?",
              headerTitle_other: "Are you sure you want to reject {{count}} expense reports?",
              buttonLabel: "Reject",
              reasonLabel_one: "Select a reason for disapproval",
              reasonLabel_other: "Select a reason for disapprovals",
              toasts: {
                failed: {
                  title_one: "Failed to reject the expense report",
                  title_other: "Failed to reject the expense reports",
                },
                success: {
                  title_one: "Expense report successfully rejected!",
                  title_other: "Expense reports successfully rejected!",
                },
              },
            },
            [ExpenseStatus.PENDING_ACCOUNTING]: {
              headerTitle_one: "Are you sure you want to reopen the expense report?",
              headerSubtitle_one: "Reopening will reset it to the 'in payment' status.",
              headerTitle_other: "Are you sure you want to reopen {{count}} expense reports?",
              headerSubtitle_other: "Reopening will reset it to the 'in payment' status.",
              buttonLabel: "Reopen",
              toasts: {
                failed: {
                  title_one: "Failed to reopen the expense report",
                  title_other: "Failed to reopen the expense reports",
                },
                success: {
                  title_one: "Expense report successfully reopened!",
                  title_other: "Expense reports successfully reopened!",
                },
              },
            },
          },
        },
        pleaseSelectAReason: "Please select a reason",
        warning: "Warning!",
        pleaseTryAgainLater: "Please try again later.",
      },
    },
    molecules: {
      categoryFormModal: {
        editCategory: "Edit category",
        createCategory: "Create category",
        save: "Save",
        create: "Create",
        fields: {
          name: {
            label: "Category name",
          },
          externalId: {
            label: "Company code",
            helperText: "Enter the code used by your company for better control.",
          },
          activated: {
            label: "Enabled/disabled",
          },
        },
        requiredField_one: "Required field",
        requiredField_other: "Required fields",
        helperTextCodeField: "Category reference code",
        mandatoryDescription: "Mandatory fields",
        toasts: {
          successCreated: {
            title: "Success",
            description: "Category created successfully!",
          },
          successEdited: {
            title: "Success",
            description: "Category edited successfully!",
          },
          errorCreate: {
            title: "Error creating category",
            messages: {
              DUPLICATED_CATEGORY_NAME: "A category with the same name already exists",
            },
          },
          errorEdit: {
            title: "Error editing category",
            messages: {
              DUPLICATED_CATEGORY_NAME: "A category with the same name already exists",
            },
          },
        },
        tooltips: {
          cantEditDefaultCategoryName: "Can't edit the name of default categories",
        },
      },
      dateRangeField: { label: "Select range" },
      filterButton: {
        filters: "Filters",
        tooltip: {
          tooltipTitle_one: "You have {{count}} selected filter",
          tooltipTitle_other: "You have {{count}} selected filters",
        },
      },
      errorBoundary: {
        defaultTitle: "Error trying to load the page",
        pageLabel: "Page:",
        moduleLabel: "Module:",
        retryButton: "Try again",
        isolatedError: {
          modulePrefix: "Module:",
          defaultTitle: "An error occurred",
          defaultDescription: "Something went wrong while rendering this module.",
        },
      },
      defaultModal: {
        cancel: "Cancel",
        confirm: "Confirm",
      },
      receiptDropzone: {
        title: "Receipt",
        empty: {
          title: "Drag and drop your file here",
          subtitle: "Allowed formats: {{acceptedFileExtensions}}",
          uploadButton: "Select file",
        },
        addReceipt: "Add receipt",
        prevReceipt: "Previous receipt",
        nextReceipt: "Next receipt",
        alt: "Receipt",
        loading: "Loading receipts...",
        modals: {
          delete: {
            iconLabel: "Attention!",
            title: "Are you sure you want to remove this receipt?",
            subtitle: "All data from this receipt will be lost and this action cannot be undone.",
            action: "Remove",
          },
        },
        toasts: {
          uploadError: {
            title: "Error uploading receipt",
            messages: {
              INVALID_FILE_FORMAT: "Invalid file format, please try again with a different file",
            },
          },
        },
      },
      reimbursementForm: {
        amount: "R$0,00",
        establishment: "Establishment",
        category: "Category",
        expenseAmount: "Expense amount",
        fields: {
          currency: "Currency",
          amount: "Amount",
          date: "Reimbursement date",
          category: "Category",
          description: "Commentary (optional)",
        },
        categoryLoading: "Category (Loading...)",
      },
      expenseFooterActions: {
        [ExpenseType.REIMBURSEMENT]: {
          exitAndSave: "Exit and save changes",
          addAnother: "Save and request another",
          submit: {
            onLoading: "Request reimbursement",
            description: "Request reimbursement",
          },
          toasts: {
            requiredField: {
              title: "Required fields",
              saveDescription: "Fill in the required field to save the request",
              description: "Fill in the required field to submit the request",
            },
          },
          new: "New reimbursement",
          edit: "Edit reimbursement",
          cancel: "Cancel",
          return: "Return",
          save: "Save",
          requestReimbursement: "Request reimbursement",
          creating: "Creating reimbursement...",
          updating: "Updating reimbursement...",
        },
        [ExpenseType.CORPORATE_CARD]: {
          exitAndSave: "Exit and save changes",
          addAnother: "",
          submit: {
            onLoading: "Save change",
            description: "Save change",
          },
          toasts: {
            requiredField: {
              title: "Required fields",
              saveDescription: "Fill in the required field to save changes",
              description: "Fill in the required field to save changes",
            },
          },
          new: "",
          edit: "Edit transaction",
          cancel: "Cancel",
          return: "Back",
          save: "Save change",
          requestReimbursement: "Save change",
          creating: "Saving...",
          updating: "Updating...",
        },
      },
      imagesModal: {
        toasts: {
          failedLoadingReceipts: {
            title: "Error!",
            description: "Failed to load receipts, please try again later",
          },
        },
      },
      silderField: {
        min: "Minimum Value",
        max: "Maximum Value",
      },
      checkboxGroup: {
        selectAll: "Select all",
      },
      expenseStatusTag: {
        expenseStatus: {
          [ExpenseType.REIMBURSEMENT]: common.expenseStatusReimbursements,
          [ExpenseType.CORPORATE_CARD]: common.expenseStatusCorporateCard,
        } satisfies Record<ExpenseType, Record<ExpenseStatus, string>>,
      },
      categorySelect: {
        label: "Category",
        errorDescription: "This field is required",
      },
      expenseDescriptionInput: {
        label: "Description (optional)",
        maxCharacters: "Max {{count}} characters",
      },
      finishedPendingItemsList: {
        title: "Successfully completed accounts!",
        subTitle: "Expenses have been sent to the approvers, check the details for more information.",
        cardTitle: "Establishment",
        expenseAmount: "Expense amount",
      },
      pendingsItemsForm: {
        view: "View expense",
        accordionFooter: "Submit the receipts and fill in the pending information for your expenses.",
        cardTitle: "Establishment",
        expenseAmount: "Expense amount",
        expenseDate: "Expense date",
        expenseDateValue: "{{date}}: {{time}}",
        payment: "Payment",
        corporative: "Corporate balance",
        reviewReason: "Review reason: {{accountingComments}}",
        reviewedBy: "Marked for review by {{name}}",
        expenseStatus: common.expenseStatusCorporateCard,
        cardType: {
          [ExpenseTransactionCardType.PLASTIC]: "Physical Card",
          [ExpenseTransactionCardType.VIRTUAL]: "Virtual Card",
        },
        balanceChange: {
          title: "Bought with the wrong balance?",
          subTitle: "No worries, change your purchase to the benefits balance here.",
          submit: "Change balance",
        },
      },
      expenseStatusStepperCard: {
        title: "Expense Status",
        expenseStatus: {
          [ExpenseType.REIMBURSEMENT]: common.expenseStatusReimbursements,
          [ExpenseType.CORPORATE_CARD]: common.expenseStatusCorporateCard,
        } satisfies Record<ExpenseType, Record<ExpenseStatus, string>>,
        subtitle: "In case of any irregularities with the expense, contact your manager.",
      },
      reasonSelect: {
        [ExpenseType.REIMBURSEMENT]: {
          label: "Reason",
          otherReasonLabel: "Add a comment",
          reasons: {
            absenceOfReceipt: "Absence of receipt",
            invalidReceipt: "Invalid receipt",
            lateRefundRequest: "Late refund request",
            suspectedFraud: "Suspected fraud",
            valueAboveWhatForeseenPolicy: "Amount above policy limit",
            other: "Other",
          },
        },
        [ExpenseType.CORPORATE_CARD]: {
          label: "Reason",
          otherReasonLabel: "Add a comment",
          reasons: {
            absenceOfReceipt: "Absence of receipt",
            invalidReceipt: "Invalid receipt",
            lateRefundRequest: "Late account submission",
            suspectedFraud: "Suspected fraud",
            valueAboveWhatForeseenPolicy: "Amount above policy limit",
            other: "Other",
          },
        },
      },
      movementForm: {
        fields: {
          category: "Category",
          comments: "Comment",
        },
        edit: "Edit account submission",
        cardTitle: "Establishment",
        expenseAmount: "Expense amount",
        expenseDate: "Expense date",
        expenseDateValue: "{{date}}, at {{time}}",
        payment: "Payment",
        corporative: "Corporate balance",
        canceledTransaction: {
          title: "Purchase canceled",
          subtitle:
            "The account submission was rejected because the purchase was canceled by the establishment. If you have questions, contact them directly.",
          bottomSubtitle: "The amount has already been refunded to your balance.",
        },
        reviewReason: "Review reason: {{accountingComments}}",
        rejectionReason: "Rejection reason: {{accountingComments}}",
        reviewedBy: "Marked for review by {{name}}",
        rejectedBy: "Rejected by {{name}}",
        errorSubmitting: "An error occurred while submitting for review",
        cardType: {
          [ExpenseTransactionCardType.PLASTIC]: "Physical Card",
          [ExpenseTransactionCardType.VIRTUAL]: "Virtual Card",
        },
        expenseStatus: common.expenseStatusCorporateCard,
        balanceChange: {
          title: "Bought with the wrong balance?",
          subTitle: "No worries, change your purchase to the benefits balance here.",
          submit: "Change balance",
        },
      },
      reimbursementDetails: {
        fields: {
          date: "Expense date",
          id: "Expense ID",
          category: "Category",
          comments: "Comment",
        },
        edit: "Edit account submission",
        cardTitle: "Establishment",
        expenseAmount: "Expense amount",
        expenseDate: "Expense date",
        expenseDateValue: "{{date}}, at {{time}}",
        payment: "Payment",
        corporative: "Corporate balance",
        reviewReason: "Review reason: {{accountingComments}}",
        reviewedBy: "Marked for review by {{name}}",
        rejectedBy: "Rejected by {{name}}",
        rejectionReason: "Rejection reason: {{accountingComments}}",
        errorSubmitting: "An error occurred while submitting for review",
        cardType: {
          [ExpenseTransactionCardType.PLASTIC]: "Physical Card",
          [ExpenseTransactionCardType.VIRTUAL]: "Virtual Card",
        },
        expenseStatus: common.expenseStatusReimbursements,
      },
      accountBalanceCard: {
        title: "Corporate balance",
        linkButtonText: "Access statements",
        initialDateTitle: "Initial total in Flash (at {{- dateValue, datetime}})",
        finalDateTitle: "Final total in Flash (at {{- dateValue, datetime}})",
        wallet: "Corporate wallet",
        cards: "Corporate cards",
      },
      establishmentSelect: {
        establishment: "Establishment",
        typeEstablishmentNameOrAddress: "Type the name or address of the establishment.",
        noEstablishmentFound: "No establishment found",
        loading: "Loading...",
      },
    },
    externals: {
      dashboardReimbursementsSection: {
        title: "Reimbursement",
      },
      reimbursementsSummarySection: {
        title: "Reimbursements",
        action: {
          label: "Access reimbursements",
        },
        chart: {
          totalReimbursements: "Total reimbursements (last 30 days)",
        },
        tooltip: {
          [ExpenseStatus.DRAFT]:
            "Reimbursement requests pending, either with the employee or awaiting analysis and payment by the company.",
          [ExpenseStatus.FINISHED]: "Completed reimbursements.",
          [ExpenseStatus.REJECTED]: "Rejected reimbursement requests.",
        },
        status: {
          open: "Open",
          paid: "Paid",
          rejected: "Rejected",
        },
      },
      statementSummarySection: {
        title: "Corporate Card Statements",
        action: {
          label: "Access statement",
        },
        chart: {
          totalStatement: "Total expenses (last 30 days)",
        },
        tooltip: {
          [ExpenseStatus.DRAFT]: "Pending statements, either with the employee or awaiting company approval.",
          [ExpenseStatus.FINISHED]: "Completed statements.",
          [ExpenseStatus.REJECTED]: "Rejected statements.",
        },
        status: {
          open: "Open",
          paid: "Completed",
          rejected: "Rejected",
        },
      },
    },
  },
} satisfies TranslationKey;
