import { Divider } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import Icon from '@frontend/components/Icon';
import DefaultModal from '@frontend/components/modals/DefaultModal';
import Tag from '@frontend/components/TagV2';
import Typography from '@frontend/components/Typography';
import ModalService from '@frontend/services/ModalService';
import { trpc } from '@frontend/trpc';
import { LoadingDetailCard } from './LoadingDetailCard';
import * as SC from './styled';
import { UserDetailCard } from './UserDetailCard';
import { userDetailData } from './UserDetailData';

type Props = {
  formData:
    | {
        employeeIds: Set<string>;
        deposit: {
          amount: number;
          creditDate: Date;
          paymentMethod: 'FLASH_CASH' | 'PIX' | 'BILLET';
          expires: boolean;
          automatic: boolean;
          complementary: boolean;
          confirmationToken?: string;
          expirationDate?: Date;
        };
      }
    | {
        deposits: {
          value: number;
          id: string;
          documentNumber: string;
          name: string;
        }[];
        creditDate: Date;
        paymentMethod: 'FLASH_CASH' | 'PIX' | 'BILLET';
        expires: boolean;
        automatic: boolean;
        complementary: boolean;
        confirmationToken?: string;
        expirationDate?: Date;
      };
  onConfirm?: () => void;
};

type DetailDataInput = Parameters<typeof userDetailData>[0];

function DuplicateDepositModal({ formData, onConfirm }: Props) {
  const modalController = ModalService.useModalController();

  const employees = trpc.company.employees.findEmployeesByIds.useQuery({
    employeeIds:
      'employeeIds' in formData ? Array.from(formData.employeeIds) : [],
  });

  const detailDataInput: DetailDataInput =
    'employeeIds' in formData
      ? {
          deposits: Array.from(formData.employeeIds).map((id) => {
            const user = employees?.data?.users.find((u) => u.id === id);
            return {
              value: formData.deposit.amount,
              id,
              name: user?.name || '',
              documentNumber: user?.documentNumber || '',
            };
          }),
          creditDate: formData.deposit.creditDate,
          paymentMethod: formData.deposit.paymentMethod,
          complementary: formData.deposit.complementary,
          automatic: formData.deposit.automatic,
          expires: formData.deposit.expires,
        }
      : {
          deposits: formData.deposits,
          creditDate: formData.creditDate,
          paymentMethod: formData.paymentMethod,
          complementary: formData.complementary,
          automatic: formData.automatic,
          expires: formData.expires,
        };

  const { formattedAsText, depositDetails } = userDetailData(detailDataInput);

  return (
    <DefaultModal
      isOpen={modalController.visible}
      onClose={modalController.remove}
      highlight={{
        description: 'Atenção',
        size: 'compact',
        variant: 'warning',
      }}
      title="Você pode estar fazendo um depósito duplicado."
      subtitle="Identificamos que você acabou de fazer um depósito com os mesmos dados. Ele está em processamento e, em breve, o saldo será disponibilizado."
      size="sm"
      buttons={{
        cancel: { text: 'Cancelar', onClick: modalController.remove },
        confirm: {
          text: 'Continuar mesmo assim',
          onClick: () => {
            if (onConfirm) {
              onConfirm();
            }
            modalController.remove();
          },
          icon: 'IconArrowRight',
          color: 'default',
        },
      }}
    >
      <SC.Container>
        {employees?.isLoading ? (
          <LoadingDetailCard />
        ) : (
          <>
            <UserDetailCard title={formattedAsText} />
            <Divider orientation="horizontal" />
            {depositDetails && (
              <>
                <Flex padding="xs2">
                  <Flex direction="row" gap="xs4">
                    <Tag dot variant="info" size="sm">
                      Processando depósito
                    </Tag>
                    <Flex direction="row" gap="xs4" align="center">
                      <Tag variant="neutral" size="sm">
                        <Flex direction="row" align="center">
                          <Icon
                            size={24}
                            color="neutral_40"
                            background="neutral"
                            name={depositDetails.icon}
                          />
                          {depositDetails.label}
                        </Flex>
                      </Tag>
                    </Flex>
                  </Flex>
                </Flex>
                <SC.DepositInfoContainer
                  padding={[undefined, 'xs2', 'xs2', 'xs2']}
                  grow={1}
                  align="center"
                >
                  <Flex
                    direction="column"
                    radius="s"
                    padding={['xs3', 'xs2']}
                    backgroundColor="neutral_95"
                    gap="xs3"
                  >
                    <Flex align="center" grow={1}>
                      <Icon
                        size={24}
                        color="neutral_40"
                        background="neutral"
                        name={
                          depositDetails.depositType === 'Depósito único'
                            ? 'IconTransferIn'
                            : 'IconRepeat'
                        }
                      />
                      <Flex
                        direction="column"
                        padding={[undefined, undefined, undefined, 'xs4']}
                      >
                        <Typography.Body4 color="neutral_20" weight={600}>
                          {depositDetails.depositType}
                        </Typography.Body4>
                        <Typography.Caption color="neutral_40" weight={600}>
                          {depositDetails.creditDateDescription}
                        </Typography.Caption>
                      </Flex>
                    </Flex>
                    <Flex align="center" grow={1}>
                      <Icon
                        size={24}
                        color="neutral_40"
                        background="neutral"
                        name="IconCoin"
                      />
                      <Flex
                        direction="column"
                        padding={[undefined, undefined, undefined, 'xs3']}
                      >
                        <Typography.Body4 color="neutral_20" weight={600}>
                          {depositDetails.totalAmount}
                        </Typography.Body4>
                        <Typography.Caption color="neutral_40" weight={600}>
                          {depositDetails.individualAmount}
                        </Typography.Caption>
                      </Flex>
                    </Flex>
                  </Flex>
                </SC.DepositInfoContainer>
              </>
            )}
          </>
        )}
      </SC.Container>
    </DefaultModal>
  );
}

export default ModalService.create(DuplicateDepositModal);
