import { Button, LinkButton } from '@flash-tecnologia/hros-web-ui-v2'

import { FilterContent } from './filter-content'
import { useFilter, UseFilterParams } from './hooks/use-filters'
import { ContainerStyled, ContentStyled, FooterStyled } from './styles'

type FiltersProps = UseFilterParams

export function Filters({ onCloseFilter }: FiltersProps) {
  const {
    applyFilters,
    filters,
    options,
    resetFilters,
    updateFilterState,
    selectAll,
  } = useFilter({ onCloseFilter })

  return (
    <ContainerStyled>
      <ContentStyled>
        <FilterContent
          id="groups"
          title="Grupos"
          options={options.groupOptions}
          selectedItems={filters.groups ?? []}
          handleToggleItem={updateFilterState}
          selectAll={selectAll}
          useId={false}
        />
      </ContentStyled>

      <FooterStyled>
        <LinkButton underlined variant="default" onClick={resetFilters}>
          Limpar filtros
        </LinkButton>

        <Button variant="primary" minWidth="184px" onClick={applyFilters}>
          Filtrar
        </Button>
      </FooterStyled>
    </ContainerStyled>
  )
}
