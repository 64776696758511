import {
  Button,
  Icons,
  LinkButton,
  ShapeIcon,
} from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import Grid from '@frontend/components/frames/Grid';
import { Highlight } from '@frontend/components/Highlight';
import Typography from '@frontend/components/Typography';
import { useNavigate } from 'react-router-dom';
import { Box, Img } from './styled';

export function KnowBalanceRequest() {
  const navigate = useNavigate();

  return (
    <Grid templateColumns="50%" justify="center">
      <Flex
        borderWidth="xs2"
        borderColor="neutral_80"
        radius="m"
        padding="xs"
        align="center"
        direction="column"
        gap="xs"
      >
        <Highlight variant="announcement" size="compact">
          Conheça!
        </Highlight>
        <Typography.Headline8 center>
          Gerencie pedidos de saldo dentro da plataforma Flash e ganhe mais
          agilidade no processo
        </Typography.Headline8>
        <div>
          <Button
            variant="primary"
            onClick={() =>
              navigate(
                '/expenseManagement/customizations?tab=corporate-card&subTab=approval',
              )
            }
          >
            Defina o fluxo de aprovação <Icons name="IconArrowRight" />
          </Button>
        </div>
        <Grid templateColumns="130%" justify="center">
          <Grid templateColumns="1fr 1fr 1fr" gap="xs">
            {box.map((item, index) => {
              return (
                <Flex
                  key={index}
                  borderWidth="xs2"
                  borderColor="secondary_95"
                  radius="m"
                  direction="column"
                  backgroundColor="neutral_100"
                >
                  <Img src={item.image} alt={item.alt} />
                  <Flex direction="column" gap="xs4" padding="xs2">
                    <Typography.Headline10 color="primary">
                      {item.title}
                    </Typography.Headline10>
                    <Typography.Caption>{item.description}</Typography.Caption>
                  </Flex>
                </Flex>
              );
            })}
          </Grid>
        </Grid>
        <Typography.Body4 weight={700}>
          Avise sua equipe e comece a acompanhar os pedidos por aqui!
        </Typography.Body4>
        <Box
          style={{ width: '100%' }}
          radius="m"
          backgroundColor="secondary_99"
          padding="xs2"
          align="center"
          gap="xs2"
        >
          <ShapeIcon
            variant="default"
            name="IconMessageDots"
            size={40}
            stroke="default"
          />
          <Typography.Caption weight={700}>
            Ficou com alguma dúvida?
          </Typography.Caption>
          <LinkButton
            size="small"
            variant="default"
            href="https://beneficios.flashapp.com.br/faq/pedido-de-saldo-no-cart%C3%A3o-corporativo"
          >
            Acesse Nossa FAQ
          </LinkButton>
        </Box>
      </Flex>
    </Grid>
  );
}

const box = [
  {
    title: '1. Pedido',
    description:
      'Uma pessoa que usa o cartão corporativo pode fazer um pedido de saldo pelo app da Flash.',
    image:
      'https://public-images-flash.s3.sa-east-1.amazonaws.com/expense/corporate-card/request-balance/balance-request.svg',
    alt: 'Formulário do pedido de saldo',
  },
  {
    title: '2. Análise',
    description:
      'Após a aprovação, você receberá uma notificação sobre o pedido pendente, que aparecerá aqui para análise.',
    image:
      'https://public-images-flash.s3.sa-east-1.amazonaws.com/expense/corporate-card/request-balance/balance-analysis.svg',
    alt: 'Lista de pedidos de saldo',
  },
  {
    title: '3. Depósito',
    description:
      'Revise o pedido, faça o depósito e pronto! O saldo estará disponível para a pessoa na data definida.',
    image:
      'https://public-images-flash.s3.sa-east-1.amazonaws.com/expense/corporate-card/request-balance/balance-deposit.svg',
    alt: 'Notificação do pedido de saldo confirmado',
  },
];
