import Flex from '@frontend/components/frames/Flex';
import styled from 'styled-components';
import Typography from '../Typography';

export const PixContainer = styled(Flex)`
  width: 100%;
  align-items: center;
  gap: 16px;
`;

export const QrCodeWrapper = styled.div`
  flex-shrink: 0;
  width: 140px;
`;

export const PixCodeContainer = styled(Typography.Body4)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  font-weight: 400;
  word-break: break-word;
  text-align: left;
  max-width: 100%;
  overflow-wrap: anywhere;
`;

export const ButtonWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  margin-top: 8px;
`;
