import { useContext, useState } from "react"
import { useNavigate } from "react-router"
import { useParams } from "react-router-dom"
import {
  DocumentCard,
  MiniCard,
  BindSection,
  PageTemplate,
} from "../../../../components"
import { globalContext } from "../../../../context"
import { IFieldConfig, IFormConfig, IFormGroup } from "@customTypes/documents"
import dispatchToast from "../../../../utils/dispatchToast"
import {
  BodyContainer,
  CategoryContainer,
  Description,
  DocumentsContainer,
  HeaderContainer,
} from "./styled"
import { Skeleton, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"
import { trpc } from "@api/client"
import { PendoEnum, replacePendoVariable } from "@utils/pendo"

const SkeletonLoading = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 24,
      }}
    >
      <Skeleton variant="rectangular" height={360} />
      {[0, 1, 2].map((_, idx) => (
        <Skeleton key={idx} variant="rectangular" height={132} />
      ))}
    </div>
  )
}

const ManageSections = () => {
  const [selectedSection, setSelectedSection] = useState<
    "employee" | "dependent"
  >("employee")
  const theme = useTheme()

  const { companyId } = useContext(globalContext)
  const { flowId, id: baseOrExtensionId } = useParams()

  const navigate = useNavigate()

  const { mutateAsync: sendFormConfigExtension } =
    trpc.formConfig.sendFormConfigExtension.useMutation()

  const defaultFormConfig: IFormConfig = {
    name: "",
    formGroups: [],
    type: "base",
  }

  const {
    data: getFormConfigByExtensionOrBaseData,
    refetch,
    isInitialLoading: isLoading,
    isRefetching,
  } = trpc.formConfig.getFormConfigByExtensionOrBase.useQuery(
    {
      id: baseOrExtensionId ?? "",
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        console.log(err)
        dispatchToast({
          type: "error",
          content: "Houve um problema ao buscar dados do documento",
        })
        navigate(`/flows/settings/${flowId}/documents/list`)
      },
    },
  )

  const formConfig: IFormConfig | undefined =
    getFormConfigByExtensionOrBaseData && {
      _id: getFormConfigByExtensionOrBaseData._id,
      name: getFormConfigByExtensionOrBaseData.name,
      formGroups:
        getFormConfigByExtensionOrBaseData.formGroups?.map<IFormGroup>(
          (formGroup) => ({
            _id: formGroup._id,
            title: formGroup.title,
            description: formGroup.description || "",
            fields: formGroup.fields?.map<IFieldConfig>((field) => ({
              customFieldId: field.customFieldId,
              documentTypeId: field.documentTypeId,
              formGroupId: field.formGroupId,
              _id: field.fieldId,
              fieldType: field.fieldType,
              fieldRule: field.fieldRule,
              options: field.options,
              order: field.order,
              component: field.component,
              enabled: field.enabled,
              fieldId: field.fieldId,
              type: field.type,
              placeholder: field.placeholder,
            })),
            category: formGroup.category,
            enabled: formGroup.enabled,
            fillInstruction: formGroup.fillInstruction,
            order: formGroup.order,
            type: formGroup.type,
          }),
        ) ?? [],
      type: getFormConfigByExtensionOrBaseData.type,
      companyId: getFormConfigByExtensionOrBaseData.companyId,
      baseId: getFormConfigByExtensionOrBaseData.baseId,
      extensionId: getFormConfigByExtensionOrBaseData.extensionId,
    }

  const handleUpdateSection = (
    updatedFormGroup: IFormGroup,
    successMessage?: string,
  ) => {
    if (!formConfig) return
    const groupIndex = formConfig.formGroups.findIndex(
      (group) => group._id === updatedFormGroup._id,
    )
    if (groupIndex === -1) return

    const updatedGroups = formConfig.formGroups.map<IFormGroup>((group) => ({
      ...group,
      description: group.description || "",
    }))
    updatedGroups.splice(groupIndex, 1, updatedFormGroup)

    sendFormConfigExtension(
      {
        companyId: companyId || "",
        baseId: formConfig?._id || "",
        name: formConfig?.name || "",
        formGroups: updatedGroups,
      },
      {
        onSuccess: (response) => {
          dispatchToast({
            type: "success",
            content: successMessage || "Campo atualizado com sucesso!",
          })
          const { _id: newExtension } = response
          if (baseOrExtensionId !== newExtension) {
            navigate(`/flows/settings/${flowId}/documents/${newExtension}`)
            return
          }
          refetch()
        },
        onError: (err: any) => {
          console.log(err)
          dispatchToast({
            type: "error",
            content: "Houve um erro ao atualizar campo",
          })
        },
      },
    )
  }

  const filteredSection = formConfig?.formGroups.filter(
    (group) => group.category === selectedSection,
  )

  return (
    <PageTemplate
      history={[
        { name: "Admissão", href: "/flows/hiring" },
        {
          name: "Modelos de documentos",
          href: `/flows/settings/${flowId}/documents/list`,
        },
        { name: "Configurar lista de documentos" },
      ]}
    >
      <HeaderContainer>
        <Typography variant="headline6" variantColor={theme.colors.neutral[20]}>
          Configurar modelo
        </Typography>
      </HeaderContainer>

      <BodyContainer>
        <CategoryContainer>
          <Typography
            variant="headline7"
            variantColor={theme.colors.secondary[50]}
          >
            Categorias
          </Typography>

          <Description variant="body3">
            Configure abaixo o que será solicitado na lista de documentos, como
            uniforme, foto para o crachá, entre outros.
          </Description>

          <MiniCard
            active={selectedSection === "employee"}
            onClick={() => setSelectedSection("employee")}
            dataOnboarding={replacePendoVariable(
              PendoEnum.MODEL_DOCUMENTS_TAB_CATEGORY,
              [{ name: "$category", value: "candidate" }],
            )}
          >
            Candidato
          </MiniCard>

          <MiniCard
            active={selectedSection === "dependent"}
            onClick={() => setSelectedSection("dependent")}
            dataOnboarding={replacePendoVariable(
              PendoEnum.MODEL_DOCUMENTS_TAB_CATEGORY,
              [{ name: "$category", value: "dependent" }],
            )}
          >
            Dependente
          </MiniCard>
        </CategoryContainer>

        <DocumentsContainer>
          {isLoading || isRefetching ? (
            <SkeletonLoading />
          ) : (
            <>
              {formConfig && (
                <BindSection
                  flowId={flowId || ""}
                  selectedSection={selectedSection}
                  formConfig={formConfig}
                />
              )}

              {filteredSection?.map((section, idx) => (
                <DocumentCard
                  key={`${section._id}-${idx}`}
                  formConfig={formConfig ?? defaultFormConfig}
                  flowId={flowId || ""}
                  section={section}
                  handleUpdateSection={handleUpdateSection}
                  dataOnboarding={replacePendoVariable(
                    PendoEnum.MODEL_DOCUMENTS_SECTION,
                    [
                      {
                        name: "$number",
                        value: idx.toString(),
                      },
                    ],
                  )}
                />
              ))}
            </>
          )}
        </DocumentsContainer>
      </BodyContainer>
    </PageTemplate>
  )
}

export default ManageSections
