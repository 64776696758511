import { useExternalCards } from '@frontend/pages/ExternalCards/utils/hooks/useExternalCards';
import { Navigate, Outlet } from 'react-router-dom';

export default function HasExternalCard() {
  const externalCards = useExternalCards();

  if (!externalCards.enabled) {
    return <Navigate to={'/home'} />;
  }
  return <Outlet />;
}
