import { Tag, Tooltip, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useMemo } from 'react'
import { formatCurrency } from 'src/utils/mask'

import { EmojiStackContainerStyled, NoEmojiContainerStyled } from './styles'

type Emoji = {
  url: string
  name: string
  value?: number
}

type EmojiStackProps = {
  emojis: Emoji[]
  maxEmoji?: number
  boxShadowColor?: string
  noEmojisMessage: string
}

function formatTooltipContent(icons: Emoji[]) {
  return icons.map(({ name }, key) => <p key={key}>{name}</p>)
}

function formatTooltipCurrency(name: string, value?: number) {
  return typeof value === 'number' ? `${name} - ${formatCurrency(value)}` : name
}

export function EmojiStack({
  emojis,
  maxEmoji,
  boxShadowColor,
  noEmojisMessage,
}: EmojiStackProps) {
  const hasLimit = typeof maxEmoji === 'number' && maxEmoji > 0

  const displayedEmojis = hasLimit ? emojis.slice(0, maxEmoji) : emojis

  const extraEmojiTooltip = useMemo(
    () =>
      hasLimit && maxEmoji && emojis.length > maxEmoji
        ? formatTooltipContent(emojis.slice(maxEmoji))
        : null,
    [emojis, hasLimit, maxEmoji],
  )

  if (!emojis.length) {
    return (
      <NoEmojiContainerStyled>
        <Tag variant="gray" as="span" hasLeftDotIcon>
          {noEmojisMessage}
        </Tag>
      </NoEmojiContainerStyled>
    )
  }

  return (
    <EmojiStackContainerStyled boxShadowColor={boxShadowColor}>
      {displayedEmojis.map(({ name, url, value }, key) => (
        <Tooltip key={key} title={formatTooltipCurrency(name, value)}>
          <div className="icon">
            <img src={url} alt={name} />
          </div>
        </Tooltip>
      ))}

      {extraEmojiTooltip && maxEmoji && (
        <Tooltip title={extraEmojiTooltip}>
          <div className="icon">
            <Typography variant="caption" color="neutral.10" weight={600}>
              +{emojis.length - maxEmoji}
            </Typography>
          </div>
        </Tooltip>
      )}
    </EmojiStackContainerStyled>
  )
}
