import React from "react";

type LazyComponentProps = {
  factory: () => Promise<{ default: React.ComponentType }>;
  fallback?: React.ReactNode;
};

export const LazyComponent: React.FC<LazyComponentProps> = ({ factory, fallback = <div /> }) => {
  const LazyLoadedComponent = React.lazy(factory);

  return (
    <React.Suspense fallback={fallback}>
      <LazyLoadedComponent />
    </React.Suspense>
  );
};

export default LazyComponent;
