import styled from "styled-components";

export const ListItemTrailing = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacings.xs4};
`;

export const AccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacings.xs2};
  align-items: center;
`;

export const Container = styled.div`
  padding-left: 83px;
  padding-right: 83px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacings.xs2};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacings.s};
`;

export const ReceiptContent = styled.div`
  width: 100%;
`;

export const FormContent = styled.div`
  width: 100%;
`;

export const Card = styled.div`
  margin-top: 24px;
  border-radius: 12px;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
`;

export const CardTile = styled.div<{ bottomBorder?: boolean }>`
  border-bottom-width: ${({ bottomBorder }) => (bottomBorder ? "1px" : undefined)};
  border-bottom-style: solid;
  border-bottom-color: ${(props) => props.theme.colors.neutral[90]};
  padding-top: 20px;
  padding-bottom: ${(props) => props.theme.spacings.xs2};
  padding-left: 20px;
  padding-right: 20px;
`;
export const Form = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 20px 16px 20px 16px;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacings.xs2};
`;

export const InfoCardCaption = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacings.xs4};
  align-items: center;
`;
