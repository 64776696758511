import { Draggable, DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd"
import * as SC from "./styles"

export interface CardProps {
  dataOnboarding?: string
  id: string
  index: number
  onClick?: () => void
  children: React.ReactNode
}

export const Card = ({ children, dataOnboarding, id, index, onClick }: CardProps) => {
  return (
    <Draggable draggableId={id} index={index} isDragDisabled={false}>
      {(dragProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <SC.CardWrapper
          ref={dragProvided.innerRef}
          isDragging={snapshot.isDragging}
          onClick={onClick}
          data-onboarding={dataOnboarding}
          {...dragProvided.draggableProps}
          {...dragProvided.dragHandleProps}
        >
          {children}
        </SC.CardWrapper>
      )}
    </Draggable>
  )
}
