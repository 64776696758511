import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-self: center;
  width: auto;
  gap: ${({ theme }) => theme.spacings.xs3};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  border: ${({ theme }) => theme.borders.width.xs2} solid
    ${({ theme }) => theme.colors.neutral[90]};
  padding: ${({ theme }) => theme.spacings.xs5};
  position: absolute;
  top: 270px;
  z-index: 1;
  background-color: ${({ theme }) => `${theme.colors.neutral[100]}`};
`;

export const TabItemContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.borders.radius.m};
  border: ${({ theme, isSelected }) =>
    isSelected
      ? `${theme.borders.width.xs2} ${theme.colors.secondary[70]} solid`
      : 'none'};
  background-color: ${({ theme, isSelected }) =>
    isSelected
      ? `${theme.colors.secondary[95]}`
      : `${theme.colors.neutral[100]}`};
  padding: ${({ theme }) => theme.spacings.xs4}
    ${({ theme }) => theme.spacings.xs3};
  gap: ${({ theme }) => theme.spacings.xs4};
  transition: background-color 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
  }
`;
