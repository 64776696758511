import BasePath from '@frontend/routes/BasePath';
import RouterService from '@frontend/services/RouterService';

import LazyComponent from '@frontend/components/LazyComponent';
import { Route } from 'react-router-dom';

export const ROUTE_FlashCardStatement = RouterService.create(
  `${BasePath.relativePath}/statement/flashCard`,
);

const FlashCardStatement = () => (
  <LazyComponent factory={() => import('./FlashCardStatement')} />
);

export const RouteComponent_FlashCardStatement_Index = (
  <Route index element={<FlashCardStatement />} />
);

export const RouteComponent_FlashCardStatement = (
  <Route
    path={ROUTE_FlashCardStatement.path}
    element={<FlashCardStatement />}
  />
);
