import React, { createContext, useContext, useState } from "react";

type AdminPagesContextState = {
  expandedRows: Record<string, boolean>;
  setExpandedRows: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
};

const AdminPagesContext = createContext<AdminPagesContextState | undefined>(undefined);

export type AdminPagesProviderProps = {
  children: React.ReactNode;
};

export const AdminPagesProvider = ({ children }: AdminPagesProviderProps) => {
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

  return <AdminPagesContext.Provider value={{ expandedRows, setExpandedRows }}>{children}</AdminPagesContext.Provider>;
};

export const useAdminPagesContext = () => {
  const context = useContext(AdminPagesContext);
  if (!context) {
    throw new Error("useAdminPagesContext must be used within an AdminPagesProvider");
  }
  return context;
};
