import { trpc } from '@/api/client';
import dispatchToast from '@utils/dispatchToast';

type UseChangeEmployeeCompanyProps = {
  options?: {
    onSuccess?: () => void;
    onError?: () => void;
  };
};

export const useChangeEmployeeCompany = ({
  options,
}: UseChangeEmployeeCompanyProps) => {
  const { mutate, isLoading } = trpc.changeEmployeeCompany.useMutation({
    onSuccess: () => {
      if (options?.onSuccess) {
        options.onSuccess();
      }
    },
    onError: (error) => {
      if (options?.onError) {
        options.onError();
        return;
      }
      if (error.data?.code === 'FORBIDDEN') {
        dispatchToast({
          type: 'error',
          title: 'Erro ao transferir pessoa',
          content:
            'Você não possui permissões para movimentar essa pessoa para o cnpj desejado',
        });
        return;
      }
      dispatchToast({
        type: 'error',
        content:
          'Não foi possível transferir a pessoa para esta empresa. Por favor, tente novamente.',
      });
    },
  });

  const changeEmployeeCompany = async (
    employeeId: string,
    companyId: string,
  ) => {
    return mutate({
      companyId,
      employeeId,
    });
  };

  return {
    changeEmployeeCompany,
    isLoading,
  };
};
