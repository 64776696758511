import { StyledText, StyledTitle } from '@/common/styles/general-styles';
import { PillButton, Radio, Tag } from '@flash-tecnologia/hros-web-ui-v2';
import { useForm } from '../../../../context/FormContext';
import {
  OptionContainer,
  RadioCard,
  RadioCardLeft,
  RadioCardRight,
  RadioOptionText,
  RadioOptionTitle,
} from './styled';

export const CreateOptions = () => {
  const { canMigrate, willMigrate, setWillMigrate, setAllFieldsDisabled } =
    useForm();
  return (
    <OptionContainer>
      <RadioCard>
        <RadioCardLeft>
          <Radio
            size="small"
            name="willMigrate"
            disabled={!canMigrate}
            checked={willMigrate}
            value={willMigrate}
            onChange={() => {
              setWillMigrate(true);
              setAllFieldsDisabled(true);
            }}
            error={false}
          />
          <RadioOptionText>
            <StyledTitle setColor="neutral30" variant="body3">
              Transferir a pessoa para a nova empresa
            </StyledTitle>
            <StyledText setColor="neutral50" variant="body4">
              Essas pessoas deixarão de estar vinculadas às empresas atuais e
              passarão a estar vinculadas apenas à nova empresa.
            </StyledText>
          </RadioOptionText>
        </RadioCardLeft>
        <RadioCardRight>
          <PillButton
            variant="pink"
            size="medium"
            icon="IconSwitch3"
            type="primary"
          />
        </RadioCardRight>
      </RadioCard>
      <RadioCard>
        <RadioCardLeft>
          <Radio
            size="small"
            name="willMigrate"
            checked={!willMigrate}
            value={!willMigrate}
            onChange={() => {
              setWillMigrate(false);
              setAllFieldsDisabled(false);
            }}
            error={false}
          />
          <RadioOptionText>
            <RadioOptionTitle>
              <StyledTitle setColor="neutral30" variant="body3">
                Duplicar o cadastro desta pessoa
              </StyledTitle>{' '}
              <Tag as="span" size="small" variant="yellow">
                Não recomendado
              </Tag>
            </RadioOptionTitle>
            <StyledText setColor="neutral50" variant="body4">
              A pessoa ficará vinculada a mais uma empresa, além das quais já
              está vinculada. Esta ação não é recomendada, pois gera cadastros
              duplicados, o que pode resultar na emissão de mais um cartão Flash
              e outros eventos relacionados à duplicidade de cadastro.
            </StyledText>
          </RadioOptionText>
        </RadioCardLeft>
        <RadioCardRight>
          <PillButton
            variant="pink"
            size="medium"
            icon="IconUsers"
            type="primary"
          />
        </RadioCardRight>
      </RadioCard>
    </OptionContainer>
  );
};
