import { PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import Icon from '../Icon';
import LinkButton from '../LinkButton';
import { SurveyStoreProps } from '../SurveyStore';
import Typography from '../Typography';
import { useVersion } from './context';
import { Feature } from './context/types';
import { VERSIONS_CONTENT } from './context/versions.contant';
import * as SC from './styled';

type Props = {
  feature: Feature;
  survey?: SurveyStoreProps;
  onClickChangeVersion?(): void;
};

export default function VersionCalloutOld(props: Props) {
  const context = useVersion(props.feature);
  const featureContent = VERSIONS_CONTENT[props.feature];
  const label =
    featureContent.type === 'feature' ? 'funcionalidade' : 'experiência';

  if (!context.showCallout) {
    return null;
  }

  function onClickToChangeVersion() {
    context.onChangeVersion();
    props.onClickChangeVersion?.();
  }

  return (
    <SC.Container>
      <Icon
        name="IconSparkles"
        size={24}
        color="neutral_40"
        background="neutral"
      />

      <Typography.Body4 weight={600} color="secondary_10">
        Você está acessando a nova {label} de{' '}
        {featureContent.lastFeatureTitle ?? featureContent.featureName}.
      </Typography.Body4>

      <LinkButton
        onClick={() => context.onShowSurveyModal(props.survey)}
        variant="default"
        size="md"
        icon="IconMessageChatbot"
        underlined={false}
      >
        Deixe sua opinião
      </LinkButton>

      <SC.ActionContainer>
        <PillButton
          icon="IconArrowBackUp"
          size="small"
          label="Volte para a versão anterior"
          type="secondary"
          variant="default"
          iconPosition="left"
          onClick={onClickToChangeVersion}
        />
      </SC.ActionContainer>
    </SC.Container>
  );
}
