import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledTitle = styled(Typography)`
  font-size: 14px !important;
  line-height: 16px !important;
  color: var(--color-neutral-30);
  font-weight: 700 !important;
`;

export const StyledInboxGroupContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 12px;
`;

export const StyledTitleContent = styled.div`
  display: flex;
  flex-direction: row;
`;
export const StyledAckAll = styled(Typography)`
  font-size: 12px !important;
  line-height: 18px !important;
  color: var(--color-neutral-50);
  font-weight: 700 !important;
`;

export const StyledAckAllContent = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
`;
