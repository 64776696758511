import { Checkbox, tableControllers } from '@flash-tecnologia/hros-web-ui-v2'
import { useMemo } from 'react'
import { EmojiStack } from 'src/components/emoji-stack'
import { TagStack } from 'src/components/tag-stack'
import { usePagination } from 'src/utils/hooks'

import { ActionAssginemntEditCell } from '../components/action-assignment-edit-cell'
import { EmployeeNameCell } from '../components/employee-name-cell'
import { Employee } from '../types'

type UseTableConfigProps = {
  employees: Employee[]
  areAllSelected: boolean
  isHeaderIndeterminate: boolean
  selectedEmployeeIds: string[]
  toggleSelectAll: () => void
  isRowSelected: (id: string) => boolean
  toggleRowSelection: (id: string) => void
}

type UseTableConfigResponse = {
  getHeaderGroups: ReturnType<
    typeof tableControllers.useTableColumns<Employee>
  >['getHeaderGroups']
  rows: ReturnType<typeof tableControllers.useTableColumns<Employee>>['rows']
  pagination: ReturnType<typeof usePagination>['pagination']
  setPagination: ReturnType<typeof usePagination>['setPagination']
  total: number
}

export function useTableConfig({
  employees,
  areAllSelected,
  isHeaderIndeterminate,
  toggleSelectAll,
  selectedEmployeeIds,
  toggleRowSelection,
}: UseTableConfigProps): UseTableConfigResponse {
  const { pagination, setPagination, getPaginatedData } = usePagination()

  const memoizedTotal = useMemo(() => employees.length, [employees.length])
  const data = useMemo(
    () => getPaginatedData(employees),
    [employees, getPaginatedData],
  )

  const { getHeaderGroups, rows } = tableControllers.useTableColumns<Employee>({
    columns: [
      {
        id: 'checkbox',
        size: 40,
        header: () => (
          <Checkbox
            checked={areAllSelected}
            indeterminate={isHeaderIndeterminate}
            onChange={toggleSelectAll}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={selectedEmployeeIds.includes(row.original.id)}
            onChange={() => toggleRowSelection(row.original.id)}
          />
        ),
      },
      {
        header: 'Pessoa',
        accessorKey: 'name',
        size: 240,
        cell: ({ row }) => <EmployeeNameCell employee={row.original} />,
      },
      {
        header: 'Benefícios',
        accessorKey: 'benefits',
        size: 236,
        enableResizing: false,
        cell: ({ row }) => (
          <EmojiStack
            emojis={row.original.benefits}
            noEmojisMessage="Sem benefícios"
          />
        ),
      },
      {
        header: 'Grupos',
        accessorKey: 'groups',
        size: 322,
        enableResizing: false,
        cell: ({ row }) => (
          <TagStack
            names={row.original.groups}
            noNameMessage="Sem grupos"
            maxName={3}
          />
        ),
      },
      {
        id: 'action',
        header: 'Ações',
        size: 64,
        enableResizing: false,
        sticky: 'right',
        cell: ({ row }) => <ActionAssginemntEditCell employee={row.original} />,
      },
    ],
    data,
    total: memoizedTotal,
    pagination,
    onPaginationChange: setPagination,
  })

  return {
    getHeaderGroups,
    rows,
    total: memoizedTotal,
    pagination,
    setPagination,
  }
}
