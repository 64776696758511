import styled from "styled-components";

export const ModalStyled = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: ${({ theme }) => theme.spacings.xs};

  .header-modal {
    padding-left: ${({ theme }) => theme.spacings.m};
    padding-right: ${({ theme }) => theme.spacings.m};
    margin-top: -${({ theme }) => theme.spacings.m};
  }

  .content-modal {
    padding-left: ${({ theme }) => theme.spacings.m};
    padding-right: ${({ theme }) => theme.spacings.m};
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: ${({ theme }) => theme.spacings.m};
  padding-right: ${({ theme }) => theme.spacings.m};
  padding-top: ${({ theme }) => theme.spacings.xs2};
  border-top: 1px solid ${(props) => props.theme.colors.neutral[90]};
`;
