import {
  Skeleton,
  PDFViewer,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { PdfViewerContainer } from "./styled";
import { BaseModal } from "../BaseModal";

type ContractPdfModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  pdfUrl: string;
};

const ContractPdfModal = ({
  isOpen,
  isLoading,
  onClose,
  pdfUrl,
}: ContractPdfModalProps) => {
  return (
    <BaseModal open={isOpen} onClose={onClose}>
      <>
        <Typography.Headline7 color="neutral.20" style={{ marginBottom: 12 }}>
          Contrato
        </Typography.Headline7>
        <PdfViewerContainer>
          {isLoading && (
            <Skeleton variant="rectangular" width="100%" height="650px" />
          )}
          {!isLoading && (
            <PDFViewer
              style={{ borderRadius: 8.5 }}
              width="100%"
              height="650px"
              src={pdfUrl}
              options={{
                showToolbar: true,
                fit: "height",
                page: 1,
              }}
            />
          )}
        </PdfViewerContainer>
      </>
    </BaseModal>
  );
};

export { ContractPdfModal };
