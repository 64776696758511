import { trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

type Input = {
  orderId: string;
  employeeIds: Set<string>;
  deposit: {
    amount: number;
    automatic: boolean;
    complementary: boolean;
    confirmationToken?: string;
    creditDate: Date;
    expirationDate?: Date;
    expires: boolean;
    paymentMethod: 'FLASH_CASH' | 'PIX' | 'BILLET';
  };
  onSuccess: () => void;
  onWarning: () => void;
  onAuthError: () => void;
};

export function usePayBalanceRequest() {
  const context = trpc.useContext();
  const approval = trpc.corporateCard.requestBalance.approval.useMutation();
  function formatOutput() {
    if (approval.data) {
      return {
        deposit: {
          status: 'SUCCESS',
        },
      };
    }
    return {
      deposit: {
        status: 'FAILED',
      },
    };
  }

  return {
    mutateAsync: (input: Input) => {
      return approval.mutateAsync(
        {
          id: input.orderId,
          data: {
            creditDate: input.deposit.creditDate,
            employees: Array.from(input.employeeIds).map((employeeId) => {
              return {
                id: employeeId,
                value: input.deposit.amount,
                ...automaticMap({
                  depositType: input.deposit.automatic ? 'AUTOMATIC' : 'SINGLE',
                  expires: input.deposit.expires,
                  expirationDate: input.deposit.expirationDate,
                  complementary: input.deposit.complementary,
                }),
              };
            }),
            paymentMethod: paymentMethodMap(input.deposit.paymentMethod),
          },
        },
        {
          onSuccess: () => {
            input.onSuccess();
            void context.corporateCard.requestBalance.search.invalidate();
          },
          onError: (error) => {
            dispatchToast({
              type: 'error',
              content: error.message,
            });
          },
        },
      );
    },
    data: formatOutput(),
  };
}

function automaticMap(input: {
  depositType: 'SINGLE' | 'AUTOMATIC';
  expires?: boolean;
  expirationDate?: Date;
  complementary?: boolean;
}):
  | {
      creditType: 'normal' | 'automaticCredit' | 'automaticCreditFixedValue';
    }
  | {
      creditType: 'automaticDebit';
      debitDate: Date;
    } {
  if (input.depositType === 'SINGLE') {
    if (!input.expires || !input.expirationDate)
      return { creditType: 'normal' };
    return {
      creditType: 'automaticDebit',
      debitDate: input.expirationDate,
    } as const;
  } else {
    if (!input.complementary) return { creditType: 'automaticCredit' } as const;
    return {
      creditType: 'automaticCreditFixedValue',
    } as const;
  }
}

function paymentMethodMap(input: 'BILLET' | 'PIX' | 'FLASH_CASH') {
  switch (input) {
    case 'BILLET':
      return 'billet';
    case 'PIX':
      return 'pix';
    default:
      return 'balance';
  }
}
