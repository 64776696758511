import { Children, isValidElement, ReactElement } from "react"
import { DragDropContext, Droppable, DroppableProvided, DropResult } from "react-beautiful-dnd"
import { Column, ColumnProps, IndexedColumn } from "./Column"
import { CardsList } from "./Column/CardsList"
import { Card } from "./Column/CardsList/Card"
import { Header } from "./Column/Header"
import * as SC from "./styled"

export interface KanbanProps {
  onDragCardEnd: (result: DropResult) => void
  children: ReactElement<ColumnProps, typeof Column> | ReactElement<ColumnProps, typeof Column>[]
}

export const Root = ({ onDragCardEnd, children }: KanbanProps) => {
  const indexedColumns = Children.map(children, (child, index) => {
    if (isValidElement(child)) {
      switch (child.type) {
        case Column:
          return <IndexedColumn index={index} {...child.props} />
        default:
          return child
      }
    }
  })

  const handleDragEnd = (dropResult: DropResult) => {
    if (!dropResult.destination) return

    const sourceColumn = dropResult.source
    const destinationColumn = dropResult.destination

    if (sourceColumn.droppableId === destinationColumn.droppableId && sourceColumn.index === destinationColumn.index) {
      return
    }

    if (dropResult.type === CardsList.name) {
      onDragCardEnd(dropResult)
    }
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={Root.name} type="Column" direction="horizontal">
        {(provided: DroppableProvided) => (
          <SC.DroppableColumnsWrapper ref={provided.innerRef} {...provided.droppableProps}>
            {indexedColumns}
          </SC.DroppableColumnsWrapper>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export const Kanban = {
  Root,
  Column,
  Header,
  CardsList,
  Card,
}
