import {
  IconsProps,
  PillButton,
  Radio,
  Tag,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  StyledContainer,
  StyledIconWrapper,
  StyledRadioContainer,
  StyledRadioText,
} from "./styles";

type OptionProps = {
  text: string;
  icon: IconsProps["name"];
  enabled: boolean;
  checked: boolean;
  onCheck: (option: string) => void;
};

export const Option = ({
  text,
  icon,
  enabled,
  checked,
  onCheck,
}: OptionProps) => {
  return (
    <StyledContainer enabled={enabled} onClick={() => onCheck(text)}>
      <StyledRadioContainer>
        <Radio checked={checked} />
        <StyledRadioText variant="body4" weight={700} tag="span">
          {text}
        </StyledRadioText>
        {!enabled && (
          <Tag variant="info" size="small">
            Em breve
          </Tag>
        )}
      </StyledRadioContainer>
      <StyledIconWrapper>
        <PillButton size="medium" type="primary" variant="pink" icon={icon} />
      </StyledIconWrapper>
    </StyledContainer>
  );
};
