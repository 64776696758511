import {
  HeaderGroup,
  Row,
} from '@flash-tecnologia/hros-web-ui-v2/dist/components/Table/shared/table.types'
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDebounce } from 'react-use'
import { trpc } from 'src/api/client'
import { Pagination } from 'src/utils/hooks/usePagination'
import { useRowSelection } from 'src/utils/hooks/useRowSelection'
import { useToast } from 'src/utils/hooks/useToast'

import { useNewOrderStore } from '../../store/use-new-order-store'
import { buildDeposits } from '../payment-setup/utils/build-deposits'
import {
  DEBOUNCE_SEARCH_PAGINATION_DELAY,
  DEBOUNCE_SELECTED_DELAY,
} from './config/constants'
import { useTableConfig } from './hooks/use-table-config'
import { Employee } from './types'
import { filterEmployeesByCriteria } from './utils/filter-employees-by-criteria'
import { searchEmployee } from './utils/search-employee'

type EmployeeSelectionContextType = {
  employees: Row<Employee>[]
  employeeTotal: number
  employeeSelectedTotal: number
  pagination: Pagination
  isLoading: boolean
  tableHeaderGroups: () => HeaderGroup<Employee>[]
  handleSearch: (value: string) => void
  setPagination: (pagination: Pagination) => void
  handleEditGroup: () => void
  handleContinue: () => void
  selectedAllEmployees: () => void
  clearSelectedRows: () => void
  toggleEmployeeSelection: (employeeId: string) => void
  areAllSelected: boolean
  noEmployeesData: boolean
  selectedEmployees: Employee[]
  activeFiltersCount: number
}

type EmployeeSelectionProps = {
  children: ReactNode
}

const MINIMUM_DEPOSIT_AMOUNT = 200

export const EmployeeSelectionContext = createContext(
  {} as EmployeeSelectionContextType,
)

export function EmployeeSelectionProvider({
  children,
}: EmployeeSelectionProps) {
  const [searchTerm, setSearchTerm] = useState('')
  const [noEmployeesData, setNoEmployeesData] = useState(true)

  const { toastWarning, toastError } = useToast()

  const {
    employees,
    employeesFilter,
    selectedEmployees,
    setEmployees,
    isEmployeesInitialized,
    markEmployeesAsInitialized,
    setSelectedEmployees,
    creditDays,
    nextStep,
    setContinueButtonOptions,
    setFilterEmployees,
  } = useNewOrderStore()

  const { data: employeesData = [], isLoading } =
    trpc.simpleAssignment.getEmployees.useQuery()

  const filteredEmployees = useMemo(() => {
    const employeesMatchingSearch = searchTerm
      ? searchEmployee(employees, searchTerm)
      : employees

    return filterEmployeesByCriteria(employeesMatchingSearch, employeesFilter)
  }, [employees, employeesFilter, searchTerm])

  const activeFiltersCount = useMemo(
    () =>
      Object.values(employeesFilter).filter(
        (filterValues) => filterValues.length > 0,
      ).length,
    [employeesFilter],
  )

  const {
    areAllSelected,
    isHeaderIndeterminate,
    selectedRows: selectedEmployeeIds,
    selectedData,
    isRowSelected,
    toggleSelectAll,
    toggleRowSelection,
    clearSelectedRows,
  } = useRowSelection({
    filteredRows: filteredEmployees,
    allData: employees,
    initialSelectedRows: selectedEmployees.map((employee) => employee.id),
  })

  const table = useTableConfig({
    employees: filteredEmployees,
    areAllSelected,
    isHeaderIndeterminate,
    selectedEmployeeIds,
    toggleSelectAll,
    isRowSelected,
    toggleRowSelection,
  })

  useDebounce(
    () => {
      setSelectedEmployees(selectedData)
    },
    DEBOUNCE_SELECTED_DELAY,
    [selectedData],
  )

  useDebounce(
    () => {
      if (searchTerm) {
        table.setPagination((prev) => ({ ...prev, pageNumber: 1 }))
      }
    },
    DEBOUNCE_SEARCH_PAGINATION_DELAY,
    [searchTerm],
  )

  const employeeSelectedTotal = useMemo(() => {
    if (activeFiltersCount > 0 || !!searchTerm) {
      return filteredEmployees.filter(
        (employee) =>
          selectedEmployeeIds.includes(employee.id) ||
          selectedEmployeeIds.includes(employee.name),
      ).length
    }

    return selectedEmployeeIds.length
  }, [activeFiltersCount, filteredEmployees, searchTerm, selectedEmployeeIds])

  const handleEditGroup = useCallback(() => {
    window.open('/settings/groups', '_blank')
  }, [])

  const handleContinue = useCallback(() => {
    if (!selectedEmployeeIds.length) {
      toastWarning({
        title:
          'É necessário selecionar pessoas para prosseguir com seu pedido.',
      })

      return
    }

    const hasLowDepositAmount = buildDeposits(
      selectedEmployees,
      creditDays,
    ).some(
      (deposit) =>
        deposit.amount > 0 && deposit.amount < MINIMUM_DEPOSIT_AMOUNT,
    )

    if (hasLowDepositAmount) {
      toastError({
        title: 'Não é possível realizar depósitos com valor abaixo de R$ 2,00',
        description:
          'Uma ou mais pessoas estão com valores de benefícios abaixo de R$ 2,00 atribuídos a elas. Por favor, remova as pessoas do pedido ou altere o valor de benefício delas.',
      })

      return
    }

    setFilterEmployees({ groups: [] })

    nextStep()
  }, [
    creditDays,
    nextStep,
    selectedEmployeeIds.length,
    selectedEmployees,
    setFilterEmployees,
    toastError,
    toastWarning,
  ])

  useEffect(() => {
    if (employeesData.length && !isEmployeesInitialized) {
      setEmployees(employeesData)
      setNoEmployeesData(false)
      markEmployeesAsInitialized()
    }
  }, [
    employeesData,
    isEmployeesInitialized,
    markEmployeesAsInitialized,
    setEmployees,
  ])

  useEffect(() => {
    setContinueButtonOptions({
      text: 'Continuar',
      onClick: handleContinue,
    })
  }, [handleContinue, setContinueButtonOptions])

  return (
    <EmployeeSelectionContext.Provider
      value={{
        employees: table.rows,
        isLoading,
        employeeTotal: table.total,
        employeeSelectedTotal,
        tableHeaderGroups: table.getHeaderGroups,
        handleSearch: setSearchTerm,
        pagination: table.pagination,
        setPagination: table.setPagination,
        handleContinue,
        handleEditGroup,
        clearSelectedRows,
        selectedAllEmployees: toggleSelectAll,
        areAllSelected,
        selectedEmployees,
        toggleEmployeeSelection: toggleRowSelection,
        activeFiltersCount,
        noEmployeesData,
      }}
    >
      {children}
    </EmployeeSelectionContext.Provider>
  )
}
