import React, {
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { EnumGlobalActions, globalContext, IActions } from "../../../context"
import { reorderMap } from "./reorder"
import { Col } from "./column"
import { ContainerInline, Container } from "./styles"
import { Card, FlowCategory, FlowWithCards } from "../../../types"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import dispatchToast from "../../../utils/dispatchToast"
import { request, trpc } from "../../../api/client"
import { HIRING_CHANGE_POSITION } from "../../../api/mutations/hiring-card-move-position"
import { RESIGNATION_CHANGE_POSITION } from "../../../api/mutations/resignation-card-move-position"
import {
  ChangeCardColumn,
  HIRING_MOVE_COLUMN,
} from "../../../api/mutations/hiring-card-move-column"
import { RESIGNATION_MOVE_COLUMN } from "../../../api/mutations/resignation-card-move-column"
import { modalAction } from "../../../utils"
import {
  ModalDoneAdmission,
  AdmissionWarningModal,
} from "../../common/ModalDoneAdmission"
import { DocumentNode } from "graphql"
import { getQueryKey } from "@trpc/react-query"
import { useTracking } from "@utils/useTracking"

const initialConfigColumn = "Configurações iniciais"
const hiringDoneColumns = ["Admissão concluída", "Concluído"]

type QueryType = {
  [key in FlowCategory]: DocumentNode
}

export interface BoardProps<CardType> {
  flow: FlowWithCards<CardType>
}

const queryType: QueryType = {
  hiring: HIRING_MOVE_COLUMN,
  resignation: RESIGNATION_MOVE_COLUMN,
}

export const Board = <CardType extends Card>({
  flow,
}: BoardProps<CardType>) => {
  const [modal, setModal] = useState<"warning" | "done" | null>(null)
  const [cbFunction, setCbFunction] = useState(() => () => {})
  const [cardForModal, setCardForModal] =
    useState<React.ComponentProps<typeof ModalDoneAdmission>["card"]>()
  const [payload, setPayload] = useState<{
    flowCardId: string
    newColumnId: string
    newPosition: number
    version: number
    orderedArray: {
      cards: CardType[]
    }[]
    currentColumnName: string
    newColumnName: string
  }>({
    flowCardId: "",
    newColumnId: "",
    newPosition: 0,
    version: 0,
    orderedArray: [],
    currentColumnName: "",
    newColumnName: "",
  })
  const { dispatch } = useContext(globalContext)

  const queryClient = useQueryClient()
  const tracking = useTracking()

  const getFlowByIdQueryKey = getQueryKey(
    trpc.flow.getFlowById,
    { flowId: flow._id },
    "query",
  )

  const MutateCardToOtherColumn = useMutation(
    ({
      flowCardId,
      version,
      newColumnId,
      newPosition,
    }: ChangeCardColumn & {
      orderedArray: any
      currentColumnName: string
      newColumnName: string
    }) => {
      return request(queryType[flow.category], {
        params: {
          flowCardId,
          version,
          newColumnId,
          newPosition,
        },
      })
    },
    {
      onMutate: async ({ orderedArray, ...data }) => {
        await queryClient.cancelQueries(getFlowByIdQueryKey)
        const previousHiringFlow = queryClient.getQueryData(getFlowByIdQueryKey)
        queryClient.setQueryData<FlowWithCards<CardType>>(
          getFlowByIdQueryKey,
          (oldQueryData) => {
            return {
              ...oldQueryData,
              columns: orderedArray,
            } as FlowWithCards<CardType>
          },
        )
        tracking.trackEvent({
          name:
            flow.category === FlowCategory.HIRING
              ? "hiring_dragged_card_to_new_column"
              : "resignation_dragged_card_to_new_column",
          params: {
            from: data.currentColumnName,
            to: data.newColumnName,
          },
        })
        return { previousHiringFlow }
      },
      onError: (_error, _var, context) => {
        queryClient.setQueryData(
          getFlowByIdQueryKey,
          context?.previousHiringFlow,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(getFlowByIdQueryKey)
      },
    },
  )

  const MutateCardToOtherPosition = useMutation(
    ({
      flowCardId,
      version,
      newPosition,
    }: {
      flowCardId: string
      newPosition: number
      version: number
      orderedArray: {
        cards: CardType[]
      }[]
    }) => {
      const queryType = {
        hiring: HIRING_CHANGE_POSITION,
        resignation: RESIGNATION_CHANGE_POSITION,
      }

      return request(queryType[flow.category], {
        params: {
          flowCardId,
          version,
          newPosition,
        },
      })
    },
    {
      onMutate: async ({ orderedArray }) => {
        await queryClient.cancelQueries(getFlowByIdQueryKey)
        const previousHiringFlow = queryClient.getQueryData(getFlowByIdQueryKey)
        queryClient.setQueryData<FlowWithCards<CardType>>(
          getFlowByIdQueryKey,
          (old) => {
            return { ...old, columns: orderedArray } as FlowWithCards<CardType>
          },
        )

        return { previousHiringFlow }
      },
      onError: (_error, _var, context) => {
        queryClient.setQueryData(
          getFlowByIdQueryKey,
          context?.previousHiringFlow,
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(getFlowByIdQueryKey)
      },
    },
  )

  const onDragEnd = useCallback(
    async (result: { [key: string]: any }) => {
      if (!result.destination) return

      const source = result.source
      const destination = result.destination

      const currentColumns = flow.columns?.find(
        (f: any) => f._id === source.droppableId,
      )

      const { version = 0, _id = "" } =
        currentColumns?.cards?.[source.index] || {}

      if (
        source.droppableId === destination.droppableId &&
        source.index === destination.index
      ) {
        return
      }

      if (result.type === "Card") {
        const orderedArray = reorderMap({
          item: flow.columns,
          source: source,
          destination: destination,
        })

        const currentItem = flow.columns?.find(
          (f: any) => f._id === source.droppableId,
        )

        const destinationItem = flow.columns?.find(
          (f: any) => f._id === destination.droppableId,
        )

        if (!destinationItem || !currentItem) return

        if (flow.category === "hiring") {
          if (
            destinationItem.name === initialConfigColumn &&
            !hiringDoneColumns.includes(currentItem.name)
          ) {
            setModal("warning")
            return
          }
          const destinationIsHiringDone = hiringDoneColumns.includes(
            destinationItem.name,
          )
          if (
            destinationIsHiringDone &&
            currentItem.name !== initialConfigColumn
          ) {
            const cardInfo = currentItem.cards.find(
              (f: any) => f._id === result.draggableId,
            ) as any
            const isMissingData =
              !cardInfo?.employeeId ||
              !cardInfo?.hiringDate ||
              !cardInfo?.documentNumber ||
              !cardInfo?.corporateEmail

            if (isMissingData) {
              setCardForModal({
                candidateId: cardInfo.candidateId,
                candidate: {
                  name: cardInfo?.name,
                  documentNumber: cardInfo?.documentNumber,
                  email: cardInfo?.email,
                  corporateEmail: cardInfo?.corporateEmail,
                  proposalLetterInfo: {
                    hiringDate: cardInfo?.hiringDate,
                  },
                  probationPeriod: cardInfo?.probationPeriod,
                },
              })
              setPayload({
                flowCardId: _id,
                newColumnId: destination.droppableId,
                newPosition: destination.index,
                version: version,
                orderedArray: orderedArray,
                currentColumnName: currentItem.name,
                newColumnName: destination.name,
              })
              setModal("done")
              return
            }
          }
        }

        if (source.droppableId === destination.droppableId) {
          await MutateCardToOtherPosition.mutateAsync(
            {
              flowCardId: _id,
              newPosition: destination.index,
              version: version,
              orderedArray: orderedArray,
            },
            {
              onError: () =>
                dispatchToast({
                  content: "Ocorreu um erro de conexão ao atualizar o card!",
                  type: "error",
                }),
            },
          )
        } else {
          if (destinationItem?.name === "Carta proposta") {
            modalAction({
              param: {
                name: "proposedLetter",
                open: true,
                cardId: _id,
              },
              dispatch: dispatch as Dispatch<IActions<EnumGlobalActions>>,
            })
          }

          await MutateCardToOtherColumn.mutateAsync(
            {
              flowCardId: _id,
              newColumnId: destination.droppableId,
              newPosition: destination.index,
              version: version,
              orderedArray: orderedArray,
              currentColumnName: currentItem.name,
              newColumnName: destinationItem.name,
            },
            {
              onError: () =>
                dispatchToast({
                  content: "Ocorreu um erro de conexão ao atualizar o card!",
                  type: "error",
                }),
            },
          )
        }
      }
    },
    [flow],
  )

  useEffect(() => {
    setCbFunction(() => async () => {
      await MutateCardToOtherColumn.mutateAsync(payload, {
        onError: () =>
          dispatchToast({
            content: "Ocorreu um erro de conexão ao atualizar o card!",
            type: "error",
          }),
      })
      setModal(null)
    })
  }, [payload])

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Container>
          <div
            style={{
              position: "absolute",
              height: "100%",
            }}
          >
            <Droppable droppableId="board" type="Column" direction="horizontal">
              {(provided: { [key: string]: any }) => (
                <ContainerInline
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {flow.columns?.map((data, columnIndex) => (
                    <Col<CardType>
                      key={data._id}
                      category={flow.category}
                      data={data}
                      columnIndex={columnIndex}
                      company={{
                        companyId: flow.companyId,
                        setupId: flow.setupId,
                        flowId: flow._id,
                      }}
                    />
                  ))}
                  {provided.placeholder}
                </ContainerInline>
              )}
            </Droppable>
          </div>
        </Container>
      </DragDropContext>
      <AdmissionWarningModal
        isOpen={modal === "warning"}
        handleClose={() => setModal(null)}
      />
      {modal === "done" && cardForModal && (
        <ModalDoneAdmission
          isOpen={true}
          handleClose={() => setModal(null)}
          card={cardForModal}
          cbConfigure={cbFunction}
          loading={MutateCardToOtherPosition.isLoading}
        />
      )}
    </>
  )
}
