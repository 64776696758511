import {
  DatePicker,
  dayjs,
  Radio,
  TimePicker,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import { Grid } from 'src/components/grid'
import { useTheme } from 'styled-components'

import { useAddEmployees } from '../../hooks/use-add-employees'

export function ScheduleNotification() {
  const theme = useTheme()
  const {
    handleDateChange,
    handleNotificationSend,
    pickedDate,
    scheduleNotification,
  } = useAddEmployees()

  return (
    <Grid.CardGrid flexDirection="column" gap={theme.spacings.xs}>
      <Grid.Container gap={theme.spacings.xs5}>
        <Typography
          variant="headline8"
          weight={700}
          variantColor={theme.colors.neutral30}
        >
          Envio do convite de primeiro acesso
        </Typography>
        <Typography variant="body3" weight={400} color="neutral.40">
          Realize o envio do convite logo após a finalização do cadastro ou
          defina uma data e horário específicos. Através desse convite de
          primeiro acesso, a pessoa irá criar sua conta para utilizar o app e
          plataforma da Flash.
        </Typography>
      </Grid.Container>

      <Grid.Container
        flexDirection="row"
        alignItems="center"
        gap={theme.spacings.m}
      >
        <Grid.Container
          width="fit-content"
          minWidth="auto"
          flexDirection="row"
          alignItems="center"
        >
          <Radio
            checked={!scheduleNotification}
            onChange={handleNotificationSend}
          />
          <Typography
            variant="body3"
            weight={700}
            variantColor={theme.colors.neutral30}
          >
            Enviar imediatamente
          </Typography>
        </Grid.Container>
        <Grid.Container
          width="fit-content"
          minWidth="auto"
          flexDirection="row"
          alignItems="center"
        >
          <Radio
            checked={scheduleNotification}
            onChange={handleNotificationSend}
          />
          <Typography
            variant="body3"
            weight={700}
            variantColor={theme.colors.neutral30}
          >
            Agendar envio
          </Typography>
        </Grid.Container>
      </Grid.Container>

      {scheduleNotification && (
        <Grid.Container flexDirection="row" gap={theme.spacings.xs}>
          <Grid.Container
            width="fit-content"
            minWidth="auto"
            flexDirection="row"
            alignItems="center"
          >
            <DatePicker
              fromDate={dayjs().add(1, 'day').toDate()}
              label={'Data de envio'}
              value={pickedDate}
              name={'date'}
              onDateChange={(e) => e && handleDateChange(e)}
              error={!pickedDate}
            />
          </Grid.Container>

          <TimePicker
            label={'Hora de envio'}
            value={dayjs().hour(8).minute(0).second(0) as any}
            onChange={() => {}}
            disabled={true}
          />
        </Grid.Container>
      )}
    </Grid.CardGrid>
  )
}
