import { QueryClient } from "@tanstack/react-query";
import { createTRPCReact, httpBatchLink } from "@trpc/react-query";
import type { AppRouter } from "server";
import {
  getAccessToken,
  useSelectedCompany,
  getFromLS,
} from "@flash-tecnologia/hros-web-utility";

type TrpcClientCustomProps = {
  selectedCompany: ReturnType<typeof useSelectedCompany>["selectedCompany"];
};
export const trpc = createTRPCReact<AppRouter>();

const cognitoToken = getFromLS("userAuth");

export const queryClient = new QueryClient();
export const trpcClient = ({ selectedCompany }: TrpcClientCustomProps) =>
  trpc.createClient({
    links: [
      httpBatchLink({
        // url: "http://localhost:3000/trpc",
        url: `${process.env.BFF_URL}/trpc`,
        headers: async () => {
          const token = await getAccessToken();
          return {
            Authorization: token,
            Auth: cognitoToken?.tokenId,
            "X-Selected-Company": selectedCompany?.id,
          };
        },
      }),
    ],
  });
