import { QueryClient } from "@tanstack/react-query";
import { createTRPCReact, httpBatchLink } from "@trpc/react-query";
import type { AppRouter } from "server";
import { getAccessToken } from "@flash-tecnologia/hros-web-utility";

export const trpc = createTRPCReact<AppRouter>();

export const queryClient = new QueryClient();
export const trpcClient = trpc.createClient({
  links: [
    httpBatchLink({
      url: process.env.BFF_URL || "",
      headers: async () => {
        const token = await getAccessToken();
        return {
          Authorization: token,
        };
      },
    }),
  ],
});
