import {
  Import,
  ImportStatus,
  ImportType,
} from '@/pages/PageEmployeesWorksheet/types';
import {
  Card,
  IconContainer,
  StyledIcon,
  TextContainer,
  InfoContainer,
} from '../../styled';
import { StyledText, StyledTitle } from 'src/common/styles/general-styles';
import { worksheetComponentInfo } from '@/pages/PageEmployeesWorksheet/componentsText';
import { FooterFeedback } from '../Footer/footer-feedback';
import { NavigateFunction } from 'react-router-dom';

interface ISuccessFeedback {
  navigate: NavigateFunction;
  operation: ImportType;
  importProcess: Import | null;
  importStatus: ImportStatus;
  successRowsQt: number;
  newImportUrl?: string;
  redirectBackTo?: string;
}

export const SuccessFeedback = ({
  navigate,
  operation,
  importProcess,
  importStatus,
  successRowsQt,
  newImportUrl,
  redirectBackTo,
}: ISuccessFeedback) => {
  return (
    <Card>
      <TextContainer>
        <IconContainer setColor="success90">
          <StyledIcon
            name="IconCheck"
            fill="none"
            setColor="success40"
            size={32}
          />
        </IconContainer>
        <StyledTitle
          setColor="neutral30"
          variant="headline8"
          children={`${worksheetComponentInfo[operation].processmentStatusPage.mainCard.infoContainer.successTitle}`}
        />
        <StyledText
          setColor="neutral50"
          variant="body3"
          children={`${successRowsQt} ${worksheetComponentInfo[operation].processmentStatusPage.mainCard.infoContainer.successDescription}`}
        />
      </TextContainer>
      <InfoContainer>
        <StyledText
          setColor="neutral50"
          variant="body3"
          children={importProcess?.fileName}
          style={{ fontWeight: 'bolder' }}
        />

        <StyledText
          setColor="neutral50"
          variant="body3"
          children={
            importProcess!.totalRows
              ? `${successRowsQt || 0} de ${importProcess?.totalRows || 0} ${
                  worksheetComponentInfo[operation].processmentStatusPage
                    .mainCard.success.plural
                }`
              : ''
          }
        />
      </InfoContainer>
      <FooterFeedback
        navigate={navigate}
        importStatus={importStatus}
        operation={operation}
        setOpenAbortModal={() => {}}
        newImportUrl={newImportUrl}
        redirectBackTo={redirectBackTo}
      />
    </Card>
  );
};
