import BasePath from '@frontend/routes/BasePath';
import RouterService from '@frontend/services/RouterService';

import LazyComponent from '@frontend/components/LazyComponent';
import { Route } from 'react-router-dom';

export const ROUTE_ExternalCardStatement = RouterService.create(
  `${BasePath.relativePath}/statement/externalCards`,
);

const ExternalCardStatement = () => (
  <LazyComponent factory={() => import('./ExternalCardStatement')} />
);

export const RouteComponent_ExternalCardStatement = (
  <Route
    path={ROUTE_ExternalCardStatement.path}
    element={<ExternalCardStatement />}
  />
);
