import styled from 'styled-components';

export const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${({ theme }) => theme.spacings.xs};

  @media screen and (max-width: 920px) {
    grid-template-columns: 1fr;
  }
`;
