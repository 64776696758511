import { trpc } from '@frontend/trpc';
import { QUERY_RETRY_CONFIG } from '@frontend/utils/constants';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

type Input = {
  orderId?: string;
  paymentMethod: 'PIX' | 'BILLET';
};

export function useGetCode(input: Input) {
  const retryConfig = QUERY_RETRY_CONFIG(input.paymentMethod);

  const query = trpc.company.billing.paymentInfo.useQuery(input.orderId ?? '', {
    enabled: !!input.orderId,
    ...retryConfig,
    onError() {
      dispatchToast({
        content: 'Não foi possível obter o código. Tente novamente mais tarde.',
        type: 'error',
      });
    },
  });

  return {
    code: query.data?.code,
    isLoading: query.isFetching,
    isError: query.isError,
    refetch: query.refetch,
  };
}
