import {
  PillButton,
  Table as SoftTable,
  tableControllers,
} from "@flash-tecnologia/hros-web-ui-v2";

type TableProps = {
  data?: any[];
  headers?: any[];
  isLoading?: boolean;
  pagination: {
    pageNumber: number;
    pageSize: number;
  };
  onPagination: (data: TableProps["pagination"]) => void;
  total: number;
};

export const Table = ({
  data,
  headers,
  isLoading,
  pagination,
  onPagination,
  total,
}: TableProps) => {
  const pageSizeOptions = [
    {
      label: "5 itens",
      value: 5,
    },
    {
      label: "10 itens",
      value: 10,
    },
    {
      label: "25 itens",
      value: 25,
    },
  ];

  const table = tableControllers.useTableColumns<any>({
    defaultColumn: {
      minSize: 200,
    },
    total: total,
    columns:
      headers?.map((column) => ({
        header: column.displayName,
        accessorKey: column.piiReference ? column.piiReference : column.name,
        minSize: 120,
      })) || [],
    data: data || [],
    pagination: pagination,
    onPaginationChange: onPagination,
  });

  return (
    <SoftTable.Root variant="soft">
      <SoftTable.Content>
        <SoftTable.FilterSearch onSearch={() => null}>
          <PillButton
            icon="IconFilter"
            label="Filtros"
            onClick={() => null}
            variant="default"
            size="small"
            type="primary"
          />
        </SoftTable.FilterSearch>

        <SoftTable.Grid.Root loading={isLoading}>
          <SoftTable.Grid.Header
            getHeaderGroups={table.getHeaderGroups}
            toggleAllRowsExpanded={table.toggleAllRowsExpanded}
          />

          {table.rows.map((row, index) => (
            <SoftTable.Grid.Row key={index + row.id} row={row} />
          ))}
        </SoftTable.Grid.Root>

        <SoftTable.Pagination
          count={total}
          onPaginationChange={({ pageSize, pageNumber }) =>
            onPagination({ ...pagination, pageSize, pageNumber })
          }
          showItemRange
          pagination={pagination}
          pageSizeOptions={pageSizeOptions}
        />
      </SoftTable.Content>
    </SoftTable.Root>
  );
};
