import BasePath from '@frontend/routes/BasePath';
import RouterService from '@frontend/services/RouterService';

import LazyComponent from '@frontend/components/LazyComponent';
import { Route } from 'react-router-dom';

export const ROUTE_CorporateCardStatement = RouterService.create(
  `${BasePath.relativePath}/statement/corporateCard`,
);

const CorporateCardStatement = () => (
  <LazyComponent factory={() => import('./CorporateCardStatement')} />
);

export const RouteComponent_CorporateCardStatement = (
  <Route
    path={ROUTE_CorporateCardStatement.path}
    element={<CorporateCardStatement />}
  />
);
