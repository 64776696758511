import { Icons, LinkButton, Tooltip, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import { CombinedExpenseStatus, expenseStatusVariants, getExpenseStatusTranslations, VariantType } from "$organisms";
import { ExpenseType } from "$serverTypes";
import { getValueWithCurrency } from "$utils";
import { Circle, ReimbursementStatusCardContainer, ReimbursementStatusCardText } from "./styled";

/**
 * Props for the ReimbursementStatusCard component.
 */
type ReimbursementStatusCardProps = {
  /** The amount associated with the reimbursement status.*/
  amount: number;

  /** The count of items associated with the reimbursement status.*/
  count: number;

  /** The status of the reimbursement.*/
  status: CombinedExpenseStatus;

  /** Indicates whether the status is currently filtered.*/
  value: boolean;

  /** Callback when filtered state toggles.*/
  onChange: (newValue: boolean) => void;

  /** Expense type */
  type: ExpenseType;
};

export const ExpenseStatusCard = ({ status, amount, count, value, onChange, type }: ReimbursementStatusCardProps) => {
  const { colors } = useTheme();
  const { t } = useTranslation("translations", {
    keyPrefix: `organisms.expenseSummaryStatusCard.${type}`,
  });
  const expenseStatusTranslations = getExpenseStatusTranslations(t);

  function handleButtonClick() {
    onChange(!value);
  }

  function translateExpenseStatus(status: CombinedExpenseStatus): string {
    return expenseStatusTranslations[status];
  }

  function getExpenseStatusColor(status: CombinedExpenseStatus): VariantType {
    return expenseStatusVariants[status] || VariantType.GRAY;
  }

  function getTooltipText(status: CombinedExpenseStatus): string {
    const key = `tooltip.${status}`;
    return t(key);
  }

  return (
    <ReimbursementStatusCardContainer itemCount={count}>
      <ReimbursementStatusCardText>
        <Circle variant={getExpenseStatusColor(status)} size={8} />
        <Typography variant="body4" weight={600} variantColor={colors.neutral[40]}>
          {translateExpenseStatus(status)}
        </Typography>
        <Tooltip arrow title={getTooltipText(status)} placement="top-end">
          <div>
            <Icons name={"IconInfoCircle"} size={16} color={colors.neutral[40]} fill="transparent" />
          </div>
        </Tooltip>
      </ReimbursementStatusCardText>
      <ReimbursementStatusCardText>
        <Typography variant="headline8" weight={700} variantColor={colors.neutral[30]}>
          {getValueWithCurrency({
            value: amount,
          })}
        </Typography>

        {type == ExpenseType.REIMBURSEMENT && (
          <>
            <Circle variant="gray" size={4} />
            <Typography variant="caption" variantColor={colors.neutral[40]}>
              {t("expenses", { count })}
            </Typography>
          </>
        )}
      </ReimbursementStatusCardText>
      <ReimbursementStatusCardText>
        <LinkButton onClick={() => handleButtonClick()} variant="neutral" size="small">
          {value ? (
            <>
              <Icons name="IconFilterOff" size={13} color={colors.neutral[40]} />
              {t("statusCard.removeFilter")}
            </>
          ) : (
            <>
              <Icons name="IconFilter" size={13} color={colors.neutral[40]} />
              {t("statusCard.applyFilter")}
            </>
          )}
        </LinkButton>
      </ReimbursementStatusCardText>
    </ReimbursementStatusCardContainer>
  );
};
