import { IconProps } from '@components/Icon';

import { DownloadFile } from './DownloadFile';
import { MenuItem } from './MenuItem';
import * as SC from './styled';

type Props = {
  children: React.ReactNode;
  options: {
    label: string;
    icon?: IconProps['name'];
    description?: string;
    selected?: boolean;
    onClick(): void;
  }[];
};

export function Menu(props: Props) {
  return (
    <SC.Container
      type="default"
      options={props.options.map(option => ({
        children: <MenuItem {...option} />,
        onClick: option.onClick,
        selected: option.selected,
      }))}>
      {props.children}
    </SC.Container>
  );
}

Menu.DownloadFile = DownloadFile;
