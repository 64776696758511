import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import React from "react";
import { useTranslation } from "react-i18next";

import { Alignment } from "$frontend/utils/enum";
import {
  ActionButton,
  AlertIconTriangle,
  IconActionButtonContainer,
  IconClose,
  IconHeaderContainer,
  IconLabel,
  IconLabelContainer,
  ModalBodyContainer,
  ModalContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  StyledActionModal,
  Subtitle,
  Title,
} from "./styled";

export interface DangerActionModalProps {
  /**
   * if 'true' open the modal
   */
  open: boolean;

  /**
   * if 'true' the button shows loading status
   */
  isLoading?: boolean;

  /**
   * Modal content
   */
  children?: React.ReactNode;

  /**
   * Icon Label header
   *
   */
  headerIconLabel?: string;

  /**
   * Title header
   *
   */
  headerTitle?: string;

  /**
   * Subtitle header
   *
   */
  headerSubtitle?: string;

  /**
   * Text or custom item in header
   *
   */
  headerCustom?: React.ReactNode;

  /**
   * Text or custom item in footer
   *
   */
  footerCustom?: React.ReactNode;

  /**
   * Confirmation button custom text or item
   *
   */
  labelButtonAction?: React.ReactNode;

  /**
   * Icon Action Typography
   *
   */
  iconActionButton?: React.ReactNode;

  /**
   * Cancel button custom text or item
   *
   */
  labelButtonCancel?: React.ReactNode;

  /**
   * if 'true' disable button action
   */
  isActionDisabled?: boolean;

  /**
   * Header icon position
   *
   */
  iconPosition?: Alignment;

  /**
   * Show Cancel Typography
   *
   */
  showCancelButton?: boolean;

  /**
   * Custom header icon component
   */
  headerIcon?: React.ReactNode;

  /**
   * Cancel event
   *
   */
  onCancelClick?(): void;

  /**
   * Close event
   *
   */
  onCloseClick?(): void;

  /**
   * Action event
   *
   */
  onActionClick?(): void;
}

export const DangerActionModal = ({
  open,
  isLoading,
  children,
  headerIconLabel,
  headerTitle,
  headerSubtitle,
  headerCustom,
  footerCustom,
  labelButtonAction,
  iconActionButton,
  labelButtonCancel,
  isActionDisabled,
  iconPosition = Alignment.center,
  showCancelButton = true,
  onCancelClick,
  onCloseClick,
  onActionClick,
  headerIcon = <AlertIconTriangle />,
}: DangerActionModalProps) => {
  const { t } = useTranslation();

  return (
    <StyledActionModal open={open} onClose={onCloseClick}>
      <ModalContainer>
        <ModalHeaderContainer iconPosition={iconPosition}>
          {Boolean(headerCustom) ? (
            headerCustom
          ) : (
            <>
              <IconClose onClick={onCloseClick || onCancelClick} />
              <IconHeaderContainer iconPosition={iconPosition}>{headerIcon}</IconHeaderContainer>

              <IconLabelContainer>
                {Boolean(headerIconLabel) && <IconLabel>{headerIconLabel}</IconLabel>}
              </IconLabelContainer>

              {Boolean(headerTitle) && <Title>{headerTitle}</Title>}
              {Boolean(headerSubtitle) && <Subtitle>{headerSubtitle}</Subtitle>}
            </>
          )}
        </ModalHeaderContainer>
        {Boolean(children) && <ModalBodyContainer>{children}</ModalBodyContainer>}
        <ModalFooterContainer showCancelButton={showCancelButton}>
          {Boolean(footerCustom) ? (
            footerCustom
          ) : (
            <>
              {showCancelButton && (
                <div>
                  <LinkButton variant="neutral" onClick={onCancelClick || onCloseClick}>
                    {labelButtonCancel || t("molecules.defaultModal.cancel")}
                  </LinkButton>
                </div>
              )}
              <div>
                <ActionButton
                  loading={isLoading}
                  disabled={isActionDisabled}
                  onClick={onActionClick}
                  variantType="error"
                  size="large"
                  variant="primary"
                >
                  {labelButtonAction || t("molecules.defaultModal.confirm")}
                  {Boolean(iconActionButton) && (
                    <IconActionButtonContainer>{iconActionButton}</IconActionButtonContainer>
                  )}
                </ActionButton>
              </div>
            </>
          )}
        </ModalFooterContainer>
      </ModalContainer>
    </StyledActionModal>
  );
};
