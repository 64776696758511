import { trpc } from '@frontend/trpc';

type Input = {
  employeeIds: Set<string>;
  deposit: {
    amount: number;
    automatic: boolean;
    complementary: boolean;
    confirmationToken?: string;
    creditDate: Date;
    expirationDate?: Date;
    expires: boolean;
    paymentMethod: 'FLASH_CASH' | 'PIX' | 'BILLET';
  } | null;
  onSuccess: () => void;
  onAuthError: () => void;
};

export function useActivateUsers() {
  const activateMany = trpc.company.employees.activateMany.useMutation();

  return {
    data: activateMany.data,
    mutateAsync: (input: Input) => {
      return activateMany.mutateAsync(
        {
          employeeIds: Array.from(input.employeeIds),
          deposit: input.deposit,
        },
        {
          onSuccess: (data) => {
            if (data.auth === 'FAILED') {
              input.onAuthError();
            } else {
              input.onSuccess();
            }
          },
        },
      );
    },
  };
}
