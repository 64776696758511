import AuthService from '@frontend/services/AuthService';
import { trpc } from '@frontend/trpc';
import { useEffect } from 'react';

export function useFirstSteps() {
  const company = AuthService.useCompany();

  const firstSteps = trpc.company.alreadyTookFirstSteps.useMutation();

  useEffect(() => {
    if (company?.id) {
      firstSteps.mutate();
    }
  }, [company?.id]);

  return {
    isLoading: firstSteps.isLoading,
    hasError: !!firstSteps.error,
    alreadyActivatedEmployee: firstSteps.data?.alreadyActivatedEmployee ?? true,
    hasEmployeesRegistered: firstSteps.data?.hasEmployeesRegistered ?? true,
  };
}
