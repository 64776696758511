import { UseGetBillsOutput } from "../../dataSource/useGetBills";
import { NoWrap } from "./styled";
import { StatusTag } from "../StatusTag";
import ActionsMenu from "../ActionsMenu";
import Table from "@components/TableV2";
import { toCurrency } from "../../../../utils/masks/toCurrency";
import { formatDate } from "../../../../utils/masks/formatDate";

interface TablePageSizeOption {
  label: string;
  value: number;
}

export const pageSizeOptions: TablePageSizeOption[] = [
  { label: "5 itens", value: 5 },
  { label: "10 itens", value: 10 },
  { label: "20 itens", value: 20 },
  { label: "50 itens", value: 50 },
];

type Row = UseGetBillsOutput["bills"][number];
type Columns = React.ComponentProps<typeof Table<Row>>["columns"];

type BusinessUnit = "BENEFITS" | "EXPENSE_MANAGEMENT" | "FLASH_HUB" | "PEOPLE";

type BusinessUnitMapper = {
  [key in BusinessUnit]: string;
};

const businessUnitMap: BusinessUnitMapper = {
  BENEFITS: "Flash Benefícios",
  EXPENSE_MANAGEMENT: "Flash Expense",
  FLASH_HUB: "Flash Parcerias",
  PEOPLE: "Flash People",
};

const paymentTypeMap: Record<string, string> = {
  BALANCE: "Flash Cash",
  BILLET: "Boleto",
  CARD: "Cartão De Crédito",
  PIX: "Pix",
};

export const billsColumns = [
  {
    header: "Produto",
    id: "businessUnits",
    accessorKey: "businessUnits",
    cell: ({ cell }) => {
      const value = cell.getValue();
      return <NoWrap>{businessUnitMap[value[0] as BusinessUnit]}</NoWrap>;
    },
  },
  {
    header: "Valor",
    id: "items-value",
    accessorKey: "items",
    cell: ({ cell }) => {
      const value = cell.getValue();
      const totalAmount = value.reduce(
        (total, items) => total + items.amount,
        0
      );
      return <NoWrap>{toCurrency(totalAmount)}</NoWrap>;
    },
  },
  {
    header: "Status",
    id: "status",
    accessorKey: "status",
    cell: ({ cell }) => <StatusTag status={cell.getValue<string>()} />,
  },
  {
    header: "Meio de Pagamento",
    id: "payments-type",
    accessorKey: "payments",
    cell: ({ cell }) => {
      const value = cell.getValue();
      const type = value[0]?.type;
      return <NoWrap>{paymentTypeMap[type] || "-"}</NoWrap>;
    },
  },
  {
    header: "Data de Vencimento",
    id: "dueDate",
    accessorKey: "dueDate",
    cell: ({ cell }) => {
      const value = cell.getValue();
      const formatedDate = value ? formatDate(new Date(value)) : "";
      return <NoWrap>{formatedDate}</NoWrap>;
    },
  },
  {
    header: "Número do Pedido",
    id: "number",
    accessorKey: "number",
  },
  {
    header: "Ações",
    isSticky: true,
    cell: ({ cell }) => {
      const { payments, invoices, debitNote } = cell.row.original;
      return (
        <ActionsMenu
          payments={payments}
          invoices={invoices}
          debitNote={debitNote}
        />
      );
    },
  },
] as Columns;
