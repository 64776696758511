import FeatureFlagService from '@frontend/services/FeatureFlagService';
import { trpc } from '@frontend/trpc';
import RequestBalanceSectionV2 from './RequestBalanceSectionV2';

function RequestBalanceApprovalTab() {
  const { data } =
    trpc.corporateCard.requestBalance.countPendingApproval.useQuery();

  return {
    component: <RequestBalanceSectionV2 type="approval" />,
    label: `Pedidos de saldo (${data?.totalPendencies || 0})`,
    shouldShow,
  };
}

function shouldShow() {
  return FeatureFlagService.getFlag('approvals');
}

export { RequestBalanceApprovalTab, shouldShow };
