import Flex from '@frontend/components/frames/Flex';
import { QRCodeSVG } from 'qrcode.react';
import { CopyButton } from '../CopyButton';
import {
  ButtonWrapper,
  PixCodeContainer,
  PixContainer,
  QrCodeWrapper,
} from './styled';

export interface IQRCodeProps {
  code: string;
}

export function QRCode(props: IQRCodeProps) {
  return (
    <PixContainer>
      <QrCodeWrapper>
        <QRCodeSVG value={props.code} size={140} />
      </QrCodeWrapper>
      <Flex direction="column">
        <PixCodeContainer>{props.code}</PixCodeContainer>

        <ButtonWrapper>
          <CopyButton
            textToCopy={props.code}
            copiedLabel="Código copiado!"
            defaultLabel="Copiar código Pix"
          />
        </ButtonWrapper>
      </Flex>
    </PixContainer>
  );
}

export function copyCodeToClipboard(
  setCopied: React.Dispatch<React.SetStateAction<boolean>>,
  code?: string,
) {
  if (!code) return;

  navigator.clipboard.writeText(code).then(() => {
    setCopied(true);

    setTimeout(function () {
      setCopied(false);
    }, 5000);
  });
}
