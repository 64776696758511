import { Box, Typography } from '@components';
import { ColorToken } from '@flash-tecnologia/hros-web-ui-v2';
import { format } from '@utils/format';
type Props = {
  type: 'DEBIT' | 'CREDIT' | 'BLOCKED' | 'DAILY_BALANCE';
  amount: number;
  /** @default true */
  useTypeColor?: boolean;
};

export function Amount(props: Props) {
  const data = mappedType[props.type];

  return (
    <Box display="flex" justifyContent="flex-end">
      <Typography.Body4
        weight={700}
        color={props.useTypeColor === false ? 'neutral.40' : data.color}>
        {data.prefix ?? ''} {format.currency(props.amount)}
      </Typography.Body4>
    </Box>
  );
}

const mappedType: Record<Props['type'], { color: ColorToken; prefix?: '+' | '-' }> = {
  BLOCKED: {
    color: 'feedback.error.40',
    prefix: '-',
  },
  DEBIT: {
    color: 'neutral.40',
    prefix: '-',
  },
  CREDIT: {
    color: 'feedback.success.40',
    prefix: '+',
  },
  DAILY_BALANCE: {
    color: 'neutral.40',
  },
};
