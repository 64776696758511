import { useCheckIsCorporateCard } from '@frontend/pages/Statement/data/useCheckIsCorporateCard';
import { useIsNaasEnabled } from '@frontend/pages/Statement/data/useIsNaasEnabled.ts';
import { Navigate, Outlet } from 'react-router-dom';

export default function CheckIsCorporateCard() {
  const checkCorporateCard = useCheckIsCorporateCard();
  const isNaasEnabled = useIsNaasEnabled();

  if (
    (isNaasEnabled.enabled && !isNaasEnabled.isLoading) ||
    isNaasEnabled.hasError ||
    (!checkCorporateCard.isCorporateCardUser &&
      !checkCorporateCard.isLoading) ||
    checkCorporateCard.hasError
  ) {
    return <Navigate to={'/home'} />;
  }
  return <Outlet />;
}
