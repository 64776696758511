import {
  Icons,
  Tab,
  TabItens,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import ApprovalFlow from '@frontend/pages/ApprovalFlowTab/ApprovalFlow';
import { useApprovalFlowEnable } from '@frontend/pages/ApprovalFlowTab/data/useApprovalflowEnabled';
import Policies from '@frontend/pages/Policies/Policies';
import PermissionsService from '@frontend/services/Permissions';
import { useSearchParams } from 'react-router-dom';
import { NewTag } from './styled';

export function CustomizationsTab() {
  const permissions = PermissionsService.usePermissions();
  const [searchParam] = useSearchParams();
  const approvalsEnable = useApprovalFlowEnable();
  const show =
    permissions?.includes('*') ||
    permissions?.includes('expense_manage_corporate_cards');

  const tab = searchParam.get('subTab');
  const tabs: TabItens[] = [
    {
      label: 'Políticas',
      component: <Policies />,
    },
  ];

  if (approvalsEnable) {
    tabs.push({
      label: (
        <Flex gap="xs4" align="center">
          <Typography variant="body4">Aprovações</Typography>
          <NewTag gap="xs5" align="center">
            <Icons name="IconSparkles" size={16} />
            <Typography variant="caption">Novo</Typography>
          </NewTag>
        </Flex>
      ),
      component: <ApprovalFlow />,
    });
  }

  return {
    show,
    label: 'Cartão corporativo',
    component: (
      <Tab
        variant="secondary"
        tabItens={tabs}
        defaultTab={tab === 'approval' ? 1 : 0}
      />
    ),
  };
}
