import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  CardGroupContainer,
  CardGroupPlanInfoContainer,
  CardGroupPlansNameContainer,
  CardsContainer,
} from './card-group.styles';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Card, CardDataProps } from '../Card';
import { trackEvent } from '@/utils/tracking';

export interface CardGroupProps {
  backgroundImage?: string;
  plansInfo?: {
    title: string;
    description: string;
  };
  plansName?: string;
  cards: CardDataProps[];
}
export const CardGroup = (props: CardGroupProps) => {
  const navigate = useNavigate();

  const handleCardButtonClick = (marketPageId: string) => {
    trackEvent('acquisition_app_store_card_clicked', { marketPageId });
    const params = createSearchParams(location.search);
    params.set('origin', 'app_store_card');
    params.set('id', marketPageId);
    navigate({ pathname: '/acquisition/overview', search: params.toString() });
  };
  if (!props.cards.length) return null;
  return (
    <CardGroupContainer withBorder={!!props.backgroundImage}>
      {props.backgroundImage && (
        <img
          src={props.backgroundImage}
          alt="card"
          style={{
            position: 'absolute',
            width: '25%',
            top: '-5%',
            right: 0,
          }}
        />
      )}
      {props.plansInfo && (
        <CardGroupPlanInfoContainer>
          <Typography
            variantColor="var(--color-neutral-20)"
            variant="headline8"
            style={{ textAlign: 'center' }}
          >
            {props.plansInfo.title}
          </Typography>
          <Typography
            variantColor="var(--color-neutral-40)"
            variant="body4"
            style={{ textAlign: 'center' }}
          >
            {props.plansInfo.description}
          </Typography>
        </CardGroupPlanInfoContainer>
      )}
      {props.plansName && (
        <CardGroupPlansNameContainer>
          <Typography
            variantColor="var(--color-secondary-50)"
            variant="headline9"
            style={{ textAlign: 'center' }}
          >
            {props.plansName}
          </Typography>
        </CardGroupPlansNameContainer>
      )}
      <CardsContainer>
        {props.cards.map((card) => (
          <Card {...card} onButtonClick={handleCardButtonClick} />
        ))}
      </CardsContainer>
    </CardGroupContainer>
  );
};
