import { Icons, IconTypes, Menu, MenuOptionType, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";

import { Container, Item, MenuContainer, OuterContainer } from "./styled";

export type ButtonGroupOption = {
  label: string;
  icon: IconTypes;
  onClick?: () => void;
  disabled?: boolean;
  iconColor?: string;
  menuOptions?: MenuOptionType[];
};

type ButtonGroupProps = {
  options: ButtonGroupOption[];
};

export const ButtonGroup = (props: ButtonGroupProps) => {
  function onClick(option: ButtonGroupOption) {
    if (option.disabled || option.onClick === undefined) {
      return;
    }
    option.onClick();
  }

  return (
    <OuterContainer>
      <Container onClick={(event) => event.stopPropagation()}>
        {props.options.map((option) => (
          <Tooltip title={option.label} key={option.label}>
            <Item onClick={() => onClick(option)} disabled={option.disabled ?? false} color={option.iconColor}>
              {option.menuOptions ? (
                <MenuContainer>
                  <Menu type="default" options={option.menuOptions}>
                    <Icons size={16} name={option.icon} />
                  </Menu>
                </MenuContainer>
              ) : (
                <Icons size={16} name={option.icon} />
              )}
            </Item>
          </Tooltip>
        ))}
      </Container>
    </OuterContainer>
  );
};
