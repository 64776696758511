import { trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

type Props = {
  multiple: boolean;
  closeModal(): void;
};

export function useApproveRequest({ multiple, closeModal }: Props) {
  const context = trpc.useContext();

  const { isLoading: loading, mutate } =
    trpc.corporateCard.requestBalance.approveMany.useMutation({
      onSuccess: () => {
        const { success } = getFeedback(multiple);

        setTimeout(() => {
          void context.corporateCard.requestBalance.search.invalidate();
          void context.corporateCard.requestBalance.countPendingApproval.invalidate();
          dispatchToast({
            type: 'success',
            content: success.content,
            description: success.description,
          });
        }, 2_500);
        closeModal();
      },
      onError: () => {
        const { error } = getFeedback(multiple);
        dispatchToast({
          type: 'error',
          content: error.content,
          description: error.description,
        });
      },
    });

  function approval(data: { orderIds: Array<string> }) {
    mutate(data);
  }
  return {
    approval,
    loading,
  };
}

function getFeedback(multiple: boolean) {
  return {
    success: {
      content: multiple
        ? 'Pedidos de saldo aprovados com sucesso!'
        : 'Pedido de saldo aprovado com sucesso!',
      description: multiple
        ? 'Os pedidos seguirão para revisão dos depósitos.'
        : 'O pedido seguirá para revisão do depósito.',
    },
    error: {
      content: multiple
        ? 'Não foi possível aprovar os pedidos de saldo.'
        : 'Não foi possível aprovar o pedido de saldo.',
      description: 'Por favor, tente novamente dentro de alguns instantes.',
    },
  };
}
