import * as SC from "././styled";

type FooterProps = {
  start?: JSX.Element[];
  middle?: JSX.Element[];
  end?: JSX.Element[];
};

export const FocusedFooter = ({ start, middle, end }: FooterProps) => {
  return (
    <SC.FooterContainer>
      <SC.FooterActions>
        {start && start.map((element, index) => <div key={`start-${index}`}>{element}</div>)}
      </SC.FooterActions>
      <div>{middle && middle.map((element, index) => <div key={`middle-${index}`}>{element}</div>)}</div>
      <SC.FooterActions>
        {end && end.map((element, index) => <div key={`end-${index}`}>{element}</div>)}
      </SC.FooterActions>
    </SC.FooterContainer>
  );
};
