import { Spinner } from "@flash-tecnologia/hros-web-ui-v2";
import {
  StyledCard,
  StyledFooter,
  StyledProcessingLabel,
  StyledCompletedLabel,
  StyledTitle,
  StyledDownloadIcon,
} from "./styled";
import { trpc } from "src/api/client";

type ExportCardProps = {
  type: "PROCESSING" | "COMPLETED";
  title: string;
  exportId: string;
  companyId: string;
};

const ProcessingState = () => {
  return (
    <StyledFooter>
      <StyledProcessingLabel variant="caption">
        Em processamento
      </StyledProcessingLabel>
      <Spinner variant="primary" size={16} />
    </StyledFooter>
  );
};

const CompletedState: React.FC<{
  exportId: string;
  companyId: string;
  fileName: string;
}> = ({ exportId, companyId, fileName }) => {
  const { mutateAsync } = trpc.downloadFile.useMutation();
  const utils = trpc.useContext();

  const downloadFile = async () => {
    const url = await mutateAsync({ companyId, exportId });
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    await utils.findExports.invalidate({ companyId });
  };

  return (
    <StyledFooter onClick={downloadFile} cursor="pointer">
      <StyledCompletedLabel variant="caption" weight={700}>
        Baixar relatório
      </StyledCompletedLabel>
      <StyledDownloadIcon name="IconDownload" size={16} />
    </StyledFooter>
  );
};

export const ExportCard = ({
  type,
  title,
  exportId,
  companyId,
}: ExportCardProps) => {
  return (
    <StyledCard>
      <StyledTitle title={title} variant="body4" weight={600}>
        {title}
      </StyledTitle>
      {type === "PROCESSING" && <ProcessingState />}
      {type === "COMPLETED" && (
        <CompletedState
          exportId={exportId}
          companyId={companyId}
          fileName={title}
        />
      )}
    </StyledCard>
  );
};
