import type { CommonModalProps } from "@components/Modals/types";

import { ConfirmationModalV2 } from "@components/Modals/common";

export interface FirstFeedbackSentModalProps extends CommonModalProps {
  surveyId?: string;
}

export const FirstFeedbackSentModal = ({
  open,
  isLoading,
  surveyId,
  onClose,
  onConfirm,
}: FirstFeedbackSentModalProps) => {
  return (
    <ConfirmationModalV2
      open={open}
      closeButton
      icon="IconConfetti"
      iconTitle="Parabéns!"
      title="Feedback enviado com sucesso!"
      subTitle={
        <>
          O destinatário receberá uma notificação via e-mail e poderá
          visualizá-lo. Continue enviando ou solicitando feedbacks e contribua
          com o crescimento de outros colaboradores!
        </>
      }
      confirm={{ title: "Fechar" }}
      onClose={onClose}
      onConfirm={onConfirm}
      isLoading={isLoading}
      surveyId={surveyId}
    />
  );
};
