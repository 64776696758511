import styled from "styled-components";

const Container = styled.div<{ hideBorder?: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid
    ${({ hideBorder }) =>
      hideBorder ? "var(--color-neutral-100)" : "var(--color-neutral-90)"};
  padding: 24px 0;
  gap: 4px;
  width: 100%;
`;

export { Container };
