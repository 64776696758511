import { Spinner } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import Typography from '@frontend/components/Typography';
import { ComponentPadding } from '../../utils/theme/paddings';
import { CorporateCardHighlights } from './components/CorporateCardHighlights';
import { EnableCorporateCard } from './components/EnableCorporateCard';
import { useFirstSteps } from './data/useFirstSteps';

import * as SC from './styled';

type Props = {
  title?: string;
  /**
   * @default all
   */
  variant?: 'minimal' | 'all';
  padding?: ComponentPadding;
  children: React.ReactNode;
};

export function FirstStepsChecker(props: Props) {
  const context = useFirstSteps();

  if (context.isLoading) {
    return (
      <Flex justify="center" gap="s" padding="xs">
        <Spinner size={32} />
      </Flex>
    );
  }

  if (context.alreadyActivatedEmployee) {
    return <>{props.children}</>;
  }

  function renderTitle() {
    if (props.variant !== 'minimal') {
      return (
        <Typography.Headline7 color="neutral_20">
          {props.title}
        </Typography.Headline7>
      );
    }

    return (
      <Typography.Headline8 color="neutral_20">
        {props.title}
      </Typography.Headline8>
    );
  }

  return (
    <SC.Container padding={props.padding}>
      {props.title && renderTitle()}

      <EnableCorporateCard
        hasEmployeesRegistered={context.hasEmployeesRegistered}
      />

      {props.variant !== 'minimal' && <CorporateCardHighlights />}
    </SC.Container>
  );
}
