import styled from 'styled-components';

export const TagContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: ${({ theme }) => theme.borders.radius.l};
  padding: ${({ theme }) => `${theme.spacings.xs5} ${theme.spacings.xs4}`};
  gap: ${({ theme }) => `${theme.spacings.xs5}`};
`;
