import { FieldType, FieldValue } from "."
import { S3File } from "../shared"

export interface CandidateDocumentField {
  fieldId: string
  customFieldId: string
  type: FieldType
  created?: Date
  expirationDate?: Date | null
  formGroupId: string
  value: string | number | boolean | Date | S3File | null
}

export interface IAcademicRecord {
  course?: string
  institution?: string
  graduationLevel?: string
  startDate?: Date
  endDate?: Date
}

export interface IRichText {
  html?: string
  json?: string
}

export interface IRecord {
  organization?: string
  role?: string
  assignments?: IRichText
  startDate?: Date
  endDate?: Date
}

export interface IProposalLetterInfo {
  assignments?: string
  aboutCompany?: S3File[]
  benefits?: S3File[]
  companyRole?: {
    _id: string
    name: string
  }
  department?: {
    _id: string
    name: string
  }
  group?: {
    _id: string
    name: string
  }
  hiringType?: string
  salary?: number
  variableSalary?: string
  hiringDate?: Date
  proposalStatus?: string
  refuseReason?: string
}

export interface IInternship {
  institutionName?: string
  courseName?: string
  startDate?: Date
  endDate?: Date
  workship?: string
  workload?: string
}

export interface Leader {
  _id: string
  name: string
  profilePicture?: S3File
}

export const PROBATION_OPTIONS = [
  "no-period",
  "custom",
  "one-period-30days",
  "one-period-45days",
  "one-period-60days",
  "one-period-90days",
  "two-period-30days",
  "two-period-45days",
  "period-30-60",
  "period-60-30",
] as const
export type ProbationOptions = (typeof PROBATION_OPTIONS)[number]

export type ProbationPeriod = {
  option?: ProbationOptions
  firstDate?: Date
  secondDate?: Date
}

export interface Candidate {
  _id: string
  active: boolean
  nationality?: string
  jobId?: string
  companyId: string
  employeeId: string
  registrationDate?: Date
  name: string
  socialName?: string
  legalName?: string
  documentNumber: string
  cnpj?: string
  gender?: string
  race?: string
  maritalStatus?: string
  schooling?: string
  leader?: Leader
  email: string
  phone?: string
  isPCD: boolean
  disabilityType?: string
  linkedinProfileUrl?: string
  indicator?: string
  jobUrl?: string
  corporateEmail?: string
  inviteDate?: Date
  curriculum?: S3File
  academicCareer?: IAcademicRecord[]
  professionalCareer?: IRecord[]
  extracurricularActivities?: IRecord[]
  documents?: CandidateDocumentField[]
  proposalLetterInfo?: IProposalLetterInfo
  internship?: IInternship
  validations?: IDocumentValidation[]
  externalCardDeliveryId?: string
  workshiftId?: string
  benefitsIds?: string[]
  probationPeriod?: ProbationPeriod
  workerTypeCode?: WorkerTypeCode
  assignmentTermCode?: AssignmentTermCode
  remunerationBasisCode?: RemunerationBasisCode
  workerGroups?: WorkerGroups
  standardHours?: number
}

export interface IDocumentValidation {
  formGroupId: string
  status: "approved" | "declined" | "waiting"
  statusComment?: string
}

export interface Dependent {
  _id: string
  active: boolean
  name: string
  birthDate: string
  kinship: string
  candidateId: string
  values: FieldValue[]
  validations: IDocumentValidation[]
}

export enum DesabilityTypes {
  Physical = "Deficiência física",
  Visual = "Deficiência visual",
  Hearing = "Deficiência auditiva",
  Mental = "Deficiência mental",
  Intellectual = "Deficiência intelectual",
  Autism = "Transtorno do espectro autista",
}

export enum WorkerTypeCode {
  CLT = "CLT",
  INTERN = "internship",
  YOUNG_APPRENTICE = "young_apprentice",
}

export enum AssignmentTermCode {
  INDETERMINED = "indetermined",
  FIXED_TERM_DAYS = "fixed_term_in_days",
  FIXED_TERM_CONDITION = "fixed_term_condition",
}

export enum RemunerationBasisCode {
  HOURLY = "hourly",
  MONTHLY = "monthly",
  COMMISSIONED = "commissioned",
  TASK_BASED = "task_based",
}

export enum WorkerGroups {
  CLOCK_IN = "clock_in",
  NO_CLOCK_IN = "no_clock_in",
  NO_EFFECTIVENESS = "no_effectiveness",
  CLOCK_IN_BY_EXCEPTION = "clock_in_by_exception",
}
