import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useEffect } from 'react'
import { Focused } from 'src/components/focused'

import { useNewOrderPage } from './hooks/use-new-order-page'
import { Step, useNewOrderStore } from './store/use-new-order-store'
import { SubTitlesContainerStyled, TitleStyled } from './styles'

export default function NewOrderPage() {
  const {
    activeStep,
    StepRender,
    breadcrumb,
    steps,
    startActions,
    endActions,
  } = useNewOrderPage()

  const { resetStore } = useNewOrderStore()

  useEffect(() => {
    return () => {
      resetStore()
    }
  }, [resetStore])

  return (
    <Focused
      activeStep={activeStep}
      breadcrumb={breadcrumb}
      steps={steps}
      footer={{
        startActions,
        endActions,
      }}
    >
      <TitleStyled variant="headline6" weight={700} color="neutral.20">
        Pedido por seleção na plataforma
      </TitleStyled>

      {activeStep === Step.EMPLOYEE_SELECTION && (
        <SubTitlesContainerStyled>
          <Typography variant="headline8" weight={700} color="neutral.20">
            Selecione as pessoas que farão parte do seu pedido
          </Typography>

          <Typography variant="body3" color="neutral.40">
            Confira sempre o resumo do pedido para acompanhar as suas escolhas
          </Typography>
        </SubTitlesContainerStyled>
      )}

      <StepRender />
    </Focused>
  )
}
