import { Box } from "@atoms"
import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2"

export const LoadingState = () => {
  return (
    <Box gap="xs2" $px="xs2">
      <Skeleton variant="rounded" width={"25%"} height={"100vh"} />
      <Skeleton variant="rounded" width={"25%"} height={"100vh"} />
      <Skeleton variant="rounded" width={"25%"} height={"100vh"} />
      <Skeleton variant="rounded" width={"25%"} height={"100vh"} />
    </Box>
  )
}
