import ModalService from '@frontend/services/ModalService';
import React from 'react';
import ConfirmDepositModal from '../components/steps/01-Form/components/ConfirmDepositModal/ConfirmDepositModal';
import InvalidDepositModal from '../components/steps/01-Form/components/InvalidDepositModal/InvalidDepositModal';
import { Form } from '../components/steps/01-Form/Form';
import { Feedback } from '../components/steps/02-Feedback/Feedback';
import { useForm } from './form/useForm';

enum ActivateUsersStepsEnum {
  /** Formulary for selecting users and defining the first deposit */
  FORM = 1,
  /** Reports the result of the operation */
  FEEDBACK = 2,
}

export function useFlowController() {
  const [step, setStep] = React.useState(ActivateUsersStepsEnum.FORM);
  const form = useForm({
    onSuccess: onSubmitSuccess,
    onAuthError: () => {
      ModalService.show(ConfirmDepositModal, {
        form,
        onConfirm: () => {
          void form.onSubmit();
        },
      });
    },
  });
  const modalController = ModalService.useModalController();

  return {
    content: getContent(),
    leftButtons: getLeftButtons(),
    rightButtons: getRightButtons(),
  };

  function getContent() {
    switch (step) {
      case ActivateUsersStepsEnum.FORM:
        return <Form form={form} />;
      case ActivateUsersStepsEnum.FEEDBACK:
        return <Feedback form={form} />;
    }
  }

  function getLeftButtons() {
    switch (step) {
      case ActivateUsersStepsEnum.FORM:
        return {
          label: 'Voltar',
          onClick: () => {
            modalController.remove();
          },
          isDisabled: false,
        } as const;
      case ActivateUsersStepsEnum.FEEDBACK:
        return undefined;
    }
  }
  function getRightButtons() {
    switch (step) {
      case ActivateUsersStepsEnum.FORM:
        return [
          {
            label: 'Ativar uso corporativo',
            onClick: confirm,
            isDisabled: false,
            isLoading: form.formState.isSubmitting,
            variant: 'primary',
          },
        ] as const;
      case ActivateUsersStepsEnum.FEEDBACK:
        return [
          {
            label: 'Fechar',
            onClick: () => {
              modalController.remove();
            },
            isDisabled: false,
            isLoading: false,
            variant: 'primary',
          },
        ] as const;
    }
  }
  async function confirm() {
    // Trigger form validation (necessary for untouched forms)
    await form.trigger();

    // Check if any deposit fields are filled; Otherwise, may continue without it
    const deposit = form.getValues().deposit;
    if (Object.values(deposit).some((value) => Boolean(value))) {
      // Check if the deposit is valid
      if (deposit.amount && deposit.creditDate && deposit.paymentMethod) {
        void form.onSubmit();
      } else {
        ModalService.show(InvalidDepositModal, {
          continueWithoutDeposit: () => {
            void form.onSubmit();
          },
        });
      }
      return;
    }
    void form.onSubmit();
  }
  function onSubmitSuccess() {
    setStep(ActivateUsersStepsEnum.FEEDBACK);
  }
}
