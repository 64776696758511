import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacings.xs2} 20px 20px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacings.xs3};
  align-self: stretch;

  border-radius: ${(props) => props.theme.spacings.xs2};
  border: 1px solid ${(props) => props.theme.colors.neutral[90]};
  background: ${(props) => props.theme.colors.neutral[100]};
`;

export const StepperContainer = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const Subtitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const Step = styled.div<{ filled: boolean; fillColor: string }>`
  height: 4px;
  width: 100%;
  border-radius: ${(props) => props.theme.spacings.xs4};

  background-color: ${(props) => (props.filled ? props.fillColor : props.theme.colors.neutral[90])};
`;
