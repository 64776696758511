import { useEffect, useState } from "react";
import {
  IPermissionsFlags,
  getPermissionsFlags,
} from "@flash-tecnologia/hros-web-utility";

export const CheckUnleashPermission = ({
  economicGroupId,
  flagName,
}: {
  economicGroupId: string;
  flagName: string;
}) => {
  const [flags, setFlags] = useState<IPermissionsFlags["flags"]>([]);
  const getUnleashToggle = async () => {
    const { flags } = await getPermissionsFlags("", "", economicGroupId);
    setFlags(flags || []);
  };

  useEffect(() => {
    getUnleashToggle();
  }, [economicGroupId]);

  return flags?.some((_flag) => _flag.name === flagName);
};
