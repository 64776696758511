import { Button as BaseButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const PrimaryBannerContentContainer = styled.div<{
  contentWidth: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${({ contentWidth }) => contentWidth};
  padding: ${({ theme }) => theme.spacings.s};
`;

export const PrimaryBannerInnerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export const BannerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 324px;
  gap: ${({ theme }) => theme.spacings.xs3};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  position: relative;
  overflow: hidden;
`;

export const Button = styled(BaseButton)`
  background-color: ${({ theme }) => theme.colors.secondary[50]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
`;
