import AppRouter from "./routes"
import { ThemeProvider } from "@flash-tecnologia/hros-web-ui-v2"
import { GlobalContext } from "./context"
import { QueryClientProvider } from "@tanstack/react-query"
import { ApolloProvider } from "@apollo/react-hooks"
import { makeApolloClient } from "./utils/apollo"
import { trpc, createTrpcClient, queryClient } from "./api/client"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import "./i18n"
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility"

const apolloClient = makeApolloClient()

export default function Root() {
  const { selectedCompany } = useSelectedCompany()

  return (
    <trpc.Provider
      client={createTrpcClient(selectedCompany.id)}
      queryClient={queryClient}
    >
      <ApolloProvider client={apolloClient}>
        <QueryClientProvider client={queryClient}>
          <GlobalContext>
            <ThemeProvider>
              <AppRouter />
            </ThemeProvider>
          </GlobalContext>

          {process.env.NODE_ENV !== "production" && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
        </QueryClientProvider>
      </ApolloProvider>
    </trpc.Provider>
  )
}
