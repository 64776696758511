import { Loader } from "@flash-tecnologia/hros-web-ui-v2"
import { ResignationCardTemplate } from "../CardTemplate"
import { PageTemplate, RouteHistory } from "../CardPageTemplate"
import { SpinnerContainer } from "./styles"
import { ResignationPageProps } from "./types"
import dispatchToast from "../../../utils/dispatchToast"
import { useNavigate } from "react-router-dom"
import { resignationFlowColumns } from "../../../mock/board"
import { trpc } from "@api/client"
import { FlowCategory } from "@customTypes/flow"

const ResignationCardPage = ({
  cardId,
  pageHistory,
  contents,
}: ResignationPageProps) => {
  const navigate = useNavigate()

  const {
    data: getResignationCardByIdData,
    isLoading,
    isRefetching,
  } = trpc.resignationCard.getResignationCardById.useQuery(
    { cardId },
    {
      enabled: !!cardId,
      refetchOnWindowFocus: false,
      retry: false,
      queryKey: ["resignationCard.getResignationCardById", { cardId }],
      onError: (err: any) => {
        const defaultError = "Ocorreu um erro ao encontrar o candidato"
        const errorMessage =
          err.serviceError?.details?.[0]?.message ||
          err.serviceError?.message ||
          err.httpError?.message ||
          err?.message

        dispatchToast({
          content: errorMessage || defaultError,
          type: "error",
        })
        navigate("/flows/resignation")
      },
      onSuccess: (card) => {
        if (!card) return
        const columnFromPathname = resignationFlowColumns.find((column) =>
          location.pathname.includes(column.route),
        )
        if (
          !columnFromPathname ||
          columnFromPathname.columnsId.includes(card.columnId)
        )
          return

        const rightColumnInfo = resignationFlowColumns.find((column) =>
          column.columnsId.includes(card.columnId),
        )
        navigate(`${rightColumnInfo?.route}${card._id}`, {
          replace: true,
        })
      },
    },
  )

  const card = getResignationCardByIdData as unknown as any

  const { data: getOnlyFlowByIdData, isInitialLoading: isFlowLoading } =
    trpc.flow.getOnlyFlowById.useQuery(
      {
        flowId: card?.flowId || "",
      },
      {
        enabled: !!card,
        refetchOnWindowFocus: false,
      },
    )

  const flow = getOnlyFlowByIdData as unknown as any

  if (!flow || !card || isFlowLoading || isLoading || isRefetching) {
    return (
      <SpinnerContainer>
        <Loader variant="primary" size="large" />
      </SpinnerContainer>
    )
  }

  const breadcrumbs: RouteHistory[] = pageHistory.map((history) => {
    return {
      name: history.name || "",
      href: history.href,
    }
  })

  const Content = contents?.["default"] || null

  return (
    <PageTemplate
      history={breadcrumbs}
      footer={{
        card: card,
        flow: flow,
        flowType: FlowCategory.RESIGNATION,
      }}
    >
      <ResignationCardTemplate card={card} flow={flow}>
        {Content && <Content card={card} flow={flow} />}
      </ResignationCardTemplate>
    </PageTemplate>
  )
}

export default ResignationCardPage
