import { Tag } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 712px;
  gap: 20px;
  @media screen and (max-width: 380px) {
    padding: 20px;
  }
`;

export const RadioCard = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 712px;
  max-height: 140px;
  padding: 16px 24px 16px 24px;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  align-items: center;
  @media screen and (max-width: 720px) {
    flex-direction: column;
    align-items: center;
    max-height: 300px;
    gap: 0px;
  }
`;

export const RadioCardRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const RadioCardLeft = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const RadioOptionText = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const RadioOptionTitle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 8px;
`;

export const StyledTag = styled(Tag)`
  padding: 4px 8px 4px 8px;
  gap: 4px;
  font-size: 10px;
  line-height: 12x;
  border-radius: 24px;
  height: 20px;
  margin-left: 4px;
`;
