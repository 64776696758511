import { z } from "zod"

const S3_FILE_TYPE_OPTIONS = ["internal", "external"] as const

export type S3File = {
  key: string
  path: string
  value?: string
  type: (typeof S3_FILE_TYPE_OPTIONS)[number]
}

export const S3FileZodObject = z.object({
  key: z.string(),
  path: z.string(),
  type: z.enum(S3_FILE_TYPE_OPTIONS),
  value: z.string().optional(),
})

export const ContractOutputZodObject = S3FileZodObject.extend({
  version: z.number(),
})

export type Nullable<T> = { [K in keyof T]: T[K] | null }

export * from "./contract"
