import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import React from 'react'
import styled from 'styled-components'

export const OrderedList = styled.ol`
  list-style-type: none;
  counter-reset: li;
  display: grid;
  gap: ${({ theme }) => theme.spacings.xs3};
`

export const StyledListItem = styled.li`
  position: relative;
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs5};
  align-items: center;
  &::before {
    content: counter(li);
    counter-increment: li;

    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.secondary[95]};

    font-family: 'Nunito', sans-serif;
    color: ${({ theme }) => theme.colors.secondary[50]};
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 150% */
    text-align: center;
  }
`

interface IListItemProps {
  children: React.ComponentProps<typeof Typography>['children']
}

export const ListItem: React.FC<IListItemProps> = ({ children }) => {
  return (
    <StyledListItem>
      <Typography variant="body3" color="neutral.30">
        {children}
      </Typography>
    </StyledListItem>
  )
}
