import { PageHeaderContainer, PageSubtitle, PageTitle, TitleSubtitleContainer } from "./styled";
import React from "react";

type PageHeaderProps = {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
};

export const PageHeader = (props: PageHeaderProps) => {
  return (
    <PageHeaderContainer>
      <TitleSubtitleContainer>
        <PageTitle>{props.title}</PageTitle>
        <PageSubtitle>{props.subtitle}</PageSubtitle>
      </TitleSubtitleContainer>
      {props.children}
    </PageHeaderContainer>
  );
};
