import { useCallback, useMemo, useState } from 'react'
import { useNewOrderStore } from 'src/domain/orders/pages/new-order-page/store/use-new-order-store'

import { createGroupArray } from '../../../utils/create-group-array'
import { FilterOption } from '../filter-content/hooks/use-filter-content'

export type FilterKey = 'groups'

type FiltersState = Record<FilterKey, string[]>

export type UseFilterParams = {
  onCloseFilter: () => void
}

export function useFilter({ onCloseFilter }: UseFilterParams) {
  const { employees, setFilterEmployees, employeesFilter } = useNewOrderStore()

  const [filters, setFilters] = useState<FiltersState>(employeesFilter)

  const groupOptions = useMemo<FilterOption[]>(
    () =>
      createGroupArray(employees).map((group) => ({
        name: group.name,
        count: group.employeeCount,
      })),
    [employees],
  )

  const updateFilterState = useCallback(
    (filterKey: FilterKey, itemValue: string) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [filterKey]: prevFilters[filterKey]?.includes(itemValue)
          ? prevFilters[filterKey].filter((item) => item !== itemValue)
          : [...(prevFilters[filterKey] || []), itemValue],
      }))
    },
    [],
  )

  const selectAll = useCallback(
    (
      filterKey: FilterKey,
      isSelected: boolean,
      useId: boolean,
      options: FilterOption[],
    ) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [filterKey]: isSelected
          ? options.map((option) =>
              useId && option.id ? option.id : option.name,
            )
          : [],
      }))
    },
    [],
  )

  const resetFilters = useCallback(() => {
    setFilters({ groups: [] })
    setFilterEmployees({ groups: [] })
  }, [setFilterEmployees])

  const applyFilters = useCallback(() => {
    setFilterEmployees(filters)
    onCloseFilter()
  }, [filters, onCloseFilter, setFilterEmployees])

  return {
    options: {
      groupOptions,
    },
    updateFilterState,
    selectAll,
    resetFilters,
    applyFilters,
    filters,
  }
}
