import * as SC from "./styled";

type FooterProps = {
  start?: JSX.Element[];
  middle?: JSX.Element[];
  end?: JSX.Element[];
};

export const FocusedHeader = ({ start, middle, end }: FooterProps) => {
  return (
    <SC.HeaderContainer>
      <SC.HeaderActions>
        {start && start.map((element, index) => <div key={`start-${index}`}>{element}</div>)}
      </SC.HeaderActions>
      <div>{middle && middle.map((element, index) => <div key={`middle-${index}`}>{element}</div>)}</div>
      <SC.HeaderActions>
        {end && end.map((element, index) => <div key={`end-${index}`}>{element}</div>)}
      </SC.HeaderActions>
    </SC.HeaderContainer>
  );
};
