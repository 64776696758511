import { dayjs, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container } from "./styles";
import { CommentInterface } from "./types";

const Comment = ({ name, date, value, hideBorder }: CommentInterface) => (
  <Container hideBorder={hideBorder}>
    <Typography
      variant="body3"
      variantColor="var(--color-neutral-40)"
      weight={600}
    >
      {value}
    </Typography>
    <Typography
      variant="body4"
      variantColor="var(--color-neutral-60)"
      weight={400}
    >
      {`${name} | ${dayjs(date)?.format("DD/MM/YYYY - HH:MM")}`}
    </Typography>
  </Container>
);

export { Comment };
