import {
  PillButton,
  Table as SoftTable,
  tableControllers,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { useState } from "react";
import { trpc } from "src/api/client";
import { StyledActions, StyledActionsContainer, StyledIcon } from "./styles";
import { useNavigate } from "react-router-dom";

type TableProps = {
  moduleId: string;
  openModal: (id: string) => void;
};

export const Table = ({ moduleId, openModal }: TableProps) => {
  const { selectedCompany } = useSelectedCompany();

  const { data } = trpc.findReports.useQuery({
    companyId: selectedCompany.id,
    moduleId,
  });

  const { table, pagination, total, setPagination } = useTableData(
    data,
    openModal
  );

  if (!data) {
    return null;
  }

  const pageSizeOptions = [
    {
      label: "5 itens",
      value: 5,
    },
    {
      label: "10 itens",
      value: 10,
    },
    {
      label: "25 itens",
      value: 25,
    },
  ];

  return (
    <SoftTable.Root variant="soft">
      <SoftTable.Content>
        <SoftTable.FilterSearch onSearch={() => null}>
          <PillButton
            icon="IconFilter"
            label="Filtros"
            onClick={() => null}
            variant="default"
            size="small"
            type="primary"
          />
        </SoftTable.FilterSearch>

        <SoftTable.Grid.Root>
          <SoftTable.Grid.Header
            getHeaderGroups={table.getHeaderGroups}
            toggleAllRowsExpanded={table.toggleAllRowsExpanded}
          />

          {table.rows.map((row, index) => (
            <SoftTable.Grid.Row key={index + row.id} row={row} />
          ))}
        </SoftTable.Grid.Root>

        <SoftTable.Pagination
          count={total}
          onPaginationChange={({ pageSize, pageNumber }) =>
            setPagination({ ...pagination, pageSize, pageNumber })
          }
          showItemRange
          pagination={pagination}
          pageSizeOptions={pageSizeOptions}
        />
      </SoftTable.Content>
    </SoftTable.Root>
  );
};

function useTableData(reports: any, openModal: (id: string) => void) {
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const data = reports || [];

  const { pageNumber, pageSize } = pagination;

  const total = data?.length || 0;

  const finalData = data?.slice(
    (pageNumber - 1) * pageSize,
    pageNumber * pageSize
  );

  const table = tableControllers.useTableColumns<any>({
    defaultColumn: {
      minSize: 200,
    },
    total: total,
    columns: [
      {
        header: "Relatório",
        accessorKey: "title",
        sticky: "left",
      },
      {
        header: "Data de criação",
        accessorKey: "createdAt",
      },
      {
        header: "Criador",
        accessorKey: "owner",
        minSize: 120,
      },
      {
        header: "Ações",
        accessorKey: "actions",
        minSize: 120,
        cell: ({ row }) => (
          <StyledActionsContainer>
            <StyledActions>
              <StyledIcon
                onClick={() => openModal(row?.original?.id)}
                name="IconDownload"
                size={20}
              />
              <StyledIcon
                onClick={() => navigate(`/reports/${row?.original?.id}`)}
                name="IconFileDescription"
                size={20}
              />
            </StyledActions>
          </StyledActionsContainer>
        ),
      },
    ],

    data: finalData,
    pagination: pagination,
    onPaginationChange: setPagination,
  });

  return {
    table,
    pagination,
    total,
    setPagination,
  };
}
