import { LazyComponent } from "$atoms";
export { ExternalRoot } from "./Root";

export function DashboardReimbursementsSection() {
  return (
    <LazyComponent
      factory={() => import("$frontend/external/DashboardReimbursementsSection/DashboardReimbursementsSection")}
    />
  );
}

export function Categories() {
  return <LazyComponent factory={() => import("$frontend/external/Categories/Categories")} />;
}
