import styled, { css } from 'styled-components'

export const SectionStyled = styled.div`
  display: flex;
  flex-direction: column;

  padding: ${({ theme }) => theme.spacings.xs1};
  gap: ${({ theme }) => theme.spacings.xs3};

  border-radius: ${({ theme }) => theme.borders.radius.m};
  border: ${({ theme }) => theme.borders.width.xs2} solid
    ${({ theme }) => theme.colors.neutral[90]};
`

export const HeaderStyled = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.spacings.xs5};
`

export const ListStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs5};

  max-height: 298px;

  overflow: auto;
`

export const ItemStyled = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${({ theme }) => theme.spacings.xs3};

  border-radius: ${({ theme }) => theme.borders.radius.m};

  > div {
    display: flex;
    align-items: center;

    gap: ${({ theme }) => theme.spacings.xs3};

    > span {
      padding: 0;
    }
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background-color: ${theme.colors.secondary[99]};
    `}
`
