import { CREATE_ACCOUNT_IMAGE_URL } from '@/assets/imageConstants';
import { Footer, Header } from '@/components/Common';
import { AdditionalDataAccepted, PDFModal } from '@components/Modals';
import {
  Checkbox,
  DatePicker,
  Icons,
  TextField,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITIONS_URL,
} from './complementary-data.constants';
import {
  useCepQuery,
  useUpdateComplementaryDataForm,
} from './complementary-data.hooks';
import {
  ButtonsContainer,
  CheckBoxArea,
  ContentContainer,
  CreateAccountText,
  FieldContainer,
  FlexFieldsContainer,
  FormContainer,
  HelperText,
  LeftContainer,
  LinkButtonStyled,
  PageContainer,
  RightContainer,
  StepWrapper,
  StyledButton,
  SubTitle,
  TextsContainer,
  Title,
} from './complementary-data.styles';

export const ComplementaryDataPage = () => {
  const navigate = useNavigate();
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { form, isLoading } = useUpdateComplementaryDataForm({
    onSuccess: () => {
      setOpenConfirmation(true);
    },
  });
  const {
    isLoading: isLoadingCep,
    cepData,
    fetch: fetchCepData,
  } = useCepQuery();
  useEffect(() => {
    const { logradouro, bairro, localidade, uf } = cepData;
    if (logradouro) form.setFieldValue('street', logradouro, true);
    if (bairro) form.setFieldValue('district', bairro, true);
    if (uf) form.setFieldValue('state', uf, true);
    if (localidade) form.setFieldValue('city', localidade, true);
  }, [cepData]);

  const handleModalOnClose = useCallback(() => {
    setOpenConfirmation(false);
    navigate('/home');
  }, [navigate]);

  return (
    <PageContainer>
      <Header />
      <ContentContainer>
        <StepWrapper>
          <LeftContainer>
            <TextsContainer>
              <CreateAccountText variant="body3">Criar conta</CreateAccountText>
              <Title variant="headline5">
                Para finalizar, informe alguns dados complementares
              </Title>
              <SubTitle variant="body3">
                Esses dados são importantes para manter seu cadastro de acordo
                com as instituições que regulamentam empresas como a Flash.
              </SubTitle>
            </TextsContainer>
            <form onSubmit={form.handleSubmit}>
              <FormContainer>
                <FieldContainer>
                  <TextField
                    label="Nome da Mãe"
                    id={'mothersName'}
                    name={'mothersName'}
                    value={form.values.mothersName}
                    onChange={form.handleChange}
                    error={
                      form.touched.mothersName &&
                      Boolean(form.errors.mothersName)
                    }
                    helperText={
                      form.touched.mothersName && form.errors.mothersName
                    }
                  />
                </FieldContainer>
                <FieldContainer>
                  <DatePicker
                    label="Data de Nascimento"
                    id={'birthDate'}
                    name={'birthDate'}
                    onDateChange={(value) =>
                      form.handleChange({ target: { id: 'birthDate', value } })
                    }
                    value={form.values.birthDate || undefined}
                    error={
                      form.touched.birthDate && Boolean(form.errors.birthDate)
                    }
                    helperText={form.touched.birthDate && form.errors.birthDate}
                  />
                </FieldContainer>
                <FieldContainer>
                  <TextField
                    label="CEP"
                    id={'zipCode'}
                    name={'zipCode'}
                    imaskProps={{
                      mask: '00000-000',
                      definitions: {
                        '#': /[0-9]/,
                      },
                    }}
                    onBlur={async (e) => {
                      const value = e?.target?.value || '';
                      if (value?.length === 9) await fetchCepData(value);
                    }}
                    value={form.values.zipCode}
                    onChange={form.handleChange}
                    error={form.touched.zipCode && Boolean(form.errors.zipCode)}
                    helperText={form.touched.zipCode && form.errors.zipCode}
                  />
                </FieldContainer>
                <FieldContainer>
                  <TextField
                    label="Logradouro"
                    disabled
                    id={'street'}
                    name={'street'}
                    value={form.values.street}
                    onChange={form.handleChange}
                    error={form.touched.street && Boolean(form.errors.street)}
                    helperText={form.touched.street && form.errors.street}
                  />
                </FieldContainer>
                <FlexFieldsContainer>
                  <FieldContainer fullWidth>
                    <TextField
                      label="Número"
                      fullWidth
                      id={'number'}
                      name={'number'}
                      value={form.values.number}
                      onChange={form.handleChange}
                      error={form.touched.number && Boolean(form.errors.number)}
                      helperText={form.touched.number && form.errors.number}
                    />
                  </FieldContainer>
                  <FieldContainer fullWidth>
                    <TextField
                      label="Complemento (opcional)"
                      fullWidth
                      id={'complement'}
                      name={'complement'}
                      value={form.values.complement}
                      onChange={form.handleChange}
                      error={
                        form.touched.complement &&
                        Boolean(form.errors.complement)
                      }
                      helperText={
                        form.touched.complement && form.errors.complement
                      }
                    />
                  </FieldContainer>
                </FlexFieldsContainer>
                <FieldContainer>
                  <TextField
                    label="Bairro"
                    disabled
                    id={'district'}
                    name={'district'}
                    value={form.values.district}
                    onChange={form.handleChange}
                    error={
                      form.touched.district && Boolean(form.errors.district)
                    }
                    helperText={form.touched.district && form.errors.district}
                  />
                </FieldContainer>
                <FieldContainer>
                  <TextField
                    label="Estado"
                    disabled
                    id={'state'}
                    name={'state'}
                    value={form.values.state}
                    onChange={form.handleChange}
                    error={form.touched.state && Boolean(form.errors.state)}
                    helperText={form.touched.state && form.errors.state}
                  />
                </FieldContainer>
                <FieldContainer>
                  <TextField
                    label="Cidade"
                    disabled
                    id={'city'}
                    name={'city'}
                    value={form.values.city}
                    onChange={form.handleChange}
                    error={form.touched.city && Boolean(form.errors.city)}
                    helperText={form.touched.city && form.errors.city}
                  />
                </FieldContainer>
              </FormContainer>
              <CheckBoxArea>
                <Checkbox
                  checked={form?.values?.termChecked}
                  onChange={(e) =>
                    form.handleChange({
                      target: { id: 'termChecked', value: e.target.checked },
                    })
                  }
                />
                <Typography variant="caption">
                  Li e aceito os{' '}
                  <u
                    role="presentation"
                    onClick={() => setShowTermsModal(true)}
                  >
                    Termos de Uso{' '}
                  </u>
                  e{' '}
                  <u
                    role="presentation"
                    onClick={() => setShowPrivacyPolicyModal(true)}
                  >
                    Políticas de Privacidade
                  </u>{' '}
                  da Flash
                </Typography>
              </CheckBoxArea>
              {form.touched.termChecked && form.errors.termChecked && (
                <HelperText variant="caption">
                  <Icons name="IconInfoCircle" size={18} />
                  {form.errors.termChecked}
                </HelperText>
              )}
              <ButtonsContainer>
                <LinkButtonStyled variant="default" onClick={() => {}}>
                  Voltar
                </LinkButtonStyled>
                <StyledButton
                  variant="primary"
                  size="large"
                  type="submit"
                  loading={isLoading || isLoadingCep}
                  disabled={isLoading || isLoadingCep}
                >
                  Continuar <Icons name="IconArrowRight" />
                </StyledButton>
              </ButtonsContainer>
            </form>
          </LeftContainer>
          <RightContainer imageUrl={CREATE_ACCOUNT_IMAGE_URL} />
          <PDFModal
            title="Política de Privacidade"
            src={PRIVACY_POLICY_URL}
            open={showPrivacyPolicyModal}
            onClose={() => setShowPrivacyPolicyModal(false)}
          />
          <PDFModal
            title="Termos de Uso"
            src={TERMS_AND_CONDITIONS_URL}
            open={showTermsModal}
            onClose={() => setShowTermsModal(false)}
          />
          <AdditionalDataAccepted
            open={openConfirmation}
            onClose={handleModalOnClose}
          />
        </StepWrapper>
      </ContentContainer>
      <Footer />
    </PageContainer>
  );
};
