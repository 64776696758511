import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  overflow: scroll;
  width: 100%;
  height: 100%;
  position: relative;
  .MuiPopper-root {
    z-index: 999;
  }
`;

export const Content = styled.div<{ hasFooter?: boolean }>`
  height: 100%;
  padding: ${({ theme }) => theme.spacings.xs3} ${({ theme }) => theme.spacings.xs};
  padding-bottom: ${({ hasFooter, theme }) => hasFooter && theme.spacings.l};
  display: flex;
  grid-row-gap: ${({ theme }) => theme.spacings.xs1};
  flex-direction: column;
`;
export const Header = styled.div`
  width: 100%;
  height: min-content;
  display: grid;
  grid-row-gap: ${({ theme }) => theme.spacings.xs2};
  grid-template-columns: 3fr 1fr;
  grid-column-gap: ${({ theme }) => theme.spacings.m};
  justify-items: end;
  align-items: center;
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
`;
export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.neutral[100]};
  width: fill-available !important;
  position: fixed;
  bottom: 0;
  z-index: 1300;
  box-shadow: 20px 4px 26px rgba(0, 0, 0, 0.1);
`;
