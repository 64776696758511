import DefaultModal from '@frontend/components/modals/DefaultModal';
import { ROUTE_Employees } from '@frontend/routes/ExternalRoutes';
import ModalService from '@frontend/services/ModalService';
import RouterService from '@frontend/services/RouterService';

function Modal() {
  const controller = ModalService.useModalController();
  const navigate = RouterService.useNavigate();

  return (
    <DefaultModal
      isOpen={controller.visible}
      onClose={controller.remove}
      title="Você ainda não cadastrou membros da equipe."
      subtitle="Para conseguir ativar o uso corporativo para as pessoas, você precisa antes cadastrá-las na área de Equipe."
      highlight={{ description: 'Atenção!', variant: 'warning' }}
      buttons={{
        cancel: {
          text: 'Cancelar',
          onClick: controller.remove,
        },
        confirm: {
          text: 'Ir para Equipe',
          icon: 'IconArrowRight',
          onClick: () =>
            navigate({
              route: ROUTE_Employees,
              params: {},
              searchParams: {},
              state: {},
            }),
        },
      }}
      children={undefined}
    />
  );
}

export const MODAL_WarningToRegisterEmployee = ModalService.create(Modal);
