import { Spinner } from '@flash-tecnologia/hros-web-ui-v2';
import { BarcodeGenerator } from '@frontend/components/Barcode';
import Flex from '@frontend/components/frames/Flex';
import { QRCode } from '@frontend/components/QrCode';
import { SurveyStore } from '@frontend/components/SurveyStore';
import Typography from '@frontend/components/Typography';
import FeatureFlagService from '@frontend/services/FeatureFlagService';
import { UseFormReturn } from '../../form/useForm';
import * as SC from './styled';

type Props = {
  form: UseFormReturn;
  code?: string;
};

export function Success(props: Props) {
  const paymentMethod = props.form.getValues('paymentMethod');
  const featureFlag = FeatureFlagService.getFlag('displayCode');

  return (
    <SC.Wrapper gap="xs" direction="column">
      <Typography.Body4 color="neutral_40">
        O {paymentMethod === 'PIX' ? 'código Pix' : 'boleto'} será enviado para
        o <strong>seu e-mail</strong>. Você pode acompanhar o status no Extrato
        da Carteira.
      </Typography.Body4>
      <SurveyStore
        title="Como você avalia a facilidade de uso da plataforma ao adicionar saldo na carteira corporativa?"
        type="add-flash-cash-feedback"
      />
      {paymentMethod === 'PIX' && featureFlag && (
        <Flex justify="center">
          {props.code ? <QRCode code={props.code} /> : <Spinner size={32} />}
        </Flex>
      )}
      {paymentMethod === 'BILLET' && featureFlag && (
        <Flex justify="center" padding="xs4">
          {props.code ? (
            <BarcodeGenerator barcode={props.code} width={0.8} height={50} />
          ) : (
            <Spinner size={32} />
          )}
        </Flex>
      )}
    </SC.Wrapper>
  );
}
