export const services = {
  bff: {
    development: "http://localhost:6721",
    staging: "https://expense-core-bff.private.flashapp.dev/v1/bff",
    production: "https://expense-core-bff.us.flashapp.services/v1/bff",
  },
  unleash: {
    development: "https://unleash-benefits-proxy-development.us.flashapp.services/proxy",
    staging: "https://unleash-benefits-proxy-development.us.flashapp.services/proxy",
    production: "https://unleash-benefits-proxy-production.us.flashapp.services/proxy",
  },
} as const;
