import { PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import Icon from '@frontend/components/Icon';
import Typography from '@frontend/components/Typography';
import { useCreateVirtualCardContext } from '../../../data/useCreateVirtualCardContext';

export function FeedbackHeader() {
  const context = useCreateVirtualCardContext();

  if (context.error?.createdCard) {
    return (
      <>
        <Flex direction="column" align="center" gap="xs4">
          <Icon name="IconX" color="error_40" background="error" size={48} />

          <Typography.Headline8 color="neutral_10">
            Não foi possível criar o cartão virtual
          </Typography.Headline8>

          <Typography.Body4 center color="neutral_40">
            Tivemos um erro ao processar as informações. <br />
            Tente novamente e, se o erro persistir, entre em contato com o nosso
            atendimento.
          </Typography.Body4>
        </Flex>

        <Flex justify="center">
          <PillButton
            label={
              context.loadingCreateCards
                ? 'Criando cartões...'
                : context.loadingLinkPolicy
                  ? 'Vinculando política...'
                  : 'Tentar novamente'
            }
            type="primary"
            icon="IconRefresh"
            variant="error"
            size="small"
            loading={context.loadingCreateCards || context.loadingLinkPolicy}
            onClick={() => void context.onSubmit()}
          />
        </Flex>
      </>
    );
  }

  return (
    <Flex direction="column" align="center" gap="xs4">
      <Icon
        name="IconCircleCheck"
        color="success_40"
        background="success"
        size={48}
      />

      <Typography.Headline8 color="neutral_10">
        Cartão virtual corporativo criado com sucesso!
      </Typography.Headline8>
    </Flex>
  );
}
