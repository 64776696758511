import { Tag } from "@flash-tecnologia/hros-web-ui-v2"; //TODO passar pelo styled ou criar componente
import { ComponentProps } from "react";
import { useTranslation } from "react-i18next";

import { ExpenseStatus, ExpenseType } from "$serverTypes";

export type ExpenseStatusTagProps = {
  /* Expense status */
  status?: ExpenseStatus;
  /* Determines which set of status texts should be used based on type (default REIMBURSEMENT) */
  type?: ExpenseType;
};

const expenseStatusColors = {
  [ExpenseStatus.DRAFT]: "gray",
  [ExpenseStatus.REJECTED]: "red",
  [ExpenseStatus.REQUIRE_CHANGES]: "yellow",
  [ExpenseStatus.PENDING_APPROVAL]: "purple",
  [ExpenseStatus.PENDING_ACCOUNTING]: "blue",
  [ExpenseStatus.FINISHED]: "green",
} satisfies Record<ExpenseStatus, ComponentProps<typeof Tag>["variant"]>;

export const ExpenseStatusTag = ({ status, type = ExpenseType.REIMBURSEMENT }: ExpenseStatusTagProps) => {
  const { t } = useTranslation("translations", { keyPrefix: "molecules.expenseStatusTag" });

  if (!status) {
    return <></>;
  }

  return (
    <Tag as="div" hasLeftDotIcon variant={expenseStatusColors[status]} size="small">
      {t(`expenseStatus.${type}.${status}`)}
    </Tag>
  );
};
