import { Icon, IconProps, Typography } from '@components';
import { colors, Spinner } from '@flash-tecnologia/hros-web-ui-v2';

import * as SC from './styled';

export type ActionProps = {
  isLoading?: boolean;
  isDisabled?: boolean;
  icon: IconProps['name'];
  variant: 'primary' | 'error';
  label: string;
  onClick(): void;
};

export function Action(props: ActionProps) {
  return (
    <SC.Button
      isLoading={props.isLoading}
      isDisabled={props.isDisabled}
      onClick={props.isDisabled ? undefined : props.onClick}>
      {props.isLoading ? (
        <Spinner size={24} />
      ) : (
        <Icon
          name={props.icon}
          size={24}
          color={mappedIcon[props.variant].color}
          background={mappedIcon[props.variant].background}
        />
      )}

      <Typography.Body4 as="span" color="neutral.20" weight={600}>
        {props.label}
      </Typography.Body4>

      <Icon name="IconChevronRight" size={16} color="neutral.40" />
    </SC.Button>
  );
}

const mappedIcon: Record<
  ActionProps['variant'],
  { color: Parameters<typeof colors.get>[number]; background: IconProps['background'] }
> = {
  primary: {
    color: 'secondary.50',
    background: 'default',
  },
  error: {
    color: 'feedback.error.50',
    background: 'error',
  },
};
