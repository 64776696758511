import { Icons, LinkButton, Tooltip } from "@flash-tecnologia/hros-web-ui-v2"; //TODO passar pelo styled ou criar componente
import { useState } from "react";

import { ImagesModal } from "$molecules";
import { Attachment } from "$serverTypes";
import { Container, Content, NumberReceiptsContainer, NumberReceiptsDescription, ReceiptIcon } from "./styled";

type ExpenseReceiptsProps = {
  attachments: Attachment[];
  attachmentsNumber?: number;
  variant?: "primary" | "secondary";
};

export function ExpenseReceipts({ attachments, attachmentsNumber = 0, variant = "primary" }: ExpenseReceiptsProps) {
  const attachmentsCount = attachments?.length || attachmentsNumber;
  const isEmpty = attachmentsCount === 0;
  const [showModal, setShowModal] = useState(false);
  function onShowModal() {
    if (attachments) {
      setShowModal((open) => !open);
    }
  }

  return (
    <>
      {variant === "primary" ? (
        <Container $isEmpty={isEmpty} onClick={attachmentsCount > 0 ? onShowModal : undefined}>
          <Tooltip arrow title={attachmentsCount === 0 ? "Despesa sem recibos vinculados." : ""} placement="top">
            <Content $isEmpty={isEmpty}>
              <ReceiptIcon
                $isEmpty={isEmpty}
                name={isEmpty ? "IconReceiptOff" : "IconReceipt"}
                size={35}
                fill="transparent"
                stroke="1.5"
              />
              {attachmentsCount > 1 && (
                <NumberReceiptsContainer>
                  <NumberReceiptsDescription variant="overline">{attachmentsCount}</NumberReceiptsDescription>
                </NumberReceiptsContainer>
              )}
            </Content>
          </Tooltip>
        </Container>
      ) : (
        <LinkButton variant="default" disabled={isEmpty} onClick={onShowModal}>
          {isEmpty ? "Sem Recibo" : "Ver Recibo"}
          <Icons name={isEmpty ? "IconReceiptOff" : "IconReceipt"} size={14} />
        </LinkButton>
      )}

      {showModal && (
        <ImagesModal
          attachments={[...attachments]}
          loadingDescription="Carregando recibos..."
          onCloseModalClick={onShowModal}
          initialIndex={0}
        />
      )}
    </>
  );
}
