import styled from "styled-components";

export const ErrorContainer = styled.div`
  margin-top: ${(props) => props.theme.spacings.xs5};
  display: flex;
  padding-left: ${(props) => props.theme.spacings.xs2};
  align-items: flex-start;
  gap: ${(props) => props.theme.spacings.xs5};
  align-self: stretch;
`;
