import { colors, ShapeIconProps } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const ShapeIcon = styled.div<{
  size: number;
  background: ShapeIconProps['variant'];
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  border-radius: ${p => p.theme.borders.radius.circular};
  background-color: ${p => {
    switch (p.background) {
      case 'default':
        return colors.secondary[95];
      case 'info':
        return colors.feedback.info[90];
      case 'success':
        return colors.feedback.success[90];
      case 'error':
        return colors.feedback.error[90];
      case 'neutral':
      case 'disabled':
        return colors.neutral[90];
    }
  }};
`;
