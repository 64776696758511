import { ModalV2 } from '@frontend/components/modals/ModalV2';
import { ModalFooter } from '@frontend/components/modals/ModalV2/ModalFooter';
import FeatureFlagService from '@frontend/services/FeatureFlagService';
import { downloadFromUrl } from '@frontend/utils/downloadFile/downloadFromUrl';
import { formatDate } from '@frontend/utils/masks';
import React from 'react';
import ModalService from 'src/services/ModalService';
import { useForm } from '../form/useForm';
import { Form } from '../steps/01-Form';
import { Error } from '../steps/02-Error';
import { Success } from '../steps/02-Success';
import { useGetBill } from './useGetBill';
import { useGetCode } from './useGetCode';

type ModalFooterProps = React.ReactComponentElement<
  typeof ModalFooter
>['props'];

type ButtonAction = ModalFooterProps['primary'];

enum AddFlashCashStepsEnum {
  FORM = 1,
  SUCCESS = 2,
  ERROR = 3,
}

export function useController() {
  const [step, setStep] = React.useState(AddFlashCashStepsEnum.FORM);
  const modalController = ModalService.useModalController();
  const featureFlag = FeatureFlagService.getFlag('displayCode');

  const form = useForm({
    onSuccess: onSubmitSuccess,
    onError: onSubmitError,
  });

  const paymentMethod = form.watch('paymentMethod');
  const codeData = useGetCode({
    orderId: form.data?._id,
    paymentMethod: 'PIX',
  });

  return {
    header: getHeader(),
    content: getContent(),
    actions: getActions(),
    highlight: getHighlight(),
  };

  function onSubmitSuccess() {
    setStep(AddFlashCashStepsEnum.SUCCESS);
  }

  function onSubmitError() {
    setStep(AddFlashCashStepsEnum.ERROR);
  }

  function getHighlight(): React.ComponentProps<typeof ModalV2>['highlight'] {
    switch (step) {
      case AddFlashCashStepsEnum.FORM:
        return undefined;
      case AddFlashCashStepsEnum.SUCCESS:
        return {
          description: 'Tudo certo!',
          variant: 'success',
          size: 'compact',
        };
      case AddFlashCashStepsEnum.ERROR:
        return {
          description: 'Atenção!',
          variant: 'warning',
          size: 'compact',
        };
    }
  }

  function getHeader() {
    switch (step) {
      case AddFlashCashStepsEnum.FORM:
        return 'Adicionar saldo à carteira';
      case AddFlashCashStepsEnum.SUCCESS:
        return `Conclua o pagamento até ${formatDate(form.getValues('creditDate'))}.`;
      case AddFlashCashStepsEnum.ERROR:
        return 'Não foi possível gerar o pagamento.';
    }
  }

  function getContent() {
    switch (step) {
      case AddFlashCashStepsEnum.FORM:
        return <Form form={form} />;
      case AddFlashCashStepsEnum.SUCCESS:
        return <Success form={form} code={codeData.code} />;
      case AddFlashCashStepsEnum.ERROR:
        return <Error />;
    }
  }

  function getCancelButton() {
    switch (step) {
      case AddFlashCashStepsEnum.FORM:
        return {
          description: 'Cancelar',
          onClick: () => {
            modalController.remove();
          },
          isDisabled: false,
        };
      case AddFlashCashStepsEnum.SUCCESS:
      case AddFlashCashStepsEnum.ERROR:
        return undefined;
    }
  }

  function getPrimaryButton(): ButtonAction {
    switch (step) {
      case AddFlashCashStepsEnum.FORM:
        return {
          description: 'Continuar',
          onClick: confirm,
          disabled: !form.formState.isValid,
          loading: form.formState.isSubmitting,
          icon: 'IconArrowRight',
        };
      case AddFlashCashStepsEnum.SUCCESS:
        return {
          description: 'Ir para extrato',
          onClick: () => {
            modalController.remove();
            window.location.href = '/expense/statement';
          },
          icon: 'IconArrowRight',
        };
      case AddFlashCashStepsEnum.ERROR:
        return {
          description: 'Fechar',
          onClick: () => {
            modalController.remove();
          },
          variant: 'secondary',
          color: 'neutral',
        };
    }
  }

  function getSecondaryButton(): ButtonAction | undefined {
    const bill = useGetBill(paymentMethod);

    if (featureFlag) {
      return undefined;
    }

    switch (step) {
      case AddFlashCashStepsEnum.FORM:
      case AddFlashCashStepsEnum.ERROR:
        return undefined;
      case AddFlashCashStepsEnum.SUCCESS:
        return {
          description:
            paymentMethod === 'PIX' ? 'Baixar código Pix' : 'Baixar boleto',
          onClick: () => {
            if (form.data?._id) {
              bill.mutate(form.data._id, {
                onSuccess(data) {
                  downloadFromUrl(data.pdfLink);
                },
              });
            }
          },
          loading: bill.isLoading,
          icon: 'IconDownload',
        };
    }
  }

  function getActions(): ModalFooterProps {
    return {
      cancel: getCancelButton(),
      primary: getPrimaryButton(),
      secondary: getSecondaryButton(),
    };
  }

  function confirm() {
    void form.onSubmit();
  }
}
