import { useState } from 'react';
import { Box, Details, Modal } from '@components';
import { DateRangePicker } from '@flash-tecnologia/hros-web-ui-v2';
import dayjs from 'dayjs';
import { DateRange } from 'react-day-picker';

type Props = {
  helpMessage?: string;
  isWarning?: boolean;
  isOpen: boolean;
  from?: Date;
  to?: Date;
  onApply(range: DateRange): void;
  onClose(): void;
  onchange?(range: DateRange): void;
};

export function DatePicker(props: Props) {
  const [range, setRangeDate] = useState<DateRange>({
    from: props.from,
    to: props.to,
  });

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="Defina um período personalizado"
      actions={{
        cancel: {
          description: 'Cancelar',
          onClick: props.onClose,
        },
        primary: {
          description: 'Aplicar',
          onClick: () => props.onApply(range),
        },
      }}
      size="sm">
      <DateRangePicker
        disableFooter
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error TODO: Analisar o motivo do erro no DS, pois a prop existe
        selected={range}
        styles={{
          table: {
            width: '400px',
            maxWidth: '400px',
          },
        }}
        toDate={dayjs().toDate()}
        onSelect={range => {
          setRangeDate(range);
          props.onchange(range);
        }}
        onSubmit={() => null}
      />

      {props.helpMessage && (
        <Box
          backgroundColor={props.isWarning ? 'status.attention.90' : 'neutral.95'}
          padding="xs4 xs3"
          radius="m"
          margin="xs3 none none none">
          <Details
            icon="IconInfoCircle"
            label={props.helpMessage}
            variant={props.isWarning ? 'error' : undefined}
          />
        </Box>
      )}
    </Modal>
  );
}
