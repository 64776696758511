import { Icons, IconTypes, Tooltip } from "@flash-tecnologia/hros-web-ui-v2"; //TODO passar pelo styled ou criar componente
import { ReactNode } from "react";
import { CSSProperties, useTheme } from "styled-components";

import { Caption, ContainerCaption, ContainerDescription, ContainerInfos, Description } from "./styled";

export type RowDescriptionProps = {
  title: {
    color?: string;
    description: string;
    tooltip?: string;
    style?: CSSProperties;
  };
  caption?: {
    description?: string;
    icon?: IconTypes;
    tooltip?: string;
    tooltipIcon?: IconTypes;
    color?: string;
    custom?: ReactNode;
  };
};

export function TextColumn({ title, caption }: RowDescriptionProps) {
  const { colors } = useTheme();

  function getTooltip(msg: string | undefined, color: string, size = 16, iconCustom: IconTypes | null = null) {
    if (!msg) return <></>;

    return (
      <Tooltip arrow title={msg} placement="top-end">
        <div>
          <Icons name={iconCustom || "IconInfoCircle"} size={size} color={color} fill="transparent" />
        </div>
      </Tooltip>
    );
  }

  return (
    <ContainerInfos>
      <ContainerDescription noCaption={!caption}>
        <Description variantColor={title.color} style={title.style}>
          {title.description}
        </Description>
        {getTooltip(title.tooltip, colors.neutral[40])}
      </ContainerDescription>
      {caption?.custom
        ? caption?.custom
        : caption?.description && (
            <ContainerCaption>
              {caption.icon ? (
                <Icons name={caption.icon} size={16} color={colors.neutral[60]} />
              ) : (
                caption.tooltip && getTooltip(caption.tooltip, colors.neutral[50], 15)
              )}
              <Caption variantColor={caption.color}>{caption?.description}</Caption>
              {caption.icon && getTooltip(caption?.tooltip, colors.neutral[50], 15, caption.tooltipIcon)}
            </ContainerCaption>
          )}
    </ContainerInfos>
  );
}
