import { Button, LinkButton } from '@components';

import * as SC from './styled';

type Action =
  | ({
      type: 'link';
    } & React.ComponentProps<typeof LinkButton>)
  | ({
      type: 'button';
    } & React.ComponentProps<typeof Button>);

export type DrawerActionsProps = {
  actions: Action[];
};

export function DrawerActions(props: DrawerActionsProps) {
  return (
    <SC.ActionsContainer>
      {props.actions.map((action, index) =>
        action.type === 'button' ? (
          <Button key={index} {...action} />
        ) : (
          <LinkButton key={index} {...action} />
        ),
      )}
    </SC.ActionsContainer>
  );
}
