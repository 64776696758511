import { CompanyBenefit as CompanyBenefitBFF } from "bff/src/types/benefits"
import { Employee as GetEmployeeResult } from "bff/src/types/employee"

export interface IInternship {
  institutionName: string
  courseName: string
  startDate: string
  endDate: string
  workship: string
  workload: string
}

export interface IFile {
  path: string
  key: string
  type: "internal" | "external"
}
export type Benefit = CompanyBenefitBFF

export enum EmployeeStatus {
  INVITATION_SCHEDULED = "INVITATION_SCHEDULED",
  INVITATION_PENDING = "INVITATION_PENDING",
  INVITATION_SENT = "INVITATION_SENT",
  INVITATION_EXPIRED = "INVITATION_EXPIRED",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DELETED = "DELETED",
}

export interface CompanyEmployee {
  id: string
  name: string
  documentNumber: string
  phoneNumber?: string
  email?: string
  corporateEmail?: string
  status?: EmployeeStatus
  companyId: string
  profilePicture?: string
  groups: {
    id: string
    name: string
  }[]
  roles: {
    id: string
    joinedAt: string
  }[]
  invitationDate?: string
}

export type Employee = GetEmployeeResult

export interface EmployeeDocument {
  title: string
  name: string
  formConfig: string
  extensionId: string
  enabled: boolean
  fields: EmployeeDocumentField[]
}

export interface EmployeeDocumentField {
  _id: string
  fieldId: string
  customFieldId: string
  formGroupId: string
  fieldName: string
  order: number
  fieldType: string
  component: string
  fieldRule: FieldRule
  employeeId: string
  value: any
  options?: string[]
}

export interface FieldRule {
  fieldId: string
  required: boolean
}
