import { isIncluded } from '@corporate-card/ts-utils/helper-functions/includes';
import Icon from '@frontend/components/Icon';
import { MODAL_Deposit } from '@frontend/pagesV2/user-accounts/UserAccounts/flows/Deposit/Deposit';
import AuthService from '@frontend/services/AuthService';
import FeatureFlagService from '@frontend/services/FeatureFlagService';
import ModalService from '@frontend/services/ModalService';
import { toCurrency } from '@frontend/utils/masks';
import { DateTime } from 'luxon';
import { UseFormReturn } from '../../../../controllers/form/useForm';
import { useGetEmployees } from '../../../../data/useGetEmployees';
import useGetOrder from './useGetOrder';

type Input = {
  form: UseFormReturn;
};

export function useSummaryData(input: Input) {
  const employees = useGetEmployees();
  const formValues = input.form.watch();
  const result = input.form.result;
  const controller = ModalService.useModalController();
  const user = AuthService.useUser();

  const orderId =
    result?.deposit?.status === 'SUCCESS' ? result.deposit.orderId : undefined;
  const orderData = useGetOrder({
    orderId,
    paymentMethod: formValues.deposit.paymentMethod as 'PIX' | 'BILLET',
  });

  return {
    /** Presentable details of the activation */
    activation: activationDetails(),
    /** Presentable details of the optional deposit (null if not requested) */
    deposit: depositDetails(),
    /** Downloadable link for PIX and Billet payments' PDF */
    downloadPdf: downloadPdf(),
    /** Offer to go to Create Deposit flow when a first deposit is not defined */
    goToCreateDepositFlow,
    pixCode: orderData?.pixCode,
    barcode: orderData?.barcode,
  };

  /* ---------------------------- Selected people --------------------------- */
  function activationDetails() {
    return {
      activatedEmployees: activatedEmployees(),
      couldNotActivateEmployees: couldNotActivateEmployees(),
    };
  }

  function activatedEmployees() {
    if (result?.activation?.status === 'SUCCESS') {
      return formatEmployeeList(formValues.employeeIds);
    }
    if (result?.activation?.status === 'PARTIAL') {
      const employees = new Set(formValues.employeeIds);
      result.activation.couldNotActivate.forEach((employee) => {
        employees.delete(employee);
      });
      return formatEmployeeList(employees);
    }
    return null;
  }

  function couldNotActivateEmployees() {
    if (result?.activation?.couldNotActivate?.length) {
      return formatEmployeeList(new Set(result.activation.couldNotActivate));
    }
    return null;
  }

  function formatEmployeeList(employeeIds: Set<string>) {
    if (employeeIds.size === 1) {
      const selectedEmployeeId = Array.from(employeeIds)[0];
      return {
        formattedAsText: employees.employees.find(
          (employee) => employee.value === selectedEmployeeId,
        )?.title,
        employeeIds,
      } as const;
    }

    // Get up to 2 selected people
    const selectedEmployees: string[] = [];
    for (const employee of employees.employees) {
      if (selectedEmployees.length >= 2) break;
      if (employeeIds.has(employee.value)) {
        selectedEmployees.push(employee.title);
      }
    }
    return {
      formattedAsText:
        selectedEmployees.join(', ') +
        (employeeIds.size > 2 ? ` + ${employeeIds.size - 2} pessoas` : ''),
      employeeIds,
    } as const;
  }

  /* -------------------------------- Deposit ------------------------------- */
  function depositDetails() {
    if (!result?.deposit || result.deposit.status === 'NOT_REQUESTED')
      return { status: 'NOT_REQUESTED' } as const;
    if (result.deposit.status === 'FAILED')
      return { status: 'FAILED' } as const;
    return {
      status: 'SUCCESS',
      creditDateDescription: creditDateDescription(),
      depositType: depositType(),
      depositAmount: {
        totalAmount: totalAmount(),
        individualAmount: individualAmount(),
      },
      paymentDetails: {
        paymentStatus: paymentStatus(),
        paymentMethod: selectedPaymentMethod(),
      },
    } as const;
  }

  function creditDateDescription() {
    const creditDate = formValues.deposit.creditDate
      ? DateTime.fromJSDate(formValues.deposit.creditDate, {
          zone: 'America/Sao_Paulo',
        }).startOf('day')
      : null;

    if (!creditDate?.isValid) return null;

    return formValues.deposit.automatic
      ? `Todo dia ${creditDate.toFormat(
          'dd',
        )}, a partir de ${creditDate.toFormat('dd/MM/yyyy')}`
      : creditDate.toFormat('dd/MM/yyyy');
  }

  function depositType() {
    if (formValues.deposit.automatic) {
      return formValues.deposit.complementary
        ? 'Depósito automático complementar'
        : 'Depósito automático';
    }
    return formValues.deposit.expires
      ? 'Depósito único com expiração de saldo'
      : 'Depósito único';
  }

  function totalAmount() {
    if (!formValues.deposit.amount) return 'Nenhum depósito definido';
    if (!formValues.employeeIds.size) return 'Nenhuma pessoa selecionada';
    return `${toCurrency(formValues.deposit.amount * formValues.employeeIds.size)} total`;
  }

  function individualAmount() {
    if (!formValues.deposit.amount) return null;
    return `${toCurrency(formValues.deposit.amount)} para cada conta`;
  }

  function paymentStatus() {
    if (formValues.deposit.paymentMethod === 'FLASH_CASH') {
      return {
        label: 'Depósito agendado',
        variant: 'success',
      } as const;
    }
    return {
      label: 'Pagamento pendente',
      variant: 'primary',
    } as const;
  }

  function selectedPaymentMethod() {
    const paymentMethod = formValues.deposit.paymentMethod;
    switch (paymentMethod) {
      case 'FLASH_CASH':
        return {
          label: 'Flash Cash Corporativo',
          icon: 'IconWallet' satisfies IconName,
        } as const;
      case 'BILLET':
        return {
          label: 'Boleto',
          icon: 'BarcodeMethod' satisfies IconName,
        } as const;
      case 'PIX':
        return {
          label: 'Pix',
          icon: 'Pix' satisfies IconName,
        } as const;
      default:
        return null;
    }
  }

  /* ----------------------------- Download PDF ----------------------------- */
  function downloadPdf() {
    const featureFlag = FeatureFlagService.getFlag('displayCode');
    const paymentMethod = formValues.deposit.paymentMethod ?? '';
    if (!isIncluded(paymentMethod, ['BILLET', 'PIX'])) return null;
    const documentName = paymentMethod === 'BILLET' ? 'boleto' : 'código Pix';

    if (orderData.isError)
      return {
        disabled: true,
        isLoading: false,
        label: 'Confira seu e-mail',
        onClick: () => {
          void orderData.refetch();
        },
        emailText: `O ${documentName} foi enviado para o e-mail`,
        email: user?.email ?? 'cadastrado em seu usuário',
      };
    if (featureFlag) {
      return {
        disabled: false,
        isLoading: orderData.isLoading,
        label: null,
        onClick: null,
        emailText: `O ${documentName} foi enviado para o e-mail`,
        email: user?.email ?? 'cadastrado em seu usuário',
      };
    }
    return {
      disabled: false,
      isLoading: orderData.isLoading,
      label: paymentMethod === 'BILLET' ? 'Baixar Boleto' : 'Baixar código Pix',
      onClick: () => {
        window.open(orderData.pdfUrl, '_blank');
      },
      emailText: `O ${documentName} foi enviado para o e-mail`,
      email: user?.email ?? 'cadastrado em seu usuário',
    };
  }

  /* -------------------------- Create Deposit Flow ------------------------- */

  function goToCreateDepositFlow() {
    const allSelected = employees.employees
      .filter((employee) => formValues.employeeIds.has(employee.value))
      .map((employee) => employee.value);
    controller.remove();
    ModalService.show(MODAL_Deposit, {
      defaultValues: {
        employeeIds: new Set(allSelected),
        deposit: {
          expires: false,
          automatic: false,
          complementary: false,
          paymentMethod: 'FLASH_CASH',
          creditDate: formValues.deposit.creditDate || new Date(),
          amount: formValues.deposit.amount || 0,
        },
      },
    });
  }
}

type IconName = React.ComponentProps<typeof Icon>['name'];
