import {
  IconsProps,
  LinkButtonProps,
  SummaryBox as SummaryBoxUI,
} from '@flash-tecnologia/hros-web-ui-v2';

type Action = {
  label: string;
  onClick(): void;
  icon?: IconsProps['name'];
  variant?: LinkButtonProps['variant'];
  underlined?: boolean;
};

type Props = {
  title: string;
  icon: IconsProps['name'];
  fullWidth?: boolean;
  headerAction?: Action;
  footerActions?: Action[];
  children: React.ReactNode;
};

export function SummaryBox(props: Props) {
  return (
    <SummaryBoxUI
      title={props.title}
      leftAdornment={props.icon}
      action={props.headerAction}
      footerActions={props.footerActions}
      fullWidth={props.fullWidth}>
      {props.children}
    </SummaryBoxUI>
  );
}
