import { IconTypes, ShapeIconOptions } from "@flash-tecnologia/hros-web-ui-v2"; //TODO passar pelo styled ou criar componente
import { useTheme } from "styled-components";

import { Avatar } from "$molecules";
import { RowDescriptionProps, TextColumn } from "$organisms";
import { Container, Icon } from "./styled";

export type MainRowColumnProps = {
  icon?: {
    name: IconTypes;
    variant: ShapeIconOptions;
    strokeWidth?: string;
    size?: number;
  };
  image?: {
    url?: string;
    alt: string;
  };
  title: RowDescriptionProps["title"];
  caption?: RowDescriptionProps["caption"];
};

export function MainRowColumn({ image, icon, title, caption }: MainRowColumnProps) {
  const { colors } = useTheme();

  return (
    <Container>
      {!icon?.name && (image?.url || image?.alt) && <Avatar src={image?.url || ""} alt={image.alt} size={40} />}
      {!image?.url && icon?.name && (
        <Icon
          className="shape-icon-table"
          name={icon.name}
          size={icon.size ?? 32}
          variant={icon.variant}
          stroke={icon.variant}
          strokeWidth={icon.strokeWidth || "2"}
        />
      )}
      {title.description && (
        <TextColumn
          title={{ ...title, color: title.color ?? colors.neutral[20] }}
          caption={caption ? { ...caption, color: caption.color ?? colors.neutral[40] } : undefined}
        />
      )}
    </Container>
  );
}
