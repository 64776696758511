import { Accordion, AccordionHeader, RenderField } from "@Components"
import { useEffect, useState } from "react"
import {
  AccordionChildren,
  AccordionElement,
  ChildrenContainer,
  FlexColumn,
} from "./styles"
import { trpc } from "src/api/trpc"
import { Component, FieldType, MaritalStatusEnum } from "server/src/types"
import { dayjs } from "@flash-tecnologia/hros-web-ui-v2"
import { maritalStatusOptions } from "@Utils/mocks"

type LocalDependent = {
  isOpen: boolean
  kinship?: string
  maritalStatus?: MaritalStatusEnum
  name?: string
  birthDate?: string
}

interface NewDependentProps {
  candidateId: string
  successCallback?: () => void
  handleDeleteLocal: () => void
}

const NewDependent = ({
  candidateId,
  successCallback,
  handleDeleteLocal,
}: NewDependentProps) => {
  const [localDependent, setLocalDependent] = useState<LocalDependent>({
    isOpen: true,
  })

  const utils = trpc.useContext()
  const { mutateAsync: createDependent } =
    trpc.candidate.createDependent.useMutation()

  useEffect(() => {
    async function createDependentIfAllDataFilled() {
      if (
        localDependent.kinship &&
        localDependent.name &&
        localDependent.birthDate &&
        localDependent.maritalStatus &&
        dayjs(localDependent.birthDate).isValid()
      ) {
        await createDependent({
          birthDate: localDependent.birthDate,
          kinship: localDependent.kinship,
          name: localDependent.name,
          maritalStatus: localDependent.maritalStatus,
          candidateId,
        })

        setTimeout(() => {
          utils.wizard.getDependentDocuments.invalidate()
          setLocalDependent({
            isOpen: true,
            kinship: undefined,
            name: undefined,
            birthDate: undefined,
          })

          if (successCallback) successCallback()
        }, 2000)
      }
    }

    createDependentIfAllDataFilled()
  }, [localDependent])

  const handleDelete = () => {
    setLocalDependent({
      isOpen: false,
      kinship: undefined,
      name: undefined,
      birthDate: undefined,
    })
    handleDeleteLocal()
  }

  const renderLocalChildren = () => {
    return (
      <AccordionChildren>
        <AccordionElement hasId={true}>
          <FlexColumn>
            <ChildrenContainer>
              <RenderField
                field={{
                  candidateId: candidateId,
                  type: "base",
                  enabled: true,
                  formGroupId: "",
                  fieldId: "kinship",
                  customFieldId: "kinship",
                  fieldType: FieldType.Text,
                  component: Component.Select,
                  options: [
                    "conjuge",
                    "enteados",
                    "filho(a)",
                    "filho(a) adotivo(a)",
                    "união estável",
                    "irmão(ã), neto(a), bisneto(a)",
                    "pai, avô(ó) e bisavô(ó)",
                  ],
                  value: localDependent.kinship,
                  placeholder: "Grau de parentesco",
                  fieldRule: {
                    fieldId: "kinship",
                    required: true,
                  },
                }}
                handleChange={async (value) =>
                  setLocalDependent((prev) => ({
                    ...prev,
                    kinship: value,
                  }))
                }
              />

              <RenderField
                field={{
                  candidateId: candidateId,
                  type: "base",
                  enabled: true,
                  formGroupId: "",
                  fieldId: "name",
                  customFieldId: "name",
                  fieldType: FieldType.Text,
                  component: Component.ShortText,
                  value: localDependent.kinship,
                  placeholder: "Nome do dependente",
                  fieldRule: {
                    fieldId: "name",
                    required: true,
                  },
                }}
                handleChange={async (value) =>
                  setLocalDependent((prev) => ({
                    ...prev,
                    name: value,
                  }))
                }
              />

              <RenderField
                field={{
                  candidateId: candidateId,
                  type: "base",
                  enabled: true,
                  formGroupId: "basicData",
                  fieldId: "maritalStatus",
                  customFieldId: "maritalStatus",
                  fieldType: FieldType.Text,
                  component: Component.Select,
                  value: localDependent.maritalStatus,
                  placeholder: "Estado civil",
                  options: maritalStatusOptions,
                  fieldRule: {
                    fieldId: "maritalStatus",
                    required: true,
                  },
                }}
                handleChange={async (value) =>
                  setLocalDependent((prev) => ({
                    ...prev,
                    maritalStatus: value,
                  }))
                }
              />

              <RenderField
                field={{
                  candidateId: candidateId,
                  type: "base",
                  enabled: true,
                  formGroupId: "",
                  fieldId: "birthDate",
                  customFieldId: "birthDate",
                  fieldType: FieldType.Date,
                  component: Component.DatePicker,
                  value: localDependent.birthDate,
                  placeholder: "Data de nascimento",
                  fieldRule: {
                    fieldId: "birthDate",
                    required: true,
                  },
                }}
                handleChange={async (value) =>
                  setLocalDependent((prev) => ({
                    ...prev,
                    birthDate: value,
                  }))
                }
              />
            </ChildrenContainer>
          </FlexColumn>
        </AccordionElement>
      </AccordionChildren>
    )
  }

  return (
    <Accordion
      isOpen={localDependent.isOpen}
      renderChildren={renderLocalChildren()}
    >
      <AccordionHeader
        label={"Adicionar dependente"}
        subLabel={""}
        optional={false}
        hasExpanded={localDependent.isOpen}
        hasDeleteIcon={true}
        onDeleteClick={(e) => {
          e.stopPropagation()
          handleDelete()
        }}
        onClick={() =>
          setLocalDependent((prev) => ({
            ...prev,
            isOpen: !prev.isOpen,
          }))
        }
      />
    </Accordion>
  )
}

export default NewDependent
