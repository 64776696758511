import { LinkButton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  BannerContainer,
  SecondaryBannerContentContainer,
  SecondaryBannerInnerContentContainer,
} from './secondary-banner.styles';
import { BannerProps } from '../banners-section.dto';
import { createSearchParams, useNavigate } from 'react-router-dom';

export const SecondaryBanner = (props: BannerProps) => {
  const navigate = useNavigate();
  const onButtonClick = () => {
    const params = createSearchParams(location.search);
    params.set('origin', 'app_store_banner');
    params.set('id', props.marketPageId);
    navigate({ pathname: '/acquisition/overview', search: params.toString() });
  };
  return (
    <BannerContainer style={{ backgroundColor: props.backgroundColor }}>
      <SecondaryBannerContentContainer>
        <SecondaryBannerInnerContentContainer>
          <Typography
            variantColor="var(--color-neutral-20)"
            variant="headline9"
          >
            {props.title}
          </Typography>
          <Typography variantColor="var(--color-neutral-30)" variant="body4">
            {props.description}
          </Typography>
        </SecondaryBannerInnerContentContainer>
        <LinkButton
          variant="neutral"
          style={{ flex: 0 }}
          onClick={onButtonClick}
        >
          {props.buttonText || 'Saiba mais'}
        </LinkButton>
      </SecondaryBannerContentContainer>
      <img
        src={props.image}
        alt="card"
        style={{
          overflow: 'hidden',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          right: 0,
          top: 0,
        }}
      />
    </BannerContainer>
  );
};
