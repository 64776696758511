import { ApprovalsPreview } from '@containers/Approvals/components/organisms/ApprovalsPreview';
import { external } from '@flash-hros/corporate-card';
import { BasePage } from '@organisms/BasePage/BasePage';
import { useTranslation } from 'react-i18next';

export const Approvals = () => {
  const { t } = useTranslation();

  return (
    <BasePage
      headerPage={{
        title: t('pages.approvals.title'),
      }}>
      <external.ExternalRoot>
        <ApprovalsPreview />
      </external.ExternalRoot>
    </BasePage>
  );
};
