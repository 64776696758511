import styled from "styled-components";

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RowContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 28px;

  align-items: center;
`;
