import { Skeleton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { trackEvent } from '@/utils/tracking';
import { Accordion, Container, ItemsContainer } from './styles';
import { useGetMarketPage } from '@/shared/hooks';

type FaqSectionProps = {
  marketPageId: string;
};

export const FaqSection = ({ marketPageId }: FaqSectionProps) => {
  const { marketPage, isLoading } = useGetMarketPage(marketPageId);

  const onItemClick = (item: string) => {
    trackEvent('acquisition_faq_item_clicked', {
      market_page_id: marketPageId,
      item,
    });
  };

  if (isLoading) {
    return <Skeleton variant="rounded" width="100%" height="500px" />;
  }

  if (!marketPage || !marketPage.faq?.length) {
    return null;
  }

  return (
    <Container>
      <Typography variant="headline6">Ficou com alguma dúvida?</Typography>
      <ItemsContainer>
        {marketPage.faq.map((item) => (
          <div
            key={item.title}
            onClick={() => onItemClick(item.title)}
            role="presentation"
          >
            <Accordion title={item.title}>
              <Typography
                variant="body3"
                variantColor="var(--color-neutral-50)"
                style={{ whiteSpace: 'pre-line' }}
              >
                {item.answer}
              </Typography>
            </Accordion>
          </div>
        ))}
      </ItemsContainer>
    </Container>
  );
};
