import { useGetPolicies } from '@frontend/pages/Home/FlashCard/focusedFlows/CreateVirtualCorporateCard/data/useGetPolicies';
import FormService from '@frontend/services/FormService';
import ModalService from '@frontend/services/ModalService';
import { trpc } from '@frontend/trpc';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { CreateVirtualCardsSchema } from './schema';
import { CreateVirtualCardContext } from './types';

export const createVirtualCardContext =
  React.createContext<CreateVirtualCardContext>({
    form: {} as unknown as CreateVirtualCardContext['form'],
    currentStep: 3,
    loadingCreateCards: false,
    loadingLinkPolicy: false,
    loadingEmployees: false,
    loadingPolicies: false,
    employees: [],
    policies: [],
    onSubmit: async () => {},
    onClose: () => null,
  });

type Props = {
  employeeIds?: string[];
  children: React.ReactNode;
};

export function CreateVirtualCardProvider(props: Props) {
  const [currentStep, setStep] = useState(0);
  const controller = ModalService.useModalController();

  const employees = trpc.company.cards.findToAdd.useQuery({
    search: '',
    limit: 99999,
  });
  const createVirtualCard = trpc.company.virtualCard.create.useMutation();

  const policies = useGetPolicies();
  const linkPolicy = trpc.company.policies.cardLinks.patch.useMutation();

  const form = FormService.useCreateForm(CreateVirtualCardsSchema, {
    mode: 'onChange',
    resetOptions: {
      keepErrors: true,
    },
    defaultValues: {
      employeeIds: new Set<string>(props.employeeIds),
      name: '',
    },
  });

  async function linkCardWithPolicy(policyId: string, cardIds: string[]) {
    await linkPolicy.mutateAsync({
      policyId: policyId,
      linkCardIds: cardIds,
    });
  }

  function onClose() {
    controller.remove();
  }

  async function createCards(
    cardName: string,
    employeeIds: string[],
    expirationDate?: Date,
  ) {
    const _expirationDate = expirationDate
      ? DateTime.fromJSDate(expirationDate)
      : DateTime.now().plus({ year: 5, day: -1 });

    const serializedData = employeeIds.map((employeeId) => ({
      employeeNanoId: employeeId,
      name: cardName,
      expirationDate: _expirationDate.endOf('day').toISO(),
    }));

    const cards = await createVirtualCard.mutateAsync(serializedData);

    return cards;
  }

  const onSubmit = form.handleSubmit(async (value) => {
    await form.trigger();

    const cards = await createCards(
      value.name,
      Array.from(value.employeeIds),
      value.expirationDate,
    );

    if (value.policyId) {
      await linkCardWithPolicy(
        value.policyId,
        cards.map((card) => card.financeCardId),
      );
    }

    setStep(1);
  });

  return (
    <createVirtualCardContext.Provider
      value={{
        form: {
          control: form.control,
          getValues: form.getValues,
        },
        currentStep,
        loadingCreateCards: createVirtualCard.isLoading,
        loadingLinkPolicy: linkPolicy.isLoading,
        loadingEmployees: policies.isLoading,
        loadingPolicies: employees.isLoading,
        employees: employees.data?.users ?? [],
        policies: policies.data ?? [],
        onSubmit,
        onClose,
        error: {
          createdCard: createVirtualCard.isError,
          linkPolicy: linkPolicy.isError,
        },
      }}
    >
      {props.children}
    </createVirtualCardContext.Provider>
  );
}
