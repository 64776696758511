import { PageContainer } from '@flash-tecnologia/hros-web-ui-v2';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import Flex from '@frontend/components/frames/Flex';
import ToggleField from '@frontend/components/inputs/ToggleField';
import FeatureFlagService from '@frontend/services/FeatureFlagService';

export default function FeatureFlags() {
  const corporateCardRequest = FeatureFlagService.getFlag(
    'corporateCardRequest',
  );
  const externalCards = FeatureFlagService.getFlag('externalCards');
  const policies = FeatureFlagService.getFlag('policies');
  const uploadTransactions = FeatureFlagService.getFlag('uploadTransactions');
  const exportReportAsPdf = FeatureFlagService.getFlag('exportReportAsPdf');
  const externalCardsV2 = FeatureFlagService.getFlag('externalCardsV2');
  const limitByCategoryPolicy = FeatureFlagService.getFlag(
    'limitByCategoryPolicy',
  );
  const accountManagement = FeatureFlagService.getFlag('accountManagement');

  return (
    <PageContainer>
      <Spacer y={'l'} />
      <Typography.Headline6>Feature Flags</Typography.Headline6>
      <Spacer y={'m'} />
      <Flex gap="xs2" direction="column">
        <ToggleField
          title="corporateCardRequest"
          value={corporateCardRequest}
          description=""
        />

        <ToggleField
          title="externalCards"
          value={externalCards}
          description=""
        />

        <ToggleField title="policies" value={policies} description="" />
        <ToggleField
          title="uploadTransactions"
          value={uploadTransactions}
          description=""
        />

        <ToggleField
          title="exportReportAsPdf"
          value={exportReportAsPdf}
          description=""
        />

        <ToggleField
          title="externalCardsV2"
          value={externalCardsV2}
          description=""
        />

        <ToggleField
          title="limitByCategoryPolicy"
          value={limitByCategoryPolicy}
          description=""
        />

        <ToggleField
          title="accountManagement"
          value={accountManagement}
          description=""
        />
      </Flex>
    </PageContainer>
  );
}
