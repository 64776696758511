import { Box } from "@atoms"
import { Tag, TagProps, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"

export interface ResignationCardProps {
  tagProps?: TagProps
  title: string
  subtitle: string
  bottomText: string
}

export const ResignationCard = ({ bottomText, tagProps, subtitle, title }: ResignationCardProps) => {
  const theme = useTheme()

  return (
    <Box flexDirection="column" gap="xs2">
      {tagProps && <Tag {...tagProps} size="medium" />}
      <div>
        <Typography variant="body3" weight={600} variantColor={theme.colors.neutral[30]}>
          {title}
        </Typography>
        <Typography variant="body4" weight={400} variantColor={theme.colors.neutral[60]}>
          {subtitle}
        </Typography>
      </div>
      <Typography variant="caption" weight={600} variantColor={theme.colors.neutral[50]}>
        {bottomText}
      </Typography>
    </Box>
  )
}
