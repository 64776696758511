import { z } from "zod";

export enum BuildEnvironments {
  Production = "production",
  Staging = "staging",
  Development = "development",
}

const EnvSchema = z.object({
  BUILD_ENV: z.nativeEnum(BuildEnvironments),
  LEADS_MEETINGS_SCHEDULER_URL: z.string().optional(),
  LEADS_CHAT_URL: z.string().optional(),
  BFF_URL: z.string(),
  PAYROLL_LOAN_PARTNER_URL: z.string(),
});

const env = EnvSchema.parse({
  BUILD_ENV: process.env.BUILD_ENV,
  LEADS_MEETINGS_SCHEDULER_URL: process.env.LEADS_MEETINGS_SCHEDULER_URL,
  LEADS_CHAT_URL: process.env.LEADS_CHAT_URL,
  BFF_URL: process.env.BFF_URL,
  PAYROLL_LOAN_PARTNER_URL: process.env.PAYROLL_LOAN_PARTNER_URL,
});

export default env;
