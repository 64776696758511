import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const AcronymContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: ${(props) => props.theme.colors.neutral[90]};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AcronymText = styled(Typography).attrs({ variant: "body4" })`
  font-weight: 700;
`;
