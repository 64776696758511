export type FeatureContent = {
  title: string
  description: string
  gifPath: string
}

export type FeatureNotification = {
  identifier: string
  modal: FeatureContent
  isLive: boolean
  order: number
}

export type FeatureController = {
  shouldShow: boolean
  identifier: string
}

export const features: FeatureNotification[] = [
  {
    identifier: "list-view",
    isLive: false,
    order: 2,
    modal: {
      title: "Admissão em lista",
      description:
        "Agora, com a visualização em lista, acompanhar o fluxo de admissão nunca foi tão simples e eficiente.",
      gifPath: "featureNotification/feature_notification_list_view.gif",
    },
  },
  {
    identifier: "add-mass-candidate",
    isLive: false,
    order: 1,
    modal: {
      title: "Cadastro de novos candidatos em massa",
      description:
        "Agora você pode adicionar vários candidatos de uma vez através do cadastro em massa.",
      gifPath: "featureNotification/add_mass_candidate.gif",
    },
  },
  {
    identifier: "mass-signature",
    isLive: false,
    order: 1,
    modal: {
      title: "Assinatura em lote",
      description:
        "Agora você pode assinar vários contratos de uma vez só! Simplifique seus processos, economize tempo e aumente a produtividade com nossa nova funcionalidade. Experimente agora!",
      gifPath: "featureNotification/mass-signature.gif",
    },
  },
  {
    identifier: "notifications",
    isLive: false,
    order: 1,
    modal: {
      title: "Fique por dentro de todos os detalhes com as notificações!",
      description:
        "Agora você receberá notificações por email sempre que um candidato for movimentado de etapa, quando houver novos comentários ou quando um prazo estiver prestes a expirar. Mantenha-se atualizado e não perca nenhum detalhe importante!",
      gifPath: "featureNotification/notifications.gif",
    },
  },
]
