import { ReactElement } from "react"
import { Droppable, DroppableProvided, DroppableStateSnapshot } from "react-beautiful-dnd"
import { useCardsListContext, useColumnContext } from ".."
import { Card } from "./Card"
import * as SC from "./styled"

export interface CardsListProps {
  children: ReactElement<typeof Card> | ReactElement<typeof Card>[]
}

export const CardsList = ({ children }: CardsListProps) => {
  const { isDragging } = useCardsListContext()
  const { id: columnId } = useColumnContext()

  return (
    <SC.CardsListWrapper isDragging={isDragging}>
      <Droppable type={CardsList.name} droppableId={columnId}>
        {(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
          <SC.DroppableSection
            isDraggingOver={dropSnapshot.isDraggingOver}
            ref={dropProvided.innerRef}
            {...dropProvided.droppableProps}
          >
            {children}
          </SC.DroppableSection>
        )}
      </Droppable>
    </SC.CardsListWrapper>
  )
}
