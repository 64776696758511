import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  border: 1px solid var(--color-neutral-90);
  padding: 24px;
  border-radius: 12px;

  > div:not(:last-child) {
    border-bottom: 1px solid var(--color-neutral-90);
  }
`;

const QuestionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 0;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export { Container, QuestionContainer, LabelContainer };
