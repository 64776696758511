import { useEffect, useState } from "react";
import { Checkout } from "../../components/Checkout";
import { trpc } from "../../api/client";
import dispatchToast from "../../utils/dispatchToast";
import {
  getS3Presigned,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { Authentication } from "@flash-hros/auth-helper";
const { getAccessToken } = Authentication;

interface ICreditCardData {
  name: string;
  documentNumber: string;
  cardNumber: string;
  dueDate: string;
  cvv: string;
}

interface IBillingData {
  emails: string[];
  street: string;
  complement: string;
  zipCode: string;
  state: string;
  district: string;
  city: string;
  number: string;
  phone: string;
}

export const CheckoutPage = () => {
  const [billingData, setBillingData] = useState<IBillingData>();
  const [creditCardData, setCreditCardData] = useState<ICreditCardData>();
  const [paymentMethod, setPaymentMethod] = useState<string>("creditCard");
  const [value, setValue] = useState<string | undefined>("");
  const [contractId, setContractId] = useState<any>("");
  const [html, setHtml] = useState<string | undefined>(undefined);
  const [pdf, setPdf] = useState<string>(
    "https://drive.google.com/file/d/10-PvCh4uy6eMGN9a2eUdDUalwaQF3ESz/preview"
  );
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);

  const { selectedCompany } = useSelectedCompany();

  const { data: contractsAndProducts, isLoading } =
    trpc.getContractsAndProducts.useQuery({
      businessUnity: "people",
    });

  const { data: contractSetup, isLoading: isLoadingSetup } =
    trpc.getContractSetup.useQuery();

  const updateContractSetup = trpc.updateContractSetup.useMutation({
    onSuccess: async () => {
      await getAccessToken({ companyId: selectedCompany.id, isAdmin: true });
      setOpenSuccessModal(true);
    },
    onError: () =>
      dispatchToast({
        content: "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
        type: "error",
      }),
  });

  const updateActiveContract = trpc.updateActiveContract.useMutation({
    onError: () =>
      dispatchToast({
        content: "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
        type: "error",
      }),
  });

  const createBilletCustomer = trpc.createBilletCustomer.useMutation({
    onSuccess: () => {
      updateActiveContract.mutate({
        contractId: contractId,
        body: {
          status: "active",
        },
      });
    },

    onError: () => {
      dispatchToast({
        content: "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
        type: "error",
      });
    },
  });

  const validateCreditCard = trpc.validateCreditCard.useMutation({
    onSuccess: () => {
      updateActiveContract.mutate({
        contractId: contractId,
        body: {
          status: "active",
        },
      });
    },

    onError: () => {
      dispatchToast({
        content: "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
        type: "error",
      });
    },
  });

  const contract = contractsAndProducts?.contract;
  const product = contractsAndProducts?.product;
  const productFinance = contractsAndProducts?.productFinance;
  const loading =
    isLoading ||
    isLoadingSetup ||
    validateCreditCard.isLoading ||
    createBilletCustomer.isLoading ||
    updateContractSetup.isLoading ||
    updateActiveContract?.isLoading;

  const formatPrice = (price: string) => {
    const splittedPrice = price?.split("");
    const number =
      splittedPrice[0] +
      splittedPrice[1] +
      "." +
      splittedPrice[2] +
      splittedPrice[3];
    const formatted = parseFloat(number)?.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formatted;
  };

  useEffect(() => {
    (async () => {
      if (contract?.length) {
        const peopleContract = contract?.find((c: any) =>
          c.product.some((p: any) => p.businessUnity == "people")
        );

        if (!peopleContract?._id) {
          dispatchToast({
            content:
              "Não foi possível localizar seu contrato. Tente novamente mais tarde.",
            type: "error",
          });
        }

        setContractId(peopleContract?._id);

        if (productFinance) {
          setValue(formatPrice(productFinance?.price));
        } else {
          setValue("");
        }

        const contractTemplateHtml = peopleContract?.contractTemplate?.find(
          (f: any) => f?.html != "" && f?.active != false
        );

        const contractTemplatePdf = peopleContract?.contractTemplate?.find(
          (f) => f?.url != null && f?.active != false
        );

        if (contractTemplateHtml?.html) {
          setHtml(contractTemplateHtml?.html);
        }

        if (contractTemplatePdf?.url?.path != null) {
          const s3 = await getS3Presigned({
            filename: contractTemplatePdf?.url?.path,
            module: "contract-manager",
          });
          setPdf(s3.url);
        }
      }
    })();
  }, [contract, product, productFinance]);

  useEffect(() => {
    switch (paymentMethod) {
      case "creditCard":
        if (creditCardData != undefined) {
          const date = creditCardData?.dueDate?.split("/");
          const body = {
            number: creditCardData?.cardNumber?.replace(/\s+/g, ""),
            holder_name: creditCardData?.name,
            exp_month: date?.[0],
            exp_year: date?.[1],
            cvv: creditCardData?.cvv,
            options: { verify_card: true },
            isCompanyAddress: false,
            billing_address: {
              line_1: `${billingData?.number}, ${billingData?.street}, ${billingData?.district}`,
              line_2: `${billingData?.complement}`,
              zip_code: `${billingData?.zipCode?.replace(/[^\d]+/g, "")}`,
              city: `${billingData?.city}`,
              state: `${billingData?.state}`,
              country: "BR",
            },
            phoneNumber: {
              number: `${billingData?.phone
                .split(/[()]/)[2]
                .replace(/[^\d]+/g, "")}`,
              area_code: `${billingData?.phone?.split(/[()]/)[1]}`,
            },
            documentNumber: creditCardData?.documentNumber?.replace(
              /[^\d]+/g,
              ""
            ),
            emails: billingData?.emails ?? [],
          };
          validateCreditCard.mutate({ body });
          updateContractSetup.mutate({
            finance: { paymentMethodSelected: "creditCard" },
          });
        }
        break;
      case "billet":
        if (billingData != undefined) {
          const body = {
            isCompanyAddress: false,
            billing_address: {
              line_1: `${billingData?.number}, ${billingData?.street}, ${billingData?.district}`,
              line_2: `${billingData?.complement}`,
              zip_code: `${billingData?.zipCode?.replace(/[^\d]+/g, "")}`,
              city: `${billingData?.city}`,
              state: `${billingData?.state}`,
              country: "BR",
            },
            phoneNumber: {
              number: `${billingData?.phone
                .split(/[()]/)[2]
                .replace(/[^\d]+/g, "")}`,
              area_code: `${billingData?.phone?.split(/[()]/)[1]}`,
            },
            emails: billingData?.emails ?? [],
          };
          createBilletCustomer.mutate({ body });
          updateContractSetup.mutate({
            finance: { paymentMethodSelected: "billet" },
          });
        }
        break;
    }
  }, [billingData, creditCardData]);

  return (
    <Checkout
      isSelection={true}
      setCreditCardData={setCreditCardData}
      setBillingData={setBillingData}
      price={value}
      template={html ? html : pdf}
      isPdf={html ? false : true}
      paymentMethod={paymentMethod}
      setPaymentMethod={setPaymentMethod}
      modules={contractSetup?.modules}
      openSuccessModal={openSuccessModal}
      setOpenSuccessModal={setOpenSuccessModal}
      loading={loading}
    />
  );
};
