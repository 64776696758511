import FeatureFlagService from '@frontend/services/FeatureFlagService';
import PlanFeaturesService from '@frontend/services/PlanFeatures';

export function useApprovalFlowEnable() {
  const approvals = FeatureFlagService.getFlag('approvals');

  const approvalFeatures = [
    'INTERMEDIATE_APPROVALS',
    'ADVANCED_APPROVALS',
  ] as const;

  return (
    approvals &&
    approvalFeatures.some((feature) => PlanFeaturesService.get(feature))
  );
}
