import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { SelectField, Skeleton, TextArea } from "$atoms";
import { ExpenseType } from "$serverTypes";
import { Container } from "./styled";

type ReasonSelectProps = {
  value?: string;
  isLoading?: boolean;
  onChange: (reason: { id: string; name: string }) => void;
  disabled?: boolean;
  error?: boolean;
  hideSelectField?: boolean;
  type: ExpenseType;
};

export const ReasonSelect = (props: ReasonSelectProps) => {
  const { t } = useTranslation("translations", { keyPrefix: `molecules.reasonSelect.${props.type}` });

  const reasons = [
    { id: "absenceOfReceipt", name: t("reasons.absenceOfReceipt") },
    { id: "invalidReceipt", name: t("reasons.invalidReceipt") },
    { id: "lateRefundRequest", name: t("reasons.lateRefundRequest") },
    { id: "suspectedFraud", name: t("reasons.suspectedFraud") },
    { id: "valueAboveWhatForeseenPolicy", name: t("reasons.valueAboveWhatForeseenPolicy") },
    { id: "other", name: t("reasons.other") },
  ];

  const isLoading = props.isLoading;
  const [isOtherSelected, setIsOtherSelected] = useState(props.value === "other");
  const [otherReasonText, setOtherReasonText] = useState("");

  if (isLoading) {
    return <Skeleton height={60} />;
  }

  const handleSelectChange = (_: unknown, option: { value: string }) => {
    if (option.value === "other") {
      setIsOtherSelected(true);
      setOtherReasonText("");
      props.onChange({ id: "other", name: "" });
    } else {
      setIsOtherSelected(false);
      const reasonSelected = reasons.find((reason) => reason.id === option.value);
      if (reasonSelected) {
        props.onChange(reasonSelected);
      }
    }
  };

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value;
    setOtherReasonText(text);
    props.onChange({ id: "other", name: text });
  };

  return (
    <Container>
      {!props.hideSelectField && (
        <SelectField
          error={props.error}
          id="reason"
          disabled={props.disabled}
          required={true}
          options={reasons.map((reason) => ({
            label: reason.name,
            value: reason.id,
          }))}
          onSelectChange={handleSelectChange}
          value={props.value ?? ""}
          fullWidth={true}
          label={t("label")}
        />
      )}
      {(isOtherSelected || props.hideSelectField) && (
        <TextArea
          id="otherReason"
          value={otherReasonText}
          onChange={handleTextAreaChange}
          aria-pressed
          placeholder={t("otherReasonLabel")}
          resizable
          maxLength={500}
        />
      )}
    </Container>
  );
};
