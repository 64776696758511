import { useCallback, useEffect } from "react";

export function useDebounce(effect: () => void, dependencies: React.DependencyList, delay: number) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(effect, dependencies);

  useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, [callback, delay]);
}
