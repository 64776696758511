import Flex from '@frontend/components/frames/Flex';
import NextStepCard from '@frontend/components/NextStepCard';
import CreateVirtualCardModal from '@frontend/pagesV2/user-accounts/create-virtual-card/CreateVirtualCardModal';
import { ROUTE_PlasticCards } from '@frontend/routes/ExternalRoutes';
import ModalService from '@frontend/services/ModalService';
import RouterService from '@frontend/services/RouterService';

export function NextStepCards() {
  const modalController = ModalService.useModalController();
  const navigate = RouterService.useNavigate();

  function showCreateVirtualCards() {
    modalController.remove();
    ModalService.show(CreateVirtualCardModal, {});
  }

  function showRequestCards() {
    modalController.remove();
    navigate({
      route: ROUTE_PlasticCards,
      params: {},
      searchParams: {},
      state: {},
    });
  }

  return (
    <Flex justify="space-between" gap="xs">
      <NextStepCard
        icon="IconDeviceMobile"
        title="Criar cartões virtuais"
        description="Mais segurança e controle para gastos com mobilidade e assinaturas digitais"
        action={{
          children: 'Criar cartões',
          onClick: showCreateVirtualCards,
          color: 'default',
          variant: 'primary',
          icon: 'IconArrowRight',
        }}
      />
      <NextStepCard
        icon="IconCreditCard"
        title="Pedir cartões físicos"
        description="Peça o cartão Flash para as pessoas da sua empresa que ainda não o tenham"
        action={{
          children: 'Fazer pedido',
          onClick: showRequestCards,
          color: 'default',
          variant: 'secondary',
          icon: 'IconArrowRight',
        }}
      />
    </Flex>
  );
}
