import styled from 'styled-components';

export const FeatureContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.xs3};
  align-items: flex-start;
`;

export const FeatureIconContainer = styled.div`
  padding-top: 2px;
`;
