import { PageContainer as DSPageContainer } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledPageContainer = styled(DSPageContainer)`
  padding: ${({ theme }) => theme.spacings.xl5};
  display: flex;
  flex-direction: column;
`;
export const MainBannerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export const PageDividerLine = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  margin: ${({ theme }) => theme.spacings.m} 0;
`;
