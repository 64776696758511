import { Box } from "@components/common/atoms/Box"
import { Flow, FlowSubcategory } from "@customTypes/flow"
import * as yup from "yup"
import {
  AssignmentTermCode,
  RemunerationBasisCode,
  WorkerGroups,
} from "@customTypes/candidate"
import i18n from "@i18n"
import { useFormik } from "formik"
import {
  SelectField,
  TextField,
  Toggle,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"
import { useTranslation } from "react-i18next"
import { FullWidthDiv } from "./styles"
import React from "react"

const flowSubcategoryTranslation: Record<FlowSubcategory, string> = {
  [FlowSubcategory.CLT]: i18n.t(
    "page.documentsValidation.payrollIntegration.translation.subcategory.clt",
  ),
  [FlowSubcategory.INTERNSHIP]: i18n.t(
    "page.documentsValidation.payrollIntegration.translation.subcategory.internship",
  ),
  [FlowSubcategory.PJ]: i18n.t(
    "page.documentsValidation.payrollIntegration.translation.subcategory.pj",
  ),
}

const remunerationBasisTranslation: Record<RemunerationBasisCode, string> = {
  [RemunerationBasisCode.COMMISSIONED]: i18n.t(
    "page.documentsValidation.payrollIntegration.translation.remunerationBasis.comissioned",
  ),
  [RemunerationBasisCode.HOURLY]: i18n.t(
    "page.documentsValidation.payrollIntegration.translation.remunerationBasis.hourly",
  ),
  [RemunerationBasisCode.MONTHLY]: i18n.t(
    "page.documentsValidation.payrollIntegration.translation.remunerationBasis.monthly",
  ),
  [RemunerationBasisCode.TASK_BASED]: i18n.t(
    "page.documentsValidation.payrollIntegration.translation.remunerationBasis.taskBased",
  ),
}

const assignmentTermTranslation: Record<AssignmentTermCode, string> = {
  [AssignmentTermCode.FIXED_TERM_CONDITION]: i18n.t(
    "page.documentsValidation.payrollIntegration.translation.assignmentTerm.condition",
  ),
  [AssignmentTermCode.FIXED_TERM_DAYS]: i18n.t(
    "page.documentsValidation.payrollIntegration.translation.assignmentTerm.days",
  ),
  [AssignmentTermCode.INDETERMINED]: i18n.t(
    "page.documentsValidation.payrollIntegration.translation.assignmentTerm.indetermined",
  ),
}

const workerGroupsTranslation: Record<WorkerGroups, string> = {
  [WorkerGroups.CLOCK_IN]: i18n.t(
    "page.documentsValidation.payrollIntegration.translation.workerGroups.clockIn",
  ),
  [WorkerGroups.CLOCK_IN_BY_EXCEPTION]: i18n.t(
    "page.documentsValidation.payrollIntegration.translation.workerGroups.clockInByException",
  ),
  [WorkerGroups.NO_CLOCK_IN]: i18n.t(
    "page.documentsValidation.payrollIntegration.translation.workerGroups.noClockIn",
  ),
  [WorkerGroups.NO_EFFECTIVENESS]: i18n.t(
    "page.documentsValidation.payrollIntegration.translation.workerGroups.noEffectiveness",
  ),
}

const validationSchema = yup.object({
  isYoungApprentice: yup.boolean().optional(),
  assignmentTermCode: yup
    .string()
    .oneOf(Object.values(AssignmentTermCode))
    .required(i18n.t("form.requiredField")),
  remunerationBasisCode: yup
    .string()
    .oneOf(Object.values(RemunerationBasisCode))
    .required(i18n.t("form.requiredField")),
  workerGroups: yup
    .string()
    .oneOf(Object.values(WorkerGroups))
    .required(i18n.t("form.requiredField")),
  standardHours: yup.number().required(i18n.t("form.requiredField")),
})

export type PayrollIntegrationFormType = {
  isYoungApprentice?: boolean
  assignmentTermCode?: AssignmentTermCode
  remunerationBasisCode?: RemunerationBasisCode
  workerGroups?: WorkerGroups
  standardHours?: number
}

export type PayrollIntegrationFormRef = ReturnType<
  typeof useFormik<PayrollIntegrationFormType>
>

interface PayrollIntegrationProps {
  flow: Flow
  initialValues: PayrollIntegrationFormType
}

export const PayrollIntegration = React.forwardRef(
  (
    { flow, initialValues }: PayrollIntegrationProps,
    ref: React.Ref<PayrollIntegrationFormRef>,
  ) => {
    const theme = useTheme()
    const [t] = useTranslation("translations", {
      keyPrefix: "page.documentsValidation.payrollIntegration",
    })

    const formik = useFormik<PayrollIntegrationFormType>({
      initialValues: {
        isYoungApprentice: initialValues.isYoungApprentice || false,
        assignmentTermCode: initialValues.assignmentTermCode || undefined,
        remunerationBasisCode: initialValues.remunerationBasisCode || undefined,
        workerGroups: initialValues.workerGroups || undefined,
        standardHours: initialValues.standardHours || undefined,
      },
      validationSchema: validationSchema,
      validateOnChange: false,
      onSubmit: async (values): Promise<PayrollIntegrationFormType> => values,
    })

    React.useImperativeHandle(ref, () => formik, [formik])

    return (
      <Box flexDirection="column" gap="xs" $width="100%">
        <div>
          <Typography
            variant="headline8"
            variantColor={theme.colors.neutral[30]}
          >
            {t("title")}
          </Typography>
          <Typography variant="body4" variantColor={theme.colors.neutral[50]}>
            {t("description")}
          </Typography>
        </div>

        <FullWidthDiv>
          <SelectField
            label={t("subcategoryLabel")}
            disabled={true}
            value={flow.subcategory}
            options={Object.values(FlowSubcategory).map((subcategory) => ({
              label: flowSubcategoryTranslation[subcategory],
              value: subcategory,
            }))}
          />
        </FullWidthDiv>

        <Box $width="100%" gap="xs4">
          <Toggle
            value={formik.values.isYoungApprentice}
            onChange={(_e, checked) => {
              formik.setFieldValue("isYoungApprentice", checked)
            }}
          />
          <Typography variant="body3" variantColor={theme.colors.neutral[30]}>
            {t("youngApprenticeLabel")}
          </Typography>
        </Box>

        <FullWidthDiv>
          <SelectField
            label={t("assignmentTermCodeLabel")}
            value={formik.values.assignmentTermCode}
            options={Object.values(AssignmentTermCode).map((code) => ({
              label: assignmentTermTranslation[code],
              value: code,
            }))}
            onSelectChange={(e, option) => {
              formik.setFieldValue("assignmentTermCode", option.value)
            }}
            error={!!formik.errors.assignmentTermCode}
            helperText={formik.errors.assignmentTermCode?.toString()}
          />
        </FullWidthDiv>

        <FullWidthDiv>
          <SelectField
            label={t("remunerationBasisCodeLabel")}
            value={formik.values.remunerationBasisCode}
            options={Object.values(RemunerationBasisCode).map((code) => ({
              label: remunerationBasisTranslation[code],
              value: code,
            }))}
            onSelectChange={(e, option) => {
              formik.setFieldValue("remunerationBasisCode", option.value)
            }}
            error={!!formik.errors.remunerationBasisCode}
            helperText={formik.errors.remunerationBasisCode?.toString()}
          />
        </FullWidthDiv>

        <FullWidthDiv>
          <SelectField
            label={t("workerGroupsLabel")}
            value={formik.values.workerGroups}
            options={Object.values(WorkerGroups).map((group) => ({
              label: workerGroupsTranslation[group],
              value: group,
            }))}
            onSelectChange={(e, option) => {
              formik.setFieldValue("workerGroups", option.value)
            }}
            error={!!formik.errors.workerGroups}
            helperText={formik.errors.workerGroups?.toString()}
          />
        </FullWidthDiv>

        <FullWidthDiv>
          <TextField
            type="number"
            label={t("standardHoursLabel")}
            value={formik.values.standardHours}
            onChange={(e) => {
              formik.setFieldValue("standardHours", e.target.value)
            }}
            fullWidth
            error={!!formik.errors.standardHours}
            helperText={formik.errors.standardHours?.toString()}
          />
        </FullWidthDiv>
      </Box>
    )
  },
)
