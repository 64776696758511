import {
  Breadcrumbs,
  Icons,
  LinkButton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Container, StyledPageContainer } from './overview.styles';
import { SummarySection } from '@/components/SummarySection';
import { CommercialContentsSection } from '@/components/CommercialContentsSection';
import { ResourcesSection } from '@/components/ResourcesSection';
import { SalesContactModal } from '@/components/Modals/SalesContactModal';
import { useEffect } from 'react';
import { trackPage } from '@/utils/tracking';
import { dispatchToast } from '@/utils';
import { LoadingState } from '@/components';
import { useTranslation } from 'react-i18next';
import { FaqSection } from '@/components/FaqSection';
import { useGetMarketPage } from '@/shared/hooks';

export const OverviewPage = () => {
  const [t] = useTranslation('translations', { keyPrefix: 'acquisition' });
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const id = params.get('id') || '';
  const origin = params.get('origin') || '';
  const { error, isLoading, marketPage } = useGetMarketPage(id);

  useEffect(() => {
    trackPage('acquisition_overview_page', { id, origin });
  }, [id]);

  useEffect(() => {
    if (!id || error) {
      dispatchToast({ type: 'error', content: 'Página não encontrada.' });
      navigate('/home');
    }
  }, [error]);

  const isFromAppStore = /app_store/.test(origin);
  const initialPageRoute = isFromAppStore ? '/acquisition/app-store' : '/home';
  const initialPageTitle = t(
    isFromAppStore ? 'breadcrumb.appStore' : 'breadcrumb.initialPage',
  );

  const onInitialPageClick = () => {
    navigate(initialPageRoute);
  };

  if (isLoading) {
    return <LoadingState />;
  }

  const breadcrumbs = [
    <LinkButton key="1" variant="neutral" onClick={onInitialPageClick}>
      {initialPageTitle}
    </LinkButton>,
    <Typography
      key="2"
      variant="body4"
      weight={700}
      variantColor="var(--color-neutral-40)"
    >
      {marketPage?.title}
    </Typography>,
  ];

  return (
    <StyledPageContainer>
      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        separator={<Icons name="IconChevronRight" />}
        style={{ height: 24 }}
      />
      <Container>
        <SummarySection marketPageId={id} />
        <CommercialContentsSection marketPageId={id} />
        <ResourcesSection marketPageId={id} />
        <FaqSection marketPageId={id} />
      </Container>
      <SalesContactModal />
    </StyledPageContainer>
  );
};
