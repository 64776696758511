import { AccordionProps as SCAccordionProps } from "@mui/material";
import { Accordion as StyledAccordion } from "./styled";

export type AccordionProps = {
  children: React.ReactNode;
  variant: "default" | "disabled";
  customHeader?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  expanded?: SCAccordionProps["expanded"];
  defaultExpanded?: SCAccordionProps["defaultExpanded"];
  onChange?: SCAccordionProps["onChange"];
  style?: React.CSSProperties;
  fullWidth?: boolean;
  selected?: boolean;
};

export const Accordion = (props: AccordionProps) => {
  return <StyledAccordion {...props} />;
};
