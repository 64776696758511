import { useCallback, useContext, useState } from 'react'
import { redirectToURL } from 'src/utils/redirectToURL'

import { EmployeeSelectionContext } from '../context'

export function useEmployeeSelection() {
  const context = useContext(EmployeeSelectionContext)

  if (!context) {
    throw new Error(
      'useEmployeeSelectionContext must be used within an EmployeeSelectionProvider',
    )
  }

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false)
  const [isBulkUpdateModalOpen, setIsBulkUpdateModalOpen] = useState(false)

  const openFilterDrawer = useCallback(() => {
    setIsFilterDrawerOpen(true)
  }, [])

  const closeFilterDrawer = useCallback(() => {
    setIsFilterDrawerOpen(false)
  }, [])

  const openBulkUpdateModal = useCallback(() => {
    setIsBulkUpdateModalOpen(true)
  }, [])

  const closeBulkUpdateModal = useCallback(() => {
    setIsBulkUpdateModalOpen(false)
  }, [])

  const redirectToEmployees = useCallback(() => {
    redirectToURL('/employees')
  }, [])

  return {
    ...context,
    isFilterDrawerOpen,
    isBulkUpdateModalOpen,
    openFilterDrawer,
    closeFilterDrawer,
    openBulkUpdateModal,
    closeBulkUpdateModal,
    redirectToEmployees,
  }
}
