import {
  Icons,
  tableControllers,
  Tag,
  Tooltip,
} from '@flash-tecnologia/hros-web-ui-v2';
import { PaginationState } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import { RouterOutputs } from '@frontend/trpc';
import { formatDate, formatTime, toCurrency } from '@frontend/utils/masks';
import { capitalizeWords } from '@frontend/utils/masks/capitalizeWords';
import { TABLE_COLUMNS } from '../../RequestBalanceSection/table/columns';
import * as styled from '../styled';
import { ActionButtons } from './ActionButtons';

type Output = RouterOutputs['corporateCard']['requestBalance']['search'];

export type Rows = Output['entries'][number];

export type TableProps = ReturnType<
  typeof tableControllers.useTableColumns<Rows>
>;

type Columns = Parameters<
  typeof tableControllers.useTableColumns<Rows>
>[number]['columns'];

type Props = {
  data: Array<Rows>;
  pagination: PaginationState;
  totalItems: number;
  selectable: boolean;
  type: 'approval' | 'deposit';
};

export function useTableColumns({
  data,
  pagination,
  totalItems,
  selectable,
  type,
}: Props) {
  const columns: Columns = [
    {
      accessorKey: 'createdAt',
      header: () => (
        <styled.Column>
          {TABLE_COLUMNS[4]}
          <Spacer x="xs4" />
          <Tooltip
            title="Você tem 15 dias para aprovar esse pedido, a partir da data de solicitação, ou ele será recusado automaticamente"
            arrow={true}
          >
            <div>
              <Icons name="IconInfoCircle" fill="transparent" color="#83727D" />
            </div>
          </Tooltip>
        </styled.Column>
      ),
      cell: ({ row }) => {
        return (
          <styled.Cell>
            <Typography.Body4 color="neutral_20">
              {formatDate(row.original.createdAt)}
            </Typography.Body4>
            <Typography.Caption color="neutral_40">
              {formatTime(row.original.createdAt)}
            </Typography.Caption>
          </styled.Cell>
        );
      },
    },
    {
      accessorKey: 'employee',
      accessorFn: (row) => ({
        name: capitalizeWords(row.employee.name),
        email: row.employee.email,
      }),
      header: () => (
        <styled.Cell>
          <Spacer x="xs" />
          {TABLE_COLUMNS[0]}
        </styled.Cell>
      ),
      cell: ({ cell }) => {
        const value = cell.getValue<{ name: string; email?: string }>();
        return (
          <styled.Cell>
            <Typography.Body4 color="neutral_20">{value.name}</Typography.Body4>
            <Typography.Caption color="neutral_40">
              {value.email}
            </Typography.Caption>
          </styled.Cell>
        );
      },
    },
    {
      accessorKey: 'reason',
      header: () => <styled.Cell>{TABLE_COLUMNS[1]}</styled.Cell>,
      cell: ({ row }) => {
        return <styled.Cell>{row.original.reason}</styled.Cell>;
      },
    },
    {
      accessorKey: 'value',
      header: () => <styled.Cell>{TABLE_COLUMNS[2]}</styled.Cell>,
      cell: ({ row }) => {
        return <styled.Cell>{toCurrency(row.original.value)}</styled.Cell>;
      },
    },
    {
      accessorKey: 'status',
      minSize: 200,
      header: () => <styled.Cell>{TABLE_COLUMNS[3]}</styled.Cell>,
      cell: ({ row }) => {
        const { label, value } = row.original.status;

        return (
          <Tag
            size="small"
            as="div"
            variant={getColorTag(value)}
            hasLeftDotIcon
          >
            {label}
          </Tag>
        );
      },
    },
    {
      accessorKey: 'creditDate',
      header: () => <styled.Cell>{TABLE_COLUMNS[5]}</styled.Cell>,
      cell: ({ row }) => {
        return <styled.Cell>{formatDate(row.original.creditDate)}</styled.Cell>;
      },
    },
    {
      id: 'actions',
      sticky: 'right',
      header: () => <styled.Cell>Ações</styled.Cell>,
      cell: ({ row }) => {
        return (
          <styled.Cell>
            <ActionButtons order={row.original} type={type} />
          </styled.Cell>
        );
      },
    },
  ];

  const approverColumns: Columns = [
    {
      accessorKey: 'requestBalance',
      header: () => <styled.Cell>{TABLE_COLUMNS[6]}</styled.Cell>,
      cell: ({ row }) => {
        const approvers =
          row.original.requestBalance.approvers?.filter(
            (status) => status.status === 'APPROVED',
          ) || [];

        const first2Approvers = approvers?.slice(0, 2);
        const remainingApprovers = approvers?.slice(2);

        return (
          <styled.ApproverBox gap="xs4">
            {first2Approvers.map((approver, index) => {
              return (
                <Tag
                  key={index}
                  size="small"
                  as="label"
                  variant="gray"
                  hasLeftDotIcon
                >
                  {approver.name}
                </Tag>
              );
            })}
            {remainingApprovers.length > 0 && (
              <Tooltip title={remainingApprovers.map((a) => a.name).join(', ')}>
                <Tag size="small" as="label" variant="gray" hasLeftDotIcon>
                  +{remainingApprovers.length}
                </Tag>
              </Tooltip>
            )}
          </styled.ApproverBox>
        );
      },
    },
  ];

  if (type === 'deposit') {
    columns.push(...approverColumns);
  }

  return tableControllers.useTableColumns<Rows>({
    total: totalItems,
    data,
    onPaginationChange: () => {},
    pagination: pagination,
    options: {
      selectable: selectable || undefined,
    },

    columns: columns,
  });
}

function getColorTag(
  status: Rows['status']['value'],
): Parameters<typeof Tag>[number]['variant'] {
  switch (status) {
    case 'expired':
    case 'cancelled':
    case 'reproved':
    case 'depositCancelled':
      return 'error';
    case 'paid':
      return 'success';
    default:
      return 'info';
  }
}
