import { ReactNode, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuItem } from "@mui/material";

import {
  DataGrid,
  Icons,
  dayjs,
  Pagination,
  Menu,
  PillButton,
  Tooltip,
  MenuOptionType,
} from "@flash-tecnologia/hros-web-ui-v2";

import { TableTag } from "@components/TableTag";
import { OverflowCheck } from "@components/OverflowCheck";

import { WordsConfirmationModal } from "@components/Modals";

import { trpc } from "@api/client";

import {
  dispatchToast,
  pageSizeOptions,
  StyledIcon,
  StyledText,
  track,
} from "@utils";

import { routes } from "@routes";

import {
  CellArea,
  DefaultCell,
  DeleteOption,
  DeleteWarning,
  ItensPerPageArea,
  Option,
  PaginationArea,
  PaginationContainer,
  StyledSelectField,
  StyledTypography,
  TagArea,
  TooltipText,
} from "./styled";

import type { TableGridCommonProps } from "@components/PaginationTable/types";

interface TOption extends MenuOptionType {
  onClick: any;
  children: ReactNode;
}

export const TableGrid = ({
  data,
  pagination,
  loading,
  onPaginationChanged,
}: TableGridCommonProps) => {
  const utils = trpc.useContext();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [cycleId, setCycleId] = useState("");

  const refetch = () => {
    utils.performance.cycle.getAllCycles.invalidate();
    utils.performance.cycle.getLastCycle.refetch();
  };

  const { mutate: deleteCycle, isLoading } =
    trpc.performance.cycle.deleteCycle.useMutation({
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: "Ciclo excluído com sucesso.",
        });

        refetch();
        setOpen(false);
        setCycleId("");
      },
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao deletar o Ciclo, tente novamente mais tarde.",
        });
      },
    });

  const renderOptions = ({
    _id,
    cycleStatus,
  }: {
    _id: string;
    cycleStatus: string;
  }) => {
    const itens = [
      {
        onClick: () => {
          track({
            name: "people_strategic_hr_performance_company_cycles_actions_viewdetails_clicked",
          });
          navigate(routes.PageCycleDetail(_id));
        },
        children: (
          <Option>
            <Icons name="IconDeviceDesktopAnalytics" fill="transparent" />
            <StyledText variant="body3" style={{ fontWeight: 600 }}>
              Ver detalhes do ciclo
            </StyledText>
          </Option>
        ),
      },
    ] as TOption[];

    if (cycleStatus != "closed") {
      itens.push({
        onClick: () => {
          navigate(routes.PageCreateCycle(_id));
        },
        children: (
          <Option>
            <Icons name="IconPencil" fill="transparent" />
            <StyledText variant="body3" style={{ fontWeight: 600 }}>
              Editar ciclo
            </StyledText>
          </Option>
        ),
      });
    }

    itens.push({
      onClick: () => {
        setOpen(true);
        setCycleId(_id);
      },
      children: (
        <DeleteOption>
          <StyledIcon name="IconTrash" fill="transparent" setColor="error50" />
          <StyledText
            variant="body3"
            style={{ fontWeight: 600 }}
            setColor="error50"
          >
            Excluir ciclo
          </StyledText>
        </DeleteOption>
      ),
    });

    return itens;
  };

  const columns = useMemo(
    () => [
      {
        Header: "Ciclo",
        disableSortBy: true,
        accessor: "name",
        Cell: ({ row: { original } }) => {
          const { name } = original;
          return (
            <CellArea>
              <TooltipText variant="body3">
                {OverflowCheck({ text: name })}
              </TooltipText>
            </CellArea>
          );
        },
      },
      {
        Header: "Status",
        disableSortBy: true,
        accessor: "cycleStatus",
        Cell: ({ row: { original } }) => {
          const { cycleStatus } = original;

          const labels = {
            scheduled: { label: "Ciclo agendado", color: "pink" },
            available: { label: "Ciclo ativo", color: "green" },
            closed: { label: "Ciclo encerrado", color: "gray" },
          };

          return (
            <DefaultCell>
              <TableTag
                label={labels[cycleStatus]?.label}
                variant={labels[cycleStatus]?.color}
                hasDot={true}
              />
            </DefaultCell>
          );
        },
      },
      {
        Header: "Etapas",
        disableSortBy: true,
        accessor: "steps",
        Cell: ({ row: { original } }) => {
          const { steps = [] } = original;

          const labels = {
            evaluation: { label: "Avaliação" },
            calibration: { label: "Calibração" },
            feedback: { label: "Feedback" },
            idp: { label: "PDI" },
          };

          const slicedSteps = steps.slice(0, 2);

          return (
            <TagArea>
              {slicedSteps?.map((step, index) => {
                return (
                  <TableTag
                    key={index}
                    label={labels[step?.type]?.label}
                    variant="gray"
                    hasDot={false}
                    style={{ marginRight: 8 }}
                  />
                );
              })}
              {steps.length > 2 ? (
                <Tooltip
                  title={steps?.map((step) => labels[step?.type]?.label + ", ")}
                >
                  <div style={{ cursor: "pointer" }}>
                    <TableTag label={`+${steps?.length - 2}`} variant="gray" />
                  </div>
                </Tooltip>
              ) : null}
            </TagArea>
          );
        },
      },
      {
        Header: "Início",
        disableSortBy: true,
        accessor: "startDate",
        Cell: ({ row: { original } }) => {
          const { startDate } = original;
          return (
            <StyledText variant="body3">
              {dayjs(startDate).format("DD/MM/YY")}
            </StyledText>
          );
        },
      },
      {
        Header: "Encerramento",
        disableSortBy: true,
        accessor: "endDate",
        Cell: ({ row: { original } }) => {
          const { endDate } = original;
          return (
            <StyledText variant="body3">
              {dayjs(endDate).format("DD/MM/YY")}
            </StyledText>
          );
        },
      },
      {
        Header: "Criado em",
        disableSortBy: true,
        accessor: "createdAt",
        Cell: ({ row: { original } }) => {
          const { createdAt } = original;
          return (
            <StyledText variant="body3">
              {dayjs(createdAt).format("DD/MM/YY")}
            </StyledText>
          );
        },
      },
      {
        Header: "Ações",
        accessor: "action",
        sticky: "right",
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
          const { _id, cycleStatus } = original;
          return (
            <Menu
              type={"select"}
              options={renderOptions({ _id, cycleStatus })}
              disableAutoFocusItem={true}
              anchorOrigin={{ vertical: "center", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <PillButton
                variant="default"
                size="small"
                type="secondary"
                icon="IconDotsVertical"
              />
            </Menu>
          );
        },
      },
    ],
    [data]
  );

  const { page, pageSize, totalCount } = pagination;

  return (
    <>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={data || []}
        loading={loading}
        initialState={{ pageSize: Number(pageSize) }}
        emptyState={{
          isFiltered: true,
          children: <div>Nenhum ciclo encontrado.</div>,
        }}
        pageSizeOptions={pageSizeOptions}
        customPagination={({ setPageSize }) => (
          <PaginationArea>
            <ItensPerPageArea>
              <StyledSelectField
                value={pageSize ?? 10}
                onChange={(e) => {
                  const newPageSize = Number(e?.target?.value);

                  setPageSize(newPageSize);
                  onPaginationChanged(page, newPageSize);
                }}
              >
                {pageSizeOptions?.map((opt) => (
                  <MenuItem key={opt?.value} value={opt?.value}>
                    {opt?.label}
                  </MenuItem>
                ))}
              </StyledSelectField>
              <StyledTypography variant="body3" style={{ fontWeight: 700 }}>
                Resultados:&nbsp;
                {page === 1 ? 1 : page * pageSize - pageSize + 1}-
                {page * pageSize > totalCount ? totalCount : page * pageSize}
                &nbsp;de&nbsp;{totalCount || 0}&nbsp;
                {totalCount === 1 ? "item" : "itens"}
              </StyledTypography>
            </ItensPerPageArea>
            <PaginationContainer>
              <Pagination
                defaultPage={1}
                page={page}
                count={Math.ceil(totalCount / pageSize)}
                onChange={(_, value) => {
                  onPaginationChanged(value, pageSize);
                }}
              />
            </PaginationContainer>
          </PaginationArea>
        )}
      />
      <WordsConfirmationModal
        open={open}
        wordToConfirm="EXCLUIR"
        showWarning
        icon="IconAlertCircle"
        title="Tem certeza que deseja excluir este ciclo?"
        customSubTitle={
          <StyledText
            variant="body3"
            setColor="neutral50"
            style={{
              textAlign: "center",
              marginBottom: "14px",
            }}
          >
            <b>
              <DeleteWarning>Importante:</DeleteWarning> Todos os dados{" "}
            </b>
            relacionados as <b>avaliações associadas</b> (caso existam) serão{" "}
            <b>perdidos</b> e essa ação não poderá ser desfeita.
            <br />
            <br />
            Os feedbacks e <b>PDIs</b> associados ao ciclo{" "}
            <b>não serão afetados</b> por essa ação e poderão ser encontrados em
            suas respectivas áreas.
          </StyledText>
        }
        confirm={{ title: "Confirmar exclusão", icon: "IconTrash" }}
        onClose={() => {
          setCycleId("");
          setOpen(false);
        }}
        onConfirm={() => {
          deleteCycle({
            cycleId: cycleId,
          });
        }}
        isLoading={isLoading}
      />
    </>
  );
};
