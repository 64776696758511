import { Outlet, Navigate, matchPath } from "react-router-dom";

import {
  getFromLS,
  usePermissions,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";

import { flatMap } from "lodash";

export const PermissionsRoutesGuard = ({
  children,
}: {
  children?: JSX.Element;
}) => {
  const isLoggedIn = getFromLS("hrosAccessToken");

  const { selectedCompany } = useSelectedCompany();

  const allPermissions = usePermissions();

  const companyPermissions = allPermissions?.companies?.find(
    (company) => company.id === selectedCompany.id
  );

  const permissions = companyPermissions?.permissions || [];

  const slugRoutes = [
    {
      slug: "core_contract_products",
      routes: ["/people/acquisition/checkout"],
    },
  ];

  const routesBySlug = slugRoutes.filter((route) =>
    permissions?.some(
      (permission) => permission === "*" || permission === route?.slug
    )
  );

  const routes = flatMap(routesBySlug, "routes");

  const isRouteAllowed = routes?.some((c) =>
    matchPath({ path: c, end: true }, location?.pathname)
  );

  if (!isLoggedIn) {
    return <Navigate to={"/authentication/login"} />;
  }

  if (!isRouteAllowed) {
    return <Navigate to={"/home"} />;
  }

  return children ? children : <Outlet />;
};
