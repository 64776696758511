import { Dispatch, useContext } from "react"
import {
  EnumGlobalActions,
  globalContext,
  IActions,
} from "../../../../../../context"
import {
  StyledDescription,
  StyledModal,
  StyledTitle,
  StyledButtonContainer,
  StyledLinkButton,
  StyledIconContainer,
  StyledIcon,
  StyledSectionContainer,
} from "./styles"

import {
  Button,
  IconButton,
  Loader,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import dispatchToast from "../../../../../../utils/dispatchToast"
import { modalAction } from "../../../../../../utils"
import { useQueryClient } from "@tanstack/react-query"
import { trpc } from "@api/client"

const RemoveTemplateModal = () => {
  const queryClient = useQueryClient()
  const { dispatch, modal } = useContext(globalContext)
  const { mutate: removeFormConfig, isLoading: isRemoving } =
    trpc.formConfig.removeFormConfig.useMutation()

  const handleSubmit = () => {
    removeFormConfig(
      { formConfigId: modal?.configId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries()

          dispatchToast({
            type: "success",
            content: "Template removido com sucesso!",
          })
          modalAction({
            param: { name: "removeTemplateModal", open: false },
            dispatch: dispatch as Dispatch<IActions<EnumGlobalActions>>,
          })
        },
        onError: (err: any) => {
          dispatchToast({
            type: "error",
            content: "Houve um problema ao remover template",
          })
        },
      },
    )
  }

  return (
    <StyledModal
      open={modal?.name === "removeTemplateModal" && modal?.open}
      onClose={() =>
        modalAction({
          param: { name: "removeTemplateModal", open: false },
          dispatch: dispatch as Dispatch<IActions<EnumGlobalActions>>,
        })
      }
      icon={
        <StyledIconContainer>
          <StyledIcon size={48} name="IconAlertCircle" fill="transparent" />
        </StyledIconContainer>
      }
    >
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
            marginTop: "46px",
            justifyContent: "space-between",
          }}
        >
          <StyledSectionContainer>
            <StyledTitle
              variant={"headline7"}
              style={{ marginTop: "16px", maxWidth: "308px" }}
            >
              Tem certeza que deseja excluir este template?
            </StyledTitle>
            <StyledDescription
              variant={"body3"}
              style={{ marginTop: "16px", maxWidth: "270px" }}
            >
              Todas as sessões contidas no template também serão excluídas.
            </StyledDescription>
          </StyledSectionContainer>
          <IconButton
            size="small"
            variant="line"
            icon="IconX"
            style={{ marginRight: "40px" }}
            onClick={() =>
              modalAction({
                param: { name: "removeTemplateModal", open: false },
                dispatch: dispatch as Dispatch<IActions<EnumGlobalActions>>,
              })
            }
          />
        </div>

        <StyledButtonContainer>
          <StyledLinkButton
            variant="primary"
            style={{ alignSelf: "center" }}
            onClick={() => {
              modalAction({
                param: { name: "removeTemplateModal", open: false },
                dispatch: dispatch as Dispatch<IActions<EnumGlobalActions>>,
              })
            }}
          >
            Cancelar
          </StyledLinkButton>
          <Button
            size={"large"}
            variant="primary"
            style={{ alignSelf: "center" }}
            onClick={handleSubmit}
            disabled={isRemoving}
          >
            {isRemoving ? (
              <Loader size="extraSmall" variant="secondary" />
            ) : (
              <>
                <Typography variant="body3" style={{ fontWeight: 700 }}>
                  Sim, excluir template
                </Typography>
              </>
            )}
          </Button>
        </StyledButtonContainer>
      </>
    </StyledModal>
  )
}

export { RemoveTemplateModal }
