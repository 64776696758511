import styled from "styled-components";

//TODO remover? não utilizado no PendingExpenseGrid.tsx
export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
`;

//TODO remover? não utilizado no PendingExpenseGrid.tsx
export const ActionButton = styled.div<{ show?: boolean }>`
  flex: float;
  display: ${({ show }) => ((show ?? true) ? "block" : "none")};
`;

export const AccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: ${(props) => props.theme.spacings.xs};
  padding-right: ${(props) => props.theme.spacings.xs2};
`;

export const AccordionHeaderCounters = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacings.l};
`;

//TODO remover? não utilizado no PendingExpenseGrid.tsx
export const ActionDetailCaption = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacings.xs4};
  align-items: center;
`;
