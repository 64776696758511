import { useChangeEmployeeCompany } from '@/hooks/useChangeEmployeeCompany';
import { useCreateEmployee } from '@/hooks/useCreateEmployee';
import { Button, Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import dispatchToast from '@utils/dispatchToast';
import { useCallback, useMemo, useState } from 'react';
import { FormTypeEnum, useForm } from '../../context/FormContext';
import { useFindEmployeeContacts } from '../../hooks/useFindEmployeeContacts';
import { useLastInvitation } from '../../hooks/useLastInvitation';
import { useRetry } from '../../hooks/useRetry';
import { ModalWarning } from '../ModalWarning';
import { StyledFooterContainer } from './styles';

const modalValues = {
  phone: {
    title: 'Já existe uma pessoa cadastrada com esse celular!',
    description:
      'O convite de primeiro acesso será enviado para o celular informado. Certifique-se de que a pessoa correta conseguirá acessá-lo para criar seu cadastro no app e plataforma da Flash.',
    submitText: 'Enviar convite mesmo assim',
  },
  email: {
    title: 'Já existe uma pessoa cadastrada com esse e-mail!',
    description:
      'O convite de primeiro acesso será enviado para o e-mail informado. Certifique-se de que a pessoa correta conseguirá acessá-lo para criar seu cadastro no app e plataforma da Flash.',
    submitText: 'Enviar convite mesmo assim',
  },
  both: {
    title: 'Já existe uma pessoa cadastrada com esse e-mail e celular!',
    description:
      'O convite de primeiro acesso será enviado para o e-mail informado. Certifique-se de que a pessoa correta conseguirá acessá-lo para criar seu cadastro no app e plataforma da Flash. ',
    submitText: 'Enviar convite mesmo assim',
  },
  documentNumber: {
    title:
      'Você tem certeza de que deseja duplicar o cadastro desta pessoa nesta empresa?',
    description:
      'Ela ficará vinculada a mais esta empresa, além das quais já está vinculada. Esta ação não é recomendada, pois gera cadastros duplicados, o que pode resultar na emissão de mais um cartão Flash e outros eventos relacionados à duplicidade de cadastro.',
    submitText: 'Sim, duplicar cadastro',
  },
  transfer: {
    title: 'Você tem certeza que deseja transferir a pessoa para esta empresa?',
    description:
      'Ela deixará de estar vinculada à empresa atual e passará a estar vinculada apenas a esta empresa',
    submitText: 'Sim, transferir',
  },
};

type FooterProps = {
  onClose: () => void;
  onCreateInviteCode: (code: string) => void;
};

export const Footer = ({ onClose, onCreateInviteCode }: FooterProps) => {
  const [openModalWarning, setOpenModalWarning] = useState(false);
  const [warningState, setWarningState] = useState<
    'email' | 'phone' | 'both' | 'documentNumber' | 'transfer'
  >('email');
  const {
    errors,
    employeeIdForTransfer,
    validateFields,
    form,
    invitationType,
    formType,
    disableEmailField,
    canMigrate,
    willMigrate,
    foundEmployee,
  } = useForm();

  const { selectedCompany } = useSelectedCompany();

  const { createEmployee, isLoading: createEmployeeLoading } =
    useCreateEmployee({
      options: {
        onError: () => {
          dispatchToast({
            type: 'error',
            title: 'Erro ao cadastrar pessoa!',
            content: `Por favor, tente novamente.`,
          });
        },
        onSuccess: (employee) => {
          if (invitationType === 'SCHEDULED' || disableEmailField) {
            dispatchToast({
              type: 'success',
              title: 'Pessoa cadastrada com sucesso!',
              content: `O cadastro da pessoa foi realizado com sucesso. Agora ela está disponível no sistema e pronta para futuras operações.`,
            });
            onClose();
            return;
          }
          startPolling({ employeeId: employee.id });
        },
      },
    });

  const { changeEmployeeCompany, isLoading: migrateEmployeeLoading } =
    useChangeEmployeeCompany({
      options: {
        onSuccess: () => {
          dispatchToast({
            type: 'success',
            title: 'Pessoa transferida com sucesso!',
            content:
              'O colaborador foi transferido da empresa de origem e cadastrada nesta empresa com sucesso!',
          });
          onClose();
        },
      },
    });

  const { getLastInvitation } = useLastInvitation();
  const { startPolling, loading: findInviteLoading } = useRetry({
    maxAttempts: 5,
    interval: 4000,
    stopCondition: (data) => {
      return !!data;
    },
    query: getLastInvitation,
    onCompleted: (data) => {
      onCreateInviteCode(data);
      onClose();
    },
  });

  const loading = useMemo(
    () => createEmployeeLoading || findInviteLoading || migrateEmployeeLoading,
    [createEmployeeLoading, findInviteLoading, migrateEmployeeLoading],
  );

  const handleSubmit = useCallback(async () => {
    const isValidInput = validateFields();

    if (!isValidInput) {
      return;
    }

    const employee = {
      name: form.name,
      email: form.email,
      phone: form.phone,
      documentNumber: form.documentNumber,
      invitationDate: form.invitationDate,
    };

    const payload = formType === FormTypeEnum.SIMPLE ? employee : form;

    if (canMigrate && willMigrate) {
      await changeEmployeeCompany(employeeIdForTransfer, selectedCompany.id);
      return;
    }

    await createEmployee(payload);
  }, [canMigrate, willMigrate, form]);

  const { findEmployeeContacts } = useFindEmployeeContacts({
    onFound: (foundContact) => {
      const isValidInput = validateFields();
      if (!isValidInput) {
        return;
      }
      setWarningState(foundContact);
      setOpenModalWarning(true);
    },
    onNotFound: async () => {
      await handleSubmit();
    },
  });

  const hasErrors = Object.values(errors).filter((error) => !!error);

  const disableButton = useMemo(
    () => hasErrors.length > 0 || loading,
    [hasErrors, loading],
  );

  const submitText = useCallback(() => {
    if ((canMigrate && !willMigrate) || (!canMigrate && foundEmployee)) {
      return loading
        ? 'Duplicando...'
        : 'Duplicar o cadastro desta pessoa para esta empresa';
    }
    if (canMigrate && willMigrate) {
      return loading
        ? 'Transferindo...'
        : 'Transferir pessoa para esta empresa';
    }
    return loading ? 'Enviando...' : 'Enviar';
  }, [loading, canMigrate, foundEmployee, willMigrate]);

  return (
    <StyledFooterContainer>
      <LinkButton onClick={() => onClose()} variant="neutral">
        Cancelar
      </LinkButton>
      <Button
        onClick={async () => {
          if ((canMigrate && !willMigrate) || (!canMigrate && foundEmployee)) {
            setWarningState('documentNumber');
            setOpenModalWarning(true);
            return;
          }
          if (canMigrate && willMigrate) {
            setWarningState('transfer');
            setOpenModalWarning(true);
            return;
          }
          findEmployeeContacts(form.phone, form.email);
        }}
        disabled={disableButton}
        loading={loading}
        variant="primary"
      >
        {submitText()}
        {!loading && <Icons name="IconArrowRight" />}
      </Button>
      <ModalWarning
        isOpen={openModalWarning}
        onClose={() => setOpenModalWarning(false)}
        onSubmit={() => {
          handleSubmit();
          setOpenModalWarning(false);
        }}
        title={modalValues[warningState].title}
        description={modalValues[warningState].description}
        submitText={modalValues[warningState].submitText}
      />
    </StyledFooterContainer>
  );
};
