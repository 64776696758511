import { SearchField as DSSearchField } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const SearchField = styled(DSSearchField)`
  width: 48%;
  /* height: 40px; */
  border: 1px solid ${(props) => props.theme.colors.neutral[90]};
  border-radius: 30px;

  &::hover {
    border: 1px solid ${(props) => props.theme.colors.neutral[90]};
    border-radius: 30px;
  }

  .Mui-focused:not(label) {
    border: 1px solid ${(props) => props.theme.colors.primary};
  }

  .MuiFilledInput-root {
    border: 1px solid ${(props) => props.theme.colors.neutral[90]};
    border-radius: 30px;
  }

  .MuiFilledInput-root:hover:not(.MuiInputLabel-shrink):not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) {
    border: 1px solid ${(props) => props.theme.colors.neutral[90]};
    border-radius: 30px;
  }
`;

// height 58; padding-top:19
// height 40; padding-top: 13
