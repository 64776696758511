import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledActions = styled.div`
  padding: 4px 8px;
  border-radius: 80px;
  border: solid 1px ${({ theme }) => theme.colors.neutral[90]};
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const StyledIcon = styled(Icons)`
  cursor: pointer;
`;
