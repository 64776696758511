import styled from "styled-components";

export const SummaryCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: ${(props) => props.theme.spacings.xs};
  margin-bottom: ${(props) => props.theme.spacings.s};
`;

export const ExpenseCardsContainer = styled.div`
  flex: 1;
  min-width: 300px;

  @media (max-width: ${(props) => props.theme.breakpoints.values.xl}px) {
    flex: 100%;
  }
`;
