import { Tag } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import Typography from '@frontend/components/Typography';
import FeatureFlagService from '@frontend/services/FeatureFlagService';
import { trpc } from '@frontend/trpc';
import { Outlet } from 'react-router-dom';
import { KnowBalanceRequest } from '../KnowBalanceRequest/KnowBalanceRequest';
import usePendencyCounter from './data/usePendencyCounter';
import RequestBalanceSection from './RequestBalanceSection';

function RequestBalanceTab() {
  const pendencyCounter = usePendencyCounter();
  const approvals = FeatureFlagService.getFlag('approvals');
  const { data, isLoading } = trpc.corporateCard.requestBalance.search.useQuery(
    {
      filter: {},
      pagination: { currentPage: 0, pageSize: 1 },
    },
  );
  const totalPendencies = pendencyCounter.totalPendencies || 0;

  const hasRequestBalance =
    data?.pagination?.totalItems && data.pagination.totalItems > 0;

  if (!approvals) {
    return {
      component: <RequestBalanceSection />,
      label: `Pedidos de saldo (${totalPendencies})`,
      shouldShow,
    };
  }

  return {
    component:
      hasRequestBalance || isLoading ? <Outlet /> : <KnowBalanceRequest />,
    label: (
      <Flex gap="xs4" align="center">
        <Typography.Body4>
          Pedidos de saldo
          {!hasRequestBalance ? '' : ` (${totalPendencies})`}
        </Typography.Body4>
        {!hasRequestBalance && !isLoading && (
          <Tag variant="primary" as="p" size="extra-small">
            Conheça!
          </Tag>
        )}
      </Flex>
    ),
    shouldShow,
  };
}

function shouldShow() {
  return true;
}

export { RequestBalanceTab, shouldShow };
