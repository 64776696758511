import styled from "styled-components";

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacings.xs};
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 40px;
  padding: 8px 67px;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid ${(props) => props.theme.colors.neutral[90]};
  background: ${(props) => props.theme.colors.neutral[100]};
`;
