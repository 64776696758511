import { Box, Icon, IconProps } from '@components';

import { Label } from './styled';

type BoxProps = React.ComponentProps<typeof Box>;

type Props = Partial<Pick<BoxProps, 'width' | 'padding'>> & {
  label: string;
  children?: React.ReactNode;
  icon?: IconProps['name'];
  isLineThrough?: boolean;
  variant?: 'neutral' | 'success' | 'error';
};

export function Details({ label, children, ...props }: Props) {
  return (
    <Box {...props}>
      <Box
        display="grid"
        gridTemplateColumns={props.icon ? '24px auto' : '1fr'}
        alignItems="center"
        gap="xs4">
        {props.icon && (
          <Icon
            name={props.icon}
            size={24}
            {...mappedVariantToColor[props.variant ?? 'neutral']}
          />
        )}

        <div>
          <Label isLineThrough={props.isLineThrough}>{label}</Label>

          <div>{children}</div>
        </div>
      </Box>
    </Box>
  );
}

const mappedVariantToColor: Record<
  Props['variant'],
  { color: IconProps['color']; background: IconProps['background'] }
> = {
  neutral: {
    color: 'neutral.40',
    background: 'neutral',
  },
  success: {
    color: 'feedback.success.40',
    background: 'success',
  },
  error: {
    color: 'feedback.error.40',
    background: 'error',
  },
};
