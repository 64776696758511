import React, { useMemo } from "react";

import { ModalStyled } from "../styled";

type ModalContentProps = {
  children: React.ReactNode;
  loading?: boolean;
};

export const ModalContent = ({ children, loading = false, ...restProps }: ModalContentProps) => {
  const render = useMemo(() => {
    if (loading) {
      return null;
    }
    return children;
  }, [loading, children]);

  return (
    <ModalStyled {...restProps}>
      <div className="content-modal">{render}</div>
    </ModalStyled>
  );
};
