import { IconsProps } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import Icon from '@frontend/components/Icon';
import Typography from '@frontend/components/Typography';

type Props = {
  variant?: 'neutral' | 'error';
  icon: IconsProps['name'];
  title: string;
  description?: React.ReactNode;
};

export function SummaryDetailsItem(props: Props) {
  return (
    <Flex gap="xs4" align="center">
      <Icon
        name={props.icon}
        color={props.variant === 'error' ? 'error_40' : 'neutral_40'}
        background={props.variant === 'error' ? 'error' : 'neutral'}
        size={24}
      />

      <div>
        <Typography.Body4 color="neutral_20" weight={600}>
          {props.title}
        </Typography.Body4>

        {typeof props.description === 'string' ? (
          <Typography.Caption color="neutral_40">
            {props.description}
          </Typography.Caption>
        ) : (
          props.description
        )}
      </div>
    </Flex>
  );
}
