import { ROUTE_Employees } from '@frontend/routes/ExternalRoutes';
import RouterService from '@frontend/services/RouterService';
import { useMemo } from 'react';
import {
  ComboBoxField,
  ComboBoxFieldProps,
} from '../inputs/ComboBoxField/ComboBoxField';

type Employee = {
  id: string;
  name: string;
  documentNumber: string;
  alreadyActive: boolean;
};

type Props = {
  /**
   * Label render in input
   *
   * @default "Selecione uma ou mais pessoas"
   */
  label?: string;
  /** Input value */
  value: Set<string>;
  /** Employees to list in options */
  employees: Employee[];
  /** onChange call on user selected */
  onChange(value: Set<string>): void;
  /** show error bottom input */
  errorMessage?: string;
  /** employees loading */
  loading?: boolean;
  renderEmployeeDescription?(employee: Employee): string | undefined;
};

export function SelectEmployees(props: Props) {
  const navigate = RouterService.useNavigate();

  const employees: ComboBoxFieldProps['options'] = useMemo(
    () =>
      props.employees?.map?.((employee) => ({
        title: employee.name,
        value: employee.id,
        description: props.renderEmployeeDescription?.(employee),
        additionalFilterKey: employee.documentNumber,
        disabled: !employee.alreadyActive,
      })),
    [props.employees],
  );

  function goToEmployeesManagement() {
    navigate({
      route: ROUTE_Employees,
      params: {},
      searchParams: {},
      state: {},
    });
  }

  return (
    <ComboBoxField
      icon="IconUsers"
      label={props.label ?? 'Selecione uma ou mais pessoas'}
      onChange={(value) => props.onChange(value)}
      value={props.value}
      options={employees}
      error={props.errorMessage}
      loading={props.loading}
      emptyState={{
        allDisabled:
          'Todas as pessoas cadastradas em Equipe já usam cartão corporativo',
        filtered: {
          title: 'Nenhum resultado encontrado',
          description:
            'Garanta que as pessoas estejam cadastradas em Equipe para conseguir selecioná-las',
          linkButton: {
            label: 'Ir para Equipe',
            onClick: goToEmployeesManagement,
          },
        },
        unfiltered: {
          title: 'Nenhuma pessoa disponível',
          description:
            'Garanta que as pessoas estejam cadastradas em Equipe para conseguir selecioná-las',
          linkButton: {
            label: 'Ir para Equipe',
            onClick: goToEmployeesManagement,
          },
        },
      }}
    />
  );
}
