import { useState, useEffect } from "react";
// import { EmbeddedFinanceBillsPage } from "@flash-hros/finance";

import { StyledIcon, StyledTag, StyledText, StyledTitle } from "./styled";
import { Grid, Skeleton } from "@mui/material";

import { InfoBox } from "@components/InfoBox";
import { BillingBox } from "@components/BillingBox";
// import { BillingHistoric } from "@components/BillingHistoric";
import { SubscriptionBox } from "@components/SubscriptionBox";
import { SubscriptionDetailsBox } from "@components/SubscriptionDetailsBox";
import { EmailModal } from "@components/EmailModal";

import { trpc } from "../../api/client";

import {
  dispatchToast,
  getMonthEndDate,
  getMonthStartDate,
  getNextMonthFirstWeekday,
  segment,
  segmentPageTrack,
} from "@utils";
// import { useSearchParams } from "react-router-dom";
import { Bills } from "./components/BillsTable";
import { SubsidiaryModal } from "./components/SubsidiaryModal";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";

const BillingPagePeople = ({ tab = 0 }) => {
  // const [searchParams, setSearchParams] = useSearchParams();
  const [activeEmployee, setActiveEmployees] = useState<string>("0");
  const [showInfoBox, setShowInfoBox] = useState<boolean>(false);
  const [openEmailModal, setOpenEmailModal] = useState<boolean>(false);
  const [openSubsidiaryModal, setOpenSubsidiaryModal] =
    useState<boolean>(false);
  const [mainEmail, setMainEmail] = useState<string>("");
  const [emailsCc, setEmailsCc] = useState<any>([]);
  const [subscriptionStatus, setSubscriptionStatus] = useState<string>("");
  const [currentValue, setCurrentValue] = useState<string>("");
  const [monthEndDate, setMonthEndDate] = useState("");
  const [monthStartDate, setMonthStartDate] = useState("");
  const [firstUtilDate, setFirsUtilDate] = useState("");
  const [mostRecentInvoice, setMostRecentInvoice] = useState<any>();
  const [selectedSubsidiaryCompanies, setSelectedSubsidiaryCompanies] =
    useState<string[]>([]);
  const [acceptSubsidiaryTerms, setAcceptSubsidiaryTerms] =
    useState<boolean>(false);

  const { selectedCompany } = useSelectedCompany();

  const { data, isLoading } =
    trpc.getContractByCompanyAndGetBillingEmails.useQuery();

  const {
    data: subsidiaryCompanies,
    isError: isErrorCompanies,
    isLoading: isLoaddingCompanies,
  } = trpc.getCompanyAndContract.useQuery();

  if (isErrorCompanies) {
    dispatchToast({
      type: "error",
      content:
        "Sentimos muito, ocorreu um erro ao buscar as empresas do grupo. Tente novamente mais tarde",
    });
  }

  const contractStatus = data?.contractData?.contract?.status;
  const value = data?.contractData?.price;
  const paymentMethod = data?.contractData?.paymentMethod;
  const billingEmails = data?.billingEmails;
  const invoices = data?.invoices;
  const companyType = data?.companyType;

  const getOpenedInvoice = trpc.getOpenedInvoice.useMutation({
    onSuccess: (data) => {
      setActiveEmployees(data?.employeesQuantity || "0");
      setCurrentValue(data?.value || "00,00");
      setMonthEndDate(getMonthEndDate());
      setMonthStartDate(getMonthStartDate());
      setFirsUtilDate(getNextMonthFirstWeekday());
    },
    onError: () => ({}),
  });

  const createSubsidiaryContracts = trpc.createSubsidiaryContract.useMutation({
    onSuccess: async () => {
      setSelectedSubsidiaryCompanies([]);
      setAcceptSubsidiaryTerms(false);
      setOpenSubsidiaryModal(false);
      dispatchToast({
        content: "Filiais liberadas.",
        type: "success",
      });
    },
    onError: () =>
      dispatchToast({
        content:
          "Sentimos muito, ocorreu um erro ao liberar as filiais. Tente novamente mais tarde.",
        type: "error",
      }),
  });

  const loading = isLoading || getOpenedInvoice?.isLoading;

  // useEffect(() => {
  //   setSearchParams({ businessUnits: "PEOPLE" });
  // }, []);

  useEffect(() => {
    (async () => {
      getOpenedInvoice.mutate();

      const subscription = "Aberta" as unknown;
      switch (true) {
        case subscription == "Aberta":
          setSubscriptionStatus("subscriptionopen");
          break;
        case subscription === "Cancelada":
          setSubscriptionStatus("subscriptioncanceled");
          break;
        case subscription == "Fechada":
          setSubscriptionStatus("subscriptionclosed");
          break;
        case subscription == "Error":
          setSubscriptionStatus("subscriptionchargingerror");
          break;
      }

      // if (billingEmails) {
      //   const { value } = billingEmails?.find((e) => {
      //     return e?.type == "emailTo";
      //   });
      //   setMainEmail(value);

      //   const emailsCC = billingEmails?.filter((e) => {
      //     return e?.type == "emailCc";
      //   });

      //   setEmailsCc(emailsCC);
      // }

      // if (invoices) {
      //   const invoicesWithError = invoices?.find((invoice) => {
      //     return invoice?.status == "error";
      //   });
      //   setShowInfoBox(invoicesWithError);

      //   const invoicesFiltradas = invoices?.filter(
      //     (invoice) => invoice?.status !== "open"
      //   );

      //   if (invoicesFiltradas.length !== 0) {
      //     const invoiceMaisRecente = invoicesFiltradas?.reduce(
      //       (invoice1, invoice2) => {
      //         const data1 = new Date(invoice1?.createdAt);
      //         const data2 = new Date(invoice2?.createdAt);
      //         return data1 > data2 ? invoice1 : invoice2;
      //       }
      //     );
      //     setMostRecentInvoice(invoiceMaisRecente);
      //   }
      // }
    })();
  }, [billingEmails, invoices]);

  useEffect(() => {
    segmentPageTrack({
      name: "people_billing_page_view",
    });
  }, []);

  useEffect(() => {
    if (acceptSubsidiaryTerms) {
      segment({ track: "people_billing_subsidiaries_checkbox_clicked" });
    }
  }, [acceptSubsidiaryTerms]);

  const handleCreateSubscription = () => {
    if (!!selectedSubsidiaryCompanies?.length && acceptSubsidiaryTerms) {
      createSubsidiaryContracts.mutate({
        subsidiaryCompanies: selectedSubsidiaryCompanies,
        hasActiveOnUnleash: true,
      });
    } else if (
      !!selectedSubsidiaryCompanies?.length &&
      !acceptSubsidiaryTerms
    ) {
      dispatchToast({
        type: "error",
        content: "É necessário aceitar os termos para prosseguir.",
      });
    } else if (!selectedSubsidiaryCompanies?.length && acceptSubsidiaryTerms) {
      dispatchToast({
        type: "error",
        content: "Selecione alguma empresa para prosseguir.",
      });
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        {showInfoBox && (
          <>
            <Grid item md={12}>
              <InfoBox type={"pending"} setState={setShowInfoBox} />
            </Grid>
          </>
        )}
        <Grid item md={mostRecentInvoice ? 8 : 12} sm={12} xs={12}>
          {loading ? (
            <Skeleton width={"100%"} height="180px" />
          ) : (
            <SubscriptionDetailsBox
              type={contractStatus != "CANCELLED" ? "open" : "canceled"}
              employees={activeEmployee}
              price={currentValue}
              endDate={monthEndDate}
              billingDate={firstUtilDate}
              segmentTrack={subscriptionStatus}
              hasClosedInvoice={!!mostRecentInvoice}
            />
          )}
        </Grid>
        {mostRecentInvoice && (
          <Grid item md={4} sm={12} xs={12}>
            {loading ? (
              <Skeleton width={"100%"} height="180px" />
            ) : (
              <SubscriptionBox
                date={mostRecentInvoice?.dueDate}
                employees={mostRecentInvoice?.employees}
                price={mostRecentInvoice?.amount}
                type={"closed"}
                url={`invoice/${mostRecentInvoice?._id}`}
              />
            )}
          </Grid>
        )}
        <Grid item md={4} sm={6} xs={12}>
          {loading ? (
            <Skeleton width={"100%"} height="180px" />
          ) : (
            <BillingBox
              type={"subscription"}
              tab={tab}
              component={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "4px",
                  }}
                >
                  {value && (
                    <>
                      <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
                        {value}
                      </StyledTitle>
                      <StyledText variant="body3">por pessoa</StyledText>
                    </>
                  )}
                </div>
              }
            />
          )}
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          {loading ? (
            <Skeleton width={"100%"} height="180px" />
          ) : (
            <BillingBox
              type={
                !!contractStatus ||
                contractStatus == "WAITING_SIGNATURE" ||
                contractStatus == "trial" ||
                contractStatus == "trial_over"
                  ? "card"
                  : "emptyCard"
              }
              hasContract={
                !!contractStatus ||
                contractStatus == "WAITING_SIGNATURE" ||
                contractStatus == "trial" ||
                contractStatus == "trial_over"
              }
              component={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  {paymentMethod && (
                    <>
                      <ShapeIcon
                        name={
                          paymentMethod == "Cartão de crédito"
                            ? "IconCreditCard"
                            : "IconBarcode"
                        }
                        variant="default"
                        size={24}
                        color="#F20D7A"
                      />
                      <StyledText variant="body3" style={{ fontWeight: 600 }}>
                        {paymentMethod == "Cartão de crédito"
                          ? "Cartão de crédito"
                          : "Boleto bancário"}
                      </StyledText>
                    </>
                  )}
                </div>
              }
            />
          )}
        </Grid>
        {contractStatus == "ACTIVE" && companyType == "main" && (
          <Grid item md={4}>
            {loading ? (
              <Skeleton width={"100%"} height="180px" />
            ) : (
              <BillingBox
                type={"subsidiary"}
                setOpenModal={setOpenSubsidiaryModal}
                segmentClick={() =>
                  segment({ track: "people_billing_subsidiaries_edit_clicked" })
                }
              />
            )}
          </Grid>
        )}
        {/* <Grid item md={4}>
          {loading ? (
            <Skeleton width={"100%"} height="180px" />
          ) : (
            <BillingBox
              type={"email"}
              setOpenEmailModal={setOpenEmailModal}
              component={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    alignItems: "flex-start",
                  }}
                >
                  <StyledText variant="body3" style={{ fontWeight: 600 }}>
                    {mainEmail}
                  </StyledText>
                  {!emailsCc?.length ? (
                    <div></div>
                  ) : (
                    <StyledTag variant="gray">+{emailsCc?.length}</StyledTag>
                  )}
                </div>
              }
            />
          )}
        </Grid> */}
      </Grid>
      <Grid item md={12} style={{ marginTop: "40px" }}>
        {loading ? (
          <Skeleton width={"100%"} height="100%" />
        ) : (
          // <BillingHistoric
          //   segmentTrack={subscriptionStatus}
          //   invoices={invoices}
          // />
          // <EmbeddedFinanceBillsPage />
          <Bills />
        )}
      </Grid>

      <SubsidiaryModal
        setOpen={setOpenSubsidiaryModal}
        open={openSubsidiaryModal}
        subsidiaryCompanies={subsidiaryCompanies}
        setSelectedSubsidiaryCompanies={setSelectedSubsidiaryCompanies}
        selectedSubsidiaryCompanies={selectedSubsidiaryCompanies}
        setTermsChecked={setAcceptSubsidiaryTerms}
        termsChecked={acceptSubsidiaryTerms}
        companyName={selectedCompany?.name}
        handleCreateSubscription={() => handleCreateSubscription()}
        loading={createSubsidiaryContracts?.isLoading}
      />

      <EmailModal
        onOpen={openEmailModal}
        billingEmails={billingEmails}
        setOpenEmailModal={setOpenEmailModal}
      />
    </>
  );
};

export { BillingPagePeople };
