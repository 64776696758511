import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${(props) => props.theme.spacings.xs4};
  min-height: 184px;

  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};

  svg {
    margin-bottom: ${(props) => props.theme.spacings.xs4};
  }
`;
