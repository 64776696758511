import { combinedExpenseStatusMapping, ExpenseStatusCard, unifySummaries } from "$organisms";
import { ExpenseStatus, ExpenseType, Summary } from "$serverTypes";
import { useMemo } from "react";
import { ReimbursementContainer } from "./styled";
import { CombinedExpenseStatus } from "./types";

/**
 * Props for the ReimbursementStatusCardList component.
 */
type ReimbursementStatusCardListProps = {
  /** Summaries to display */
  summaries: Summary[];

  /** Combined statuses to filter */
  combinedStatuses?: CombinedExpenseStatus[];

  /** Current status to filter.*/
  status: ExpenseStatus[];

  /** Callback when status need to change */
  onStatusChange: (status: ExpenseStatus[]) => void;

  /** Expense type */
  type: ExpenseType;
};

export const ExpenseStatusCardList = ({
  summaries,
  combinedStatuses = [
    CombinedExpenseStatus.DRAFT_OR_REQUIRE_CHANGES,
    CombinedExpenseStatus.PENDING_ACCOUNTING,
    CombinedExpenseStatus.FINISHED,
    CombinedExpenseStatus.REJECTED,
  ],
  status,
  onStatusChange,
  type,
}: ReimbursementStatusCardListProps) => {
  const unified = useMemo(() => unifySummaries(summaries, combinedStatuses), [combinedStatuses, summaries]);

  function onSetIsFiltered(combinedStatus: CombinedExpenseStatus, isFiltered: boolean) {
    const statusToAddOrRemove = combinedExpenseStatusMapping[combinedStatus];

    // NOTE: in baseline 2024 this could be simplified using Set.prototype.union and Set.prototype.difference
    // ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set/union
    if (isFiltered) {
      // onStatusChange(new Set(status).union(new Set(statusToAddOrRemove)))
      onStatusChange([...new Set([...status, ...statusToAddOrRemove])]);
    } else {
      // onStatusChange(new Set(status).difference(new Set(statusToAddOrRemove)))
      onStatusChange(status.filter((item) => !statusToAddOrRemove.includes(item)));
    }
  }

  return (
    <ReimbursementContainer>
      {unified.map((summary) => (
        <ExpenseStatusCard
          type={type}
          key={summary.status}
          status={summary.status as CombinedExpenseStatus}
          count={summary.count}
          amount={summary.amount}
          value={combinedExpenseStatusMapping[summary.status].every((s) => status.includes(s))}
          onChange={(isFiltered) => onSetIsFiltered(summary.status, isFiltered)}
        />
      ))}
    </ReimbursementContainer>
  );
};
