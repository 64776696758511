import {
  Tag as TagUi,
  Tooltip,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import { useMemo } from 'react'

import { TagStackContainerStyled } from './styles'

type TagStackProps = {
  names: string[]
  maxName?: number
  noNameMessage: string
}

function formatTooltipContent(names: string[]) {
  return names.map((name, key) => <p key={key}>{name}</p>)
}

function trucateName(name: string) {
  const maxLength = 20

  return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name
}

export function TagStack({ names, maxName = 4, noNameMessage }: TagStackProps) {
  const extraNameTooltip = useMemo(
    () => formatTooltipContent(names.slice(maxName)),
    [names, maxName],
  )

  if (!names.length) {
    return (
      <TagStackContainerStyled>
        <TagUi variant="gray" as="span" hasLeftDotIcon>
          {noNameMessage}
        </TagUi>
      </TagStackContainerStyled>
    )
  }

  return (
    <TagStackContainerStyled>
      {names.slice(0, maxName).map((name, key) => {
        const truncatedName = trucateName(name)
        const isTruncated = truncatedName !== name

        return (
          <TagUi key={key} variant="gray" as="span">
            {isTruncated ? (
              <Tooltip title={name.length > 20 ? name : ''}>
                <div>{truncatedName}</div>
              </Tooltip>
            ) : (
              name
            )}
          </TagUi>
        )
      })}

      {names.length > maxName && (
        <TagUi variant="gray" as="span">
          <Tooltip title={extraNameTooltip}>
            <div className="icon">
              <Typography variant="caption" color="neutral.40" weight={700}>
                +{names.length - maxName}
              </Typography>
            </div>
          </Tooltip>
        </TagUi>
      )}
    </TagStackContainerStyled>
  )
}
