import styled from "styled-components"
import { LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { Accordion } from "@mui/material"
import { DeprecatedModal } from "../../../components"

const CloseRightContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-top: 21px;
  padding-right: 21px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    display: none;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    flex-direction: column;
    overflow: auto;
  }
`

export const AccordionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px 0px;

  :not(:last-child) {
    border-bottom: 1px solid #e8e3e6;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
`

const RightContainer = styled.div`
  width: 100%;

  overflow: hidden auto;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    overflow: visible;
  }
`

const Title = styled.span`
  font-family: Cabinet Grotesk;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #53464f;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`

const IconContainer = styled.div`
  display: inline-flex;
  cursor: pointer;
  padding: 16px;
  border: 1px solid #e8e3e6;
  border-radius: 50%;

  &:hover {
    opacity: 0.7;
  }
`

const ConfirmButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;

  background-color: #fb2a81;

  border-radius: 4px 16px 4px 4px;
  margin-top: 80px;
  margin-bottom: 50px;

  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    pointer-events: none;
  }

  p {
    color: #fff;
    font-weight: 700;
  }

  &:hover {
    opacity: 0.5;
  }
`

const CollapseContainer = styled.div<{ expanded: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;

  cursor: pointer;
  border: 1px solid #e8e3e6;
  border-radius: 8px;
  margin-top: 16px;

  ${({ expanded }) =>
    expanded
      ? "border-bottom: 0px; border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;"
      : ""}

  &:hover {
    opacity: 0.7;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const FlexSpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const PreviewDocContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  padding: 12px 24px 12px 12px;

  border: 1px solid #e8e3e6;
  border-radius: 4px;
  width: 50%;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    width: 100%;
  }
`

export const FullWidthDiv = styled.div`
  width: 100%;
`

const StyledPOneLine = styled.p<{ numberOfLines: number }>`
  display: block;
  display: -webkit-box;
  line-height: ${({ numberOfLines }) => numberOfLines};
  -webkit-line-clamp: ${({ numberOfLines }) => numberOfLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`

const AccordionChildren = styled.div`
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    text-align: left;
  }
`

const AccordionElement = styled.div`
  border-bottom: 1px solid #ebe5e9;
  :last-child {
    border-bottom: none;
  }
`

const SectionTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #856f7e;
  margin-bottom: 24px;
`

const SectionSubTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #856f7e;
  margin-bottom: 24px;
`

const PreviewTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 10px;
`

const PreviewImageContainer = styled.div<{ url?: string }>`
  width: 45px;
  height: 45px;
  background-color: #ccc;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
`

const PreviewDocWithoutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 24px 12px 12px;

  border: 1px solid #e8e3e6;
  border-radius: 4px;
  width: 50%;
  pointer-events: none;
  &:hover {
    opacity: 0.5;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    width: 100%;
  }
`

const ArchiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  :first-child {
    margin-top: 12px;
  }
`

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

const StyledTextArea = styled.textarea`
  resize: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 120px;
  border: 1px solid #b9acb4;

  padding: 16px;
  margin-top: 24px;

  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    background: transparent;

    width: 6px;
    height: 48px;
  }

  :focus {
    outline: none !important;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`

const StyledModal = styled(DeprecatedModal)`
  && {
    .modal-container {
      border-radius: 16px;
      max-width: 95vw;
      max-height: 95vh;
      overflow: hidden;
    }
    .modal-content-area {
      padding: 0px;
      width: 95vw;
      height: 95vh;
    }
  }
`

const ChildrenContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

const StyledAccordion = styled(Accordion)`
  &.MuiPaper-root {
    border: 1px solid #ebe6e9;
    border-radius: 8px;
    box-shadow: none;

    margin-bottom: 40px;
    &::before {
      background-color: transparent;
    }
  }
  .MuiButtonBase-root {
    padding: 40px 44px;
  }
`

const StyledContainerIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 10px;

  width: 48px;
  height: 48px;

  background: ${({ theme }) => theme.colors.neutral[95]};
  border-radius: 40px;
`

const AccordionTitle = styled(Typography)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral[30]};
`

const AccordionDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`

const ListContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 24px;
`

const OptionalBadge = styled.div`
  max-width: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 4px 12px;
  background: ${({ theme }) => theme?.colors?.primary};
  border-radius: 24px;

  margin-left: 0px;
  margin-bottom: 4px;

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #fff;
  }
`

const ShareText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`

const StyledLinkButton = styled(LinkButton)`
  && {
    &.MuiButtonBase-root {
      padding: 0px;
      color: ${({ theme }) => theme.colors.neutral[40]};

      &::before {
        border-bottom: 2px solid ${({ theme }) => theme.colors.neutral[40]} !important;
      }
    }
  }
`

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`

export {
  Container,
  RightContainer,
  IconContainer,
  CloseRightContainer,
  ConfirmButton,
  HeaderContainer,
  Title,
  CollapseContainer,
  FlexSpaceBetween,
  ContentContainer,
  AccordionChildren,
  AccordionElement,
  FlexColumn,
  SectionTitle,
  PreviewDocContainer,
  PreviewImageContainer,
  PreviewTextContainer,
  StyledPOneLine,
  PreviewDocWithoutContainer,
  SubContainer,
  StyledTextArea,
  StyledModal,
  ChildrenContainer,
  SectionSubTitle,
  StyledContainerIcon,
  AccordionDescription,
  AccordionTitle,
  StyledAccordion,
  ListContainer,
  ArchiveContainer,
  OptionalBadge,
  StyledLinkButton,
  ShareText,
}
