import { LinkButton as UIButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const LinkButton = styled(UIButton)<{ iconPosition: 'left' | 'right' }>`
  width: max-content;
  white-space: nowrap;
  flex-direction: ${({ iconPosition }) =>
    iconPosition === 'left' ? 'row-reverse' : 'row'};
  gap: 2px;
`;
