import { useEffect, useMemo, useState } from "react";

import { ShapeIcon } from "$atoms";
import { trpc } from "$client";
import { useDebounce } from "$frontend/shared/hooks/debounce";
import { getRandomStr } from "$frontend/utils";
import { Autocomplete, type Option } from "$molecules";
import { PlaceAutocomplete } from "$serverTypes";
import { AutocompleteInputChangeReason } from "@mui/material";
import { useTranslation } from "react-i18next";

type PropsType = {
  value?: PlaceAutocomplete;
  onChange: (value: PlaceAutocomplete | null, sessionToken: string) => void;
  error?: boolean;
};

function placeAutoCompleteToOption(placeAutocomplete: PlaceAutocomplete) {
  return {
    value: placeAutocomplete.placeId,
    label: placeAutocomplete.name,
  } satisfies Option;
}

export const EstablishmentSelect = (props: PropsType) => {
  const { t } = useTranslation("translations", { keyPrefix: "molecules.establishmentSelect" });
  const sessionToken = useMemo(() => getRandomStr(12), []);

  // input value to use in autcomplete
  const [inputValue, setInputValue] = useState("");
  // value to be debounced
  const [searchValue, setSearchValue] = useState("");
  // debounced from searchValue and used in trpc
  const [query, setQuery] = useState("");

  const { data, isFetching } = trpc.places.searchPlace.useQuery(
    {
      query,
      sessionToken,
    },
    {
      initialData: { results: props.value ? [props.value] : [] },
      refetchOnMount: false,
    },
  );

  const options = useMemo(() => {
    const options = data.results.map(placeAutoCompleteToOption);
    if (options.length === 0 && props.value) {
      // props.value were set and no results returned by the trpc
      return [placeAutoCompleteToOption(props.value)];
    }

    return options;
  }, [data, props.value]);

  useEffect(() => {
    if (props.value) {
      setInputValue(props.value.name);
    }
  }, [props.value]);

  useDebounce(() => setQuery(searchValue), [searchValue], 500);

  function getNoOptionDescription() {
    if (query == "") {
      return t("typeEstablishmentNameOrAddress");
    }
    if (options.length == 0) {
      return t("noEstablishmentFound");
    }
    return "";
  }

  function onSelectChange(value: Option | null): void {
    const placeAutocompleteSelected = data.results.find(({ placeId }) => placeId == value?.value) ?? null;

    props.onChange(placeAutocompleteSelected, sessionToken);
  }

  function onInputChange(_: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) {
    switch (reason) {
      case "clear":
        onSelectChange(null);
        return setInputValue("");

      case "input":
        setInputValue(value);
        return setSearchValue(value);
    }
  }

  return (
    <Autocomplete
      label={t("establishment")}
      loadingText={t("loading")}
      error={props.error}
      isLoading={isFetching || searchValue !== query}
      noOptionContent={{ title: getNoOptionDescription() }}
      renderIcon={(_) => <ShapeIcon icon="IconMapPin" size="extra_small" />}
      value={props.value?.placeId}
      options={options}
      onSelectChange={onSelectChange}
      filterOptions={() => options}
      onInputChange={onInputChange}
      inputValue={inputValue}
    />
  );
};
