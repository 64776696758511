import { Tag as BaseTag, TagProps } from '@flash-tecnologia/hros-web-ui-v2';

import { TagStatus } from './TagStatus';

type Props = {
  as?: 'button' | 'span' | 'div' | 'a' | 'label' | 'li' | 'p';
  children: React.ReactNode;
  /**
   * Boolean to loading left dot icon inside tag
   * @default false
   * @type boolean
   */
  hasLeftDotIcon?: boolean;
  variant?:
    | 'primary'
    | 'pink'
    | 'success'
    | 'green'
    | 'info'
    | 'blue'
    | 'error'
    | 'yellow'
    | 'progress'
    | 'purple'
    | 'negative'
    | 'red'
    | 'gray'
    | 'disabled';
  isDisabled?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
};
export function Tag(props: Props) {
  return (
    <BaseTag
      as={props.as}
      variant={props.variant ?? 'pink'}
      hasLeftDotIcon={props.hasLeftDotIcon}
      disabled={props.isDisabled}
      size={mappedSize[props.size] ?? mappedSize.md}>
      {props.children}
    </BaseTag>
  );
}

const mappedSize: Record<Props['size'], TagProps['size']> = {
  xs: 'extra-small',
  sm: 'small',
  md: 'medium',
  lg: 'large',
} as const;

Tag.Status = TagStatus;
