import { TextField } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";

import { centsToMoney } from "$frontend/utils/formatters";
import { ButtonGroup } from "./styled";

type Range = {
  min?: number;
  max?: number;
};

type SliderFieldProps = {
  min?: number;
  max?: number;
  onChange: (range: Range) => void;
};

export const MIN_SLIDER_FIELD_VALUE = -100_000_000_000;
export const MAX_SLIDER_FIELD_VALUE = 100_000_000_000;

export const SliderField = ({ min, max, onChange }: SliderFieldProps) => {
  const { t } = useTranslation("translations", { keyPrefix: "molecules.silderField" });
  const setMin = (value?: number) => onChange({ min: value, max });
  const setMax = (value?: number) => onChange({ min, max: value });
  return (
    <div>
      {/* TODO: (maybe) add slider  */}
      {/* <FlashSliderField
        fieldMask={(value) => getValueWithCurrency({ value })}
        value={value.min && value.max ? [value.min, value.max] : undefined}
        min={props.min}
        max={props.max}
        onChange={(_, values) => {
          if (values instanceof Array) {
            setValue({ min: values[0], max: values[1] });
          }
        }}
      /> */}
      <ButtonGroup>
        <NumericFormat
          decimalSeparator=","
          thousandSeparator="."
          decimalScale={2}
          prefix={"R$"}
          value={min && min !== MIN_SLIDER_FIELD_VALUE ? centsToMoney(min) : ""}
          onFocus={(event) => {
            event.target.select();
          }}
          customInput={TextField}
          fullWidth={true}
          label={t("min")}
          onValueChange={(values) => {
            if (values.floatValue) {
              setMin(Math.trunc(values.floatValue * 100));
            } else {
              setMin(undefined);
            }
          }}
        />
        <NumericFormat
          decimalSeparator=","
          thousandSeparator="."
          decimalScale={2}
          prefix={"R$"}
          value={max && max !== MAX_SLIDER_FIELD_VALUE ? centsToMoney(max) : ""}
          customInput={TextField}
          fullWidth={true}
          onFocus={(event) => {
            event.target.select();
          }}
          label={t("max")}
          onValueChange={(values) => {
            if (values.floatValue) {
              setMax(Math.trunc(values.floatValue * 100));
            } else {
              setMax(undefined);
            }
          }}
        />
      </ButtonGroup>
    </div>
  );
};
