import { useContext, useMemo } from 'react';
import useTranslate from '@locale/useTranslate';

import { SubscriptionContext } from '../context';
import { SAASContractInformation } from '../context/types/contractInformation';

type Props = {
  saasServiceContract?: SAASContractInformation;
};

const FULL_PLAN_NAME = 'Gestão completa de despesas' as const;

export function useSubscriptionPlan(props?: Props) {
  const t = useTranslate();

  const { saasServiceContract, isNewSAAS } = useContext(SubscriptionContext);

  const contract = props?.saasServiceContract || saasServiceContract;

  const isFull = useMemo(() => {
    const conditions = contract?.conditions || {};

    const hasFullPlanName = contract?.conditions?.plan?.toLowerCase() === FULL_PLAN_NAME.toLowerCase();

    return conditions.pricePerUser > 0 || hasFullPlanName;
  }, [contract]);

  const planName = useMemo(() => {
    if (!isFull) {
      return t('organisms.subscriptionDetails.plans.basic');
    } else {
      return t('organisms.subscriptionDetails.plans.advanced');
    }
  }, [isFull, t]);

  return {
    plan: contract?.conditions?.plan,
    planName,
    isFull,
    isNewSAAS,
  };
}
