import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  gap: 24px;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 30%;
`;

export const TitleContainer = styled.div`
  padding: 0px 40px;
`;
