import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const BoxContainer = styled.div<{ height: number }>`
  display: flex;
  margin-top: 20px;
  height: auto;
  width: 100%;
  overflow-x: auto;
  justify-content: center;
  align-items: center;

  .recharts-wrapper {
    width: 100% !important;
    height: ${({ height }) => height}px !important;

    > svg {
      overflow: visible;
    }
  }
`;

export const LabelStyle = styled(Typography)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral.dark.dark4};
`;
