import { Component } from "bff/src/types/hiring"
import {
  FieldConfig as BFFFieldConfig,
  FieldRule as BFFFieldRule,
  FormConfig as BFFFormConfig,
  FormConfigList as BFFFormConfigList,
  FormGroup as BFFFormGroup,
  CompareField,
  Comparison,
  ComparisonType,
  FieldType,
} from "bff/src/types/hiring/documents"
import { IDocumentValidation } from "./hiring"

export type IFormConfigList = BFFFormConfigList

export type IFieldRule = BFFFieldRule

export type IFieldConfig = BFFFieldConfig

export type IFormGroup = BFFFormGroup

export type IFormConfig = Omit<BFFFormConfig, "_id" | "formGroups"> & {
  _id?: string
  formGroups: IFormGroup[]
}

export { ComparisonType, FieldType }

export enum EmployeePiiCustomFieldTypeEnum {
  SHORT_TEXT = "SHORT_TEXT",
  LONG_TEXT = "LONG_TEXT",
  NUMBER = "NUMBER",
  DATE = "DATE",
  BOOLEAN = "BOOLEAN",
  ENUM = "ENUM",
  MULTI_ENUM = "MULTI_ENUM",
}

export const piiCustomFieldTypeToFieldTypeMap = {
  [EmployeePiiCustomFieldTypeEnum.BOOLEAN]: FieldType.Boolean,
  [EmployeePiiCustomFieldTypeEnum.DATE]: FieldType.Date,
  [EmployeePiiCustomFieldTypeEnum.ENUM]: FieldType.Text,
  [EmployeePiiCustomFieldTypeEnum.MULTI_ENUM]: FieldType.Array,
  [EmployeePiiCustomFieldTypeEnum.NUMBER]: FieldType.Number,
  [EmployeePiiCustomFieldTypeEnum.SHORT_TEXT]: FieldType.Text,
  [EmployeePiiCustomFieldTypeEnum.LONG_TEXT]: FieldType.Text,
}

export { Component } from "bff/src/types/hiring"

export const customFieldComponentNameMap = {
  [Component.ShortText]: "Texto curto",
  [Component.LongText]: "Texto longo",
  [Component.Number]: "Número",
  [Component.Link]: "Link",
  [Component.Attachment]: "Anexo",
  [Component.DatePicker]: "Data",
  [Component.TimePicker]: "Horário",
  [Component.Select]: "Seleção única em lista",
  [Component.MultiSelect]: "Seleção múltipla em lista",
  [Component.Checkbox]: "Checkbox",
}

export const piiCustomFieldTypeToComponentType = {
  [EmployeePiiCustomFieldTypeEnum.BOOLEAN]: Component.Checkbox,
  [EmployeePiiCustomFieldTypeEnum.DATE]: Component.DatePicker,
  [EmployeePiiCustomFieldTypeEnum.ENUM]: Component.Select,
  [EmployeePiiCustomFieldTypeEnum.MULTI_ENUM]: Component.MultiSelect,
  [EmployeePiiCustomFieldTypeEnum.NUMBER]: Component.Number,
  [EmployeePiiCustomFieldTypeEnum.SHORT_TEXT]: Component.ShortText,
  [EmployeePiiCustomFieldTypeEnum.LONG_TEXT]: Component.LongText,
}

export type IComparison = Comparison
export type ICompareField = CompareField

export interface BackboneField {
  _id: string
  name: string
  mask?: any
  sensitive: boolean
  order: number
  component: string
  type: string
  options?: any
  expires?: any
}

export interface BackboneSection {
  _id: string
  name: string
  module: string
  type: string
  order: number
  companyId?: string
  fields: BackboneField[]
}

export interface FieldValue extends IFieldConfig {
  value?: any
  candidateId: string
}

export interface ConfirmationData {
  candidateId: string
  formConfigId: string
  extensionId?: string
  candidateFields: FieldValue[]
  documentFields: ConfirmationDocumentsWithValue[]
  dependents?: ConfirmationDependent[]
}

export enum MaritalStatusEnum {
  Single = "single",
  Married = "married",
  DomesticPartnership = "domestic partnership",
  Divorced = "divorced",
  Separated = "separated",
  Widowed = "widowed",
}

export interface ConfirmationDependent {
  _id: any
  name: string
  active: boolean
  kinship: string
  birthDate: string
  maritalStatus: MaritalStatusEnum
  candidateId: string
  formConfig: string
  extensionId: string
  documents: ConfirmationDocumentsWithValue[]
}

export interface ConfirmationDocumentsWithValue {
  _id: string
  formConfigId: string
  extensionId?: string
  name: string
  title: string
  description?: string
  enabled: boolean
  fields: FieldValue[]
  validation: IDocumentValidation
}
