import AuthService, { CCPermissions } from '@services/AuthService';
import { Navigate, Outlet } from 'react-router-dom';

type Props = {
  permissions: CCPermissions | CCPermissions[];
  redirectTo?: string;
  children?: JSX.Element;
};

export function PermissionsGuard(props: Props) {
  const { hasPermission } = AuthService.usePermissions();

  if (!hasPermission(props.permissions)) {
    return <Navigate to={props.redirectTo ?? '/home'} />;
  }

  return props.children ?? <Outlet />;
}
