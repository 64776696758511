import FormService from '@frontend/services/FormService';
import { trpc } from '@frontend/trpc';
import { toCurrency } from '@frontend/utils/masks';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { approvalFlowSchema } from './schema';

export function useForm() {
  const { id } = useParams();
  const { data, isFetching } = trpc.approvalFlow.getById.useQuery(
    id as string,
    {
      enabled: !!id,
    },
  );

  const methods = FormService.useCreateForm(approvalFlowSchema, {
    mode: 'onChange',
    defaultValues: {
      documentType: 'ORDER',
      status: 'ACTIVE',
      criteria: [{ relationshipCondition: 'AND' }],
      condition: [],
    },
  });

  useEffect(() => {
    if (data) {
      methods.reset({
        ...data,
        criteria: data.criteria.map((c) => {
          if (c.type === 'EXPENSE_AMOUNT') {
            c.value = toCurrency(parseInt(c.value), true);
          }
          return c;
        }),
      });
    }
  }, [data, methods]);

  return { methods, isLoadingForm: isFetching };
}
