import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { t } from 'i18next'

import { WrapperTitleStyled } from './styles'

interface ITitle {
  children: string
}

export const Title = ({ children }: ITitle) => {
  return (
    <WrapperTitleStyled>
      <Typography variant="body3" weight={700} color="secondary.50">
        {t('modalAlertConfirm.attention')}
      </Typography>

      <Typography variant="headline6" color="neutral.20">
        {children}
      </Typography>
    </WrapperTitleStyled>
  )
}
