import styled from "styled-components";

export const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  padding-left: ${(props) => props.theme.spacings.xs4};
  padding-right: ${(props) => props.theme.spacings.xs4};
  padding-top: ${(props) => props.theme.spacings.xs4};
  padding-bottom: ${(props) => props.theme.spacings.xs4};
  justify-content: center;
  border-radius: 80px;
  border: 1px solid ${(props) => props.theme.colors.neutral[90]};
  display: flex;
  gap: ${(props) => props.theme.spacings.xs3};
`;

export const Item = styled.div<{ disabled: boolean; color?: string }>`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  &:hover {
    color: ${({ disabled, color, theme }) =>
      disabled ? theme.colors.neutral[80] : color || theme.colors.secondary[50]};
  }
  color: ${({ disabled, theme, color }) => (disabled ? theme.colors.neutral[80] : color || theme.colors.neutral[40])};
`;

export const MenuContainer = styled.div`
  > div.menu-base-container-custom-theme {
    display: flex;
  }
`;
