import {
  Typography,
  Skeleton as DSSkeleton,
} from "@flash-tecnologia/hros-web-ui-v2";
import { HeaderContainer, HeaderText, Subtitle } from "./styles";

type HeaderProps = {
  title: string;
  subtitle: string;
};

const Component = ({ title, subtitle }: HeaderProps) => {
  return (
    <HeaderContainer>
      <HeaderText>
        <Typography variant="headline6">{title}</Typography>
        <Subtitle>{subtitle}</Subtitle>
      </HeaderText>
    </HeaderContainer>
  );
};

const Skeleton = () => {
  return (
    <HeaderContainer>
      <HeaderText>
        <DSSkeleton animation="pulse" height={38} width={300} />
        <DSSkeleton animation="pulse" height={20} width={120} />
      </HeaderText>
    </HeaderContainer>
  );
};

const Header = {
  Component,
  Skeleton,
};

export { Header };
