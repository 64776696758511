import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { trpc } from "$client";
import { CategoriesList } from "$frontend/containers/category/CategoriesList/CategoriesList";
import { CategorySettingsProvider } from "$frontend/containers/category/context/category-settings";
import { CategoryFormModal } from "$molecules";
import { BasePage } from "$organisms";

const AdminCategoriesPage = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { mutateAsync: createCategory, isLoading: createCatLoading } = trpc.category.createCategory.useMutation();

  return (
    <CategorySettingsProvider>
      <BasePage
        header={{
          title: t("pages.categoriesList.title"),
          subTitle: t("pages.categoriesList.subtitle"),
          action: (
            <Button variant="primary" size="large" onClick={() => setIsOpen(true)} loading={createCatLoading}>
              {t("pages.categoriesList.headerAction")}
            </Button>
          ),
        }}
      >
        <CategoriesList />
        <CategoryFormModal isOpen={isOpen} onClose={() => setIsOpen(false)} onDone={createCategory} />
      </BasePage>
    </CategorySettingsProvider>
  );
};

export default AdminCategoriesPage;
