import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import React from 'react'
import { Grid } from 'src/components/grid'
import { useTheme } from 'styled-components'

export interface IInfoItemProps {
  label: string
  value: string
  variant?: 'primary' | 'secondary'
}

export const InfoItem: React.FC<IInfoItemProps> = ({
  label,
  value,
  variant = 'primary',
}) => {
  const theme = useTheme()
  return (
    <Grid.Item>
      <Typography
        variant="body3"
        weight={700}
        color="neutral.50"
        style={{ marginBottom: theme.spacings.xs4 }}
      >
        {label}
      </Typography>
      <Typography
        variant="headline7"
        weight={700}
        style={{
          color:
            variant === 'primary'
              ? theme.colors.secondary[50]
              : theme.colors.neutral[20],
        }}
      >
        {value}
      </Typography>
    </Grid.Item>
  )
}
