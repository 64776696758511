import { TextField, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledModalTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[10]};
`;

export const StyledModalSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledInput = styled(TextField)`
  width: 100%;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding-bottom: 20px;
`;

export const StyledContainerSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledSectionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const StyledSectionSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const StyledOptionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 16px;
`;

export const StyledFooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
