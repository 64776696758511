import { Icons, Tooltip, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from 'src/utils/mask'
import { useTheme } from 'styled-components'

import { TotalPartialContainerStyled } from './styles'

type TotalPartialProps = {
  amount: number
}

export function TotalPartial({ amount }: TotalPartialProps) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <TotalPartialContainerStyled>
      <div>
        <Typography variant="body3" color="neutral.30" weight={600}>
          {t('newOrder.orderSummary.totalPartial.title')}
        </Typography>

        <Tooltip
          title={t('newOrder.orderSummary.totalPartial.tooltip')}
          arrow
          placement="right"
        >
          <Icons name="IconHelp" size={16} color={theme.colors.neutral[30]} />
        </Tooltip>
      </div>

      <Typography variant="body3" color="secondary.50" weight={700}>
        {formatCurrency(amount)}
      </Typography>
    </TotalPartialContainerStyled>
  )
}
