import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const LoadingWrapper = styled.div`
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const Card = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs1};
  max-width: 560px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  padding: ${({ theme }) => theme.spacings.xs}
    ${({ theme }) => theme.spacings.s} ${({ theme }) => theme.spacings.m};
  margin-top: ${({ theme }) => theme.spacings.xs3};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 20px;
  }
`;

export const LeftColumn = styled.div``;
export const RightColumn = styled.div``;
export const CardContent = styled.div``;

export const CardItens = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.xs5};
`;

export const ConfirmationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacings.xs3};
`;

export const TermTextLink = styled.span`
  display: inline;
  text-decoration: underline;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary[50]};
  &:hover {
    cursor: pointer;
  }
`;

export const TermText = styled(Typography).attrs({
  variant: 'body3',
  color: 'neutral.50',
})`
  display: inline;
  font-weight: 700;
`;

export const CardTitle = styled(Typography).attrs({
  variant: 'headline9',
  color: 'brand.50',
})``;

export const CardDescription = styled(Typography).attrs({
  variant: 'body4',
  color: 'neutral.50',
})`
  margin-bottom: ${({ theme }) => theme.spacings.xs2};
`;

export const CardDescriptionTitle = styled(Typography).attrs({
  variant: 'body4',
  color: 'neutral.40',
})`
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.spacings.xs5};
`;
