import { useCallback, useMemo } from 'react'
import { useNewOrderStore } from 'src/domain/orders/pages/new-order-page/store/use-new-order-store'
import { useEventTracking } from 'src/utils/hooks/useEventTracking'

type UseOrderReviewSummaryProps = {
  setShowEmployeesOrderModal: (status: boolean) => void
}

export function useOrderReviewSummary({
  setShowEmployeesOrderModal,
}: UseOrderReviewSummaryProps) {
  const { trackEvent } = useEventTracking()

  const { order, isTopupCredit, orderSummary, selectedEmployees } =
    useNewOrderStore()

  const handleShowEmployeesOrderModal = useCallback(() => {
    trackEvent({
      name: 'FlashOS_BenefitOrders_Checkout_Review_ShowEmployees_Click',
    })

    setShowEmployeesOrderModal(true)
  }, [setShowEmployeesOrderModal, trackEvent])

  const benefits = useMemo(
    () =>
      orderSummary?.benefits?.map((benefit) => ({
        image: benefit.emoji,
        label: benefit.name,
        value: benefit.amount,
      })),
    [orderSummary?.benefits],
  )

  const fees = useMemo(
    () =>
      Number(orderSummary?.totalFee) > 0
        ? [
            {
              key: 'accountsFee',
              value: orderSummary?.totalFee ?? 0,
            },
          ]
        : undefined,
    [orderSummary?.totalFee],
  )

  const totals = useMemo(
    () =>
      orderSummary
        ? [
            {
              key: 'total',
              value: orderSummary.totalAmount,
            },
          ]
        : undefined,
    [orderSummary],
  )

  const employeesCount = useMemo(
    () => orderSummary?.employeeCount || selectedEmployees.length || 0,
    [orderSummary?.employeeCount, selectedEmployees.length],
  )

  return {
    benefits,
    fees,
    totals,
    employeesCount,
    handleShowEmployeesOrderModal,
    order,
    isTopupCredit,
  }
}
