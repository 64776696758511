import { Typography } from '@components';
import { format } from '@utils/format';
type Props = {
  date: Date;
};

export function Date(props: Props) {
  return (
    <div>
      <Typography.Body4 color="neutral.20" weight={600}>
        {format.date.format(props.date)}
      </Typography.Body4>

      <Typography.Body4 color="neutral.40">
        {format.date.hours(props.date)}
      </Typography.Body4>
    </div>
  );
}
