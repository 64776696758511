import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { Candidate, Flow, HiringCard } from "../../../../types"
import ProfilePicture from "../../ProfilePicture"

import {
  CandidateContainer,
  ProfileTitle,
  IconWrapper,
  InfoHeader,
} from "./styles"

import {
  Dayjs,
  dayjs,
  IconButton,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2"

import dispatchToast from "../../../../utils/dispatchToast"
import { HIRING_MOVE_COLUMN } from "../../../../api/mutations/hiring-card-move-column"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { request } from "../../../../api/client"
import EditCandidate from "./EditCandidate"
import { FlowSubcategory } from "bff/src/types/hiring"
import { ModalConfirmation } from "@components/common/ModalConfirmation"
import { hiringColumnsId } from "src/mock/board"
import {
  CandidateInfoList,
  InfoPropsWithouIdx,
  InfoByType,
  FlowType,
  InfoWrapper,
} from "./CandidateInfoList"

type ModalEdit = {
  isOpen: boolean
  candidate?: Candidate
  type?: FlowType
}

interface HiringInformationTabProps {
  card: HiringCard
  flow: Flow
}

function daysToExpire(date: Dayjs) {
  const days = date.diff(dayjs(), "day", true)
  if (days <= 0) return 0
  return Math.ceil(days)
}

const copyToClipboard = (value: string) => navigator.clipboard.writeText(value)

const HiringInformationTab = ({ card, flow }: HiringInformationTabProps) => {
  const [editModal, setEditModal] = useState<ModalEdit>({
    isOpen: false,
  })

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const [isArchiveModalOpen, setArchiveModalOpen] = useState(false)

  const { mutate: moveColumn, isLoading: isMoveColumnLoading } = useMutation(
    async ({ params }: { params: any }) => {
      await request(HIRING_MOVE_COLUMN, { params })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries()
      },
    },
  )

  const redirect = "/flows/hiring"

  const candidateGenericInfo: InfoPropsWithouIdx[] = React.useMemo(() => {
    let probationPeriodOption: InfoPropsWithouIdx[] = []

    const probationPeriod = card.candidate.probationPeriod
    if (
      !!probationPeriod?.option &&
      probationPeriod.option !== "no-period" &&
      !!probationPeriod?.firstDate
    ) {
      const hiringDate = dayjs(card.candidate.proposalLetterInfo?.hiringDate)
      const firstDate = dayjs(probationPeriod.firstDate)

      const expirationFirstDate = daysToExpire(firstDate)

      probationPeriodOption.push({
        value: t("personalInfo.probationaryPeriod.value", {
          replace: {
            initialDate: hiringDate.format("DD/MM/YYYY"),
            finalDate: firstDate.format("DD/MM/YYYY"),
          },
          interpolation: { escapeValue: false },
        }),
        tagValue:
          expirationFirstDate <= 0
            ? t("personalInfo.probationaryPeriod.expired")
            : expirationFirstDate <= 3
              ? t("personalInfo.probationaryPeriod.toBeExpired", {
                  replace: { days: expirationFirstDate },
                  interpolation: { escapeValue: false },
                })
              : undefined,
        text: t("personalInfo.probationaryPeriod.firstPeriodLabel"),
        icon: "IconCalendarPlus",
        disableButton: true,
        order: 10,
      })

      const secondDate = probationPeriod.secondDate
        ? dayjs(probationPeriod.secondDate)
        : undefined

      if (secondDate?.isValid()) {
        const expirationSecondDate = daysToExpire(secondDate)

        probationPeriodOption.push({
          value: t("personalInfo.probationaryPeriod.value", {
            replace: {
              initialDate: firstDate.format("DD/MM/YYYY"),
              finalDate: secondDate.format("DD/MM/YYYY"),
            },
            interpolation: { escapeValue: false },
          }),
          tagValue:
            expirationSecondDate <= 0
              ? t("personalInfo.probationaryPeriod.expired")
              : expirationSecondDate <= 3
                ? t("personalInfo.probationaryPeriod.toBeExpired", {
                    replace: { days: expirationSecondDate },
                    interpolation: { escapeValue: false },
                  })
                : undefined,
          text: t("personalInfo.probationaryPeriod.secondPeriodLabel"),
          icon: "IconCalendarPlus",
          disableButton: true,
          order: 11,
        })
      }
    } else {
      probationPeriodOption.push({
        value: t("personalInfo.probationaryPeriod.noValue"),
        text: t("personalInfo.probationaryPeriod.noPeriodLabel"),
        icon: "IconCalendarPlus",
        disableButton: true,
        order: 10,
      })
    }

    return [
      {
        value: card.candidate.name || "Não cadastrado",
        text: t("personalInfo.name"),
        icon: "IconUser",
        disableButton: !card?.candidate?.name,
        order: 0,
        onClick: () => copyToClipboard(card?.candidate?.name),
        buttonIcon: "IconCopy",
      },
      {
        value: card.candidate.email || "Não cadastrado",
        text: t("personalInfo.email"),
        icon: "IconMail",
        disableButton: !card?.candidate?.email,
        order: 1,
        onClick: () => copyToClipboard(card?.candidate?.email),
        buttonIcon: "IconCopy",
      },
      {
        value:
          card.candidate.phone?.replace(
            /(\d{2})(\d{2})(\d{5})(\d{4})/,
            "+$1 ($2) $3-$4",
          ) || "Não cadastrado",
        text: t("personalInfo.phone"),
        icon: "IconPhone",
        disableButton: !card?.candidate?.phone,
        order: 2,
        onClick: () =>
          window.open(
            `https://api.whatsapp.com/send?phone=${card?.candidate?.phone?.replace(/\W+/g, "")}`,
            "_blank",
          ),
        buttonIcon: "IconBrandWhatsapp",
      },
      {
        value:
          card.candidate?.documentNumber?.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/,
            "$1.$2.$3-$4",
          ) || "Não cadastrado",
        text: t("personalInfo.cpf"),
        icon: "IconId",
        disableButton: !card?.candidate?.documentNumber,
        order: 3,
        onClick: () => copyToClipboard(card?.candidate?.documentNumber),
        buttonIcon: "IconCopy",
      },
      {
        value: card.candidate?.proposalLetterInfo?.hiringDate
          ? dayjs(card.candidate.proposalLetterInfo?.hiringDate).format(
              "DD/MM/YYYY",
            )
          : "Não cadastrado",
        text: t("personalInfo.dateLabel"),
        icon: "IconCalendar",
        disableButton: !card?.candidate?.proposalLetterInfo?.hiringDate,
        order: 9,
        onClick: () =>
          copyToClipboard(
            card?.candidate?.proposalLetterInfo?.hiringDate ?? "",
          ),
        buttonIcon: "IconCopy",
      },
      ...probationPeriodOption,
    ]
  }, [])

  const candidateInfoByType: InfoByType = {
    clt: [],
    pj: [
      {
        value: card.candidate?.cnpj || "Não cadastrado",
        text: "CNPJ",
        icon: "IconReceipt",
        disableButton: !card?.candidate?.cnpj,
        order: 5,
        onClick: () => copyToClipboard(card?.candidate?.cnpj ?? ""),
        buttonIcon: "IconCopy",
      },
      {
        value: card.candidate?.legalName || "Não cadastrado",
        text: "Razão social",
        icon: "IconBuildingSkyscraper",
        disableButton: !card?.candidate?.legalName,
        order: 6,
        onClick: () => copyToClipboard(card?.candidate?.legalName ?? ""),
        buttonIcon: "IconCopy",
      },
    ],
    internship: [
      {
        value: card.candidate?.internship?.startDate
          ? dayjs(card.candidate?.internship?.startDate).format("DD/MM/YYYY")
          : "Não cadastrado",
        text: "Data prevista de início",
        icon: "IconCalendar",
        disableButton: !card?.candidate?.internship?.startDate,
        order: 5,
        onClick: () =>
          copyToClipboard(card?.candidate?.internship?.startDate ?? ""),
        buttonIcon: "IconCopy",
      },
    ],
  }

  const candidateInfo = [
    ...candidateGenericInfo,
    ...candidateInfoByType[flow.subcategory as FlowSubcategory],
  ]

  const archiveCandidate = () => {
    if (!flow.columns) {
      dispatchToast({
        content: "Houve um problema ao arquivar o candidato",
        type: "warning",
      })
      return
    }
    const { _id: archivedColumn } =
      flow.columns.find((column) => column.name === "Arquivado") || {}
    if (!archivedColumn) {
      dispatchToast({
        content: "Houve um problema ao arquivar o candidato",
        type: "warning",
      })
      return
    }

    moveColumn(
      {
        params: {
          flowCardId: card._id,
          newColumnId: archivedColumn,
          newPosition: 0,
          version: card.version,
        },
      },
      {
        onSuccess: () => {
          dispatchToast({
            content: t("hiring.candidate.messages.archiveSuccess.title"),
            description: t(
              "hiring.candidate.messages.archiveSuccess.description",
            ),
            type: "success",
          })
          queryClient.invalidateQueries()
          navigate(redirect)
        },
      },
    )
  }

  const handleEditCandidate = () => {
    setEditModal({
      isOpen: true,
      candidate: card.candidate,
      type: flow.subcategory,
    })
  }

  const hideArchiveButton =
    hiringColumnsId.archived.includes(card.columnId) ||
    hiringColumnsId.done.includes(card.columnId)

  return (
    <CandidateContainer>
      <InfoHeader>
        <ProfilePicture
          userName={card.candidate?.name}
          alt="Foto de perfil do usuario"
          style={{ marginTop: 30 }}
        />

        <ProfileTitle>{card.candidate?.name}</ProfileTitle>

        <IconWrapper>
          <Tooltip title="Editar Candidato">
            <span>
              <IconButton
                icon="IconPencil"
                variant="line"
                size="small"
                aria-label="Editar Candidato"
                onClick={handleEditCandidate}
                style={{ margin: "0 5px" }}
              />
            </span>
          </Tooltip>
          {!hideArchiveButton && (
            <Tooltip title="Arquivar Candidato">
              <span>
                <IconButton
                  icon="IconArchive"
                  variant="line"
                  size="small"
                  aria-label="Arquivar Candidato"
                  onClick={() => setArchiveModalOpen(true)}
                  style={{ margin: "0 5px" }}
                />
              </span>
            </Tooltip>
          )}
          <Tooltip title="Compartilhar Candidato">
            <span>
              <IconButton
                icon="IconShare"
                variant="line"
                size="small"
                aria-label="Compartilhar Candidato"
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href)
                  dispatchToast({
                    type: "success",
                    content: "Link do candidato copiado",
                  })
                }}
                style={{ margin: "0 5px" }}
              />
            </span>
          </Tooltip>
        </IconWrapper>
      </InfoHeader>
      <InfoWrapper>
        <CandidateInfoList
          data={candidateInfo.sort((a, b) => (a.order || 0) - (b.order || 1))}
        />
      </InfoWrapper>
      {editModal.isOpen && editModal.candidate && editModal.type && (
        <EditCandidate
          isOpen={true}
          handleClose={() =>
            setEditModal({
              isOpen: false,
              candidate: undefined,
            })
          }
          card={card}
          candidate={editModal.candidate}
          flow={flow}
        />
      )}
      <ModalConfirmation
        isOpen={isArchiveModalOpen}
        isLoading={isMoveColumnLoading}
        variant="error"
        title={t("hiring.modalConfirmArchive.title")}
        description={t("hiring.modalConfirmArchive.description")}
        cancelLabel={t("hiring.modalConfirmArchive.button.cancel")}
        confirmLabel={t("hiring.modalConfirmArchive.button.submit")}
        confirmIcon="IconArchive"
        onClose={() => setArchiveModalOpen(false)}
        onConfirm={async () => archiveCandidate()}
      />
    </CandidateContainer>
  )
}

export default HiringInformationTab
