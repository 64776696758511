import { useModalOTPActions } from '@flash-hros/hocs'
import { dayjs } from '@flash-tecnologia/hros-web-ui-v2'
import {
  getFromLS,
  legacyGetFromLS,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility'
import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { trpc } from 'src/api/client'
import { isBalanceInsufficient } from 'src/domain/checkout/presenters/checkout-method/components/flash-cash/flash-cash-balances'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'
import { dispatchToast } from 'src/utils/dispatchEvents'
import { useEventTracking } from 'src/utils/hooks/useEventTracking'

import { useNewOrderStore } from '../../../store/use-new-order-store'

export function useReviewDetails() {
  const { trackEvent, trackPage } = useEventTracking()
  const [params] = useSearchParams()

  const {
    isTopupCredit,
    order,
    setContinueButtonOptions,
    setBackButtonOptions,
    previousStep,
    orderSummary,
    flashCashBalances,
    setOrder,
    nextStep,
  } = useNewOrderStore()

  const company = useSelectedCompany()
  const otpRef = useModalOTPActions()

  const [showEmployeesOrderModal, setShowEmployeesOrderModal] = useState(false)
  const PIX_FOR_VIRTUAL_BENEFIT_ERROR_MESSAGE =
    'PIX_UNAVAILABLE_FOR_VIRTUAL_BENEFIT'

  const scheduleId = params.get('schedule')

  const { mutate, isLoading } = trpc.benefitOrders.confirmOrder.useMutation({
    onError: (error) => {
      trackEvent({
        name: 'Portal_BenefitsOrderByPlatformReview_Response',
        params: { type: 'error' },
      })
      // @to-do implements modal error
      dispatchToast({
        content:
          error.data?.errorCode === PIX_FOR_VIRTUAL_BENEFIT_ERROR_MESSAGE
            ? `${t('order.errors.pixForVirtualBenefits')}`
            : `${t('order.errors.generic')}`,
        type: 'error',
        description: error.message,
      })
    },
    onSuccess: (data) => {
      trackEvent({
        name: 'Portal_BenefitsOrderByPlatformReview_Response',
        params: { type: 'success' },
      })

      if (data.pixCode) {
        setOrder({ pixCode: data.pixCode })
      }

      nextStep()
    },
  })

  const isBalanceLowerThanTotal = useMemo(() => {
    const total = orderSummary
      ? orderSummary.totalAmount + orderSummary.totalFee
      : 0

    return flashCashBalances.some((balance) =>
      isBalanceInsufficient(balance, total),
    )
  }, [flashCashBalances, orderSummary])

  const isConfirmingPixOrder =
    isLoading && order?.paymentMethod === PaymentMethodEnum.PIX

  const confirmOrder = useCallback(
    (code?: string, authToken?: string) => {
      const { emailAssociated } = legacyGetFromLS('clientData')
      trackEvent({
        name: 'FlashOS_BenefitOrders_Checkout_Review_FinishOrder_Click',
      })
      mutate({
        id: order?.id ?? '',
        description: order?.receiptDescription ?? ('' as string),
        cnpj: company.selectedCompany.registrationNumber,
        creditDate: order?.depositDate?.toDate() ?? new dayjs.Dayjs().toDate(),
        paymentMethod: order?.paymentMethod ?? PaymentMethodEnum.BILLET,
        adminEmail: emailAssociated,
        scheduleId,
        ...(code && { confirmationCode: code }),
        ...(authToken && { authToken }),
      })
    },
    [
      company.selectedCompany.registrationNumber,
      mutate,
      order?.depositDate,
      order?.id,
      order?.paymentMethod,
      order?.receiptDescription,
      scheduleId,
      trackEvent,
    ],
  )

  const handleConfirm = useCallback(() => {
    if (!order?.id || !order?.depositDate) return

    if (order.paymentMethod === PaymentMethodEnum.FLASH_CASH) {
      if (isBalanceLowerThanTotal) {
        dispatchToast({
          content: `${t('order.errors.balance')}`,
          description: `${t('order.errors.balanceDescription')}`,
          type: 'warning',
        })
        return
      }

      otpRef.current?.openModalIfNeeded({
        onConfirmCallback: (confirmationCode: string) => {
          const token = getFromLS('hrosAccessToken')

          confirmOrder(confirmationCode, token.accessToken)
        },
      })

      return
    }

    confirmOrder()
  }, [confirmOrder, isBalanceLowerThanTotal, order, otpRef])

  const toggleEmployeesOrderModal = useCallback(() => {
    setShowEmployeesOrderModal(!showEmployeesOrderModal)
  }, [showEmployeesOrderModal, setShowEmployeesOrderModal])

  useEffect(() => {
    setContinueButtonOptions({
      text: t('newOrder.buttons.completeOrder'),
      onClick: handleConfirm,
      isLoading: isLoading || isConfirmingPixOrder,
      iconName: 'IconShoppingCart',
      iconPosition: 'left',
    })
  }, [handleConfirm, isConfirmingPixOrder, isLoading, setContinueButtonOptions])

  useEffect(() => {
    setBackButtonOptions({
      text: t('newOrder.buttons.back'),
      onClick: previousStep,
      iconName: 'IconArrowLeft',
      iconPosition: 'left',
      disabled: isLoading,
    })
  }, [isLoading, previousStep, setBackButtonOptions])

  useEffect(() => {
    if (!order) {
      return previousStep()
    }

    trackPage({ name: 'FlashOS_BenefitOrders_Checkout_Review_View' })
  }, [order, previousStep, trackPage])

  return {
    isTopupCredit,
    otpRef,
    isConfirmingPixOrder,
    showEmployeesOrderModal,
    setShowEmployeesOrderModal,
    toggleEmployeesOrderModal,
    order,
    orderSummary,
    flashCashBalances,
  }
}
