import { IconTypes, Icons, Skeleton } from "@flash-tecnologia/hros-web-ui-v2"
import {
  FieldContainer,
  FieldDescription,
  FieldTitle,
  FieldWrapper,
  InfoWrapper,
  ListWrapper,
  SmallTag,
  StyledPOneLine,
} from "./styles"
import Clipboard from "./Clipboard"

export interface IInfoValue {
  value: string
  idx: number
  hideCopyIcon?: boolean
}

const InfoValue: React.FC<IInfoValue> = ({ value, idx, hideCopyIcon }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <StyledPOneLine numberOfLines={1}>
      <FieldDescription variant="body3">{value}</FieldDescription>
    </StyledPOneLine>
    {!hideCopyIcon && <Clipboard value={value} idx={idx} />}
  </div>
)

export type FlowType = "clt" | "pj" | "internship"

export interface InfoProps extends IInfoValue {
  text: string
  icon?: IconTypes
  tagValue?: string
  loading?: boolean
  order?: number
}

export type InfoPropsWithouIdx = Omit<InfoProps, "idx">

export type InfoByType = {
  [key in FlowType]: InfoPropsWithouIdx[]
}

export const Info = ({
  icon,
  text,
  value,
  tagValue,
  hideCopyIcon,
  idx,
  loading,
}: InfoProps) => {
  return (
    <FieldContainer>
      {icon && (
        <Icons
          name={icon}
          fill="transparent"
          style={{
            marginRight: 16,
          }}
        />
      )}
      <FieldWrapper>
        <StyledPOneLine numberOfLines={1}>
          <FieldTitle variant="caption">{text}</FieldTitle>
        </StyledPOneLine>

        {loading ? (
          <Skeleton variant="rectangular" width={"100%"} height={"20px"} />
        ) : (
          <InfoValue value={value} idx={idx} hideCopyIcon={hideCopyIcon} />
        )}

        {tagValue ? <SmallTag marginTop={3}>{tagValue}</SmallTag> : <></>}
      </FieldWrapper>
    </FieldContainer>
  )
}

export type InfoListProps = {
  data: InfoPropsWithouIdx[]
  loading?: boolean
}

export const InfoList = ({ data, loading }: InfoListProps) => {
  return (
    <ListWrapper>
      {data.map((info, index) => (
        <Info
          key={`card-info-${index}`}
          idx={index}
          loading={loading}
          {...info}
        />
      ))}
    </ListWrapper>
  )
}

export default InfoList
export { InfoWrapper }
