import { PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import { useState } from 'react';

type Props = {
  textToCopy: string;
  copiedLabel: string;
  defaultLabel: string;
};

export function CopyButton({ textToCopy, copiedLabel, defaultLabel }: Props) {
  const [copied, setCopied] = useState(false);

  function handleCopy() {
    if (!textToCopy) return;

    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 5000);
    });
  }

  return (
    <PillButton
      icon={copied ? 'IconCheck' : 'IconCopy'}
      size="small"
      label={copied ? copiedLabel : defaultLabel}
      type="primary"
      variant={copied ? 'default' : 'pink'}
      disabled={copied}
      onClick={handleCopy}
      iconPosition="left"
    />
  );
}
