import { IconsProps, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  ButtonContainer,
  ButtonContentContainer,
  ButtonTextContainer,
  ButtonsContainer,
  MainContainer,
  StyledPageContainer,
} from './access-buttons.styles';
import { RoundIcon } from '../RoundIcon';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '@/utils/tracking';

export const AccessButtons = () => {
  const [t] = useTranslation('translations', {
    keyPrefix: 'appStore.accessButtons',
  });
  const buttons: AccessButtonProps[] = [
    {
      title: t('buttons.manageProducts.title'),
      description: t('buttons.manageProducts.description'),
      iconName: 'IconLicense',
      link: '/contract-manager/plan',
    },
    {
      title: t('buttons.managePayments.title'),
      description: t('buttons.managePayments.description'),
      iconName: 'IconCash',
      link: '/billing',
    },
  ];

  return (
    <StyledPageContainer>
      <MainContainer>
        <Typography variant="headline9" variantColor="var(--color-neutral-20)">
          {t('title')}
        </Typography>
        <ButtonsContainer>
          {buttons.map((button) => (
            <AccessButton {...button} />
          ))}
        </ButtonsContainer>
      </MainContainer>
    </StyledPageContainer>
  );
};

interface AccessButtonProps {
  iconName: IconsProps['name'];
  title: string;
  description: string;
  link: string;
}

const AccessButton = (props: AccessButtonProps) => {
  const navigate = useNavigate();
  const onButtonClick = () => {
    trackEvent('acquisition_app_store_access_button_clicked', {
      link: props.link,
    });
    navigate(props.link);
  };
  return (
    <ButtonContainer onClick={onButtonClick}>
      <ButtonContentContainer>
        <RoundIcon
          iconName={props.iconName}
          size={16}
          iconColor="var(--color-secondary-50)"
          backgroundColor="var(--color-secondary-90)"
        />
        <ButtonTextContainer>
          <Typography
            variant="headline10"
            variantColor="var(--color-neutral-30)"
          >
            {props.title}
          </Typography>
          <Typography variant="caption" variantColor="var(--color-neutral-40)">
            {props.description}
          </Typography>
        </ButtonTextContainer>
      </ButtonContentContainer>
      <RoundIcon
        iconName="IconArrowRight"
        size={16}
        iconColor="var(--color-neutral-40)"
        backgroundColor="var(--color-neutral-95)"
      />
    </ButtonContainer>
  );
};
