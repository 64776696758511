import { Box } from "@atoms"
import { Icons, ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2"
import { ReactElement } from "react"
import { Trans } from "react-i18next"
import { useTheme } from "styled-components"
import * as SC from "./styled"

interface KanbanStepHeaderProps {
  title: string
  description: string
  cardsCountDescription: ReactElement<typeof Trans>
}

export const KanbanStepHeader = ({ title, description, cardsCountDescription }: KanbanStepHeaderProps) => {
  const theme = useTheme()
  return (
    <Box flexDirection="column" gap="xs4">
      <Box justifyContent="space-between" gap="xs4" alignItems="center">
        <SC.EllipsisText variant="headline8" variantColor={theme.colors.neutral[30]} lines={1}>
          {title}
        </SC.EllipsisText>
        <Icons name="IconArrowsSort" color={theme.colors.neutral[50]} onClick={() => console.log("sort")} />
      </Box>
      <Box gap="xs4" alignItems="center">
        <ShapeIcon name="IconUser" color={theme.colors.neutral[40]} variant="neutral" />
        {cardsCountDescription}
      </Box>
      <SC.EllipsisText variant="body4" weight={600} variantColor={theme.colors.neutral[50]} lines={2}>
        {description}
      </SC.EllipsisText>
    </Box>
  )
}
