import {
  Container,
  Bar,
  BarContainer,
  LabelContainer,
  ValueContainer,
  BarArea,
  LineContainer,
  Line,
} from "./styles";
import { EmptyStateChart, Typography } from "../";
import { Divider } from "../v2";
import { HorizontalBarChartInterface } from "./types";
import { Tooltip } from "@flash-tecnologia/hros-web-ui-v2";

const HorizontalBarChart = ({ title, data }: HorizontalBarChartInterface) => {
  const totalDomain = data?.reduce((a, c) => a + c?.value, 0);

  if (!totalDomain) return <EmptyStateChart />;

  return (
    <Container>
      <Typography
        variant="headline8"
        color="var(--color-neutral-30)"
        fontWeight={700}
      >
        {title}
      </Typography>
      <Divider spacing="xxs3" />
      {data?.map(({ value, label = "" }, i) => {
        const percentage = ((value || 0) * 100) / totalDomain;
        return (
          <>
            <BarContainer>
              <LabelContainer
                style={{
                  marginTop: i === 0 ? "-25px" : 0,
                  marginBottom: i === data?.length - 1 ? "-25px" : 0,
                }}
              >
                <Tooltip
                  arrow
                  placement="top"
                  children={
                    <div>
                      <Typography
                        variant="body4"
                        color="var(--color-neutral-50)"
                        fontWeight={600}
                      >
                        {label?.length > 10
                          ? `${label?.substring(0, 10)}...`
                          : label}
                      </Typography>
                    </div>
                  }
                  title={<div>{label}</div>}
                />
              </LabelContainer>
              <Divider type="horizontal" spacing="xs1" />
              <BarArea
                style={{
                  padding: "25px 0",
                  paddingBottom: i !== data?.length - 1 ? "25px" : 0,
                  paddingTop: i !== 0 ? "25px" : 0,
                }}
              >
                <LineContainer>
                  <Line />
                  <Line />
                  <Line />
                  <Line />
                  <Line />
                  <Line />
                  <Line />
                  <Line />
                  <Line />
                  <Line />
                </LineContainer>
                <Bar width={`${percentage}%` || "0%"} />
                <Divider type="horizontal" spacing="xs1" />
                <ValueContainer
                  style={{
                    zIndex: 1,
                    marginLeft: percentage > 90 ? "-130px" : 0,
                  }}
                >
                  <Typography
                    variant="body4"
                    color={
                      percentage > 90
                        ? "var(--color-neutral-100)"
                        : "var(--color-neutral-30)"
                    }
                    fontWeight={700}
                  >
                    {~~percentage}%
                  </Typography>
                  <Divider type="horizontal" spacing="xxxs" />
                  <Typography
                    variant="caption"
                    color={
                      percentage > 90
                        ? "var(--color-neutral-100)"
                        : "var(--color-neutral-50)"
                    }
                    fontWeight={400}
                  >
                    {` - ${value} resp.`}
                  </Typography>
                </ValueContainer>
              </BarArea>
            </BarContainer>
          </>
        );
      })}
    </Container>
  );
};

export { HorizontalBarChart };
