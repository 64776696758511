export enum TransactionStatus {
  AUTHORIZED = "AUTHORIZED",
  COMPLETED = "COMPLETED",
  SETTLED = "SETTLED",
  CANCELED = "CANCELED",
  REVERTED = "REVERTED",
  PROCESSING = "PROCESSING",
}

export enum TransactionType {
  DEBIT = "DEBIT",
  CREDIT = "CREDIT",
}

export enum TransactionMovement {
  OPEN_LOOP_PAYMENT = "OPEN_LOOP_PAYMENT",
  CLOSED_LOOP_PAYMENT = "CLOSED_LOOP_PAYMENT",
  BILLET_PAYMENT = "BILLET_PAYMENT",
  CASH_IN_PIX = "CASH_IN_PIX",
  CASH_IN_TED = "CASH_IN_TED",
  CASH_OUT_PIX = "CASH_OUT_PIX",
  CASH_OUT_TED = "CASH_OUT_TED",
  DEPOSIT = "DEPOSIT",
  COMPANY_WITHDRAW = "COMPANY_WITHDRAW",
  EMPLOYEE_WITHDRAW = "EMPLOYEE_WITHDRAW",
}

export enum TransactionBalanceType {
  BENEFIT = "benefit",
  CORPORATE = "corporate",
}

export type TransactionSearchRequestFilter = {
  companyNanoId?: string;
  type?: TransactionBalanceType;
  employeeNanoId?: string;
  movement?: string | string[];
  effect?: TransactionType[];
  value?: {
    min?: number;
    max?: number;
  };
  status?: TransactionStatus[];
  date?: {
    from: string;
    to: string;
  };
};

export type ExpenseSearchRequest = {
  filter?: TransactionSearchRequestFilter;
  pagination?: {
    currentPage: number;
    pageSize: number;
  };
};

export type UpdateTransactionPaymentRequest = {
  preview?: boolean;
  shouldSendEmail?: boolean;
};

export type Transaction = {
  _id: string;
  accountability?: {
    transactionId: string;
    code: string;
    translated: string;
  };
  automaticDebitDate?: Date;
  automaticCredit?: {
    type: string;
    date: Date;
    amount: number;
    createdAt?: Date;
  };
  createdAt: Date;
  updatedAt: Date;
  conversionRate?: number;
  currencyString?: string;
  localAmount?: number;
  localCurrencyCode?: string;
  localCurrencyString?: string;
  iofAmount?: number;
  iofRate?: number;
  networkConversionRate?: number;
  status: string;
  type?: string;
  accountId?: string;
  amount: number;
  amountWithoutFees?: number;
  cardType?: string;
  employeeId?: string;
  employeeNanoId?: string;
  date: string;
  description: string;
  isCorporate: boolean;
  localConversionRate?: number;
  maximumLimitTimes?: number;
  maximumUpdateDays?: number;
  mcc: string;
  merchantName?: string;
  movement?: string;
  cardLastDigits?: string;
  companyId?: string;
  companyNanoId?: string;
  events?: {
    user: string;
    date: Date;
    description: {
      previousBenefit: {
        amount: string;
        name: string;
        imageUrl: string;
      };
      currentBenefit: {
        amount: string;
        name: string;
        imageUrl: string;
      };
    };
  }[];
  expenseId?: string;
  openLoopPaymentId: string;
  category?: string;
  currencyCode?: string;
};

export type SearchTransactionResponse = {
  transactions: Transaction[];
  pagination?: {
    currentPage: number;
    totalPages: number;
    totalItems: number;
    pageSize: number;
  };
};

export type SearchUsers = {
  filter: {
    balance?: {
      max: number;
      min: number;
    };
    companyId: string;
    externalId?: Array<string>;
    active?: Array<boolean>;
    search?: string;
  };
  pagination?: {
    currentPage: number;
    pageSize: number;
  };
  projection?: {
    cardNumber?: boolean;
    hasBenefits?: boolean;
    policy?: boolean;
  };
};

export type CorporateCardUserResponse = {
  users: {
    _id: string;
  }[];
  pagination: {
    currentPage: number;
    totalPages: number;
    totalItems: number;
    pageSize: number;
  };
};
