export enum ExitFormEnum {
  VOLUNTARY = "voluntary",
  INVOLUNTARY = "involuntary",
}

export enum ExitTermsEnum {
  WITHOUT_CAUSE = "termination-without-cause",
  FOR_CAUSE = "termination-for-cause",
  MUTUAL_AGREEMENT = "mutual-termination",
  RESIGNATION = "employee-resignation",
}

export enum Steps {
  INITIAL_INFO = "initial_info",
  EVALUATION = "evaluation",
  INSTRUCTIONS = "instructions",
  EXIT_EXAM = "exit_exam",
  SIGNATURE = "signature",
  DONE = "done",
  ARCHIVED = "archived",
}

export enum ResignationStatus {
  CREATED = "created",

  INITIAL_INFO_IN_APPROVAL = "initial-info-in-approval",
  INITIAL_INFO_PENDING_COMMUNICATION = "initial-info-pending-communication",
  INITIAL_INFO_REJECTED = "initial-info-rejected",
  INITIAL_INFO_COMPLETED = "initial-info-completed",

  EVALUATION_PENDING_INTERVIEW = "evaluation-pending-interview",
  EVALUATION_IN_PROGRESS = "evaluation-in-progress",
  EVALUATION_COMPLETED = "evaluation-completed",

  INSTRUCTIONS_PENDING_EMAIL = "instructions-pending-email",
  INSTRUCTIONS_IN_PROGRESS = "instructions-in-progress",
  INSTRUCTIONS_COMPLETED = "instructions-completed",

  EXIT_EXAM_PENDING_REQUEST = "exit-exam-pending-request",
  EXIT_EXAM_AWAITING_PERSON_RESPONSE = "exit-exam-awaiting-person-response",
  EXIT_EXAM_PENDING_VALIDATION = "exit-exam-pending-validation",
  EXIT_EXAM_COMPLETED = "exit-exam-completed",

  SIGNATURE_PENDING_CONTRACT = "signature-pending-contract",
  SIGNATURE_PENDING_SIGNATURE = "signature-pending-signature",
  SIGNATURE_SIGNED = "signature-signed",
}

export enum ResignationColumnsOrder {
  INITIAL_INFO = 1,
  EVALUATION = 2,
  INSTRUCTIONS = 3,
  EXIT_EXAM = 4,
  SIGNATURE = 5,
  COMPLETED = 6,
  ARCHIVED = 7,
}

export interface Card {
  position: number
}

export interface Resignation {
  id: string
  step: Steps
  employeeId: string
  companyId: string
  responsibleId: string
  followers: string[]
  exitForm: ExitFormEnum
  email?: string
  requestDate: Date
  status: ResignationStatus
  exitTerms: ExitTermsEnum
  noticePeriod?: boolean
  terminationDate: Date
  motivation?: string
  createdAt?: Date
  updatedAt?: Date
  card: Card
}
