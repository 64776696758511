import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  BannerContainer,
  Button,
  PrimaryBannerContentContainer,
  PrimaryBannerInnerContentContainer,
} from './primary-banner.styles';
import { BannerTag } from '../BannerTag';
import { BannerProps } from '../banners-section.dto';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { trackEvent } from '@/utils/tracking';

export interface MainBannerProps extends BannerProps {
  contentStyle: {
    width: string;
  };
  imageStyle?: React.CSSProperties;
  buttonText: string;
  marketPageId: string;
}

export const PrimaryBanner = (props: MainBannerProps) => {
  const navigate = useNavigate();
  const onButtonClick = () => {
    trackEvent('acquisition_app_store_banner_clicked', {
      marketPageId: props.marketPageId,
    });
    const params = createSearchParams(location.search);
    params.set('origin', 'app_store_banner');
    params.set('id', props.marketPageId);
    navigate({ pathname: '/acquisition/overview', search: params.toString() });
  };
  return (
    <BannerContainer style={{ backgroundColor: props.backgroundColor }}>
      <PrimaryBannerContentContainer contentWidth={props.contentStyle.width}>
        <BannerTag icon="IconNorthStar" text="Novidade!" />
        <PrimaryBannerInnerContentContainer>
          <Typography
            variantColor="var(--color-neutral-20)"
            variant="headline7"
            weight={700}
          >
            {props.title}
          </Typography>
          <Typography
            variantColor="var(--color-neutral-30)"
            variant="body4"
            style={{ WebkitLineClamp: 3, textOverflow: 'ellipsis' }}
          >
            {props.description}
          </Typography>
        </PrimaryBannerInnerContentContainer>
        <Button onClick={onButtonClick} variant="primary">
          {props.buttonText}
        </Button>
      </PrimaryBannerContentContainer>
      <img
        src={props.image}
        alt="card"
        style={{
          overflow: 'hidden',
          position: 'absolute',
          right: 0,
          ...props.imageStyle,
        }}
      />
    </BannerContainer>
  );
};
