import { lazy } from "react";
import { Navigate, type RouteObject } from "react-router-dom";

import ROUTES from "@utils/route-names";

import { RoutesGuard } from "./permissions.guard";

const JobOpenings = lazy(() => import("@pages/job-openings"));
const JobOpeningsCreation = lazy(() => import("@pages/job-openings/1-create"));
const JobOpeningsClose = lazy(() => import("@pages/job-openings/2-close"));
const JobApplications = lazy(() => import("@pages/job-applications"));
const JobApplicationsPipelines = lazy(
  () => import("@pages/job-applications/1-pipelines"),
);
const JobApplicationsEmailTemplate = lazy(
  () => import("@pages/job-applications/2-email-template"),
);
const Settings = lazy(() => import("@pages/settings"));

const routes: Array<RouteObject> = [
  {
    element: <RoutesGuard />,
    children: [
      {
        element: <JobOpenings />,
        path: ROUTES.JOB_OPENINGS.path,
      },
      {
        element: <JobOpeningsCreation />,
        path: ROUTES.JOB_OPENINGS.CREATE.path,
      },
      {
        element: <JobOpeningsClose />,
        path: ROUTES.JOB_OPENINGS.CLOSE.path,
      },
      {
        element: <JobApplications />,
        path: ROUTES.JOB_APPLICATION.path,
      },
      {
        element: <JobApplicationsPipelines />,
        path: ROUTES.JOB_APPLICATION.PIPELINES.path,
      },
      // {
      //   element: <JobApplicationsEmailTemplate />,
      //   path: ROUTES.JOB_APPLICATION.EMAIL_TEMPLATE.path,
      // },
      {
        element: <Settings />,
        path: ROUTES.SETTINGS.path,
      },
      {
        path: "*",
        element: <Navigate to={ROUTES.JOB_OPENINGS.path} />,
      },
    ],
  },
];

export default routes;
