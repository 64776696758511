import { trpc } from '@api/client';

export const useAckAllInboxMessage = () => {
  const utils = trpc.useContext();
  const { isLoading, mutate } = trpc.ackAllInboxMessage.useMutation({
    onSuccess: async () => {
      await utils.getInbox.invalidate();
      await utils.getUnacknowledgedInboxMessages.invalidate(undefined, {
        refetchType: 'all',
      });
    },
  });
  return { mutate, isLoading };
};
