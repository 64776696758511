import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container } from "./styled";

interface TagProps {
  text: string;
  remove: any;
}

export default function Tag({ text, remove }: TagProps) {
  const handleOnRemove = (e: any) => {
    e.stopPropagation();
    remove(text);
  };

  return (
    <Container>
      <Typography variant="caption" weight={600} variantColor="#490424">
        {text}
      </Typography>

      <Icons
        name="IconX"
        size={12}
        color="#490424"
        onClick={handleOnRemove}
        style={{ cursor: "pointer" }}
      />
    </Container>
  );
}
