import NiceModal from "@ebay/nice-modal-react";
import { ThemeProvider } from "@flash-tecnologia/hros-web-ui-v2";

import { TrpcProvider } from "$client";
import { AppContextProvider } from "$frontend/shared/context/AppContextProvider";
import "../src/i18n";
import { AppRouter } from "./routes";
import { FeatureFlagProvider } from "./services";

export default function Root() {
  return (
    <ThemeProvider>
      <TrpcProvider>
        <FeatureFlagProvider>
          <AppContextProvider>
            <NiceModal.Provider>
              <AppRouter />
            </NiceModal.Provider>
          </AppContextProvider>
        </FeatureFlagProvider>
      </TrpcProvider>
    </ThemeProvider>
  );
}
