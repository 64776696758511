import React, { Dispatch, MutableRefObject, SetStateAction } from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import Root from './root.component';
import { ErrorBoundary } from '@utils';
import { trpc, trpcClient, queryClient } from './api/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { TaskHeaderIcon } from './pages/AdminHome/shared/TaskHeaderIcon';
import { BrowserRouter } from 'react-router-dom';

import { NotificationContent } from './pages/AdminHome/components/QuickAccessSection/components/InboxWidget/components/NotificationContent';
import BellNotification, {
  BellNotificationProps,
} from './pages/AdminHome/shared/BellNotificationHeader';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundaryClass: ErrorBoundary,
});

export const { bootstrap, mount, unmount } = lifecycles;

function TaskHeaderNotificationIcon() {
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <TaskHeaderIcon />
      </QueryClientProvider>
    </trpc.Provider>
  );
}

function HeaderNotification(opt: {
  bgColor: string;
  inboxOptions?: {
    invertTitle: boolean;
    uniqueList: boolean;
    showAckAll: boolean;
  };
  notificationsOptions?: {
    showNotificationDetails: boolean;
  };
}) {
  return (
    <BrowserRouter>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <NotificationContent
            bgColor={opt.bgColor}
            inboxOptions={opt.inboxOptions}
            notificationsOptions={opt.notificationsOptions}
          />
        </QueryClientProvider>
      </trpc.Provider>
    </BrowserRouter>
  );
}

function HeaderBellNotificationIcon({ onClick }: BellNotificationProps) {
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <BellNotification onClick={onClick} />
      </QueryClientProvider>
    </trpc.Provider>
  );
}

export {
  TaskHeaderNotificationIcon,
  HeaderNotification,
  HeaderBellNotificationIcon,
};
