import { CardDetailsFormSection } from './CardDetailsFormSection';
import { PoliciesFormSection } from './PoliciesFormSection';
import { SelectEmployeesFormSection } from './SelectEmployeesFormSection';
import * as SC from './styled';

export function CreateVirtualCardForm() {
  return (
    <SC.Container>
      <SC.SectionContainer>
        <SelectEmployeesFormSection />
      </SC.SectionContainer>

      <SC.SectionContainer>
        <CardDetailsFormSection />
      </SC.SectionContainer>

      <SC.SectionContainer>
        <PoliciesFormSection />
      </SC.SectionContainer>
    </SC.Container>
  );
}
