import styled from "styled-components";

export const Counters = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: ${(props) => props.theme.spacings.xs2};
  padding-right: ${(props) => props.theme.spacings.xs2};
`;
