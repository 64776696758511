import { useFormik } from "formik"
import * as yup from "yup"
import {
  StyledDescription,
  StyledModal,
  StyledSubTitle,
  StyledTextField,
  StyledTitle,
  StyledSubtitleContainer,
  StyledButtonContainer,
  StyledSectionContainer,
  CloseContainer,
} from "./styles"
import {
  Button,
  IconButton,
  Icons,
  Loader,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import dispatchToast from "../../../../../../utils/dispatchToast"
import { useQueryClient } from "@tanstack/react-query"
import { FlowSubcategory } from "../../../../../../types"
import { useTracking } from "@utils/useTracking"
import { trpc } from "@api/client"

const CreateTemplateModal = ({
  isOpen,
  handleClose,
  companyId,
  subcategory,
}: {
  isOpen: boolean
  handleClose: () => void
  subcategory: FlowSubcategory
  companyId: string
}) => {
  const queryClient = useQueryClient()
  const tracking = useTracking()
  const { mutate: createFormConfig, isLoading } =
    trpc.formConfig.createFormConfig.useMutation()

  const handleSubmit = (name: string) => {
    tracking.trackEvent({
      name: `company_hiring_${subcategory}_documentlisttemplates_continue_clicked`,
    })

    createFormConfig(
      { companyId, name },
      {
        onSuccess: () => {
          queryClient.invalidateQueries()

          dispatchToast({
            content: "Template criado com sucesso!",
            type: "success",
          })
          handleClose()
        },
        onError: (err: any) => {
          dispatchToast({
            content: "Houve um problema ao criar um novo modelo de documento.",
            type: "error",
          })
        },
      },
    )
  }

  const validationSchema = yup.object({
    name: yup.string().required("Favor digitar o nome do campo"),
  })

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    validate: (values) => {
      const errors: any = {}
      return errors
    },
    onSubmit: async (values) => handleSubmit(values.name),
  })

  return (
    <StyledModal open={isOpen} onClose={handleClose}>
      <>
        <CloseContainer>
          <IconButton
            size="small"
            variant="line"
            icon="IconX"
            onClick={handleClose}
          />
        </CloseContainer>

        <StyledSectionContainer>
          <StyledTitle variant={"headline6"}>
            Template de lista de documentos
          </StyledTitle>
          <StyledDescription variant={"body3"} style={{ marginTop: "8px" }}>
            Crie e edite templates de listas de documentos por tipo de
            contratação, departamento e/ou cargo
          </StyledDescription>
        </StyledSectionContainer>

        <StyledSectionContainer>
          <StyledSubTitle variant={"headline8"}>
            Nomeie seu template
          </StyledSubTitle>
          <StyledSubTitle variant={"body4"} style={{ marginTop: "8px" }}>
            Dê um nome ao template e descreva o seu objetivo.
          </StyledSubTitle>
          <StyledTextField
            id={"name"}
            name={"name"}
            label={"Nome do template"}
            inputProps={{ maxLength: 50 }}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            style={{ marginTop: "16px" }}
          />

          <StyledSubtitleContainer>
            <StyledDescription variant="caption">
              Máximo 50 caracteres
            </StyledDescription>
            <StyledDescription variant="caption">
              {formik.values.name.length}/50
            </StyledDescription>
          </StyledSubtitleContainer>
        </StyledSectionContainer>

        <StyledButtonContainer>
          <Button
            size={"large"}
            variant="secondary"
            onClick={handleClose}
            style={{ alignSelf: "center", marginRight: "24px" }}
          >
            <Typography variant="body3" style={{ fontWeight: 700 }}>
              Cancelar
            </Typography>
          </Button>
          <Button
            size={"large"}
            variant="primary"
            style={{ alignSelf: "center" }}
            onClick={() => {
              formik.handleSubmit()
            }}
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader size="extraSmall" variant="secondary" />
            ) : (
              <>
                <Typography variant="body3" style={{ fontWeight: 700 }}>
                  Continuar
                </Typography>
                <Icons name="IconArrowRight" />
              </>
            )}
          </Button>
        </StyledButtonContainer>
      </>
    </StyledModal>
  )
}

export { CreateTemplateModal }
