import { useCategorySettingsContext } from "$containers/category/hooks/useCategorySettingsContext";
import { Modal } from "$frontend/components/molecules/Modal/Modal";
import { handleTrcpError, useDisplayToast } from "$frontend/utils";
import { Category } from "$serverTypes";
import { TextField } from "@flash-tecnologia/hros-web-ui-v2";
import { Tooltip } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Container, StyledTypography } from "./styled";

interface CategoryFormModalProps {
  isOpen: boolean;
  category?: Category;
  onClose: () => void;
  onDone: (category: Pick<Category, "name" | "externalId">) => Promise<void>;
}

interface CategoryFormModalData {
  name: string;
  externalId: string;
}

export const CategoryFormModal = ({ isOpen, category, onClose, onDone }: CategoryFormModalProps) => {
  const { t } = useTranslation("translations", {
    keyPrefix: "molecules.categoryFormModal",
  });

  const { control, handleSubmit, reset } = useForm<CategoryFormModalData>({
    defaultValues: {
      name: category?.name ?? "",
      externalId: category?.externalId ?? "",
    },
  });

  const { refetch } = useCategorySettingsContext();
  const { displayToast } = useDisplayToast();
  const isEdit = Boolean(category?.id);

  useEffect(() => {
    reset({
      name: category?.name ?? "",
      externalId: category?.externalId ?? "",
    });
  }, [category, reset]);

  async function onSubmit(data: CategoryFormModalData) {
    try {
      await onDone(data);
      displayToast({
        title: t(isEdit ? "toasts.successEdited.title" : "toasts.successCreated.title"),
        description: t(isEdit ? "toasts.successEdited.description" : "toasts.successCreated.description"),
      });
      refetch();
      reset();
      onClose();
    } catch (e) {
      const description = handleTrcpError(e, (errorCode) => {
        const translationKey = isEdit
          ? `toasts.errorEdit.messages.${errorCode}`
          : `toasts.errorCreate.messages.${errorCode}`;
        return t(translationKey, { defaultValue: null });
      });

      displayToast({
        type: "error",
        title: t(isEdit ? "toasts.errorEdit.title" : "toasts.errorCreate.title"),
        description,
      });
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        reset();
        onClose();
      }}
      title={t(isEdit ? "editCategory" : "createCategory")}
      footerProps={{
        onConfirm: handleSubmit(onSubmit),
        confirmText: isEdit ? t("save") : t("create"),
      }}
      buttonVariant="neutral"
    >
      <Container onSubmit={handleSubmit(onSubmit)}>
        <Tooltip title={category?.isDefault ? t("tooltips.cantEditDefaultCategoryName") : undefined}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: t("requiredField_one"),
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                id="name"
                fullWidth
                required
                label={t("fields.name.label")}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                disabled={category?.isDefault ?? false}
              />
            )}
          />
        </Tooltip>

        <Controller
          name="externalId"
          control={control}
          rules={{
            required: t("requiredField_one"),
          }}
          render={({ field, fieldState }) => (
            <div>
              <TextField
                {...field}
                id="externalId"
                fullWidth
                required
                label={t("fields.externalId.label")}
                error={Boolean(fieldState.error)}
                helperText={t("fields.externalId.helperText")}
              />
            </div>
          )}
        />
        <StyledTypography variant="body4">
          <span>*</span> {t("requiredField_other")}
        </StyledTypography>
      </Container>
    </Modal>
  );
};
