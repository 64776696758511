import { Button as BaseButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Button = styled(BaseButton)`
  background-color: ${({ theme }) => theme.colors.secondary[50]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
`;

export const CardPlanInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const CardContainer = styled.div`
  display: flex;
  width: 340px;
  height: 540px;
  flex-direction: column;
  border: ${({ theme }) => `1px solid ${theme.colors.secondary[90]}`};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  padding: ${({ theme }) => theme.spacings.xs1};
  position: relative;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const FeeTagContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: ${({ theme }) => theme.borders.radius.l};
  flex: 0 0 auto;
  padding: ${({ theme }) => `0 ${theme.spacings.xs4}`};
  align-self: start;
`;

export const FeaturesContainer = styled.div<{ longList: boolean }>`
  display: grid;
  grid-template-columns: ${({ longList }) =>
    longList ? 'repeat(2, 1fr)' : '1fr'};
  gap: ${({ theme }) => theme.spacings.xs3};
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const CardButtonsInnerContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.s};
  justify-content: flex-end;
`;
export const CardButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
`;
