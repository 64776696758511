import { FilterKey } from '../components/filters/hooks/use-filters'
import { Employee } from '../types'

export const filterEmployeesByCriteria = (
  employees: Employee[],
  employeesFilter: Record<FilterKey, string[]>,
) => {
  return employees.filter((employee) =>
    Object.entries(employeesFilter).every(([filterKey, filterValues]) => {
      if (filterValues.length === 0) {
        return true
      }

      const employeeValue = employee[filterKey as keyof Employee]

      if (Array.isArray(employeeValue)) {
        return filterValues.some((value) =>
          (employeeValue as string[]).includes(value),
        )
      }

      return filterValues.includes(employeeValue)
    }),
  )
}
