import { ShapeIcon, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import React from 'react'
import styled from 'styled-components'

interface IReminderProps {
  title: React.ComponentProps<typeof Typography>['children']
  children: React.ComponentProps<typeof Typography>['children']
}

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: ${({ theme }) => theme.borders.width.xs2} solid
    ${({ theme }) => theme.colors.feedback.info[70]};
  padding: ${({ theme }) => theme.spacings.xs};
  gap: ${({ theme }) => theme.spacings.xs2};
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs5};
`

export const Reminder: React.FC<IReminderProps> = ({ title, children }) => {
  return (
    <Container>
      <ShapeIcon
        name={'IconAlertCircle'}
        size={32}
        variant="info"
        stroke="info"
      />
      <TextContainer>
        <Typography variant="body3" color="feedback.info.10" weight={600}>
          {title}
        </Typography>
        <Typography variant="body4" color="feedback.info.10">
          {children}
        </Typography>
      </TextContainer>
    </Container>
  )
}
