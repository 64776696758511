import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import { MODAL_BalanceRequestPayment } from '@frontend/pagesV2/user-accounts/UserAccounts/flows/BalanceRequestPayment/BalanceRequestPayment';
import ModalService from '@frontend/services/ModalService';

type Props = {
  order: {
    _id: string;
    creditDate: Date;
    employees: Array<{
      id: string;
      documentNumber: string;
      name: string;
    }>;
    value: number;
  };
};

function ApproveRequestModal(props: Props) {
  const modalController = ModalService.useModalController();

  return (
    <ConfirmationModal
      buttons={{
        confirm: {
          text: 'Ir para pagamento',
          onClick: () => {
            modalController.remove();
            ModalService.show(MODAL_BalanceRequestPayment, {
              orderId: props.order._id,
              defaultValues: {
                employeeIds: new Set(
                  props.order.employees.map((employee) => employee.id),
                ),
                deposit: {
                  creditDate: new Date(props.order.creditDate),
                  amount: props.order.value,
                  paymentMethod: 'FLASH_CASH',
                  expires: false,
                  automatic: false,
                  complementary: false,
                },
              },
            });
          },
        },
        cancel: {
          text: 'Decidir mais tarde',
          onClick: () => {
            modalController.remove();
          },
        },
      }}
      variant="success"
      text={{
        highlightedText: 'Atenção',
        title: 'Aprovar pedido de saldo',
        body: 'Você está prestes a aprovar o pedido de saldo, mas precisa finalizar a etapa de pagamento para concluir o depósito.',
      }}
      isOpen={modalController.visible}
      onClose={modalController.remove}
    />
  );
}

export default ModalService.create(ApproveRequestModal);
