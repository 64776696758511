import { DealFee } from '@/api/types/deal-properties';
import {
  CardDescription,
  CardDescriptionTitle,
  CardItens,
} from '../../commercial-conditions.styles';
import {
  ExemptContainer,
  ExemptIcon,
  ExemptIconText,
  ExemptText,
} from './styled';

interface TaxesProps {
  taxes: DealFee[];
}

export const Taxes = (props: TaxesProps) => {
  return (
    <div>
      {props?.taxes?.map((tax) => (
        <div key={tax.title + tax.text}>
          <CardItens>
            <CardDescriptionTitle color="neutral.40">
              {tax.title}
            </CardDescriptionTitle>
            <ExemptContainer>
              <ExemptText exempt={tax.exempt!}>
                <CardDescription color="neutral.50">{tax.text}</CardDescription>
              </ExemptText>
              {tax.exempt && (
                <ExemptIcon>
                  <ExemptIconText>Isento</ExemptIconText>
                </ExemptIcon>
              )}
            </ExemptContainer>
          </CardItens>
        </div>
      ))}
    </div>
  );
};
