import errors from '@frontend/utils/commonTexts/errors';
import { DateTime } from 'luxon';
import { z } from 'zod';

export const CreateVirtualCardsSchema = z.object({
  employeeIds: z.set(z.string()).min(1, errors.forms.generic.required),
  name: z.string().min(3, 'É necessário no mínimo 3 caracteres'),
  expirationDate: z
    .date()
    .min(
      DateTime.now().plus({ days: 1 }).startOf('day').toJSDate(),
      'O vencimento mínimo é de 1 dias',
    )
    .max(
      DateTime.now().plus({ years: 5 }).toJSDate(),
      'O tempo máximo de expiração é 5 anos',
    )
    .optional(),
  policyId: z.string().optional(),
});
