import { Icons, Tooltip, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import {
  TotalPartialContainerStyled,
  TotalPartialTextContainerStyled,
} from './styles'

interface ITotalPartial {
  totalAmount: number
  isTopup?: boolean
}

export const TotalPartial = ({
  totalAmount,
  isTopup = false,
}: ITotalPartial) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const text = isTopup
    ? t('order.checkout.orderSummary.value')
    : t('order.checkout.orderSummary.total')

  return (
    <TotalPartialContainerStyled>
      <TotalPartialTextContainerStyled>
        <Typography variant="body3" color="neutral.30" weight={600}>
          {text}
        </Typography>

        <Tooltip
          title={t('order.checkout.orderSummary.totalPartial.tooltip')}
          arrow
          placement="right"
        >
          <Icons name="IconHelp" size={16} color={theme.colors.neutral[30]} />
        </Tooltip>
      </TotalPartialTextContainerStyled>

      <Typography variant="body3" color="secondary.50" weight={700}>
        {t('order.checkout.orderSummary.totalAmount.value', {
          value: totalAmount,
        })}
      </Typography>
    </TotalPartialContainerStyled>
  )
}
