import React, { createContext, useContext, useState, ReactNode } from "react";

interface ReportsContextType {
  selectedBu: string | null;
  setSelectedBu: (bu: string | null) => void;
  selectedModule: string | null;
  setSelectedModule: (module: string | null) => void;
}

const ReportsContext = createContext<ReportsContextType | undefined>(undefined);

interface ReportsProviderProps {
  children: ReactNode;
}

export const ReportsProvider: React.FC<ReportsProviderProps> = ({
  children,
}) => {
  const [selectedBu, setSelectedBu] = useState<string | null>(null);
  const [selectedModule, setSelectedModule] = useState<string | null>(null);

  const handleSelectBu = (bu: string | null) => {
    setSelectedBu(bu);
    setSelectedModule(null);
  };

  return (
    <ReportsContext.Provider
      value={{
        selectedBu,
        selectedModule,
        setSelectedBu: handleSelectBu,
        setSelectedModule,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

export const useReportsContext = (): ReportsContextType => {
  const context = useContext(ReportsContext);
  if (!context) {
    throw new Error("useReportsContext must be used within a ReportsProvider");
  }
  return context;
};
