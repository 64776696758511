export enum ICONS {
  BENEFITS = 'IconShoppingCart',
  DOCUMENT_MANAGEMENT = 'IconFolder',
  PAYROLL_LOAN = 'IconBusinessplan',
  CORPORATE_ACCOUNTS = 'IconWallet',
  CORPORATE_TRAVEL = 'IconPlaneTilt',
  RECORDINGS = 'IconCalculator',
  RECRUITMENT = 'IconUserSearch',
  PEOPLE_FLOW = 'IconLayoutKanban',
  WORK_SHIFT = 'IconClockHour3',
  PERFORMANCE = 'IconRocket',
  TRAININGS = 'IconSchool',
  ENGAGEMENT = 'IconSpeakerphone',
  ORGANIZATIONAL_CHART = 'IconHierarchy',
  SALES_DEAL = 'IconBuildingStore',
  ANTI_FRAUD_COMPLIANCE = 'IconCheckupList',
}

export enum LABELS {
  BENEFITS = 'Benefícios da empresa',
  DOCUMENT_MANAGEMENT = 'Gestão de Documentos',
  PAYROLL_LOAN = 'Consignado',
  CORPORATE_ACCOUNTS = 'Contas corporativas',
  CORPORATE_TRAVEL = 'Viagens corporativas',
  RECORDINGS = 'Contabilizações',
  RECRUITMENT = 'Recrutamento',
  PEOPLE_FLOW = 'Fluxos de pessoas',
  WORK_SHIFT = 'Jornada de trabalho',
  PERFORMANCE = 'Desempenho',
  TRAININGS = 'Treinamentos',
  ENGAGEMENT = 'Engajamento',
  ORGANIZATIONAL_CHART = 'Organograma',
  SALES_DEAL = 'Negociações',
  ANTI_FRAUD_COMPLIANCE = 'Regularize sua empresa na plataforma',
}
