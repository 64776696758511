import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'
import { Emoji } from 'src/components/emoji'

import { IOrderSummaryBenefitProps } from '../../order-summary.interface'
import { OrderSummary } from '../summary'

interface IOrderSummaryBenefits {
  benefits: IOrderSummaryBenefitProps[]
  isTopup?: boolean
}

export const OrderSummaryBenefits = ({
  benefits,
  isTopup = false,
}: IOrderSummaryBenefits) => {
  const { t } = useTranslation()

  const title = isTopup
    ? t('order.checkout.orderSummary.topup.title')
    : t('order.checkout.orderSummary.benefits.title')

  return (
    <OrderSummary.Root>
      <OrderSummary.ListTitle>
        <Typography variant="headline8" color="neutral.20">
          {title}
        </Typography>
      </OrderSummary.ListTitle>
      <OrderSummary.List>
        {benefits.map(({ value, image, label }) => (
          <OrderSummary.Item key={`benefits-item-${label + value}`}>
            <OrderSummary.ItemRoot>
              <Emoji imgSrc={image} />
              <Typography variant="body4" color="neutral.30">
                {label}
              </Typography>
            </OrderSummary.ItemRoot>
            <Typography variant="body4" color="neutral.30" weight={700}>
              {t('order.checkout.orderSummary.benefits.value', {
                value,
              })}
            </Typography>
          </OrderSummary.Item>
        ))}
      </OrderSummary.List>
    </OrderSummary.Root>
  )
}
