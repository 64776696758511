import { useCheckActiveModule } from '@flash-tecnologia/expense-web-activation-utils';
import { useHasSignPermission } from '@shared/hooks/useHasSignPermission';
import { Navigate, Outlet } from 'react-router-dom';

import { useAcceptedContacts } from './hooks/useAcceptedContacts';
import { Router } from './Router';

export const RoutesContractsGuard = ({ children }: { children?: JSX.Element }) => {
  const allowedSignContract = useHasSignPermission();
  const { termsSigned } = useAcceptedContacts();
  const isNewSAASEnabled = useCheckActiveModule('lifecycle');

  if (!termsSigned) {
    return <Navigate to={allowedSignContract && !isNewSAASEnabled ? Router.getSubscription() : '/home'} />;
  }

  return children ? children : <Outlet />;
};
