import {
  Checkbox,
  SearchField,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import { useCallback } from 'react'

import {
  FilterOption,
  useFilterContent,
  UseFilterContentParams,
} from './hooks/use-filter-content'
import { HeaderStyled, ItemStyled, ListStyled, SectionStyled } from './styles'

type FilterContentProps = UseFilterContentParams & { title: string }

function formatSelectedNames(names: string[], maxDisplay = 3) {
  if (names.length > maxDisplay) {
    return `${names.slice(0, maxDisplay).join(', ')} +${names.length - maxDisplay}`
  }

  return names.join(', ')
}

export function FilterContent({
  id,
  title,
  options,
  handleToggleItem,
  selectAll,
  selectedItems,
  useId = true,
}: FilterContentProps) {
  const {
    selectedNames,
    isOptionsSelected,
    toogleItemSelection,
    filteredOptions,
    allSelected,
    someSelected,
    toggleSelectAll,
    handleChangeSearchTerm,
  } = useFilterContent({
    id,
    options,
    selectedItems,
    useId,
    handleToggleItem,
    selectAll,
  })

  const renderItem = useCallback(
    ({ id: optionId, name, count }: FilterOption) => {
      const isSelected = isOptionsSelected(name, optionId)

      return (
        <ItemStyled isSelected={isSelected}>
          <div>
            <Checkbox
              checked={isSelected}
              onChange={() => toogleItemSelection(name, optionId)}
            />
            <Typography
              variant="body4"
              weight={600}
              color={isSelected ? 'secondary.30' : 'neutral.30'}
            >
              {name}
            </Typography>
          </div>

          <Typography
            variant="body4"
            weight={600}
            color={isSelected ? 'secondary.30' : 'neutral.60'}
          >
            {count} resultados
          </Typography>
        </ItemStyled>
      )
    },
    [isOptionsSelected, toogleItemSelection],
  )

  return (
    <SectionStyled>
      <HeaderStyled>
        <Typography variant="headline9" weight={700} color="neutral.30">
          {title}
        </Typography>

        {selectedItems.length > 0 && (
          <Typography variant="body4" color="neutral.50">
            {formatSelectedNames(selectedNames as string[])}
          </Typography>
        )}
      </HeaderStyled>

      <SearchField
        label="Buscar"
        size="small"
        onChange={(event) => handleChangeSearchTerm(event.target.value)}
      />

      <ItemStyled isSelected={someSelected || allSelected}>
        <div>
          <Checkbox
            checked={allSelected}
            indeterminate={someSelected}
            onChange={toggleSelectAll}
            disabled={filteredOptions.length === 0}
          />

          <Typography
            variant="body4"
            weight={600}
            color={someSelected || allSelected ? 'secondary.30' : 'neutral.30'}
          >
            Selecionar tudo
          </Typography>
        </div>
      </ItemStyled>

      <ListStyled>{filteredOptions.map(renderItem)}</ListStyled>
    </SectionStyled>
  )
}
