import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { cpfMask } from 'src/utils/mask'

import { Employee } from '../../types'
import { EmployeeNameWrapperStyled } from './styles'

type EmployeeNameCellProps = {
  employee: Employee
}

export function EmployeeNameCell({ employee }: EmployeeNameCellProps) {
  return (
    <EmployeeNameWrapperStyled>
      <Typography variant="body3" weight={600} color="neutral.20">
        {employee.name}
      </Typography>

      <div>
        <Typography variant="body4" color="neutral.40">
          {cpfMask(employee.document)}
        </Typography>
      </div>
    </EmployeeNameWrapperStyled>
  )
}
