import { ExpenseStatusTag } from "$molecules";
import { ExpenseStatusTagProps } from "../ExpenseStatusTag/ExpenseStatusTag";
import { Container } from "./styled";

type InfoCardStatusProps = ExpenseStatusTagProps & {
  /* TODO add "Dentro da política" props in the future */
};

export const InfoCardStatus = ({ status, type }: InfoCardStatusProps) => {
  return (
    <Container>
      {/* TODO add "Dentro da política" component in the future */}
      <div />
      <ExpenseStatusTag type={type} status={status} />
    </Container>
  );
};
