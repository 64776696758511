import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container } from "./styled";

export const Warning = () => {
  return (
    <Container>
      <Icons size={24} name="IconInfoCircle" color="#015FCB" />
      <Typography variant="body4" variantColor="#00244C">
        Conclua a assinatura do novo contrato acessando a área de{" "}
        <strong style={{ fontWeight: 700 }}>Tarefas/Pendências</strong> na Home
        das filiais.
      </Typography>
    </Container>
  );
};
