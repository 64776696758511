import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import Tag from "../Tag";
import { Container } from "./styled";

export const BillingEmails = ({
  emails,
  remove,
}: {
  emails: string[];
  remove: (string) => void;
}) => {
  return (
    <Container>
      {emails?.length ? (
        <Typography variant="caption" weight={600} variantColor="#83727D">
          E-mails já cadastrados:
        </Typography>
      ) : (
        <></>
      )}
      {emails?.map((email, index) => {
        return <Tag key={index} text={email} remove={remove} />;
      })}
    </Container>
  );
};
