import { ResignationStatus } from "@customTypes/resignation"

export default {
  translations: {
    page: {
      helloworld: "Olá mundo com I18n",
      home: {
        kanban: {
          cardsCountDescription: "<display>{{cardsCount}}</display> <description>pessoa(s) na etapa</description>",
          requestDateLabel: "Data da solicitação:",
        },
      },
    },
    components: {
      resignationStatusTag: {
        [ResignationStatus.EVALUATION_COMPLETED]: "Concluído",
        [ResignationStatus.EVALUATION_IN_PROGRESS]: "Em andamento",
        [ResignationStatus.EVALUATION_PENDING_INTERVIEW]: "Entrevista pendente",
        [ResignationStatus.EXIT_EXAM_AWAITING_PERSON_RESPONSE]: "Aguardando retorno da pessoa",
        [ResignationStatus.EXIT_EXAM_COMPLETED]: "Concluído",
        [ResignationStatus.EXIT_EXAM_PENDING_REQUEST]: "Envio de solicitação pendente",
        [ResignationStatus.EXIT_EXAM_PENDING_VALIDATION]: "Validação pendente",
        [ResignationStatus.INSTRUCTIONS_COMPLETED]: "Concluído",
        [ResignationStatus.INSTRUCTIONS_IN_PROGRESS]: "Em andamento",
        [ResignationStatus.INSTRUCTIONS_PENDING_EMAIL]: "Envio de e-mail pendente",
        [ResignationStatus.INITIAL_INFO_COMPLETED]: "Concluído",
        [ResignationStatus.INITIAL_INFO_IN_APPROVAL]: "Em aprovação",
        [ResignationStatus.INITIAL_INFO_PENDING_COMMUNICATION]: "Comunicação pendente",
        [ResignationStatus.INITIAL_INFO_REJECTED]: "Reprovado",
        [ResignationStatus.SIGNATURE_PENDING_CONTRACT]: "Envio do contrato pendente",
        [ResignationStatus.SIGNATURE_PENDING_SIGNATURE]: "{{pendingSignaturesCount}} assinatura(s) pendente(s)",
        [ResignationStatus.SIGNATURE_SIGNED]: "Assinado",
      },
    },
    error: {
      internalServerError: "Ocorreu um problema no sistema, por favor tente novamente mais tarde.",
    },
  },
}
