import { Button, Icons, LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "styled-components";

import { Loader } from "$atoms";
import { trpc } from "$client";
import { ExpenseFormDto } from "$frontend/pages";
import { Routes } from "$frontend/routes/routes";
import { handleTrcpError, useDisplayToast } from "$frontend/utils";
import { ExpenseFooterActions, FocusedContainer, FocusedHeader, ReimbursementForm } from "$molecules";
import { Expense, ExpenseStatus } from "$serverTypes";

export const AdminReimbursementEditPage = () => {
  const { expenseId } = useParams();
  const [loading, setLoading] = useState(false);
  const { displayToast } = useDisplayToast();
  const { t } = useTranslation("translations", {
    keyPrefix: "pages.adminEditReimbursement",
  });
  const navigate = useNavigate();

  const { mutateAsync: patchExpense } = trpc.expense.patchExpense.useMutation();
  const { colors } = useTheme();

  const { data, isLoading } = trpc.expense.getExpenseById.useQuery(expenseId ?? "");
  const { mutateAsync: updateExpenseStatus } = trpc.expense.updateSingleStatus.useMutation();

  const methods = useForm<ExpenseFormDto>({
    defaultValues: {},
  });

  function setReimbursementFormData(data: Expense) {
    methods.setValue("amount", data.amount);
    methods.setValue("attachments", data.attachments);
    methods.setValue("category", data.category);
    methods.setValue("comments", data.comments);
    methods.setValue("date", new Date(data.date));
    if (data.establishment) {
      methods.setValue("establishment", {
        placeAutocomplete: {
          name: data.establishment.description,
          placeId: data.establishment.placeId,
        },
        // will be set properly in EstablishmentSelect component
        sessionToken: "",
      });
    }
  }

  useEffect(() => {
    if (data) {
      setReimbursementFormData(data);
    }
  }, [data]);

  function shouldSendToAccountByStatus(status?: ExpenseStatus): boolean {
    if (!status) return false;
    return status === ExpenseStatus.DRAFT || status === ExpenseStatus.REQUIRE_CHANGES;
  }

  async function onContinueClick(formData: ExpenseFormDto, shouldSendToAccount?: boolean, navigateToList?: boolean) {
    if (!formData.date || isNaN(new Date(formData.date).getTime())) return;

    const establishmentChanged = formData.establishment?.placeAutocomplete.placeId !== data?.establishment?.placeId;
    const expense = {
      amount: formData.amount,
      attachments: formData.attachments,
      category: formData.category,
      comments: formData.comments,
      currency: "BRL",
      date: formData.date.toISOString(),
      ...(formData.establishment && establishmentChanged
        ? {
            establishment: {
              sessionToken: formData.establishment.sessionToken,
              placeId: formData.establishment.placeAutocomplete.placeId,
            },
          }
        : {}),
    };

    setLoading(true);
    try {
      const expenseId = data?.id ?? "";
      if (!expenseId) {
        displayToast({
          type: "error",
          title: t("toasts.failedEdit.title"),
          description: t("toasts.failedEdit.messages.missingId"),
        });
        return;
      }

      await patchExpense([expenseId, expense]);

      if (shouldSendToAccount) {
        await updateExpenseStatus({
          expenseId: expenseId,
          targetStatus: ExpenseStatus.PENDING_ACCOUNTING,
        });
      }

      displayToast({ title: t("toasts.successEdit.title") });

      if (navigateToList) {
        navigate(Routes.ADMIN_REIMBURSEMENTS);
      }
      navigate(Routes.ADMIN_REIMBURSEMENT.replace(":expenseId", expenseId ?? ""));
    } catch (e) {
      const description = handleTrcpError(e, (errorCode) =>
        t(`toasts.failedEdit.messages.${errorCode}`, {
          defaultValue: null,
        }),
      );

      displayToast({
        type: "error",
        title: t("toasts.failedEdit.title"),
        description,
      });
    } finally {
      setLoading(false);
    }
  }

  if (isLoading) {
    return <Loader show={true} />;
  }

  const header = (
    <FocusedHeader
      start={[
        <LinkButton variant="neutral" key={1} onClick={() => navigate(Routes.ADMIN_REIMBURSEMENTS)}>
          <Icons name="IconChevronLeft" />
          {t("back")}
        </LinkButton>,
      ]}
      middle={[
        <Typography variant="headline9" weight={700} color={colors.neutral[30]}>
          {t("title")}
        </Typography>,
      ]}
      end={[
        <Button
          variant="secondary"
          variantType="neutral"
          size="small"
          key={1}
          onClick={() => {
            alert("TODO: Help button");
          }}
        >
          <Icons name="IconHelp" />
          {t("help")}
        </Button>,
      ]}
    />
  );

  return (
    <FocusedContainer
      header={header}
      footer={
        <ExpenseFooterActions
          methods={methods}
          hasExpense={!shouldSendToAccountByStatus(data?.status)}
          isLoading={loading}
          onContinueClick={(item) => onContinueClick(item, shouldSendToAccountByStatus(data?.status))}
          onQuitAndSave={(item) => onContinueClick(item, false)}
        />
      }
    >
      <ReimbursementForm methods={methods} expenseStatus={data?.status} />
    </FocusedContainer>
  );
};
