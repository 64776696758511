import { DepositDTO } from 'backend/src/routers/benefit-orders/dtos'

import { DepositTimeUnitEnum, Employee } from '../../employee-selection/types'

const calculateAmount = (value: number, daysMultiplier: number) => {
  const cents = Math.trunc(value * 100)

  return cents * daysMultiplier
}

const getMultiplier = (
  depositTimeUnit: DepositTimeUnitEnum,
  numberDays: number,
) => {
  return depositTimeUnit === DepositTimeUnitEnum.DAY ? numberDays : 1
}

export const buildDeposits = (
  employees: Employee[],
  numberDays: number,
): DepositDTO[] =>
  employees.flatMap((employee) => {
    return employee.benefits.map((benefit) => ({
      amount: calculateAmount(
        benefit.value,
        getMultiplier(benefit.depositTimeUnit, numberDays),
      ),
      benefitId: benefit.id,
      benefitName: benefit.name,
      employeeId: employee.id,
      employeeDocument: employee.document,
      employeeName: employee.name,
    }))
  })
