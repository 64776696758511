import {
  getAccessToken,
  getAccessTokenPayloadSync,
  legacyGetFromLS,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { httpLink } from '@trpc/client'
import { createTRPCReact } from '@trpc/react-query'
import type { AppRouter } from 'backend'
import React from 'react'
import { PossibleEnvs, processEnv } from 'src/utils/env'
import superjson from 'superjson'

const urlMap: Record<PossibleEnvs, string> = {
  development: 'http://localhost:3000/trpc',
  staging: 'https://benefits-public.private.flashapp.dev/benefit-orders',
  production: 'https://benefits-public.us.flashapp.services/benefit-orders',
}

export const trpc = createTRPCReact<AppRouter>({
  abortOnUnmount: true,
})

export function TrpcProvider({ children }: { children: React.ReactNode }) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 2,
        retryDelay: 3000,
        staleTime: 1000 * 60 * 5,
      },
      mutations: {
        retry: false,
      },
    },
  })

  const company = useSelectedCompany()
  const { adminId: legacyAdminId, companyId } = legacyGetFromLS('clientData')
  const trpcClient = trpc.createClient({
    transformer: superjson,
    links: [
      httpLink({
        url: urlMap[processEnv.BUILD_ENV],
        async headers() {
          const accessToken = await getAccessToken()
          const { employeeId, economicGroupId } = getAccessTokenPayloadSync()
          const xRequestId = Math.random().toString(36).substring(2, 15)
          return {
            authorization: accessToken,
            'x-flash-auth': `Bearer ${accessToken}`,
            companyId: company.selectedCompany.id,
            economicGroupId,
            legacycompanyId: companyId,
            legacyAdminId,
            adminId: employeeId,
            'x-request-id': xRequestId,
          }
        },
      }),
    ],
  })

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  )
}
