import { ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs3};
  align-items: center;
  white-space: nowrap;
`;

export const Icon = styled(ShapeIcon)`
  flex-shrink: 0;
`;
