import { ShapeIcon, Tooltip, Typography } from "@flash-tecnologia/hros-web-ui-v2"; //TODO passar pelo styled ou criar componente
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import { Features } from "$frontend/pages/Reimbursement/ReimbursementPage";
import { getValueWithCurrency } from "$frontend/utils";
import {
  CategorySelect,
  ExpenseAmountTrailingContainer,
  ExpenseDescriptionInput,
  ExpenseStatusStepperCard,
  InfoCard,
  InfoCardStatus,
  ReceiptDropzone,
} from "$molecules";
import { ExpenseFormDto } from "$pages";
import { Expense } from "$serverTypes";
import {
  Card,
  CardTile,
  CardTitle,
  Container,
  Content,
  Form,
  FormContent,
  ReadonlyTextInput,
  StepperCard,
  WarningCard,
  WarningCardSubtitle,
} from "./styled";

type ReimbursementDetailsProps = {
  /** Expense object with its details to be displayed */
  expense: Expense;
  /** Configuration options that control UI elements and features, such as toggling the form and customizing icon colors */
  features: Features;
  /** Determines if the form should be toggled or if data should be displayed only*/
  formToggle: boolean;
  /** Loading status */
  isLoading: boolean;
  /** Access to methods and state for managing the reimbursement form */
  methods: UseFormReturn<ExpenseFormDto, Record<string, unknown>>;
};

export const ReimbursementDetails = ({
  isLoading,
  methods,
  expense,
  features,
  formToggle,
}: ReimbursementDetailsProps) => {
  const { t } = useTranslation("translations", { keyPrefix: "molecules.reimbursementDetails" });

  const { alertBox, toggledIconColor } = features;

  const { colors } = useTheme();

  return (
    <Container formToggle={formToggle}>
      <Content>
        <Controller
          control={methods.control}
          name="attachments"
          rules={{ required: true }}
          render={({ field }) => (
            <ReceiptDropzone
              isLoading={isLoading}
              onChange={field.onChange}
              value={field.value ?? []}
              disabled={!formToggle}
            />
          )}
        />

        <FormContent>
          <Card>
            <CardTile bottomBorder={true}>
              <InfoCard
                leading={
                  <ShapeIcon
                    variant={(formToggle ? toggledIconColor : undefined) ?? "neutral"}
                    stroke={(formToggle ? toggledIconColor : undefined) ?? "neutral"}
                    name="IconReceipt"
                    size={40}
                  />
                }
                title={
                  <Typography variant="caption" variantColor={colors.neutral[40]}>
                    {t("cardTitle")}
                  </Typography>
                }
                subTitle={
                  <Tooltip title={t("cardTitle")}>
                    <Tooltip title={expense.establishment?.description}>
                      <div>
                        <CardTitle weight={700} variant="headline8" variantColor={colors.neutral[30]}>
                          {expense.establishment?.name}
                        </CardTitle>
                      </div>
                    </Tooltip>
                  </Tooltip>
                }
                trailing={
                  <ExpenseAmountTrailingContainer>
                    <InfoCard
                      alignItems="flex-end"
                      title={
                        <Typography variant="caption" variantColor={colors.neutral[40]}>
                          {t("expenseAmount")}
                        </Typography>
                      }
                      subTitle={
                        <Typography weight={700} variant="headline8" variantColor={colors.neutral[30]}>
                          {getValueWithCurrency({
                            value: expense?.amount ?? 0,
                            currencyPrefix: expense?.currency,
                          })}
                        </Typography>
                      }
                    />
                  </ExpenseAmountTrailingContainer>
                }
              />
              <InfoCardStatus status={expense?.status} />
            </CardTile>
            <Form>
              {alertBox && (
                <WarningCard color={alertBox.color}>
                  <InfoCard
                    leading={
                      <ShapeIcon
                        name={alertBox.icon}
                        variant={alertBox.iconVariant}
                        stroke={alertBox.iconVariant}
                        size={32}
                      />
                    }
                    title={
                      <Typography weight={700} variant="body4" variantColor={colors.neutral[30]}>
                        {alertBox.title}
                      </Typography>
                    }
                    subTitle={
                      <WarningCardSubtitle>
                        <Typography weight={400} variant="caption" variantColor={colors.neutral[30]} tag="p">
                          {alertBox.subtitle}
                        </Typography>
                        {alertBox.bottomSubtitle && (
                          <Typography weight={700} variant="caption" variantColor={colors.neutral[30]} tag="p">
                            {alertBox.bottomSubtitle}
                          </Typography>
                        )}
                      </WarningCardSubtitle>
                    }
                  />
                </WarningCard>
              )}
              {formToggle ? (
                <>
                  <Controller
                    control={methods.control}
                    rules={{ required: true }}
                    name="category"
                    render={({ field }) => (
                      <CategorySelect
                        isLoading={isLoading}
                        onChange={(category) =>
                          field.onChange({
                            nanoId: category.id,
                            description: category.name,
                          })
                        }
                        value={field.value?.nanoId ?? ""}
                      />
                    )}
                  />

                  <Controller
                    control={methods.control}
                    name="comments"
                    render={({ field }) => (
                      <ExpenseDescriptionInput
                        isLoading={isLoading}
                        onChange={field.onChange}
                        value={field.value ?? ""}
                      />
                    )}
                  />
                </>
              ) : (
                <>
                  <InfoCard
                    leading={<ShapeIcon name="IconCategory" variant="neutral" stroke="neutral" size={32} />}
                    title={
                      <Typography weight={600} variant="caption" variantColor={colors.neutral[40]}>
                        {t("fields.category")}
                      </Typography>
                    }
                    subTitle={
                      <Typography weight={600} variant="body4" variantColor={colors.neutral[20]}>
                        {expense.category?.description}
                      </Typography>
                    }
                  />
                  <InfoCard
                    leading={<ShapeIcon name="IconCalendarEvent" variant="neutral" stroke="neutral" size={32} />}
                    title={
                      <Typography weight={600} variant="caption" variantColor={colors.neutral[40]}>
                        {t("fields.date")}
                      </Typography>
                    }
                    subTitle={
                      <Typography weight={600} variant="body4" variantColor={colors.neutral[20]}>
                        {t("expenseDateValue", {
                          date: new Date(expense.date).toLocaleDateString("pt-BR", {
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                          }),
                        })}
                      </Typography>
                    }
                  />
                  <InfoCard
                    leading={<ShapeIcon name="IconIdBadge2" variant="neutral" stroke="neutral" size={32} />}
                    title={
                      <Typography weight={600} variant="caption" variantColor={colors.neutral[40]}>
                        {t("fields.id")}
                      </Typography>
                    }
                    subTitle={
                      <Typography weight={600} variant="body4" variantColor={colors.neutral[20]}>
                        {expense.referenceId}
                      </Typography>
                    }
                  />
                  <InfoCard
                    fullWidth
                    leading={<ShapeIcon name="IconQuote" variant="neutral" stroke="neutral" size={32} />}
                    title={
                      <Typography weight={600} variant="caption" variantColor={colors.neutral[40]}>
                        {t("fields.comments")}
                      </Typography>
                    }
                    subTitle={
                      <ReadonlyTextInput>
                        <Typography
                          weight={600}
                          variant="body4"
                          variantColor={colors.neutral[20]}
                          style={{ width: "100%" }}
                        >
                          {expense.comments}
                        </Typography>
                      </ReadonlyTextInput>
                    }
                  />
                </>
              )}
            </Form>
          </Card>
          <StepperCard>
            <ExpenseStatusStepperCard status={expense.status} type={expense.type} />
          </StepperCard>
        </FormContent>
      </Content>
    </Container>
  );
};
