import { Employee } from '../types'

type CreateGroupResponse = {
  name: string
  employeeCount: number
}

export function createGroupArray(employees: Employee[]): CreateGroupResponse[] {
  const groupMap = new Map<string, number>()

  employees.forEach((employee) => {
    employee.groups.forEach((group) => {
      groupMap.set(group, (groupMap.get(group) || 0) + 1)
    })
  })

  return Array.from(groupMap, ([name, employeeCount]) => ({
    name,
    employeeCount,
  })).sort((a, b) => a.name.localeCompare(b.name, 'pt'))
}
