import Typography from '@frontend/components/Typography';
import { Controller } from 'react-hook-form';

import { DatePicker } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import TextField from '@frontend/components/inputs/TextField';
import ToggleField from '@frontend/components/inputs/ToggleField';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useCreateVirtualCardContext } from '../../../data/useCreateVirtualCardContext';

const todayConstant = DateTime.now().startOf('day');
const today = todayConstant.toJSDate();
const fiveYears = todayConstant.plus({ years: 5 }).toJSDate();

const minDate = DateTime.now().plus({ days: 1 }).startOf('day').toJSDate();
const maxDate = DateTime.now()
  .plus({ years: 5, days: -1 })
  .endOf('day')
  .toJSDate();

export function CardDetailsFormSection() {
  const { form } = useCreateVirtualCardContext();
  const [isTemporaryCard, setIsTemporaryCard] = useState(false);

  return (
    <>
      <Typography.Headline8 as="h2" color="neutral_20">
        Detalhes do cartão virtual
      </Typography.Headline8>

      <Controller
        control={form.control}
        name="name"
        render={(ctx) => (
          <Flex direction="column">
            <TextField
              label="Apelido do cartão"
              value={ctx.field.value}
              onChange={ctx.field.onChange}
              error={ctx.fieldState.error?.message}
            />
          </Flex>
        )}
      />

      <Controller
        control={form.control}
        name="expirationDate"
        render={(ctx) => (
          <ToggleField
            title="Cartão temporário"
            description="Defina uma data de expiração para o cartão virtual. Ideal para compras pontuais."
            togglePosition="right"
            toggleAlign="start"
            value={isTemporaryCard}
            onChange={(value) => {
              setIsTemporaryCard(value);
              !value && ctx.field.onChange();
            }}
          >
            {isTemporaryCard && (
              <DatePicker
                onDateChange={(date) =>
                  ctx.field.onChange(
                    date?.add(new Date().getHours(), 'hour')?.toDate(),
                  )
                }
                fromDate={minDate}
                toDate={maxDate}
                value={ctx.field.value}
                label="Data de expiração"
                disabledDate={{
                  before: today,
                  after: fiveYears,
                }}
                error={!!ctx.fieldState.error?.message}
                helperText={ctx.fieldState.error?.message}
              />
            )}
          </ToggleField>
        )}
      />
    </>
  );
}
