export const graphs = ({
  theme,
  firstGraph = [],
  secondGraph = [],
  thirdGraph = [],
  fourthGraph = [],
  second_graph_options,
  third_graph_options,
  fourth_graph_options,
}: {
  firstGraph: { name: string; line: number }[];
  theme: any;
  secondGraph: { [key: string]: string | number }[];
  thirdGraph: { [key: string]: string | number }[];
  fourthGraph: { [key: string]: string | number }[];
  second_graph_options: any;
  third_graph_options: any;
  fourth_graph_options: any;
}) => [
  {
    id: "firstgraph",
    info: {
      title: "Sentimento ao longo dos envios",
      metric: "Sentimento x Data de envio",
      explanation: `Sentimento ao longo dos envios Apresenta a média das notas de como as pessoas se sentiram ao longo de cada envio. Caso o envio não apresente respostas, ele não será exibido.`,
    },
    data: firstGraph,
    settings: {
      line: "Sentimento Atual",
      lineColor: "#F20D7A",
      isPercentage: false,
    },
  },
  {
    id: "secondgraph",
    info: {
      title: "Qual das opções abaixo melhor descreve seu sentimento?",
      metric: "Respostas ao longo do tempo",
      explanation: "",
    },
    data: secondGraph,
    settings: second_graph_options.map((option, index) => {
      return {
        name: option,
        color: {
          0: "#490424",
          1: "#78003C",
          2: "#AA0955",
          3: "#DA0B6D",
          4: "#F20D7A",
          5: "#FE67AF",
          6: "#FF9AC9",
          7: "#FFCCE4",
          8: "#FFD7EB",
          9: "#FFE1F3",
          10: "#FBE5F6",
          11: "#F7F0F9",
        }[index],
      };
    }),
  },
  {
    id: "thirdgraph",
    info: {
      title: "O que foi bom?",
      metric: "Respostas ao longo do tempo",
      explanation: "",
    },
    data: thirdGraph,
    settings: third_graph_options.map((option, index) => {
      return {
        name: option,
        color: {
          0: "#490424",
          1: "#78003C",
          2: "#AA0955",
          3: "#DA0B6D",
          4: "#F20D7A",
          5: "#FE67AF",
          6: "#FF9AC9",
          7: "#FFCCE4",
          8: "#FFD7EB",
          9: "#FFE1F3",
          10: "#FBE5F6",
          11: "#F7F0F9",
        }[index],
      };
    }),
  },
  {
    id: "fourthgraph",
    info: {
      title: "O que poderia melhorar?",
      metric: "Respostas ao longo do tempo",
      explanation: "",
    },
    data: fourthGraph,
    settings: fourth_graph_options.map((option, index) => {
      return {
        name: option,
        color: {
          0: "#490424",
          1: "#78003C",
          2: "#AA0955",
          3: "#DA0B6D",
          4: "#F20D7A",
          5: "#FE67AF",
          6: "#FF9AC9",
          7: "#FFCCE4",
          8: "#FFD7EB",
          9: "#FFE1F3",
          10: "#FBE5F6",
          11: "#F7F0F9",
        }[index],
      };
    }),
  },
];
