import { useContext, useMemo, useState } from "react"
import { cloneDeep } from "lodash-es"

import { Context } from "../../../../../context"
import ArrowRight from "../../../../../assets/arrow_right.svg"

import { uploadHelper } from "@flash-hros/utility"

import { IconButton, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"

import {
  PreviewDocContainer,
  ButtonContainer,
  ConfirmButton,
  PreviewImageContainer,
  Container,
  CloseContainer,
  SectionTitle,
  FlexColumn,
  PreviewTextContainer,
  IconContainer,
  StyledModal,
  ChildrenContainer,
  SectionSubTitle,
} from "./styles"
import dispatchToast from "../../../../../utils/dispatchToast"
import {
  ConfirmationDependent,
  ConfirmationDocumentsWithValue,
  FieldValue,
  HiringCard,
} from "server/src/types"
import { trpc } from "src/api/trpc"
import { RenderField, DeprecatedUpload } from "@Components"
import { MandatoryField } from "../../DependentsStep/RenderDependents"
import { mandatoryFieldsMock } from "@Utils/mocks"
import { convertMandatoryFieldToFieldValue } from "@Utils/index"

interface EditModalProps {
  isOpen: boolean
  handleClose: () => void
  card: HiringCard
  dependent: ConfirmationDependent
}

const EditDependentsModal = ({
  isOpen,
  handleClose,
  card,
  dependent,
}: EditModalProps) => {
  const [localDependent, setLocalDependent] =
    useState<ConfirmationDependent>(dependent)

  const utils = trpc.useContext()
  const { token } = useContext(Context)

  const { mutateAsync: processingFile } =
    trpc.helper.processingFile.useMutation()
  const { mutateAsync: updateDependent } =
    trpc.candidate.updateDependent.useMutation()
  const { mutateAsync: updateDependentFields } =
    trpc.candidate.updateDependentFields.useMutation()

  const handleLocalMandatoryFields = ({
    mandatoryField,
    value,
  }: {
    mandatoryField: string
    value: any
  }) => {
    setLocalDependent((prev) => {
      const clonedDependent = cloneDeep(prev)
      clonedDependent[mandatoryField] = value
      return clonedDependent
    })
  }

  const renderFields = () => {
    return (
      <FlexColumn width="70%">
        {mandatoryFieldsMock.map((field, idx) => (
          <FlexColumn key={idx}>
            <ChildrenContainer>
              <div style={{ width: "100%" }}>
                <RenderField
                  field={convertMandatoryFieldToFieldValue(
                    field,
                    dependent[field.fieldName],
                    card.candidateId
                  )}
                  handleChange={async (value) => {
                    handleLocalMandatoryFields({
                      mandatoryField: field.fieldName,
                      value,
                    })
                  }}
                />
              </div>
            </ChildrenContainer>
          </FlexColumn>
        ))}
        {localDependent.documents.map((document, index) => {
          const fields = document.fields || []
          const filterFileFields =
            fields.filter((f) => f.fieldType === "file") || []
          const filterOtherFields =
            fields.filter((f) => f.fieldType !== "file") || []

          return (
            <FlexColumn key={index}>
              <SectionTitle
                style={{ marginBottom: document.description ? "0px" : "24px" }}
              >
                {document.title}
              </SectionTitle>
              <SectionSubTitle>{document.description}</SectionSubTitle>

              {filterFileFields.length > 0 &&
                filterFileFields.map((f, index) => {
                  const handleFile = async ({
                    name,
                    file,
                  }: {
                    name: any
                    file: any
                  }): Promise<void> => {
                    const { companyId, candidateId } = card

                    const url = `${
                      companyId +
                      "/" +
                      candidateId +
                      "/" +
                      f.customFieldId +
                      "/" +
                      name
                    }`
                    if (!companyId || !candidateId || !f.customFieldId || !name)
                      return

                    const { key, path } = await uploadHelper({
                      key: url,
                      file,
                      fileName: name,
                      module: "employee-pii",
                      externalToken: token,
                    })

                    const processedFile = await processingFile({ path: path })

                    const value = {
                      key,
                      path,
                      type: "internal",
                      value: processedFile,
                    }

                    setLocalDependent((prev) => {
                      const dependent = cloneDeep(prev)

                      const documentIndex = dependent.documents.findIndex(
                        (doc) => doc._id === document._id
                      )
                      if (documentIndex === -1) return prev
                      const fieldIndex = dependent.documents[
                        documentIndex
                      ].fields.findIndex(
                        (field) => field.customFieldId === f.customFieldId
                      )
                      if (fieldIndex === -1) return prev

                      dependent.documents[documentIndex].fields[
                        fieldIndex
                      ].value = value
                      return dependent
                    })
                  }

                  const { value } = f.value || { value: null }

                  return value ? (
                    <ChildrenContainer key={index}>
                      <PreviewDocContainer
                        onClick={(e) => {
                          e.stopPropagation()
                          window.open(value, "_blank")
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <PreviewImageContainer url={value} />
                          <PreviewTextContainer style={{ marginLeft: "10px" }}>
                            <Typography variant="body3">
                              {f.placeholder}
                            </Typography>
                          </PreviewTextContainer>
                        </div>
                        <IconContainer
                          onClick={async (e: any) => {
                            e.stopPropagation()
                            e.preventDefault()

                            setLocalDependent((prev) => {
                              const dependent = cloneDeep(prev)

                              const documentIndex =
                                dependent.documents.findIndex(
                                  (doc) => doc._id === document._id
                                )
                              if (documentIndex === -1) return prev
                              const fieldIndex = dependent.documents[
                                documentIndex
                              ].fields.findIndex(
                                (field) =>
                                  field.customFieldId === f.customFieldId
                              )
                              if (fieldIndex === -1) return prev

                              dependent.documents[documentIndex].fields[
                                fieldIndex
                              ].value = null
                              return dependent
                            })
                          }}
                          style={{
                            backgroundColor: "#fff",
                            border: "1px solid #EBE5E9",
                            marginLeft: "10px",
                          }}
                        >
                          <Icons name="IconTrash" fill={"transparent"} />
                        </IconContainer>
                      </PreviewDocContainer>
                    </ChildrenContainer>
                  ) : (
                    <ChildrenContainer key={index}>
                      <DeprecatedUpload
                        onChange={handleFile}
                        label={f.placeholder}
                        accept={["pdf", "png", "jpg", "jpeg"]}
                        maxSize={5242880}
                        onFileSizeError={() => {
                          dispatchToast({
                            content:
                              "Arquivo maior que 5mb. Por favor, faça upload de arquivo menor que 5mb",
                            type: "warning",
                          })
                        }}
                      />
                    </ChildrenContainer>
                  )
                })}

              {filterOtherFields.length > 0 &&
                filterOtherFields.map((f, index) => {
                  return (
                    <ChildrenContainer key={index}>
                      <div style={{ width: "100%" }}>
                        <RenderField
                          field={f}
                          handleChange={async (value) => {
                            setLocalDependent((prev) => {
                              const dependent = cloneDeep(prev)

                              const documentIndex =
                                dependent.documents.findIndex(
                                  (doc) => doc._id === document._id
                                )
                              if (documentIndex === -1) return prev
                              const fieldIndex = dependent.documents[
                                documentIndex
                              ].fields.findIndex(
                                (field) =>
                                  field.customFieldId === f.customFieldId
                              )
                              if (fieldIndex === -1) return prev

                              dependent.documents[documentIndex].fields[
                                fieldIndex
                              ].value = value
                              return dependent
                            })
                          }}
                        />
                      </div>
                    </ChildrenContainer>
                  )
                })}
            </FlexColumn>
          )
        })}
      </FlexColumn>
    )
  }

  const handleSubmit = async () => {
    await updateDependent({
      dependentId: localDependent._id,
      params: {
        name: localDependent.name,
        birthDate: localDependent.birthDate,
        kinship: localDependent.kinship,
        maritalStatus: localDependent.maritalStatus,
      },
    })

    const localFields: FieldValue[] = localDependent.documents.reduce(
      (prev: any, curr: ConfirmationDocumentsWithValue) => {
        return [...prev, ...curr.fields]
      },
      []
    )
    const originalFields: FieldValue[] = dependent.documents.reduce(
      (prev: any, curr: ConfirmationDocumentsWithValue) => {
        return [...prev, ...curr.fields]
      },
      []
    )

    const editedFields = localFields.filter((field) => {
      const originalField = originalFields.find(
        (ogField) => ogField.customFieldId === field.customFieldId
      )

      if (originalField && originalField.value !== field.value) return true

      return false
    })

    const parsedFieldsToUpdate = editedFields.map((field) => ({
      fieldId: field.fieldId,
      customFieldId: field.customFieldId,
      documentTypeId: field.documentTypeId,
      type: field.fieldType,
      formGroupId: field.formGroupId,
      value: field.value || "",
      expirationDate: null,
    }))

    if (parsedFieldsToUpdate.length && parsedFieldsToUpdate.length > 0)
      await updateDependentFields({
        dependentId: dependent._id,
        params: {
          values: parsedFieldsToUpdate,
        },
      })

    utils.wizard.invalidate()

    dispatchToast({
      type: "success",
      content: "Campos atualizados com sucesso!",
    })

    handleClose()
  }

  return (
    <StyledModal
      open={isOpen}
      onClose={() => {
        handleClose()
      }}
    >
      <Container>
        <CloseContainer>
          <IconButton
            onClick={() => {
              handleClose()
            }}
            size="medium"
            variant="line"
            icon="IconX"
          />
        </CloseContainer>

        <FlexColumn style={{ maxWidth: "535px", marginBottom: "63px" }}>
          <Typography
            variant="headline6"
            style={{ marginBottom: "4px", fontWeight: 700 }}
          >
            Editar documento
          </Typography>
        </FlexColumn>

        {renderFields()}

        <ButtonContainer style={{ paddingTop: "60px", paddingBottom: "20px" }}>
          <ConfirmButton onClick={() => handleSubmit()}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body3" style={{ color: "#fff" }}>
                Confirmar
              </Typography>
              <ArrowRight style={{ color: "#fff", marginLeft: "7px" }} />
            </div>
          </ConfirmButton>
        </ButtonContainer>
      </Container>
    </StyledModal>
  )
}

export default EditDependentsModal
