import { Children, createContext, isValidElement, ReactElement, ReactNode, useContext } from "react"

import { Draggable, DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd"

import { dayjs } from "@utils"
import { CardsList } from "./CardsList"
import { Header } from "./Header"
import * as SC from "./styled"

export type ColumnProps = {
  id: string
  columnType: KanbanColumnType
  dataOnboarding?: string
  children:
    | (ReactElement<typeof Header> | ReactElement<typeof CardsList>)
    | (ReactElement<typeof Header> | ReactElement<typeof CardsList>)[]
}

export type IndexedColumnProps = ColumnProps & { index: number }

export enum KanbanColumnType {
  Primary = "PRIMARY",
  Neutral = "NEUTRAL",
}

type ColumnContextData = {
  id: string
  columnType: KanbanColumnType
}

type CardsListContextData = {
  isDragging: boolean
}

const ColumnContext = createContext({
  id: dayjs().toISOString(),
  columnType: KanbanColumnType.Primary,
} as ColumnContextData)

const CardsListContext = createContext({
  isDragging: false,
} as CardsListContextData)

export const Column: React.FC<ColumnProps> = (props) => {
  return <>{props.children}</>
}

export const IndexedColumn: React.FC<IndexedColumnProps> = ({ dataOnboarding, children, columnType, id, index }) => {
  let HeaderChild: ReactNode
  let CardsListChild: ReactNode

  Children.forEach(children, (child) => {
    if (isValidElement(child)) {
      switch (child.type) {
        case Header:
          HeaderChild = child
          break
        case CardsList:
          CardsListChild = child
          break
        default:
          break
      }
    }
  })

  return (
    <ColumnContext.Provider value={{ id, columnType }}>
      <SC.ColumnWrapper>
        {HeaderChild}
        <SC.DraggableCardsListWrapper>
          <Draggable draggableId={id} index={index} isDragDisabled>
            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
              const isDragging = snapshot?.isDragging ?? false
              return (
                <CardsListContext.Provider value={{ isDragging }}>
                  <SC.CardsListWrapper
                    ref={provided.innerRef}
                    data-onboarding={dataOnboarding}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {CardsListChild}
                  </SC.CardsListWrapper>
                </CardsListContext.Provider>
              )
            }}
          </Draggable>
        </SC.DraggableCardsListWrapper>
      </SC.ColumnWrapper>
    </ColumnContext.Provider>
  )
}

export const useColumnContext = () => {
  const context = useContext(ColumnContext)

  if (!context) {
    throw new Error("useColumnContext must be used within a ColumnContextProvider")
  }

  return context
}

export const useCardsListContext = () => {
  const context = useContext(CardsListContext)

  if (!context) {
    throw new Error("useCardsListContext must be used within a CardsListContextProvider")
  }

  return context
}
