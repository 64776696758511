import { useRegisterRoutes } from "@flash-tecnologia/hros-web-utility";
import { BrowserRouter, Routes as ReactRoutes, Route } from "react-router-dom";

import {
  AdminCategoriesPage,
  AdminMovementEditPage,
  AdminMovementPage,
  AdminReimbursementEditPage,
  AdminReimbursementPage,
  AdminReimbursementsPage,
  AdminStatementPage,
  MovementPage,
  ReimbursementCreatePage,
  ReimbursementEditPage,
  ReimbursementPage,
  ReimbursementsPage,
  StatementPage,
  StatementPendingItemsPage,
} from "$pages";
import { ProtectedRoutes } from "./ProtectedRoutes";
import { Routes } from "./routes";

const AppRouter = () => {
  const pathsWithoutHeader = [
    Routes.MOVEMENT,
    Routes.STATEMENT_PENDING_ITEMS,
    Routes.REIMBURSEMENT,
    Routes.REIMBURSEMENT_CREATE,
  ];

  useRegisterRoutes(pathsWithoutHeader.map((path) => ({ path, hideHeader: true })));

  return (
    <BrowserRouter>
      <ReactRoutes>
        <Route element={<ProtectedRoutes />}>
          {/* Admin Routes for Categories Management */}
          <Route path={Routes.ADMIN_CATEGORIES} element={<AdminCategoriesPage />} />

          {/* Admin Routes for Reimbursements Management */}
          <Route path={Routes.ADMIN_REIMBURSEMENTS} element={<AdminReimbursementsPage />} />
          <Route path={Routes.ADMIN_REIMBURSEMENT} element={<AdminReimbursementPage />} />
          <Route path={Routes.ADMIN_REIMBURSEMENT_EDIT} element={<AdminReimbursementEditPage />} />

          {/* Admin Routes for Statement and Movement Management */}
          <Route path={Routes.ADMIN_STATEMENT} element={<AdminStatementPage />} />
          <Route path={Routes.ADMIN_MOVEMENT} element={<AdminMovementPage />} />
          <Route path={Routes.ADMIN_MOVEMENT_EDIT} element={<AdminMovementEditPage />} />

          {/* User Routes for Reimbursements list and visualize a Reimbursement */}
          <Route path={Routes.REIMBURSEMENTS} element={<ReimbursementsPage />} />
          <Route path={Routes.REIMBURSEMENT} element={<ReimbursementPage />} />
          <Route path={Routes.REIMBURSEMENT_EDIT} element={<ReimbursementEditPage />} />
          <Route path={Routes.REIMBURSEMENT_CREATE} element={<ReimbursementCreatePage />} />

          {/* User Routes for Statement list and visualize a Movement */}
          <Route path={Routes.STATEMENT} element={<StatementPage />} />
          <Route path={Routes.MOVEMENT} element={<MovementPage />} />
          <Route path={Routes.STATEMENT_PENDING_ITEMS} element={<StatementPendingItemsPage />} />
        </Route>
      </ReactRoutes>
    </BrowserRouter>
  );
};

export default AppRouter;
