import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledCard = styled.div`
  padding: 12px 16px;
  border: solid 1px ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledProcessingLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const StyledCompletedLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
`;

export const StyledFooter = styled.div<{ cursor?: string }>`
  display: flex;
  align-items: center;
  gap: 4px;
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
`;

export const StyledDownloadIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.secondary[50]};
`;
