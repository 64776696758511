import { ArcElement, Chart } from "chart.js";
import { Doughnut } from "react-chartjs-2";

Chart.register(ArcElement);
/**
 * Props for the DoughnutChart component.
 */
type DoughnutChartProps = {
  /**
   * An array of labels for the chart.
   */
  label: string[];

  /**
   * The data for the chart, including labels and datasets.
   */
  data: {
    /**
     * An array of labels for the chart.
     */
    labels: string[];

    /**
     * An array of datasets for the chart.
     * Each dataset contains data points, background colors, and border width.
     */
    datasets: {
      /**
       * An array of data points for the dataset.
       */
      data: number[];

      /**
       * An array of background colors for the dataset.
       */
      backgroundColor: string[];

      /**
       * The border width for the dataset.
       */
      borderWidth: number;
    }[];
  };

  /**
   * The size of the chart.
   */
  size: number;

  /**
   * The portion of the chart that is cut out of the middle.
   * ('50%' - for doughnut, 0 - for pie) String ending with '%' means percentage, number means pixels.
   */
  cutout: string;
};

export const DoughnutChart = ({ label, data, size, cutout }: DoughnutChartProps) => {
  return (
    <Doughnut
      data={data}
      aria-label={label.join(", ")}
      height={size}
      width={size}
      options={{
        cutout: cutout,
        animation: false,
        responsive: false,
        events: [],
      }}
    />
  );
};
