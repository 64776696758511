import { colors } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: ${({ theme }) =>
    `${theme.borders.width.xs2} solid ${colors.get('neutral.90')}`};
  border-radius: ${({ theme }) => theme.borders.radius.l};
  padding: ${({ theme }) => theme.spacings.xs};
  gap: ${({ theme }) => theme.spacings.xs3};
  text-align: center;
`;
