import { IconProps, PillButton } from '@components';
import { Drawer as DrawerMUI } from '@mui/material';

import { DrawerActions, DrawerActionsProps } from './DrawerActions';
import { DrawerHeader } from './DrawerHeader';
import * as SC from './styled';

type Props = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  highlight?: React.ReactNode;
  icon?: IconProps['name'];
  isOpen: boolean;
  anchor: React.ComponentProps<typeof DrawerMUI>['anchor'];
  onClose(): void;
  children: React.ReactNode;
  actions?: DrawerActionsProps['actions'];
};

export function Drawer(props: Props) {
  return (
    <DrawerMUI open={props.isOpen} anchor={props.anchor} onClose={props.onClose}>
      <SC.CloseContainer>
        <PillButton variant="default" icon="IconX" size="sm" onClick={props.onClose} />
      </SC.CloseContainer>

      <DrawerHeader
        title={props.title}
        subtitle={props.subtitle}
        highlight={props.highlight}
        icon={props.icon}
      />

      <SC.Body>{props.children}</SC.Body>

      {props.actions?.length > 0 && <DrawerActions actions={props.actions} />}
    </DrawerMUI>
  );
}
