import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const WarningBadgeContainer = styled.div`
  position: absolute;
  top: -${(props) => props.theme.spacings.xs5};
  right: -${(props) => props.theme.spacings.xs5};
  border-radius: 100px;
  background: ${(props) => props.theme.colors.neutral[30]};
  display: flex;
  width: ${(props) => props.theme.spacings.xs2};
  height: ${(props) => props.theme.spacings.xs2};
  justify-content: center;
  align-items: center;
`;

export const SpanNumber = styled(Typography).attrs({ variant: "caption", weight: 700 })`
  color: ${(props) => props.theme.colors.neutral[100]};
`;

export const Container = styled.div`
  position: relative;
`;
