import { IconTypes } from "@flash-tecnologia/hros-web-ui-v2";

import { ColorVariant, SizeVariant } from "$frontend/themes/types/variants";
import { Icon, ShapeIconContainer } from "./styled";

export type ShapeIconProps = {
  /**
   * Icon content
   * @default IconAlertCircle
   */
  icon: IconTypes;

  /**
   * Sets the size
   *
   * @default medium
   */
  size: SizeVariant;

  /**
   * Sets the variant theme
   *
   * @default secondary
   */
  variant?: ColorVariant;

  $foregroundColor?: string;
};

export const ShapeIcon = ({
  icon = "IconAlertCircle",
  size = "medium",
  variant = "secondary",
  $foregroundColor,
}: ShapeIconProps) => {
  const iconSize: Record<SizeVariant, number> = {
    large: 34,
    medium: 24,
    small: 32,
    extra_small: 24,
    extra_small_2: 16,
    extra_small_3: 12,
    extra_small_4: 8,
    extra_small_5: 4,
    extra_large: 80,
    extra_large_2: 96,
    extra_large_3: 120,
    extra_large_4: 144,
    extra_large_5: 160,
  };

  return (
    <ShapeIconContainer variant={variant} size={size}>
      <Icon
        name={icon}
        variant={variant}
        size={iconSize[size]}
        fill="transparent"
        $foregroundColor={$foregroundColor}
      />
    </ShapeIconContainer>
  );
};
