import { Button, useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2";

import {
  StyledFooterBar,
  StyledButtonContainer,
  StyledLinkButton,
} from "./styled";

const FooterBar = ({
  nextHandleClick,
  previousHandleClick,
  nextHandleButtonLabel,
  previousHandleButtonLabel,
  disabled,
}: {
  nextHandleClick: any;
  previousHandleClick: any;
  nextHandleButtonLabel?: any;
  previousHandleButtonLabel?: any;
  disabled: boolean;
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <StyledFooterBar>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <StyledLinkButton
          variant="default"
          style={{ alignSelf: "center" }}
          onClick={previousHandleClick}
        >
          {previousHandleButtonLabel ? previousHandleButtonLabel : "Voltar"}
        </StyledLinkButton>
        <StyledButtonContainer>
          <Button
            size={isMobile ? "small" : "large"}
            variant="primary"
            style={{ marginLeft: "24px", alignSelf: "center", width: "250px" }}
            onClick={nextHandleClick}
            disabled={!disabled}
          >
            {nextHandleButtonLabel ? nextHandleButtonLabel : "Confirmar"}
          </Button>
        </StyledButtonContainer>
      </div>
    </StyledFooterBar>
  );
};

export { FooterBar };
