import { ButtonProps as DSButtonProps } from "@flash-tecnologia/hros-web-ui-v2";

import { PrimaryButton, PrimaryButtonLabel, Button as StyledButton } from "./styled";

type ButtonProps = DSButtonProps & {
  customVariant?: "primary" | "secondary";
};

export const Button = (props: ButtonProps) => {
  if (props.customVariant === "secondary") {
    return <StyledButton />;
  }

  return (
    <PrimaryButton>
      <PrimaryButtonLabel>{props.children}</PrimaryButtonLabel>
    </PrimaryButton>
  );
};
