import styled from "styled-components";

export const Container = styled.div<{ fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : undefined)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Content = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : undefined)};
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacings.xs3};
`;

export const Body = styled.div<{ fullWidth?: boolean; alignItems?: string }>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : undefined)};
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems || "flex-start"};
`;

export const Leading = styled.div``;

export const Trailing = styled.div<{ fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : undefined)};
`;
