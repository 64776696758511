import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { createContext, useContext } from "react";
import { useForm, UseFormReturn } from "react-hook-form";

import { ExpenseStatus } from "$serverTypes";
import { getDateRange } from "$utils";

export type AdminReimbursementsState = {
  filters: {
    status: ExpenseStatus[];
    categoryNanoIds: string[];
    dateRange: { from: Date; to: Date };
    valueRange: { max?: number; min?: number };
  };
  search: string;
  pagination: PaginationState;
};

const defaultValues: AdminReimbursementsState = {
  filters: {
    status: [ExpenseStatus.PENDING_ACCOUNTING],
    categoryNanoIds: [],
    dateRange: getDateRange(),
    valueRange: { min: undefined, max: undefined },
  },
  search: "",
  pagination: { pageNumber: 1, pageSize: 10 },
};

export type ISessionProviderProps = {
  children: React.ReactNode;
};

type AdminReimbursementsContextProps = {
  methods: UseFormReturn<AdminReimbursementsState>;
};

const AdminReimbursementsContext = createContext<AdminReimbursementsContextProps | undefined>(undefined);

export const AdminReimbursementsProvider = ({ children }: ISessionProviderProps) => {
  const methods = useForm<AdminReimbursementsState>({ defaultValues });

  return <AdminReimbursementsContext.Provider value={{ methods }}>{children}</AdminReimbursementsContext.Provider>;
};

export const useAdminReimbursementsContext = () => {
  const context = useContext(AdminReimbursementsContext);
  if (!context) {
    throw new Error("useAdminReimbursementsContext must be used within an AdminReimbursementsProvider");
  }
  return context;
};
