import { trpc } from '@frontend/trpc';

export function useIsNaasEnabled() {
  const { data, isLoading, error } =
    trpc.corporateCard.userStatement.isNaasEnabled.useQuery(undefined, {
      cacheTime: 10000000000,
      staleTime: 10000000000,
      refetchOnWindowFocus: false,
    });

  return {
    enabled: !!data,
    isLoading,
    hasError: !!error,
  };
}
