export type TServiceName = keyof typeof services;

export const services = {
  accessManagement: {
    development: "https://hros-access-management.private.flashapp.dev",
    staging: "https://hros-access-management.private.flashapp.dev",
    production: "https://hros-access-management.us.flashapp.services",
  },
  aws: {
    // development: "http://localhost:2999",
    development: "https://aws-utilities-service.private.flashapp.dev",
    staging: "https://aws-utilities-service.private.flashapp.dev",
    production: "https://aws-utilities-service.us.flashapp.services",
  },
  company: {
    // development: "http://localhost:3003",
    development: "https://company-management.private.flashapp.dev",
    staging: "https://company-management.private.flashapp.dev",
    production: "https://company-management.us.flashapp.services",
  },
  corporateCardBff: {
    // development: 'http://localhost:2022/trpc',
    development:
      "https://hros-web-corporate-card-backend-preview.private.flashapp.dev/trpc",
    staging:
      "https://hros-web-corporate-card-backend-preview.private.flashapp.dev/trpc",
    production:
      "https://hros-web-corporate-card-backend.us.flashapp.services/trpc",
  },
  employee: {
    // development: "http://localhost:3001",
    development: "https://employee-pii.private.flashapp.dev",
    staging: "https://employee-pii.private.flashapp.dev",
    production: "https://employee-pii.us.flashapp.services",
  },
  hiring: {
    // development: "http://localhost:3002",
    development: "https://hros-hiring.private.flashapp.dev",
    staging: "https://hros-hiring.private.flashapp.dev",
    production: "https://hros-hiring.us.flashapp.services",
  },
  lms: {
    // development: "http://localhost:3003",
    development: "https://learning-management-system.private.flashapp.dev",
    staging: "https://learning-management-system.private.flashapp.dev",
    production: "https://learning-management-system.us.flashapp.services",
  },
  tokenManager: {
    development: "https://hros-token-manager.private.flashapp.dev",
    staging: "https://hros-token-manager.private.flashapp.dev",
    production: "https://hros-token-manager.us.flashapp.services",
  },
  engagement: {
    // development: "http://localhost:9013",
    development: "https://engagement.private.flashapp.dev",
    staging: "https://engagement.private.flashapp.dev",
    production: "https://engagement.us.flashapp.services",
  },
  permission: {
    // development: "http://localhost:3004",
    development: "https://hros-service-permission.private.flashapp.dev",
    staging: "https://hros-service-permission.private.flashapp.dev",
    production: "https://hros-service-permission.us.flashapp.services",
  },
  permissionV2: {
    development: "https://hros-web-permissions-bff.private.flashapp.dev",
    staging: "https://hros-web-permissions-bff.private.flashapp.dev",
    production: "https://hros-web-permissions-bff.us.flashapp.services",
  },
  legacyCompany: {
    //development: "http://localhost:4949",
    development: "https://dev.api.flashapp.com.br/c",
    staging: "https://dev.api.flashapp.com.br/c",
    production: "https://api.flashapp.com.br/c",
  },
  contractManager: {
    // development: "http://localhost:3000",
    development: "https://hros-service-contract-manager.private.flashapp.dev",
    staging: "https://hros-service-contract-manager.private.flashapp.dev",
    production: "https://hros-service-contract-manager.us.flashapp.services",
  },
  expenseManagement: {
    development: "https://flash-expense-gateway.private.flashapp.dev",
    staging: "https://flash-expense-gateway.private.flashapp.dev",
    production: "https://flash-expense-gateway.us.flashapp.services",
  },
  backwardMigration: {
    // development: "http://localhost:3000",
    development: "https://hros-backward-migration.private.flashapp.dev",
    staging: "https://hros-backward-migration.private.flashapp.dev",
    production: "https://hros-backward-migration.us.flashapp.services",
  },
  billingManager: {
    // development: "http://localhost:3004",
    development: "https://billing-manager.private.flashapp.dev",
    staging: "https://billing-manager.private.flashapp.dev",
    production: "https://billing-manager.us.flashapp.services",
  },
  folhaCertaAuth: {
    development: "https://dev-auth.folhacerta.com",
    staging: "https://dev-auth.folhacerta.com",
    production: "https://auth.folhacerta.com",
  },
  expenseCoreBff: {
    // development: "http://localhost:6721",
    development: "https://expense-core-bff.private.flashapp.dev/v1/bff",
    staging: "https://expense-core-bff.private.flashapp.dev/v1/bff",
    production: "https://expense-core-bff.us.flashapp.services/v1/bff",
  },
} as const;

export const gatewayServices: (keyof typeof services)[] = ["expenseCoreBff"];
