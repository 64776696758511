import { useMemo, useState } from "react";
import { useNavigate, useParams, createSearchParams } from "react-router-dom";

import { Button, Menu, PillButton } from "@flash-tecnologia/hros-web-ui-v2";

import { trpc } from "@api/client";

import { routes } from "@routes";

import { dispatchToast, StyledIcon, StyledText, track } from "@utils";

import { DataGrid } from "@components/DataGrid";
import { ProfileTable } from "@components/ProfileTable";
import { ProgressBar } from "@components/ProgressBar";
import { SendReminderModal } from "@components/Modals";

import { BulkActions } from "./BulkActions";

import { Option, RowContainer } from "./styled";

import type { TColumnProps } from "@components/DataGrid/types";
import type { TableGridCommonProps } from "@components/Table/types";

import type { CycleIdpEmployees, IReminderModalProps } from "../../types";

interface IBulkActionsGridProps extends TableGridCommonProps {
  data: CycleIdpEmployees[];
}

export const BulkActionsGrid = ({ data, loading }: IBulkActionsGridProps) => {
  const { id = "" } = useParams();
  const navigate = useNavigate();

  const [reminderModal, setReminderModal] = useState<IReminderModalProps>({
    open: false,
    peopleToSend: [],
  });

  const columns = useMemo<TColumnProps<any>>(
    () => [
      {
        header: "Pessoa",
        accessorKey: "name",
        cell: ({ row: { original } }) => (
          <ProfileTable name={original?.name || "-"} email={original?.email} />
        ),
      },
      {
        header: "Líder",
        accessorKey: "reportsToName",
        minSize: 150,
        cell: ({ row: { original } }) => (
          <StyledText
            variant="body3"
            children={original?.reportsToName || "-"}
          />
        ),
      },
      {
        header: "Total de planos",
        accessorKey: "total",
        minSize: 130,
        cell: ({ row: { original } }) => (
          <StyledText variant="body3" children={original?.total} />
        ),
      },
      {
        header: "Planos não iniciados",
        accessorKey: "created",
        minSize: 150,
        cell: ({ row: { original } }) => (
          <div style={{ width: "150px" }}>
            <StyledText variant="body3" children={original?.created} />
          </div>
        ),
      },
      {
        header: "Planos em andamento",
        accessorKey: "progressing",
        cell: ({ row: { original } }) => (
          <StyledText variant="body3" children={original?.progressing} />
        ),
      },
      {
        header: "Planos atrasados",
        accessorKey: "delayed",
        minSize: 150,
        cell: ({ row: { original } }) => (
          <StyledText variant="body3" children={original?.delayed} />
        ),
      },
      {
        header: "Planos concluídos",
        accessorKey: "finished",
        minSize: 150,
        cell: ({ row: { original } }) => (
          <StyledText variant="body3" children={original?.finished} />
        ),
      },
      {
        header: "Taxa de conclusão",
        accessorKey: "completionRate",
        cell: ({ row: { original } }) => (
          <ProgressBar progress={original?.completionRate?.toFixed(2)} />
        ),
      },
      {
        header: "Ações",
        accessorKey: "action",
        sticky: "right",
        cell: ({ row: { original } }) => {
          const employeeId = original?._id || "";
          return (
            <>
              <RowContainer>
                {original.total === 0 && (
                  <Button
                    variant="primary"
                    style={{ width: "198px" }}
                    onClick={() => {
                      track({
                        name: "people_strategic_hr_performance_company_cycles_cycledetails_idps_sendreminder_clicked",
                      });
                      setReminderModal({
                        open: true,
                        peopleToSend: [original?.reportsTo],
                      });
                    }}
                  >
                    Enviar lembrete <StyledIcon name="IconMail" />
                  </Button>
                )}
                <Menu
                  type={"select"}
                  options={[
                    {
                      onClick: () => {
                        navigate({
                          pathname: routes.PagePersonDetails(
                            "cycle-details",
                            employeeId
                          ),
                          search: createSearchParams({
                            cycleId: id,
                          }).toString(),
                        });
                      },
                      children: (
                        <Option>
                          <StyledIcon
                            name="IconUser"
                            fill="transparent"
                            setColor="neutral40"
                            size={25}
                            style={{ marginRight: "12px" }}
                          />
                          <StyledText variant="body3" setColor="neutral40">
                            Ver detalhes da pessoa
                          </StyledText>
                        </Option>
                      ),
                    },
                    {
                      onClick: () => {
                        navigate(
                          routes.PageCreateIDP.replace(
                            ":origin",
                            "manage-idps"
                          ).replace(":step", "1") + `/to/${original._id}`
                        );
                      },
                      children: (
                        <Option>
                          <StyledIcon
                            name="IconPlus"
                            fill="transparent"
                            setColor="neutral40"
                            size={25}
                            style={{ marginRight: "12px" }}
                          />
                          <StyledText variant="body3" setColor="neutral40">
                            Criar Plano de Ação
                          </StyledText>
                        </Option>
                      ),
                    },
                  ]}
                  disableAutoFocusItem={true}
                  anchorOrigin={{ vertical: "center", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <PillButton
                    variant="default"
                    size="small"
                    type="secondary"
                    icon="IconDotsVertical"
                  />
                </Menu>
              </RowContainer>
            </>
          );
        },
      },
    ],
    [data]
  );

  const { mutate, isLoading } =
    trpc.performance.communication.sendReminder.useMutation({
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: "Lembrete enviado com sucesso!",
        });
        setReminderModal({ open: false, peopleToSend: [] });
      },
      onError: (e: any) => {
        const companyNotExists = e?.data?.error === "COMPANY_NOT_EXISTS_ERROR";

        const cycleNotExists = e?.data?.error === "CYCLE_NOT_EXISTS_ERROR";

        const employeeNotExists =
          e?.data?.error === "EMPLOYEE_NOT_EXISTS_ERROR";

        let message = "Erro ao enviar lembrete, tente novamente em breve.";

        if (companyNotExists)
          message = "Erro ao enviar lembrete, empresa não encontrada!";
        if (cycleNotExists)
          message = "Erro ao enviar lembrete, ciclo não encontrado!";
        if (employeeNotExists)
          message = "Erro ao enviar lembrete, colaborador não encontrado!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  return (
    <>
      <DataGrid
        data={data}
        columns={columns}
        options={{
          selectable: (row) => row._id,
        }}
        loading={loading}
        empty={{
          isFiltered: true,
          message: "Nenhuma pessoa foi encontrada!",
        }}
        bulkActionsRender={(props) => {
          return (
            <BulkActions
              {...props}
              data={data}
              setReminderModal={setReminderModal}
            />
          );
        }}
      />
      <SendReminderModal
        open={reminderModal?.open}
        onClose={() => setReminderModal({ open: false, peopleToSend: [] })}
        isLoading={isLoading}
        origin="idp"
        peopleToSend={reminderModal?.peopleToSend?.length}
        onConfirm={() => {
          if (!reminderModal?.peopleToSend?.length) return;
          track({
            name: "people_strategic_hr_performance_company_cycles_cycledetails_idps_confirmationmodal_sendreminder_clicked",
          });
          mutate({
            cycleId: id,
            employeeIds: reminderModal?.peopleToSend,
            type: "idp",
          });
        }}
      />
    </>
  );
};
