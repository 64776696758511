import { ModuleErrorBoundary } from "$atoms";
import { external as CorporateCardExternal } from "@flash-hros/corporate-card";

export const CorporateCardEmptyStateSection = () => {
  return (
    <ModuleErrorBoundary moduleName="corporate-card">
      <CorporateCardExternal.ExternalRoot>
        <CorporateCardExternal.EmptyStateSection />
      </CorporateCardExternal.ExternalRoot>
    </ModuleErrorBoundary>
  );
};
