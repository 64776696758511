import { MouseEvent, useState } from "react";
import { Container, Image } from "./styled";

type ImageZoomProps = {
  src: string;
  alt?: string;
};

export const ImageZoom = ({ src, alt }: ImageZoomProps) => {
  const [zoom, setZoom] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  function handleClick(e: MouseEvent<HTMLDivElement>) {
    if (!zoom) {
      const { left, top } = e.currentTarget.getBoundingClientRect();
      setPosition({ x: e.clientX - left, y: e.clientY - top });
    } else {
      setOffset({ x: 0, y: 0 });
    }

    setZoom(!zoom);
  }

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    const { left, top } = e.currentTarget.getBoundingClientRect();
    setOffset({ x: e.clientX - left - position.x, y: e.clientY - top - position.y });
  };

  return (
    <Container
      zoom={zoom}
      onClick={handleClick}
      onMouseMove={zoom ? handleMouseMove : undefined}
      onMouseLeave={zoom ? () => setOffset({ x: 0, y: 0 }) : undefined}
    >
      <Image src={src} alt={alt} zoom={zoom} x={position.x + offset.x} y={position.y + offset.y} />
    </Container>
  );
};
