import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { MainContainer, Container, Number } from './styles';
import { MouseEventHandler } from 'react';
import { useGetTotalNewNotifications } from '@/hooks/use-get-total-new-notifications';
import { useGetUnacknowledgeInboxMessages } from '@/hooks/use-get-unacknowledged-inbox-messages';

export interface BellNotificationProps {
  value?: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const BellNotification = ({ onClick }: BellNotificationProps) => {
  const { data: totalNewNotifications } = useGetTotalNewNotifications();
  const { data: unacknowledgeInboxMessages } =
    useGetUnacknowledgeInboxMessages();
  let value = 0;

  if (
    totalNewNotifications !== undefined &&
    unacknowledgeInboxMessages !== undefined
  ) {
    value = totalNewNotifications + unacknowledgeInboxMessages;
  }

  return (
    <MainContainer onClick={(e) => (onClick ? onClick(e) : null)}>
      {value ? <Number>{value}</Number> : null}
      <Container>
        <Icons
          name="IconMail"
          size={16}
          fill="none"
          color="var(--color-neutral-50)"
        />
      </Container>
    </MainContainer>
  );
};

export default BellNotification;
