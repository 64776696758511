import styled, { type DefaultTheme } from 'styled-components';

type SpacerProps = {
  x?: keyof DefaultTheme['spacings'];
  y?: keyof DefaultTheme['spacings'];
  useMargin?: boolean;
};

export const Spacer = styled.div<SpacerProps>(({ theme, x, y, useMargin }) => ({
  [!useMargin ? 'width' : 'margin-right']: x ? theme.spacings[x] : 0,
  [!useMargin ? 'height' : 'margin-top']: y ? theme.spacings[y] : 0,
}));
