import {
  colors,
  Icons,
  IconsProps,
  ShapeIconProps,
} from '@flash-tecnologia/hros-web-ui-v2';

import * as SC from './styled';

type ColorToken = Parameters<typeof colors.get>[number];

export type IconProps = {
  name: IconsProps['name'];
  color: ColorToken;
  onClick?: () => void;
} & (
  | {
      size?: number;
      background?: false;
    }
  | {
      size?: keyof typeof shapeIconSize;
      background: ShapeIconProps['variant'];
    }
);

export function Icon(props: IconProps) {
  if (props.background) {
    const size = props.size ?? 24;
    return (
      <SC.ShapeIcon size={size} background={props.background}>
        <Icons
          name={props.name}
          fill="transparent"
          color={colors.get(props.color)}
          size={shapeIconSize[size]}
          style={{ flexShrink: 0 }}
          onClick={props.onClick}
        />
      </SC.ShapeIcon>
    );
  }

  return (
    <Icons
      name={props.name}
      fill="transparent"
      color={colors.get(props.color)}
      size={props.size}
      style={{ flexShrink: 0 }}
      onClick={props.onClick}
    />
  );
}
const shapeIconSize = {
  24: 16,
  32: 16,
  40: 16,
  48: 24,
  56: 32,
  64: 40,
  72: 40,
  96: 64,
} as const;
