import { Dot, ShapeIcon, Tag } from '@flash-tecnologia/hros-web-ui-v2';
import Flex from '@frontend/components/frames/Flex';
import Grid from '@frontend/components/frames/Grid';
import Drawer from '@frontend/components/modals/Drawer';
import Spacer from '@frontend/components/Spacer';
import Typography from '@frontend/components/Typography';
import ModalService from '@frontend/services/ModalService';
import { formatDateTime, toCurrency } from '@frontend/utils/masks';
import { FlexBorderTop, FlexBorderTopBottom } from './styled';
import { Rows } from './table/setup';

export type Props = {
  order: Rows;
};

function RequestBalanceDrawer({ order }: Props) {
  const controller = ModalService.useModalController();
  const paid = order.status.value === 'paid';

  function handleClose() {
    controller.remove();
  }

  return (
    <Drawer
      visible={controller.visible}
      title={
        <Grid templateColumns="auto 1fr" padding="xs2" gap="xs2" align="center">
          <ShapeIcon name="IconTransferIn" variant="neutral" size={48} />
          <Flex direction="column" gap="xs4">
            <Typography.Body4>Depósito único</Typography.Body4>
            <Typography.Headline7>
              + {toCurrency(order.value)}
            </Typography.Headline7>
          </Flex>
        </Grid>
      }
      close={handleClose}
      anchor="right"
    >
      <Flex
        direction="column"
        borderWidth="xs2"
        borderColor="neutral_90"
        radius="m"
      >
        <Flex padding="xs2" justify="space-between" align="center">
          <Tag
            as="div"
            variant={getColorTag(order.status.value)}
            hasLeftDotIcon
          >
            {order.status.label}
          </Tag>
          <Typography.Caption color="neutral_40">
            {!!order.transferredAt &&
              `Disponibilizado em ${formatDateTime(order.transferredAt)}`}
          </Typography.Caption>
        </Flex>
        <FlexBorderTopBottom>
          <Flex
            padding="xs2"
            justify="space-between"
            direction="column"
            gap="xs5"
          >
            <Typography.Caption color="neutral_40">
              Pagamento
            </Typography.Caption>
            <Typography.Body4>Carteira corporativa</Typography.Body4>
          </Flex>
        </FlexBorderTopBottom>

        <Flex
          padding="xs2"
          justify="space-between"
          direction="column"
          gap="xs5"
        >
          <Typography.Caption color="neutral_40">
            Agendado por
          </Typography.Caption>
          {paid ? (
            <Flex align="center" gap="xs4">
              <Typography.Body4>{order.adminInfo?.name}</Typography.Body4>
              <Dot variant="disabled" />
              <Typography.Body4>
                {order.createdAt ? formatDateTime(order.createdAt) : '-'}
              </Typography.Body4>
            </Flex>
          ) : (
            '-'
          )}
        </Flex>
      </Flex>
      <Spacer y="xs" />
      <Flex
        direction="column"
        borderWidth="xs2"
        borderColor="neutral_90"
        radius="m"
      >
        <Flex padding="xs2" justify="space-between" align="center">
          <Flex direction="column" gap="xs5">
            <Typography.Caption color="neutral_40">
              Depositada para
            </Typography.Caption>

            <Typography.Body4>
              {paid ? order.employee.name : '-'}
            </Typography.Body4>
          </Flex>
          <ShapeIcon name="IconUser" variant="neutral" size={40} />
        </Flex>
        <FlexBorderTop padding="xs2" justify="space-between" align="center">
          <Flex direction="column" gap="xs5">
            <Typography.Caption color="neutral_40">
              Pedido de saldo aprovado por
            </Typography.Caption>
            {order.requestBalance?.approvers?.map((approver) => {
              return (
                <Flex align="center" gap="xs4" key={approver.id}>
                  <Typography.Body4>{approver.name}</Typography.Body4>
                  <Dot variant="disabled" />
                  <Typography.Body4>
                    {approver.updatedAt
                      ? formatDateTime(approver.updatedAt)
                      : ''}
                  </Typography.Body4>
                  <Dot variant="disabled" />
                  <Typography.Body4>
                    {approver.status === 'APPROVED' && 'Aprovado'}
                    {approver.status === 'REJECTED' && 'Reprovado'}
                    {approver.status === 'PENDING' && 'Pendente'}
                  </Typography.Body4>
                </Flex>
              );
            }) || null}
          </Flex>
          <ShapeIcon name="IconUserCheck" variant="neutral" size={40} />
        </FlexBorderTop>
      </Flex>
    </Drawer>
  );
}

function getColorTag(
  status: Rows['status']['value'],
): Parameters<typeof Tag>[number]['variant'] {
  switch (status) {
    case 'expired':
    case 'cancelled':
    case 'reproved':
    case 'depositCancelled':
      return 'error';
    case 'paid':
      return 'success';
    default:
      return 'info';
  }
}

export default ModalService.create(RequestBalanceDrawer);
