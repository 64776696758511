import { Dayjs, dayjs } from '@flash-tecnologia/hros-web-ui-v2'
import { setInLS } from '@flash-tecnologia/hros-web-utility'
import { useCallback, useEffect, useState } from 'react'
import { trpc } from 'src/api/client'
import { useToast } from 'src/utils/hooks/useToast'

import { useOrderBySpreadsheetStore } from '../../../store/use-order-by-spreadsheet-store'

export function useAddEmployees() {
  const { toastWarning, toastError, toastSuccess } = useToast()
  const [scheduleNotification, setScheduleNotification] =
    useState<boolean>(false)
  const [migrateEmployees, setMigrateEmployees] = useState<boolean>(false)
  const [reactivateEmployees, setReactivateEmployees] = useState<boolean>(false)
  const [isUploadingSpreadsheet, setIsUploadingSpreadsheet] = useState(false)
  const [pickedDate, setPickedDate] = useState<Dayjs>()
  const {
    droppedFile,
    nextStep,
    previousStep,
    setBackButtonOptions,
    setContinueButtonOptions,
  } = useOrderBySpreadsheetStore()

  const { mutate: importSpreadsheet, isLoading: isLoadingImportSpreadsheet } =
    trpc.benefitOrders.importSpreadsheet.useMutation({
      onSuccess: async ({ uploadUrl, importId }) => {
        if (droppedFile) await uploadFile(uploadUrl, droppedFile)
        setInLS({ key: 'importId', value: { importId } })
      },
      onError: () => {
        toastError({
          title: 'Não foi possível processar arquivo enviado',
          description: 'Por favor, volte e envie o arquivo novamente.',
        })
      },
    })

  const handleNotificationSend = useCallback(() => {
    setScheduleNotification((prev) => !prev)
  }, [])

  const handleMigrateEmployees = useCallback(() => {
    setMigrateEmployees((prev) => !prev)
  }, [])

  const handleReactivateEmployees = useCallback(() => {
    setReactivateEmployees((prev) => !prev)
  }, [])

  const uploadFile = useCallback(
    async (signedUrl: string, file: File) => {
      setIsUploadingSpreadsheet(true)

      try {
        await fetch(signedUrl, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type,
          },
        })

        toastSuccess({
          title: 'Arquivo enviado com sucesso',
        })
        nextStep()
      } catch (error) {
        toastError({
          title: 'Não foi possível realizar o envio do arquivo',
          description:
            'Tente novamente mais tarde, ou entre em contato com a nossa Central de Ajuda.',
        })
      } finally {
        setIsUploadingSpreadsheet(false)
      }
    },
    [toastError, toastSuccess, nextStep],
  )

  const handleDateChange = useCallback(
    (e: Dayjs) => {
      setPickedDate(e)
    },
    [setPickedDate],
  )

  const handleImportSpreadsheet = useCallback(async () => {
    const today = dayjs()
    const date = scheduleNotification ? pickedDate : today

    if (!date) {
      toastWarning({
        title: 'Insira uma data para envio do convite.',
      })
      return
    }

    if (droppedFile)
      importSpreadsheet({
        fileName: droppedFile.name,
        invitationDate: `${date.format('YYYY-MM-DD')}T08:00:00`,
        reactivate: reactivateEmployees,
        migrate: migrateEmployees,
      })

    nextStep()
  }, [
    droppedFile,
    importSpreadsheet,
    migrateEmployees,
    pickedDate,
    reactivateEmployees,
    scheduleNotification,
  ])

  useEffect(() => {
    setContinueButtonOptions({
      text: 'Continuar',
      onClick: handleImportSpreadsheet,
      isLoading: isLoadingImportSpreadsheet || isUploadingSpreadsheet,
    })
  }, [
    handleImportSpreadsheet,
    isLoadingImportSpreadsheet,
    isUploadingSpreadsheet,
    setContinueButtonOptions,
  ])

  useEffect(() => {
    setBackButtonOptions({
      text: 'Voltar',
      onClick: previousStep,
      iconName: 'IconArrowLeft',
      iconPosition: 'left',
      disabled: isLoadingImportSpreadsheet || isUploadingSpreadsheet,
    })
  }, [
    previousStep,
    setBackButtonOptions,
    isLoadingImportSpreadsheet,
    isUploadingSpreadsheet,
  ])

  return {
    handleDateChange,
    handleMigrateEmployees,
    handleNotificationSend,
    handleReactivateEmployees,
    migrateEmployees,
    pickedDate,
    reactivateEmployees,
    scheduleNotification,
  }
}
