import { Icons, Spinner, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { t } from 'i18next'
import { Grid } from 'src/components/grid'
import styled, { useTheme } from 'styled-components'

const LoaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  svg {
    position: absolute;
  }
`

export const OrderConfirmLoading = () => {
  const theme = useTheme()
  return (
    <Grid.Item
      xs={12}
      xl={8}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      textAlign={'center'}
      justifyContent={'center'}
      rowGap={theme.spacings.s}
    >
      <LoaderContainer>
        <Icons name="IconShoppingCart" stroke={theme.colors.secondary[40]} />
        <Spinner size={64} variant="primary" />
      </LoaderContainer>

      <div>
        <Typography variant="headline6" color="neutral.20" weight={700}>
          {t('order.review.loading.title')}
        </Typography>
        <Typography variant="body3" color="neutral.40">
          {t('order.review.loading.subtitle')}
        </Typography>
      </div>
    </Grid.Item>
  )
}
