/**
 * Format number to Brazilian currency R$ 123,00
 * @param value amount in cents ex: 1000 -> R$ 10,00
 */
export function toCurrency(value: number) {
  if (Number.isNaN(Number(value))) {
    return 'R$ 0,00';
  }

  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value / 100);
}
