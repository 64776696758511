import { dayjs, IconTypes } from '@flash-tecnologia/hros-web-ui-v2'
import { GetOrderSummaryResponse } from 'backend/src/services'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'
import { processEnv } from 'src/utils/env'
import { create } from 'zustand'

import { FilterKey } from '../steps/employee-selection/components/filters/hooks/use-filters'
import { Benefit, Employee } from '../steps/employee-selection/types'
import { updateBenefits } from './helpers/update-benefits'

export const CREDIT_DAYS_DEFAULT = 30

export enum Step {
  EMPLOYEE_SELECTION = 0,
  PAYMENT_SETUP = 1,
  REVIEW_DETAILS = 2,
  ORDER_COMPLETED = 3,
}

type Order = {
  id?: string
  paymentMethod?: PaymentMethodEnum
  depositDate?: dayjs.Dayjs
  dueDate?: dayjs.Dayjs
  cutoffDate?: dayjs.Dayjs
  receiptDescription?: string
  pixCode?: string
}

export type FlashCashBalance = {
  label: 'plastic' | 'virtual'
  value: number
}

export type StepNavigationButtonOptions = {
  text: string
  onClick?: () => void
  isLoading?: boolean
  showIcon?: boolean
  iconName?: IconTypes
  iconPosition?: 'left' | 'right'
  disabled?: boolean
}

type NewOrderStoreState = {
  currentStep: Step
  employees: Employee[]
  employeesFilter: Record<FilterKey, string[]>
  isEmployeesInitialized: boolean
  selectedEmployees: Employee[]
  order: Order | null
  orderSummary: GetOrderSummaryResponse | null
  isTopupCredit: boolean
  continueButtonOptions: StepNavigationButtonOptions | null
  backButtonOptions: StepNavigationButtonOptions | null
  flashCashBalances: FlashCashBalance[] | []
  creditDays: number
}

type UpdateEmployeeBenefitValue = {
  employeeId: string
  updatedBenefits: Benefit[]
}

type NewOrderStoreSetters = {
  setEmployees: (employees: Employee[]) => void
  setFilterEmployees: (employeesFilter: Record<FilterKey, string[]>) => void
  markEmployeesAsInitialized: () => void
  updateEmployeeBenefitValue: (employee: UpdateEmployeeBenefitValue) => void
  updateSelectedEmployeesBenefitValue: (updatedBenefits: Benefit[]) => void
  setBenefitsForSelectedEmployees: (
    employees: Employee[],
    updatedBenefits: Benefit[],
  ) => void
  setSelectedEmployees: (employees: Employee[]) => void
  setOrder: (order: Partial<Order>) => void
  setOrderSummary: (summary: GetOrderSummaryResponse | null) => void
  resetOrder: () => void
  setContinueButtonOptions: (options: StepNavigationButtonOptions) => void
  setBackButtonOptions: (options: StepNavigationButtonOptions) => void
  nextStep: () => void
  previousStep: () => void
  setFlashCashBalances: (balances: FlashCashBalance[]) => void
  setIsTopupCredit: (isTopup: boolean) => void
  setCreditDays: (days: number) => void
  resetStore: () => void
}

type NewOrderStore = NewOrderStoreState & NewOrderStoreSetters

const initialState: NewOrderStoreState = {
  employees: [],
  employeesFilter: { groups: [] },
  isEmployeesInitialized: false,
  selectedEmployees: [],
  order: null,
  orderSummary: null,
  continueButtonOptions: null,
  backButtonOptions: null,
  currentStep: Step.EMPLOYEE_SELECTION,
  flashCashBalances: [],
  isTopupCredit: false,
  creditDays: CREDIT_DAYS_DEFAULT,
}

export const useNewOrderStore = create<NewOrderStore>((set) => ({
  ...initialState,
  setEmployees: (employees) => {
    set({ employees })
  },
  setFilterEmployees: (employeesFilter) => {
    set({ employeesFilter })
  },
  markEmployeesAsInitialized: () => {
    set({ isEmployeesInitialized: true })
  },
  updateEmployeeBenefitValue: ({ employeeId, updatedBenefits }) => {
    set((state) => {
      const updatedEmployee = (employee: Employee) => ({
        ...employee,
        benefits: updateBenefits(employee.benefits, updatedBenefits),
      })

      const employees = state.employees.map((employee) =>
        employee.id === employeeId ? updatedEmployee(employee) : employee,
      )

      const selectedEmployees = state.selectedEmployees.map((employee) =>
        employee.id === employeeId ? updatedEmployee(employee) : employee,
      )

      return {
        employees,
        selectedEmployees,
      }
    })
  },
  updateSelectedEmployeesBenefitValue: (updatedBenefits) => {
    set((state) => {
      const updatedEmployees = state.employees.map((employee) => {
        const updatedEmployeeBenefits = [...employee.benefits]

        updatedBenefits.forEach((updatedBenefit) => {
          const benefitIndex = updatedEmployeeBenefits.findIndex(
            (benefit) => benefit.id === updatedBenefit.id,
          )

          if (benefitIndex !== -1) {
            updatedEmployeeBenefits[benefitIndex] = {
              ...updatedEmployeeBenefits[benefitIndex],
              value: updatedBenefit.value,
            }
          } else {
            updatedEmployeeBenefits.push(updatedBenefit)
          }
        })

        return {
          ...employee,
          benefits: updatedEmployeeBenefits,
        }
      })

      const updatedSelectedEmployees = state.selectedEmployees.map(
        (selectedEmployee) => {
          const updatedSelectedEmployeeBenefits = [...selectedEmployee.benefits]

          updatedBenefits.forEach((updatedBenefit) => {
            const benefitIndex = updatedSelectedEmployeeBenefits.findIndex(
              (benefit) => benefit.id === updatedBenefit.id,
            )

            if (benefitIndex !== -1) {
              updatedSelectedEmployeeBenefits[benefitIndex] = {
                ...updatedSelectedEmployeeBenefits[benefitIndex],
                value: updatedBenefit.value,
              }
            } else {
              updatedSelectedEmployeeBenefits.push(updatedBenefit)
            }
          })

          return {
            ...selectedEmployee,
            benefits: updatedSelectedEmployeeBenefits,
          }
        },
      )

      return {
        employees: updatedEmployees,
        selectedEmployees: updatedSelectedEmployees,
      }
    })
  },
  setBenefitsForSelectedEmployees: (employees, updatedBenefits) => {
    set((state) => {
      const updateEmployeeBenefits = (employee: Employee) => {
        const updatedEmployeeBenefits = [...employee.benefits]

        updatedBenefits.forEach((updatedBenefit) => {
          const benefitIndex = updatedEmployeeBenefits.findIndex(
            (benefit) => benefit.id === updatedBenefit.id,
          )

          if (benefitIndex !== -1) {
            updatedEmployeeBenefits[benefitIndex] = {
              ...updatedEmployeeBenefits[benefitIndex],
              value: updatedBenefit.value,
            }
          } else {
            updatedEmployeeBenefits.push(updatedBenefit)
          }
        })

        return {
          ...employee,
          benefits: updatedEmployeeBenefits,
        }
      }

      const updatedEmployees = state.employees.map((employee) => {
        const isSelected = employees.some(
          (selectedEmployee) => selectedEmployee.id === employee.id,
        )

        return isSelected ? updateEmployeeBenefits(employee) : employee
      })

      const updatedSelectedEmployees = state.selectedEmployees.map(
        (selectedEmployee) => {
          const isSelected = employees.some(
            (employee) => employee.id === selectedEmployee.id,
          )

          return isSelected
            ? updateEmployeeBenefits(selectedEmployee)
            : selectedEmployee
        },
      )

      return {
        employees: updatedEmployees,
        selectedEmployees: updatedSelectedEmployees,
      }
    })
  },
  setSelectedEmployees: (employees) => {
    set({ selectedEmployees: employees })
  },
  setOrder: (order) => {
    set((oldState) => ({ order: { ...oldState.order, ...order } }))
  },
  resetOrder: () => {
    set({ order: initialState.order })
  },
  setOrderSummary: (summary) => {
    set({ orderSummary: summary })
  },
  setContinueButtonOptions: (options) => {
    set({ continueButtonOptions: options })
  },
  setBackButtonOptions: (options) => {
    set({ backButtonOptions: options })
  },
  nextStep: () => {
    const stepMapping = {
      [Step.EMPLOYEE_SELECTION]: Step.PAYMENT_SETUP,
      [Step.PAYMENT_SETUP]: Step.REVIEW_DETAILS,
      [Step.REVIEW_DETAILS]: Step.ORDER_COMPLETED,
      [Step.ORDER_COMPLETED]: Step.ORDER_COMPLETED,
    }

    set((oldState) => {
      return {
        currentStep: stepMapping[oldState.currentStep],
        continueButtonOptions: null,
        backButtonOptions: null,
      }
    })
  },
  previousStep: () => {
    const stepMapping = {
      [Step.EMPLOYEE_SELECTION]: Step.EMPLOYEE_SELECTION,
      [Step.PAYMENT_SETUP]: Step.EMPLOYEE_SELECTION,
      [Step.REVIEW_DETAILS]: Step.PAYMENT_SETUP,
      [Step.ORDER_COMPLETED]: Step.ORDER_COMPLETED,
    }

    set((oldState) => ({
      currentStep: stepMapping[oldState.currentStep],
      continueButtonOptions: null,
      backButtonOptions: null,
    }))
  },
  setFlashCashBalances: (balances) => {
    set({ flashCashBalances: balances })
  },
  setIsTopupCredit: (isTopup) => {
    set({ isTopupCredit: isTopup })
  },
  setCreditDays(days) {
    set({ creditDays: days })
  },
  resetStore: () => {
    set({ ...initialState })
  },
}))

if (processEnv.BUILD_ENV === 'development') {
  useNewOrderStore.subscribe((state) => {
    console.log('state updated (useNewOrderStoreNew)', state)
  })
}
