import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loader, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container, TitleContainer } from "./styled";

export const RedirectPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate("/home");
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Container>
      <Loader size="medium" variant="primary" />
      <TitleContainer>
        <Typography variant="headline6" weight={700}>
          Você está sendo redirecionado para a Home
        </Typography>
      </TitleContainer>
      <Typography variant="body3" variantColor="#6B5B66">
        Assine o contrato disponível na área de pendências para acessar seus
        módulos de Pessoas
      </Typography>
    </Container>
  );
};
