import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import {
  HeaderContainer,
  HeaderText,
  StyledButtonContainer,
  Subtitle,
} from "./styles";

type HeaderProps = {
  openDrawer: () => void;
};

export const Header = ({ openDrawer }: HeaderProps) => {
  return (
    <HeaderContainer>
      <HeaderText>
        <Typography variant="headline6">Central de Relatórios</Typography>
        <Subtitle>
          Explore relatórios prontos ou personalize o seu próprio a partir de
          diversos modelos disponíveis.
        </Subtitle>
      </HeaderText>
      <StyledButtonContainer>
        <Button
          onClick={() => openDrawer()}
          variant="secondary"
          size="medium"
          variantType="neutral"
        >
          Central de downloads
          <Icons name="IconDownload" />
        </Button>
      </StyledButtonContainer>
    </HeaderContainer>
  );
};
