import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ThemeProvider as NewProvider,
  Button,
  Tab,
  dayjs,
  Icons,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  TitleContainer,
  Title,
  PageContainer,
  DataGrid,
  Divider,
  MenuIcon,
  Tag,
  NotFoundMessage,
  CardSkeleton,
  DataGridSkeleton,
  Modal,
  CarouselCards,
  PeopleTable,
  CampaignReport,
  FeatureNotification,
} from "../../components";
import { alert, segment, useOwnershipValidation } from "../../utils";
import { FiltersType } from "../../components/TableFilter/types";
import { trpc } from "../../api/client";
import { EditOwnersModal } from "src/modals";
import { EngagementModel } from "@models";
import { useUser } from "src/hooks/useUser";
import { EngajaNotificationCard } from "@components/EngajaNotificationCard";
import { useContractValidation } from "src/hooks/useContractValidation";
import { FreeTrialChronometer } from "@components/FreeTrialChronometer";

export default () => {
  const hasEngagementContract = useContractValidation();

  const validateOwnership = useOwnershipValidation();
  const user = useUser();
  const navigate = useNavigate();
  const [ownersEditModal, setOwnersEditModal] = useState<{
    open: boolean;
    engagement?: EngagementModel;
  }>({
    open: false,
    engagement: undefined,
  });

  const [empty, setEmpty] = useState(true);
  const [search, setSearch] = useState<string | null>(null);
  const [recurrenceSearch, setRecurrenceSearch] = useState<string | null>(null);
  const filterConfig: FiltersType = [
    {
      id: "status",
      type: "multi-select",
      label: "Status",
      valueInLabel: true,
      options: [
        {
          label: "Enviado",
          value: "send",
        },
        {
          label: "Rascunho",
          value: "draft",
        },
        {
          label: "Agendado",
          value: "scheduled",
        },
      ],
    },
    {
      id: "createdAt",
      type: "date",
      label: "Criação",
      valueInLabel: true,
    },
    {
      id: "sendAt",
      type: "date",
      label: "Envio",
      valueInLabel: true,
    },
  ];

  const [filters, setFilters] = useState<FiltersType>(filterConfig);
  const [recurrenceFilters, setRecurrenceFilters] = useState<FiltersType>(
    filterConfig?.map(({ id, ...rest }) => {
      if (id === "status")
        return {
          id,
          ...rest,
          options: [
            {
              label: "Rascunho",
              value: "draft",
            },
            {
              label: "Não iniciado",
              value: "scheduled",
            },
            {
              label: "Em andamento",
              value: "progress",
            },
            {
              label: "Pausado",
              value: "paused",
            },
            {
              label: "Encerrado",
              value: "closed",
            },
          ],
        };

      return { id, ...rest };
    })
  );

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showCampaignReport, setShowCampaignReport] = useState(false);
  const [loading, setLoading] = useState(true);
  const [campaigns, setCampaigns] = useState<any>(null);
  const [recurrences, setRecurrences] = useState<any[]>([]);
  const [totalEmployees, setTotalEmployees] = useState(0);

  const [selectedCampaignId, setSelectedCampaignId] = useState<string>();
  const [dashboard, setDashboard] = useState<{
    engagements?: number;
    open?: number;
    openRate?: number;
    visualization?: number;
    visualizationRate?: number;
    interation?: number;
    interationRate?: number;
  } | null>(null);

  const selectCampaign = (campaignId: string, action: "delete" | "export") => {
    setSelectedCampaignId(campaignId);

    if (action === "delete") {
      setShowConfirmModal(!showConfirmModal);
      return;
    }

    setShowCampaignReport(!showCampaignReport);
  };

  const { refetch: refetchEngagements, isFetching: filterLoading } =
    trpc?.getEngagementsByCompanyId?.useQuery(
      {
        type: "communication",
        search: search || "",
        status:
          filters
            .find((e) => e.id === "status")
            ?.options?.filter(({ checked }) => checked)
            ?.map(({ value }) => value) || [],
        createdAt: filters?.find(({ id }) => id === "createdAt")?.dateRange
          ?.from
          ? {
              from: dayjs(
                filters?.find(({ id }) => id === "createdAt")?.dateRange?.from
              )?.format("DD-MM-YYYY"),
              to: dayjs(
                filters?.find(({ id }) => id === "createdAt")?.dateRange?.to
              )?.format("DD-MM-YYYY"),
            }
          : undefined,
        sendAt: filters?.find(({ id }) => id === "sendAt")?.dateRange?.from
          ? {
              from: dayjs(
                filters?.find(({ id }) => id === "sendAt")?.dateRange?.from
              )?.format("DD-MM-YYYY"),
              to: dayjs(
                filters?.find(({ id }) => id === "sendAt")?.dateRange?.to
              )?.format("DD-MM-YYYY"),
            }
          : undefined,
      },
      {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        onError: () => {
          alert("Ocorreu um erro, tente novamente mais tarde.");
        },
        onSuccess: (data) => {
          const engagements = data?.engagements;
          if (empty && engagements?.length) setEmpty(false);

          setCampaigns([
            ...engagements
              ?.filter(
                ({ engagementOrigin, recurrence }) =>
                  !engagementOrigin && !recurrence
              )
              ?.map((campaign) => ({
                id: campaign?._id,
                ...campaign,
              })),
          ]);
        },
      }
    );

  const { refetch: refetchRecurrences, isFetching: refetchRecurrencesLoading } =
    trpc?.getEngagementsByCompanyId?.useQuery(
      {
        type: "communication",
        search: recurrenceSearch || "",
        status:
          recurrenceFilters
            .find((e) => e.id === "status")
            ?.options?.filter(({ checked }) => checked)
            ?.map(({ value }) => value) || [],
        createdAt: recurrenceFilters?.find(({ id }) => id === "createdAt")
          ?.dateRange?.from
          ? {
              from: dayjs(
                recurrenceFilters?.find(({ id }) => id === "createdAt")
                  ?.dateRange?.from
              )?.format("DD-MM-YYYY"),
              to: dayjs(
                recurrenceFilters?.find(({ id }) => id === "createdAt")
                  ?.dateRange?.to
              )?.format("DD-MM-YYYY"),
            }
          : undefined,
        sendAt: recurrenceFilters?.find(({ id }) => id === "sendAt")?.dateRange
          ?.from
          ? {
              from: dayjs(
                recurrenceFilters?.find(({ id }) => id === "sendAt")?.dateRange
                  ?.from
              )?.format("DD-MM-YYYY"),
              to: dayjs(
                recurrenceFilters?.find(({ id }) => id === "sendAt")?.dateRange
                  ?.to
              )?.format("DD-MM-YYYY"),
            }
          : undefined,
      },
      {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        onError: () => {
          alert("Ocorreu um erro, tente novamente mais tarde.");
        },
        onSuccess: (data) => {
          const engagements = data?.engagements;
          if (empty && engagements?.length) setEmpty(false);

          setRecurrences([
            ...engagements
              ?.filter(({ recurrence }) => recurrence?.status)
              ?.map((campaign) => ({
                id: campaign?._id,
                ...campaign,
              })),
          ]);
        },
      }
    );

  useEffect(() => {
    refetchEngagements();
  }, [filters, search]);

  useEffect(() => {
    refetchRecurrences();
  }, [recurrenceFilters, recurrenceSearch]);

  const { refetch } = trpc?.getEngagementReportByEngagementId?.useQuery(
    {
      screen: recurrences?.find(({ _id }) => _id === selectedCampaignId)
        ?.recurrence
        ? "recurrence-communication-home"
        : "communication-home",
      type: "communication",
      id: selectedCampaignId || "",
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        alert("Ocorreu um erro, tente novamente mais tarde.");
        console.log(error);
      },
      enabled: false,
    }
  );

  const deleteEngagement = trpc?.deleteEngagement.useMutation({
    onMutate: () => {
      setShowConfirmModal(!showConfirmModal);
      refetchEngagements();
      refetchRecurrences();
    },
    onSuccess: () => {
      (() => {
        refetchEngagements();
        setSelectedCampaignId(undefined);
        setShowSuccessModal(!showSuccessModal);
      })();
    },
    onError: () => {
      alert("Ocorreu um erro, tente novamente mais tarde.");
    },
  });

  const updateRecurrenceStatus = trpc?.updateRecurrenceStatus.useMutation({
    onSuccess: () => {
      refetchEngagements();
    },
    onError: (e) => {
      console.log(e);
      alert("Ocorreu um erro, tente novamente mais tarde.");
    },
  });

  const updateRecStatus = (
    id: string,
    status: "scheduled" | "progress" | "paused" | "closed"
  ) => {
    updateRecurrenceStatus.mutate({ id, status });
  };

  const createEngagement = trpc?.createEngagement.useMutation({
    onSuccess: () => {
      refetchRecurrences();
      refetchEngagements();
    },
    onError: () => {
      alert("Ocorreu um erro, tente novamente mais tarde.");
    },
  });

  trpc?.getEngagementsPerformance?.useQuery(
    { type: "communication" },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setDashboard(data);
        setLoading(false);
      },
      onError: (e: any) => {
        alert("Ocorreu um erro, tente novamente mais tarde.");
        setLoading(false);
        console.log("err: ", e);
      },
    }
  );

  const deleteCampaign = () => {
    if (selectedCampaignId) deleteEngagement.mutate({ id: selectedCampaignId });
  };

  const copyEngagement = trpc.copyEngagement.useMutation({
    onSuccess: () => {
      refetchRecurrences();
      refetchEngagements();
    },
    onError: () => {
      alert("Ocorreu um erro, tente novamente mais tarde.");
    },
  });

  const copyCampaign = (campaignId: string) =>
    copyEngagement.mutate({ id: campaignId });

  if (loading)
    return (
      <PageContainer>
        <CardSkeleton />
        <Divider />
        <DataGridSkeleton />
      </PageContainer>
    );

  return (
    <>
      {/* !{hasEngagementContract && <FreeTrialChronometer />} */}
      <PageContainer>
        <TitleContainer>
          <Title>Comunicações</Title>
          <Button
            size="large"
            variant="primary"
            onClick={() => {
              segment({
                track:
                  "people_strategic_hr_engagement_company_communications_create_communication",
              });
              navigate("/engagement/communication/add");
            }}
          >
            Criar comunicação
          </Button>
        </TitleContainer>
        {/* <EngajaNotificationCard
          title="Novidade: Pesquisa Engaja S/A"
          description="Transforme seu RH! Nosso novo modelo vai revolucionar a forma como
          você entende e motiva seus colaboradores."
          linkButtons={[
            { label: "Ver tutorial", callback: () => null },
            {
              label: "Usar modelo",
              callback: () =>
                navigate("/engagement/survey/add", {
                  replace: true,
                  state: { drawerOpen: "engaja" },
                }),
            },
            {
              label: (
                <a
                  href="https://flashapp.com.br/blog/institucional/lancamento-indice-engajamento-flash"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    flexDirection: "row",
                    justifyItems: "center",
                    gap: "4px",
                  }}
                >
                  Saiba mais <Icons name="IconExternalLink" size={16} />
                </a>
              ),
              callback: () => null,
            },
          ]}
        /> */}
        <CampaignReport
          hideMode
          type={"communication"}
          visible={showCampaignReport}
          onClick={() => {
            segment({
              track:
                "company_engagement_homecommunication_exportreport_clicked",
            });
            setShowCampaignReport(!showCampaignReport);
          }}
          onExport={() => {
            segment({
              track:
                "company_engagement_exportcommunication_exportreport_clicked",
            });
            if (selectedCampaignId?.length) refetch();
          }}
          onClose={() => {
            segment({
              track: "company_engagement_exportcommunication_cancel_clicked",
            });
            setShowCampaignReport(!showCampaignReport);
          }}
          onFinish={() => {
            setShowCampaignReport(!showCampaignReport);
          }}
          texts={{
            message: (
              <>
                O relatório apresentará somente os <strong>dados dessa</strong>
                <br />
                <strong>comunicação</strong> convertidos em um arquivo CSV.
              </>
            ),
          }}
        />
        <Divider size="large" />
        <CarouselCards
          cards={[
            {
              title: "Comunicações enviadas",
              icon: "IconSpeakerphone",
              value: dashboard?.engagements || 0,
            },
            {
              title: "Taxa média de visualização",
              icon: "IconEyeCheck",
              value: dashboard?.visualizationRate || 0,
              subValue: dashboard?.visualization || 0,
              toolTip: {
                title: "Taxa média de visualização",
                description:
                  "A taxa é calculada a partir do total de destinatários que visualizaram a comunicação na área de notificações dividido pelo total de destinatários que receberam a comunicação.",
              },
            },
            {
              title: "Taxa média de clique em link",
              value: dashboard?.openRate || 0,
              subValue: dashboard?.open || 0,
              icon: "IconHandClick",
              toolTip: {
                title: "Taxa média de clique em link",
                description:
                  "A taxa é calculada a partir do total de destinatários que clicaram no link da comunicação dividido pelo total de destinatários que receberam campanhas do tipo comunicação.",
              },
            },
            {
              title: "Taxa média de reações",
              value: dashboard?.interationRate || 0,
              subValue: dashboard?.interation || 0,
              icon: "IconMoodHappy",
              toolTip: {
                title: "Taxa média de reações",
                description:
                  "A taxa é calculada a partir do total de reações na comunicação dividido pelo total de destinatários que receberam a comunicação.",
              },
            },
          ]}
        />
        <NewProvider>
          <Divider size="medium" />
          <Tab
            tabItens={[
              {
                label: `Envios únicos (${campaigns?.length || 0})`,
                component: (
                  <>
                    {!empty ? (
                      <DataGrid
                        emptyState={{
                          isFiltered: true,
                          message: filters?.some((filter) => {
                            if (filter?.options?.length)
                              return filter?.options?.some((o) => o?.checked);

                            if (
                              filter?.dateRange?.from &&
                              filter?.dateRange?.to
                            )
                              return true;

                            return false;
                          })
                            ? "O filtro selecionado não apresenta resultados"
                            : "Você ainda não criou nenhuma comunicação de envio único",
                        }}
                        searchField={{
                          value: search || "",
                          onSearch: (value: string) => setSearch(value || ""),
                          placeholder: "Buscar por comunicação",
                        }}
                        filterTags={{
                          filters: filters,
                          onFilter: (e) => setFilters(e),
                          onClear: (e) => setFilters(e),
                        }}
                        columns={[
                          {
                            Header: "Nome de identificação",
                            accessor: "name",
                          },
                          {
                            Header: "Status",
                            accessor: "status",
                            Cell: ({ value }) => (
                              <Tag variant={value}>
                                {value === "send"
                                  ? "Enviado"
                                  : value === "scheduled"
                                  ? "Agendado"
                                  : "Rascunho"}
                              </Tag>
                            ),
                          },
                          {
                            Header: "Criação",
                            accessor: "createdAt",
                            Cell: ({ value }) => (
                              <>{dayjs(value)?.format("DD/MM/YYYY - HH:mm")}</>
                            ),
                          },
                          {
                            Header: "Envio",
                            accessor: "sendDate",
                            Cell: ({ value }) => (
                              <>
                                {value
                                  ? dayjs(value)?.format("DD/MM/YYYY - HH:mm")
                                  : null}
                              </>
                            ),
                          },
                          {
                            Header: "Grupos",
                            accessor: "groups",
                            Cell: ({ value }) => <>{value?.length}</>,
                          },
                          {
                            Header: "Destinatários",
                            accessor: "employees",
                            Cell: ({ value }) => <>{value?.length}</>,
                          },
                          {
                            disableSortBy: true,
                            accessor: "_id",
                            sticky: "right",
                            Cell: ({ value, row }) => {
                              if (
                                !validateOwnership(
                                  user as any,
                                  row?.original
                                ) &&
                                row?.original?.status != "draft"
                              ) {
                                return <MenuIcon disabled options={[]} />;
                              }
                              return (
                                <MenuIcon
                                  options={[
                                    {
                                      label: "Ver detalhes da comunicação",
                                      icon: "IconFileDescription",
                                      disabled:
                                        row?.original?.status === "draft"
                                          ? true
                                          : false,
                                      onClick: () => {
                                        segment({
                                          track: `people_strategic_hr_engagement_company_communications_viewdetails`,
                                        });
                                        navigate(
                                          `/engagement/communication/${value}`
                                        );
                                      },
                                    },
                                    {
                                      label:
                                        "Exportar relatório da comunicação",
                                      icon: "IconDownload",
                                      onClick: () => {
                                        segment({
                                          track:
                                            "people_strategic_hr_engagement_company_communications_exportreport",
                                        });
                                        selectCampaign(value, "export");
                                      },
                                      disabled:
                                        row?.original?.status === "draft",
                                    },
                                    {
                                      label: "Duplicar comunicação",
                                      icon: "IconCopy",
                                      onClick: () => {
                                        segment({
                                          track:
                                            "people_strategic_hr_engagement_company_communications_duplicatecommunication",
                                        });
                                        copyCampaign(value);
                                      },
                                    },
                                    {
                                      label: "Editar responsáveis",
                                      icon: "IconCheckupList",
                                      disabled:
                                        row?.original?.status === "draft",
                                      onClick: () => {
                                        segment({
                                          track:
                                            "people_strategic_hr_engagement_company_communications_editresponsible",
                                        });
                                        setOwnersEditModal({
                                          open: true,
                                          engagement: row?.original,
                                        });
                                      },
                                    },
                                    {
                                      label: "Editar comunicação",
                                      icon: "IconPencil",
                                      disabled: [
                                        "draft",
                                        "scheduled",
                                      ]?.includes(row?.original?.status)
                                        ? false
                                        : true,
                                      onClick: () => {
                                        segment({
                                          track:
                                            "people_strategic_hr_engagement_company_communications_editcommunication",
                                        });
                                        navigate(
                                          `/engagement/communication/edit/${value}`
                                        );
                                      },
                                    },
                                    {
                                      label: "Excluir comunicação",
                                      icon: "IconTrash",
                                      disabled: [
                                        "draft",
                                        "scheduled",
                                      ]?.includes(row?.original?.status)
                                        ? false
                                        : true,
                                      onClick: () => {
                                        segment({
                                          track:
                                            "people_strategic_hr_engagement_company_communications_deletecommunication",
                                        });
                                        selectCampaign(value, "delete");
                                      },
                                    },
                                  ]}
                                />
                              );
                            },
                          },
                        ]}
                        loading={filterLoading}
                        data={campaigns}
                      />
                    ) : (
                      <>
                        <Divider size="large" />
                        <NotFoundMessage
                          title={""}
                          message="Você ainda não criou nenhuma comunicação"
                        >
                          <Button
                            size="large"
                            variant="primary"
                            onClick={() =>
                              navigate("/engagement/communication/add")
                            }
                          >
                            {"Criar comunicação"}
                          </Button>
                        </NotFoundMessage>
                      </>
                    )}
                  </>
                ),
              },
              {
                label: `Envios recorrentes (${recurrences?.length || 0})`,
                component: (
                  <>
                    {!empty ? (
                      <DataGrid
                        emptyState={{
                          isFiltered: true,
                          message: recurrenceFilters?.some((filter) => {
                            if (filter?.options?.length)
                              return filter?.options?.some((o) => o?.checked);

                            if (
                              filter?.dateRange?.from &&
                              filter?.dateRange?.to
                            )
                              return true;

                            return false;
                          })
                            ? "O filtro selecionado não apresenta resultados"
                            : "Você ainda não criou nenhuma comunicação de envio recorrente",
                        }}
                        searchField={{
                          value: recurrenceSearch || "",
                          onSearch: (value: string) =>
                            setRecurrenceSearch(value || ""),
                          placeholder: "Buscar por comunicação",
                        }}
                        filterTags={{
                          filters: recurrenceFilters,
                          onFilter: (e) => setRecurrenceFilters(e),
                          onClear: (e) => setRecurrenceFilters(e),
                        }}
                        columns={[
                          {
                            Header: "Nome de identificação",
                            accessor: "name",
                          },
                          {
                            Header: "Status",
                            accessor: "recurrence",
                            Cell: ({ value, row }) => {
                              const status =
                                row?.original?.status === "draft"
                                  ? "draft"
                                  : value?.status || "";

                              if (!status?.length) return <></>;

                              return (
                                <Tag variant={status}>
                                  {(() => {
                                    if (status === "draft") return "Rascunho";
                                    if (status === "send") return "Enviado";
                                    if (status === "scheduled")
                                      return "Não iniciado";
                                    if (status === "progress")
                                      return "Em andamento";
                                    if (status === "paused") return "Pausado";
                                    if (status === "closed") return "Encerrado";
                                  })()}
                                </Tag>
                              );
                            },
                          },
                          {
                            Header: "Criação",
                            accessor: "createdAt",
                            Cell: ({ value }) => (
                              <>{dayjs(value)?.format("DD/MM/YYYY HH:mm")}</>
                            ),
                          },
                          {
                            Header: "Início",
                            accessor: "sendDate",
                            Cell: ({ value }) => (
                              <>
                                {value
                                  ? dayjs(value)?.format("DD/MM/YYYY HH:mm")
                                  : null}
                              </>
                            ),
                          },
                          {
                            id: "endDate",
                            Header: "Término",
                            accessor: "recurrence",
                            Cell: ({ value }) => (
                              <>
                                {value && value?.endDate
                                  ? dayjs(value?.endDate)?.format("DD/MM/YYYY")
                                  : null}
                              </>
                            ),
                          },
                          {
                            Header: "Grupos",
                            accessor: "groups",
                            Cell: ({ value }) => <>{value?.length}</>,
                          },
                          {
                            Header: "Destinatários",
                            accessor: "employees",
                            Cell: ({ value, row }) => (
                              <>
                                {row?.original?.recurrence?.employees?.length ||
                                  value?.length}
                              </>
                            ),
                          },
                          {
                            accessor: "_id",
                            sticky: "right",
                            disableSortBy: true,
                            Cell: ({ value, row }) => {
                              if (
                                !validateOwnership(
                                  user as any,
                                  row?.original
                                ) &&
                                row?.original?.status != "draft"
                              ) {
                                return <MenuIcon disabled options={[]} />;
                              }
                              return (
                                <MenuIcon
                                  options={[
                                    {
                                      label: "Ver detalhes da comunicação",
                                      disabled:
                                        row?.original?.status === "draft"
                                          ? true
                                          : false,
                                      icon: "IconFileDescription",
                                      onClick: () => {
                                        segment({
                                          track: `people_strategic_hr_engagement_company_communications_viewdetails`,
                                        });
                                        navigate(
                                          `/engagement/communication/${value}`
                                        );
                                      },
                                    },
                                    {
                                      label: "Retomar comunicação",
                                      icon: "IconPlayerPlay",
                                      onClick: () => {
                                        segment({
                                          track: `people_strategic_hr_engagement_company_communications_resumecommunication`,
                                        });
                                        updateRecStatus(
                                          row?.original?._id,
                                          "progress"
                                        );
                                      },
                                      disabled:
                                        row?.original?.recurrence?.status ===
                                        "paused"
                                          ? false
                                          : true,
                                    },
                                    {
                                      label: "Encerrar comunicação",
                                      icon: "IconX",
                                      onClick: () => {
                                        segment({
                                          track: `people_strategic_hr_engagement_company_communications_closecommunication`,
                                        });
                                        updateRecStatus(
                                          row?.original?._id,
                                          "closed"
                                        );
                                      },
                                      disabled:
                                        [
                                          "paused",
                                          "progress",
                                          "scheduled",
                                        ]?.includes(
                                          row?.original?.recurrence?.status
                                        ) && row?.original?.status !== "draft"
                                          ? false
                                          : true,
                                    },
                                    {
                                      label: "Pausar comunicação",
                                      icon: "IconPlayerPause",
                                      onClick: () => {
                                        segment({
                                          track: `people_strategic_hr_engagement_company_communications_pausecommunication`,
                                        });
                                        updateRecStatus(
                                          row?.original?._id,
                                          "paused"
                                        );
                                      },
                                      disabled:
                                        row?.original?.recurrence?.status ===
                                        "progress"
                                          ? false
                                          : true,
                                    },
                                    {
                                      label:
                                        "Exportar relatório da comunicação",
                                      icon: "IconDownload",
                                      onClick: () => {
                                        segment({
                                          track:
                                            "people_strategic_hr_engagement_company_communications_exportreport",
                                        });
                                        selectCampaign(value, "export");
                                      },
                                      disabled:
                                        row?.original?.status === "draft",
                                    },
                                    {
                                      label: "Duplicar comunicação",
                                      icon: "IconCopy",
                                      onClick: () => {
                                        segment({
                                          track: `people_strategic_hr_engagement_company_communications_duplicatecommunication`,
                                        });
                                        copyCampaign(value);
                                      },
                                    },
                                    {
                                      label: "Editar comunicação",
                                      icon: "IconPencil",
                                      disabled:
                                        [
                                          "draft",
                                          "scheduled",
                                          "progress",
                                        ]?.includes(
                                          row?.original?.recurrence?.status
                                        ) || row?.status === "draft"
                                          ? false
                                          : true,
                                      onClick: () => {
                                        segment({
                                          track: `people_strategic_hr_engagement_company_communications_editcommunication`,
                                        });
                                        navigate(
                                          `/engagement/communication/edit/${value}`
                                        );
                                      },
                                    },
                                    {
                                      label: "Editar responsáveis",
                                      icon: "IconCheckupList",
                                      disabled:
                                        row?.original?.status === "draft",
                                      onClick: () => {
                                        segment({
                                          track: `people_strategic_hr_engagement_company_communications_editresponsible`,
                                        });
                                        setOwnersEditModal({
                                          open: true,
                                          engagement: row?.original,
                                        });
                                      },
                                    },
                                    {
                                      label: "Excluir comunicação",
                                      icon: "IconTrash",
                                      disabled: [
                                        "draft",
                                        "scheduled",
                                      ]?.includes(row?.original?.status)
                                        ? false
                                        : true,
                                      onClick: () => {
                                        segment({
                                          track: `people_strategic_hr_engagement_company_communications_deletecommunication`,
                                        });
                                        selectCampaign(value, "delete");
                                      },
                                    },
                                  ]}
                                />
                              );
                            },
                          },
                        ]}
                        loading={refetchRecurrencesLoading}
                        data={recurrences}
                      />
                    ) : (
                      <>
                        <Divider size="large" />
                        <NotFoundMessage
                          title={""}
                          message="Você ainda não criou nenhuma comunicação"
                        >
                          <Button
                            size="large"
                            variant="primary"
                            onClick={() =>
                              navigate("/engagement/communication/add")
                            }
                          >
                            {"Criar comunicação"}
                          </Button>
                        </NotFoundMessage>
                      </>
                    )}
                  </>
                ),
              },
              {
                label: totalEmployees
                  ? `Pessoas (${totalEmployees})`
                  : "Pessoas",
                component: (
                  <>
                    <PeopleTable
                      originPage="communication"
                      getTotalEmployees={(result) =>
                        setTotalEmployees(result || 0)
                      }
                    />
                  </>
                ),
              },
            ]}
          />
          <Modal
            title={"Tem certeza que deseja excluir este rascunho?"}
            message={
              "Excluir um rascunho irá removê-lo permanentemente da sua lista."
            }
            open={showConfirmModal}
            rightButton={{
              label: "Sim, excluir rascunho",
              onClick: () => deleteCampaign(),
            }}
            leftButton={{
              label: "Cancelar",
              onClick: () => {
                setSelectedCampaignId(undefined);
                setShowConfirmModal(!showConfirmModal);
              },
            }}
            status="alert"
            onClose={() => setShowConfirmModal(!showConfirmModal)}
          />
          <Modal
            title={"Pronto"}
            message={"Seu rascunho foi excluído com sucesso."}
            open={showSuccessModal}
            rightButton={{
              label: "Voltar para engajamento",
              onClick: () => setShowSuccessModal(!showSuccessModal),
            }}
            status="success"
            onClose={() => setShowSuccessModal(!showSuccessModal)}
          />
        </NewProvider>
        <FeatureNotification id="survey-feeling-notification" />
        <EditOwnersModal
          open={ownersEditModal?.open}
          engagement={ownersEditModal?.engagement}
          onClose={() =>
            setOwnersEditModal({
              open: false,
              engagement: undefined,
            })
          }
          onSuccess={refetchEngagements}
        />
      </PageContainer>
    </>
  );
};
