export enum DepositTimeUnitEnum {
  DAY = 'day',
  MONTH = 'month',
}

export type Benefit = {
  id: string
  name: string
  url: string
  value: number
  topupDepositEnabled: boolean
  depositTimeUnit: DepositTimeUnitEnum
}

export type Employee = {
  id: string
  name: string
  document: string
  benefits: Benefit[]
  groups: string[]
}
