import { useMemo } from 'react';
import {
  Auth,
  getAccessTokenPayloadSync,
  usePermissions as usePlatformPermissions,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility';
import { z } from 'zod';

/** Currently selected employeeId */
function useEmployeeId() {
  const accessToken = getAccessTokenPayloadSync();

  if (!accessToken?.employeeId) {
    forceSignOut();
    return null;
  }
  return accessToken.employeeId;
}

/** Currently selected company */
function useCompany() {
  const selectedCompany = useSelectedCompany();

  /** Currently selected company */
  const selectedCompanyParsed = selectedCompanySchema.safeParse(selectedCompany);

  if (!selectedCompanyParsed.success) {
    forceSignOut();
    return null;
  }

  return selectedCompanyParsed.data?.selectedCompany;
}

const selectedCompanySchema = z.object({
  selectedCompany: z.object({
    /** Company id */
    id: z.string(),
    /** Company registration number */
    registrationNumber: z.string(),
  }),
});

function forceSignOut() {
  Auth.signOut();
}

export type CCPermissions =
  | 'expense_manage_corporate_card_balances'
  | 'expense_manage_corporate_cards';

function usePermissions() {
  const company = useCompany();
  const allPermissions = usePlatformPermissions();

  const permissions = useMemo(() => {
    if (allPermissions?.isAdmin) {
      return ['*'];
    } else if (!company) {
      return [];
    }

    const companyPermissions = allPermissions?.companies?.find?.(
      _company => company?.id === _company?.id,
    );

    return companyPermissions?.permissions ?? [];
  }, [allPermissions?.companies, allPermissions?.isAdmin, company?.id]);

  function hasPermission(permission: CCPermissions | CCPermissions[]) {
    if (typeof permission === 'string') {
      return permissions?.includes(permission);
    }

    return permission?.some(currentPermission =>
      permission?.includes(currentPermission),
    );
  }

  return {
    isRoot: allPermissions?.isAdmin ?? false,
    permissions: permissions,
    hasPermission,
  };
}

const AuthService = {
  useCompany,
  useEmployeeId,
  usePermissions,
};
export default AuthService;
