import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  BannersColumn,
  BannersContainer,
  BannersSectionContainer,
  StyledPageContainer,
} from './layout-2.styles';
import { PrimaryBanner } from '../PrimaryBanner';
import { BannerProps } from '../banners-section.dto';
import { useTranslation } from 'react-i18next';

export interface BannersLayout2Props {
  banners: [BannerProps, BannerProps];
}

const bannerImageStyles: React.CSSProperties = {
  height: '95%',
  right: '-10%',
  bottom: 0,
};

export const BannersLayout2 = (props: BannersLayout2Props) => {
  const [t] = useTranslation('translations', {
    keyPrefix: 'appStore.bannersSection',
  });
  return (
    <BannersSectionContainer>
      <StyledPageContainer>
        <Typography
          variantColor="var(--color-secondary-50)"
          variant="headline9"
          style={{ marginBottom: '12px' }}
        >
          {t('title')}
        </Typography>
        <BannersContainer>
          <BannersColumn>
            <PrimaryBanner
              contentStyle={{ width: '60%' }}
              imageStyle={bannerImageStyles}
              {...props.banners[0]}
              backgroundColor="var(--color-secondary-90)"
            />
          </BannersColumn>
          <BannersColumn>
            <PrimaryBanner
              contentStyle={{ width: '60%' }}
              imageStyle={bannerImageStyles}
              {...props.banners[1]}
              backgroundColor="#C6EEFD"
            />
          </BannersColumn>
        </BannersContainer>
      </StyledPageContainer>
    </BannersSectionContainer>
  );
};
