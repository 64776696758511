import { Loader, Spinner } from '@flash-tecnologia/hros-web-ui-v2'
import { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'

import { CheckoutGuardRoute } from './checkout-guard-route'
const OrderPage = lazy(() => import('../../domain/checkout/pages/order-page'))
const OrderReviewPage = lazy(
  () => import('../../domain/checkout/pages/order-review-page'),
)
const OrderConfirmationPage = lazy(
  () => import('../../domain/checkout/pages/order-confirmation-page'),
)

export const CheckoutRouter = [
  <Route element={<CheckoutGuardRoute />} key="CheckoutGuard">
    <Route
      path="order"
      index
      element={
        <Suspense fallback={<Loader size="small" variant="primary" />}>
          <OrderPage />
        </Suspense>
      }
      key="OrderPage"
    />
    <Route
      path="checkout/:orderId"
      element={
        <Suspense fallback={<Loader size="small" variant="primary" />}>
          <OrderPage />
        </Suspense>
      }
      key="OrderPageSummary"
    />
    <Route
      path="checkout/:orderId/review"
      element={
        <Suspense fallback={<Loader size="small" variant="primary" />}>
          <OrderReviewPage />
        </Suspense>
      }
      key="OrderReviewPage"
    />
    <Route
      path="checkout/:orderId/confirmation"
      element={
        <Suspense fallback={<Spinner size={32} variant="primary" />}>
          <OrderConfirmationPage />
        </Suspense>
      }
      key="OrderConfirmationPage"
    />
  </Route>,
]
