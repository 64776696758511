import { PageContainer as DSPageContainer } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledPageContainer = styled(DSPageContainer)`
  padding: ${({ theme }) => theme.spacings.xl5};
  display: flex;
  flex-direction: column;
`;

export const BannersSectionContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => `${theme.colors.neutral[95]}`};
  padding: ${({ theme }) => theme.spacings.l} 0;
  margin-top: -${({ theme }) => theme.spacings.l};
`;

export const BannersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const BannersColumnA = styled.div`
  display: flex;
  height: 100%;
  flex: 6;
  border-radius: ${({ theme }) => theme.borders.radius.m};
`;

export const BannersColumnnB = styled.div`
  display: flex;
  height: 100%;
  flex: 4;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs3};
`;
