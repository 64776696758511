export interface Flow {
  _id: string
  name: string
  type: any
  active: boolean
  companyId: string
  setupId: string
  columns: Column[]
  category: FlowCategory
  subcategory: FlowSubcategory
}

export const FLOW_SUBCATEGORY_OPTIONS = ["clt", "pj", "internship"] as const
export enum FlowSubcategory {
  CLT = "clt",
  PJ = "pj",
  INTERNSHIP = "internship",
}

export const FLOW_CATEGORY_OPTIONS = ["hiring", "resignation"] as const
export enum FlowCategory {
  HIRING = "hiring",
  RESIGNATION = "resignation",
}

export interface Column {
  _id: string
  name: string
  locked: true
  editable: boolean
  color: string
  statusOptions: {
    name: string
    colors: {
      circle: string
      background: string
      text: string
    }
  }[]
  description?: string
  position: number
  hidden: boolean
  tags: any
}
