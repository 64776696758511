import { QueryClient } from "@tanstack/react-query";
import { createTRPCReact, httpBatchLink } from "@trpc/react-query";
import type { AppRouter } from "bff";
import { getAccessToken } from "@flash-tecnologia/hros-web-utility";

export const trpc = createTRPCReact<AppRouter>();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const trpcClient = trpc.createClient({
  links: [
    httpBatchLink({
      headers: async () => {
        const token = await getAccessToken();
        return {
          "x-flash-auth": `Bearer ${token}`,
        };
      },
      url: `${process.env.BFF_URL}`,
    }),
  ],
});
