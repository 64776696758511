import { LocaleKeys } from './localeKeys';

const translations: LocaleKeys = {
  translations: {
    page: {
      helloworld: 'Olá expense :D',
    },
    general: {
      services: {
        feedback: {
          error_403: 'Você não possui acesso a está operação',
        },
      },
      helper: {
        optionNotRegistered: 'Nome não cadastrado',
      },
    },
    atoms: {
      checkboxList: {
        apply: 'Aplicar',
        clean: 'Limpar',
        empty: 'Nenhum item encontrado!',
      },
    },
    molecules: {
      advancesStatus: {
        open: 'Abertos',
        finished: 'Finalizados',
        submitted: 'Submetidos',
        reproved: 'Reprovados',
        approved: 'Aprovados',
        pending: 'Pendentes',
        awaiting_financial_processing: 'Aguardando processamento financeiro',
        processing_payment: 'Processando pagamento',
        processing_payment_error: 'Erro de pagamento',
      },
      feedbackBox: {
        dismiss: 'fechar',
        title: {
          info: {
            clients: 'Importação de clientes em andamento',
            costCenters: 'Importação de centros de custos em andamento',
          },
          error: {
            clients: 'Processamento finalizado com erros.',
            costCenters: 'Processamento finalizado com erros.',
            projects: 'Processamento finalizado com erros.',
          },
          success: 'Importação finalizada com sucesso',
        },
        description: {
          error: {
            fail: {
              clients: {
                plural: '{{quantity}} clientes não puderam ser importados. Você deverá refazer a importação.',
                singular: '{{quantity}} cliente não pôde ser importado. Você deverá refazer a importação.',
              },
              costCenters: {
                plural: '{{quantity}} centros de custo não puderam ser importados. Você deverá refazer a importação.',
                singular: '{{quantity}} centro de custo não pôde ser importado. Você deverá refazer a importação.',
              },
              projects: {
                plural: '{{quantity}} projetos não puderam ser importados. Você deverá refazer a importação.',
                singular: '{{quantity}} projeto não pôde ser importado. Você deverá refazer a importação.',
              },
            },
            completed: {
              clients: {
                singular: '{{quantity}} cliente conta com dados corretos para cadastro.',
                plural: '{{quantity}} clientes contam com dados corretos para cadastro.',
              },
              projects: {
                singular: '{{quantity}} projeto conta com dados corretos para cadastro.',
                plural: '{{quantity}} projetos contam com dados corretos para cadastro.',
              },
              costCenters: {
                singular: '{{quantity}} centro de custo conta com dados corretos para cadastro.',
                plural: '{{quantity}} centros de custo contam com dados corretos para cadastro.',
              },
            },
          },
          info: 'Enquanto isso, você pode continuar navegando normalmente. Assim que sua importação estiver concluída, notificaremos aqui na plataforma.',
          success: {
            clients: '{{quantity}} clientes cadastrados com sucesso. Confira na tabela abaixo os cadastros importados.',
            costCenters:
              '{{quantity}} centros de custo cadastrados com sucesso. Confira na tabela abaixo os cadastros importados.',
            projects:
              '{{quantity}} projetos cadastrados com sucesso. Confira na tabela abaixo os cadastros importados.',
          },
        },
        actionLabel: {
          error: 'Confira os erros',
        },
      },
      bulkImportDropzone: {
        loadFile: {
          subTitle: 'Arquivo enviado com sucesso!',
        },
        sendFile: {
          yourFile: 'Seu arquivo',
        },
      },
      costCenterToast: {
        updateErrorMessage: 'Erro ao atualizar o centro de custo',
        updateSuccessMessage: 'Centro de custo atualizado com sucesso',
        uploadErrorMessage: 'Erro ao carregar centro de custo',
      },
      projectToast: {
        uploadErrorMessage: 'Erro ao carregar projetos',
      },
      underConstruction: {
        description: 'Página em construção!',
        toGoBack: 'Voltar',
      },
      importHelpModal: {
        title: 'Precisa de ajuda com o seu arquivo?',
        subTitle: 'Em caso de dúvida ou erro na importação, envie o arquivo para a equipe de suporte.',
        actionButton: 'Enviar',
        input: {
          goal: 'Objetivo do arquivo',
          description: 'Descreva seu problema e como podemos te ajudar',
          max: 'Máximo de 100 caracteres',
        },
        toast: {
          success: {
            title: 'Pedido de suporte para arquivo criado com sucesso!',
            subTitle: 'Verifique o número de protocolo enviado para o seu e-mail.',
          },
        },
        dropzone: 'Seu arquivo',
      },
      clientToast: {
        getClientsTemplateErrorMessage: 'Erro ao obter arquivo template de clientes',
        getClientsErrorMessage: 'Erro ao obter a lista de clientes',
        createErrorMessageTitle: 'Erro ao adicionar cliente',
        createErrorMessage: 'As informações não foram salvas por erro no sistema, por favor, tente novamente.',
        createSuccessMessage: 'Cliente adicionado com sucesso!',
        deleteErrorMessage: {
          singularForm: 'Erro ao excluir o cliente',
          pluralForm: 'Erro ao excluir clientes',
        },
        deleteSuccessMessage: {
          singularForm: 'Cliente excluído com sucesso',
          pluralForm: 'Clientes excluídos com sucesso',
        },
        updateErrorMessage: 'Erro ao atualizar o cliente',
        updateSuccessMessage: 'Cliente atualizado com sucesso',
        uploadErrorMessage: 'Erro ao carregar clientes',
      },
      deleteClientModal: {
        confirm: 'Confirmar exclusão',
        title: {
          singularForm: 'Tem certeza que deseja excluir esse cliente?',
          pluralForm: 'Tem certeza que deseja excluir {{quantity}} clientes?',
        },
        subTitle: {
          singularForm: 'Todos os dados relacionados a ele serão perdidos e essa ação não poderá ser desfeita.',
          pluralForm: 'Todos os dados relacionados a eles serão perdidos e essa ação não poderá ser desfeita.',
        },
        attention: 'Atenção!',
      },
      inactivateClientModal: {
        title: {
          singularForm: {
            deactivate: 'Tem certeza que deseja desativar esse cliente?',
            activate: 'Tem certeza que deseja ativar esse cliente?',
          },
          pluralForm: {
            deactivate: 'Tem certeza que deseja desativar {{quantity}} clientes?',
            activate: 'Tem certeza que deseja ativar {{quantity}} clientes?',
          },
        },
        subTitle: {
          singularForm: {
            deactivate:
              'Ao desativá-lo, o cliente deixará de aparecer na listagem de clientes no lançamento de despesas.',
            activate: 'Ao ativá-lo, o cliente deixará de aparecer na listagem de clientes no lançamento de despesas.',
          },
          pluralForm: {
            deactivate:
              'Ao desativá-los, os clientes deixarão de aparecer na listagem de clientes no lançamento de despesas.',
            activate: 'Ao ativá-los, os clientes aparecerão na listagem de clientes no lançamento de despesas.',
          },
        },
        attention: 'Atenção!',
        confirm: {
          activate: 'Confirmar ativação',
          deactivate: 'Confirmar desativação',
        },
      },
      moreFilters: {
        title: 'Filtros',
        labels: {
          startDate: 'Data de início',
          endDate: 'Data final',
          creationDate: 'Data de criação',
          occurrences: 'Ocorrências',
          cardNumber: 'Número do cartão corporativo',
          requester: 'Solicitante',
          branch: 'Filial',
          area: 'Área',
          position: 'Cargo',
          costCenter: 'Centro de custo',
          client: 'Cliente',
          project: 'Projeto',
          reimbursableValue: 'Valor reembolsável',
          reportName: 'Nome do relatório',
          lastApprover: 'Último aprovador',
          paymentDate: 'Data de envio para pagamento',
          value: 'Valor',
        },
      },
      setupIntroduction: {
        hello: 'Olá, {{userName}}',
        title: 'Tenha controle total das despesas corporativas',
        description:
          'Falta pouco para você começar a revolução no gerenciamento das despesas corporativas da sua empresa. Configure em poucos cliques o módulo de Despesas da Flash.',
        skipConfiguration: 'Pular configurações',
        configureNow: 'Configurar agora',
      },
      setupInviteEmployees: {
        finish: 'Tudo pronto, {{userName}}!',
        title: 'Sua empresa já pode começar a revolucionar as despesas ',
        description:
          'Chegou o momento, você pode enviar um convite aos colaboradores cadastrados pra realizar o primeiro acesso.',
        skipInvite: 'Agora não',
        inviteEmployees: 'Convidar colaboradores',
        actionsDescriptions: 'Se preferir, você pode enviar o convite mais tarde.',
      },
      categoryForm: {
        codeLabel: 'Código',
        codeHelp: 'Utilize o código para integrar com seu sistema ERP',
        descriptionLabel: 'Descrição',
        isActiveLabel: 'Ativo',
        cancel: 'Cancelar',
        createSubmit: 'Criar',
        updateSubmit: 'Editar',
      },
      limitsForm: {
        spendingPolicies: 'Política de gastos',
        releaseCriteriaLabel: 'Critério de lançamento',
        criterion: {
          day: 'Dia',
          expense: 'Despesa',
          quantity: 'Quantidade',
          month: 'Mês',
        },
        currencyTypeLabel: 'Moeda',
        limitValueLabel: 'Valor limite',
        settings: 'Configurações',
        allowValueGreaterThanLimitLabel: 'Bloquear envio de despesa com valor maior que o limite',
        allowValueGreaterThanLimitHelp:
          'Caso o valor de uma despesa seja maior que o valor limite, o envio não será realizado e o colaborador será notificado.',
        commentRequiredLabel: 'Campo de comentário obrigatório',
        proofMandatory: 'Comprovante obrigatório',
        minimumInvoiceProofValueLabel: 'A partir de',
        minimumInvoiceProofValueHelp: 'Comprovante obrigatório em despesas com valor igual ou superior ao informado.',
      },
      actionsFeedback: {
        occurrence: 'ocorrência',
        occurrences: 'ocorrências',
      },
      gridPagination: {
        items: 'itens',
      },
      defaultModal: {
        cancel: 'Cancelar',
        confirm: 'Confirmar',
      },
      modalReportSelection: {
        selectReport: 'Selecionar relatório',
        select: 'Selecionar',
        createReport: 'Criar relatório',
        selectTheReportYouWantToAddTheExpenseToOrCreateANewReport:
          'Selecione o relatório em que deseja adicionar a despesa, ou crie um novo relatório.',
        report: 'Relatório',
        noReportsFound: 'Nenhum relatório encontrado',
      },
      receiptsImages: {
        receipt: 'Recibo',
        add: 'Adicionar recibo',
      },
      linkedReport: {
        add: 'Adicionar relatório',
        report: 'Relatório',
      },
      expenseCollapse: {
        establishment: 'Estabelecimento',
        value: 'Valor',
        date: 'Data',
        toRecall: 'Recolher',
        seeDetails: 'Ver detalhes',
        client: 'Cliente',
        project: 'Projeto',
        category: 'Categoria',
        costCenter: 'Centro de custo',
        comments: 'Comentário',
        uninformed: 'Não informado',
        report: 'Relatório',
        quantity: 'Quantidade',
      },
      occurrencesBox: {
        dismiss: 'Dispensar',
      },
      reportStatusCard: {
        filter: 'Filtrar',
        removeFilter: 'Limpar este filtro',
        pluralForm: {
          open: 'Abertos',
          reimbursed: 'Reembolsados',
          submitted: 'Submetidos',
          reproved: 'Reprovados',
          approved: 'Relatórios aprovados',
        },
        singularForm: {
          open: 'Aberto',
          reimbursed: 'Reembolsado',
          submitted: 'Submetido',
          reproved: 'Reprovado',
          approved: 'Relatório aprovado',
        },
      },
      newReportModal: {
        createTitle: 'Criar relatório',
        updateTitle: 'Editar relatório',
        create: 'Criar',
        update: 'Salvar',
        description: 'Finalidade',
        reportName: 'Nome do relatório',
        reportApprover: 'Aprovador',
      },
      reportToast: {
        createErrorMessage: 'Erro ao criar relatório',
        createSuccessMessage: 'Relatório criado com sucesso',
        updateSuccessMessage: 'Relatório atualizado com sucesso',
        updateErrorMessage: 'Erro ao atualizar o relatório',
        deleteSuccessMessage: 'Relatório excluído com sucesso',
        attachExpenseSuccessMessage: 'Despesa vinculada ao relatório com sucesso',
      },
      deleteReportModal: {
        attention: 'Atenção',
        enterTheJustification: '',
        justifyTheDisapproval: '',
        popupTitle: 'Tem certeza que deseja excluir este relatório?',
        popupSubtitle: 'Todas as despesas serão desvinculadas deste relatório.',
        confirmDeletion: 'Confirmar exclusão',
        attachExpenseSuccessMessage: 'Despesa vinculada ao relatório com sucesso',
      },
      gridFilters: {
        labelSearchField: 'Buscar',
        filterListLabel: 'Filtrar por',
      },
      addLimitToCategoryModal: {
        singleTitle: 'Adicionar limite para uma categoria ',
        sharedTitle: 'Adicionar limite compartilhado',
        singleSubtitle: 'Crie um limite de gastos para uma única categoria.',
        sharedSubtitle:
          'Crie um limite único para as categorias selecionadas. O limite será aplicado em cima da soma dos gastos nas diferentes categorias.',
        editSubtitle: 'Edite o limite de gastos para as categorias abaixo.',
        editTitle: 'Editar limite',
        edit: 'Salvar',
        add: 'Adicionar',
        updateSuccessMessage: 'Limite atualizado com sucesso.',
        categoryLabel: 'Categoria',
        releaseCriterionLabel: 'Critério de lançamento',
        criterion: {
          day: 'Dia',
          expense: 'Despesa',
          quantity: 'Quantidade',
          month: 'Mês',
        },
        currencyLabel: 'Moeda',
        limitValueLabel: 'Valor limite',
        settings: 'Configurações',
        allowValueGreaterThanLimitLabel: 'Bloquear envio de despesa com valor maior que o limite',
        allowValueGreaterThanLimitHelp:
          'Caso o valor de uma despesa seja maior que o valor limite, o envio não será realizado e o colaborador será notificado.',
        commentRequiredLabel: 'Campo de comentário obrigatório',
        proofMandatory: 'Comprovante obrigatório',
        minimumInvoiceProofValueLabel: 'A partir de',
        minimumInvoiceProofValueHelp: 'Comprovante obrigatório em despesas com valor igual ou superior ao informado.',
        tooltipAction: {
          single: 'Selecione uma categoria e defina um valor para continuar!',
          shared: 'Selecione uma ou mais categorias e defina um valor para continuar!',
          receiptRequired: 'Preencha o valor do comprovante obrigatório em despesas!',
        },
      },
      deleteLimitConfirmModal: {
        iconLabel: 'Atenção',
        title: {
          plural: 'Tem certeza que deseja excluir os {{quantity}} limites?',
          singular: 'Tem certeza que deseja excluir o limite?',
        },
        subtitle: {
          plural: 'Todos os dados relacionados a eles serão perdidos e essa ação não poderá ser desfeita.',
          singular: 'Todos os dados relacionados a ele serão perdidos e essa ação não poderá ser desfeita.',
        },
        delete: 'Confirmar exclusão',
      },
      deleteMileageRateConfirmModal: {
        iconLabel: 'Atenção',
        title: {
          plural: 'Tem certeza que deseja excluir as taxas de km?',
          singular: 'Tem certeza que deseja excluir a taxa de km?',
        },
        subtitle: {
          plural: 'Todos os dados relacionados a eles serão perdidos e essa ação não poderá ser desfeita.',
          singular: 'Todos os dados relacionados a ele serão perdidos e essa ação não poderá ser desfeita.',
        },
        delete: 'Confirmar exclusão',
      },
      mileageRateRegisterModel: {
        description: 'Estabeleça o valor por quilômetro rodado.',
        create: {
          title: 'Adicionar taxa de quilometragem',
          submit: 'Adicionar',
        },
        update: {
          title: 'Editar taxa de quilometragem',
          submit: 'Salvar',
        },
        fields: {
          startDate: 'Data de início',
          value: 'Valor',
        },
      },
      selectLimitTypeModal: {
        title: 'Adicionar limite',
        subtitle: 'Selecione como deseja adicionar o limite de categoria',
        single: {
          title: 'Limite para uma categoria',
          description: 'Crie um limite de gastos para uma única categoria.',
        },
        shared: {
          title: 'Limite compartilhado',
          description:
            'Crie um limite único para as categorias selecionadas. O limite será aplicado em cima da soma dos gastos nas diferentes categorias.',
        },
        continue: 'Continuar',
      },
      addLimitSuccessModal: {
        conclude: 'Concluir',
        continue: 'Continuar adicionando',
        singular: {
          title: 'Limite adicionado com sucesso!',
          subtitle: 'O limite para a categoria {{category}} foi adicionado.',
        },
        plural: {
          title: 'Limite adicionado com sucesso!',
          subtitle: 'O limite para as categorias {{category}} foi adicionado.',
        },
      },
      expenseSettings: {
        feedbacks: {
          updated: {
            success: 'Configuração atualizada com sucesso',
            error: 'Erro ao atualizar a configuração',
          },
        },
      },
      generalSettings: {
        title: 'Configurações gerais',
        description: 'Edite as configurações básicas que interferem no lançamento de despesas.',
        fieldsLabel: {
          expirationExpenses: 'Despesas expiram em',
          manualExpenseEntry: 'Permitir lançamento de despesas manuais',
          daysExpirationExpenses: 'Dias',
          allowLaterDate: 'Permitir envio de despesas com data posterior',
          allowEarlierDate: 'Permitir envio de despesas com data anterior',
        },
      },
      fieldCustomization: {
        title: 'Personalização de campos',
        description: 'Personalize os campos que serão exibidos e obrigatórios no lançamento de despesas.',
        enabledField: 'Campo habilitado',
        disabledField: 'Campo desabilitado',
        mandatoryField: 'Preenchimento obrigatório',
        mandatory: 'Obrigatório',
        nonMandatory: 'Não obrigatório',
        mandatoryFieldInfo: 'Campo obrigatório',
        fields: {
          quantity: 'Quantidade',
          category: 'Categoria',
          address: 'Estabelecimento',
          receiptFile: 'Recibo',
          currency: 'Moeda',
          paymentType: 'Tipo de pagamento',
          value: 'Valor',
          date: 'Data',
          description: 'Comentário',
          invoice: 'Nº do comprovante',
          reimbursableExpenses: 'Despesas reembolsáveis',
          notifyPendingExpenses: 'Notificar despesas pendentes',
          client: 'Cliente',
          invoiceKey: 'Chave da nota fiscal',
          project: 'Projeto',
          costCenter: 'Centro de custo',
          billable: 'Faturável',
        },
        feedback: {
          error: 'Ocorreu em erro na solicitação do campo: {{ field }}.',
        },
      },
      contractsModal: {
        expenses: {
          title: 'Condições Comerciais Flash Expense',
        },
        corporateCard: {
          title: 'Condições Comerciais Cartão Corporativo',
        },
        subtitle:
          'Para confirmar sua assinatura, você deve ler atentamente e aceitar aos Termos e Condições Comerciais do Contrato.',
        confirmation: 'Li e aceito',
        close: 'Fechar',
      },
      acceptSubscriptionCheckbox: {
        iHaveReadEndAcceptThe: 'Li e aceito as',
        commercialConditions: 'condições comerciais',
        fromFlashServices: 'dos serviços da Flash relacionados ao',
        products: {
          corporateCard: 'cartão corporativo',
          saas: 'software para gestão de despesas (”SAAS”)',
          travel: 'gerenciamento das viagens corporativas',
        },
        common: {
          and: 'e',
        },
        feedbacks: {
          error: {
            companyRevoked:
              'Você não deve assinar um novo contrato de despesas para esse CNPJ, pois possui cadastro ativo na plataforma antiga. Continue utilizando o sistema antigo até que ocorra a migração da sua empresa para o novo.',
            employeeInactive:
              'A assinatura não pode ser realizada pois esse usuário não está ativo. Realize a ativação do usuário e tente novamente.',
            saasContractNotFound:
              'Não encontramos contratos de SAAS pendentes para assinatura. Se o problema persistir, entre em contrato com o suporte.',
            corporateCardContractNotFound:
              'A assinatura não pode ser realizada pois o Contrato de Cartão Corporativo não foi carregado corretamente. Se o problema persistir, entre em contrato com o suporte.',
            default: 'Ocorreu um erro ao registrar o aceite dos termos, por favor tente novamente.',
            contractsHaveAlreadyBeenSigned:
              'Você não deve assinar um novo contrato de despesas para esse CNPJ, pois já possui contratos assinados.',
          },
          success: {
            labelIcon: { stepOne: 'Parabéns', stepTwo: '', stepThree: 'Importante' },
            title: {
              stepOne: 'Sua assinatura da Flash Expense está confirmada ⚡',
              stepThree:
                'Antes de começar a utilizar nossos serviços, revise quem será administrador de expense, atribuindo esse perfil para colaboradores já cadastrados na plataforma.',
            },
            caption: {
              stepOne: {
                welcome:
                  'A partir de hoje você já pode impulsionar sua gestão de despesas com a ferramenta mais inteligente do mercado.',
                description: {
                  partOne: 'Antes de começar a usar, <b>{{quickQuestions}}</b>',
                  quickQuestions: 'responda a algumas perguntas',
                  partTwo: ' rápidas para que possamos ajudá-los na utilização.',
                },
              },
              stepTwo: {
                error: 'An error occurred while submitting the form data, please try later',
                doubts: 'Diante de dúvidas sobre a atribuição de perfil, acesse esse vídeo tutorial.',
                addEmployee: 'Se o colaborador não estiver cadastrado, acesse "Equipe" e efetue o cadastro antes.',
                attention: 'Atenção',
                labels: {
                  default: 'Selecione',
                  valueDefault: 'Insira um valor',
                  value: 'Valor',
                  selected: 'Selecionado',
                  emailField: 'E-mail',
                  email: 'E-mail do(a) responsável pelo módulo de despesas e cartão corporativo',
                },
                options: {
                  requiredField: 'Campo obrigatório',
                  budgeLabel:
                    'Qual o valor mensal aproximado que sua empresa pretende gastar com os cartões corporativos Flash?',
                  budgetOptions: {
                    upTo10k: 'Até R$10 mil',
                    from10kTo20k: 'De R$10,001 a R$20,000',
                    from20kTo30k: 'De R$20,001 a R$30,000',
                    from30kTo50k: 'De R$30,001 a R$50,000',
                    from50kTo100k: 'De R$50,001 a R$100,000',
                    from100kTo200k: 'De R$100,001 a R$200,000',
                    above200k: 'Acima de R$200,001',
                    unknown: 'Não sei dizer',
                  },
                  travelBudgeLabel: 'Qual a média de valor gasto em viagens dos últimos 6 meses?',
                  personLabel: 'Quem será o responsável pela utilização do módulo de despesas e cartão corporativo?',
                  personOptions: {
                    me: 'Eu',
                    other: 'Outra pessoa',
                  },
                  deadlineExpectationLabel:
                    'Qual sua expectativa de prazo para implementar a solução de Despesas e Cartão Corporativo?',
                  deadlineExpectation: {
                    upTo1Month: 'Até 1 mês',
                    upTo2Month: 'Até 2 meses',
                    upTo3Month: 'Até 3 meses',
                    upTo4Month: 'Até 4 meses',
                    upTo5Month: 'Até 5 meses',
                    above6Months: 'Acima de 6 meses',
                    unknown: 'Não sei dizer',
                  },
                },
              },
            },
            modalButton: {
              confirm: 'Confirmar',
              startUsing: 'Comece a usar',
              next: 'Responder',
              reviewPermission: 'Revisar permissões',
              skip: 'Pular e iniciar',
              start: 'Comece a usar',
            },
            videoTitle: 'Como atribuir permissões ao colaborador',
          },
        },
      },
      creditCardForm: {
        title: 'Insira seu cartão de crédito',
        subtitle: 'Sua assinatura será debitada todo dia 5, no cartão abaixo.',
        labelFields: {
          cardholderName: 'Nome do titular',
          cardNumber: 'Número do Cartão',
          dueDate: 'Data de vencimento',
        },
      },
      subscriptionInformation: {
        valueCaption: 'pessoa/mês',
      },
      subscriptionPriceBox: {
        title: 'Sua assinatura Flash Expense no plano {{planName}}',
        free: 'Gratuito',
        perPersonMonth: 'por pessoa/mês',
        minimumPeoplePerMonth: 'Quantidade mínima de usuários/mês: {{quantity}} usuários',
      },
      pageHeader: {
        faq: {
          expenses: 'Tire dúvidas e conheça mais sobre prestação de contas',
          reports: 'Tire dúvidas e conheça mais sobre relatórios',
          accounting: 'Tire dúvidas e conheça mais sobre contabilizações',
          customizations: 'Tire dúvidas e conheça mais sobre preferências',
        },
      },
      addNewStopRoute: {
        buttonLabel: 'Adicione uma parada ou destino final',
      },
      distanceRoutes: {
        distance: 'Distância',
        ratePerKM: 'Taxa por KM',
      },
      expenseDetailsKilometer: {
        distance: 'Distância',
        ratePerKM: 'Taxa por quilometro',
        totalValue: 'Valor Total',
      },
      odometerUpload: {
        label: {
          start: 'odometro_inicial',
          final: 'odometro_final',
        },
      },
      subscriptionFeatures: {
        title: 'Serviços inclusos',
        corporateCard: 'Cartão Flash Corporativo',
        refundRequest: 'Solicitação de reembolso',
        expenseAccountsProvision: 'Prestação de contas de gastos',
        automaticApprovals: 'Aprovações automáticas',
        companyWidePolicy: 'Política a nível de empresa',
        companyCustomPolicy: 'Políticas multinível e personalizadas',
        corporateCardPolicy: 'Política por cartão corporativo',
        managementReport: 'Relatório gerencial',
        kmRefund: 'Reembolso por quilometragem',
        automaticManualApprovals: 'Aprovações automáticas e manuais',
        companyWideApprovals: 'Aprovações multinível e personalizadas',
        advances: 'Adiantamentos',
        companyPolicy: 'Política a nível de empresa',
        erpIntegration: 'Integração com ERP (Omie)',
        multipleErpIntegration: 'Integração com principais ERPs de mercado',
        moreFeatures: 'E muito mais (vide contrato)',
        travel: {
          corporateTravels: 'Viagens corporativas',
          airfareAndHotelQuotes: 'Cotação de aéreo e hotel',
          domesticAndInternationalTickets: 'Passagens nacionais e internacionais',
          domesticAndInternationalHotelReservations: 'Reserva de hotel nacional e internacional',
          approvalFlow: 'Fluxo de aprovação',
          policyConfiguration: 'Configuração de políticas',
          detailedReports: 'Relatórios detalhados',
        },
      },
      newFeatureTag: {
        new: 'Novo!',
      },
    },
    organisms: {
      subsidiaryRegister: {
        fields: {
          name: 'Unidade de negócio',
          status: 'Ativo',
          cnpj: 'CNPJ',
          uf: 'UF',
          code: 'Código',
          costCenters: 'Centro de custo',
          address: 'Endereço',
          number: 'Número',
          complement: 'Complemento',
          neighborhood: 'Bairro',
          city: 'Cidade',
          state: 'Estado',
          zipCode: 'CEP',
          activeSubsidiary: {
            title: 'Ativar unidade de negócio',
            description: 'Quando ativa, a unidade negócio pode ser vinculada a um usuário.',
          },
          requiredField: 'Campo obrigatório',
        },
        editSubsidiary: 'Editar unidade de negócio',
        manualAddSubsidiary: 'Cadastrar unidade de negócio',
        pageContent: [
          {
            title: 'Dados gerais',
            sectionTitle: 'Dados gerais',
            subTitle: 'Preencha os dados para o cadastro da unidade de negócio.',
          },
          {
            title: 'Atribuir pessoas',
            sectionTitle: 'Selecione as pessoas',
            subTitle: 'Vincule pessoas a unidade de negócio.',
          },
        ],
        sections: {
          costCenterInfo: 'Centro de custo',
          addressInfo: 'Endereço',
          generalData: 'Dados gerais',
        },
      },
      advancesApproval: {
        filters: {
          status: 'Status',
          referenceId: 'Busca por código',
          statusLabel: {
            open: 'Abertos',
            finished: 'Finalizados',
            submitted: 'Submetidos',
            reproved: 'Reprovados',
            approved: 'Aprovados',
            pending: 'Pendentes',
          },
        },
      },
      registerCostCenter: {
        bulkImport: {
          sendData: {
            title: 'Envio de dados',
            subTitle:
              'Envie os dados dos centros de custo por planilha ou arquivo de texto para o cadastro em lote. Nos próximos passos você vai poder conferir tudo e corrigir possíveis erros.',
          },

          fileModel: {
            title: 'Baixe o modelo Flash e insira os dados',
            subTitle:
              'Para a leitura do documento e redução do risco de erros, é aceito apenas documentos com o modelo Flash.',
          },
          sendFile: {
            title: 'Envie seu arquivo',
            subTitle: 'Com o arquivo em mãos, agora é só anexar abaixo! ',
          },
          help: {
            title: 'Precisa de ajuda com o seu arquivo?',
            subTitle: 'Em caso de dúvida ou erro na importação, envie seu arquivo para a nossa equipe de suporte.',
            actionButton: 'Enviar o arquivo para o suporte',
          },
          breadcrumbs: {
            manageCompany: 'Gerenciar empresa',
            addByFile: 'Adicionar cliente por arquivo',
          },
          footer: {
            continue: 'Continuar',
            cancel: 'Cancelar',
          },
        },
      },
      advances: {
        delete: {
          errorMessage: 'Ocorreu um erro ao tentar excluir os adiantamentos',
          successMessage: {
            plural: 'Adiantamentos excluídos com sucesso',
            singular: 'Adiantamento excluído com sucesso',
          },
        },
        unlink: {
          errorMessage: 'Ocorreu um erro ao tentar desvincular os adiantamentos',
          successMessage: {
            plural: 'Adiantamentos desvinculados com sucesso',
            singular: 'Adiantamento desvinculado com sucesso',
          },
        },
        addButton: 'Adicionar adiantamento',
        grid: {
          statusLabel: 'Status',
          searchFieldLabel: 'Busca por código de adiantamento',
          header: {
            columns: {
              advance: 'Código',
              advanceDate: 'Data',
              value: 'Valor',
              status: 'Status',
              actions: 'Ações',
              requester: 'Solicitante',
            },
            batchActions: {
              delete: 'Excluir',
              approve: 'Aprovar',
              reprove: 'Reprovar',
              unlink: 'Desvincular',
              link: 'Vincular',
              pay: 'Pagar',
              sendToIntegration: 'Enviar para integração',
              tooltips: {
                status: 'Somente adiantamentos com status "Pendente" podem ter essa ação realizada',
              },
            },
          },
          body: {
            actions: {
              edit: 'Editar adiantamento',
              delete: 'Excluir adiantamento',
              link: 'Vincular adiantamento',
              unlink: 'Desvincular adiantamento',
              view: 'Visualizar',
              approve: 'Aprovar',
              reprove: 'Reprovar',
              pay: 'Pagar',
              sendToIntegration: 'Enviar para integração',
            },
            status: {
              pending: 'Pendente',
              submitted: 'Submetido',
              open: 'Aberto',
              approved: 'Aprovado',
              reproved: 'Reprovado',
              finished: 'Finalizado',
              awaiting_financial_processing: 'Aguardando processamento financeiro',
              processing_payment: 'Aguardando pagamento',
              processing_payment_error: 'Erro no processamento de pagamento',
            },
            occurrence: {
              plural: 'ocorrências',
              singular: 'ocorrência',
            },
          },
        },
        listPreview: {
          counterData: {
            label: 'Adiantamentos',
            items: {
              plural: '{{ items }} itens',
              singular: '{{ items }} item',
            },
          },
        },
        modal: {
          exclusion: {
            iconDescription: 'Atenção!',
            title: {
              plural: 'Tem certeza que deseja excluir os adiantamentos selecionados?',
              singular: 'Tem certeza que deseja excluir esse adiantamento?',
            },
            message: {
              plural: 'Todos os dados relacionados a eles serão perdidos e essa ação não poderá ser desfeita.',
              singular: 'Todos os dados relacionados a ele serão perdidos e essa ação não poderá ser desfeita.',
            },
            actions: {
              confirm: {
                plural: 'Confirmar exclusões',
                singular: 'Confirmar exclusão',
              },
            },
          },
          approve: {
            iconDescription: 'Atenção!',
            title: {
              plural: 'Tem certeza que deseja excluir as formas de pagamento selecionadas?',
              singular: 'Tem certeza que deseja excluir essa forma de pagamento?',
            },
            message: {
              plural: 'Todos os dados relacionados a elas serão perdidos e essa ação não poderá ser desfeita.',
              singular: 'Todos os dados relacionados a ela serão perdidos e essa ação não poderá ser desfeita.',
            },
            actions: {
              confirm: {
                plural: 'Confirmar exclusões',
                singular: 'Confirmar exclusão',
              },
            },
          },
          reprove: {
            iconDescription: 'Atenção!',
            title: {
              plural: 'Tem certeza que deseja reprovar os adiantamentos selecionados?',
              singular: 'Tem certeza que deseja reprovar esse adiantamento?',
            },
            message: {
              plural:
                'O adiantamento reprovado será removido do relatório e o colaborador poderá ajustá-lo e reenviá-lo para análise, se necessário.',
              singular:
                'Os adiantamentos reprovados serão removidos do relatório e o colaborador poderá ajustá-los e reenviá-los para análise, se necessário.',
            },
            actions: {
              confirm: {
                plural: 'Confirmar reprovações',
                singular: 'Confirmar reprovação',
              },
            },
          },
          unlink: {
            iconDescription: 'Atenção!',
            title: {
              plural: 'Tem certeza que deseja desvincular os adiantamento selecionados?',
              singular: 'Tem certeza que deseja desvincular esse adiantamento?',
            },
            message: {
              plural:
                'Os adiantamentos voltarão para a listagem, e será necessário vinculá-los a um relatório novamente.',
              singular:
                'O adiantamento voltará para a listagem, e será necessário vinculá-lo a um relatório novamente.',
            },
            actions: {
              confirm: {
                plural: 'Confirmar desvinculações',
                singular: 'Confirmar desvinculação',
              },
            },
          },
        },
      },
      importInfoContainer: {
        error: {
          row: 'Erro na linha {{line}}',
        },
      },
      BulkImportDetails: {
        title: 'Status da importação',
        subTitle:
          'Acompanhe aqui o status da sua importação. Esse processo pode demorar alguns minutos, se desejar, você pode fechar essa página e continuar usando a plataforma normalmente que notificaremos quando for finalizada.',
        header: {
          clients: 'Adicionar cliente por arquivo',
          costCenters: 'Adicionar centro de custo por arquivo',
        },
        newImportLabel: 'Nova importação',
      },
      ImportInfoContainer: {
        errorProcessing: 'Processamento finalizado com erros',
        peopleInfoError: {
          clients: {
            singular: '{{quantity}} cliente conta com erro no cadastro, impedindo a importação.',
            plural: '{{quantity}} clientes contam com erro no cadastro, impedindo a importação.',
          },
          projects: {
            singular: '{{quantity}} projeto conta com erro no cadastro, impedindo a importação.',
            plural: '{{quantity}} projetos contam com erro no cadastro, impedindo a importação.',
          },
          costCenters: {
            singular: '{{quantity}} centro de custo conta com erro no cadastro, impedindo a importação.',
            plural: '{{quantity}} centros de custo contam com erro no cadastro, impedindo a importação.',
          },
        },
        peopleInfoSuccess: {
          clients: {
            singular: '{{quantity}} cliente conta com dados corretos para cadastro.',
            plural: '{{quantity}} clientes contam com dados corretos para cadastro.',
          },
          projects: {
            singular: '{{quantity}} projeto conta com dados corretos para cadastro.',
            plural: '{{quantity}} projetos contam com dados corretos para cadastro.',
          },
          costCenters: {
            singular: '{{quantity}} centro de custo conta com dados corretos para cadastro.',
            plural: '{{quantity}} centro de custos contam com dados corretos para cadastro.',
          },
        },
        errorDetails: 'Confira no arquivo abaixo os erros. Corrija o arquivo e o importe novamente.',
        errorImporting: 'Alguns itens contam com erro no cadastro.',
      },
      registerProject: {
        assignUsersGrid: {
          title: 'Selecione as pessoas',
          subTitle:
            'Como padrão todas as pessoas poderão lançar despesas ao cliente, se desejar, selecione pessoas específicas. ',
          header: {
            name: 'Nome',
          },
          allPeople: 'Todas as pessoas ({{selected}})',
          selectedForProject: 'Selecionados para lançar nesse projeto ({{selected}})',
          feedback: {
            addUsers: {
              success: 'Pessoas vinculadas ao projeto com sucesso',
              error: 'Erro ao vincular pessoas ao projeto',
            },
            removeUsers: {
              success: 'Pessoas desvinculadas ao projeto com sucesso',
              error: 'Erro ao desvincular pessoas ao projeto',
            },
          },
        },
        bulkImport: {
          sendData: {
            title: 'Envio de dados',
            subTitle:
              'Envie os dados dos clientes por planilha ou arquivo de texto para o cadastro em lote. Nos próximos passos você vai poder conferir tudo e corrigir possíveis erros.',
          },
          fileModel: {
            warning: 'É necessário enviar um arquivo',
            title: 'Baixe o modelo Flash e insira os dados',
            subTitle:
              'Para a leitura do documento e redução do risco de erros, é aceito apenas documentos com o modelo Flash.',
          },
          sendFile: {
            title: 'Envie seu arquivo',
            subTitle: 'Com o arquivo em mãos, agora é só anexar abaixo! ',
          },
          help: {
            title: 'Precisa de ajuda com o seu arquivo?',
            subTitle: 'Em caso de dúvida ou erro na importação, envie seu arquivo para a nossa equipe de suporte.',
            actionButton: 'Enviar o arquivo para o suporte',
          },
          breadcrumbs: {
            manageCompany: 'Gerenciar empresa',
            addByFile: 'Adicionar projeto por arquivo',
            projects: 'Projetos',
          },
          footer: {
            continue: 'Continuar',
            cancel: 'Cancelar',
          },
        },
      },
      clientsPreview: {
        title: 'Clientes',
        subTitle: 'Gerencie a carteira de clientes da sua empresa que contará com alocação de despesas corporativas.',
        addClient: {
          label: 'Adicionar cliente',
          options: {
            addClientByFile: 'Adicionar cliente via planilha',
            addClientManually: 'Adicionar cliente via plataforma',
          },
        },
        breadcrumbs: {
          clients: 'Clientes',
          manageCompany: 'Gerenciar empresa',
        },
      },
      clientsGridPreview: {
        items: {
          plural: 'itens',
          singular: 'item',
        },
        title: 'Clientes cadastrados',
        search: 'Busca por cliente',
      },
      registerClient: {
        title: 'Dados gerais',
        subTitle: 'Insira os dados básicos do cliente.',
        assignUsersDescription:
          'Como padrão todas as pessoas poderão lançar despesas ao cliente, se desejar, selecione pessoas específicas.',
        required: 'campo obrigatório',
        breadcrumbs: {
          clients: 'Clientes',
          manageCompany: 'Gerenciar empresa',
          registerClient: 'Cadastrar cliente',
        },
        assignUsersGrid: {
          allPeople: 'Todas as pessoas ({{selected}})',
          selectedForClient: 'Selecionadas para lançar nesse cliente ({{selected}})',
          all: 'Todas ({{selected}})',
          selected: 'Selecionadas ({{selected}})',
          header: {
            name: 'Nome',
          },
        },
        steps: {
          header: {
            generalData: 'Dados gerais',
            users: 'Atribuir usuários',
          },
          pageContent: [
            {
              title: 'Dados gerais',
              sectionTitle: 'Dados gerais',
              subTitle: 'Insira os dados básicos do cliente.',
            },
            {
              title: 'Atribuir pessoas',
              sectionTitle: 'Selecione as pessoas',
              subTitle: 'Escolha quais pessoas poderão lançar despesas a esse projeto.',
            },
          ],
        },
        bulkImport: {
          sendData: {
            title: 'Envio de dados',
            subTitle: {
              clients:
                'Envie os dados dos clientes por planilha ou arquivo de texto para o cadastro em lote. Nos próximos passos você vai poder conferir tudo e corrigir possíveis erros.',
              projects:
                'Envie os dados dos projetos por planilha ou arquivo de texto para o cadastro em lote. Nos próximos passos você vai poder conferir tudo e corrigir possíveis erros.',
              costCenters:
                'Envie os dados dos centros de custo por planilha ou arquivo de texto para o cadastro em lote. Nos próximos passos você vai poder conferir tudo e corrigir possíveis erros.',
            },
          },
          fileModel: {
            warning: 'É necessário enviar um arquivo',
            title: 'Baixe o modelo Flash e insira os dados',
            subTitle:
              'Para a leitura do documento e redução do risco de erros, é aceito apenas documentos com o modelo Flash.',
          },
          sendFile: {
            title: 'Envie seu arquivo',
            subTitle: 'Com o arquivo em mãos, agora é só anexar abaixo! ',
          },
          help: {
            title: 'Precisa de ajuda com o seu arquivo?',
            subTitle: 'Em caso de dúvida ou erro na importação, envie seu arquivo para a nossa equipe de suporte.',
            actionButton: 'Enviar o arquivo para o suporte',
          },
          breadcrumbs: {
            manageCompany: 'Gerenciar empresa',
            addByFile: 'Adicionar cliente por arquivo',
            clients: 'Clientes',
          },
          footer: {
            continue: 'Continuar',
            cancel: 'Cancelar',
          },
        },
      },
      registerClientForm: {
        name: 'Nome do cliente',
        phone: 'Telefone do cliente',
        email: 'E-mail do cliente',
        site: 'Site do cliente',
        referenceId: 'Código',
        referenceIdHelper: 'Insira o código utilizado pela sua empresa para melhor controle.',
        active: 'Ativar cliente',
        activeDescription: 'Quando ativo, o cliente aparece como uma opção ao lançar uma despesa.',
        error: {
          fieldRequired: '{{field}} deve ser preenchido',
        },
      },
      clientsGrid: {
        client: 'Cliente',
        phone: 'Telefone',
        status: 'Status',
        actions: 'Ações',
        uninformedEmailClient: 'E-mail não informado',
        active: {
          true: 'Ativo',
          false: 'Inativo',
        },
        actionsGrid: {
          singularForm: {
            edit: 'Editar',
            delete: 'Excluir',
            activate: 'Ativar',
            deactivate: 'Desativar',
          },
          pluralForm: {
            delete: 'Excluir',
            activate: 'Ativar',
            deactivate: 'Desativar',
          },
        },
        selectedItems: '{{selectedItems}} de {{totalItems}} selecionados',
      },
      analyticsPreview: {
        tabLabel: {
          expense: 'Expense',
          insights: 'Insights',
          reports: 'Reports',
        },
      },
      setupCategory: {
        title: 'Configure as categorias',
        description:
          'As categorias são utilizadas na prestação de contas para especificar o tipo de despesa, facilitando a análise dos gastos da sua corporação. Ative as categorias que deseja disponibilizar na plataforma.',
        createButton: 'Criar categoria',
        formTitleCreate: 'Criar categoria',
        formTitleUpdate: 'Editar categoria',
        disableAll: 'Desativar todas',
        enableAll: 'Ativar todas',
      },
      setupLimits: {
        title: 'Configure os limites',
        description:
          'Os limites de gastos por categoria permitem que o sistema identifique automaticamente quando uma despesa viola à regra, facilitando a análise das prestações de contas.',
      },
      expenseGrid: {
        category: 'Categoria',
        date: 'Data',
        value: 'Valor',
        place: 'Local',
        receipt: 'Recibo',
        actions: 'Ações',
        expenses: 'Despesas',
        pendingExpenses: 'Despesas com pendência',
        ascendingOrder: 'Ordem ascendente',
        descendingOrder: 'Ordem decrescente',
        items: 'itens',
        uninformedCategory: 'Não Categorizada',
        establishment: 'Local',
        expenseDate: 'Recibo',
        attachments: 'Data',
        occurrence: 'ocorrência',
        occurrences: 'ocorrências',
        referenceId: 'ID',
        actionsGrid: {
          feedbacks: {
            unlink: {
              pluralForm: {
                error: 'Ocorreu um erro ao desvincular as despesas!',
                success: 'Despesas desvinculadas com sucesso!',
              },
              singularForm: {
                error: 'Ocorreu um erro ao desvincular a despesa!',
                success: 'Despesa desvinculada com sucesso!',
              },
            },
            delete: {
              pluralForm: {
                error: 'Ocorreu um erro ao excluir as despesas!',
                success: 'Despesas excluídas com sucesso!',
              },
              singularForm: {
                error: 'Ocorreu um erro ao excluir a despesa!',
                success: 'Despesa excluída com sucesso!',
              },
            },
          },
          pluralForm: {
            unlink: 'Desvincular despesas',
            delete: 'Excluir despesas',
            remove: 'Remover despesas',
          },
          singularForm: {
            view: 'Visualizar despesa',
            edit: 'Editar despesa',
            unlink: 'Desvincular despesa',
            delete: 'Excluir despesa',
          },
          tooltip: {
            delete:
              'A exclusão de despesas é permitida somente para despesas desvinculadas ou vinculadas a um relatório com status "Aberto".',
            emptyReceipts: 'Despesa sem recibos vinculados.',
          },
        },
        selectedItems: '{{selectedItems}} de {{totalItems}} selecionados',
      },
      expenseReceipts: {
        loading: 'Carregando recibos... ',
      },
      expenseActions: {
        expense: 'Despesa',
        mileage: 'Quilometragem',
        import: 'Importar',
      },
      reportGrid: {
        name: 'Nome do relatório',
        activePeriod: 'Período',
        value: 'Valor',
        status: 'Status',
        id: 'Id',
        actions: 'Ações',
        expenses: 'Despesas',
        viewReport: 'Visualizar relatório',
        editReport: 'Editar relatório',
        deleteReport: 'Excluir relatório',
        ascendingOrder: 'Ordem ascendente',
        descendingOrder: 'Ordem decrescente',
        selectedItems: '{{selectedItems}} de {{totalItems}} selecionados',
        search: 'Busca por nome do relatório, código ou solicitante',
      },
      expenseRegisterForm: {
        ocr: {
          error: {
            title: 'Erro na leitura automática',
          },
          loading: 'Obtendo dados do recibo...',
        },
        fieldLabels: {
          client: 'Cliente',
          project: 'Projeto',
          quantity: 'Quantidade',
          category: 'Categoria',
          address: 'Estabelecimento',
          receiptFile: 'Recibos',
          currency: 'Moeda',
          paymentType: 'Forma de pagamento',
          value: 'Valor',
          date: 'Data da despesa',
          description: 'Adicione um comentário',
          costCenter: 'Centro de custo',
          refundable: 'Reembolsável',
          billable: 'Faturável',
          report: 'Relatório',
          invoice: 'Nº do comprovante',
          invoiceKey: 'Chave da nota fiscal',
          odometer: {
            start: 'Km inicial do odômetro',
            end: 'Km final do odômetro',
          },
        },
        optionsNotFound: 'Opções não encontradas',
        mandatoryField: 'Campo obrigatório',
      },
      expenseHistoryGrid: {
        by: 'por',
        date: 'Data',
        actions: 'Ações',
        title: 'Histórico',
      },
      odometerExpenseDetails: {
        initialKm: 'Km inicial do odômetro',
        finalKm: 'Km final do odômetro',
        distance: 'Distância',
        notInformed: 'Não informado',
      },
      approverReportGrid: {
        actionsOptions: {
          tooltip: {
            approval: 'Somente relatórios com status "Submetido" podem ser reembolsados',
            reprove: 'Somente relatórios com status "Submetido" podem ser reprovados',
          },
        },
      },
      batchActionsGrid: {
        selectedItems: '{{selectedItems}} de {{totalItems}} selecionados',
      },
      accountingPending: {
        statusCard: {
          title: 'despesas',
          pendingAccounting: 'Prestação de contas pendentes',
          outOfPolitics: 'Fora da política',
          corporateCardExpenses: 'Despesa com cartão corporativo',
          filter: 'Analisar despesas',
          clearFilter: 'Limpar filtro',
          filterButton: {
            addFilter: 'Analisar despesas',
            removeFilter: 'Limpar este filtro',
          },
        },
        expensesTable: {
          header: {
            name: 'Membro da equipe e despesas',
            expensePendingAmount: 'Valor',
            reportsCount: 'Relatório',
            paymentMethods: 'Conteúdo do relatório',
            action: 'Ações',
          },
          body: {
            reportsCount: 'Relatórios',
            totalReport: 'Relatório',
            totalPendingExpense: 'Despesa',
            pendingExpensesCount: 'Despesas',
            violationCount: '{{violationCount}} {{expense}} fora da política',
            totalReceiptNotFound: 'Faltam {{totalReceiptNotFound}} recibos',
          },
          subRows: {
            noReportsFound: 'Sem relatório',
            loadingSubRows: 'Carregando...',
            loadMore: 'Carregar mais',
            paginationOf: 'de',
          },
          action: {
            seeReport: 'Ver Relatório',
            seeExpense: 'Ver detalhes da despesa',
            seeReceipts: 'Ver recibo',
            request: 'Solicitar prestação',
          },
          filters: {
            userName: 'Membro da equipe',
            paymentMethod: 'Conteúdo do relatório',
            creationDate: 'Data de Criação',
            value: 'Valor',
          },
        },
      },
      financialReportGrid: {
        report: 'Relatório',
        requester: 'Solicitante',
        name: 'Nome do relatório',
        value: 'Valor',
        status: 'Status',
        period: 'Período',
        date: 'Data',
        actions: 'Ações',
        selectedItems: '{{selectedItems}} de {{totalItems}} selecionados',
        orderBy: {
          ascendingOrder: 'Ordem ascendente',
          descendingOrder: 'Ordem decrescente',
        },
        statusOptions: {
          open: 'Abertos',
          reimbursed: 'Reembolsados',
          submitted: 'Submetidos',
          reproved: 'Reprovados',
          pending: 'Pendentes',
          approved: 'Aprovados',
          awaiting_financial_processing: 'Aguardando processamento financeiro',
          processing_payment: 'Aguardando pagamento',
          processing_payment_error: 'Erro de pagamento',
        },
        actionsOptions: {
          repay: 'Reembolsar',
          sendIntegration: 'Enviar para integração',
          integration: 'Integrar',
          cancel: 'Cancelar',
          cancelIntegration: 'Cancelar integração',
          reprove: 'Reprovar',
          exportPdf: 'Exportar PDF',
          exportPdfByCategory: 'Exportar PDF por categoria',
          view: 'Ver relatório',
          tooltip: {
            repay: 'Somente relatórios com status "Aprovado" podem ser reembolsados',
            integration: 'Somente relatórios com status "Aprovado" podem ser enviados para integração',
            cancelIntegration:
              'Somente relatórios com status "Aguardando processamento financeiro" e "Erro de pagamento" podem realizar o cancelamento da integração.',
            reprove: 'Somente relatórios com status "Aprovado" podem ser reprovados',
            exportPdf: '',
            exportPdfByCategory: '',
            exportExcel: 'Para realizar a exportação para excel é necessário filtrar um período',
          },
          feedbacks: {
            export: {
              pluralForm: {
                error: 'Ocorreu um erro ao exportar os relatórios',
                processing: 'Iniciando download dos relatórios em PDF',
              },
              singularForm: {
                error: 'Ocorreu um erro ao exportar o relatório',
                processing: 'Iniciando download do relatório em PDF',
              },
              notFound: 'Nenhum relatório encontrado',
            },
          },
        },
      },
      justificationModalReportReproval: {
        attention: 'Atenção!',
        enterTheJustification: 'Digite a justificativa',
        pluralForm: {
          justifyTheDisapproval: 'Justifique as reprovações',
          popupTitle: 'Tem certeza que deseja reprovar os {{reportsSelected}} relatórios selecionados?',
          popupSubtitle:
            'Após reprovados, o colaborador poderá reabri-los e realizar as alterações para submete-los novamente.',
          confirmDisapproval: 'Confirmar reprovações',
        },
        singularForm: {
          justifyTheDisapproval: 'Justifique a reprovação',
          popupTitle: 'Tem certeza que deseja reprovar o relatório?',
          popupSubtitle:
            'Após reprovado, o colaborador poderá reabri-lo e realizar as alterações para submeter novamente.',
          confirmDisapproval: 'Confirmar reprovação',
        },
      },
      justificationModalReportCancelIntegration: {
        attention: 'Atenção!',
        pluralForm: {
          popupTitle: 'Tem certeza que deseja cancelar a integração dos {{reportsSelected}} relatórios selecionados?',
          popupSubtitle:
            'Após cancelamento, os relatórios voltarão ao status de aprovado e continuarão disponíveis para ações do financeiro.',
          confirmDisapproval: 'Confirmar',
        },
        singularForm: {
          popupTitle: 'Tem certeza que deseja cancelar a integração do relatório?',
          popupSubtitle:
            'Após cancelamento, o relatório voltará ao status de aprovado e continuará disponível para ações do financeiro.',
          confirmDisapproval: 'Confirmar',
        },
      },
      justificationModalAdvanceReproval: {
        attention: 'Atenção!',
        enterTheJustification: 'Digite a justificativa',
        pluralForm: {
          justifyTheDisapproval: 'Justifique as reprovações',
          popupTitle: 'Tem certeza que deseja reprovar os {{advancesSelected}} adiantamentos selecionados?',
          popupSubtitle:
            'Após reprovados, o colaborador poderá reabri-los e realizar as alterações para submete-los novamente.',
          confirmDisapproval: 'Confirmar reprovações',
        },
        singularForm: {
          justifyTheDisapproval: 'Justifique a reprovação',
          popupTitle: 'Tem certeza que deseja reprovar o adiantamento?',
          popupSubtitle:
            'Após reprovado, o colaborador poderá reabri-lo e realizar as alterações para submeter novamente.',
          confirmDisapproval: 'Confirmar reprovação',
        },
      },
      justificationModalRemoveExpense: {
        attention: 'Atenção!',
        enterTheJustification: 'Digite o motivo',
        pluralForm: {
          justifyTheDisapproval: 'Justifique as remoções',
          popupTitle: 'Tem certeza que deseja reprovar as {{expensesSelected}} despesas selecionadas?',
          popupSubtitle:
            'As despesas reprovadas serão removidas do relatório e o colaborador poderá ajustá-las e reenviá-las para análise, se necessário.',
          confirmDisapproval: 'Confirmar reprovações',
        },
        singularForm: {
          justifyTheDisapproval: 'Justifique a remoção',
          popupTitle: 'Tem certeza que deseja reprovar a despesa do relatório?',
          popupSubtitle:
            'A despesa reprovada será removida do relatório e o colaborador poderá ajustá-la e reenviá-la para análise, se necessário.',
          confirmDisapproval: 'Confirmar reprovação',
        },
      },
      confirmModalReportRepay: {
        attention: 'Atenção!',
        conclude: 'Confirmar',
        pluralForm: {
          popupSubtitle: 'Os relatórios serão fechados e não poderão ser editados novamente.',
        },
        singularForm: {
          popupSubtitle: 'O relatório será fechado e não poderá ser editado novamente.',
        },
        refund: {
          pluralForm: {
            popupTitle: 'Tem certeza que deseja reembolsar os {{reportsSelected}} relatórios selecionados?',
          },
          singularForm: {
            popupTitle: 'Tem certeza que deseja reembolsar o relatório?',
          },
        },
        integration: {
          pluralForm: {
            popupTitle: 'Tem certeza que deseja enviar os {{reportsSelected}} relatórios selecionados para integração?',
          },
          singularForm: {
            popupTitle: 'Tem certeza que deseja enviar o relatório para integração?',
          },
        },
      },
      expirationDateModal: {
        conclude: 'Confirmar',
        popupTitle: 'Data de vencimento',
        requiredField: 'Campo obrigatório',
        expirationDateValidation: 'Data selecionada deve ser igual ou maior que o dia de hoje.',
        pluralForm: {
          popupSubtitle:
            'Escolha a data do vencimento dos relatórios. Caso o colaborador tenha direito ao reembolso, essa será a data de recebimento do valor.',
        },
        singularForm: {
          popupSubtitle:
            'Escolha a data do vencimento do relatório. Caso o colaborador tenha direito ao reembolso, essa será a data de recebimento do valor.',
        },
      },
      establishments: {
        fieldLabel: 'Estabelecimento',
        empty: {
          title: 'Buscar Locais',
          caption: 'Encontre um local por nome ou endereço informando a rua, número, bairro ou cidade.',
        },
        fixedMessage: {
          title: 'Não encontrou o local?',
          caption: 'Informe mais detalhes como número, bairro ou cidade.',
        },
        loader: {
          description: 'Carregando opções...',
        },
        loading: 'carregando...',
      },
      confirmationModalUnlinkExpense: {
        attention: 'Atenção!',
        pluralForm: {
          popupTitle: 'Tem certeza que deseja desvincular as {{expensesSelected}} despesas do relatório?',
          popupSubtitle: 'A despesas voltarão para a listagem, e será necessário vinculá-las a um relatório novamente',
          confirm: 'Confirmar desvinculações',
        },
        singularForm: {
          popupTitle: 'Tem certeza que deseja desvincular a despesa do relatório?',
          popupSubtitle: 'A despesa voltará para a listagem, e será necessário vinculá-la a um relatório novamente',
          confirm: 'Confirmar desvinculação',
        },
      },
      confirmationModalDeleteExpense: {
        attention: 'Atenção!',
        pluralForm: {
          popupTitle: 'Tem certeza que deseja excluir as {{expensesSelected}} despesas selecionadas?',
          popupSubtitle: 'Todos os dados relacionados a elas serão perdidos e essa ação não poderá ser desfeita.',
          confirm: 'Confirmar exclusões',
        },
        singularForm: {
          popupTitle: 'Tem certeza que deseja excluir a despesa?',
          popupSubtitle: 'Todos os dados relacionados a ela serão perdidos e essa ação não poderá ser desfeita.',
          confirm: 'Confirmar exclusão',
        },
      },
      expenseDetails: {
        occurrences: {
          title: 'Esta despesa possui',
          pluralForm: {
            occurrences: 'ocorrências',
          },
          singularForm: {
            occurrences: 'ocorrência',
          },
        },
        expenseTitle: {
          originDestination: 'Quilometragem - Origem e Destino',
          odometer: 'Quilometragem - Odômetro',
        },
        route: 'Trajeto',
      },
      reportDetailsPreview: {
        feedback: {
          sendReport: {
            singularForm: {
              success: 'Relatório enviado com sucesso!',
            },
            pluralForm: {
              success: 'Relatórios enviados com sucesso!',
            },
            confirmation: {
              title: 'Divisão de Relatório',
              attention: 'Atenção',
              proceed: 'Prosseguir',
            },
            feedback: {
              success: 'Sucesso',
              title: 'Relatórios submetidos com sucesso!',
              message:
                'As despesas foram adicionadas aos relatórios {{referenceId}}, que foram submetidos para aprovação com sucesso.',
              goToReports: 'Ir para relatórios',
            },
          },
          reopenReport: {
            singularForm: {
              success: 'Relatório reaberto com sucesso!',
              modalTitle: 'Tem certeza que deseja reabrir o relatório?',
              modalMsg:
                'O relatório será reaberto para que você possa editá-lo e submetê-lo, novamente, para análise e aprovação.',
            },
            pluralForm: {
              success: 'Relatórios reabertos com sucesso!',
              modalTitle: 'Tem certeza que deseja reabrir os relatórios selecionados?',
              modalMsg:
                'Os relatórios serão reabertos para que você possa editá-los e submetê-los, novamente, para análise e aprovação.',
            },
          },
        },
        occurrences: {
          singularForm: {
            title: 'Esse relatório possui {{occurrencesNumber}} ocorrência:',
          },
          pluralForm: {
            title: 'Esse relatório possui {{occurrencesNumber}} ocorrências:',
          },
        },
      },
      financialReportsPreview: {
        pluralForm: {
          refundedSuccessMessage: 'Relatórios reembolsados ​​com sucesso!',
          disapprovedSuccessMessage: 'Relatórios reprovados ​​com sucesso!',
          integrationSuccessMessage: 'Relatórios enviados para integração .',
          cancelIntegrationSuccessMessage: 'Cancelamentos realizados.',
        },
        singularForm: {
          refundedSuccessMessage: 'Relatório reembolsado ​​com sucesso!',
          disapprovedSuccessMessage: 'Relatório reprovado ​​com sucesso!',
          integrationSuccessMessage: 'Relatório enviado para integração.',
          cancelIntegrationSuccessMessage: 'Cancelamento realizado.',
        },
      },
      mileageRatesPreview: {
        feedbacks: {
          mileageRateDeletedSuccessfully: 'Taxa de quilometragem apagada com sucesso',
          errorDeletingMileageRate: 'Ocorreu um erro ao apagar a taxa de quilometragem',
          mileageRateSavedSuccessfully: 'Taxa de quilometragem salva com sucesso',
          errorSavingMileageRate: 'Ocorreu um erro ao salvar a taxa de quilometragem',
        },
      },
      attachExpenseModal: {
        title: 'Vincular despesa a relatório',
        subtitle: 'Selecione as despesas que deseja vincular ao relatório.',
        add: 'Adicionar',
        emptyExpenses: 'Nenhuma despesa disponível para vinculo!',
      },
      attachAdvancesModal: {
        title: 'Vincular adiantamento a relatório',
        subtitle: 'Selecione os adiantamentos que deseja vincular ao relatório.',
        add: 'Adicionar',
        emptyExpenses: 'nenhum adiantamento disponível para vinculo!',
      },
      customizationsPreview: {
        tabTitle: {
          expense: 'Despesas corporativas',
          companyPayables: 'Despesas com fornecedor',
          corporateTravel: 'Viagens Corporativas',
          categories: 'Categorias',
          policies: 'Políticas',
          corporateCard: 'Cartão corporativo',
        },
        tabLabel: {
          categories: 'Categorias',
          expenses: 'Despesas',
          policies: 'Políticas',
          approvals: 'Aprovações',
          reports: 'Relatórios',
          generalSettings: 'Configurações gerais',
          forms: 'Formulários',
          advances: 'Adiantamentos',
          delegation: 'Delegação de ações',
        },
      },
      customizationsPaymentMethods: {
        title: 'Formas de pagamento',
        caption: 'Adicione as formas de pagamento que poderão ser selecionadas no lançamento de despesas.',
        searchFieldLabel: 'Buscar por forma de pagamento',
        addPaymentMethod: {
          buttonAdd: 'Adicionar forma de pagamento',
        },
        grid: {
          header: {
            code: 'Código',
            paymentMethod: 'Forma de pagamento',
            status: 'Status',
            mileage: 'Quilometragem',
            refundable: 'Reembolsável',
            actions: 'Ações',
            tooltips: {
              mileage: 'Sinaliza se a forma de pagamento pode ser utilizada em despesas de quilometragem.',
              refundable: 'Sinaliza se a forma de pagamento permite à despesa ser reembolsada.',
            },
            batchActions: {
              deactivate: 'Desativar',
              activate: 'Ativar',
              delete: 'Deletar',
              tooltips: {
                delete: 'Formas de pagamento padrão não podem ser excluídas',
                active: 'Selecione formas de pagamento ativas',
              },
            },
          },
          body: {
            active: 'Ativo',
            deactivate: 'Inativo',
            yes: 'Sim',
            not: 'Não',
            actions: {
              edit: 'Editar forma de pagamento',
              active: 'Ativar forma de pagamento',
              deactivate: 'Desativar forma de pagamento',
              delete: 'Excluir forma de pagamento',
            },
          },
        },
        modal: {
          paymentMethod: {
            title: {
              add: 'Adicionar forma de pagamento',
              edit: 'Editar forma de pagamento',
            },
            fields: {
              code: {
                label: 'Código',
                helperText:
                  'Insira o código utilizado pela sua empresa para melhor controle. O uso desse código facilita a conciliação de relatórios exportados pela nossa plataforma',
              },
              paymentMethodName: 'Nome da forma de pagamento',
              settings: {
                title: 'Configurações',
                label: 'Ativar forma de pagamento',
                caption: 'Quando ativa, a forma de pagamento aparece como uma opção ao lançar uma despesa.',
              },
              refundablePaymentMethod: {
                label: 'Forma de pagamento reembolsável',
                tooltip: 'Permite às despesas lançadas com essa forma de pagamento a possibilidade de reembolso.',
              },
              allowUseOnMileageExpenses: 'Permitir uso em despesas de quilometragem',
              tooltip: {
                codeFiled: 'O campo de código não pode ser editado na forma de pagamento padrão!',
                paymentMethodNameField:
                  'O campo de nome da forma de pagamento não pode ser editado na forma de pagamento padrão!',
              },
              validationMessage: 'Preencha todos os campos!',
            },
            actionButton: {
              add: 'Adicionar',
              edit: 'Salvar',
            },
            feedbacks: {
              success: 'Alteração realizada com sucesso!',
              error: 'Não foi possível realizar alteração!',
              add: {
                success: 'Forma de pagamento adicionada com sucesso!',
                error: 'Não foi possível adicionar a forma de pagamento. Por favor, tente novamente.',
              },
              edit: {
                success: 'Forma de pagamento alterada com sucesso!',
                error: 'Não foi possível alterar a forma de pagamento. Por favor, tente novamente.',
              },
              active: {
                success: 'Alteração de status de ativação realizada com sucesso!',
                error: 'Erro ao alterar status de ativação!',
              },
              delete: {
                error: {
                  title: 'Ocorreu um erro ao excluir o método de pagamento: {{methodName}}!',
                  message: 'Não foi possível excluir o método de pagamento solicitado!',
                },
                success: 'Forma de pagamento excluída com sucesso!',
              },
              refundable: {
                confirmationMessage:
                  'A alteração da flag reembolsável será replicada para todas as despesas pendentes. Deseja continuar?',
              },
            },
          },
          exclusion: {
            iconDescription: 'Atenção!',
            title: {
              plural: 'Tem certeza que deseja excluir as formas de pagamento selecionadas?',
              singular: 'Tem certeza que deseja excluir essa forma de pagamento?',
            },
            message: {
              plural: 'Todos os dados relacionados a elas serão perdidos e essa ação não poderá ser desfeita.',
              singular: 'Todos os dados relacionados a ela serão perdidos e essa ação não poderá ser desfeita.',
            },
            actions: {
              confirm: {
                plural: 'Confirmar exclusões',
                singular: 'Confirmar exclusão',
              },
            },
          },
          deactivation: {
            iconDescription: 'Atenção!',
            title: {
              plural: 'Tem certeza que deseja desativar as formas de pagamento selecionadas?',
              singular: 'Tem certeza que deseja desativar essa forma de pagamento?',
            },
            message: {
              plural:
                'Ao desativá-las, elas deixarão de aparecer na listagem de centro de custo no lançamento de despesas.',
              singular:
                'Ao desativá-lo, ela deixará de aparecer na listagem de centro de custo no lançamento de despesas.',
            },
            actions: {
              confirm: {
                plural: 'Confirmar desativações',
                singular: 'Confirmar desativação',
              },
            },
          },
          refundable: {
            iconDescription: 'Atenção!',
            title: {
              singular: 'Tem certeza que deseja realizar essa alteração?',
            },
            actions: {
              confirm: {
                singular: 'Confirmar',
              },
            },
          },
        },
      },
      customizationsCustomFields: {
        title: 'Campos customizáveis',
        caption: 'Adicione campos customizáveis que poderão ser preenchidos no lançamento de despesas.',
        searchFieldLabel: 'Buscar por campo customizável',
        addCustomField: {
          buttonAdd: 'Adicionar campo customizável',
          disabledTooltip: 'Você só pode criar até 10 campos customizados',
        },
        grid: {
          header: {
            customField: 'Campo',
            status: 'Status',
            required: 'Obrigatório',
            type: 'Tipo',
            actions: 'Ações',
            batchActions: {
              deactivate: 'Desativar',
              activate: 'Ativar',
              delete: 'Deletar',
            },
          },
          body: {
            active: 'Ativo',
            deactivate: 'Inativo',
            yes: 'Sim',
            not: 'Não',
            actions: {
              edit: 'Editar campo customizado',
              active: 'Ativar campo customizado',
              deactivate: 'Desativar campo customizado',
              delete: 'Excluir campo customizado',
              moveUp: 'Mover para cima',
              moveDown: 'Mover para baixo',
            },
          },
        },
        modal: {
          customField: {
            title: {
              add: 'Adicionar campo customizado',
              edit: 'Editar campo customizado',
            },
            preview: {
              description: 'Confira a pré-visualização do campo',
            },
            configureField: 'Configurar campo',
            addFieldInfo: 'Configure as informações do campo',
            fields: {
              customFieldName: 'Nome do campo',
              customFieldType: {
                label: 'Tipo do campo',
                options: {
                  TEXT: 'Texto',
                  DATE: 'Data',
                  NUMBER: 'Numérico',
                  OPTIONS: 'Opções',
                  BAR_CODE: 'Leitor de código de barra',
                },
              },
              options: {
                addOptions: 'Adicione as opções',
                addOption: 'Adicionar opção',
                option: 'Opção',
              },
              addSelectOptions: {
                label: 'Adicionar items para seleção',
              },
              allowEditByField: {
                label: 'Permitir edição por',
                tooltip: 'Selecione os usuários que terão permissão para alterar esse campo',
                options: {
                  ADMIN: 'Administrador',
                  APPROVER: 'Aprovador',
                  USER: 'Usuário',
                  FINANCIAL: 'Financeiro',
                },
              },
              settings: {
                title: 'Configurações',
                label: 'Ativar campo customizado',
                caption:
                  'Quando ativo, o campo customizado aparece como um campo para preencher ao lançar uma despesa.',
              },
              requiredField: {
                label: 'Campo obrigatório',
                tooltip: 'Quando obrigatório, o usuário só pode lançar a despesa caso preencha o campo.',
              },
              validationMessage: 'Preencha todos os campos!',
            },
            actionButton: {
              add: 'Adicionar',
              edit: 'Salvar',
            },
            feedbacks: {
              success: 'Alteração realizada com sucesso!',
              error: 'Não foi possível realizar alteração!',
              add: {
                success: 'Campo customizado adicionado com sucesso!',
                error: 'Não foi possível adicionar o campo customizado. Por favor, tente novamente.',
              },
              edit: {
                success: 'Campo customizado alterado com sucesso!',
                error: 'Não foi possível alterar o campo customizado. Por favor, tente novamente.',
              },
              active: {
                success: 'Alteração de status de ativação realizado com sucesso!',
                error: 'Erro ao alterar status de ativação!',
              },
              delete: {
                error: {
                  title: 'Ocorreu um erro ao excluir o campo customizado: {{methodName}}!',
                  message: 'Não foi possível excluir o campo customizado solicitado!',
                },
                success: 'Campo customizado excluído com sucesso!',
              },
            },
          },
          exclusion: {
            iconDescription: 'Atenção!',
            title: {
              plural: 'Tem certeza que deseja excluir os campos customizados selecionados?',
              singular: 'Tem certeza que deseja excluir esse campo customizado?',
            },
            message: {
              plural: 'Todos os dados relacionados a elas serão perdidos e essa ação não poderá ser desfeita.',
              singular: 'Todos os dados relacionados a ela serão perdidos e essa ação não poderá ser desfeita.',
            },
            actions: {
              confirm: {
                plural: 'Confirmar exclusões',
                singular: 'Confirmar exclusão',
              },
            },
          },
          deactivation: {
            iconDescription: 'Atenção!',
            title: {
              plural: 'Tem certeza que deseja desativar os campos customizados selecionados?',
              singular: 'Tem certeza que deseja desativar esse campo customizado?',
            },
            message: {
              plural:
                'Ao desativá-las, elas deixarão de aparecer na listagem de centro de custo no lançamento de despesas.',
              singular:
                'Ao desativá-lo, ela deixará de aparecer na listagem de centro de custo no lançamento de despesas.',
            },
            actions: {
              confirm: {
                plural: 'Confirmar desativações',
                singular: 'Confirmar desativação',
              },
            },
          },
        },
      },
      generalReportSettings: {
        title: 'Configurações gerais',
        caption: 'Defina as configurações básicas dos relatórios de prestação de contas.',
        allowReimbursementToContainReimbursementExpenseOnly: {
          allow: 'Permitir que relatórios com reembolso contenham apenas despesas de mesmo',
          allowCostCenter: 'Centro de custo',
          allowPaymentMethod: 'Forma de pagamento',
          allowProject: 'Projeto',
          allowCategory: 'Categoria',
          allowByExpense: 'Por despesa',
          subsidiary: 'Filial',
          department: 'Área',
          costCenterSelectLabel: 'Selecione centro de custo',
          costCenterTooltip:
            'Quando a separação for feita por centro de custos, deve ser possível escolher se será utilizado o centro de custos da filial ou da área. Vale mencionar que essa definição não é obrigatória',
        },
        allowSameUserToApproveAdvanceAndReport: 'Permitir que o mesmo usuário aprove adiantamento e relatório',
        expirationDate: {
          allowAdd: 'Adicionar data de vencimento ao relatório',
          conditionalType: '',
          expirationCriteria: 'Critério de vencimento',
          typeOfFrequency: '',
          value: '',
          configureAutomaticExpiryData: 'Configurar dados de vencimento automático',
          manual: 'Manual',
          automatic: 'Automático',
          configurationModal: {
            title: 'Configurar data de vencimento automática',
            caption: 'Selecione uma das opções para definir a regra de vencimento automática dos relatórios.',
            actionButton: 'Salvar',
            cancelButton: 'Cancelar',
            fields: {
              reportsExpireEvery: 'Relatórios vencem a cada',
              after: 'após',
              frequency: 'Frequência',
              reportsExpireAll: 'Relatórios vencem toda',
              weeklyFrequency: 'Frequência semanal',
              reportsEreDueOnTheDay: 'Relatórios vencem no dia',
              expirationDateType: 'Critério',
              manual: 'Manual',
              automatic: 'Automático',
              days: 'Dias',
              dayOfTheWeek: 'Semanas',
              daysOfMonth: 'Meses',
              whenReimbursed: 'Relatório ser reembolsado',
              whenSentForIntegration: 'Relatório ser enviado para integração',
              whenApproved: 'Relatório ser aprovado',

              daysAfter: 'dia(s) após',
              theNext: 'A próxima',
              everyDay: 'Todo(s) dia(s)',
              ofTheMonthAfter: 'do mês após',

              monday: 'Segunda-feira',
              tuesday: 'Terça-feira',
              wednesday: 'Quarta feira',
              thursday: 'Quinta-feira',
              friday: 'Sexta-feira',
            },
            tooltip: {
              invalidFields: 'Preencha os campos da opção escolhida!',
            },
          },
        },
        prefixValue: 'Prefixo',
        startValue: 'Número inicial',
        codeTitle: 'Código',
        codeCaption: 'Defina um código que irá facilitar a conciliação de relatórios exportados pela plataforma.',
        tooltip: {
          addDateToggle:
            'Data em que o usuário receberá reembolso ou o relatório será contabilizado. Caso não for selecionada, o relatório fica com status aberto até ser finalizado pelo financeiro.',
          allowReportsOfTheSameToggle:
            'Quando habilitado, o sistema organizará os relatórios para terem despesas de um mesmo critério. Despesas que não seguirem o critério, serão alocadas em outro relatório.',
        },
      },
      exportPreferencesReports: {
        title: 'Preferências de exportação',
        includeInExportedDocument: 'Incluir no documento exportado:',
        pdfExportSettings: {
          includeCopyOfReceiptsAttached: 'Cópia dos recibos em anexo ao relatório',
          includeCostCenterSubtotal: 'Subtotal por centro de custo',
          includeHistory: 'Histórico do relatório',
          includeMileageSubtotal: 'SubTotal por quilometragem',
          includeProjectSubtotal: 'Subtotal por projeto',
          includeRequestorData: 'Dados do solicitante',
          includeSignature: 'Assinaturas',
          includeViolation: 'Violação de política',
        },
      },
      categoriesPreview: {
        title: 'Categorias',
        description: 'Defina as categorias de gastos que deseja disponibilizar para lançamento de despesas.',
        createCategory: 'Criar categoria',
        disableAll: 'Desativar todas',
        enableAll: 'Ativar todas',
        create: 'Criar',
        deleteModal: {
          headerIconLabel: 'Atenção!',
          headerTitle: 'Tem certeza que deseja excluir essa categoria?',
          headerSubtitle: 'Todos os dados relacionados a ela serão perdidos e essa ação não poderá ser desfeita.',
          confirmDeletion: 'Confirmar exclusão',
        },
      },
      categoryFormModal: {
        fieldsLabel: {
          code: 'Código empresa',
          description: 'Nome da categoria',
          activated: 'Ativada',
          contabilityAccountName: 'Nome da conta contábil',
          contabilityAccountCode: 'Código da conta contábil',
        },
        helperTextCodeField: 'Insira o código utilizado pela sua empresa para melhor controle.',
        mandatoryDescription: 'Campo obrigatório',
        createCategory: 'Criar categoria',
        editCategory: 'Editar categoria',
        create: 'Criar',
        edit: 'Editar',
        feedback: {
          create: {
            success: 'Categoria criada com sucesso!',
            error: 'Ocorreu um erro ao criar a categoria.',
          },
          update: {
            success: 'Categoria atualizada com sucesso!',
            error: 'Ocorreu um erro ao atualizar a categoria.',
          },
        },
      },
      limitGrid: {
        category: 'Categoria',
        criterion: 'Critério',
        limit: 'Limite',
        notAllowedActions: 'Ações não permitidas',
        limitBlocksSending: 'Limite trava envio',
        proofRequired: 'Recibo obrigatório',
        commentRequired: 'Comentário obrigatório',
        orderBy: {
          ascendingOrder: 'Ordem ascendente',
          descendingOrder: 'Ordem descendente',
        },
        actions: 'Ações',
        actionsOptions: {
          edit: 'Editar limite',
          disable: 'Desativar limite',
          enable: 'Ativar limite',
          delete: 'Excluir limite',
        },
        false: 'Não',
        true: 'Sim',
        criterionOptions: {
          DAY: 'Por dia',
          EXPENSE: 'Por despesa',
          QUANTITY: 'Por quantidade',
          MONTH: 'Por mês',
        },
        status: 'Status',
        active: {
          true: 'Ativo',
          false: 'Inativo',
        },
        selectedItems: '{{selectedItems}} de {{totalItems}} selecionados',
      },
      policiesPreview: {
        title: 'Políticas',
      },
      limitsPreview: {
        add: 'Adicionar limite',
        accordionTitle: 'Limites de gastos',
        accordionDescription: 'Defina as configurações básicas dos relatórios de prestação de contas.',
      },
      approvalsFluxPreview: {
        accordionTitle: 'Fluxo de aprovação',
        accordionDescription: 'Configure como funcionará o fluxo de aprovação de relatórios.',
        approvalModes: {
          manual: {
            title: 'Aprovação manual',
            description:
              'O solicitante seleciona a pessoa responsável por aprovar seu relatório dentre as pessoas com o perfil de aprovador.',
          },
          automatic: {
            title: 'Aprovação automática',
            description:
              'Ao submeter o relatório, ele é enviado automaticamente para o financeiro, sem a necessidade de aprovação de algum aprovador.',
          },
          custom: {
            title: 'Aprovação personalizada',
            description: 'Crie um fluxo personalizado de acordo com as necessidades da sua empresa.',
            actions: {
              reportApproval: 'Aprovação de relatórios',
              advanceApproval: 'Aprovação de adiantamentos',
            },
          },
          tooltip: {
            actionNotAllowed: 'Você não tem permissão para alterar o fluxo de aprovações!',
          },
        },
        feedbacks: {
          errorWhileUpdatingType: 'Ocorreu um erro ao atualizar o tipo de aprovação',
        },
      },
      mileagePreview: {
        title: 'Quilometragem',
        accordionTitle: 'Configurações gerais',
        accordionDescription:
          'Edite as configurações básicas que interferem no lançamento de despesas de quilometragem',
        mileageToggles: {
          originDestination: {
            title: 'Origem-destino',
            description:
              'Permite o lançamento de despesas de quilometragem inserindo o ponto de partida e o ponto de chegada, assim como eventuais paradas.',
          },
          odometer: {
            title: 'Odômetro',
            description:
              'Permite a inserção de quilometragem do veículo no início, e no final do deslocamento. Resultando assim, na distância percorrida.',
          },
          gpsPin: {
            title: 'GPS',
            description: 'Permite o lançamento de despesas através do traqueamento do trajeto por GPS.',
            appOnly: 'Apenas app',
          },
          gps: {
            title: 'Pin GPS',
            description:
              'Permite a inserção do local do usuário via GPS no momento do lançamento da despesa (ponto de partida, de parada ou de chegada).',
          },
          registeredPlaces: {
            title: 'Locais cadastrados',
            description: 'Permite a seleção dos locais que foram cadastrados no lançamento de despesas.',
          },
          multipleAddresses: {
            title: 'Múltiplos endereços (paradas)',
            description:
              'Permite o lançamento de múltiplos endereços (pontos de parada) no lançamento de de despesas de quilometragem.',
          },
          manualEntry: {
            title: 'Entrada manual',
            description: 'Permite a inserção manual de endereços.',
          },
        },
        distanceUnit: {
          title: 'Unidade de distância',
          kilometer: 'Quilômetro',
          miles: 'Milhas',
        },
        mileageRates: {
          accordionTitle: 'Taxa de quilometragem',
          accordionDescription: 'Defina o valor por quilômetro rodado.',
          addKmRate: 'Adicionar taxa de quilometragem',
          startDate: 'Data de início',
          ratePerKm: 'Taxa por quilômetro',
        },
      },
      subscriptionDetails: {
        title: 'Detalhes da contratação',
        valueCaption: 'pessoa/mes',
        plans: {
          basic: 'Gestão de despesas',
          intermediate: 'Gestão completa de despesas',
          advanced: 'Gestão completa de despesas',
        },
        travel: {
          title: 'Serviços de Travel',
        },
        sass: {
          title: 'Software para gestão de despesas ("SaaS")',
          caption: 'Versão plano {{planName}}',
          description: {
            basic: 'O plano Gestão de Despesas é gratuito, não havendo cobrança pelos serviços disponibilizados.',
            intermediate:
              'Confira os detalhes da assinatura do plano gestão e avance na contratação dos nossos serviços.',
            advanced:
              'Confira os detalhes da assinatura do plano Gestão Completa de Despesas e avance na contratação dos nossos serviços.',
          },
        },
        corporateCard: {
          title: 'Cartão Flash Corporativo',
          caption: 'Versão plano {{planName}}',
          infos:
            '* Para clientes que não contrataram Benefícios, são disponibilizados <b>{{freeCardQuantity}} cartões</b> gratuitamente e será cobrada, uma única vez, tarifa de <b>{{additionalValue}}</b> para cada cartão corporativo adicional solicitado.',
        },
        erpIntegration: {
          title: 'Integrações ERP',
          caption: 'Serviço adicional',
        },
        details: {
          title: 'Valor total da assinatura',
          caption: {
            periodOfValidity: 'Prazo de vigência – 12 meses, sem multa de rescisão',
            gracePeriodInMonths: 'Período de carência (Em meses): {{period}} meses',
            discountPeriodInMonths: 'Período do desconto (Em meses): {{period}} meses',
            discountPercentage: 'Desconto aplicado (Em %): {{discount}}%',
            billingTypeDescription:
              'A quantidade mínima de vidas/ usuários, bem como o valor do pacote, foram fechados para o grupo econômico. Sendo assim, serão calculados para o grupo como um todo, somando os usuários ativos em cada CNPJ.',
            billingTypeCompany: 'O faturamento será centralizado na matriz (CNPJ: {{registrationNumber}}).',
          },
        },
      },
      customizationTipsModal: [
        {
          labelButtonAction: 'Me mostre o caminho',
          labelButtonCancel: 'Não, obrigado',
          title: 'Começar é muito mais fácil quando você tem um mapa por onde navegar',
          iconLabel: 'Vamos explorar juntos?',
          subtitle: 'Preparamos algumas dicas rápidas para você dar seus primeiros passos aqui.',
        },
        {
          title: 'Configure as categorias',
          subtitle: 'Defina as categorias de gastos que poderão ser selecionados no lançamento de despesas.',
        },
        {
          title: 'Cadastre limites de gastos',
          subtitle: 'Defina um valor máximo de gastos que o colaborador poderá ter em uma, ou em múltiplas categorias.',
        },
        {
          labelButtonAction: 'Concluir',
          labelButtonCancel: 'Voltar',
          title: 'Comece a divulgar a plataforma para seus colaboradores!',
          iconLabel: 'Pronto!',
          subtitle: 'e envie um convite de acesso à nova plataforma.',
          link: 'Clique aqui',
        },
      ],
      launchMileageExpensePreview: {
        titleNewExpense: 'Nova despesa de quilometragem',
        titleEditExpense: 'Editar despesa de quilometragem',
        tabs: {
          originAndDestiny: 'Origem e destino',
          odometer: 'Odômetro',
          gps: 'GPS',
        },
      },
      waypointFields: {
        fieldLabel: {
          origin: 'Origem',
          stop: 'Parada',
          destination: 'Destino',
        },
        button: {
          open: 'Exibir trajeto resumido',
          close: 'Exibir trajeto completo',
        },
      },
      expensesTipsModal: [
        {
          labelButtonAction: 'Me mostre o caminho',
          labelButtonCancel: 'Não, obrigado',
          title: 'Prestar contas de despesas ficou muito mais fácil!',
          iconLabel: 'Vamos explorar juntos?',
          subtitle: 'Preparamos algumas dicas rápidas para você dar seus primeiros passos aqui.',
        },
        {
          title: 'Adicione uma despesa',
          subtitle:
            'Crie uma nova despesa e selecione o relatório que ela pertencerá. Se necessário, crie um novo relatório.',
        },
        {
          title: 'Preencha as informações',
          subtitle: 'Vincule o recibo à despesa e selecione como deseja preencher os campos.',
        },
        {
          title: 'Envie o relatório à aprovação',
          subtitle: 'Envie o relatório com a despesa criada para aprovação do aprovador.',
        },
        {
          labelButtonAction: 'Concluir',
          labelButtonCancel: 'Voltar',
          title: 'Agora você está pronto para lançar suas despesas!',
          iconLabel: 'Tudo certo!',
          subtitle: 'Se ainda tiver dúvidas, consulte nossa',
          link: 'Central de Ajuda Flash',
        },
      ],
      approvals: {
        tabs: {
          reports: 'Relatórios',
          advances: 'Adiantamentos',
          companyPayables: 'Despesas com fornecedores',
        },
        filters: {
          label: {
            isPendingApproval: 'Pendente',
            requester: 'Solicitante',
            startDate: 'Data de início',
            status: 'Status',
            submissionDate: 'Data de envio',
            reportCreationDate: 'Data de criação',
            search: 'Buscar relatório por nome ou referência',
          },
        },
      },
      confirmReportApprovalModal: {
        attention: 'Atenção!',
        conclude: 'Confirmar',
        pluralForm: {
          popupTitle: 'Tem certeza que deseja aprovar os {{reportsSelected}} relatórios selecionados?',
          popupSubtitle: 'Os relatórios serão encaminhados para o fluxo de aprovação do financeiro.',
        },
        singularForm: {
          popupTitle: 'Tem certeza que deseja aprovar o relatório?',
          popupSubtitle: 'O relatório será encaminhado para o fluxo de aprovação do financeiro.',
        },
      },
      approvalReportsPreview: {
        pluralForm: {
          approvedSuccessMessage: 'Relatórios aprovados ​​com sucesso!',
          disapprovedSuccessMessage: 'Relatórios reprovados ​​com sucesso!',
        },
        singularForm: {
          approvedSuccessMessage: 'Relatório aprovado ​​com sucesso!',
          disapprovedSuccessMessage: 'Relatório reprovado ​​com sucesso!',
        },
      },
      ApprovalsReportGrid: {
        report: 'Relatório',
        actionsOptions: {
          view: 'Visualizar',
          exportPdf: 'Exportar PDF',
          exportPdfByCategory: 'Exportar PDF por categoria',
          approve: 'Aprovar',
          reprove: 'Reprovar',
          tooltip: {
            approve: 'Somente relatórios com status "Submetido" podem ser aprovados',
            reprove: 'Somente relatórios com status "Submetido" podem ser reprovados',
            exportPdf: '',
            exportPdfByCategory: '',
            exportExcel: 'Para realizar a exportação para excel é necessário filtrar um período de início',
          },
          feedbacks: {
            export: {
              pluralForm: {
                error: 'Ocorreu um erro ao exportar os relatórios',
              },
              singularForm: {
                error: 'Ocorreu um erro ao exportar o relatório',
              },
            },
          },
        },
        statusOptions: {
          open: 'Abertos',
          reimbursed: 'Reembolsados',
          submitted: 'Submetidos',
          reproved: 'Reprovados',
          approved: 'Aprovados',
        },
        pendingOptions: {
          true: 'Sua aprovação',
          false: 'Qualquer aprovação',
        },
        selectedItems: '{{selectedItems}} de {{totalItems}} selecionados',
      },
    },
    delegation: {
      configuration: {
        title: 'Delegação de ações',
        descriptionFieldPeople: 'Selecione a pessoa alvo da delegação',
        labelPeople: 'Pessoa',
        toDelegate: 'Delegar',
        expenseDelegation: {
          title: 'Delegação de prestação de conta de despesas corporativas',
          caption: 'Usuários com permissão para prestar conta pelo usuário selecionado.',
        },
        approvalDelegation: {
          title: 'Delegação de aprovação de relatórios',
          caption: 'Usuários com permissão para aprovar relatórios pela pessoa selecionada.',
          legacy: {
            title: 'Delegação de aprovação de relatórios e adiantamentos',
            caption: 'Usuários com permissão para aprovar relatórios e adiantamentos pela pessoa selecionada.',
          },
        },
        grid: {
          header: {
            person: 'Pessoa',
            startDate: 'Data de início',
            endDate: 'Data final',
            status: 'Status',
            actions: 'Ações',
            delete: 'Deletar',
            reason: 'Motivo',
            disable: 'Desativar',
            tooltips: {
              disable: 'Somente status "Ativo" podem ser alterados.',
            },
          },
          body: {
            active: 'Ativo',
            inactive: 'Inativo',
            selectUser: 'Selecione para quem vc deseja delegar!',
            emptyData: 'O usuário selecionado não tem pessoas com permissão para delegação!',
          },
        },
        tooltip: {
          buttonAddSelectUser: 'Selecione para quem vc deseja delegar.',
        },
        add: {
          cancel: 'Cancelar',
          add: 'Adicionar',
          requiredField: 'Campo obrigatório',
          allowedActions: 'Ações permitidas',
          fields: {
            person: 'Pessoa',
            duration: 'Duração',
            shortPeriod: 'Período curto',
            noDeadline: 'Sem prazo final',
            startDate: 'Data inicial',
            endDate: 'Data final',
            reason: 'Motivo',
          },
          expense: {
            title: 'Delegar prestador de contas',
            caption: 'Selecione o usuário que terá acesso para prestar contas em seu nome.',
            allowedActions: {
              createAndSend: 'Criar e enviar relatórios de despesas para aprovação',
              accessCards: 'Acessar os cartões corporativos e prestações de conta',
              editAndLink: 'Editar e vincular relatórios às despesas de cartão corporativo',
              accessReports: 'Acessar relatório',
            },
          },
          approval: {
            title: 'Delegar aprovador',
            caption:
              'Escolha o usuário que terá acesso para aprovar relatórios e adiantamentos no lugar da pessoa selecionada.',
            allowedActions: {
              approveAndReproveReports: 'Aprovar e reprovar relatórios submetidos à aprovação',
              approveAndReproveRequests: 'Aprovar e reprovar solicitações de adiantamentos',
            },
          },
        },
        feedbacks: {
          attention: 'Atenção!',
          disableModal: {
            title: {
              plural: 'Tem certeza que deseja desativar essas pessoas da delegação?',
              singular: 'Tem certeza que deseja desativar essa pessoa da delegação?',
            },
            description: {
              plural: 'Ao desativar, essas pessoas não poderão aprovar em seu nome.',
              singular: 'Ao desativar, essa pessoa não poderá aprovar em seu nome.',
            },
            confirm: {
              plural: 'Confirmar desativações',
              singular: 'Confirmar desativação',
            },
          },
          deleteModal: {
            title: {
              plural: 'Tem certeza que deseja excluir essas pessoas da delegação?',
              singular: 'Tem certeza que deseja excluir essa pessoa da delegação?',
            },
            description: {
              plural:
                'Ao excluir, todos os dados relacionados a eles serão perdidos, e essas pessoas não poderão aprovar em seu nome.',
              singular:
                'Ao excluir, todos os dados relacionados a ele serão perdidos, e essa pessoa não poderá aprovar em seu nome.',
            },
            confirm: {
              plural: 'Confirmar exclusões',
              singular: 'Confirmar exclusão',
            },
          },
          create: {
            success: {
              approval: 'Delegado de aprovações adicionado com sucesso!',
              expense: 'Pessoa para delegado de prestação de contas adicionado com sucesso!',
            },
            error: {
              approval: 'Não foi possível adicionar a pessoa {{ userName }} para delegação.',
              expense: 'Não foi possível adicionar a pessoa {{ userName }} para delegação',
            },
          },
        },
      },
    },
    approversDrawer: {
      title: 'Aprovadores do relatório',
      caption: 'Confira os aprovadores e seus status em relação ao relatório.',
      approved: 'Aprovado',
      disapproved: 'Reprovado',
      pendingApproval: 'Aprovação pendente',
    },
    report: {
      reportDetails: 'Detalhes do relatório',
      totalExpenses: 'Despesas totais',
      reimbursableValue: 'Valor a receber',
      approver: 'Aprovador',
      approvers: 'Aprovadores',
      dateLimit: 'Data de vencimento',
      toRecall: 'Recolher',
      seeDetails: 'Ver detalhes',
      finality: 'Finalidade',
      reimbursableExpenses: 'Despesas reembolsáveis',
      nonRefundableExpenses: 'Despesas não reembolsáveis',
      advances: 'Adiantamentos',
      withdrawals: 'Saques',
      period: 'Período',
      to: 'até',
      historic: 'Histórico',
      advance: 'Adiantamentos',
      expenses: 'Despesas',
      unnamedReport: 'Relatório sem nome',
      reportName: 'Nome do Relatório',
      newExpense: 'Adicionar despesa',
      linkExpense: 'Vincular despesa',
      uninformed: 'Não informado',
      requester: 'Solicitante',
      withdraw: 'Saques',
      reprovalMessageTitle: 'Motivo da reprovação',
      status: {
        open: 'Aberto',
        submitted: 'Submetido',
        approved: 'Aprovado',
        reproved: 'Reprovado',
        reimbursed: 'Reembolsado',
        awaitingFinancialProcessing: 'Aguardando processamento',
        processingPayment: 'Processando pagamento',
        processingPaymentError: 'Processando Erro de pagamento',
        undefined: 'Status não informado',
      },
      reportHistoryGrid: {
        date: 'Data',
        user: 'Usuário',
        actions: 'Ações',
        title: 'Histórico',
      },
      filters: {
        label: {
          createdBy: 'Solicitante',
          reportCreationDate: 'Criado em',
          reportEndDate: 'Data final',
          reportStartDate: 'Período',
          search: 'Buscar relatório por nome ou referência',
          status: 'Status',
          moreFilters: 'Mais filtros',
          periodDate: 'Período',
          awaitingImmediateApproverDecision: 'Pendente',
        },
      },
      actions: {
        exportToExcel: 'Exportar para excel',
      },
    },
    withdraw: {
      feedbacks: {
        attach: {
          success: {
            plural: 'Saque vinculado com sucesso!',
            singular: 'Saques vinculados com sucesso!',
          },
          error: 'Ocorreu um erro ao vincular saque.',
        },
        detach: {
          success: {
            plural: 'Saque desvinculado com sucesso!',
            singular: 'Saques desvinculados com sucesso!',
          },
          error: 'Ocorreu um erro ao desvincular saque.',
        },
        tooltips: {
          notDetach: {
            plural: 'Saque só poderá ser desvinculado em relatórios com status ABERTO.',
            singular: 'Saques só poderão ser desvinculados em relatórios com status ABERTO.',
          },
        },
      },
      grid: {
        header: {
          columns: {
            card: 'Cartão',
            value: 'Valor',
            date: 'Data',
            actions: 'Ações',
          },
        },
        body: {
          actions: {
            link: 'Vincular saque',
            unlink: 'Desvincular saque',
          },
        },
        tooltips: {
          notDetach: {
            plural: 'Saques só poderão ser desvinculados em relatórios com status ABERTO.',
            singular: 'Saque só poderá ser desvinculado em relatórios com status ABERTO.',
          },
        },
      },
      attachWithdrawModal: {
        title: 'Vincular saque ao relatório',
        subtitle: 'Selecione os saques que deseja vincular ao relatório.',
        add: 'Adicionar',
        empty: 'nenhum saque disponível para vinculo!',
      },
      modal: {
        detachConfirmation: {
          iconDescription: 'Atenção!',
          title: {
            plural: 'Tem certeza que deseja desvincular os saques selecionados?',
            singular: 'Tem certeza que deseja desvincular esse saque?',
          },
          message: {
            plural: 'Os saques voltarão para a listagem, e será necessário vinculá-los a um relatório novamente.',
            singular: 'O saque voltará para a listagem, e será necessário vinculá-lo a um relatório novamente.',
          },
          actions: {
            confirm: {
              plural: 'Confirmar desvínculos',
              singular: 'Confirmar desvinculo',
            },
          },
        },
      },
    },
    analytics: {
      actions: {
        exportCSV: 'Exportar CSV',
        exportPDF: 'Exportar PDF',
        exportXLSX: 'Exportar XLSX',
        loading: 'Por favor, aguarde o carregamento das informações para realizar a exportação!',
        seeDetails: 'Ver detalhes',
        actions: 'Ações',
      },
      sections: {
        expenses: {
          title: 'Despesas',
          caption: 'Gere relatórios completos para analisar os gastos corporativos da sua organização.',
        },
        reports: {
          title: 'Relatórios',
          caption: 'Analise os relatórios de prestação de contas.',
        },
        advances: {
          title: 'Adiantamentos',
          caption: 'Analise os relatórios relacionados às solicitações de adiantamento.',
        },
        logs: {
          title: 'Logs',
          caption: 'Analise os logs de mudanças realizadas na plataforma.',
        },
      },
      filters: {
        label: {
          expenseDate: 'Data da despesa',
          expenseCategory: 'Categoria',
          expensePaymentMethod: 'Forma de pagamento',
          expenseCorporateCardNumber: 'Nº cartão corporativo',
          user: 'Nome solicitante',
          expenseSubsidiary: 'Filial',
          expenseArea: 'Área',
          expenseHasViolations: 'Identificador de despesa fora da política',
          expensePosition: 'Posição',
          expenseCosteCenter: 'Centro de custos',
          expenseClient: 'Cliente',
          expenseProject: 'Projeto',
          createdDate: 'Data de criação',
          value: 'Valor',
          reimbursableValue: 'Valor reembolsável',
          report: 'Relatório',
          reportLastApprover: 'Último aprovador',
          reportStatus: 'Status do relatório',
          reportCreationDate: 'Data criação do relatório',
          reportApprovalDate: 'Data da aprovação',
          reportPaymentSubmitDate: 'Data envio para pagamento',
          reportPaymentDate: 'Data do Pagamento',
          reportCurrentApprover: 'Aprovador atual',
          advanceStatus: 'Status do adiantamento',
          advanceCreationDate: 'Data de criação adiantamento',
          logDate: 'Data',
          logAction: 'Ação',
          logParameter: 'Parâmetro',
          logUser: 'Usuário que fez a ação',
          logApprovalFlow: 'Fluxo de aprovação',
          logArea: 'Área',
          logCostCenter: 'Centro de custo',
          logProject: 'Projeto',
          logPolicy: 'Política',
          logUserTarget: 'Usuário que recebeu a ação',
        },
      },
      advancesOverview: {
        title: 'Visão geral',
        fileName: 'relatorio-de-adiantamentos',
      },
      advancesUsers: {
        title: 'Status por solicitante',
        fileName: 'relatorio-de-adiantamentos-status-por-solicitante',
      },
      expenseOverview: {
        title: 'Visão geral',
        fileName: 'relatorio-de-despesas',
        columns: {
          date: 'Data',
          id: 'ID',
          requesterName: 'Solicitante',
          category: 'Categoria',
          currency: 'Moeda',
          value: 'Valor',
          receipt: 'Recibo',
          reportCode: 'Código relatório',
          reportStatus: 'Status relatório',
        },
      },
      expenseCategories: {
        title: 'Despesas por categoria',
        fileName: 'relatorio-por-categoria',
        columns: {
          description: 'Categoria',
          quantity: 'Quantidade',
          value: 'Valor',
        },
      },
      expenseRequester: {
        title: 'Despesas por solicitante',
        fileName: 'relatorio-de-despesas-por-solicitante',
        columns: {
          requesterName: 'Nome do solicitante',
          quantity: 'Quantidade',
          value: 'Valor',
        },
      },
      reportOverview: {
        title: 'Visão geral',
        fileName: 'relatorios-visao-geral',
        columns: {
          reportName: 'Nome do relatório',
          id: 'ID',
          createAt: 'Data de criação',
          reimbursableValue: 'Valor reembolsável',
          currency: 'Moeda',
          value: 'Valor',
          status: 'Status',
          user: 'Solicitante',
          approver: 'Último aprovador',
        },
      },
      reportStatusByRequester: {
        title: 'Status por solicitante',
        fileName: 'relatorio-status-por-solicitante',
        columns: {
          requesterName: 'Nome do solicitante',
          status: 'Status',
          quantity: 'Quantidade',
          value: 'Valor',
          reimbusableValue: 'Valor Reembolsável',
        },
      },
      reportsPendingApprovalsByUsers: {
        title: 'Aprovações pendentes por aprovador',
        fileName: 'relatório-aprovacoes-pendetes-por-aprovador',
        columns: {
          approverOfTheTime: 'Aprovador da vez',
          quantityOfReports: 'Quantidade de relatórios',
          currency: 'Moeda',
          value: 'Valor',
          reimbusableValue: 'Valor reembolsável',
          createdAt: 'Data de criação',
        },
      },
      expenseCorporateCardOverview: {
        title: 'Despesas de cartão corporativo',
        fileName: 'visao-geral-despesas-cartao-coporativo',
        columns: {
          expenseDate: 'Data',
          expenseId: 'ID',
          requesterName: 'Nome solicitante',
          cardNumber: 'Nº cartão corporativo',
          categoryName: 'Nome categoria',
          currency: 'Moeda',
          value: 'Valor',
          receipt: 'Recibo',
          reportCode: 'Código relatório',
          statusReport: 'Status relatório',
        },
      },
      expenseCorporateCardPendenciesPerUser: {
        title: 'Pendentes por solicitante',
        fileName: 'despesas-coporativas-pendentes-por-solicitante',
        columns: {
          requesterName: 'Nome solicitante',
          cardNumber: 'Nº cartão corporativo ',
          pendenciesNumber: 'Nº despesas pendentes',
          value: 'Valor',
        },
      },
      corporateCardTransactions: {
        title: 'Transações',
        modal: {
          title: 'Fazer download do relatório',
          subtitle: 'Relatório de transações',
          dateRangeInputLabel: 'Período de lançamento',
          dateRangeLabel: 'Selecione o período',
        },
      },
      corporateCardActiveUsers: {
        title: 'Usuários ativos',
        modal: {
          title: 'Fazer download do relatório',
          subtitle: 'Relatório de usuários ativos',
          dateRangeInputLabel: 'Período de lançamento',
          dateRangeLabel: 'Selecione o período',
        },
      },
      corporateCardFlashCashStatement: {
        title: 'Extrato Flash Cash',
        modal: {
          title: 'Fazer download do relatório',
          subtitle: 'Relatório de extrato Flash Cash',
          dateRangeInputLabel: 'Período de lançamento',
          dateRangeLabel: 'Selecione o período',
        },
      },
      corporateCardMonthlyFlashCash: {
        title: 'Extrato Flash Cash Mensal',
        modal: {
          title: 'Fazer download do relatório',
          subtitle: 'Relatório de Flash Cash mensal',
          dateRangeInputLabel: 'Período de lançamento',
          dateRangeLabel: 'Selecione o período',
        },
      },
      logs: {
        approval: {
          title: 'Aprovações',
        },
        area: {
          title: 'Área',
        },
        costCenter: {
          title: 'Centro de custo',
        },
        project: {
          title: 'Projeto',
        },
        policy: {
          title: 'Política',
        },
        user: {
          title: 'Usuário',
        },
        modal: {
          logDetails: 'Detalhes do log',
          userIP: 'IP do usuário',
          feature: 'Funcionalidade',
          viaAttendance: 'Via atendente Flash Expense',
          changedData: 'Dado alterado',
          newValue: 'Novo valor',
          oldValue: 'Valor antigo',
        },
        common: {
          yes: 'Sim',
          no: 'Não',
          previousValue: 'Valor anterior',
          newValue: 'Novo valor',
        },
      },
      toasts: {
        error: {
          title: 'Erro ao gerar relatório',
          message: 'Tente novamente mais tarde. Se o problema persistir, entre em contato com o suporte.',
        },
        success: {
          title: 'Relatório gerado com sucesso',
          message: 'O arquivo foi salvo em seu computador',
        },
      },
      customizeReport: {
        availableFields: 'Campos disponíveis',
        selectedFields: 'Campos selecionados',
        applyAndSave: 'Salvar e aplicar',
        apply: 'Aplicar',
        cancel: 'Cancelar',
        customizeReport: 'Personalizar relatório',
        fields: 'Campos',
        tooltip: 'Ao menos um campo deve ser selecionado.',
        tooltipButtonTransfer: 'Selecione 1 ou mais campos para movimentar.',
        feedback: {
          success: 'Customização do relatório salva com sucesso!',
          error: 'Occorreu um erro ao salvar a customização do relatório.',
        },
      },
    },
    clients: {
      page: {
        title: 'Clientes',
        caption: 'Gerencie a carteira de clientes da sua empresa que contará com alocação de despesas corporativas.',
        breadcrumb: {
          clients: 'Clientes',
          manageCompany: 'Gerenciar empresa',
        },
      },
      clientsPreview: {
        clientsCounter: {
          label: 'Clientes cadastrados',
          items: {
            plural: '{{items}} itens',
            singular: '{{items}} item',
          },
        },
      },
      clientsGridPreview: {
        items: {
          plural: 'itens',
          singular: 'item',
        },
        title: 'Clientes cadastrados',
        search: 'Busca por cliente',
      },
      addClient: {
        buttonLabel: 'Adicionar Cliente',
        options: {
          addClientManually: 'Adicionar cliente manualmente',
          addClientByFile: 'Adicionar cliente por arquivo',
        },
      },
      grid: {
        searchFieldLabel: 'Buscar por cliente',
        active: 'Ativo',
        inactive: 'Inativo',
        uninformedClient: 'Nome não informado',
        uninformedEmailClient: 'E-mail não informado',
        header: {
          columns: {
            client: 'Cliente',
            phone: 'Telefone',
            status: 'Status',
            actions: 'Ações',
            uninformedEmailClient: 'E-mail não informado',
          },
          batchActions: {
            deactivate: 'Desativar',
            activate: 'Ativar',
            delete: 'Deletar',
            tooltips: {
              active: 'Selecione clientes ativos',
            },
          },
        },
        body: {
          actions: {
            edit: 'Editar cliente',
            active: 'Ativar cliente',
            deactivate: 'Desativar cliente',
            delete: 'Excluir cliente',
          },
        },
      },
      registerClient: {
        title: 'Dados gerais',
        subTitle: 'Insira os dados básicos do cliente.',
        assignUsersDescription:
          'Como padrão todas as pessoas poderão lançar despesas ao cliente, se desejar, selecione pessoas específicas.',
        required: 'campo obrigatório',
        breadcrumbs: {
          clients: 'Clientes',
          manageCompany: 'Gerenciar empresa',
          registerClient: 'Cadastrar cliente',
        },
        assignUsersGrid: {
          allPeople: 'Todas as pessoas ({{selected}})',
          selectedForClient: 'Selecionadas para lançar nesse cliente ({{selected}})',
          all: 'Todas ({{selected}})',
          selected: 'Selecionadas ({{selected}})',
          header: {
            name: 'Nome',
          },
        },
        steps: {
          header: {
            generalData: 'Dados gerais',
            users: 'Atribuir usuários',
          },
          pageContent: [
            {
              title: 'Dados gerais',
              sectionTitle: 'Dados gerais',
              subTitle: 'Insira os dados básicos do cliente.',
            },
            {
              title: 'Atribuir pessoas',
              sectionTitle: 'Selecione as pessoas',
              subTitle: 'Escolha quais pessoas poderão lançar despesas a esse projeto.',
            },
          ],
        },
      },
      registerClientForm: {
        name: 'Nome do cliente',
        phoneNumber: 'Telefone do cliente',
        email: 'E-mail do cliente',
        website: 'Site do cliente',
        externalCode: 'Código',
        externalCodeHelper: 'Insira o código utilizado pela sua empresa para melhor controle.',
        active: 'Ativar cliente',
        activeDescription: 'Quando ativo, o cliente aparece como uma opção ao lançar uma despesa.',
        error: {
          fieldRequired: '{{field}} deve ser preenchido',
        },
      },
      clientsGrid: {
        client: 'Cliente',
        phone: 'Telefone',
        status: 'Status',
        actions: 'Ações',
        active: {
          true: 'Ativo',
          false: 'Inativo',
        },
        actionsGrid: {
          singularForm: {
            edit: 'Editar',
            delete: 'Excluir',
            activate: 'Ativar',
            deactivate: 'Desativar',
          },
          pluralForm: {
            delete: 'Excluir',
            activate: 'Ativar',
            deactivate: 'Desativar',
          },
        },
        selectedItems: '{{selectedItems}} de {{totalItems}} selecionados',
      },
      modal: {
        exclusion: {
          iconDescription: 'Atenção!',
          title: {
            plural: 'Tem certeza que deseja excluir os clientes selecionados?',
            singular: 'Tem certeza que deseja excluir esse cliente?',
          },
          message: {
            plural: 'Todos os dados relacionados a eles serão perdidos e essa ação não poderá ser desfeita.',
            singular: 'Todos os dados relacionados a ele serão perdidos e essa ação não poderá ser desfeita.',
          },
          actions: {
            confirm: {
              plural: 'Confirmar exclusões',
              singular: 'Confirmar exclusão',
            },
          },
        },
        deactivation: {
          iconDescription: 'Atenção!',
          title: {
            plural: 'Tem certeza que deseja desativar os clientes selecionados?',
            singular: 'Tem certeza que deseja desativar esse cliente?',
          },
          message: {
            plural:
              'Ao desativá-los, os clientes deixarão de aparecer na listagem de clientes no lançamento de despesas.',
            singular:
              'Ao desativá-lo, o cliente deixará de aparecer na listagem de clientes no lançamento de despesas.',
          },
          actions: {
            confirm: {
              plural: 'Confirmar desativações',
              singular: 'Confirmar desativação',
            },
          },
        },
      },
    },
    subsidiaries: {
      page: {
        title: 'Unidades de negócio',
        caption: 'Cadastre as unidades de negócio aos quais os colaboradores estão vinculados.',
        breadcrumb: {
          manageCompany: 'Gerenciar empresa',
          subsidiaries: 'Unidades de negócios',
          add: 'Adicionar unidade de negócios',
          edit: 'Editar unidade de negócios',
        },
      },
      subsidiariesPreview: {
        subsidiariesCounter: {
          label: 'Unidades de negócio',
          items: {
            plural: '{{ items }} itens',
            singular: '{{ items }} item',
          },
        },
      },
      addSubsidiaries: {
        buttonLabel: 'Adicionar unidade de negócio',
      },
      grid: {
        searchFieldLabel: 'Buscar por unidade de negócio',
        active: 'Ativo',
        inactive: 'Inativo',
        header: {
          columns: {
            subsidiary: 'Unidade de negócio',
            uf: 'UF',
            cnpj: 'CNPJ',
            status: 'Status',
            actions: 'Ações',
          },
          batchActions: {
            deactivate: 'Desativar',
            activate: 'Ativar',
            delete: 'Deletar',
            tooltips: {
              active: 'Selecione unidades de negócio ativas',
            },
          },
        },
        body: {
          actions: {
            edit: 'Editar unidades de negócio',
            active: 'Ativar unidades de negócio',
            deactivate: 'Desativar unidade de negócio',
            delete: 'Excluir unidade de negócio',
          },
        },
      },
      modal: {
        exclusion: {
          iconDescription: 'Atenção!',
          title: {
            plural: 'Tem certeza que deseja excluir as unidades de negócio selecionados?',
            singular: 'Tem certeza que deseja excluir essa unidade de negócio?',
          },
          message: {
            plural: 'Todos os dados relacionados a eles serão perdidos e essa ação não poderá ser desfeita.',
            singular: 'Todos os dados relacionados a ele serão perdidos e essa ação não poderá ser desfeita.',
          },
          actions: {
            confirm: {
              plural: 'Confirmar exclusões',
              singular: 'Confirmar exclusão',
            },
          },
          feedbacks: {
            success: {
              title: 'Unidade de negócio {{name}}',
              message: 'deletada com sucesso',
            },
            error: {
              title: 'Erro ao tentar deletar',
              message: 'unidade de negócio {{name}}',
            },
          },
        },
        deactivation: {
          iconDescription: 'Atenção!',
          title: {
            plural: 'Tem certeza que deseja desativar as unidades de negócio selecionadas?',
            singular: 'Tem certeza que deseja desativar essa unidade de negócio?',
          },
          message: {
            plural:
              'Ao desativá-las, as unidades de negócio não estarão mais disponíveis para vínculo a um colaborador',
            singular: 'Ao desativá-la, a unidade de negócio não estará mais disponível para vínculo a um colaborador.',
          },
          actions: {
            confirm: {
              plural: 'Confirmar desativações',
              singular: 'Confirmar desativação',
            },
          },
        },
      },
      feedbacks: {
        error: 'Ocorreu um erro ao tentar obter unidades de negócio',
      },
    },
    projects: {
      page: {
        title: 'Projetos',
        caption: 'Crie os projetos em que as despesas serão lançadas.',
        breadcrumb: {
          manageCompany: 'Gerenciar empresa',
        },
      },
      registerProject: {
        steps: {
          header: {
            generalData: 'Dados gerais',
            users: 'Atribuir usuários',
          },
          pageContent: [
            {
              title: 'Dados gerais',
              sectionTitle: 'Dados gerais',
              subTitle: 'Insira os dados básicos do projeto.',
            },
            {
              title: 'Atribuir pessoas',
              sectionTitle: 'Selecione as pessoas',
              subTitle: 'Escolha quais pessoas poderão lançar despesas a esse projeto.',
            },
          ],
        },
      },
      projectsPreview: {
        projectsCounter: {
          label: 'Projetos',
          items: {
            plural: '{{ items }} itens',
            singular: '{{ items }} item',
          },
        },
      },
      addProjects: {
        buttonLabel: 'Adicionar projeto',
        options: {
          addProjectManually: 'Adicionar projeto manualmente',
          addProjectByFile: 'Adicionar projeto por arquivo',
        },
      },
      grid: {
        searchFieldLabel: 'Buscar por projeto',
        active: 'Ativo',
        inactive: 'Inativo',
        header: {
          columns: {
            projects: 'Projetos',
            clients: 'Clientes',
            accountable: 'Responsável',
            period: 'Período',
            status: 'Status',
            actions: 'Ações',
          },
          batchActions: {
            deactivate: 'Desativar',
            activate: 'Ativar',
            delete: 'Deletar',
            tooltips: {
              active: 'Selecione projetos ativos',
            },
          },
        },
        body: {
          actions: {
            edit: 'Editar projeto',
            active: 'Ativar projeto',
            deactivate: 'Desativar projeto',
            delete: 'Excluir projeto',
          },
        },
      },
      modal: {
        exclusion: {
          iconDescription: 'Atenção!',
          title: {
            plural: 'Tem certeza que deseja excluir os projetos selecionados?',
            singular: 'Tem certeza que deseja excluir esse projeto?',
          },
          message: {
            plural: 'Todos os dados relacionados a eles serão perdidos e essa ação não poderá ser desfeita.',
            singular: 'Todos os dados relacionados a ele serão perdidos e essa ação não poderá ser desfeita.',
          },
          actions: {
            confirm: {
              plural: 'Confirmar exclusões',
              singular: 'Confirmar exclusão',
            },
          },
        },
        deactivation: {
          iconDescription: 'Atenção!',
          title: {
            plural: 'Tem certeza que deseja desativar os projetos selecionados?',
            singular: 'Tem certeza que deseja desativar esse projeto?',
          },
          message: {
            plural:
              'Ao desativá-los, os projetos deixarão de aparecer na listagem de projetos no lançamento de despesas.',
            singular:
              'Ao desativá-lo, o projeto deixará de aparecer na listagem de projetos no lançamento de despesas.',
          },
          actions: {
            confirm: {
              plural: 'Confirmar desativações',
              singular: 'Confirmar desativação',
            },
          },
        },
      },
    },
    approvalFlows: {
      pageGrid: {
        title: {
          reports: 'Fluxo de aprovação de relatórios',
          advances: 'Fluxo de aprovação de adiantamentos',
        },
        addButton: 'Adicionar fluxo',
        breadcrumb: {
          approvals: 'Aprovações',
          manageApprovals: 'Gerenciar aprovações',
        },
      },
      pageRegister: {
        title: {
          edit: 'Editar fluxo de aprovação',
          create: 'Adicionar fluxo de aprovação',
        },
        progress: {
          basicInformation: 'Informações básicas',
          approvalType: 'Tipo de aprovação',
        },
        breadcrumb: {
          approvals: 'Aprovações',
          manageApprovals: 'Gerenciar aprovações',
          report: 'Fluxo de aprovação de relatórios',
          advance: 'Fluxo de aprovação de adiantamentos',
          create: 'Adicionar fluxo de aprovação',
          edit: 'Editar fluxo de aprovação',
        },
        infos: {
          basicInformation: {
            title: 'Informações básicas',
            description:
              'Edite as informações básicas do fluxo de aprovação, e quais serão os critérios para ativação.',
          },
          approvalType: {
            title: 'Tipo de aprovação',
            description: 'Selecione de que forma acontecerá a aprovação pelos aprovadores.',
            important: {
              title: 'Importante:',
              item1: {
                advance:
                  'Quando o solicitante também for o aprovador do adiantamento, a aprovação é realizada de forma automática, seguindo para os demais aprovadores se houver.',
                report:
                  'Quando o solicitante também for o aprovador do relatório, a aprovação é realizada de forma automática, seguindo para os demais aprovadores se houver.',
              },
              item2: 'Se um aprovador estiver envolvido em várias etapas do fluxo ele só precisará aprovar uma vez.',
              item3: {
                advance:
                  'Caso o adiantamento atenda o critério de 2 fluxos de aprovação, ele irá requisitar apenas o primeiro nível de aprovação.',
                report:
                  'Caso o relatório atenda o critério de 2 fluxos de aprovação, ele irá requisitar apenas o primeiro nível de aprovação.',
              },
            },
          },
        },
        buttons: {
          cancel: 'Cancelar',
          continue: 'Continuar',
          save: 'Salvar',
          back: 'Voltar',
        },
        loader: {
          edit: 'Salvando alterações...',
          create: 'Criando fluxo...',
        },
      },
      reorderingModal: {
        title: 'Reordenar fluxos',
        caption:
          'Arraste as linhas e reordene os fluxos de acordo com a prioridade desejada. Você também pode alterar diretamente na listagem da tela inicial.',
        cancel: 'Cancelar',
        save: 'Salvar',
      },
      grid: {
        searchFieldLabel: 'Buscar por fluxo de aprovação',
        active: 'Ativo',
        inactive: 'Inativo',
        header: {
          columns: {
            priority: 'Prioridade',
            name: 'Nome do fluxo',
            approvalType: 'Tipo de aprovação',
            status: 'Status',
            description: 'Descrição',
            actions: 'Ações',
          },
          batchActions: {
            deactivate: 'Desativar',
            activate: 'Ativar',
            delete: 'Excluir',
            tooltips: {
              active: 'Selecione fluxos de aprovação ativos.',
            },
          },
        },
        body: {
          actions: {
            edit: 'Editar fluxo',
            deactivate: 'Desativar fluxo',
            active: 'Ativar fluxo',
            delete: 'Excluir fluxo',
            duplicate: 'Duplicar fluxo',
          },
          approvalType: {
            manual: 'Manual',
            automaticApproval: 'Aprovação automática',
            automaticRefusal: 'Reprovação automática',
          },
        },
      },
      basicInformation: {
        title: 'Informações básicas',
        link: 'Reordenar fluxos',
        fields: {
          name: {
            label: 'Nome do fluxo',
            mandatoryInfo: 'O campo nome do fluxo é de preenchimento obrigatório!',
            charactersInfo: 'Nome deve conter entre 4 e 60 caracteres!',
          },
          priority: {
            label: 'Prioridade',
            helperText: 'Caso deseje criar um fluxo com alguma prioridade já utilizada, reordene os fluxos.',
          },
          description: {
            label: 'Descrição do fluxo',
            mandatoryInfo: 'O campo descrição do fluxo é de preenchimento obrigatório!',
            charactersInfo: 'Descrição deve conter entre 4 e 250 caracteres!',
          },
          mandatoryInfo: 'Campo obrigatório',
        },
      },
      approvalCriteria: {
        title: 'Critérios de aprovação',
        caption: 'Insira condicionais que quando atingidas, acionarão o fluxo de aprovação.',
        link: 'Adicionar condicional',
        tooltipLink: 'Preencha todos os campos de condicional para adicionar novos!',
        fields: {
          labelMain: 'Quando',
          field: {
            label: 'Condicional',
            mandatoryInfo: 'Campo obrigatório!',
            tooltip: 'Preencha o condicional anterior.',
          },
        },
        options: {
          statement: {
            and: 'E',
            or: 'Ou',
          },
          criterion: {
            area: 'Área',
            jobPosition: 'Cargo',
            subsidiary: 'Filial',
            costCenterResponsible: 'Resp. Centro de custo',
            responsibleForTheProject: 'Responsável pelo projeto',
            category: 'Categoria',
            totalReportValue: 'Valor total do relatório',
            totalAdvanceValue: 'Valor do adiantamento',
            totalViolations: 'Total de violações',
          },
          operator: {
            equals: 'é igual',
            greaterThan: 'é maior que',
            greaterThanOrEqual: 'é maior ou igual que',
            notEqual: 'é diferente',
            lesserThan: 'é menor que',
            lesserThanOrEqual: 'é menor ou igual que',
          },
        },
      },
      approvalSources: {
        automaticApproval: {
          title: 'Aprovação automática',
          caption: {
            advance:
              'Ao submeter o adiantamento, ele é enviado automaticamente para o financeiro, sem a necessidade de aprovação de algum aprovador.',
            report:
              'Ao submeter o relatório, ele é enviado automaticamente para o financeiro, sem a necessidade de aprovação de algum aprovador.',
          },
        },
        automaticDisapproval: {
          title: 'Reprovação automática',
          caption: {
            advance:
              'Cumprindo com os critérios estabelecidos na etapa anterior, o adiantamento é reprovado automaticamente.',
            report:
              'Cumprindo com os critérios estabelecidos na etapa anterior, o relatório é reprovado automaticamente.',
          },

          toggle: 'Configurar mensagem de reprovação',
          fieldLabel: 'Mensagem de reprovação',
          mandatoryInfo: 'Campo Obrigatório',
        },
        approvalByLevels: {
          title: 'Aprovação manual',
          caption: 'Defina quem será o aprovador, sendo possível adicionar mais de um aprovador para o fluxo.',
          buttonLink: 'Adicionar nível',
          tooltipLink: 'Preencha os campos para adicionar novo nível.',
          fields: {
            criterion: {
              label: 'Critério',
              mandatoryInfo: 'Campo Obrigatório!',
            },
            hierarchyLevels: {
              label: 'Níveis de hierarquia',
              mandatoryInfo: 'Campo Obrigatório!',
            },
            people: {
              label: 'Pessoa',
              mandatoryInfo: 'Campo Obrigatório!',
            },
          },
        },
        optionsCriteriaSource: {
          costCenterOwner: {
            label: 'Responsável pelo centro de custo',
            caption: {
              report: 'A aprovação é realizada pela pessoa responsável pelo centro de custo associado à despesa.',
              advance:
                'A aprovação é realizada pela pessoa responsável pelo centro de custo associado ao adiantamento.',
            },
          },
          costCenterOwnerWithoutDefault: {
            label: 'Aprovação automática quando o centro de custo for o padrão do solicitante',
          },
          immediateManager: {
            label: 'Nível Único',
            caption: 'Se A envia para B, o aprovador deve ser B',
          },
          secondLevelManager: {
            label: 'Dois Níveis',
            caption: 'Se A envia para B e B para C, o fluxo de aprovação será B => C',
          },
          thirdLevelManager: {
            label: 'Três Níveis',
            caption: 'Se A envia para B, B para C e C para D, o fluxo de aprovação será B => C => D',
          },
          fourthLevelManager: {
            label: 'Quatro Níveis',
            caption: 'Se A envia para B, B para C, C para D e D para E, o fluxo de aprovação será B => C => D => E',
          },
          fifthLevelManager: {
            label: 'Cinco Níveis',
            caption:
              'Se A envia para B, B para C, C para D, D para E e E para F, o fluxo de aprovação será B => C => D => E => F',
          },
          manual: {
            label: 'Escolher aprovador manualmente',
            caption: 'Selecione uma pessoa com perfil de aprovação para aprovar.',
          },
          projectOwner: {
            label: 'Responsável pelo projeto',
            caption: {
              report: 'A aprovação é realizada pela pessoa responsável pelo projeto associado à despesa.',
              advance: 'A aprovação é realizada pela pessoa responsável pelo projeto associado ao adiantamento.',
            },
          },
          approvalByHierarchy: {
            label: 'Aprovação por hierarquia',
            caption: {
              report: 'Os aprovadores cadastrados a partir do gestor imediato recebem o relatório para aprovar.',
              advance: 'Os aprovadores cadastrados a partir do gestor imediato recebem o adiantamento para aprovar.',
            },
          },
        },
      },
      modal: {
        exclusion: {
          iconDescription: 'Atenção!',
          title: {
            plural: 'Tem certeza que deseja excluir os fluxos de aprovação selecionados?',
            singular: 'Tem certeza que deseja excluir o fluxo de aprovação selecionado?',
          },
          message: {
            plural: 'Todos os dados relacionados a eles serão perdidos e essa ação não poderá ser desfeita.',
            singular: 'Todos os dados relacionados a ele serão perdidos e essa ação não poderá ser desfeita.',
          },
          actions: {
            confirm: {
              plural: 'Confirmar exclusões',
              singular: 'Confirmar exclusão',
            },
          },
        },
        deactivation: {
          iconDescription: 'Atenção!',
          title: {
            plural: 'Tem certeza que deseja desativar os fluxos de aprovação selecionados?',
            singular: 'Tem certeza que deseja desativar o fluxo de aprovação selecionado?',
          },
          message: {
            plural: 'Ao desativá-los, eles deixarão de ser aplicados.',
            singular: 'Ao desativá-lo, ele deixará ser aplicado.',
          },
          actions: {
            confirm: {
              plural: 'Confirmar desativações',
              singular: 'Confirmar desativação',
            },
          },
        },
      },
      feedbacks: {
        toggle: {
          success: {
            singular: 'Status do fluxo de aprovação atualizado com sucesso!',
            plural: 'Status dos fluxos de aprovação atualizados com sucesso!',
          },
          error: 'Ocorreu um erro ao alterar status do fluxo de aprovação: ',
        },
        duplicate: {
          success: 'Duplicação realizada com sucesso!',
          error: 'Ocorreu um erro ao duplicar fluxo de aprovação.',
        },
        delete: {
          success: {
            plural: 'Fluxos de aprovação excluídos com sucesso!',
            singular: 'Fluxo de aprovação excluído com sucesso!',
          },
          error: 'Ocorreu um erro ao excluir o fluxo de aprovação: ',
        },
        create: {
          success: 'Fluxo de aprovação criado com sucesso!',
          error:
            'Ocorreu um erro ao criar o fluxo de aprovação, verifique o contexto do fluxo de aprovação solicitado!',
        },
        edit: {
          success: 'Alterações realizadas com sucesso!',
          error: 'Ocorreu um erro ao realizar alterações, verifique o contexto do fluxo de aprovação solicitado!',
        },
        byId: {
          error: 'Erro ao abrir fluxo, por favor tente novamente!',
        },
      },
    },
    costCenters: {
      page: {
        title: 'Centro de custo',
        caption: 'Crie centros de custo que podem ser selecionados no lançamento de despesas.',
        breadcrumb: {
          manageCompany: 'Gerenciar empresa',
        },
      },
      listPreview: {
        counterData: {
          label: 'Centro de custo',
          items: {
            plural: '{{ items }} itens',
            singular: '{{ items }} item',
          },
        },
      },
      addButton: {
        buttonLabel: 'Adicionar centro de custo',
        options: {
          addManually: 'Adicionar centro de custo via plataforma',
          addByFile: 'Adicionar centro de custo por arquivo',
        },
      },
      grid: {
        searchFieldLabel: 'Buscar por centro de custo',
        active: 'Ativo',
        inactive: 'Inativo',
        header: {
          columns: {
            costCenter: 'Centro de custo',
            status: 'Status',
            responsible: 'Responsável',
            groupCompany: 'Empresas do grupo',
            department: 'Departamento',
            code: 'Código',
            project: 'Projeto',
            actions: 'Ações',
          },
          batchActions: {
            deactivate: 'Desativar',
            activate: 'Ativar',
            delete: 'Excluir',
            tooltips: {
              active: 'Selecione centros de custos ativos.',
            },
          },
        },
        body: {
          actions: {
            edit: 'Editar centro de custo',
            deactivate: 'Desativar centro de custo',
            active: 'Ativar centro de custo',
            delete: 'Excluir centro de custo',
          },
        },
      },
      modal: {
        exclusion: {
          iconDescription: 'Atenção!',
          title: {
            plural: 'Tem certeza que deseja excluir os centros de custos selecionados?',
            singular: 'Tem certeza que deseja excluir esse centro de custos?',
          },
          message: {
            plural: 'Todos os dados relacionados a eles serão perdidos e essa ação não poderá ser desfeita.',
            singular: 'Todos os dados relacionados a ele serão perdidos e essa ação não poderá ser desfeita.',
          },
          actions: {
            confirm: {
              plural: 'Confirmar exclusões',
              singular: 'Confirmar exclusão',
            },
          },
        },
        deactivation: {
          iconDescription: 'Atenção!',
          title: {
            plural: 'Tem certeza que deseja desativar os centros de custos selecionados?',
            singular: 'Tem certeza que deseja desativar esse centro de custo?',
          },
          message: {
            plural:
              'Ao desativá-los, eles deixarão de aparecer na listagem de centro de custos no lançamento de despesas.',
            singular:
              'Ao desativá-lo, ele deixará de aparecer na listagem de centro de custos no lançamento de despesas.',
          },
          actions: {
            confirm: {
              plural: 'Confirmar desativações',
              singular: 'Confirmar desativação',
            },
          },
        },
      },
    },
    advancesAccounting: {
      statusCard: {
        filter: 'Filtrar',
        removeFilter: 'Remover filtro',
        label: {
          open: 'Abertos',
          finished: 'Finalizados',
          submitted: 'Submetidos',
          reproved: 'Reprovados',
          approved: 'Adiantamentos aprovados',
          pending: 'Pendentes',
        },
      },
      filters: {
        status: 'Status',
        paymentMethod: 'Método de pagamento',
        createdDate: 'Criado em',
        approver: 'Aprovador',
        createdBy: 'Solicitante',
        referenceId: 'Busca por código',
        statusLabel: {
          open: 'Abertos',
          finished: 'Finalizados',
          submitted: 'Submetidos',
          reproved: 'Reprovados',
          approved: 'Aprovados',
          pending: 'Pendentes',
        },
      },
    },
    advancesSettings: {
      general: {
        title: 'Configurações gerais',
        caption: 'Defina as configurações básicas das solicitações de adiantamento.',
        fields: {
          allowsMultipleAdvancesPerReport: 'Permitir vincular mais de um adiantamento por relatório.',
          approveOwnAdvance: 'Permitir que usuários aprovem seus próprios adiantamentos.',
          includeAdvanceBankDetails: 'Incluir dados bancários do criador do adiantamento.',
          enableAdvanceBatch: 'Habilitar adiantamento em lote.',
          allowsLinkingExpenseReturnOfAdvanceWithoutLinkingAdvances:
            'Permitir vincular despesas de "devolução de adiantamento" sem ter adiantamento vinculado ao relatório.',
        },
      },
      code: {
        title: 'Código',
        caption:
          'Defina um código que irá facilitar a conciliação das solicitações de adiantamento fetas na plataforma.',
        fields: {
          prefixValue: 'Prefixo',
          startValue: 'Número inicial',
        },
      },
      exportPDF: {
        title: 'Preferências de exportação (PDF)',
        caption: 'Incluir no documento pdf exportado:',
        fields: {
          allowIncludeBankDetailsPdf: 'Incluir dados bancários do criador no relatório',
          allowIncludeSignaturePdf: 'Incluir assinaturas no relatório',
          allowIncludeReportHistoryPdf: 'Incluir histórico do relatório',
        },
      },
      form: {
        title: 'Personalização de campos',
        caption: 'Personalize os campos que serão exibidos e obrigatórios na solicitação de adiantamento.',
        titleField: 'Campo habilitado',
        titleRequired: 'Preenchimento obrigatório',
        fields: {
          costCenter: 'Centro de custos',
          approver: 'Aprovador',
          report: 'Relatório',
          client: 'Cliente',
          project: 'Projeto',
          currency: 'Moeda',
          paymentMethod: 'Forma de pagamento',
        },
      },
      feedbacks: {
        success: 'Alterações realizadas com sucesso!',
        error: 'Ocorreu um erro ao realizar a solicitação!',
      },
    },
    mandatoryFieldInfo: {
      plural: 'Campos obrigatórios',
      singular: 'Campo obrigatório',
    },
    popoverDelegateUsers: {
      apply: 'Aplicar',
      goBack: 'Voltar',
      empty: 'Nenhuma pessoa encontrada!',
      clear: 'Limpar',
      delegateProfiles: 'Perfis delegados',
    },
    policy: {
      gridPage: {
        title: 'Políticas',
        caption: 'Gerencie as políticas da sua empresa.',
        add: {
          label: 'Adicionar política',
          options: {
            expensePolicy: 'Política de Despesas',
            corporateCardPolicy: 'Uso do Cartão Corporativo',
          },
        },
      },
      register: {
        title: {
          add: 'Adicionar política',
          edit: 'Editar politica',
        },
        ocr: {
          title: 'Leitura de recibo',
          caption: 'Configure as regras de leitura de recibo.',
          enableRulesOnReceiptReading: 'Habilitar regras para leitura automática de notas fiscais',
          sections: {
            validations: {
              title: 'Informações validadas pelo leitor',
              caption: 'Selecione quais informações serão necessárias para determinar a validade de uma nota fiscal',
              checks: {
                establishmentName: 'Nome do estabelecimento',
                address: 'Endereço',
                registrationNumber: 'CNPJ',
                invoiceDate: 'Data de emissão da nota',
                invoiceKey: 'Chave da nota fiscal',
                totalInvoiceValue: 'Valor total da nota',
                invoiceItemsDescription: 'Descrição dos itens da nota',
                invoiceItemsValue: 'Valor unitário dos itens da nota',
                invoiceItemsQuantity: 'Quantidade dos itens da nota',
                invoiceItemsTax: 'Valor de taxa dos itens da nota',
                invoiceItemsMeasurementUnit: 'Unidade de medida dos itens da nota',
                invoiceTotalItemsValue: 'Valor total dos itens da nota',
              },
              violationBehaviour:
                'Se alguma informação das selecionadas não for identificada, determine o que acontecerá:',
            },
            manualChanges: {
              title: 'Detecção de alterações manuais',
              caption:
                'Defina o que acontecerá quando o sistema detectar alguma alteração realizada em cima da leitura da nota fiscal.',
            },
            compliantRules: {
              title: 'Itens pessoais e regras de conformidade',
              caption:
                'Defina se deseja bloquear ou gerar uma violação quando houver itens pessoais como bebidas e cigarros nas notas fiscais.',
            },
          },
          behaviour: {
            generateViolation: 'Gerar violação',
            blockSubmit: 'Bloquear envio',
          },
          selectAll: 'Selecionar todos',
        },
        categories: {
          title: 'Categorias',
          caption:
            'Selecione as categorias que poderão ser lançadas nas despesas e, caso necessário, adicione limites de gastos.',
          direct: 'Você pode, ainda, criar novas categorias em: ',
          linkDirect: 'Despesas > Configurações de despesas > Categorias',
          field: {
            label: 'Categorias ativas na política',
          },
          optional: 'Opcional',
          actionTitle: 'Adicione limites de gastos personalizados',
          actionCaption: 'Defina limites personalizados para as despesas lançadas nas categorias selecionadas acima.',
        },
        conditions: {
          when: 'Quando',
          and: 'e',
          itIs: 'é',
          conditionOptions: {
            project: 'Projeto',
            user: 'Usuário',
            role: 'Cargo',
            subsidiary: 'Unidade de Negócio (Filial)',
            area: 'Departamento',
            company: 'Empresa',
          },
          fields: {
            conditionLabel: 'Condição',
            mandatory: 'Preenchimento obrigatório',
          },
          addConditional: 'Adicionar condicional',
          infos: {
            title: 'Condicionais da política',
            subTitle: 'Nível de prioridade',
            caption: 'Entenda o funcionamento das políticas de acordo com o nível de prioridade.',
            Paragraph1: 'Quando há mais de uma política aplicada a uma mesma pessoa, a mais restritiva é priorizada.',
            Paragraph2:
              'Por exemplo, uma política definida para um projeto é prioritária em relação a uma geral da empresa ou a uma criada para um cargo específico. Dessa forma, quando o usuário for lançar a despesa no projeto em questão, as regras dessa política serão aplicadas.',
            Paragraph3:
              'No caso de múltiplos critérios, a política pertencerá ao critério com maior nível de prioridade. ',
            Paragraph4: 'Uma política com critério de projeto, e cargo, será uma política de projeto.',
          },
        },
        mileage: {
          title: 'Quilometragem',
          caption: 'Habilite as configurações e adicione taxas de quilometragem.',
        },
        advances: {
          title: 'Adiantamentos',
          caption: 'Defina as configurações de adiantamentos.',
          settings: {
            simultaneous: {
              title: 'Adiantamento simultâneo',
              caption: 'Permite criar e processar mais de um adiantamento ao mesmo tempo.',
            },
            residual: {
              title: 'Adiantamento residual',
              caption:
                'Quando a prestação de contas for menor que o adiantamento, um adiantamento residual é gerado para cobrir a diferença.',
            },
          },
          limits: {
            title: 'Limites',
            caption: 'Defina os limites de adiantamento conforme cada moeda.',
            actions: {
              addLimit: 'Adicionar limite',
            },
            item: {
              currency: 'Moeda',
              value: 'Valor',
            },
            modal: {
              title: {
                create: 'Adicionar limite de adiantamento',
                edit: 'Editar limite de adiantamento',
              },
              caption: 'Adicione um limite para solicitação de adiantamentos conforme a moeda.',
              actions: {
                cancel: 'Cancelar',
                add: 'Adicionar',
                save: 'Salvar',
              },
              fields: {
                currency: 'Moeda',
                value: 'Valor limite',
              },
            },
            feedbacks: {
              advanceLimitDeletedSuccessfully: 'O limite foi apagado com sucesso',
              errorDeletingAdvanceLimit: 'Ocorreu um erro ao apagar o limite',
              advanceLimitSavedSuccessfully: 'Limite salvo com sucesso',
              errorSavingAdvanceLimit: 'Ocorreu um erro ao salvar o limite',
            },
          },
        },
        breadcrumb: {
          customizations: 'Personalizações',
        },
        steps: {
          basicInformation: {
            title: 'Informações básicas',
            caption:
              'Insira as informações básicas da política, e caso necessário, adicione o documento da política da sua empresa.',
            fields: {
              namePolicy: 'Nome da política',
              description: 'Descrição',
              mandatory: 'Preenchimento obrigatório',
              charactersInfo: 'Digite ao menos 3 caracteres!',
            },
            documentSection: {
              title: 'Documento da política',
              caption:
                'Faça o upload do documento da política da sua empresa que será disponibilizado à todos para consulta.',
              optional: 'Opcional',
              field: 'Política',
              documentName: 'Políticas',
            },
          },
          policyProperties: {
            title: 'Propriedades da política',
            caption:
              'Selecione quando a política será aplicada, e quais regras determinarão a aplicação. Nenhuma regra é obrigatória, mas a política deve conter ao menos uma regra.',
            policyConditions: {
              title: 'Condições de aplicação da política',
              caption:
                'Defina, através das condicionais selecionadas, para quais grupos de pessoas essa política será aplicada. Quanto mais específicas as condicionais, mais prioritária será a política.',
              knowMore: 'Saiba mais',
            },
            policyRules: {
              title: 'Regras de aplicação da política',
              rules: {
                category: 'Categoria',
                mileage: 'Quilometragem',
                advances: 'Adiantamentos',
                receiptReading: 'Leitura de recibo',
              },
            },
          },
          policySummary: {
            title: 'Resumo',
            caption:
              'Revise as informações da sua política, só prossiga depois de confirmar as definições. Caso necessário, retorne para a edição.',
          },
        },
        footer: {
          cancel: 'Cancelar',
          back: 'Voltar',
          continue: 'Continuar',
          finish: 'Finalizar',
        },
        feedbacks: {
          byId: {
            error: {
              title: 'Ocorreu um erro ao abrir a política',
              message: 'Por favor, tente novamente dentro de alguns instantes.',
            },
          },
        },
      },
      policyGrid: {
        all: 'Todas',
        byLevel: 'Por nível',
        search: 'Buscar por nome, descrição da política',
        byLevelDescription1: 'Visualize todas as políticas de acordo com o nível de hierarquia.',
        byLevelDescription2:
          'Em caso de conflito entre as políticas do mesmo nível, você pode alterar o grau de prioridade entre elas.',
        feedbacks: {
          list: {
            error: {
              title: 'Não foi possível obter a lista de políticas',
              message: 'Por favor, tente novamente dentro de alguns instantes.',
            },
          },
        },
        header: {
          name: 'Nome da política',
          description: 'Descrição',
          level: 'Nível',
          updatedAt: 'Última atualização',
          status: 'Status',
          actions: 'Ações',
        },
        columns: {
          status: {
            on: 'Ativo',
            off: 'Inativo',
          },
          actions: {
            seeDetails: 'Ver detalhes da política',
            edit: 'Editar política',
            duplicate: 'Duplicar política',
            export: 'Exportar política',
            delete: 'Excluir política',
          },
        },
        filters: {
          level: {
            label: 'Nível',
            options: {
              project: 'Projeto',
              user: 'Usuário',
              role: 'Cargo',
              subsidiary: 'Unidade de negócio (Filial)',
              area: 'Departamento',
              company: 'Empresa',
            },
          },
          status: {
            label: 'Status',
            options: {
              active: 'Ativo',
              inactive: 'Inativo',
            },
          },
          lastUpdate: {
            label: 'Última atualização',
          },
        },
      },
      policyDetails: {
        drawer: {
          title: 'Políticas',
          caption: 'Visualize os detalhes das políticas da sua empresa e quando são aplicadas.',
          active: 'Ativo',
          inactive: 'Inativo',
          undocumented: 'Essa politica não possui documento!',
          emptyPolicies: 'Nenhuma política disponível.',
        },
      },
      create: {
        feedback: {
          error: {
            title: 'Ocorreu um erro ao criar a política',
            message: 'Por favor, tente novamente dentro de alguns instantes.',
          },
          success: {
            title: 'Política criada com sucesso',
            message: 'Continue com os próximos passos de configuração.',
          },
        },
      },
      update: {
        feedback: {
          error: {
            title: 'Ocorreu um erro ao atualizar os dados da política',
            message: 'Por favor, tente novamente dentro de alguns instantes.',
          },
          success: {
            title: 'Política atualizada com sucesso',
            message: 'Caso queria alterar outros dados continue nos próximos passos!',
          },
        },
      },
      duplicate: {
        feedback: {
          error: {
            title: 'Ocorreu um erro ao duplicar política',
            message: 'Por favor, tente novamente dentro de alguns instantes.',
          },
          success: {
            message: 'Política duplicada com sucesso!',
          },
        },
      },
      toggle: {
        plural: {
          feedback: {
            error: {
              title: 'Não foi possível alterar o status das políticas selecionadas',
              message: 'Por favor, tente novamente dentro de alguns instantes.',
            },
            success: {
              message: 'Status das políticas alterados com sucesso!',
            },
          },
        },
        singular: {
          feedback: {
            error: {
              title: 'Não foi possível alterar o status da política',
              message: 'Por favor, tente novamente dentro de alguns instantes.',
            },
            success: {
              message: 'Status da política alterado com sucesso!',
            },
          },
        },
      },
      delete: {
        plural: {
          modal: {
            title: 'Tem certeza que deseja excluir as políticas selecionadas?',
            message: 'Ao exclui-las, todos os dados relacionados a elas serão perdidos',
            confirm: 'Confirmar exclusões',
          },
          feedback: {
            error: {
              title: 'Não foi possível excluir as políticas selecionadas',
              message: 'Por favor, tente novamente dentro de alguns instantes.',
            },
            success: {
              message: 'Políticas excluídas com sucesso!',
            },
          },
        },
        singular: {
          modal: {
            title: 'Tem certeza que deseja excluir essa política?',
            message: 'Ao excluir, todos os dados relacionados a ele serão perdidos',
            confirm: 'Confirmar exclusão',
          },
          feedback: {
            error: {
              title: 'Não foi possível excluir a política',
              message: 'Por favor, tente novamente dentro de alguns instantes.',
            },
            success: {
              message: 'Política excluída com sucesso!',
            },
          },
        },
      },
      disable: {
        plural: {
          modal: {
            title: 'Tem certeza que deseja desativar as políticas selecionadas?',
            message: 'Ao desativa-las, as políticas ficarão indisponíveis, mas podem ser ativadas quando desejar.',
            confirm: 'Confirmar desativações',
          },
          feedback: {
            error: {
              title: 'Não foi possível desativar as políticas selecionadas',
              message: 'Por favor, tente novamente dentro de alguns instantes.',
            },
            success: {
              message: 'Políticas desativadas com sucesso!',
            },
          },
        },
        singular: {
          modal: {
            title: 'Tem certeza que deseja desativar essa política?',
            message: 'Ao desativar, a política fica indisponível, mas pode ser ativada quando desejar.',
            confirm: 'Confirmar desativação',
          },
          feedback: {
            error: {
              title: 'Não foi possível desativar a política',
              message: 'Por favor, tente novamente dentro de alguns instantes.',
            },
            success: {
              message: 'Política desativada com sucesso!',
            },
          },
        },
      },
    },
    pages: {
      subsidiaryRegister: {
        actions: {
          cancel: 'Cancelar',
          save: 'Salvar',
          goBack: 'Voltar',
          continue: 'Continuar',
        },
        feedbacks: {
          savingSubsidiary: 'Salvando dados da unidade de negócio.',
          create: {
            success: 'Unidade de negócio criada com sucesso!',
            error: 'Ocorreu um erro ao tentar criar unidade de negócio',
          },
          update: {
            success: 'Unidade de negócio atualizada com sucesso!',
            error: 'Ocorreu um erro ao tentar atualizar unidade de negócio',
          },
          gettingOne: {
            error: 'Erro ao tentar obter unidade de negócio',
          },
        },
        assignUsersGrid: {
          allPeople: 'Todas as pessoas ({{selected}})',
          selectedForCostCenter: 'Selecionadas para lançar nessa unidade de negócio ({{selected}})',
          all: 'Todas ({{selected}})',
          selected: 'Selecionadas ({{selected}})',
          header: {
            name: 'Nome',
          },
        },
        breadcrumb: {
          manualAddSubsidiary: 'Cadastrar unidade de negócio',
          manageCompany: 'Gerenciar empresa',
          subsidiary: 'Unidade de negócio',
          editSubsidiary: 'Editar unidade de negócio',
        },
      },
      advances: {
        title: 'Adiantamentos',
        caption: 'Adicione e acompanhe seus pedidos de adiantamentos.',
        advance: 'Adiantamento',
        attach: 'Vincular adiantamento',
        addButton: 'Adicionar adiantamento',
        add: 'Adicionar',
        approval: {
          feedbacks: {
            statusSuccessMessage: {
              plural: 'Status alterados com sucesso',
              singular: 'Status alterado com sucesso',
            },
          },
        },
      },
      subsidiaries: {
        feedbacks: {
          update: {
            success: 'Unidade de negócio atualizada com sucesso!',
            error: 'Ocorreu um erro ao tentar atualizar unidade de negócio',
          },
          subsidiary: 'Unidade de negócio: ',
        },
      },
      analytics: {
        title: 'Relatórios de dados',
      },
      clientRegister: {
        title: 'Adicionar cliente manualmente',
        cancel: 'Cancelar',
        continue: 'Continuar',
        conclude: 'Concluir',
        creatingClient: 'Criando client...',
        updatingClient: 'Atualizando client...',
        bulkImportLoading: 'Carregando clientes...',
        feedbacks: {
          gettingMany: {
            error: 'Erro ao obter a lista de de clientes',
          },
          gettingOne: {
            error: 'Erro ao obter os dados do cliente',
          },
          savingProject: 'Salvando cliente',
          create: {
            success: 'Cliente criado com sucesso',
            error: 'Error ao criar o cliente',
          },
          update: {
            success: 'Cliente atualizado com sucesso',
            error: 'Erro ao atualizar o cliente',
          },
          toggleStatus: {
            success: 'Status do cliente alterado com sucesso!',
            error: 'Erro ao atualizar o cliente',
          },
          delete: {
            success: 'Cliente excluído com sucesso!',
            error: 'Erro ao excluir o cliente',
          },
        },
      },
      setupOnboarding: {
        title: 'Configurar Flash Despesas',
        steps: {
          categories: 'Categorias',
          limits: 'Limites',
          communication: 'Comunicação',
        },
        skipConfiguration: 'Pular configurações',
        backStep: 'Voltar',
        nextStep: 'Avançar',
      },
      reportDetails: {
        title: 'Detalhes do relatório',
        addExpense: 'Adicionar despesa',
        addKM: 'Adicionar quilometragem',
        addAdvance: 'Adicionar adiantamento',
        attachExpense: 'Vincular despesa',
        attachAdvance: 'Vincular adiantamento',
        comeBack: 'Voltar',
        reopenReport: 'Reabrir relatório',
        sendReport: 'Enviar relatório',
        cancel: 'Cancelar',
      },
      expenses: {
        pageTitle: 'Prestação de contas',
        expenses: 'Despesas',
        pendingExpenses: 'Despesas com pendência',
        add: 'Adicionar',
        mileage: 'Quilometragem',
        latestReports: 'Últimos relatórios',
        seeAllReports: 'Ver todos relatórios',
      },
      sharedExpenses: {
        add: 'Adicionar Despesa',
        addCorporate: 'Adicionar despesa corporativa',
        addCompanyPayable: 'Despesa com fornecedor',
        expenses: 'Despesa manual',
        mileage: 'Despesa de quilometragem',
        goToConfiguration: 'Ir para configurações',
        goToDelegationSettings: 'Ir para configurações de delegação',
        policy: 'Ver políticas',
        delegateProfiles: 'Perfis Delegados',
        addVendor: 'Adicionar fornecedor',
        tabs: {
          transaction: 'Transações sem prestação de conta',
          expenses: 'Despesas corporativas',
          vendorExpenses: 'Despesas com fornecedor',
        },
        viewingAs: 'Visualizando como',
        backToMyProfile: 'Retornar para meu perfil',
      },
      expenseRegister: {
        newExpense: 'Nova despesa',
        editExpense: 'Editar despesa',
        cancel: 'Cancelar',
        comeBack: 'Voltar',
        continue: 'Continuar',
        creatingExpense: 'Criando despesa...',
        updatingExpense: 'Atualizando despesa...',
        reportNotFound: 'Relatório não encontrado',
        selectReport: 'Selecionar relatório',
        addMileage: 'Adicionar quilometragem',
        editMileage: 'Editar quilometragem',
        requiredFields: 'Campos obrigatórios',
        requiredFieldsMgs: 'Por favor, preencha os campos indicados!',
      },
      projectRegister: {
        title: 'Adicionar projeto por arquivo',
        project: 'Projeto',
        manualAddProject: ['Adicionar projeto manualmente', 'Atribuir pessoas'],
        registerInstructions: [
          'Insira os dados do projeto, e configure as pessoas que podem lançar despesas a ele.',
          'Escolha quais pessoas poderão lançar despesas a esse projeto.',
        ],
        editProject: 'Editar projeto',
        breadcrumb: {
          manageCompany: 'Gerenciar empresa',
          project: 'Projetos',
        },
        sections: {
          generalData: 'Dados Gerais',
        },
        fields: {
          projectName: 'Nome do projeto',
          client: 'Cliente',
          costCenter: 'Centro de custo',
          startAt: 'Data inicial',
          endAt: 'Data final',
          accountable: 'Responsável',
          code: 'Código',
          codeHelperText:
            'Insira o código utilizado pela sua empresa para melhor controle. O uso desse código facilita a conciliação de relatórios exportados pela nossa plataforma',
          activeProject: {
            title: 'Ativar projeto',
            description: 'Quando ativo, o projeto aparece como uma opção ao lançar uma despesa.',
          },
        },
        actions: {
          cancel: 'Cancelar',
          continue: 'Continuar',
        },
        feedbacks: {
          gettingMany: {
            error: 'Erro ao obter a lista de projetos',
          },
          gettingOne: {
            error: 'Erro ao obter os dados do projeto',
          },
          savingProject: 'Salvando projeto',
          create: {
            success: 'Projeto criado com sucesso',
            error: 'Error ao criar o projeto',
          },
          update: {
            success: 'Projeto atualizado com sucesso',
            error: 'Erro ao atualizar o projeto',
          },
          toggleStatus: {
            success: 'Status do projeto alterado com sucesso!',
            error: 'Erro ao atualizar o projeto',
          },
          delete: {
            success: 'Projeto excluído com sucesso!',
            error: 'Erro ao excluir o projeto',
          },
        },
        validation: {
          startDateMustBeGreaterThenToday: 'A data inicial deve ser maior que a data atual',
          startDateMustBeLessThenEndDate: 'A data inicial deve ser menor que a data final',
        },
      },
      advanceRegister: {
        title: {
          toView: 'Adiantamento',
          create: 'Adicionar adiantamento',
          edit: 'Editar adiantamento',
        },
        advances: 'Adiantamentos',
        approvers: 'Aprovadores',
        description: 'Preencha as informações ao lado para solicitar um adiantamento.',
        toViewDescription: 'Detalhes da solicitação de adiantamento.',
        fields: {
          currency: 'Moeda',
          value: 'Valor',
          paymentType: 'Forma de pagamento',
          client: 'Cliente',
          project: 'Projeto',
          costCenter: 'Centro de custos',
          report: 'Relatório',
          approver: 'Aprovador',
          advanceDate: 'Data do adiantamento',
          description: 'Descrição',
          requester: 'Solicitante',
        },
        actions: {
          cancel: 'Cancelar',
          create: 'Solicitar adiantamento',
          edit: 'Salvar adiantamento',
          goBack: 'Voltar',
          approve: 'Aprovar adiantamento',
          reprove: 'Reprovar adiantamento',
          reopen: 'Reabrir adiantamento',
          pay: 'Pagar adiantamento',
          submitForIntegration: 'Enviar para integração',
          tooltip: {
            accounting: 'Essa ação só pode ser tomada quando o adiantamento estiver com status aprovado!',
            approval: 'Essa ação só pode ser tomada quando o adiantamento estiver com status submetido!',
          },
        },
        feedbacks: {
          save: 'Alterações realizadas com sucesso!',
          create: 'Adiantamento criado com sucesso!',
          error: 'Ocorreu um erro, por favor tente novamente.',
          attach: 'Vínculo do adiantamento ao relatório {{ reportName }} realizado com sucesso!',
        },
        validation: {
          requiredField: 'Campo obrigatório!',
        },
      },
      costCenterRegister: {
        steps: {
          header: {
            generalData: 'Dados gerais',
            users: 'Atribuir usuários',
          },
          pageContent: [
            {
              title: 'Dados gerais',
              sectionTitle: 'Dados gerais',
              subTitle: 'Insira os dados básicos do centro de custo.',
            },
            {
              title: 'Atribuir pessoas',
              sectionTitle: 'Selecione as pessoas',
              subTitle: 'Configure as pessoas que podem lançar no centro de custos',
            },
          ],
        },
        assignUsersGrid: {
          allPeople: 'Todas as pessoas ({{selected}})',
          selectedForCostCenter: 'Selecionadas para lançar nesse centro de custo ({{selected}})',
          all: 'Todas ({{selected}})',
          selected: 'Selecionadas ({{selected}})',
          header: {
            name: 'Nome',
          },
        },
        costCenter: 'Centro de custo',
        manualAddCostCenter: 'Adicionar centro de custo manualmente',
        registerInstructions: 'Insira os dados do centro de custo, e configure as pessoas pertencentes a ele.',
        editCostCenter: 'Editar centro de custo',
        breadcrumb: {
          manageCompany: 'Gerenciar empresa',
          costCenter: 'Centros de custo',
        },
        sections: {
          generalData: 'Dados Gerais',
        },
        fields: {
          costCenterName: 'Nome do centro de custo',
          responsible: 'Responsável',
          department: 'Departamento',
          project: 'Projeto',
          groupCompany: 'Empresas do grupo',
          code: 'Código',
          codeHelperText:
            'Insira o código utilizado pela sua empresa para melhor controle. O uso desse código facilita a conciliação de relatórios exportados pela nossa plataforma.',
          activeCostCenter: {
            title: 'Ativar centro de custo',
            description: 'Quando ativo, esse centro de custo aparece como uma opção ao lançar uma despesa.',
          },
        },
        actions: {
          cancel: 'Cancelar',
          continue: 'Continuar',
          save: 'Salvar',
          goBack: 'Voltar',
        },
        feedbacks: {
          savingCostCenter: 'Salvando centro de custo',
          costCenterCreatedSuccess: 'Centro de custo criado com sucesso!',
          costCenterCreatedAndEditError: 'Ocorreu um erro ao realizar sua solicitação!',
          costCenterEditSuccess: 'Centro de custo editado com sucesso!',
          costCenterStatusSuccess: {
            plural: 'Status dos centros de custo alterados com sucesso!',
            singular: 'Status do centro de custo alterado com sucesso!',
          },
          costCenterDeleteSuccess: {
            plural: 'Centros de custo excluídos com sucesso!',
            singular: 'Centro de custo excluído com sucesso!',
          },
        },
        bulkImport: {
          title: 'Adicionar centro de custo por arquivo',
        },
      },
      reports: {
        title: 'Relatórios',
        add: 'Adicionar relatório',
      },
      expenseDetails: {
        expense: 'Despesa',
        breadcrumb: {
          expenses: 'Despesas',
          expenseDetails: 'Detalhes da despesa',
          originDestination: 'Detalhes de quilometragem - O&D',
          odometer: 'Detalhes de quilometragem - Odômetro',
        },
      },
      financialReports: {
        title: 'Contabilizações',
        Accountability: {
          title: 'Prestação de contas',
          tabs: {
            pendingAccounts: 'Contabilização pendente',
            pendingAccountability: 'Prestação de contas pendentes',
            filterTitle: 'Filtrar por',
          },
          errorBoundary: {
            moduleName: 'Contas a Pagar',
            title: 'Erro ao tentar carregar banner de despesas com fornecedor',
          },
        },
      },
      financialReportDetails: {
        reprove: 'Reprovar',
        approve: 'Aprovar',
        repay: 'Reembolsar',
        comeBack: 'Voltar',
        integration: 'Enviar para integração',
        cancelIntegration: 'Cancelar integração',
      },
      financialAdvances: {
        title: 'Adiantamento',
      },
      customizations: {
        title: 'Preferências',
      },
      launchMileageExpense: {
        title: {
          newMileageExpense: 'Nova despesa de quilometragem',
          editMileageExpense: 'Editar despesa de quilometragem',
        },
        breadcrumbs: {
          expenses: 'Despesas',
          addMileage: 'Adicionar quilometragem',
          editMileage: 'Editar quilometragem',
        },
      },
      subscriptionConfirmation: {
        title: 'Confira as informações da sua assinatura',
        caption:
          'Confira os serviços que a sua empresa está adquirindo, reveja as condições comerciais e assine o contrato para obter mais agilidade, controle e segurança na gestão das despesas da sua empresa!',
        loading: {
          title: 'Carregando as informações do seu contrato',
          message:
            'Preparamos as melhores condições comerciais para tornar a gestão de despesas da sua empresa ainda mais eficiente',
        },
        breadcrumbs: {
          start: 'Início',
          getToKnowFlashExpense: 'Conheça a Flash Expense',
          confirmYourSubscription: 'Confirme sua assinatura',
        },
        actionsPage: {
          seeMore: 'Saiba mais',
          toGoBack: 'Voltar',
          cancel: 'Cancelar',
          confirm: 'Confirmar',
        },
        tooltip: {
          confirm: 'Você precisa ler e aceitar as condições comerciais para prosseguir.',
        },
      },
      approvals: {
        title: 'Aprovações',
      },
    },
  },
};

export default translations;
