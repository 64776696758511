import { z } from 'zod';

export const approvalFlowSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1),
  condition: z.array(
    z.object({
      conditionType: z.enum(['MANUAL', 'HIERARCHY']),
      value: z.string().min(1),
    }),
  ),
  description: z.string().min(1),
  status: z.enum(['ACTIVE', 'INACTIVE']),
  approvalType: z.enum(['AUTO', 'CUSTOM', 'AUTO_REJECT']),
  documentType: z.enum(['ORDER']),
  priority: z.coerce.number().optional(),
  criteria: z.array(
    z.object({
      type: z.enum(['EXPENSE_AMOUNT', 'ROLE', 'DEPARTMENT']),
      operator: z.enum([
        'EQUAL',
        'GREATER_THAN',
        'LESS_THAN',
        'GREATER_THAN_OR_EQUAL',
        'LESS_THAN_OR_EQUAL',
        'NOT_EQUAL',
      ]),
      relationshipCondition: z.enum(['AND', 'OR']),
      value: z.string().min(1),
      complementValue: z.string().optional(),
    }),
  ),
});

export type ApprovalFlow = z.infer<typeof approvalFlowSchema>;
