import styled from "styled-components"

export interface CardsListWrapperProps {
  isDragging: boolean
}

export const CardsListWrapper = styled.div<CardsListWrapperProps>`
  display: flex;
  flex-direction: column;
  height: 100%;

  background-color: ${({ isDragging, theme }) => (isDragging ? theme.colors.neutral[90] : theme.colors.neutral[95])};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  padding: ${({ theme }) => theme.spacings.xs3};
  overflow: hidden scroll;
`

export interface DroppableSectionProps {
  isDraggingOver: boolean
}

export const DroppableSection = styled.div<DroppableSectionProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ isDraggingOver, theme }) =>
    isDraggingOver ? theme.colors.neutral[90] : theme.colors.neutral[95]};

  transition:
    background-color 0.2s ease,
    opacity 0.1s ease;
  user-select: none;

  width: 100%;
  height: 100%;

  overflow: visible auto;

  &:not(:hover) {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      background: transparent;
      width: 0px;
      height: 0px;
    }
  }
`

export const ScrollContainer = styled.div`
  overflow: hidden auto;

  &:not(:hover) {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      background: transparent;
      width: 0px;
      height: 0px;
    }
  }

  height: 100%;
`
