import { trpc } from '@/api/client';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';

export const useGetAppStoreData = () => {
  const { selectedCompany } = useSelectedCompany();
  const { data, isLoading } = trpc.getAppStoreData.useQuery({
    companyId: selectedCompany.id,
  });

  return { appStoreData: data, isLoading };
};
