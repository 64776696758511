import { ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { CompanyInfo, Container, RightContainer } from "./styled";
import cnpj from "../../../../../../utils/cnpjMask";
import { CheckboxInternal } from "../../../../../Checkbox";

export const BoxAdornment = ({
  name,
  hasContract,
  registrationNumber,
  checked,
  setChecked,
}) => {
  return (
    <Container>
      <CheckboxInternal
        disabled={hasContract}
        checked={hasContract || checked}
        onChange={setChecked}
      />
      <RightContainer>
        <ShapeIcon
          name="IconBuildingCommunity"
          variant="default"
          size={32}
          color="#F20D7A"
        />
        <CompanyInfo>
          <Typography variant="headline9" weight={700} variantColor="#FE2B8F">
            {name}
          </Typography>
          <Typography variant="caption" variantColor="#83727D">
            CNPJ: {cnpj(registrationNumber)}
          </Typography>
        </CompanyInfo>
      </RightContainer>
    </Container>
  );
};
