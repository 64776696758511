import { useMemo } from 'react';
import { Box } from '@components/Box';
import { Button, LinkButton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { ROUTE_Accounts } from '@pages/external';

import * as SC from './styled';

type Props = {
  moduleName: string;
  title?: string;
  description?: string;
  printIsolatedError?: boolean;
};

export function ErrorContent(props: Props) {
  const createdAt = useMemo(() => new Date().toISOString(), []);

  if (props.printIsolatedError)
    return (
      <SC.ContainerIsolatedError>
        <SC.IconAlert name="IconAlertTriangle" size={40} />
        <div>
          {props.moduleName && (
            <SC.IsolatedErrorModuleName variant="body4">{`Modulo: ${props.moduleName}`}</SC.IsolatedErrorModuleName>
          )}

          {props.title && (
            <SC.IsolatedErrorTitle variant="body3">{props.title}</SC.IsolatedErrorTitle>
          )}

          {props.description && (
            <SC.IsolatedErrorDescription variant="body4">
              {props.description}
            </SC.IsolatedErrorDescription>
          )}
        </div>
      </SC.ContainerIsolatedError>
    );

  return (
    <SC.Container>
      <div>
        <Box margin="none none m none">
          <SC.Title.Headline6>
            {props.title || 'Erro ao tentar carregar a página'}
          </SC.Title.Headline6>

          <Typography.Body4>
            <b>Módulo:</b> {props.moduleName}
          </Typography.Body4>
        </Box>

        <Typography.Body4>
          <b>Página:</b> <code>{location.href}</code>
        </Typography.Body4>

        <Typography.Body4>
          <b>Horário:</b> {createdAt}
        </Typography.Body4>
      </div>

      <Typography.Caption>
        Clique em uma das opções abaixo para tentar novamente. <br />
        Caso o erro persistir, entre em contato com o suporte.
      </Typography.Caption>

      <SC.ActionContainer>
        <a href={ROUTE_Accounts.buildPath({})}>
          <LinkButton variant="default">Ir para tela de Saldos e cartões</LinkButton>
        </a>

        <Button size="medium" variant="primary" onClick={() => location.reload()}>
          Tentar novamente
        </Button>
      </SC.ActionContainer>
    </SC.Container>
  );
}
