import { PageContainer as DSPageContainer } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledPageContainer = styled(DSPageContainer)`
  padding: ${({ theme }) => theme.spacings.xl5};
  display: flex;
  flex-direction: column;
`;

export const ProductGroupTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin: ${({ theme }) => `${theme.spacings.l} 0 ${theme.spacings.m} 0`};
  gap: ${({ theme }) => theme.spacings.xs5};
`;
