import { Dispatch, useContext } from "react"

import { useFormik } from "formik"
import * as yup from "yup"

import {
  EnumGlobalActions,
  globalContext,
  IActions,
} from "../../../../../../context"
import {
  StyledDescription,
  StyledModal,
  StyledSubTitle,
  StyledTextField,
  StyledTitle,
  StyledSubtitleContainer,
  StyledButtonContainer,
  StyledSectionContainer,
  CloseContainer,
  StyledLinkButton,
} from "./styles"

import {
  Button,
  IconButton,
  Icons,
  Loader,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import dispatchToast from "../../../../../../utils/dispatchToast"
import { modalAction } from "../../../../../../utils"
import { useQueryClient } from "@tanstack/react-query"
import { trpc } from "@api/client"

const RenameTemplateModal = () => {
  const queryClient = useQueryClient()
  const { dispatch, modal } = useContext(globalContext)
  const { mutate: editFormConfigName, isLoading: isRenaming } =
    trpc.formConfig.renameFormConfig.useMutation()

  const validationSchema = yup.object({
    name: yup.string().required("Favor digitar o nome do campo"),
  })

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    validate: (values) => {
      const errors: any = {}
      return errors
    },
    onSubmit: async (values) => handleRenameTemplate(values.name),
  })

  const handleRenameTemplate = (newName: string) => {
    editFormConfigName(
      {
        formConfigId: modal?.configId,
        newName,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries()

          dispatchToast({
            type: "success",
            content: "Template renomeado com sucesso!",
          })
          modalAction({
            param: { name: "renameTemplateModal", open: false },
            dispatch: dispatch as Dispatch<IActions<EnumGlobalActions>>,
          })
        },
        onError: (err: any) => {
          console.log(err)
          dispatchToast({
            type: "error",
            content: "Houve um problema ao renomear o template",
          })
        },
      },
    )
  }

  return (
    <StyledModal
      open={modal?.name === "renameTemplateModal" && modal?.open}
      onClose={() =>
        modalAction({
          param: { name: "renameTemplateModal", open: false },
          dispatch: dispatch as Dispatch<IActions<EnumGlobalActions>>,
        })
      }
    >
      <>
        <CloseContainer>
          <IconButton
            size="small"
            variant="line"
            icon="IconX"
            onClick={() =>
              modalAction({
                param: { name: "renameTemplateModal", open: false },
                dispatch: dispatch as Dispatch<IActions<EnumGlobalActions>>,
              })
            }
          />
        </CloseContainer>

        <StyledSectionContainer>
          <StyledTitle variant={"headline6"}>
            Template de lista de documentos
          </StyledTitle>
          <StyledDescription variant={"body3"} style={{ marginTop: "8px" }}>
            Crie e edite templates de listas de documentos por tipo de
            contratação, departamento e/ou cargo
          </StyledDescription>
        </StyledSectionContainer>

        <StyledSectionContainer>
          <StyledSubTitle variant={"headline8"}>
            Nomeie seu template
          </StyledSubTitle>
          <StyledSubTitle variant={"body4"} style={{ marginTop: "8px" }}>
            Dê um nome ao template e descreva o seu objetivo.
          </StyledSubTitle>
          <StyledTextField
            id={"name"}
            name={"name"}
            label={"Nome do template"}
            inputProps={{ maxLength: 50 }}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            style={{ marginTop: "16px" }}
          />

          <StyledSubtitleContainer>
            <StyledDescription variant="caption">
              Máximo 50 caracteres
            </StyledDescription>
            <StyledDescription variant="caption">
              {formik.values.name.length}/50
            </StyledDescription>
          </StyledSubtitleContainer>
        </StyledSectionContainer>

        <StyledButtonContainer>
          <div>
            <StyledLinkButton
              variant="primary"
              style={{ alignSelf: "center" }}
              onClick={() => {
                modalAction({
                  param: { name: "renameTemplateModal", open: false },
                  dispatch: dispatch as Dispatch<IActions<EnumGlobalActions>>,
                  callback: () => {
                    modalAction({
                      param: {
                        name: "removeTemplateModal",
                        open: true,
                        configId: modal?.configId,
                        companyId: modal?.companyId,
                      },
                      dispatch: dispatch as Dispatch<
                        IActions<EnumGlobalActions>
                      >,
                    })
                  },
                })
              }}
            >
              Excluir template
            </StyledLinkButton>
          </div>
          <div style={{ display: "flex" }}>
            <Button
              size={"large"}
              variant="secondary"
              onClick={() => {
                modalAction({
                  param: { name: "renameTemplateModal", open: false },
                  dispatch: dispatch as Dispatch<IActions<EnumGlobalActions>>,
                })
              }}
              style={{ alignSelf: "center", marginRight: "24px" }}
            >
              <Typography variant="body3" style={{ fontWeight: 700 }}>
                Cancelar
              </Typography>
            </Button>
            <Button
              size={"large"}
              variant="primary"
              style={{ alignSelf: "center" }}
              onClick={() => {
                formik.handleSubmit()
              }}
              disabled={isRenaming}
            >
              {isRenaming ? (
                <Loader size="extraSmall" variant="secondary" />
              ) : (
                <>
                  <Typography variant="body3" style={{ fontWeight: 700 }}>
                    Confirmar
                  </Typography>
                  <Icons name="IconArrowRight" />
                </>
              )}
            </Button>
          </div>
        </StyledButtonContainer>
      </>
    </StyledModal>
  )
}

export { RenameTemplateModal }
