import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: grid;
  padding: 50px;
  gap: 30px;

  h2 {
    margin-bottom: 30px;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  a {
    margin-right: 20px;
  }
`;

export const Title = styled(Typography)`
  margin-bottom: 30px;
`;

export const ContainerIsolatedError = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs};
  padding: ${({ theme }) => theme.spacings.xs};
  align-items: center;
  border: ${({ theme }) => theme.borders.width.xs2} solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  opacity: 0.7;
`;

export const IconAlert = styled(Icons)`
  color: ${({ theme }) => theme.colors.neutral[70]};
`;

export const IsolatedErrorDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const IsolatedErrorModuleName = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const IsolatedErrorTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;
