import { useContext, useEffect, useMemo, useState } from 'react';
import { IConstractSign } from '@containers/Subscription/hooks/useSignContract';
import { Button, LinkButton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { getFromLS } from '@flash-tecnologia/hros-web-utility';
import { yupResolver } from '@hookform/resolvers/yup';
import { EFieldType, FieldFactory } from '@organisms/FieldFactory';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { Controller, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';

import { PlayerModal } from '../PlayerModa/PlayerModal';
import { useCompletionRegistrationFormOptions } from './CompletionRegistrationFormOptions';
import { completionRegistrationSchema } from './completionRegistrationSchema';
import { TAssignmentForm, useSubmitAssignmentForm } from './hooks/useSubmitAssignmentForm';
import * as SC from './styled';

interface IProps {
  isSelfAcquisition?: boolean;
  constractSignIds: IConstractSign['contracts'];
  open: boolean;
  onClose?(): void;
  onAction(): void;
}

enum EStep {
  FIRST_STEP = 0,
  SECOND_STEP = 1,
  THIRD_STEP = 2,
}

export function CompletionRegistrationModal({ open, isSelfAcquisition, constractSignIds, onAction, onClose }: IProps) {
  const t = useTranslate('molecules.acceptSubscriptionCheckbox.feedbacks.success');
  const { isAdmin } = useContext(SessionContext);
  const { budgetOptions, personOptions, deadlineExpectation } = useCompletionRegistrationFormOptions();
  const { submit, isLoading, error } = useSubmitAssignmentForm();
  const [step, setStep] = useState(EStep.FIRST_STEP);
  const [videoOpen, setVideoOpen] = useState(false);
  const [numberOfAttempts, setNumberOfAttempts] = useState(0);
  const [showEmailField, setShowEmailField] = useState(false);
  const userAuth = useMemo(() => getFromLS('userAuth'), []);
  const { control, handleSubmit, clearErrors, setValue } = useForm({
    resolver: yupResolver(completionRegistrationSchema),
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });

  const renderHeaderTitle = () => (
    <SC.ModalDescriptionContainer>
      {step === EStep.FIRST_STEP ? (
        t(`title.stepOne`)
      ) : step === EStep.SECOND_STEP ? (
        <></>
      ) : (
        step === EStep.THIRD_STEP && (
          <Typography variant="body3" weight={800}>
            {t(`title.stepThree`)}
          </Typography>
        )
      )}
    </SC.ModalDescriptionContainer>
  );

  async function onSubmit(formData: TAssignmentForm) {
    try {
      await submit(formData);

      !error && setStep(step + 1);
    } catch (error) {
      console.error(`Error on submit: ${error}`);
    } finally {
      setNumberOfAttempts(numberOfAttempts + 1);
    }
  }

  useEffect(() => {
    setValue('contracts', constractSignIds);
  }, [constractSignIds]);

  const renderHeaderSubtitle = () => {
    const [doubts, link] = t(`caption.stepTwo.doubts`).split(',');

    return (
      <SC.ModalDescriptionContainer>
        <PlayerModal open={videoOpen} onClose={() => setVideoOpen(false)} />
        {step === EStep.FIRST_STEP ? (
          <>
            <SC.FormFieldTypography variant="body3">{t(`caption.stepOne.welcome`)}</SC.FormFieldTypography>
            <Typography variant="body3">
              <Trans
                i18nKey={t(`caption.stepOne.description.partOne`)}
                values={{
                  quickQuestions: t(`caption.stepOne.description.quickQuestions`),
                }}
                components={{ b: <b /> }}
              />
              {t(`caption.stepOne.description.partTwo`)}
            </Typography>
          </>
        ) : step === EStep.SECOND_STEP ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <SC.FormFieldContainer>
              <SC.FormFieldTypography variant="body3" style={{ textAlign: 'left' }} weight={700}>
                {t(`caption.stepTwo.options.budgeLabel`)}
              </SC.FormFieldTypography>
              <Controller
                key={'expense.expectedValue'}
                name={'expense.expectedValue'}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t(`caption.stepTwo.options.requiredField`),
                  },
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FieldFactory
                      {...field}
                      isLoading={false}
                      fieldType={EFieldType.OPTIONS}
                      label={t(`caption.stepTwo.labels.default`)}
                      onChange={data => {
                        clearErrors('monthlyValue');
                        field.onChange(Number(data));
                      }}
                      error={Boolean(error)}
                      helperText={error?.message}
                      allowsEditing
                      options={
                        budgetOptions?.map(budgetOptions => ({
                          label: budgetOptions.label,
                          value: String(budgetOptions.value),
                        })) || []
                      }
                    />
                  );
                }}
              />
            </SC.FormFieldContainer>

            <SC.FormFieldContainer>
              <SC.FormFieldTypography variant="body3" style={{ textAlign: 'left' }} weight={700}>
                {t(`caption.stepTwo.options.personLabel`)}
              </SC.FormFieldTypography>
              <Controller
                key={'expense.expenseAdminManager.currentUser'}
                name={'expense.adminManager.currentUser'}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t(`caption.stepTwo.options.requiredField`),
                  },
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FieldFactory
                      {...field}
                      isLoading={false}
                      fieldType={EFieldType.OPTIONS}
                      label={t(`caption.stepTwo.labels.${field.value !== undefined ? 'selected' : 'default'}`)}
                      onChange={data => {
                        const hasValue = typeof data === 'string';
                        const value = hasValue ? data === 'me' : undefined;
                        Boolean(error) && clearErrors('expenseAdminManager.currentUser');
                        setShowEmailField(hasValue ? !value : value);
                        value || (value === undefined && setValue('expenseAdminManager.email', undefined));
                        field.onChange(value);
                      }}
                      error={Boolean(error)}
                      helperText={error?.message}
                      allowsEditing
                      value={field?.value !== undefined ? (field?.value ? 'me' : 'other') : field.value}
                      options={
                        personOptions?.map(personOption => ({
                          label: personOption.label,
                          value: personOption.value,
                        })) || []
                      }
                    />
                  );
                }}
              />
            </SC.FormFieldContainer>

            {showEmailField && (
              <SC.FormFieldContainer>
                <SC.FormFieldTypography variant="body3" style={{ textAlign: 'left' }} weight={700}>
                  {t(`caption.stepTwo.labels.email`)}
                </SC.FormFieldTypography>
                <Controller
                  key={'expense.adminManager.email'}
                  name={'expense.adminManager.email'}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t(`caption.stepTwo.options.requiredField`),
                    },
                  }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <FieldFactory
                        {...field}
                        isLoading={false}
                        fieldType={EFieldType.TEXT}
                        label={t(`caption.stepTwo.labels.emailField`)}
                        rowsTextArea={1}
                        onChange={data => {
                          Boolean(error) && clearErrors('expenseAdminManager.email');
                          field.onChange(data);
                        }}
                        error={Boolean(error)}
                        helperText={error?.message}
                        allowsEditing
                        value={field.value ?? ''}
                      />
                    );
                  }}
                />
              </SC.FormFieldContainer>
            )}

            <SC.FormFieldContainer>
              <SC.FormFieldTypography variant="body3" style={{ textAlign: 'left' }} weight={700}>
                {t(`caption.stepTwo.options.deadlineExpectationLabel`)}
              </SC.FormFieldTypography>
              <Controller
                key={'expense.expectedStarting'}
                name={'expense.expectedStarting'}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t(`caption.stepTwo.options.requiredField`),
                  },
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FieldFactory
                      {...field}
                      isLoading={false}
                      fieldType={EFieldType.OPTIONS}
                      label={t(`caption.stepTwo.labels.${field.value ? 'selected' : 'default'}`)}
                      onChange={data => {
                        Boolean(error) && clearErrors('expectedStarting');
                        field.onChange(Number(data));
                      }}
                      error={Boolean(error)}
                      helperText={error?.message}
                      allowsEditing
                      options={
                        deadlineExpectation?.map(deadline => ({
                          label: deadline.label,
                          value: String(deadline.value),
                        })) || []
                      }
                    />
                  );
                }}
              />
            </SC.FormFieldContainer>
          </form>
        ) : (
          step === EStep.THIRD_STEP && (
            <>
              <Typography variant="body3">{t(`caption.stepTwo.attention`)}</Typography>
              <SC.List>
                <li>
                  <Typography variant="body3">{t(`caption.stepTwo.addEmployee`)}</Typography>
                </li>
                <li>
                  <Typography variant="body3">
                    {`${doubts}, `}
                    <LinkButton variant="default" onClick={() => setVideoOpen(true)}>
                      {link}
                    </LinkButton>
                  </Typography>
                </li>
              </SC.List>
            </>
          )
        )}
      </SC.ModalDescriptionContainer>
    );
  };

  const renderFooterButtons = () => {
    if (!isAdmin) {
      return (
        <SC.ModalButtonContainer>
          <Button size="large" variant="primary" onClick={onAction}>
            {t(`modalButton.start`)}
          </Button>
        </SC.ModalButtonContainer>
      );
    }

    return (
      <SC.ModalButtonContainer>
        {step === EStep.FIRST_STEP ? (
          <Button size="large" variant="primary" onClick={() => setStep(!isSelfAcquisition ? step + 1 : step + 2)}>
            {t(`modalButton.next`)}
          </Button>
        ) : step === EStep.SECOND_STEP ? (
          <Button size="large" variant="primary" onClick={handleSubmit(onSubmit)} loading={isLoading}>
            {t(`modalButton.confirm`)}
          </Button>
        ) : (
          <>
            <div>
              <LinkButton variant="default" onClick={onAction}>
                {t(`modalButton.skip`)}
              </LinkButton>
            </div>
            <Button size="large" variant="primary" onClick={() => window.open('/settings/permissions', '_top')}>
              {t(`modalButton.reviewPermission`)}
            </Button>
          </>
        )}
      </SC.ModalButtonContainer>
    );
  };

  const getHeaderIconLabel = (step: number): string => {
    const key =
      step === EStep.FIRST_STEP
        ? 'stepOne'
        : step === EStep.SECOND_STEP
        ? 'stepTwo'
        : step === EStep.THIRD_STEP
        ? 'stepThree'
        : '';
    return t(`labelIcon.${key}`) + (step === EStep.FIRST_STEP ? `, ${userAuth?.attributes?.name}!` : '');
  };

  const getHeaderIcon = (step: number) => {
    switch (step) {
      case EStep.FIRST_STEP:
        return 'IconCheck';
      case EStep.SECOND_STEP:
        return null;
      default:
        return 'IconAlertSmall';
    }
  };

  return (
    <SC.StyledContractModal
      headerIcon={getHeaderIcon(step)}
      headerIconLabel={getHeaderIconLabel(step)}
      headerTitle={renderHeaderTitle()}
      open={open}
      footerCustom={renderFooterButtons()}
      onCloseClick={onClose}
      headerClassName="contract-modal-header"
      bodyClassName="contract-modal-body">
      {renderHeaderSubtitle()}
    </SC.StyledContractModal>
  );
}
