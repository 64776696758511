import { useContext, useState } from 'react';
import { SubscriptionContext } from '@containers/Subscription/context';
import { useContractsHelper } from '@containers/Subscription/hooks/useContractsHelper';
import { IConstractSign } from '@containers/Subscription/hooks/useSignContract';
import { fetchActiveModules } from '@flash-tecnologia/expense-web-activation-utils';
import { expensesCustomizationsBaseURL } from '@shared/constant';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';

type Props = {
  onSignSuccess?: () => void;
};

export function useCompletionRegistration(props?: Props) {
  const { onSignContract, isSigning } = useContext(SubscriptionContext);
  const [constractsSignIds, setConstractsSignIds] = useState<IConstractSign['contracts']>([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);

  const { companyId } = useContext(SessionContext);

  const { isLoading } = useContractsHelper(shouldFetch);

  const [isNewSAAS, setIsNewSAAS] = useState(false);

  async function handleSignContract() {
    try {
      const response = await onSignContract();

      if (response.isNewSAAS) {
        fetchActiveModules(companyId);
        setIsNewSAAS(true);
      }

      response.contracts && setConstractsSignIds(response.contracts);

      setShouldFetch(true);
      setShowSuccessModal(true);

      props?.onSignSuccess?.();
    } catch (err) {
      setShowSuccessModal(false);
    }
  }

  function onFinishSubscription() {
    if (isNewSAAS) {
      return window.open('/expense-dashboard', '_top');
    }

    return window.open(expensesCustomizationsBaseURL, '_top');
  }

  return {
    isLoadingAcceptance: isSigning || isLoading,
    showSuccessModal,
    constractsSignIds,
    handleSignContract,
    onFinishSubscription,
    isNewSAAS,
  };
}
