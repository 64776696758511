import styled from 'styled-components';

export const IconContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;
