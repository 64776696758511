import React from "react";

import { Container, Content } from "./styled";

type FocusedContainerProps = {
  children: React.ReactNode;
  header?: JSX.Element;
  footer?: JSX.Element;
};

export const FocusedContainer = ({ header, children, footer }: FocusedContainerProps) => {
  return (
    <Container>
      {header}
      <Content>{children}</Content>
      {footer}
    </Container>
  );
};
