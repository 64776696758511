import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Error } from "@components/Error";
import {
  Breadcrumbs,
  PageContainer,
  Skeleton,
  Tab,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";
import { trpc } from "@api/client";
import {
  Header,
  MonitoringDataGrid,
  PeopleDataGrid,
  Detail,
} from "./components";
import { Divider } from "@components/v2";
import { CarouselCards } from "@components/CarouselCards";
import { StatisticRow } from "@components/StatisticRow";
import FirstTab from "./tabs/FirstTab";

export default () => {
  const navigate = useNavigate();
  const { id = "", occurenceId = "" } = useParams();
  const { data: engagement, isFetching: getEngagementByIdLoading } =
    trpc.getEngagementById.useQuery(
      {
        id: id,
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        onSuccess: (e: any) => {
          if (e?.code === "EMPLOYEE_NOT_ENGAGEMENT_OWNER_ERROR") {
            alert(
              "Você não tem permissão para visualizar os detalhes desta pesquisa."
            );
          }
        },
        onError: (e) => {
          alert("Erro ao buscar pesquisa, tente novamente mais tarde.");
          console.log("err: ", e);
        },
      }
    );

  const {
    data: occurrences,
    isFetching: getRecurrenceByIdLoading,
    refetch,
  } = trpc?.getRecurrenceById.useQuery(
    {
      id,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (e: any) => {
        alert("Ocorreu um erro, tente novamente mais tarde.");
        console.log("err: ", e);
      },
    }
  );

  const {
    data: employeesMonitorings,
    isFetching: getEmployeeMonitoringsByEngagementIdLoading,
  } = trpc?.getEmployeeMonitoringsByEngagementId.useQuery(
    { id: occurenceId },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (e) => setData(e),
      onError: (e) => {
        alert("Erro ao buscar destinatários, tente novamente mais tarde.");
        console.log("err: ", e);
      },
    }
  );

  const occurrence = occurrences?.find(
    (occurrence) => occurrence._id === occurenceId
  );

  console.log({ occurrences });

  // console.log({ employees });

  const { data: insights, isFetching: getSurveyInsightsLoading } =
    trpc?.getSurveyInsights.useQuery(
      { id: occurenceId },
      {
        retry: false,
        refetchOnWindowFocus: false,
        onError: (e) => {
          alert("Erro ao buscar insights, tente novamente mais tarde.");
          console.log("err: ", e);
        },
      }
    );

  const totalAnswers = employeesMonitorings?.filter(
    (e) =>
      e?.monitorings?.filter(
        (m) => m?.type && ["enps", "react"]?.includes(m?.type) && !m?.draft
      )?.length
  )?.length;

  const [data, setData] = useState<typeof employeesMonitorings>([]);

  if (getEmployeeMonitoringsByEngagementIdLoading || getSurveyInsightsLoading)
    return <Skeleton variant="rectangular" width="100%" height="100%" />;

  if ((engagement as any)?.code === "EMPLOYEE_NOT_ENGAGEMENT_OWNER_ERROR")
    return (
      <Error
        title="Você não tem permissão para acessar os detalhes"
        description="Se achar que isso é um erro, entre em contato com seu gestor."
        buttonLabel="Voltar ao Início"
        buttonCallback={() => navigate(-1)}
      />
    );

  return (
    <PageContainer>
      {occurrence?.engagementOrigin || occurrence?.recurrence?.status ? (
        <Breadcrumbs
          style={{ marginTop: "32px" }}
          separator=">"
          breadcrumbs={[
            <Link to="/engagement/survey">Gerenciar pesquisas</Link>,
            <Link to={`/engagement/survey/${id}`} onClick={() => navigate(-1)}>
              Detalhe da pesquisa
            </Link>,
            <Link to={`/engagement/survey/${id}`}>Detalhe do envio</Link>,
          ]}
        />
      ) : (
        <Breadcrumbs
          separator=">"
          breadcrumbs={[
            <Link to="/engagement/survey">Gerenciar pesquisas</Link>,
            <Link to={`/engagement/survey/${id}`}>Detalhe da pesquisa</Link>,
          ]}
        />
      )}
      <Header
        refetch={refetch}
        pendingCount={
          (occurrence?.employees?.length || 0) - (totalAnswers || 0)
        }
        name={occurrence?.name || ""}
        status={occurrence?.status || ""}
        data={
          occurrence
            ? {
                ...occurrence,
                notifications: {
                  email: occurrence?.notifications?.email || false,
                  push: occurrence?.notifications?.push || false,
                  reminder: {
                    enabled:
                      occurrence?.notifications?.reminder?.enabled || false,
                    minRate: occurrence?.notifications?.reminder?.minRate || 0,
                    lastSentDate: occurrence?.notifications?.reminder
                      ?.lastSentDate
                      ? new Date(
                          occurrence?.notifications?.reminder?.lastSentDate
                        )
                      : undefined,
                  },
                },
                createdAt: occurrence?.createdAt
                  ? new Date(occurrence?.createdAt)
                  : undefined,
                updatedAt: occurrence?.updatedAt
                  ? new Date(occurrence?.updatedAt)
                  : undefined,
                sendDate: occurrence?.sendDate
                  ? new Date(occurrence?.sendDate)
                  : undefined,
              }
            : undefined
        }
      />

      <Divider spacing="s3" />
      <CarouselCards
        cards={[
          {
            title: "Total de pessoas",
            icon: "IconUser",
            value: insights?.recipients || 0,
          },
          {
            title: "Respostas",
            icon: "IconMessage",
            value: insights?.answerRate || 0,
            subValue: insights?.answers || 0,
          },

          {
            title: "Taxa de visualização",
            icon: "IconEye",
            value: insights?.viewRate || 0,
            subValue: insights?.views,
          },
          {
            title: "Taxa de resposta",
            icon: "IconGraph",
            value: insights?.answerRate || 0,
            subValue: insights?.answers,
          },
          {
            title: "Perguntas",
            icon: "IconQuestionMark",
            value: insights?.questions || 0,
          },
        ]}
      />
      <Divider spacing="xs2" />
      <StatisticRow
        data={[
          {
            label: "Criado em",
            value: occurrence?.createdAt
              ? dayjs(occurrence?.createdAt)?.format("DD/MM/YYYY")
              : "-",
          },
          {
            label: "Enviado em",
            value: occurrence?.sendDate
              ? dayjs(occurrence?.sendDate)?.format("DD/MM/YYYY")
              : "-",
          },
          {
            label: "Prazo de respostas",
            value: occurrence?.interactionDeadline
              ? dayjs(occurrence?.interactionDeadline)?.format("DD/MM/YYYY")
              : "-",
          },
        ]}
      />
      <Divider spacing="xs2" />
      <Tab
        tabItens={[
          {
            label: "Análise",
            component: (
              <FirstTab
                occurrences={occurrences}
                employeesMonitorings={employeesMonitorings}
              />
            ),
          },
          {
            label: `Respostas (${totalAnswers})`,
            component: (
              <MonitoringDataGrid
                empty={
                  !employeesMonitorings?.filter(
                    (e) =>
                      e?.monitorings?.filter(
                        (m) =>
                          m?.type &&
                          ["enps", "react"]?.includes(m?.type) &&
                          !m?.draft
                      )?.length
                  )?.length
                }
                engagementId={id || ""}
                data={
                  data
                    ? data?.filter(
                        (e) =>
                          e?.monitorings?.filter(
                            (m) =>
                              m?.type &&
                              ["enps", "react"]?.includes(m?.type) &&
                              !m?.draft
                          )?.length
                      )
                    : []
                }
                employees={employeesMonitorings || []}
                engagement={{
                  sections: occurrence?.sections,
                  anonymous: occurrence?.anonymous,
                }}
                onSearch={(e) =>
                  setData(
                    employeesMonitorings?.filter(({ name }) =>
                      name?.match(RegExp(e, "ig"))
                    ) || []
                  )
                }
              />
            ),
          },
          {
            label: `Pessoas (${insights?.selectedEmployees?.length || 0})`,
            component: (
              <PeopleDataGrid
                selectedEmployees={insights?.selectedEmployees || []}
                engagementId={id}
                data={data || []}
                anonymous={occurrence?.anonymous}
                onSearch={(e) =>
                  setData(
                    employeesMonitorings?.filter(({ name }) =>
                      name?.match(RegExp(e, "ig"))
                    ) || []
                  )
                }
              />
            ),
          },
          {
            label: "Detalhe",
            component: occurrence ? (
              <Detail
                data={{
                  ...occurrence,
                  notifications: {
                    email: occurrence?.notifications?.email || false,
                    push: occurrence?.notifications?.push || false,
                    reminder: {
                      enabled:
                        occurrence?.notifications?.reminder?.enabled || false,
                      minRate:
                        occurrence?.notifications?.reminder?.minRate || 0,
                    },
                  },
                  recurrence: undefined,
                  createdBy: occurrence?.createdBy,
                  createdAt: occurrence?.createdAt
                    ? new Date(occurrence?.createdAt)
                    : undefined,
                  updatedAt: occurrence?.updatedAt
                    ? new Date(occurrence?.updatedAt)
                    : undefined,
                  sendDate: occurrence?.sendDate
                    ? new Date(occurrence?.sendDate)
                    : undefined,
                }}
              />
            ) : (
              <></>
            ),
          },
        ]}
      />
    </PageContainer>
  );
};
