import styled from 'styled-components';
import {
  Icons,
  SelectField,
  TextField,
} from '@flash-tecnologia/hros-web-ui-v2';

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 23px;
  padding: 0 26px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;
const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  max-width: 326px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    max-width: 500px;
  }
`;
const RightColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 150px;
  gap: 40px;
`;
const Card = styled.div`
  width: 100%;
  max-width: 981px;

  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: 40px;
  border-radius: 10px;

  @media screen and (max-width: 400px) {
    padding: 40px 20px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
`;
const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: -9px;
`;
const LeftTable = styled.div<{ isHeader?: Boolean }>`
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 8px;

  ${({ isHeader }) => {
    if (isHeader) return `padding-top: 0px;  padding-bottom:0px`;
    if (!isHeader) return `padding-top: 24px;  padding-bottom:48px`;
  }}
`;
const RightTable = styled.div`
  width: 40%;
  display: flex;
`;
const FlexContainer = styled.div`
  display: flex;

  gap: 40px;

  @media screen and (max-width: 505px) {
    flex-direction: column;
    gap: 0px;
  }
`;

const StyledIcon = styled(Icons)<{ setColor?: string }>`
  && {
    ${({ setColor, theme }) => {
      let color = '';
      switch (setColor) {
        case 'neutral50':
          color = theme.colors.neutral[50];
          break;
        case 'error40':
          color = theme.colors.feedback.error[40];
          break;
        case 'error50':
          color = theme.colors.feedback.error[50];
          break;
        case 'primary':
          color = theme.colors.primary;
          break;
      }
      return `color: ${color}`;
    }};
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 10px;
  padding-bottom: 17px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;
const LeftDiv = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;

  padding-top: 16px;
  padding-bottom: 32px;

  @media screen and (max-width: 1150px) and (min-width: 1001px) {
    flex-direction: column;
  }
  @media screen and (max-width: 680px) {
    flex-direction: column;
  }
`;
const StyledTextField = styled(TextField)`
  && {
    width: 100%;
    max-width: 320px;
  }
`;
const StyledTSelect = styled(SelectField)`
  && {
    div {
      display: flex;
      width: 100%;
      max-width: 320px;
      justify-content: flex-end;
    }
  }
`;
const SelectContainer = styled.div`
  width: 100%;
  max-width: 320px;
  margin-left: -9px;
`;

const InputContainer = styled.div`
  display: flex;
  gap: 8px;

  @media screen and (max-width: 1250px) and (min-width: 1001px) {
    flex-direction: column;
  }
  @media screen and (max-width: 805px) {
    flex-direction: column;
  }
`;
const BodyContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export {
  MainContainer,
  LeftColumn,
  RightColumn,
  Card,
  TextContainer,
  ContentContainer,
  FlexContainer,
  StyledIcon,
  StyledTextField,
  StyledTSelect,
  SelectContainer,
  LeftDiv,
  InputContainer,
  RightTable,
  LeftTable,
  HeaderContainer,
  BodyContainer,
};
