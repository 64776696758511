import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.form`
  display: grid;
  grid-template-rows: repeat(3, min-content);
  padding-top: ${(props) => props.theme.spacings.xs1};
  padding-bottom: ${(props) => props.theme.spacings.xs1};
  gap: ${(props) => props.theme.spacings.xs};
`;

export const StyledTypography = styled(Typography)`
  span {
    color: ${({ theme }) => theme.colors.feedback.error[40]};
  }
`;
