import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
.data-grid-custom-select div.MuiSelect-select {
  padding: 9px 26px 9px 16px  !important;
}

.tag-checkbox-filter-menu {
  ul button {
    text-align: left;
  }
}
`;
