import { Accordion as AccordionComponent } from "$atoms";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const ListItemTrailing = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  align-items: end;
`;

export const PendingList = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
`;

export const PendingItemCard = styled.div`
  padding: ${(props) => props.theme.spacings.xs2} 0px;
`;
export const PendingItemTitle = styled(Typography)`
  max-width: 300px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ListItemIcon = styled.span`
  padding-bottom: 3px;
`;

export const AccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacings.xs2};
  align-items: center;
`;

export const Container = styled.div`
  padding-left: 83px;
  padding-right: 83px;
  overflow: hidden scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacings.s};
  height: 100%;
  width: 100%;
`;

export const FormContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacings.xs};
  overflow: hidden scroll;
`;

export const Card = styled.div`
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.colors.neutral[90]};
`;

export const CardTile = styled.div<{ bottomBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacings.xs3};
  border-bottom-width: ${({ bottomBorder }) => (bottomBorder ? "1px" : undefined)};
  border-bottom-style: solid;
  border-bottom-color: ${(props) => props.theme.colors.neutral[90]};
  padding-top: 20px;
  padding-bottom: ${(props) => props.theme.spacings.xs2};
  padding-left: 20px;
  padding-right: 20px;
`;
export const Form = styled.div`
  width: 100%;
  padding: 20px 16px 20px 16px;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacings.xs2};
`;

export const InfoCardCaption = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacings.xs4};
  align-items: center;
`;

export const CardTitle = styled(Typography)`
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Accordion = styled(AccordionComponent)`
  display: inline-table;
`;

export const AccordionFooter = styled(Typography)``;

export const ExpenseAmmount = styled(Typography)`
  min-width: ${(props) => props.theme.spacings.xl4};
`;

export const BalanceChangeButtonText = styled.span`
  min-width: 83px;
`;

export const WarningCard = styled.div<{ color: string }>`
  display: flex;
  padding: ${(props) => props.theme.spacings.xs2};
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border-radius: ${(props) => props.theme.spacings.xs4};
  border: 1px solid ${({ color }) => color};
`;

export const WarningCardSubtitle = styled.div`
  max-width: 450px;
`;
