import styled, { css } from "styled-components"
import { KanbanColumnType } from ".."

const HEADER_HEIGHT = "140px"
export interface HeaderWrapperProps {
  columnType: KanbanColumnType
}

export const HeaderWrapper = styled.div<HeaderWrapperProps>`
  width: 100%;
  height: ${HEADER_HEIGHT};
  margin-bottom: ${({ theme }) => theme.spacings.xs4};
  padding: ${({ theme }) => `${theme.spacings.xs3} ${theme.spacings.xs2}`};

  border-width: ${({ theme }) =>
    `${theme.borders.width.xl} ${theme.borders.width.xs2} ${theme.borders.width.xs2} ${theme.borders.width.xs2}`};
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.secondary[80]};
  border-radius: ${({ theme }) => theme.borders.radius.s};

  ${({ columnType, theme }) => {
    return (
      {
        [KanbanColumnType.Primary]: "",
        [KanbanColumnType.Neutral]: css`
          background-color: ${theme.colors.neutral[95]};
          border-color: ${theme.colors.neutral[70]};
        `,
      }[columnType] ?? ""
    )
  }}
  transition: background-color 0.2s ease;
`
