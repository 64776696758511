import { useContext } from 'react';
import { useSelectedTab } from '@containers/Customizations/hooks/useSelectedTab';
import { useCheckActiveModule } from '@flash-tecnologia/expense-web-activation-utils';
import { Tab } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';
import { usePlatformPermissions } from '@shared/hooks/permissions/systemPermissions/usePlatformPermissions';
import { useUnleashPermissions } from '@shared/hooks/permissions/unleash/useUnleashPermissions';
import { EAuthZPermission } from '@shared/types/authZPermission';

import { CategoriesTab } from './CategoriesTab';
import CorporateCardCustomizationsTab from './components/CorporateCardCustomizationsTab';
import { ExpenseCustomizationTabItem } from './ExpenseCustomizationTabItem';
import { PayablesTabItems } from './PayAblesTabItems';
import * as SC from './styled';
import { TravelTabItem } from './TravelTabItem';

export enum ETabCustomization {
  GENERAL_SETTINGS = 'generalSettings',
  FORMS = 'forms',
  REPORT = 'reports',
  CATEGORIES = 'categories',
  POLICIES = 'policies',
  APPROVALS = 'approvals',
  ADVANCES = 'advances',
  DELEGATION = 'delegation',
}

type TTab = {
  id: 'expense' | 'supplier' | 'corporate-card' | 'travel' | 'categories';
  label: string;
  component: React.ReactNode;
};

export const CustomizationsPreview = () => {
  const { t } = useTranslation();
  const { checkPlatformPermission } = usePlatformPermissions();
  const corporateCardCustomizationsTab = CorporateCardCustomizationsTab();
  const showPayablesTab = checkPlatformPermission(EAuthZPermission.CUSTOMIZATION_PAYABLES_VIEW);
  const { isDisabledExpense } = useContext(SessionContext);
  const { permissions } = useUnleashPermissions();
  const tabs: TTab[] = [];

  const isLifecycleEnabled = useCheckActiveModule('lifecycle');

  const showCategoriesTab =
    checkPlatformPermission(EAuthZPermission.CUSTOMIZATION_CATEGORIES_VIEW) &&
    checkPlatformPermission(EAuthZPermission.CUSTOMIZATION_CATEGORIES_ACTION);

  if (!isDisabledExpense) {
    tabs.push({
      id: 'expense',
      component: <ExpenseCustomizationTabItem />,
      label: t('organisms.customizationsPreview.tabTitle.expense'),
    });

    showPayablesTab &&
      tabs.push({
        id: 'supplier',
        component: <PayablesTabItems />,
        label: t('organisms.customizationsPreview.tabTitle.companyPayables'),
      });

    // TODO: put this condition outside of the if when travel be active again together new saas
    if (permissions.enabledTravelPreferences) {
      tabs.push({
        id: 'travel',
        component: <TravelTabItem />,
        label: t('organisms.customizationsPreview.tabTitle.corporateTravel'),
      });
    }
  }

  if (isLifecycleEnabled) {
    if (showCategoriesTab) {
      tabs.push({
        id: 'categories',
        component: <CategoriesTab />,
        label: t('organisms.customizationsPreview.tabTitle.categories'),
      });
    }
  }

  if (corporateCardCustomizationsTab.show) {
    tabs.push({
      ...corporateCardCustomizationsTab,
      id: 'corporate-card',
      label: isLifecycleEnabled
        ? t('organisms.customizationsPreview.tabTitle.policies')
        : t('organisms.customizationsPreview.tabTitle.corporateCard'),
    });
  }

  const { selectedTabIndex, onTabChange } = useSelectedTab(tabs);

  return (
    <SC.Container>
      <Tab variant="primary" selected={selectedTabIndex} onTabChanged={onTabChange} tabItens={tabs} />
    </SC.Container>
  );
};
