import {
  SelectField as FlashSelectField,
  SelectFieldProps as FlashSelectFieldProps,
} from "@flash-tecnologia/hros-web-ui-v2";
import { CustomSelectFieldStyle, DefaultSelectFieldStyle } from "./styled";

type SelectFieldProps = FlashSelectFieldProps & {
  /**
   * Defines the style variant for the select field.
   * - `"default"`: Applies the default select field styling.
   * - `"custom"`: Applies custom styling to the select field.
   */
  textVariant?: "default" | "custom";
};

export const SelectField = ({ textVariant = "default", ...props }: SelectFieldProps) => {
  const SelectFieldComponent = textVariant === "custom" ? CustomSelectFieldStyle : DefaultSelectFieldStyle;

  return (
    <SelectFieldComponent>
      <FlashSelectField {...props}>{props.children}</FlashSelectField>
    </SelectFieldComponent>
  );
};
