import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledContainer = styled.div<{ enabled: boolean }>`
  padding: 16px;
  border: solid 1px ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: ${({ enabled }) => (enabled ? "auto" : "none")};
  cursor: pointer;
  filter: ${({ enabled }) =>
    enabled ? "none" : "grayscale(100%) opacity(50%)"};
`;

export const StyledRadioContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledRadioText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  transform: translateY(1px);
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
