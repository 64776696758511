import { trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

export function useReproveRequest() {
  const context = trpc.useContext();

  const { isLoading: loading, mutate } =
    trpc.corporateCard.requestBalance.cancel.useMutation({
      onSuccess: () => {
        context.corporateCard.requestBalance.search.invalidate();
        dispatchToast({
          type: 'success',
          content: 'Ação realizada com sucesso!',
        });
      },
      onError: (error) => {
        dispatchToast({
          type: 'error',
          content: error.message,
        });
      },
    });

  return {
    mutate,
    loading,
  };
}
