import BasePath from '@frontend/routes/BasePath';
import RouterService from '@frontend/services/RouterService';

import CheckIsCorporateCard from '@frontend/components/CheckIsCorporateCard';
import LazyComponent from '@frontend/components/LazyComponent';
import { Route } from 'react-router-dom';
import { RouteComponent_CorporateCardStatement } from './CorporateCard';
import { RouteComponent_ExternalCardStatement } from './ExternalCards';
import {
  RouteComponent_FlashCardStatement,
  RouteComponent_FlashCardStatement_Index,
} from './FlashCard';
import HasExternalCard from './components/HasExternalCard';

export const ROUTE_UserStatement = RouterService.create(
  `${BasePath.relativePath}/statement`,
);

const Statement = () => <LazyComponent factory={() => import('./Statement')} />;

export const RouteComponent_UserStatement = (
  <Route path={ROUTE_UserStatement.path} element={<Statement />}>
    {RouteComponent_FlashCardStatement_Index}
    {RouteComponent_FlashCardStatement}
    <Route element={<CheckIsCorporateCard />}>
      {RouteComponent_CorporateCardStatement}
      <Route element={<HasExternalCard />}>
        {RouteComponent_ExternalCardStatement}
      </Route>
    </Route>
  </Route>
);
