import { trpc } from "@api/client"
import { ResignationStatus } from "@customTypes/resignation"
import { TagProps } from "@flash-tecnologia/hros-web-ui-v2"
import i18n from "@i18n"
import { KanbanColumnType } from "@organisms/Kanban/Column"
import { dayjs } from "@utils/dayjs"
import dispatchToast from "@utils/dispatchToast"
import { useEffect } from "react"
import { DropResult } from "react-beautiful-dnd"
import { useTranslation } from "react-i18next"
import { ResignationCardProps } from "../ResignationCard"
import { mapResignationStatusToTagProps } from "../utils/mapResignationStatusToTagProps"
import { Steps } from "server/src/types/resignation"
import { Card, FlowColumn } from "@customTypes/flow"

export interface KanbanFlowCard extends ResignationCardProps {
  id: string
}

export interface KanbanFlowColumn {
  id: string
  title: string
  description: string
  columnType: KanbanColumnType
  cards: KanbanFlowCard[]
}

function orderAndFixCardPositions(cards: Card[]): Card[] {
  const orderedResignations = cards.sort((a, b) => a.card.position - b.card.position)
  return orderedResignations.map((card, index) => ({
    ...card,
    card: {
      position: index,
    },
  }))
}

function insertAndFixCardPosition(cards: Card[], position: number, cardToInsert: Card): Card[] {
  const orderedCards = cards.sort((a, b) => a.card.position - b.card.position)
  orderedCards.splice(position, 0, cardToInsert)
  return orderedCards.map((card, index) => ({
    ...card,
    card: {
      position: index,
    },
  }))
}

function moveAndFixCardPosition(cards: Card[], position: number, cardToMove: Card): Card[] {
  const orderedCards = cards.sort((a, b) => a.card.position - b.card.position)
  const cardIndex = orderedCards.findIndex((card) => card.id === cardToMove.id)
  if (cardIndex === -1) throw new Error("Resignation not found in the current column")

  orderedCards.splice(cardIndex, 1)
  orderedCards.splice(position, 0, cardToMove)

  return orderedCards.map((card, index) => ({
    ...card,
    card: {
      position: index,
    },
  }))
}

const mapFlowColumnIdToKanbanColumnType: Partial<Record<Steps, KanbanColumnType>> = {
  [Steps.ARCHIVED]: KanbanColumnType.Neutral,
}

export function useResignationKanban() {
  const utils = trpc.useUtils()

  const [t] = useTranslation("translations", {
    keyPrefix: "page.home.kanban",
  })

  const { isLoading: isGetFlowLoading, data: flowColumns, error: getFlowError } = trpc.flow.getFlow.useQuery()

  const { mutateAsync: changePosition } = trpc.flow.changePosition.useMutation({
    onMutate: async ({ resignationId, position }) => {
      utils.flow.getFlow.cancel()

      const previousDataSnapshot = utils.flow.getFlow.getData()
      const { currentFlowColumn, currentCard } =
        previousDataSnapshot?.reduce<{
          currentFlowColumn?: FlowColumn
          currentCard?: Card
        }>((acc, flowColumn) => {
          const card = flowColumn.cards.find((card) => card.id === resignationId)
          if (card) {
            acc.currentFlowColumn = flowColumn
            acc.currentCard = card
          }
          return acc
        }, {}) ?? {}

      const updatedFlowColumns = previousDataSnapshot?.map((flowColumn) => {
        if (flowColumn.id === currentFlowColumn?.id && currentCard) {
          return {
            ...flowColumn,
            cards: moveAndFixCardPosition(flowColumn.cards, position, currentCard),
          }
        }
        return flowColumn
      })
      utils.flow.getFlow.setData(undefined, updatedFlowColumns)
      return { previousFlowColumns: previousDataSnapshot }
    },
    onError: (_, __, context) => {
      if (context?.previousFlowColumns) {
        utils.flow.getFlow.setData(undefined, context.previousFlowColumns)
      }
    },
    onSettled: () => {
      utils.flow.getFlow.invalidate()
    },
  })

  const { mutateAsync: changeStep, error: changeStepError } = trpc.flow.changeStep.useMutation({
    onMutate: async ({ resignationId, step, position }) => {
      utils.flow.getFlow.cancel()

      const previousDataSnapshot = utils.flow.getFlow.getData()
      const { currentFlowColumn, currentCard } =
        previousDataSnapshot?.reduce<{
          currentFlowColumn?: FlowColumn
          currentCard?: Card
        }>((acc, flowColumn) => {
          const card = flowColumn.cards.find((card) => card.id === resignationId)
          if (card) {
            acc.currentFlowColumn = flowColumn
            acc.currentCard = card
          }
          return acc
        }, {}) ?? {}

      const updatedFlowColumns = previousDataSnapshot?.map((flowColumn) => {
        if (flowColumn.id === currentFlowColumn?.id) {
          const withoutMovedCard = flowColumn.cards.filter((card) => card.id !== currentCard?.id)
          return {
            ...flowColumn,
            cards: orderAndFixCardPositions(withoutMovedCard),
          }
        }

        if (flowColumn.id === step && currentCard) {
          return {
            ...flowColumn,
            cards: insertAndFixCardPosition(flowColumn.cards, position, currentCard),
          }
        }
        return flowColumn
      })
      utils.flow.getFlow.setData(undefined, updatedFlowColumns)
      return { previousFlowColumns: previousDataSnapshot }
    },
    onError: (_, __, context) => {
      if (context?.previousFlowColumns) {
        utils.flow.getFlow.setData(undefined, context.previousFlowColumns)
      }
    },
    onSettled: () => {
      utils.flow.getFlow.invalidate()
    },
  })

  useEffect(() => {
    if (!!getFlowError) {
      dispatchToast({
        content: getFlowError.data?.userFriendlyError?.message ?? i18n.t("error.internalServerError"),
        type: "error",
      })
    }
  }, [getFlowError])

  useEffect(() => {
    if (!!changeStepError) {
      dispatchToast({
        content: changeStepError.data?.userFriendlyError?.message ?? i18n.t("error.internalServerError"),
        type: "error",
      })
    }
  }, [changeStepError])

  const handleKanbanDragEnd = (dropResult: DropResult) => {
    const source = dropResult.source
    const destination = dropResult.destination
    if (!destination) return

    const targetResignationId = dropResult.draggableId
    const nextColumn: Steps | undefined = (Object.values(Steps) as string[]).includes(destination.droppableId)
      ? (destination.droppableId as Steps)
      : undefined

    if (nextColumn && source.droppableId !== destination.droppableId) {
      changeStep({
        resignationId: targetResignationId,
        position: destination.index,
        step: nextColumn,
      })
      return
    }

    changePosition({
      resignationId: targetResignationId,
      position: destination?.index,
    })
  }

  const kanbanFlowColumns = flowColumns?.map<KanbanFlowColumn>((flowColumn) => {
    const orderedCards = flowColumn.cards.sort((a, b) => a.card.position - b.card.position)

    return {
      id: flowColumn.id,
      title: flowColumn.name,
      description: flowColumn.description,
      columnType: mapFlowColumnIdToKanbanColumnType[flowColumn.id] ?? KanbanColumnType.Primary,
      cards: orderedCards.map<KanbanFlowCard>((card) => {
        let tagProps: TagProps | undefined
        if (card.status === ResignationStatus.SIGNATURE_PENDING_SIGNATURE) {
          tagProps = mapResignationStatusToTagProps({
            status: ResignationStatus.SIGNATURE_PENDING_SIGNATURE,
            pendingSignaturesCount: 1,
          })
        } else {
          tagProps = mapResignationStatusToTagProps({ status: card.status })
        }

        return {
          id: card.id,
          bottomText: `${t("requestDateLabel")} ${dayjs(card.requestDate).format("DD/MM/YYYY")}`,
          subtitle: card.email ?? "",
          title: card.employeeName,
          name: card.employeeName,
          tagProps,
        }
      }),
    }
  })

  return {
    kanbanFlowColumns,
    isLoading: isGetFlowLoading,
    onKanbanDragEnd: handleKanbanDragEnd,
  }
}
