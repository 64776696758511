import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { createContext, useContext } from "react";
import { useForm, UseFormReturn } from "react-hook-form";

import { ExpenseStatus, TransactionStatus } from "$serverTypes";
import { getDateRange } from "$utils";

export type AdminStatementState = {
  filters: {
    status: ExpenseStatus[];
    transactionStatus: TransactionStatus[];
    categoryNanoIds: string[];
    dateRange: { from: Date; to: Date };
    valueRange: { max?: number; min?: number };
  };
  search: string;
  pagination: PaginationState;
};

const defaultValues: AdminStatementState = {
  filters: {
    status: [ExpenseStatus.PENDING_ACCOUNTING],
    transactionStatus: [],
    categoryNanoIds: [],
    dateRange: getDateRange(),
    valueRange: { min: undefined, max: undefined },
  },
  search: "",
  pagination: { pageNumber: 1, pageSize: 10 },
};

export type ISessionProviderProps = {
  children: React.ReactNode;
};

type AdminStatementContextProps = {
  methods: UseFormReturn<AdminStatementState>;
};

const AdminStatementContext = createContext<AdminStatementContextProps | undefined>(undefined);

export const AdminStatementProvider = ({ children }: ISessionProviderProps) => {
  const methods = useForm<AdminStatementState>({ defaultValues });

  return <AdminStatementContext.Provider value={{ methods }}>{children}</AdminStatementContext.Provider>;
};

export const useAdminStatementContext = () => {
  const context = useContext(AdminStatementContext);
  if (!context) {
    throw new Error("useAdminStatementContext must be used within an AdminStatementProvider");
  }
  return context;
};
