import { tableControllers } from '@flash-tecnologia/hros-web-ui-v2';
import DSTable from '@frontend/components/DSTable';
import SearchField from '@frontend/components/inputs/SearchField';
import DefaultModal from '@frontend/components/modals/DefaultModal';
import ModalService from '@frontend/services/ModalService';
import { useEmployeesTable } from './data/useEmployeesTable';

import * as SC from './styled';

type Props = {
  employees: {
    id: string;
    name: string;
    documentNumber: string;
  }[];
};

function EmployeesDetailsModal(props: Props) {
  const controller = ModalService.useModalController();
  const [search, setSearch] = tableControllers.useSearch(() => null, '');

  const table = useEmployeesTable(props.employees, search);

  return (
    <DefaultModal
      isOpen={controller.visible}
      onClose={controller.remove}
      title="Detalhes do pedido"
    >
      <SC.Container>
        <SearchField
          label="Buscar pessoa"
          onChange={(e) => setSearch(e.target.value ?? '')}
          size="md"
        />

        <SC.TableContainer>
          <DSTable
            loading={false}
            emptyState={{
              isFiltered: search.length > 0,
              emptyText: 'Nenhuma pessoa encontrada',
              filteredEmptyText: 'Nenhuma pessoa encontrada com essa busca',
            }}
            getHeaderGroups={table.getHeaderGroups}
            rows={table.rows}
            variant="soft"
          />
        </SC.TableContainer>
      </SC.Container>
    </DefaultModal>
  );
}

export default ModalService.create(EmployeesDetailsModal);
