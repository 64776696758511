import { PageContainer, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import { Header } from "./components";
import { StyledTabs } from "./styles";
import { useEffect, useMemo, useState } from "react";
import { trpc } from "src/api/client";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { Table } from "./components/Table";
import { ExportsDrawer } from "./components/ExportsDrawer";
import { ModalExport } from "./components/ModalExport";

export const ReportsPage = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState("");
  const [selectedModule, setSelectedModule] = useState("");

  const { selectedCompany } = useSelectedCompany();

  const { data, isLoading } = trpc.findModules.useQuery({
    companyId: selectedCompany.id,
  });

  const modules = useMemo(
    () =>
      data?.map((module) => ({
        title: module.name,
        id: module.id,
      })),
    [data]
  );

  useEffect(() => {
    if (modules && modules?.length > 0) {
      setSelectedModule(modules[0].id);
    }
  }, [modules]);

  const onCloseModal = () => {
    setOpenModal(false);
    setSelectedReport("");
  };

  return (
    <>
      <PageContainer>
        <Header openDrawer={() => setOpenDrawer(true)} />
        {isLoading && <Skeleton width="100%" height={400} animation="wave" />}
        {modules && (
          <StyledTabs
            onTabChanged={(index) => setSelectedModule(modules[index].id)}
            selected={modules.findIndex(
              (module) => module.id === selectedModule
            )}
            tabItens={modules?.map((module) => ({
              label: module.title,
              component: (
                <Table
                  moduleId={selectedModule}
                  openModal={(id) => {
                    setSelectedReport(id);
                    setOpenModal(true);
                  }}
                />
              ),
            }))}
          />
        )}
      </PageContainer>
      <ExportsDrawer open={openDrawer} onClose={() => setOpenDrawer(false)} />
      <ModalExport
        open={openModal}
        onClose={() => onCloseModal()}
        reportId={selectedReport}
      />
    </>
  );
};
