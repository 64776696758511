import styled from "styled-components";

export const Container = styled.div`
  overflow: hidden;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  padding-top: ${(props) => props.theme.spacings.s};
  padding-bottom: ${(props) => props.theme.spacings.s};
  overflow: hidden scroll;
  flex: 1;
`;
