import { Tag, TagProps } from "@flash-tecnologia/hros-web-ui-v2";

const statusMap: Record<string, string> = {
  ACTIVE: "Vigente",
  INACTIVE: "Inativo",
  WAITING_SIGNATURE: "Assinatura Pendente",
  CANCELLED: "Cancelado",
};

const variantMap: Record<string, TagProps["variant"]> = {
  ACTIVE: "success",
  INACTIVE: "disabled",
  WAITING_SIGNATURE: "yellow",
  CANCELLED: "disabled",
};

const ContractStatusTag = ({ status = "" }) => {
  const statusText = statusMap[status] || "";
  const variant = variantMap[status] || "disabled";

  if (!statusText) return null;

  return <Tag variant={variant}>{statusText}</Tag>;
};
export { ContractStatusTag };
