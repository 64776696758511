import { trpc } from '@api/client';

export const useAckInboxMessage = () => {
  const utils = trpc.useContext();
  const { isLoading, mutate } = trpc.ackInboxMessage.useMutation({
    onSuccess: async () => {
      await utils.getInbox.invalidate(undefined, { refetchType: 'all' });
      await utils.getUnacknowledgedInboxMessages.invalidate(undefined, {
        refetchType: 'all',
      });
    },
  });

  return { mutate, isLoading };
};
