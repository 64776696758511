import { trpc } from '@api/client';
import { dispatchToast } from '@utils';

export const useSignContract = () => {
  const { mutateAsync, isLoading } = trpc.signContract.useMutation({
    trpc: { context: { useCognitoToken: true } },
    onError: () => {
      dispatchToast({
        type: 'error',
        content: 'Ocorreu um erro. Por favor, tente novamente.',
      });
    },
  });

  return { signContract: mutateAsync, isLoading };
};
