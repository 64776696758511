import { trpc } from "$client";
import { Category } from "server/src/services/expense-lifecycle-service/expense-lifecycle-types";

export function useCategory() {
  const { data, isFetching, isLoading, refetch } = trpc.category.getCompanyCategories.useQuery();

  const rows: Category[] | undefined = data?.map((category) => {
    return {
      ...category,
    };
  });

  return {
    rows,
    isLoading: isLoading,
    isFetching: isFetching,
    refetch: async () => {
      await refetch();
    },
  };
}
