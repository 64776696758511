import { useMemo, useState } from "react";
import { cloneDeep } from "lodash-es";

import { dayjs } from "@flash-tecnologia/hros-web-ui-v2";

import { getIn } from "@utils";

import { SearchField } from "../SearchField";
import { TableFilters } from "../TableFilters";

import { TableProps } from "./types";

export const Table = ({
  loading,
  data = [],
  filters,
  searchPlaceholder,
  searchable,
  searchableField,
  tableGridHeader,
  tableGridRender,
  onCustomSearch,
  refetch,
}: TableProps) => {
  const [search, setSearch] = useState<string>("");

  const [filterStates, setFilterStates] = useState<{
    [key: string]: any[];
  }>({});

  const filteredData = useMemo(() => {
    if (!search && !Object.keys(filterStates)?.length) return data;

    let dataToFilter = cloneDeep(data);

    if (onCustomSearch) {
      return onCustomSearch({ data, search, filterStates });
    }

    if (search) {
      dataToFilter = dataToFilter?.filter((item) => {
        const searchItem = searchableField
          ? getIn(item, searchableField)
          : item?.name;
        return searchItem?.toLowerCase().includes(search?.toLowerCase());
      });
    }

    if (Object.keys(filterStates)?.length) {
      dataToFilter = dataToFilter?.filter((item) => {
        for (const key in filterStates) {
          const itemValue =
            item[key]?.constructor === Object ? item[key]?.name : item[key];

          if (itemValue === undefined) return false;

          if (Array.isArray(itemValue)) {
            if (!itemValue.some((el) => filterStates[key].includes(el))) {
              return false;
            }
          }

          if (filterStates[key][0]?.startDate) {
            return (
              dayjs(item[key]).isSameOrAfter(
                filterStates[key][0]?.startDate,
                "day"
              ) &&
              dayjs(item[key]).isSameOrBefore(
                filterStates[key][0]?.endDate,
                "day"
              )
            );
          }

          if (!filterStates[key].includes(itemValue)) return false;
        }
        return true;
      });
    }

    return dataToFilter;
  }, [data, search, filterStates, onCustomSearch]);

  const isFiltered = !!search || !!Object.keys(filterStates || {}).length;

  return (
    <div>
      {searchable && (
        <SearchField
          disabled={loading}
          onChange={setSearch}
          placeholder={searchPlaceholder}
          style={{ marginBottom: "32px" }}
        />
      )}

      {filters?.[0] && (
        <TableFilters
          disabled={loading}
          filters={filters}
          onFilter={setFilterStates}
        />
      )}

      {tableGridHeader &&
        tableGridHeader({
          data: filteredData,
          rawData: data,
          loading,
          isFiltered: isFiltered,
        })}

      {tableGridRender({
        data: filteredData,
        rawData: data,
        loading,
        isFiltered: isFiltered,
        refetch,
      })}
    </div>
  );
};
