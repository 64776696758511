import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { EmptyState as EmptyStateUI } from "@flash-tecnologia/hros-web-ui-v2";

import { cloneDeep } from "lodash-es";
import { Grid } from "@mui/material";

import { Lantern } from "@assets/index";

import { trpc } from "@api/client";

import { routes } from "@routes";

import { dispatchToast, StyledText } from "@utils";

import Banner from "@components/Banner";
import { Table } from "@components/Table";
import { BulkActionsGrid } from "./components/BulkActionsGrid";
import { BigNumbers } from "./components/BigNumbers";

import { EmptyStateArea, StyledLinkButton } from "./styled";

const EmptyState = () => {
  const navigate = useNavigate();

  return (
    <>
      <Banner
        type="info"
        icon="IconInfoCircle"
        title="Apenas os feedbacks associados a esse ciclo são listados aqui."
        subTitle="Para ver o restante dos feedbacks enviados e recebidos, clique na opção abaixo ou acesse a área de Feedbacks no menu principal."
        children={
          <StyledLinkButton
            variant="neutral"
            onClick={() => navigate(routes.PageMyFeedbacks)}
          >
            Ver todos os meus feedbacks
          </StyledLinkButton>
        }
        hasHideBanner={false}
        style={{ marginBottom: "24px" }}
      />

      <Grid
        container
        spacing={4}
        alignItems="stretch"
        style={{ marginBottom: "40px" }}
        sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
      >
        <Grid item xs={12} style={{ width: "100%" }} display={"flex"}>
          <EmptyStateArea>
            <Lantern />
            <StyledText variant="body3" weight={600} setColor="neutral30">
              Aguarde o período de Feedbacks para ver
              <br /> seus feedbacks recebidos e enviados.
            </StyledText>
          </EmptyStateArea>
        </Grid>
      </Grid>
    </>
  );
};

export const Feedbacks = ({ hasStarted = false }: { hasStarted?: boolean }) => {
  const navigate = useNavigate();

  const { id = "" } = useParams();

  const [insightsFilter, setInsightsFilter] = useState<"unsent" | undefined>();

  const {
    data,
    isFetching: isLoading,
    isError,
  } = trpc.performance.feedback.getManagerFeedbacksParticipantsByCycleId.useQuery(
    {
      cycleId: id,
    },
    {
      enabled: !!id,
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao buscar os Feedbacks, tente novamente em breve!",
        });
      },
    }
  );

  const filteredFeedbacksParticipants = useMemo(() => {
    const feedbacks = cloneDeep(data?.feedbackParticipants || []);

    if (!insightsFilter) return feedbacks;

    return feedbacks.filter(({ status }) => status === "unsent");
  }, [insightsFilter, data?.feedbackParticipants?.length]);

  const isByLeaderNotSelected =
    !isLoading &&
    !isError &&
    !data?.evaluationTypes.find((t) => t === "byLeader");

  if (!hasStarted) return <EmptyState />;

  if (isByLeaderNotSelected)
    return (
      <div style={{ marginBottom: "20px" }}>
        <EmptyStateUI
          description={
            <StyledText
              variant="body3"
              weight={600}
              setColor="neutral30"
              style={{
                maxWidth: "65%",
                textAlign: "center",
                display: "flex",
                justifySelf: "center",
              }}
              children={
                "Não é possível enviar feedback associado ao ciclo pois não há avaliações com a modalidade “Líder avalia liderado” neste ciclo. Ainda é possível enviar ou solicitar feedbacks espontâneos há todas as pessoas."
              }
            />
          }
          buttonText={"Acessar feedback"}
          buttonProps={{
            size: "large",
            variant: "secondary",
            onClick: () => navigate(routes.PageSendFeedback),
          }}
        />
      </div>
    );

  return (
    <>
      <BigNumbers
        insights={data?.insights}
        isLoading={isLoading}
        insightsFilter={insightsFilter}
        onFilter={setInsightsFilter}
      />
      <Table
        data={filteredFeedbacksParticipants || []}
        filters={data?.filters || []}
        loading={isLoading}
        searchable={true}
        searchableField="from.name"
        searchPlaceholder={"Buscar líder por nome"}
        tableGridRender={(props) => <BulkActionsGrid {...props} cycleId={id} />}
      />
    </>
  );
};
