import { useState, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { cloneDeep } from "lodash"

import { Button, Icons } from "@flash-tecnologia/hros-web-ui-v2"

import { TableSearch } from "./TableSearch"
import { TableFilters } from "./TableFilters"
import { TableGrid } from "./TableGrid"

import { TableProps } from "./types"

import { ContainerRow } from "./styles"
import { useTracking } from "@utils/useTracking"

export const Table = ({
  loading,
  data = [],
  filters,
  flowId,
  subcategory = "clt",
}: TableProps) => {
  const [search, setSearch] = useState<string>("")
  const [filterStates, setFilterStates] = useState<{
    [key: string]: any[]
  }>({})

  const navigate = useNavigate()
  const tracking = useTracking()

  const filteredData = useMemo(() => {
    if (!search && !Object.keys(filterStates)?.length) return data

    let dataToFilter = cloneDeep(data)

    if (search) {
      dataToFilter = dataToFilter?.filter((item) => {
        const byName = item?.name?.toLowerCase().includes(search.toLowerCase())
        const byIdentification = item?.identification
          ?.toLowerCase()
          .includes(search.toLowerCase())

        return byName || byIdentification
      })
    }

    if (Object.keys(filterStates)?.length) {
      dataToFilter = dataToFilter?.filter((item) => {
        for (const key in filterStates) {
          const itemValue = item[key] || undefined

          if (itemValue === undefined) return false

          if (Array.isArray(itemValue)) {
            if (!itemValue.some((el) => filterStates[key].includes(el))) {
              return false
            }
          } else {
            if (!filterStates[key].includes(item[key])) return false
          }
        }
        return true
      })
    }

    return dataToFilter
  }, [data, search, filterStates])

  return (
    <div>
      <ContainerRow>
        <TableSearch disabled={!(data || [])?.length} onChange={setSearch} />
        <Button
          variant="primary"
          size="large"
          style={{ minWidth: "198px" }}
          onClick={() => {
            tracking.trackEvent({
              name: `company_hiring_${subcategory}_contractmodel_create_clicked`,
            })

            navigate(`/flows/settings/${flowId}/contracts/create/basic-info`)
          }}
        >
          <Icons name="IconPlus" /> Criar modelo
        </Button>
      </ContainerRow>
      {filters?.[0] && (
        <TableFilters
          disabled={!(data || [])?.length}
          filters={filters}
          data={data}
          onFilter={setFilterStates}
        />
      )}
      <TableGrid
        data={filteredData || []}
        rawData={data || []}
        pageSize={10}
        loading={loading}
        flowId={flowId}
      />
    </div>
  )
}
