import { useState, type ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  PillButton,
  Icons,
  LinkButton,
  Menu,
  MenuOptionType,
  Skeleton,
} from "@flash-tecnologia/hros-web-ui-v2";

import { WordsConfirmationModal } from "@components/Modals";
import { StyledIcon, StyledText, dispatchToast } from "@utils";
import { TableTag } from "@components/TableTag";
import { trpc } from "@api/client";
import { routes } from "@routes";

import type { Cycle } from "server/src/services/cycle/types";

import {
  HeaderArea,
  TitleArea,
  Title,
  ButtonsArea,
  Option,
  DeleteOption,
  DeleteWarning,
} from "./styled";

interface TOption extends MenuOptionType {
  onClick: any;
  children: ReactNode;
}

type IHeaderProps = {
  _id: string;
  name?: string;
  description?: string;
  cycleStatus?: Cycle["cycleStatus"];
  isLoading?: boolean;
};

const HeaderSkeleton = ({ breadCrumbs }) => {
  return (
    <div>
      <Breadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={breadCrumbs}
      />
      <HeaderArea>
        <TitleArea>
          <Skeleton variant="text" width="180px" height="38px" />
          <Skeleton variant="text" width="100px" height="28px" />
        </TitleArea>
        <ButtonsArea>
          <Skeleton variant="circular" width="50px" height="50px" />
        </ButtonsArea>
      </HeaderArea>
    </div>
  );
};

export const Header = ({
  _id,
  name,
  description = "",
  cycleStatus,
  isLoading = false,
}: IHeaderProps) => {
  const navigate = useNavigate();

  const utils = trpc.useContext();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const breadCrumbs = [
    <LinkButton
      variant="neutral"
      style={{ alignSelf: "flex-start" }}
      onClick={() => navigate(routes.PageManageCycles)}
    >
      Ciclos de desempenho
    </LinkButton>,
    <LinkButton style={{ alignSelf: "flex-start" }} variant="neutral">
      {name ?? "Ciclo"}
    </LinkButton>,
  ];

  const labels = {
    closed: { label: "Ciclo encerrado", variant: "gray" as const },
    scheduled: { label: "Ciclo agendado", variant: "pink" as const },
    available: { label: "Ciclo ativo", variant: "green" as const },
  };

  const { mutate: deleteCycle, isLoading: isDeleting } =
    trpc.performance.cycle.deleteCycle.useMutation({
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: "Ciclo excluído com sucesso.",
        });

        utils.performance.cycle.getLastCycle.invalidate();
        utils.performance.cycle.getAllCycles.invalidate();

        setOpenDeleteModal(false);
        navigate(routes?.PageManageCycles);
      },
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao deletar o Ciclo, tente novamente mais tarde.",
        });
      },
    });

  const renderOptions = ({
    _id,
    cycleStatus,
  }: {
    _id: string;
    cycleStatus: string;
  }) => {
    const itens = [] as TOption[];

    if (cycleStatus != "closed") {
      itens.push({
        onClick: () => {
          navigate(routes.PageCreateCycle(_id));
        },
        children: (
          <Option>
            <Icons name="IconPencil" fill="transparent" />
            <StyledText variant="body3" style={{ fontWeight: 600 }}>
              Editar ciclo
            </StyledText>
          </Option>
        ),
      });
    }

    itens.push({
      onClick: () => setOpenDeleteModal(true),
      children: (
        <DeleteOption>
          <StyledIcon name="IconTrash" fill="transparent" setColor="error50" />
          <StyledText
            variant="body3"
            style={{ fontWeight: 600 }}
            setColor="error50"
          >
            Excluir ciclo
          </StyledText>
        </DeleteOption>
      ),
    });

    return itens;
  };

  if (isLoading) return <HeaderSkeleton breadCrumbs={breadCrumbs} />;

  return (
    <div>
      <Breadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={breadCrumbs}
      />
      <HeaderArea>
        <TitleArea>
          <Title variant="headline6" setColor="neutral20">
            {name || "-"}
            {<>&nbsp;</>}
            {cycleStatus && (
              <TableTag
                label={labels[cycleStatus]?.label}
                variant={labels[cycleStatus]?.variant}
                hasDot={true}
                style={{ flexShrink: 0, alignSelf: "center" }}
              />
            )}
          </Title>
          {description && (
            <StyledText variant="body3" setColor="neutral40">
              {description}
            </StyledText>
          )}
        </TitleArea>

        <ButtonsArea>
          <Menu
            type={"select"}
            options={renderOptions({ _id, cycleStatus: cycleStatus || "" })}
            disableAutoFocusItem={true}
            anchorOrigin={{ vertical: "center", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <PillButton
              variant="default"
              size="small"
              type="secondary"
              icon="IconDotsVertical"
            />
          </Menu>
        </ButtonsArea>
      </HeaderArea>
      <WordsConfirmationModal
        open={openDeleteModal}
        wordToConfirm="EXCLUIR"
        showWarning
        icon="IconAlertCircle"
        title="Tem certeza que deseja excluir este ciclo?"
        customSubTitle={
          <StyledText
            variant="body3"
            setColor="neutral50"
            style={{
              textAlign: "center",
              marginBottom: "14px",
            }}
          >
            <b>
              <DeleteWarning>Importante:</DeleteWarning> Todos os dados{" "}
            </b>
            relacionados as <b>avaliações associadas</b> (caso existam) serão{" "}
            <b>perdidos</b> e essa ação não poderá ser desfeita.
            <br />
            <br />
            Os feedbacks e <b>PDIs</b> associados ao ciclo{" "}
            <b>não serão afetados</b> por essa ação e poderão ser encontrados em
            suas respectivas áreas.
          </StyledText>
        }
        confirm={{ title: "Confirmar exclusão", icon: "IconTrash" }}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        onConfirm={() => {
          deleteCycle({
            cycleId: _id,
          });
        }}
        isLoading={isDeleting}
      />
    </div>
  );
};
