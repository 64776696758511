import {
  Accordion,
  Button,
  IconTypes,
  LinkButton,
  ShapeIcon,
  Skeleton,
  Table,
  tableControllers,
  Tag,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"; //TODO passar pelo styled ou criar componente
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import { trpc } from "$client";
import { useValueFormatter } from "$frontend/shared/hooks/formatters/useValueFormatter";
import { useDisplayToast } from "$frontend/utils";
import { ButtonGroup, ButtonGroupOption, InfoCard, NotificationCard } from "$molecules";
import { Expense, ExpenseStatus, ExpenseType, GetExpensesQuery } from "$serverTypes";
import { MainRowColumn } from "../MainRowColumn/MainRowColumn";
import { AccordionHeader, AccordionHeaderCounters } from "./styled";
import { PendingExpenseAction } from "./types";

type PropsType = {
  onSendToAccount: (expense: Expense) => void;
  onReclassify: (expense: Expense) => void;
  onAddReceipt: (expense: Expense) => void;
  onAddCategory: (expense: Expense) => void;
  onPendingIssuesClicked: () => void;
};

export interface PendingExpenseGridHandles {
  resetSelection: () => void;
  refetch: () => Promise<void>;
}

export const PendingExpenseGrid = forwardRef<PendingExpenseGridHandles, PropsType>((props, ref) => {
  const { colors } = useTheme();
  const statusToFilter = [ExpenseStatus.DRAFT, ExpenseStatus.REQUIRE_CHANGES];
  const pageSizes = [5, 10, 15] as const;
  const {
    data: { summaries },
    isFetching: isSummaryLoading,
    isError: isSummaryError,
    refetch: refetchSummary,
  } = trpc.expense.getSummaryMe.useQuery(
    {
      status: statusToFilter,
      type: ExpenseType.CORPORATE_CARD,
    },
    {
      initialData: {
        summaries: [],
      },
    },
  );

  const [expensesQuery, setExpensesQuery] = useState<GetExpensesQuery>({
    pageNumber: 1,
    pageSize: pageSizes[1],
    status: statusToFilter,
    type: ExpenseType.CORPORATE_CARD,
  });
  const {
    data,
    isFetching: isExpensesLoading,
    isError: isExpensesError,
    refetch: refetchExpenses,
  } = trpc.expense.listMe.useQuery(expensesQuery, {
    initialData: {
      expenses: [],
      totalCount: 0,
    },
  });

  const pagination = { ...expensesQuery };
  const isLoading = isExpensesLoading || isSummaryLoading;
  const isError = isExpensesError || isSummaryError;

  const totalAmount = summaries.reduce((acc, summary) => acc + summary.amount, 0);

  const expenses = isExpensesLoading ? [] : (data?.expenses ?? []);
  const { t } = useTranslation("translations", { keyPrefix: "organisms.pendingExpenseGrid" });
  const { displayToast } = useDisplayToast();

  const { getValueWithCurrency } = useValueFormatter();

  const table = tableControllers.useTableColumns<Expense>({
    defaultColumn: {
      maxSize: 165,
    },
    total: data.totalCount,
    columns: [
      {
        id: "description",

        cell: ({ row }) => {
          const pendingAction = getExpensePendingAction(row.original);
          const icon = (
            {
              [PendingExpenseAction.CATEGORY_MISSING]: "IconCategoryMinus",
              [PendingExpenseAction.RECEIPT_MISSING]: "IconReceiptOff",
              [PendingExpenseAction.MULTIPLE_ITEMS_MISSING]: "IconProgress",
              [PendingExpenseAction.PENDING_SUBMIT]: "IconProgress",
            } satisfies Record<PendingExpenseAction, IconTypes>
          )[pendingAction];
          return (
            <MainRowColumn
              title={{ description: t(`expensePendingAction.${pendingAction}`) }}
              caption={{ description: row.original.transaction?.description }}
              icon={{ name: icon, variant: "default" }}
            />
          );
        },
      },
      {
        minSize: 150,
        id: "amount",
        cell: ({ row }) => {
          const transaction = row.original.transaction;
          if (!transaction) {
            return <></>;
          }

          const { amount, currency } = transaction;
          return (
            <InfoCard
              title={
                <Typography variant="caption" variantColor={colors.neutral[40]}>
                  {t("amount")}
                </Typography>
              }
              subTitle={
                <Typography weight={600} variant="body4" variantColor={colors.neutral[30]}>
                  {/* TODO verificar quais tipos de transacoes entram aqui, se add sinal negativo em todos é ok */}
                  {"- " + getValueWithCurrency({ value: amount, currencyPrefix: currency })}
                </Typography>
              }
            />
          );
        },
      },

      {
        minSize: 150,
        id: "actionDetails",
        cell: ({ row }) => {
          const expense = row.original;
          const pendingAction = getExpensePendingAction(expense);
          const title = t(`actionDetails.title.${pendingAction}`);

          const caption = (
            <LinkButton
              variant="default"
              onClick={() => {
                const hook = {
                  [PendingExpenseAction.CATEGORY_MISSING]: props.onAddCategory,
                  [PendingExpenseAction.RECEIPT_MISSING]: props.onAddReceipt,
                  [PendingExpenseAction.PENDING_SUBMIT]: props.onAddCategory,
                  [PendingExpenseAction.MULTIPLE_ITEMS_MISSING]: props.onAddReceipt,
                }[pendingAction];
                if (hook) {
                  hook(expense);
                }
              }}
            >
              {t(`actionDetails.subtitle.${pendingAction}`)}{" "}
            </LinkButton>
          );

          return (
            <InfoCard
              title={
                <Typography variant="caption" variantColor={colors.neutral[40]}>
                  {title}
                </Typography>
              }
              subTitle={caption}
            />
          );
        },
      },
      {
        header: t("columns.actions"),
        size: 60,
        //Note: using underscore to avoid this column to be named "Ações"
        id: "_actions",
        sticky: "right",
        cell: ({ row }) => renderActions(row.original),
      },
    ],
    data: expenses,
    pagination: pagination,
    onPaginationChange,
  });

  useImperativeHandle(ref, () => ({
    resetSelection() {
      table.resetSelected();
    },
    async refetch() {
      await Promise.all([refetchExpenses(), refetchSummary()]);
    },
  }));

  function renderActions(expense: Expense) {
    const options = [
      {
        icon: "IconFileExport",
        label: t("actions.sendToAccount"),
        onClick: () => props.onSendToAccount(expense),
      },
      { icon: "IconRefreshAlert", label: t("actions.reclassify"), onClick: () => props.onReclassify(expense) },
    ] satisfies ButtonGroupOption[];
    return <ButtonGroup options={options} />;
  }

  function onPaginationChange(pagination: PaginationState) {
    setExpensesQuery({
      ...expensesQuery,
      ...pagination,
    });
  }

  if (isError) {
    return (
      <NotificationCard type="error" variant="contained" iconName="IconExclamationMark">
        {t("failedToGetPendencies")}
      </NotificationCard>
    );
  }

  return (
    <Accordion
      variant="default"
      customHeader={
        isSummaryLoading ? (
          <Skeleton width="100%" height={42} />
        ) : (
          <AccordionHeader>
            <AccordionHeaderCounters>
              <InfoCard
                title={
                  <Typography variant="headline9" variantColor={colors.neutral[20]}>
                    {t("pendingExpenses", { count: data.totalCount })}
                  </Typography>
                }
                leading={<ShapeIcon variant={"default"} stroke={"default"} name="IconProgress" size={32} />}
              />
              <InfoCard
                title={
                  <Typography variant="body4" weight={600} variantColor={colors.neutral[20]}>
                    {getValueWithCurrency({ value: totalAmount })}
                  </Typography>
                }
                subTitle={
                  <Typography variant="caption" variantColor={colors.neutral[40]}>
                    {t("totalAmount")}
                  </Typography>
                }
                leading={<ShapeIcon variant={"neutral"} stroke={"neutral"} name="IconCurrencyDollar" size={32} />}
              />
            </AccordionHeaderCounters>
            <Button
              variant="primary"
              variantType="default"
              onClick={() => props.onPendingIssuesClicked()}
              disabled={data.totalCount === 0}
            >
              {t("reviewPendingIssues")} <Tag variant="primary">{data.totalCount.toString().padStart(2, "0")}</Tag>
            </Button>
          </AccordionHeader>
        )
      }
    >
      <Table.Root>
        <Table.Grid.Root loading={isLoading} empty={{ message: t("emptyState") }}>
          {/* NOTE: The following empty div is to count as an empty header so that empty state is computed correctly */}
          <div></div>
          {table.rows.map((row, index) => (
            <Table.Grid.Row key={`pending-exepnse-grid-row-${index}-${row.id}`} row={row} />
          ))}
        </Table.Grid.Root>
        <Table.Pagination
          count={data.totalCount}
          onPaginationChange={onPaginationChange}
          pagination={pagination}
          pageSizeOptions={pageSizes.map((value) => ({
            label: t("pageSizeFormat", { value }),
            value,
          }))}
        />
      </Table.Root>
    </Accordion>
  );
});

function getExpensePendingAction(expense: Expense) {
  if (!expense.category && (expense.attachments?.length ?? 0) === 0) {
    return PendingExpenseAction.MULTIPLE_ITEMS_MISSING;
  }

  if (!expense.category) {
    return PendingExpenseAction.CATEGORY_MISSING;
  }

  if ((expense.attachments?.length ?? 0) === 0) {
    return PendingExpenseAction.RECEIPT_MISSING;
  }

  return PendingExpenseAction.PENDING_SUBMIT;
}
