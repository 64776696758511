import {
  PageContainer,
  PageHeader,
  Typography,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { trpc } from "../../../api/client";
import { alert, getPeriod } from "../../../utils";
import { Modal } from "../../v2";
import {
  Footer,
  Header,
  Skeleton,
  generateId,
  generateSurveyDataByModel,
} from "../common/components";
import { StepDispatch, StepEmployee } from "../common/steps";
import {
  StepConfig,
  StepContent,
  StepDispatchExit,
  StepInfo,
  StepModel,
  StepReview,
} from "./steps";
import { Container } from "./styles";
import { SurveyFormInterface, SurveyInterface } from "./types";
import { NewModal } from "@components/NewModal";
import { useContractValidation } from "src/hooks/useContractValidation";
import { Box } from "@mui/material";
import { useTheme } from "styled-components";

const defaultNotifications = {
  email: false,
  push: false,
  reminder: {
    enabled: true,
    minRate: 70,
  },
};

const defaultOwners = [{ name: "*", id: "*" }];

const SurveyForm = ({ id, forceEngaja }: SurveyFormInterface) => {
  const engajaDescription = `Nesta pesquisa, buscamos entender o nível de engajamento de nossos colaboradores, mapeando desafios, motivações, oportunidades e outros fatores que influenciam sua conexão com a nossa organização. Com os dados coletados, queremos criar um índice que nos ajude a medir e aprimorar o engajamento. A pesquisa é dividida em:
    - Informações Pessoais: Coletamos informações demográficas e profissionais para garantir uma análise mais detalhada e segmentada, ajudando a identificar padrões e gerar insights relevantes para diferentes grupos de colaboradores.
    - Atributos do Engajamento: Responda sobre como você se sente atualmente em relação à empresa, avaliando seis dimensões do engajamento.
    - Ações: Avalie como ações do RH impactariam seu engajamento, mesmo que sua empresa não implemente essas iniciativas.
    - eNPS: Avaliamos a satisfação e recomendação dos colaboradores por meio do eNPS (Employee Net Promoter Score), que ajuda a medir o nível de satisfação dos funcionários.
    Consentimento qualificado: estou ciente de que alguns dados coletados são sensíveis, nos termos da LGPD, especialmente aqueles relacionados ao tema da pesquisa.
  `;
  const theme = useTheme() as any;
  const hasEngagementContract = useContractValidation();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(false);
  const { model = "" } = useParams();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(model || id ? 1 : 0);
  const [isDraft, setIsDraft] = useState(false);
  const [survey, setSurvey] = useState<SurveyInterface | undefined>(
    model === "custom"
      ? {
          model,
          type: "research",
          anonymous: false,
          sections: [],
          notifications: defaultNotifications,
          owners: defaultOwners,
        }
      : model === "engaja"
      ? {
          model,
          type: "research",
          anonymous: true,
          sections: generateSurveyDataByModel(model),
          notifications: defaultNotifications,
          owners: defaultOwners,
          recurrenceMode: false,
          description: engajaDescription,
        }
      : {
          notifications: defaultNotifications,
          owners: defaultOwners,
        }
  );

  const [employees, setEmployees] =
    useState<{ _id: string; name: string; corporateEmail?: string }[]>();

  const {
    isFetching: getEmployeesByCompanyIdLoading,
    refetch: getEmployeesByCompanyId,
  } = trpc?.getInternalEmployeesByCompanyId?.useQuery(
    {
      status: "ACTIVE",
    },
    {
      retry: false,
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data?.employees?.length)
          setEmployees([
            ...data?.employees?.map(({ _id, name, corporateEmail }) => ({
              _id,
              name,
              corporateEmail,
            })),
          ]);
      },
      onError: (e: any) => {
        alert("Erro ao buscar destinatários, tente novamente mais tarde.");
        console.log("err: ", e);
      },
    }
  );

  const { isFetching: getEngagementByIdLoading, refetch: getEngagementById } =
    trpc?.getEngagementById?.useQuery(
      {
        id: id || "",
      },
      {
        retry: false,
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
          setSurvey({
            ...data,
            type: data?.type === "enps" ? "enps" : "research",
            notifications: {
              ...data?.notifications,
              reminder: {
                enabled: data?.notifications?.reminder?.enabled || false,
                minRate: data?.notifications?.reminder?.minRate || 0,
              },
            },
          });

          if (
            data?._id &&
            data?.recurrence?.status === "progress" &&
            progress === false
          )
            setProgress(true);

          getEmployeesByCompanyId();
        },
        onError: (e: any) => {
          alert("Erro ao buscar pesquisa, tente novamente mais tarde.");
          console.log("err: ", e);

          if (e?.data?.error === "ENGAGEMENT_NOT_EXISTS_ERROR")
            navigate(`/engagement/survey`);
        },
      }
    );

  const { isLoading: createEngagementLoading, mutate: createEngagement } =
    trpc?.createEngagement?.useMutation({
      onSuccess: () => {
        setShowSuccessModal(true);
        // alert("Pesquisa criada com sucesso", "success");
        // navigate(`/engagement/survey`);
      },
      onError: (e: any) => {
        let msgError = "Ocorreu um erro, tente novamente mais tarde.";
        const error = e?.data?.error;

        if (error === "INVALID_START_DATE_ERROR")
          msgError = "Erro: Selecione uma data de envio válida.";
        if (error === "INVALID_PERIOD")
          msgError = "Erro: Selecione um período válido.";
        if (error === "INVALID_INTERACTION_DEADLINE_ERROR")
          msgError =
            "Erro: O prazo de resposta deve ser maior que a data de envio.";

        alert(msgError);
        console.log("err: ", e);
      },
    });

  const { mutate: createEngagementAndNavigateToEmployees } =
    trpc?.createEngagement?.useMutation({
      onSuccess: () => {
        alert("Rascunho criado com sucesso", "success");
        navigate(`/employees`);
      },
      onError: (e: any) => {
        let msgError = "Ocorreu um erro, tente novamente mais tarde.";
        const error = e?.data?.error;

        if (error === "INVALID_START_DATE_ERROR")
          msgError = "Erro: Selecione uma data de envio válida.";
        if (error === "INVALID_PERIOD")
          msgError = "Erro: Selecione um período válido.";
        if (error === "INVALID_INTERACTION_DEADLINE_ERROR")
          msgError =
            "Erro: O prazo de resposta deve ser maior que a data de envio.";

        alert(msgError);
        console.log("err: ", e);
      },
    });

  const {
    isLoading: updateRecurrenceEmployeesLoading,
    mutate: updateRecurrenceEmployees,
  } = trpc?.updateRecurrenceEmployees?.useMutation({
    onSuccess: () => {
      alert("Recorrência atualizada com sucesso", "success");
      navigate(`/engagement/survey`);
    },
    onError: (e: any) => {
      alert("Erro ao atualizar recorrência, tente novamente mais tarde.");
      console.log("error: ", e);
    },
  });

  const createOrUpdateSurvey = (
    hasDraft?: boolean,
    navigateToEmployees?: boolean
  ) => {
    const isExitSurvey = ["voluntaryExit", "involuntaryExit"]?.includes(
      survey?.model || ""
    );

    const termsAndConditions =
      survey?.termsAndConditions === undefined
        ? null
        : survey?.termsAndConditions;
    const owners = survey?.owners ?? [{ id: "*", name: "*" }];
    const status: "draft" | "send" | "scheduled" = hasDraft
      ? "draft"
      : survey?.status === "send" ||
        isExitSurvey ||
        (getPeriod(dayjs()) === survey?.startDate?.period &&
          dayjs()?.format("YYYY-MM-DD") === survey?.startDate?.date)
      ? "send"
      : "scheduled";

    if (status === "draft") setIsDraft(true);

    const recurrenceStatus: "scheduled" | "progress" =
      status === "send" ? "progress" : "scheduled";

    if (survey?.type && survey?.name && status) {
      const recurrence = survey?.recurrenceMode
        ? {
            active:
              typeof survey?.recurrence?.active === "boolean"
                ? survey?.recurrence?.active
                : true,
            daysOfTheWeek:
              survey?.recurrence?.frequency === "week"
                ? survey?.recurrence?.daysOfTheWeek
                : undefined,
            endDate: survey?.recurrence?.endDate || undefined,
            frequency: survey?.recurrence?.frequency || undefined,
            interactionDeadlineConfig:
              survey?.recurrence?.interactionDeadlineConfig || undefined,
            occurrenceLimit: survey?.recurrence?.occurrenceLimit || undefined,
            repeat: survey?.recurrence?.repeat || undefined,
            status: recurrenceStatus,
          }
        : undefined;

      if (progress) {
        updateRecurrenceEmployees({
          id: id || "",
          employees: survey?.employees || [],
          endDate: recurrence?.endDate,
          occurrenceLimit: recurrence?.occurrenceLimit,
          interactionDeadlineConfig: {
            nextMode: recurrence?.interactionDeadlineConfig?.nextMode || false,
            calculatedDate:
              recurrence?.interactionDeadlineConfig?.calculatedDate &&
              recurrence?.interactionDeadlineConfig?.calculatedDate
                ?.multiplier &&
              recurrence?.interactionDeadlineConfig?.calculatedDate?.period
                ? {
                    multiplier:
                      recurrence?.interactionDeadlineConfig?.calculatedDate
                        ?.multiplier,
                    period:
                      recurrence?.interactionDeadlineConfig?.calculatedDate
                        ?.period,
                  }
                : undefined,
          },
        });
        return;
      }

      const sections = survey?.sections?.map((s) => ({
        ...s,
        id: s?.id && s?.id?.includes("section-") ? undefined : s?.id,
        description: s?.description || undefined,
        questions: s?.questions?.map((q) => ({
          ...q,
          id: q?.id && q?.id?.includes("question-") ? undefined : q?.id,
          required: q?.required || false,
          justification:
            q?.answerFormat === "multiple-choice" ||
            q?.answerFormat === "select-box"
              ? q?.justification
              : undefined,
          options: [
            "multiple-choice",
            "select-box",
            "dropdown",
            "multi-select",
          ]?.includes(q?.answerFormat || "")
            ? q?.options
                ?.filter(({ value }) => value !== "outro")
                ?.map(({ id, referenceId, value }) => ({
                  id: id && id?.includes("option-") ? undefined : id,
                  value,
                  referenceId,
                }))
            : undefined,
          lowerLabel: ["scale", "emoji", "star", "heart"]?.includes(
            q?.answerFormat || ""
          )
            ? q?.lowerLabel
            : undefined,
          upperLabel: ["scale", "emoji", "star", "heart"]?.includes(
            q?.answerFormat || ""
          )
            ? q?.upperLabel
            : undefined,
          scaleRange: ["scale", "emoji", "star", "heart"]?.includes(
            q?.answerFormat || ""
          )
            ? {
                min: q?.scaleRange?.min,
                max: q?.scaleRange?.max,
              }
            : undefined,
        })),
      }));

      const notifications = {
        email: survey?.notifications?.email || false,
        push: survey?.notifications?.push || false,
        reminder: {
          enabled: survey?.notifications?.reminder?.enabled || false,
          minRate: survey?.notifications?.reminder?.minRate || 0,
        },
      };

      const corporateEmails = (
        survey?.employees?.map((employeeId) => ({
          _id: employeeId,
          email:
            employees?.find((e) => e?._id === employeeId)?.corporateEmail || "",
        })) || []
      )?.filter((e) => e?._id && e?.email?.length);

      const enpsSection =
        survey?.model === "enps" ||
        survey?.model === "engaja" ||
        (survey?.model === "climate" &&
          survey?.sections?.some(({ questions }) =>
            questions?.some(({ scaleRange }) => scaleRange?.max === 10)
          ))
          ? true
          : false;

      const body = {
        _id: survey?._id || undefined,
        name: survey?.name,
        model: survey?.model,
        enpsSection,
        status,
        type: survey?.type,
        anonymous: survey?.anonymous || survey?.type === "enps" ? true : false,
        description: survey?.description || undefined,
        employees: isExitSurvey ? [] : survey?.employees || undefined,
        groups: survey?.groups || undefined,
        interactionDeadline: recurrence
          ? undefined
          : survey?.interactionDeadline || undefined,
        interactionDeadlineDays:
          recurrence || !isExitSurvey
            ? undefined
            : survey?.interactionDeadlineDays || 0,
        startDate: isExitSurvey
          ? {
              date: dayjs()?.format("YYYY-MM-DD"),
              period: getPeriod(dayjs()),
            }
          : survey?.startDate,
        title: survey?.title || undefined,
        corporateEmails,
        notifications,
        recurrence,
        sections,
        owners,
        termsAndConditions,
      };

      if (body?.model === "engaja" && survey?.companyData)
        body["companyData"] = survey?.companyData;

      if (status !== "draft") {
        const currentDate = dayjs(dayjs()?.format("YYYY-MM-DD"));

        if (currentDate.diff(body?.startDate?.date, "day") > 0) {
          if (!recurrence?.status) {
            alert("Erro: Selecione uma data de envio válida.");
            return;
          }
        }

        if (body?.interactionDeadline) {
          if (
            dayjs(body?.startDate?.date)?.diff(body?.interactionDeadline) > 0
          ) {
            alert(
              "Erro: O prazo de resposta deve ser maior que a data de envio"
            );
            return;
          }
        }
      }

      if (navigateToEmployees) {
        createEngagementAndNavigateToEmployees(body);
      } else {
        createEngagement(body);
      }
    }
  };

  useEffect(() => {
    id ? getEngagementById() : getEmployeesByCompanyId();
  }, []);

  const getReminderDisabledProperty = (): boolean => {
    let result = 100;
    const sDate = dayjs(survey?.startDate?.date);

    if (survey?.recurrenceMode && survey?.recurrence) {
      if (survey?.recurrence?.interactionDeadlineConfig?.nextMode) {
        if (
          survey?.recurrence?.frequency === "day" &&
          survey?.recurrence?.repeat &&
          survey?.recurrence?.repeat < 4
        ) {
          const calcDate = sDate?.add(survey?.recurrence?.repeat, "day");
          result = calcDate.diff(sDate, "day");
        }
      }

      if (survey?.recurrence?.interactionDeadlineConfig?.nextMode !== true) {
        if (
          survey?.recurrence?.interactionDeadlineConfig?.calculatedDate
            ?.multiplier &&
          survey?.recurrence?.interactionDeadlineConfig?.calculatedDate?.period
        ) {
          const calcDate = sDate?.add(
            survey?.recurrence?.interactionDeadlineConfig?.calculatedDate
              ?.multiplier,
            survey?.recurrence?.interactionDeadlineConfig?.calculatedDate
              ?.period
          );
          result = calcDate.diff(sDate, "day");
        }
      }
    } else {
      if (survey?.interactionDeadline) {
        const interactionDeadlineDate = dayjs(survey?.interactionDeadline);
        result = interactionDeadlineDate.diff(sDate, "day");
      }
    }

    return result < 4;
  };

  useEffect(() => {
    if (getReminderDisabledProperty()) {
      setSurvey({
        ...survey,
        notifications: {
          email: survey?.notifications?.email || false,
          push: survey?.notifications?.push || false,
          reminder: {
            enabled: false,
            minRate: 0,
          },
        },
      });
    }
  }, [
    survey?.startDate?.date,
    survey?.interactionDeadline,
    survey?.recurrence?.interactionDeadlineConfig,
    survey?.recurrence?.repeat,
    survey?.recurrence?.frequency,
    survey?.recurrenceMode,
  ]);

  const employeesWithoutCorporateEmailsCount =
    survey?.employees?.reduce(
      (a, c) =>
        a + (employees?.find((e) => e?._id === c)?.corporateEmail ? 0 : 1),
      0
    ) || 0;

  const isExitSurveyModel =
    survey?.model &&
    ["voluntaryExit", "involuntaryExit"]?.includes(survey?.model);

  if (id && getEngagementByIdLoading) return <Skeleton />;

  return (
    <>
      <Header
        step={step}
        type="survey"
        hiddenSteps={isExitSurveyModel ? ["Destinatários"] : []}
      />
      {/* {!hasEngagementContract && <FreeTrialChronometer />} */}
      <Container id="survey-steps-container">
        <PageHeader title="Criar pesquisa" />
        <PageContainer>
          <StepModel
            onChange={(model) => {
              if (model) {
                setSurvey({
                  model,
                  type: model === "enps" ? "enps" : "research",
                  anonymous: ["enps", "climate", "engaja"]?.includes(model),
                  sections: generateSurveyDataByModel(model),
                  description: model === "engaja" ? engajaDescription : "",
                  recurrenceMode: model === "feeling" ? true : false,
                  owners: defaultOwners,
                  notifications:
                    model === "feeling"
                      ? {
                          email: false,
                          push: true,
                          reminder: {
                            enabled: true,
                            minRate: 70,
                          },
                        }
                      : defaultNotifications,
                  recurrence:
                    model === "feeling"
                      ? {
                          daysOfTheWeek: ["friday"],
                          endDate: undefined,
                          frequency: "week",
                          interactionDeadlineConfig: {
                            calculatedDate: undefined,
                            nextMode: true,
                          },
                          occurrenceLimit: -1,
                          repeat: 1,
                        }
                      : undefined,
                });
              }

              setStep(1);
            }}
            disabled={survey?.status === "send"}
            hide={step !== 0}
            forceEngaja={forceEngaja || false}
          />
          <StepInfo
            {...survey}
            onChange={(e) => setSurvey({ ...survey, ...e })}
            disabled={survey?.status === "send"}
            hide={step !== 1}
          />
          {isExitSurveyModel ? (
            <StepDispatchExit
              disabled={survey?.status === "send"}
              hide={step !== 2}
              interactionDeadlineDays={survey?.interactionDeadlineDays}
              onChange={(e) =>
                setSurvey({ ...survey, interactionDeadlineDays: e || 0 })
              }
            />
          ) : (
            <StepDispatch
              {...survey}
              onChange={(e) => setSurvey({ ...survey, ...e })}
              disabled={survey?.status === "send"}
              hide={step !== 2}
            />
          )}
          <StepConfig
            {...survey}
            disableReminder={getReminderDisabledProperty()}
            onChange={(e) => setSurvey({ ...survey, ...e })}
            disabled={survey?.status === "send"}
            hide={step !== 3}
          />
          <StepContent
            {...survey}
            onChange={(e) => setSurvey({ ...survey, sections: e })}
            disabled={survey?.status === "send" || survey?.model === "engaja"}
            hide={step !== 4}
          />
          {!isExitSurveyModel ? (
            <StepEmployee
              {...survey}
              type="survey"
              loading={
                getEmployeesByCompanyIdLoading || getEmployeesByCompanyIdLoading
              }
              companyEmployees={employees}
              selectedEmployeeIds={
                progress
                  ? survey?.recurrence?.employees || survey?.employees
                  : survey?.employees
              }
              onChange={(e) => setSurvey({ ...survey, employees: e })}
              showUserWithoutCorporateEmail={!survey?.notifications?.email}
              disabled={survey?.status === "send"}
              hide={step !== 5}
              onClick={() => createOrUpdateSurvey(true, true)}
            />
          ) : null}
          <StepReview
            {...survey}
            companyEmployees={employees}
            hide={isExitSurveyModel ? step !== 5 : step !== 6}
            onStepClick={(step) => setStep(step)}
          />
        </PageContainer>
      </Container>
      <Footer
        type="survey"
        loading={createEngagementLoading || updateRecurrenceEmployeesLoading}
        companyEmployeesLoading={getEmployeesByCompanyIdLoading}
        surveyData={survey}
        currentStep={step}
        onDraftButtonClick={() => createOrUpdateSurvey(true)}
        onPreviousButtonClick={(e) => setStep(e)}
        onNextButtonClick={(e) =>
          (isExitSurveyModel ? step < 5 : step < 6)
            ? setStep(e)
            : employeesWithoutCorporateEmailsCount > 0 &&
              survey?.notifications?.email
            ? setOpen(!open)
            : createOrUpdateSurvey(false)
        }
      />
      <Modal
        open={open}
        onClose={() => setOpen(!open)}
        title="Atenção!"
        subTitle={`${employeesWithoutCorporateEmailsCount} destinatários não possuem <br/> e-mail corporativo <br/> cadastrado`}
        description="Ao continuar, você está ciente de que essas pessoas não <br/> receberão a notificação por e-mail."
        iconStatus="alert"
        closeButton
        iconName="IconAlertCircle"
        footer={{
          closeButton: {
            label: "Cancelar",
            onClick: () => setOpen(!open),
            variant: "neutral",
          },
          submitButton: {
            label: "Continuar",
            onClick: () => {
              createOrUpdateSurvey(false);
              setOpen(!open);
            },
            variant: "secondary",
            variantType: "error",
          },
        }}
      />
      <NewModal
        id={"modal_create_survey"}
        title={
          isDraft
            ? "Rascunho foi criado com sucesso"
            : "Sua pesquisa foi criada com sucesso"
        }
        description={
          isDraft ? (
            <Box
              display="flex"
              flexDirection={"column"}
              alignItems={"center"}
              gap="24px"
              textAlign={"justify"}
            >
              <Typography
                variant="body4"
                variantColor={theme?.colors?.neutral[40]}
              >
                Ele estará <strong>disponível na tabela de pesquisas, </strong>
                acesse através da opção de editar nas ações.
              </Typography>
            </Box>
          ) : survey?.model === "engaja" && survey?.interactionDeadline ? (
            <Box
              display="flex"
              flexDirection={"column"}
              alignItems={"center"}
              gap="24px"
              textAlign={"justify"}
              width={500}
            >
              <Typography
                variant="body4"
                variantColor={theme?.colors?.neutral[40]}
              >
                Ela estará{" "}
                <strong>
                  disponível para os colaboradores até{" "}
                  {dayjs(survey?.interactionDeadline)?.format("DD/MM/YYYY")}
                </strong>
                , é possível acompanhar as respostas nos detalhes da pesquisa.{" "}
              </Typography>
              {/* <Typography
                variant="body4"
                variantColor={theme?.colors?.neutral[40]}
              >
                A <strong>análise completa</strong> estará{" "}
                <strong>disponível em 24/09/2024</strong>, notificaremos você em
                breve! {":)"}{" "}
              </Typography> */}
            </Box>
          ) : survey?.interactionDeadline ? (
            `Ela estará disponível para os colaboradores até ${dayjs(
              survey?.interactionDeadline
            )?.format(
              "DD/MM/YYYY"
            )}, é <br/> possível acompanhar as respostas nos detalhes da pesquisa.`
          ) : (
            "É possível acompanhar as respostas nos detalhes da pesquisa"
          )
        }
        open={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        footer={{
          submitButton: {
            label: "Voltar para Pesquisas",
            variant: "primary",
            onClick: () => navigate(`/engagement/survey`),
          },
        }}
        status={{
          icon: "IconCircleCheck",
          color: "green",
          message: "Tudo certo!",
        }}
      />
    </>
  );
};

export { SurveyForm };
