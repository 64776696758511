import { VersionCallout } from '@frontend/components/VersionCallout';
import {
  useVersion,
  VersionProvider,
} from '@frontend/components/VersionCallout/context';

export const Version = {
  Provider: VersionProvider,
  Callout: VersionCallout,
  useVersion: useVersion,
};
