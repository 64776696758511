import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const ListWrapper = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings.xs};
  width: 100%;
`

export const InfoWrapper = styled.div`
  padding: ${({ theme }) => `${theme.spacings.m} ${theme.spacings.xs4}`};
  border-top: ${({ theme }) =>
    `${theme.borders.width.xs2} solid ${theme.colors.neutral90}`};
`

export const FieldContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    stroke: ${({ theme }) => theme.colors.neutral[30]};
  }
`

export const FieldWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

export const FieldTitle = styled(Typography)`
  font-weight: 700 !important;
  line-height: 18px;
  margin-bottom: 4px;

  color: ${({ theme }) => theme.colors.neutral[50]};
`

export const FieldDescription = styled(Typography)`
  font-weight: 600 !important;
  line-height: 22px;

  color: ${({ theme }) => theme.colors.neutral[40]};
`

export const StyledPOneLine = styled.p<{ numberOfLines: number }>`
  display: block;
  display: -webkit-box;
  line-height: ${({ numberOfLines }) => numberOfLines};
  -webkit-line-clamp: ${({ numberOfLines }) => numberOfLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`

export const SmallTag = styled.div<{ marginTop?: number }>`
  font-size: 12px;
  background-color: ${({ theme }) => theme.colors.feedback.error[90]};
  padding: ${({ theme }) => `${theme.spacings.xs5} ${theme.spacings.xs4}`};
  gap: ${({ theme }) => theme.spacings.xs5};
  border-radius: ${({ theme }) => theme.spacings.xs};
  margin-top: ${({ marginTop }) => marginTop ?? 0}px;
`
