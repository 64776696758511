import { Container, Description, LoaderContent, LoaderPage, LoaderPageContent } from "./styled";

export interface LoaderProps {
  /**
   * If 'true' show the loader
   */
  show: boolean;

  /**
   * Loader description
   *
   */
  description?: string;
}

export const Loader = ({ show = false, description }: LoaderProps) => {
  if (show)
    return (
      <Container>
        <LoaderContent>
          <LoaderPageContent>
            <LoaderPage variant="primary" size="large" />
          </LoaderPageContent>
          <Description variant="body3">{description}</Description>
        </LoaderContent>
      </Container>
    );
  return <></>;
};
