import { LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled, { DefaultTheme } from "styled-components";

import { ColorVariant } from "$themes";

export const ContainerStatus = styled.div`
  display: grid;
  gap: ${(props) => props.theme.spacings.xs};
  grid-template-columns: repeat(3, 1fr);
`;

interface ContainerProps {
  main?: boolean;
  isSelected?: boolean;
}

interface SelectedStatusProps {
  isSelected?: boolean;
}

interface ReportsNumberTextProps {
  variantColor: ColorVariant;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  border-radius: ${(props) => props.theme.borders.radius.m};
  gap: ${(props) => props.theme.spacings.xs2};
  grid-template-columns: ${(props) => (props.main ? "min-content" : "min-content min-content")};
  grid-template-rows: ${(props) => (props.main ? "min-content min-content" : "min-content")};
  align-content: center;
  border: ${(props) => props.theme.borders.width.xs2} solid
    ${(props) => (props.isSelected ? props.theme.colors.secondary[80] : props.theme.colors.neutral[90])};
  padding: ${(props) => props.theme.spacings.xs1} ${(props) => props.theme.spacings.xs2};
  background-color: ${(props) =>
    props.isSelected ? props?.theme?.colors?.secondary?.[99] : props?.theme?.colors?.neutral?.[100]};
`;

export const IconFolderContainer = styled.div<SelectedStatusProps>`
  display: flex;
  align-items: center;
`;

export const ReportsNumberText = styled(Typography)<ReportsNumberTextProps>`
  color: ${(props) => getStatusColor(props.variantColor, props.theme.colors)};
  white-space: nowrap;
`;

export const ReportsStatusTitle = styled.div`
  width: 100%;
`;

export const ReportsStatusText = styled(Typography)`
  color: ${(props) => props.theme.colors.neutral[40]};
  white-space: nowrap;
  gap: ${({ theme }) => theme.spacings.xs5};
  display: flex;
`;

export const StatusDescriptionContainer = styled.div<SelectedStatusProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.theme.spacings.xs4};
  background-color: ${(props) => (props.isSelected ? 1 : 0.3)};
  width: 100%;
`;

export const ReportsExpensesText = styled(Typography)<SelectedStatusProps>`
  color: ${(props) => props.theme.colors.neutral[30]};
  white-space: nowrap;
  margin-top: ${({ theme }) => theme.spacings.xs5};
`;

export const ReportsValueText = styled(Typography)<SelectedStatusProps>`
  color: ${(props) => props.theme.colors.neutral[40]};
  display: flex;
  white-space: nowrap;
`;

export const SubtitleBox = styled.div`
  display: flex;
  align-items: baseline;
  gap: ${(props) => props.theme.spacings.xs4};
  margin-bottom: ${(props) => props.theme.spacings.xs3};
`;

export const Link = styled(LinkButton)`
  font-size: 12px;
`;

export const CardSkeletonTitle = styled.div`
  display: flex;
`;

export const CircleDivider = styled.div`
  border-radius: 50%;
  height: 4px;
  width: 4px;
  background-color: ${(props) => props.theme.colors.neutral[80]};
`;

function getStatusColor(variantColor: ColorVariant, colors: DefaultTheme["colors"]): string {
  const color = {
    primary: colors.primary,
    secondary: colors.secondary[40],
    tertiary: colors.tertiary[40],
    neutral: colors.neutral[30],
    info: colors.feedback.info[40],
    error: colors.feedback.error[40],
    success: colors.feedback.success[40],
  };
  return color[variantColor];
}
