import { createContext, useEffect } from 'react';
import { useContractsData } from '@containers/Subscription/hooks/useContractsData';
import { useCheckActiveModule } from '@flash-tecnologia/expense-web-activation-utils';
import {
  getFromLS,
  SelectedCompany,
  setInLS,
  usePermissions,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility';
import { companyNanoIdKeyLS } from '@shared/constant';
import { usePermissionsAuthZ } from '@shared/hooks/permissions/systemPermissions/usePermissionsAuthZ';
import { EAuthZPermission } from '@shared/types/authZPermission';

import { ISessionContext, ISessionProviderProps } from './types';

export const SessionContext = createContext<ISessionContext>(null);

export const SectionContextProvider = ({ children }: ISessionProviderProps) => {
  const permissionsData = usePermissions();
  const { selectedCompany } = useSelectedCompany();
  const { checkPermissionsBatch } = usePermissionsAuthZ();
  const token = getFromLS('hrosAccessToken');
  const company = selectedCompany as SelectedCompany;
  const { contracts, features, isDisabledExpense, isLoading: isContractLoading } = useContractsData();
  const isAdmin =
    permissionsData?.isAdmin || checkPermissionsBatch([EAuthZPermission.SETTINGS_CORE_COMPANY, EAuthZPermission.ADMIN]);
  const contractsData = contracts || getFromLS(`expensesAcceptanceTermsCompanyId-${selectedCompany?.id}`) || [];
  const featuresData = features || getFromLS(`expensesPlanFeatures-${selectedCompany?.id}`) || [];
  const isNewSAASEnabled = useCheckActiveModule('lifecycle');

  useEffect(() => {
    const companyNanoIdLS = getFromLS(companyNanoIdKeyLS);

    if (companyNanoIdLS && selectedCompany?.id && companyNanoIdLS !== selectedCompany?.id)
      window.location.assign('/home');

    setInLS({ key: companyNanoIdKeyLS, value: selectedCompany?.id });
  }, [selectedCompany?.id]);

  return (
    <SessionContext.Provider
      value={{
        employeeId: token?.employeeId,
        companyId: company?.id,
        companyName: company?.name,
        companyCNPJ: company?.registrationNumber,
        accessToken: token?.accessToken,
        isAdmin,
        contracts: contractsData,
        features: featuresData,
        isDisabledExpense: isDisabledExpense || isNewSAASEnabled,
        isContractLoading,
      }}>
      {children}
    </SessionContext.Provider>
  );
};
