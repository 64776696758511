import {
  Typography,
  TagFilter,
  Button,
} from '@flash-tecnologia/hros-web-ui-v2';
import { StatusEnum } from 'src/common/types/StatusEnum';
import {
  FilterContainer,
  ActionsContainer,
  Icons,
  Container,
  ActionButton,
  ActionButtonTextWrapper,
} from './styled';
import { useInvitationCompany } from './hooks/useInvitationCompany';
import { CustomAccessMenu } from '../CustomAccessMenu';
import { BatchActionsModal } from '@Components/BatchActionsModal';

const STANDARD_FILTER_SELECTION = [
  StatusEnum.ACTIVE,
  StatusEnum.INVITATION_EXPIRED,
  StatusEnum.INVITATION_PENDING,
  StatusEnum.INVITATION_SCHEDULED,
  StatusEnum.INVITATION_SENT,
];

export const TableActions = ({ onChange, filters }: any) => {
  const { resendMassIsOpen, onCloseResendModal, onOpenResendModal } =
    useInvitationCompany();

  const statusMapper = {
    Ativo: StatusEnum.ACTIVE,
    Inativo: StatusEnum.INACTIVE,
    'Aguardando convite': StatusEnum.INVITATION_PENDING,
    'Convite enviado': StatusEnum.INVITATION_SENT,
    'Convite expirado': StatusEnum.INVITATION_EXPIRED,
    'Convite agendado': StatusEnum.INVITATION_SCHEDULED,
  } as any;

  return (
    <>
      <Container>
        <FilterContainer>
          <Typography variant="body3">Filtrar por</Typography>
          <TagFilter
            hasLeftIcon={false}
            onApply={(values) => {
              onChange(
                'status',
                values.length > 0 ? values : STANDARD_FILTER_SELECTION,
              );
            }}
            onClear={() => onChange('status', STANDARD_FILTER_SELECTION)}
            options={Object.keys(statusMapper).map((key) => ({
              label: key,
              value: statusMapper[key],
            }))}
            optionIconType={'checkbox'}
            variant={'secondary'}
            filterLabel="Status"
            selectedOptions={filters?.status}
          />
        </FilterContainer>
        <ActionsContainer>
          <ActionButton
            onClick={onOpenResendModal}
            variant="secondary"
            size="small"
            variantType="neutral"
          >
            <ActionButtonTextWrapper>
              Reenviar convite em massa
              <Icons name="IconMailForward" fill="transparent" />
            </ActionButtonTextWrapper>
          </ActionButton>
          <CustomAccessMenu />
        </ActionsContainer>
      </Container>
      <BatchActionsModal
        isOpen={resendMassIsOpen}
        onClose={onCloseResendModal}
      />
    </>
  );
};
