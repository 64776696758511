import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const TitleStyled = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacings.xs};
`

export const SubTitlesContainerStyled = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.spacings.xs5};

  margin-bottom: ${({ theme }) => theme.spacings.s};
`
