import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledLinkButton = styled(LinkButton)`
  && {
    &.MuiButtonBase-root {
      color: ${({ theme }) => theme.colors.feedback.info[40]} !important;

      &::before {
        border-bottom: 2px solid
          ${({ theme }) => theme.colors.feedback.info[40]} !important;
      }
    }
  }
`;

export const EmptyStateArea = styled.div`
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  background: ${({ theme }) => theme.colors.neutral[100]};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 24px;
  gap: 24px;
  button {
    align-self: center !important;
  }
`;
