import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

interface EllipsisTextProps {
  lines: number
}

export const EllipsisText = styled(Typography)<EllipsisTextProps>`
  display: block;
  display: -webkit-box;
  line-height: ${({ lines }) => lines ?? 1};
  -webkit-line-clamp: ${({ lines }) => lines ?? 1};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: normal;
`
