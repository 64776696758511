import React from "react";

import { PageContainer as StyledPageContainer } from "./styled";
import { Loader } from "$atoms";

type PageContainerProps = {
  children: React.ReactNode;
  isLoading?: boolean;
};

export const PageContainer = (props: PageContainerProps) => {
  if (props.isLoading) return <Loader show={true} />;
  return <StyledPageContainer>{props.children}</StyledPageContainer>;
};
