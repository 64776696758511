import { AdminReimbursementsProvider, AdminStatementProvider, ReimbursementsProvider, StatementProvider } from "$pages";
import { combineComponents } from "../../utils/combine-components";
import { AdminPagesProvider } from "./AdminPagesContext";
/**
 * Combines multiple context providers into a single provider for the app.
 */
const providers = [
  AdminPagesProvider,
  AdminReimbursementsProvider,
  AdminStatementProvider,
  ReimbursementsProvider,
  StatementProvider,
];

export const AppContextProvider = combineComponents(...providers);
