import {
  Button,
  Icons,
  Modal,
  ShapeIcon,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { ModalContent } from './signup-success-modal.styles';

interface ModalProps {
  open: boolean;
  onConfirm: () => void;
}

export const SignUpSuccessModal = ({ open, onConfirm }: ModalProps) => {
  return (
    <Modal.Root open={open} onClose={() => {}} showClose={false} size="xs">
      <>
        <ModalContent>
          <ShapeIcon
            variant="default"
            name="IconCheck"
            color="var(--color-secondary-50)"
            strokeWidth="1.5px"
            size={64}
          />
          <div>
            <Typography variant="headline6" color="neutral.20">
              Sua empresa e seu perfil de administrador foram criados!
            </Typography>
            <Typography variant="body3" color="brand.50" weight={700}>
              Continue para finalizar a contratação
            </Typography>
          </div>
          <Typography variant="body4" color="neutral.50">
            A partir daqui, caso a contratação seja interrompida, você poderá
            acessar a sua conta pela{' '}
            <strong>
              pelo link de acesso à plataforma que acabamos de enviar para o seu
              e-mail
            </strong>{' '}
            com os dados cadastrados de CPF e senha para continuar de onde
            parou.
          </Typography>
        </ModalContent>
        <Modal.Footer style={{ padding: 0 }}>
          <Button
            size="large"
            variant="primary"
            onClick={onConfirm}
            style={{ margin: '0 auto' }}
          >
            Continuar contratação <Icons name="IconArrowRight" />
          </Button>
        </Modal.Footer>
      </>
    </Modal.Root>
  );
};
