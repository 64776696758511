import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import React from 'react'

export interface IBalancesTitleProps {
  children: string
}

export const BalancesTitle: React.FC<IBalancesTitleProps> = (props) => {
  return (
    <Typography variant="body3" weight={700} color="neutral.30">
      {props.children}
    </Typography>
  )
}
