import { QueryCache, QueryClient } from '@tanstack/react-query';
import { createTRPCReact, httpLink } from '@trpc/react-query';
import type { AppRouter } from 'bff';
import { getFromLS } from '@flash-tecnologia/hros-web-utility';
import { dispatchToast } from '@/utils';

export const trpc = createTRPCReact<AppRouter>();

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (_, query) => {
      if (query?.meta?.errorMessage) {
        dispatchToast({
          content: query?.meta?.errorMessage as string,
          type: 'error',
        });
      }
    },
  }),
});
export const trpcClient = trpc.createClient({
  links: [
    httpLink({
      url: `${process.env.BFF_URL}`,
      headers: async () => {
        const token = getFromLS('hrosAccessToken')?.accessToken;
        if (token)
          return {
            Authorization: `Bearer ${token}`,
            'x-flash-auth': `Bearer ${token}`,
          };
        return {};
      },
    }),
  ],
});
