import {
  IconButton,
  IconsProps,
  ShapeIcon,
  Typography,
  useMediaQuery,
} from '@flash-tecnologia/hros-web-ui-v2';
import * as SC from './styles';
import { SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

type Step = {
  icon: IconsProps['name'];
  title: string;
  description: string;
};

const steps: Step[] = [
  {
    icon: 'IconUserCheck',
    title: '1. Acesse sua conta na Flash',
    description:
      'Entre com seu login e senha ou crie uma conta para continuar a contratação.',
  },
  {
    icon: 'IconBuilding',
    title: '2. Preencha os dados da empresa',
    description:
      'Cadastre as informações da sua empresa para a criação do contrato.',
  },
  {
    icon: 'IconCheckbox',
    title: '3. Aceite os termos do contrato',
    description:
      'Agora é só ler e aceitar os termos de contratação e acessar a plataforma!',
  },
];

type StepsDescriptionSectionProps = {
  onCtaClick: () => void;
};

export const StepsDescriptionSection = ({
  onCtaClick,
}: StepsDescriptionSectionProps) => {
  const isMobile = useMediaQuery('(max-width: 1000px)');

  return (
    <SC.Section id="signup_steps_section">
      <SC.SectionContent>
        <SC.TextContent>
          <Typography variant="headline6">
            Conclua sua contratação em 3 passos:
          </Typography>
        </SC.TextContent>
        <SC.StepsContainer>
          <SC.Swiper
            spaceBetween={40}
            slidesPerView={isMobile ? 1 : 3}
            modules={[Navigation]}
            navigation={
              isMobile && {
                prevEl: '.swiper-prev-button',
                nextEl: '.swiper-next-button',
              }
            }
          >
            {steps.map((step) => (
              <SwiperSlide key={step.title}>
                <SC.StepContent>
                  <ShapeIcon
                    size={48}
                    name={step.icon}
                    variant="default"
                    color="var(--color-secondary-50)"
                    style={{ flexShrink: 0 }}
                  />
                  <Typography variant="body2" color="neutral.30" weight={700}>
                    {step.title}
                  </Typography>
                  <Typography variant="body3" color="neutral.30">
                    {step.description}
                  </Typography>
                </SC.StepContent>
              </SwiperSlide>
            ))}
          </SC.Swiper>
        </SC.StepsContainer>
        {isMobile && (
          <SC.NavigationButtons>
            <IconButton
              className="swiper-prev-button"
              variant="line"
              size="medium"
              icon="IconArrowLeft"
            />
            <IconButton
              className="swiper-next-button"
              variant="line"
              size="medium"
              icon="IconArrowRight"
            />
          </SC.NavigationButtons>
        )}
        <SC.Button
          size="large"
          variant="primary"
          onClick={onCtaClick}
          style={{ alignSelf: 'auto' }}
        >
          Continuar cadastro
        </SC.Button>
      </SC.SectionContent>
    </SC.Section>
  );
};
