import { ConfirmationModalV2 } from "@components/Modals";

import { StyledSubTitle } from "./styled";

import type { CommonModalProps } from "@components/Modals/types";

interface SendReminderModalProps extends CommonModalProps {
  origin: "idp" | "feedback";
  peopleToSend?: number;
}

export const SendReminderModal = ({
  open,
  isLoading,
  origin,
  peopleToSend = 1,
  onClose,
  onConfirm,
}: SendReminderModalProps) => {
  const isMultiple = peopleToSend > 1;

  const warning = (
    <span>
      <b>Aviso:</b> Pessoas sem e-mail corporativo não receberão o lembrete.
      Certifique-se de que todos os selecionados estão cadastrados corretamente.
    </span>
  );

  const singleMessage = <p>A pessoa selecionada será notificada via e-mail.</p>;

  const multipleMessage = () => {
    const messageByOrigin =
      origin === "idp"
        ? "que ainda não criaram seus PDIs serão notificadas via e-mail."
        : "que ainda não enviaram feedbacks à seus liderados serão notificadas via e-mail.";

    const feedbackMessage = (
      <span>
        Líderes selecionados que já concluíram o envio de todos os feedbacks não
        receberão este lembrete.
      </span>
    );

    const idpMessage = (
      <span>
        Pessoas selecionadas que já criaram seus PDIs não receberão este
        lembrete.
      </span>
    );

    return (
      <>
        <span>
          <b>{peopleToSend} pessoas</b> {messageByOrigin}
        </span>

        {origin === "feedback" && feedbackMessage}
        {origin === "idp" && idpMessage}
      </>
    );
  };

  return (
    <ConfirmationModalV2
      open={open}
      closeButton
      icon="IconInfoCircle"
      iconTitle="Atenção!"
      title="Tem certeza que deseja enviar lembrete?"
      subTitle={
        <StyledSubTitle>
          {!isMultiple && singleMessage}
          {isMultiple && multipleMessage()}
          {warning}
        </StyledSubTitle>
      }
      confirm={{ title: "Enviar lembrete" }}
      cancel={{ title: "Cancelar" }}
      onClose={onClose}
      onConfirm={onConfirm}
      isLoading={isLoading}
    />
  );
};
