import { dayjs } from '@flash-tecnologia/hros-web-ui-v2'
import { legacyGetFromLS } from '@flash-tecnologia/hros-web-utility'
import { t } from 'i18next'
import { ModalAlertConfirm } from 'src/components/modal-alert-confirm'
import { IOrderMethodConfirmationMap } from 'src/domain/checkout/pages/order-confirmation-page'
import { BilletConfirmation } from 'src/domain/checkout/presenters/order-method-confirmation/components/billet-confirmation/billet-confirmation'
import { FlashCashConfirmation } from 'src/domain/checkout/presenters/order-method-confirmation/components/flash-cash-confirmation/flash-cash-confirmation'
import { PixConfirmation } from 'src/domain/checkout/presenters/order-method-confirmation/components/pix-confirmation/pix-confirmation'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'

import { OrderLinks } from './components/order-links'
import { useOrderCompletion } from './hooks/use-order-completion'

const orderMethodConfirmationMap: IOrderMethodConfirmationMap = {
  [PaymentMethodEnum.BILLET]: BilletConfirmation,
  [PaymentMethodEnum.FLASH_CASH]: FlashCashConfirmation,
  [PaymentMethodEnum.PIX]: PixConfirmation,
}

export function OrderCompletion() {
  const {
    order,
    orderSummary,
    handleOpenNewTab,
    handleCloseModal,
    showFlashCashTopUpModal,
    redirectToBalancePage,
    cutOffDate,
  } = useOrderCompletion()
  const { emailAssociated } = legacyGetFromLS('clientData')

  const OrderMethodConfirmation =
    orderMethodConfirmationMap[order?.paymentMethod as PaymentMethodEnum]

  return (
    <>
      <OrderMethodConfirmation
        orderId={order?.id as string}
        amount={
          Number(orderSummary?.totalAmount) + Number(orderSummary?.totalFee)
        }
        depositDate={order?.depositDate as dayjs.Dayjs}
        dueDate={order?.dueDate as dayjs.Dayjs}
        email={emailAssociated}
        pixCode={order?.pixCode}
        openNewTab={handleOpenNewTab}
        OrderLinks={OrderLinks}
      />

      <ModalAlertConfirm.Root
        isOpen={showFlashCashTopUpModal}
        textCancel={t('order.flashCashTopUpModal.btnCancel')}
        textConfirm={t('order.flashCashTopUpModal.btnConfirm')}
        onClose={handleCloseModal}
        onConfirm={redirectToBalancePage}
      >
        <ModalAlertConfirm.Title>
          {t('order.flashCashTopUpModal.title')}
        </ModalAlertConfirm.Title>

        <ModalAlertConfirm.Description>
          {t('order.flashCashTopUpModal.message')}

          <br />
          <br />

          {t('order.flashCashTopUpModal.warning', {
            cutOffDate,
            interpolation: { escapeValue: false },
          })}
        </ModalAlertConfirm.Description>
      </ModalAlertConfirm.Root>
    </>
  )
}
