import { Dispatch, useEffect, SetStateAction, useState } from 'react';
import { LoaderContainer, StyledInboxMessageListWrapper } from './styles';
import { TabContentSkeleton } from '../TabContentSkeleton';
import { InboxMessageGroup } from '../InboxMessageGroup';
import { useGetInbox } from '@/hooks/use-get-inbox';
import {
  GroupedByTime,
  InboxMessage,
  InboxMessageWithGroup,
} from '../../types';
import { EmptyListIcon } from '../EmptyState/Icons/EmptyListIcon';
import { EmptyState } from '../EmptyState';
import { Loader } from '@flash-tecnologia/hros-web-ui-v2';
import { useAckAllInboxMessage } from '@/hooks/use-ack-all-inbox-messages';
import { getAccessTokenPayloadSync } from '@flash-tecnologia/hros-web-utility';

export const InboxMessageList = ({
  fetchInboxMessages,
  setFetchInboxMessages,
  opt: { invertTitle, uniqueList, showAckAll },
}: {
  fetchInboxMessages: boolean;
  setFetchInboxMessages: Dispatch<SetStateAction<boolean>>;
  opt: {
    invertTitle: boolean;
    uniqueList: boolean;
    showAckAll: boolean;
  };
}) => {
  const [formattedInboxMessages, setFormattedInboxMessages] = useState<
    Record<GroupedByTime, InboxMessageWithGroup[]>
  >({
    today: [],
    yesterday: [],
    others: [],
  });
  const [query, setQuery] = useState<{
    page: number;
    limit: number;
  }>({
    page: 1,
    limit: 10,
  });

  const { mutate: ackAllInboxMessages } = useAckAllInboxMessage();
  const { isLoading, data, parsedData: inboxResponse } = useGetInbox(query);

  const inboxMessagesTotalCount = inboxResponse?.metadata?.totalCount ?? 0;

  const mergeInboxMessages = (
    oldInboxMessages: InboxMessage[],
    newInboxMessages: InboxMessage[],
  ) => {
    const localInboxMessages = [...oldInboxMessages];
    const localNewInboxMessages = [...newInboxMessages];

    localInboxMessages.forEach((inboxMessage, index) => {
      const inboxMessageToReplaceIndex = localNewInboxMessages.findIndex(
        (newInboxMessage) => newInboxMessage.id === inboxMessage.id,
      );

      if (inboxMessageToReplaceIndex >= 0) {
        const [inboxMessageToReplace] = localNewInboxMessages.splice(
          inboxMessageToReplaceIndex,
          1,
        );

        localInboxMessages[index] = inboxMessageToReplace;
      }
    });
    return [...localInboxMessages, ...localNewInboxMessages];
  };

  const { employeeId } = getAccessTokenPayloadSync();

  const onClickAckAllMessages = () => {
    ackAllInboxMessages({
      assignedTo: employeeId,
    });

    setFormattedInboxMessages((oldValue) => {
      oldValue.today = oldValue.today.map((inboxMessage) => ({
        ...inboxMessage,
        acknowledged: true,
      }));
      oldValue.yesterday = oldValue.yesterday.map((inboxMessage) => ({
        ...inboxMessage,
        acknowledged: true,
      }));
      oldValue.others = oldValue.others.map((inboxMessage) => ({
        ...inboxMessage,
        acknowledged: true,
      }));
      return oldValue;
    });
  };

  const isEmpty =
    formattedInboxMessages.today.length === 0 &&
    formattedInboxMessages.yesterday.length === 0 &&
    formattedInboxMessages.others.length === 0;

  useEffect(() => {
    if (
      inboxResponse?.records.today.length ||
      inboxResponse?.records.yesterday.length ||
      inboxResponse?.records.others.length
    ) {
      setFormattedInboxMessages((oldValue) => {
        const today = mergeInboxMessages(
          oldValue.today,
          inboxResponse?.records.today,
        );
        const yesterday = mergeInboxMessages(
          oldValue.yesterday,
          inboxResponse?.records.yesterday,
        );
        const others = mergeInboxMessages(
          oldValue.others,
          inboxResponse?.records.others,
        );

        return { today, yesterday, others };
      });
    }
  }, [data]);

  useEffect(() => {
    if (fetchInboxMessages && !isLoading && inboxResponse.metadata?.nextPage) {
      const nextPage = inboxResponse.metadata.nextPage;
      setQuery((oldValue) => ({
        page: nextPage,
        limit: oldValue.limit,
      }));
    }
    setFetchInboxMessages(false);
  }, [fetchInboxMessages]);

  if (isLoading && isEmpty) {
    return (
      <>
        <TabContentSkeleton length={4} height={74} />
      </>
    );
  }

  if (inboxMessagesTotalCount <= 0 && isEmpty) {
    return (
      <EmptyState
        title="Você ainda não possui mensagens ou avisos da Flash"
        subtitle="Suas mensagens e avisos diversos serão exibidos aqui."
        icon={<EmptyListIcon />}
      />
    );
  }

  return (
    <>
      <StyledInboxMessageListWrapper>
        {uniqueList ? (
          <InboxMessageGroup
            inboxMessages={[
              ...formattedInboxMessages.today,
              ...formattedInboxMessages.yesterday,
              ...formattedInboxMessages.others,
            ]}
            opt={{ invertTitle, showAckAll }}
          />
        ) : (
          <>
            {formattedInboxMessages.today.length ? (
              <InboxMessageGroup
                title={'Hoje'}
                inboxMessages={formattedInboxMessages.today}
                onClickAckAllMessages={onClickAckAllMessages}
                opt={{ invertTitle, showAckAll }}
              />
            ) : null}
            {formattedInboxMessages.yesterday.length ? (
              <InboxMessageGroup
                title={'Ontem'}
                inboxMessages={formattedInboxMessages.yesterday}
                onClickAckAllMessages={onClickAckAllMessages}
                opt={{
                  invertTitle,
                  showAckAll:
                    showAckAll && !formattedInboxMessages.today.length,
                }}
              />
            ) : null}
            {formattedInboxMessages.others.length ? (
              <InboxMessageGroup
                title={'Antigas'}
                inboxMessages={formattedInboxMessages.others}
                onClickAckAllMessages={onClickAckAllMessages}
                opt={{
                  invertTitle,
                  showAckAll:
                    showAckAll &&
                    !formattedInboxMessages.today.length &&
                    !formattedInboxMessages.yesterday.length,
                }}
              />
            ) : null}
          </>
        )}
        {isLoading && (
          <LoaderContainer>
            <Loader variant="primary" size="small" />
          </LoaderContainer>
        )}
      </StyledInboxMessageListWrapper>
    </>
  );
};
