import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import React from "react";

import { Button } from "./styled";

type Props = {
  /** Typography's label */
  label: string;
  /** Icon on the left of the button's label */
  leftIcon?: React.ComponentProps<typeof Icons>["name"];
  /** Icon on the left of the button's label */
  rightIcon?: React.ComponentProps<typeof Icons>["name"];
  /** Changes the aspect of the tag */
  status: "active" | "neutral" | "disabled";
  /** Called when the button is clicked */
  onClick?: (_event: React.MouseEvent<HTMLButtonElement>) => void;
};

export function TagButton(props: Props) {
  return (
    <Button status={props.status} onClick={props.status === "disabled" ? undefined : props.onClick}>
      {props.leftIcon && <Icons name={props.leftIcon} fill="transparent" />}
      <Typography variant="body3" color="inherit" weight={600}>
        {props.label}
      </Typography>
      {props.rightIcon && <Icons name={props.rightIcon} fill="transparent" />}
    </Button>
  );
}
