import { Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { AccordionActive, RowValue } from "../../components"
import { useTheme } from "styled-components"
import { useNavigate } from "react-router-dom"
import { NewComments, NotificationsConfig } from "bff/src/types/hiring"
import { trpc } from "src/api/client"
import { Flow } from "src/types"
import dispatchToast from "src/utils/dispatchToast"
import { useTranslation } from "react-i18next"
import { PendoEnum, replacePendoVariable } from "@pendo"
import { useTracking } from "@utils/useTracking"

const columns = [
  "Pessoas",
  "Carta proposta",
  "Envio de documentos",
  "Validação de documentos",
  "Exame admissional",
  "Assinatura de contrato",
  "Concluído",
  "Configurações iniciais",
  "Arquivado",
]

const getDefault = (override?: NewComments): NewComments => ({
  active: false,
  notifyFollowers: true,
  notifyLeaders: true,
  notifyMentions: true,
  ...override,
})

type CommentaryProps = {
  notificationConfig: NotificationsConfig
  flow: Flow
}

export const Commentary = ({ notificationConfig, flow }: CommentaryProps) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const utils = trpc.useContext()
  const tracking = useTracking()

  const [t] = useTranslation("translations", {
    keyPrefix: "page.settings.notification.commentary",
  })

  const { mutateAsync: updateConfig } =
    trpc.notification.updateNotificationConfig.useMutation()

  const handleActive = async () => {
    try {
      const isActive = notificationConfig.newComment?.active
      await updateConfig({
        notificationConfigId: notificationConfig._id,
        body: {
          newComment: {
            ...getDefault(notificationConfig.newComment),
            active: !isActive,
          },
        },
      })
      tracking.trackEvent({
        name: `hiring_settings_notification_commentary_${isActive ? "inactivated" : "activated"}`,
      })
      utils.notification.getNotificationConfig.invalidate()
    } catch (err: any) {
      dispatchToast({
        content: err.message,
        type: "error",
      })
    }
  }

  return (
    <AccordionActive
      dataOnboarding={replacePendoVariable(
        PendoEnum.MODEL_NOTIFICATION_ACCORDION_CONTENT,
        [
          {
            name: "$content",
            value: "commentary",
          },
        ],
      )}
      title={t("title")}
      description={t("description")}
      actions={{
        active: {
          checked: notificationConfig.newComment?.active ?? false,
          handleClick: handleActive,
          dataOnboarding: replacePendoVariable(
            PendoEnum.MODEL_NOTIFICATION_ACCORDION_DISABLE_BUTTON,
            [
              {
                name: "$content",
                value: "commentary",
              },
            ],
          ),
        },
        edit: {
          handleClick: () =>
            navigate(`/flows/settings/notification/commentary/${flow._id}`),
        },
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
        <Typography
          variant="headline9"
          variantColor={theme.colors.neutral[30]}
          weight={700}
        >
          {t("subtitle")}
        </Typography>

        <RowValue
          title={t("notifyLeader")}
          value={
            notificationConfig.newComment?.notifyLeaders
              ? t("values.yes")
              : t("values.no")
          }
        />
        <RowValue
          title={t("notifyFollowers")}
          value={
            notificationConfig.newComment?.notifyFollowers
              ? t("values.yes")
              : t("values.no")
          }
        />
        <RowValue
          title={t("notifyMentions")}
          value={
            notificationConfig.newComment?.notifyMentions
              ? t("values.yes")
              : t("values.no")
          }
        />

        <div
          style={{
            width: "100%",
            borderBottom: `1px solid ${theme.colors.neutral[90]}`,
          }}
        />

        <Typography
          variant="headline9"
          variantColor={theme.colors.neutral[30]}
          weight={700}
        >
          {t("stepsLabel")}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 5,
          }}
        >
          {columns.map((value, idx) => (
            <Tag
              key={`tag_${idx}`}
              variant="pink"
              style={{ whiteSpace: "nowrap", fontWeight: 600, fontSize: 14 }}
            >
              {value}
            </Tag>
          ))}
        </div>
      </div>
    </AccordionActive>
  )
}
