import Flex from '@frontend/components/frames/Flex';
import SummaryFeedback from '@frontend/components/SummaryFeedback';
import { formatDate } from '@frontend/utils/masks';
import { useMemo } from 'react';
import { useCreateVirtualCardContext } from '../../../data/useCreateVirtualCardContext';
import { SummaryDetailsItem } from './SumaryDetailsItem';

import LinkButton from '@frontend/components/LinkButton';
import ModalService from '@frontend/services/ModalService';
import EmployeesDetailsModal from './EmployeesDetailsModal/EmployeesDetailsModal';
import * as SC from './styled';

export function Summary() {
  const context = useCreateVirtualCardContext();

  const policy = useMemo(() => {
    const policyId = context.form.getValues('policyId');

    return !!policyId
      ? context.policies.find((policy) => policy.value === policyId)
      : null;
  }, []);

  const expirationDate = useMemo(() => {
    const expirationDate = context.form.getValues('expirationDate');

    return expirationDate
      ? `Data de expiração: ${formatDate(expirationDate)}`
      : null;
  }, []);

  const employees = useMemo(() => {
    const employeeIds = context.form.getValues('employeeIds');

    return context.employees.filter((employee) => employeeIds.has(employee.id));
  }, [context.employees]);

  function renderEmployeeNames() {
    if (!employees.length) {
      return 'Erro ao obter o nome das pessoas';
    }

    if (employees.length <= 3) {
      return employees.map((employee) => employee.name).join(', ');
    }

    const employeesNameToRenderInSummary = employees
      .slice(0, 3)
      .map((employee) => employee.name)
      .join(', ');

    return `${employeesNameToRenderInSummary} +${employees.length - 3}`;
  }

  return (
    <SummaryFeedback
      title={renderEmployeeNames()}
      icon="IconCircleCheck"
      onClickViewMore={
        employees.length > 3
          ? () => ModalService.show(EmployeesDetailsModal, { employees })
          : undefined
      }
    >
      {!context.error?.createdCard && (
        <Flex padding="xs2">
          <SC.DescriptionContainer>
            <SummaryDetailsItem
              title={
                context.error?.linkPolicy
                  ? 'Não foi possível vincular a política'
                  : 'Política vinculada'
              }
              icon="IconLink"
              variant={context.error?.linkPolicy ? 'error' : 'neutral'}
              description={
                context.error?.linkPolicy ? (
                  <LinkButton onClick={() => null} variant="error" size="sm">
                    Tentar novamente
                  </LinkButton>
                ) : (
                  policy?.label ?? 'Nenhuma política vinculada'
                )
              }
            />

            {expirationDate && (
              <SummaryDetailsItem
                title="Cartão temporário"
                icon="IconCalendarStats"
                description={expirationDate}
              />
            )}
          </SC.DescriptionContainer>
        </Flex>
      )}
    </SummaryFeedback>
  );
}
