import '../src/i18n';

import { Error } from '@components/Error';
import { ThemeProvider } from '@flash-tecnologia/hros-web-ui-v2';
import { TrpcProvider } from '@services/corporateCard';

import AppRouter from './routes';

export default function Root() {
  return (
    <ThemeProvider>
      <Error.Boundary moduleName="Corporativo / Extrato">
        <TrpcProvider>
          <AppRouter />
        </TrpcProvider>
      </Error.Boundary>
    </ThemeProvider>
  );
}
