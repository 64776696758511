import Flex from '@frontend/components/frames/Flex';
import TextArea from '@frontend/components/inputs/TextArea';
import ConfirmationModal from '@frontend/components/modals/ConfirmationModal';
import Typography from '@frontend/components/Typography';
import ModalService from '@frontend/services/ModalService';
import { useState } from 'react';
import { useReproveRequest } from '../data/useReproveRequest';

type Props = {
  orderIds: Array<string>;
  employee?: string;
};

function ReproveRequestModal(props: Props) {
  const [justification, setJustification] = useState<string>('');
  const modalController = ModalService.useModalController();
  const { loading, reproval } = useReproveRequest({
    multiple: props.orderIds.length > 1,
    closeModal: modalController.remove,
  });

  return (
    <ConfirmationModal
      loading={loading}
      buttons={{
        confirm: {
          text: 'confirmar reprovação',
          onClick: () => {
            reproval({ orderIds: props.orderIds, justification });
          },
        },
        cancel: {
          text: 'Cancelar',
          onClick: () => {
            modalController.remove();
          },
        },
      }}
      variant="error"
      text={{
        title: `Tem certeza que deseja reprovar ${props.orderIds.length > 1 ? 'os pedidos selecionados?' : `o pedido de ${props.employee || ''}?`}`,
        highlightedText: 'Atenção!',
        body: (
          <Flex direction="column" gap="xs2">
            <Typography.Body4 color="neutral_40">
              {props.orderIds.length > 1
                ? 'Os pedidos de saldo serão cancelados e os solicitantes receberão uma notificação com o motivo da reprovação.'
                : 'O pedido será cancelado e o colaborador receberá uma notificação sobre a reprovação'}
            </Typography.Body4>
            <TextArea
              value={justification}
              onChange={(e) => setJustification(e.target.value)}
              maxLength={250}
              label="Informe o motivo da reprovação"
            />
          </Flex>
        ),
      }}
      isOpen={modalController.visible}
      onClose={modalController.remove}
    />
  );
}

export default ModalService.create(ReproveRequestModal);
