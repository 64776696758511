import { trpc } from '@api/client';

export const useGetTotalNewNotifications = () => {
  const { isLoading, data } = trpc.getTotalNewNotificationsRouter.useQuery(
    undefined,
    { refetchOnWindowFocus: false },
  );

  return {
    data,
    isLoading,
  };
};
