import { useMemo } from 'react';
import { SubscriptionInfoItem } from '@containers/Subscription/components/molecules/SubscriptionInformation/SubscriptionInformation';
import { CorporateCardContractInformation } from '@containers/Subscription/context/types/contractInformation';
import { useValueFormatter } from '@shared/hooks';

export function useCorporateCardConditions(
  corporateCardContract: CorporateCardContractInformation | undefined,
  isNewSAAS: boolean,
) {
  const { getValueWithCurrency } = useValueFormatter();

  const additionalCardValue = getValueWithCurrency({
    value: corporateCardContract?.conditions?.pricePerAdditionalCard ?? 18.99,
    currencyPrefix: 'R$',
    numberIfFull: true,
  });

  const freeCardQuantity = corporateCardContract?.conditions?.freeCardQuantity || (isNewSAAS ? 5 : 3);

  const corporateCardItems = useMemo(() => {
    const items: SubscriptionInfoItem[] = [];

    if (corporateCardContract?.conditions?.additionalCardQuantity) {
      items.push({
        included: true,
        name: `${corporateCardContract?.conditions?.additionalCardQuantity} cartões adicionais`,
      });

      items.push({
        included: true,
        name: `Cartões adicionais - ${additionalCardValue} por cartão emitido`,
      });
    }

    items.push(
      {
        included: true,
        name: 'Envio de cartão - Gratuito',
      },
      {
        included: true,
        name: `Segunda via de cartão - ${additionalCardValue} por cartão emitido`,
      },
    );

    items.push({
      included: true,
      name: 'Saque disponível',
    });

    return items;
  }, [corporateCardContract]);

  return {
    corporateCardItems,
    additionalCardValue,
    freeCardQuantity,
  };
}
