import {
  TagFilter as UITagFilter,
  Typography,
  TagDateFilter as UITagDateFilter,
} from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";
import { Filter } from "../../dataSource/useGetBills";

type TagFiltersProps = {
  filter: Filter;
  setFilterField: (field: keyof Filter) => (value) => void;
};

const TagFiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(p) => p.theme.spacings.xs3};
  margin-bottom: ${(p) => p.theme.spacings.xs};
`;

const TagFiltersText = styled(Typography)`
  color: ${(p) => p.theme.colors.neutral[40]};
`;

const TagFilter = (props) => (
  <UITagFilter
    variant="secondary"
    optionIconType="checkbox"
    disableOptionsSearch
    disableOptionsFooter
    hasLeftIcon={false}
    {...props}
  />
);

const TagDateFilter = (props) => (
  <UITagDateFilter variant="secondary" hasLeftIcon={false} {...props} />
);

const setDueDateRange = (setFilterField, selectedRange) => {
  const { from, to } = selectedRange;
  const startDate = from ? new Date(from).toISOString() : "";
  const endDate = to ? new Date(to).toISOString() : "";
  setFilterField("startDueDate")(startDate);
  setFilterField("endDueDate")(endDate);
};

export const TagFilters = ({ filter, setFilterField }: TagFiltersProps) => (
  <TagFiltersContainer>
    <TagFiltersText variant="body3">Filtrar por</TagFiltersText>
    {/* <TagFilter
      filterLabel="Produto"
      onChange={setFilterField("businessUnits")}
      options={[
        {
          label: "Flash Benefícios",
          value: "BENEFITS",
        },
        {
          label: "Flash Expense",
          value: "EXPENSE_MANAGEMENT",
        },
        {
          label: "Flash People",
          value: "PEOPLE",
        },
        {
          label: "Flash Parcerias",
          value: "FLASH_HUB",
        },
      ]}
      selectedOptions={filter.businessUnits || []}
    /> */}
    <TagFilter
      filterLabel="Status"
      onChange={setFilterField("status")}
      options={[
        {
          label: "Em espera",
          value: "PENDING_PAYMENT",
        },
        {
          label: "Pago",
          value: "PAID",
        },
        {
          label: "Cancelado",
          value: "CANCELLED",
        },
      ]}
      selectedOptions={filter.status || []}
    />
    <TagDateFilter
      filterLabel="Data de vencimento"
      onSubmit={(selectedRange) =>
        setDueDateRange(setFilterField, selectedRange)
      }
    />
  </TagFiltersContainer>
);
