export const graphs = ({
  theme,
  secondGraph = [],
  thirdGraph = [],
  fourthGraph = [],
}: {
  theme: any;
  secondGraph: { name: string; amt: number }[];
  thirdGraph: { name: string; amt: number }[];
  fourthGraph: { name: string; amt: number }[];
}) => [
  {
    id: "secondgraph",
    info: {
      title: "Qual das opções abaixo melhor descreve seu sentimento?",
      metric: "",
      explanation: "Qual das opções abaixo melhor descreve seu sentimento?",
    },
    settings: {
      name: "quantidade",
      color: "#FE2B8F",
      showAll: false,
    },
    data: secondGraph,
  },
  {
    id: "thirdgraph",
    info: {
      title: "O que foi bom?",
      metric: "",
      explanation: "",
    },
    settings: {
      name: "quantidade",
      color: "#FE2B8F",
      showAll: false,
    },
    data: thirdGraph,
  },
  {
    id: "fourthgraph",
    info: {
      title: "O que poderia melhorar?",
      metric: "",
      explanation: "",
    },
    settings: {
      name: "quantidade",
      color: "#FE2B8F",
      showAll: false,
    },
    data: fourthGraph,
  },
];
