import { getAccessToken, useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createTRPCReact, httpBatchLink } from "@trpc/react-query";
import React, { useState } from "react";
import superjson from "superjson";

import type { AppRouter } from "../../../server/src/app";
import EnvService from "../services/EnvService";
import { services } from "../utils/services";

const baseUrl = services.bff[EnvService.BUILD_ENV];

export const trpc = createTRPCReact<AppRouter>();

export function TrpcProvider({ children }: { children: React.ReactNode }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        logger: {
          log: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
          warn: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
          error: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
        },
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
          },
        },
      }),
  );
  const company = useSelectedCompany();

  const trpcClient = trpc.createClient({
    transformer: superjson,
    links: [
      httpBatchLink({
        url: `${baseUrl}/trpc`,
        async headers() {
          const accessToken = await getAccessToken();
          return {
            "X-Flash-Auth": `Bearer ${accessToken}`,
            "company-id": company.selectedCompany.id,
          };
        },
      }),
    ],
  });

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
}
