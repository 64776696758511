import { Benefit } from '../../steps/employee-selection/types'

export const updateBenefits = (
  currentBenefits: Benefit[],
  updatedBenefits: Benefit[],
) => {
  const benefitMap = new Map(
    currentBenefits.map((benefit) => [benefit.id, benefit]),
  )

  updatedBenefits.forEach((updatedBenefit) => {
    if (benefitMap.has(updatedBenefit.id)) {
      benefitMap.set(updatedBenefit.id, {
        ...(benefitMap.get(updatedBenefit.id) as Benefit),
        value: updatedBenefit.value,
      })
    } else {
      benefitMap.set(updatedBenefit.id, updatedBenefit)
    }
  })

  return Array.from(benefitMap.values())
}
