import { Spinner } from '@flash-tecnologia/hros-web-ui-v2'
import { getAccessTokenPayloadSync } from '@flash-tecnologia/hros-web-utility'
import { Navigate, Outlet, useNavigation } from 'react-router-dom'
import { dispatchToast } from 'src/utils/dispatchEvents'

export const OpenRoutesGuard = () => {
  const { state } = useNavigation()

  const isLoggedIn = getAccessTokenPayloadSync()?.employeeId
  if (!isLoggedIn) {
    dispatchToast({
      content: 'Sessão expirada',
      type: 'error',
      description: 'Por favor, realize o login novamente.',
    })
    return <Navigate to={'/authentication/login'} />
  }

  return state === 'loading' ? (
    <Spinner size={32} variant="primary" />
  ) : (
    <Outlet />
  )
}
