import AuthService from '@frontend/services/AuthService';
import { trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import { toCurrency } from '@frontend/utils/masks';
import { DateTime } from 'luxon';
import { useState } from 'react';
import writeXlsxFile from 'write-excel-file';
import { TABLE_COLUMNS } from '../table/columns';

export function useSheetTable() {
  const [dispatch, setDispatch] = useState(false);

  const company = AuthService.useCompany();

  const { isInitialLoading, isRefetching, isStale } =
    trpc.corporateCard.requestBalance.search.useQuery(
      { filter: {}, pagination: { currentPage: 0, pageSize: 100 } },
      {
        enabled: dispatch,
        onError: () => {
          dispatchToast({
            type: 'error',
            content:
              'Não foi possível baixar planilha, tente novamente mais tarde. Caso o erro persista entre em contato com suporte.',
          });
        },
        onSuccess: async (data) => {
          const sheetData = [
            TABLE_COLUMNS.map(
              (field) =>
                ({
                  value: field,
                  fontWeight: 'bold',
                  backgroundColor: '#BFBFBF',
                }) as const,
            ),
            ...data.entries.map<{ value: string }[]>((entry) => [
              { value: entry.employee.name },
              { value: entry.reason ?? '-' },
              { value: toCurrency(entry.value) },
              { value: entry.status.label },
              {
                value: DateTime.fromJSDate(entry.createdAt).toLocaleString(),
              },
              {
                value: DateTime.fromJSDate(entry.creditDate).toLocaleString(),
              },
            ]),
          ];

          await writeXlsxFile(sheetData, {
            columns: TABLE_COLUMNS.map((field: string, index: number) => ({
              width: index !== 1 ? field.length + 6 : 50,
            })),
            fileName: `pedido_de_carga_${
              company?.registrationNumber
            }_${DateTime.now().toLocaleString()}.xlsx`,
          });

          setDispatch(false);
        },
      },
    );

  return {
    dispatch: () => setDispatch(true),
    loading: isInitialLoading || (isStale && isRefetching),
  };
}
