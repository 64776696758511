import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

type Row = {
  id: string
} & Record<string, unknown>

type UseRowSelectionProps<TRow extends Row> = {
  filteredRows: TRow[]
  allData: TRow[]
  initialSelectedRows?: string[]
}

export function useRowSelection<TRow extends Row>({
  filteredRows,
  allData,
  initialSelectedRows = [],
}: UseRowSelectionProps<TRow>) {
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set())
  const [selectAll, setSelectAll] = useState(false)

  const hasInitialized = useRef(false)

  // Verifica se todas as rows estão selecionadas
  const areAllSelected = useMemo(() => {
    return filteredRows.every((row) => selectedRows.has(row.id))
  }, [filteredRows, selectedRows])

  // Verifica se o header está no estado indeterminate
  const isHeaderIndeterminate = useMemo(() => {
    const hasSelection = selectedRows.size > 0

    return hasSelection && !areAllSelected
  }, [selectedRows.size, areAllSelected])

  // Verifica se uma row está selecionada
  const isRowSelected = useCallback(
    (rowId: string) => {
      return selectedRows.has(rowId)
    },
    [selectedRows],
  )

  // Alterna a seleção de todas as rows e subRows
  const toggleSelectAll = useCallback(() => {
    setSelectedRows((prevSelectedRows) => {
      if (selectAll || areAllSelected) {
        return new Set(
          [...prevSelectedRows].filter(
            (id) => !filteredRows.some((row) => row.id === id),
          ),
        )
      }

      return new Set([
        ...prevSelectedRows,
        ...filteredRows.map((row) => row.id),
      ])
    })
  }, [selectAll, areAllSelected, filteredRows])

  // Alterna a seleção de um única row
  const toggleRowSelection = useCallback((rowId: string) => {
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = new Set(prevSelectedRows)

      if (newSelectedRows.has(rowId)) {
        newSelectedRows.delete(rowId)
      } else {
        newSelectedRows.add(rowId)
      }

      return newSelectedRows
    })
  }, [])

  // Limpa seleção
  const clearSelectedRows = useCallback(() => {
    setSelectedRows((prevSelectedRows) => {
      const filteredRowIds = new Set(filteredRows.map((row) => row.id))

      const newSelectedRows = new Set(
        Array.from(prevSelectedRows).filter((id) => !filteredRowIds.has(id)),
      )

      return newSelectedRows
    })
  }, [filteredRows])

  const memorizationSelectedRows = useMemo(
    () => Array.from(selectedRows),
    [selectedRows],
  )

  const selectedData = useMemo(
    () => allData.filter((row) => selectedRows.has(row.id)),
    [allData, selectedRows],
  )

  useEffect(() => {
    setSelectAll(areAllSelected)
  }, [areAllSelected])

  useEffect(() => {
    if (!hasInitialized.current && initialSelectedRows.length) {
      setSelectedRows(new Set(initialSelectedRows))

      hasInitialized.current = true
    }
  }, [initialSelectedRows])

  return {
    selectedRows: memorizationSelectedRows,
    selectedData,
    selectAll,
    isHeaderIndeterminate,
    isRowSelected,
    areAllSelected,
    toggleSelectAll,
    toggleRowSelection,
    clearSelectedRows,
  }
}
