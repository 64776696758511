import {
  GroupedByTime,
  InboxMessageWithGroup,
} from '@/pages/AdminHome/components/QuickAccessSection/components/InboxWidget/types';
import { trpc } from '@api/client';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';

export const useGetInbox = (input: { page: number; limit: number }) => {
  const { selectedCompany } = useSelectedCompany();
  const { isLoading, data } = trpc.getInbox.useQuery(
    {
      page: input.page,
      limit: input.limit,
      sortBy: 'createdAt',
      order: 'desc',
      selectedCompanyId: selectedCompany.id,
    },
    { refetchOnWindowFocus: false },
  );

  const inboxMessages: Record<GroupedByTime, InboxMessageWithGroup[]> = {
    today: [],
    yesterday: [],
    others: [],
  };

  const parseInbox = (inboxMessage) => {
    const { deletedAt, ...remainingInboxMessageProps } = inboxMessage;
    return {
      ...remainingInboxMessageProps,
      createdAt: new Date(inboxMessage.createdAt),
      updatedAt: new Date(inboxMessage.createdAt),
      ...(deletedAt !== undefined && {
        deletedAt: new Date(deletedAt),
      }),
    };
  };

  inboxMessages.today = data?.records.today.map(parseInbox) || [];
  inboxMessages.yesterday = data?.records.yesterday.map(parseInbox) || [];
  inboxMessages.others = data?.records.others.map(parseInbox) || [];

  return {
    data,
    parsedData: {
      records: inboxMessages,
      metadata: data?.metadata,
    },
    isLoading,
  };
};
