import { toCurrency } from '@frontend/utils/masks';
import { DateTime } from 'luxon';

type Input = {
  creditDate: Date;
  deposits: Array<{
    documentNumber: string;
    value: number;
    id: string;
    name: string;
  }>;
  paymentMethod: 'FLASH_CASH' | 'PIX' | 'BILLET';
  automatic?: boolean;
  complementary?: boolean;
  expires?: boolean;
};

export function userDetailData(input: Input) {
  /* ---------------------------- Selected people --------------------------- */
  if (input.deposits.length > 0) {
    const depositNames = input.deposits
      .slice(0, 2)
      .map((deposit) => deposit.name)
      .join(', ');
    const additionalCount = input.deposits.length - 2;

    const formattedAsText =
      additionalCount > 0
        ? `${depositNames} + ${additionalCount} pessoas`
        : depositNames;

    return {
      formattedAsText,
      depositDetails: formatDepositDetails({
        creditDate: input.creditDate,
        deposits: input.deposits,
        paymentMethod: input.paymentMethod,
        automatic: input.automatic,
        complementary: input.complementary,
        expires: input.expires,
      }),
    };
  }

  return {
    formattedAsText: 'Dados indisponíveis',
    depositDetails: null,
  };
}

/* ----------------------- Deposit Details Formatting ----------------------- */
function formatDepositDetails(input: {
  deposits: Array<{
    value: number;
    id: string;
  }>;
  paymentMethod: 'FLASH_CASH' | 'PIX' | 'BILLET';
  creditDate: Date;
  automatic?: boolean;
  complementary?: boolean;
  expires?: boolean;
}) {
  if (!input.deposits.length) return null;

  const paymentMethodLabels: Record<'FLASH_CASH' | 'PIX' | 'BILLET', string> = {
    FLASH_CASH: 'Carteira Corporativa',
    PIX: 'Pix',
    BILLET: 'Boleto Bancário',
  };

  const paymentMethodIcons: Record<
    'FLASH_CASH' | 'PIX' | 'BILLET',
    'IconWallet' | 'Pix' | 'BarcodeMethod'
  > = {
    FLASH_CASH: 'IconWallet',
    PIX: 'Pix',
    BILLET: 'BarcodeMethod',
  };

  const label = paymentMethodLabels[input.paymentMethod];
  const icon = paymentMethodIcons[input.paymentMethod];

  const creditDate = DateTime.fromJSDate(input.creditDate, {
    zone: 'America/Sao_Paulo',
  }).startOf('day');

  const creditDateDescription = formatCreditDateDescription(
    creditDate,
    input.automatic,
  );

  const depositType = formatDepositType({
    automatic: input.automatic,
    complementary: input.complementary,
    expires: input.expires,
  });

  const totalAmount = formatTotalAmount(input.deposits);

  const uniqueValues = formatUniqueValues(input.deposits);

  const individualAmount =
    uniqueValues.size > 1
      ? 'Diversos valores por pessoa'
      : `${toCurrency([...uniqueValues][0] || 0)} por pessoa`;

  return {
    creditDateDescription,
    depositType,
    totalAmount,
    individualAmount,
    paymentMethod: input.paymentMethod,
    label,
    icon,
  };
}

/* ----------------------- Deposit Details Formatting ----------------------- */
function formatCreditDateDescription(
  creditDate: DateTime | null,
  automatic?: boolean,
): string | null {
  if (!creditDate?.isValid) return null;

  if (automatic) {
    return `Todo dia ${creditDate.toFormat('dd')}, a partir de ${creditDate.toFormat('dd/MM/yyyy')}`;
  }

  return creditDate.toFormat('dd/MM/yyyy');
}

function formatDepositType(deposit: {
  automatic?: boolean;
  complementary?: boolean;
  expires?: boolean;
}): string {
  if (!deposit) return 'Depósito único';

  if (deposit.automatic) {
    return deposit.complementary
      ? 'Depósito recorrente complementar'
      : 'Depósito recorrente';
  }

  if (deposit.expires) {
    return 'Depósito único com expiração de saldo';
  }

  return 'Depósito único';
}

function formatTotalAmount(deposits: Array<{ value: number }>): string {
  if (deposits) {
    const total = deposits.reduce((acc, curr) => acc + curr.value, 0);
    return `${toCurrency(total)} total`;
  }

  return 'R$ 0,00 total';
}

function formatUniqueValues(deposits: Array<{ value: number }>): Set<number> {
  return new Set(deposits.map((deposit) => deposit.value));
}
