import Icon from '@frontend/components/Icon';
import Typography from '@frontend/components/Typography';
import * as SC from './styled';

type Props = {
  title: string;
};

export function UserDetailCard(props: Props) {
  return (
    <SC.HeaderContainer>
      <Icon
        name="IconUsers"
        color="neutral_40"
        background="neutral"
        size={24}
      />
      <Typography.Body4 color="neutral_30" weight={600}>
        {props.title}
      </Typography.Body4>
    </SC.HeaderContainer>
  );
}
