import {
  Breadcrumbs,
  Icons,
  LinkButton,
  PageContainer,
  PageHeader,
  PillButton,
  Tooltip,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Spacer from '../../components/Spacer';
import Typography from '../../components/Typography';
import ModalService from '../../services/ModalService';
import { formatDateTime, toCurrency } from '../../utils/masks';
import { GetStatementOutput } from '../Statement/FlashCard/data/useTableData';
import AccountabilityStatusTag from '../Statement/components/AccountabilityStatusTag';
import ReclassificationPreviewModal from '../Statement/modals/reclassification/ReclassificationPreviewModal';
import { useTransactionInfo } from './data/useTransactionInfo';
import * as styled from './styled';

type Rows = GetStatementOutput['items'][number] & {
  accountability?: { code: string; translated: string };
};

interface LocationState {
  transaction: Rows;
}

export default function TransactionDetail() {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const { transaction } = location.state as LocationState;
  const { transactionType, description } = useTransactionInfo(transaction.type);
  const checkStatus = ['COMPLETED', 'SETTLED', 'AUTHORIZED'].includes(
    transaction.status,
  );

  return (
    <>
      <PageHeader>
        <Breadcrumbs
          breadcrumbs={[
            <LinkButton
              onClick={() => {
                navigate(-1);
              }}
              variant="neutral"
            >
              Meu Extrato
            </LinkButton>,
            <LinkButton variant="neutral" disabled>
              Detalhes da transação
            </LinkButton>,
          ]}
          separator={<Icons name="IconChevronRight" fill="#ffffff" />}
        />
      </PageHeader>
      <PageContainer>
        <styled.Box>
          <styled.BigIcon
            name="IconBasket"
            size={45}
            color={theme.colors.primary}
          />
          <Spacer x="xs" />
          <styled.MainInfoBox>
            <Typography.Headline6 color="neutral_30">
              {transaction.description}
            </Typography.Headline6>
            <Typography.Body4 color="neutral_50">
              {description}
            </Typography.Body4>
          </styled.MainInfoBox>
          {transaction.accountability && (
            <styled.TagArea>
              <AccountabilityStatusTag {...transaction.accountability} />
            </styled.TagArea>
          )}
          {transaction.type === 'OPEN_LOOP_PAYMENT' && checkStatus && (
            <Tooltip title="Reclassificar compra">
              <PillButton
                icon="IconRefreshAlert"
                variant="default"
                onClick={() => {
                  ModalService.show(ReclassificationPreviewModal, {
                    transactionId: transaction.aggregateId,
                  });
                }}
                size="small"
              />
            </Tooltip>
          )}
        </styled.Box>
        <Spacer y="s" />
        <styled.Box>
          <styled.BoxInfo>
            <Typography.Body4 color="neutral_50">Valor</Typography.Body4>
            <Typography.Headline5 color="neutral_30">
              {`${transaction.type === 'DEPOSIT' ? '' : '-'} ${toCurrency(
                transaction.amount,
              )}`}
            </Typography.Headline5>
          </styled.BoxInfo>
          <styled.BoxInfo>
            <Typography.Body4 color="neutral_50">Data e hora</Typography.Body4>
            <Typography.Headline8 color="neutral_30">
              {formatDateTime(transaction.date)}
            </Typography.Headline8>
          </styled.BoxInfo>
          <styled.BoxInfo>
            <Typography.Headline8 color="neutral_30">
              {transaction.benefitIds}
            </Typography.Headline8>
          </styled.BoxInfo>
        </styled.Box>
        <Spacer y="s" />
        <styled.BoxTransactionInfo>
          <Typography.Headline8 color="neutral_30">
            Descrição
          </Typography.Headline8>
          <Spacer y="xs" />
          <styled.Box>
            <styled.DetailInfo flex={2}>
              <Typography.Body3 color="neutral_30">
                Tipo de transação
              </Typography.Body3>
            </styled.DetailInfo>
            <styled.DetailInfo flex={3}>
              <Typography.Body3 weight={700} color="neutral_30">
                {transactionType}
              </Typography.Body3>
            </styled.DetailInfo>
          </styled.Box>
          <Spacer y="xs" />
          <Typography.Headline8 color="neutral_30">
            Cotação
          </Typography.Headline8>
          <Spacer y="xs" />
          <styled.Box>
            <styled.DetailInfo flex={2}>
              <Typography.Body3 color="neutral_30">
                Valor Total
              </Typography.Body3>
            </styled.DetailInfo>
            <styled.DetailInfo flex={3}>
              <Typography.Body3 weight={700} color="neutral_30">
                {toCurrency(transaction.amount)}
              </Typography.Body3>
            </styled.DetailInfo>
          </styled.Box>
        </styled.BoxTransactionInfo>
        <Spacer y="s" />
      </PageContainer>
    </>
  );
}
