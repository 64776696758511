import styled from "styled-components"

export const COLUMN_WIDTH = "312px"
export const CARDS_LIST_HEIGHT = "866px"

export const CardsListWrapper = styled.div`
  width: ${COLUMN_WIDTH};
  display: flex;
  flex-direction: column;
  margin-right: ${({ theme }) => theme.spacings.xs4};

  :last-child {
    margin-right: 0px;
  }

  flex-grow: 1;
  height: ${CARDS_LIST_HEIGHT};
  overflow-y: auto;
`

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${COLUMN_WIDTH};
`

export const DraggableCardsListWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`
