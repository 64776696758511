import { PageContainer } from './app-store.styles';
import { useEffect, useState } from 'react';
import {
  AccessButtons,
  BannersSection,
  CardsSection,
  MainBanner,
  PageDivider,
  TabSelector,
} from './components';
import { useFlag } from '@flash-tecnologia/feature-flags';
import { useGetAppStoreData } from './hooks/use-get-app-store-data';
import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import { trackPage } from '@/utils/tracking';

export const AppStorePage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { appStoreData, isLoading: appStoreDataLoading } = useGetAppStoreData();
  const isFeaturedSectionEnabled = useFlag({
    flagName: 'FLASH_OS_APP_STORE_FEATURED_SECTION',
  });

  useEffect(() => {
    trackPage(`acquisition_app_store_page`);
  }, []);

  const handleSelectTab = (index: number) => {
    setSelectedTab(index);
  };

  if (appStoreDataLoading || !appStoreData) {
    return (
      <PageContainer>
        <Skeleton variant="rounded" height={320} />
      </PageContainer>
    );
  }
  const filteredData = appStoreData;
  // if (!isFeaturedSectionEnabled) {
  //   filteredData = appStoreData.filter((data) => data.name !== 'featured');
  // }
  const tabs = filteredData.map((data, index) => ({
    ...data.tabData,
    isSelected: index === selectedTab,
  }));

  const selectedData = filteredData[selectedTab];
  const bannersData = selectedData.banners;

  // TODO: add empty state

  return (
    <PageContainer>
      <MainBanner />
      <TabSelector tabs={tabs} onSelectTab={handleSelectTab} />
      {!!bannersData?.length && <BannersSection banners={bannersData} />}
      <CardsSection data={selectedData.categories} />
      <PageDivider />
      <AccessButtons />
    </PageContainer>
  );
};
