import { useParams } from "react-router-dom"
import { HiringCardPage } from "../../../components"
import {
  CLTContent,
  PJContent,
  InternshipContent,
} from "../../../components/screen/HistoricContent"

const ArchivedPage = () => {
  const { id: cardId = "" } = useParams()

  const contents = {
    ["clt"]: CLTContent,
    ["pj"]: PJContent,
    ["internship"]: InternshipContent,
  }

  return (
    <HiringCardPage
      cardId={cardId}
      pageHistory={[
        { name: "Admissão", href: "/flows/hiring" },
        { name: "Arquivado", href: `/flows/hiring/archived/${cardId}` },
      ]}
      contents={contents}
    />
  )
}

export default ArchivedPage
