import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

/**
 * Props for the SkeletonLoader component.
 */
export type SkeletonLoaderProps = {
  /**
   * Whether to show the skeleton loader.
   */
  isVisible: boolean;

  /**
   * The width of the skeleton loader. Can be a number (pixels) or a string (e.g., "100%").
   * @default "100%"
   */
  width?: number | string;

  /**
   * The height of the skeleton loader. Can be a number (pixels) or a string (e.g., "100%").
   * @default "100%"
   */
  height?: number | string;

  /**
   * The animation style for the skeleton loader. Can be "pulse", "wave", or false (no animation).
   * @default "pulse"
   */
  animation?: "pulse" | "wave" | false;
};

export const SkeletonLoader = ({ isVisible = false, width, height, animation }: SkeletonLoaderProps) => {
  return isVisible ? <Skeleton width={width} height={height} animation={animation} /> : <></>;
};
