import { Box, Typography } from '@components';

import * as SC from './styled';

type Props = {
  title: string;
  subtitle?: string;
  actions?: React.ReactNode;
  children: React.ReactNode;
};

export function Page(props: Props) {
  return (
    <SC.Container>
      <Box margin="none none xs3 none" display="flex" justifyContent="space-between">
        <div>
          <Typography.Headline7 as="h1" color="neutral.20" weight={700}>
            {props.title}
          </Typography.Headline7>

          {props.subtitle && (
            <Typography.Body4 color="neutral.40">{props.subtitle}</Typography.Body4>
          )}
        </div>

        {props.actions}
      </Box>

      {props.children}
    </SC.Container>
  );
}
