import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { cloneDeep } from "lodash-es";

import { trpc } from "@api/client";
import { Lantern } from "@assets/index";
import Banner from "@components/Banner";
import { Table } from "@components/Table";
import { Grid } from "@mui/material";
import { dispatchToast, StyledText } from "@utils";
import { routes } from "@routes";

import { BulkActionsGrid } from "./components/BulkActionsGrid";
import { BigNumbers } from "./components/BigNumbers";

import { EmptyStateArea, StyledLinkButton } from "./styled";

import type { IdpManagerPeopleInsightsResult } from "./types";

const EmptyState = () => {
  const navigate = useNavigate();

  return (
    <>
      <Banner
        type="info"
        icon="IconInfoCircle"
        title="Apenas os planos associados a esse ciclo são listados aqui."
        subTitle="Para ver o restante dos seus planos, clique na opção abaixo ou acesse a área de PDI no menu principal."
        children={
          <StyledLinkButton
            variant="neutral"
            onClick={() => navigate(routes.PageMyIdps)}
          >
            Ver todos os meus planos
          </StyledLinkButton>
        }
        hasHideBanner={false}
        style={{ marginBottom: "24px" }}
      />

      <Grid
        container
        spacing={4}
        alignItems="stretch"
        style={{ marginBottom: "40px" }}
        sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
      >
        <Grid item xs={12} style={{ width: "100%" }} display={"flex"}>
          <EmptyStateArea>
            <Lantern />
            <StyledText variant="body3" weight={600} setColor="neutral30">
              Aguarde o período de PDI para <br />
              criar e visualizar seus planos.
            </StyledText>
          </EmptyStateArea>
        </Grid>
      </Grid>
    </>
  );
};

export const Idps = ({ hasStarted = false }: { hasStarted?: boolean }) => {
  const { id = "" } = useParams();

  const [insightsFilters, setInsightsFilters] = useState<"not_created">();

  const { data: insights, isLoading: isLoadingInsights } =
    trpc.performance.idp.getManagerCycleIdpInsights.useQuery(
      { cycleId: id },
      {
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => {
          dispatchToast({
            type: "error",
            content:
              "Erro ao buscar Insights dos PDIs, tente novamente em breve!",
          });
        },
      }
    );

  const { data, isLoading } =
    trpc.performance.idp.getManagerIdpPeopleByCycle.useQuery(
      { cycleId: id },
      {
        retry: false,
        retryDelay: 3000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: () => {
          dispatchToast({
            type: "error",
            content:
              "Erro ao buscar Insights dos PDIs, tente novamente em breve!",
          });
        },
      }
    );

  const filteredIdpPeople = useMemo(() => {
    const idps = cloneDeep(data?.items || []);

    if (!insightsFilters) return idps;

    return idps.filter(({ total }) => total === 0);
  }, [insightsFilters, data?.items?.length]);

  if (!hasStarted) return <EmptyState />;

  return (
    <>
      <BigNumbers
        data={insights as IdpManagerPeopleInsightsResult}
        isLoading={isLoadingInsights}
        insightsFilter={insightsFilters}
        onFilter={setInsightsFilters}
      />
      <Table
        data={filteredIdpPeople || []}
        filters={data?.filters || []}
        loading={isLoading}
        searchable={true}
        searchableField="name"
        searchPlaceholder={"Buscar por nome"}
        tableGridRender={(props) => <BulkActionsGrid {...props} />}
      />
    </>
  );
};
