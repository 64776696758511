import { ExpenseStatus } from "$serverTypes";

/** Default status useful when using status filters in many APIs */
export const defaultStatus = [
  ExpenseStatus.DRAFT,
  ExpenseStatus.REQUIRE_CHANGES,
  ExpenseStatus.PENDING_APPROVAL,
  ExpenseStatus.PENDING_ACCOUNTING,
  ExpenseStatus.FINISHED,
  ExpenseStatus.REJECTED,
];
