import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Breadcrumbs,
  dayjs,
  Icons,
  PageContainer,
  Tab,
} from "@flash-tecnologia/hros-web-ui-v2";
import { trpc } from "@api/client";
import { alert } from "../../../utils";
import {
  Insights,
  ManualReminderModal,
  AutoReminderModal,
} from "../components";
import { TabsContainer } from "../styles";
import { EngagementModel } from "@models";
import { EditOwnersModal } from "src/modals";
import FirstTab from "./tabs/FirstTab";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { FiltersType } from "@components/TableFilter/types";
import ThirdTab from "./tabs/ThirdTab";
import FourthTab from "./tabs/FourthTab";
import { Header } from "./Header";
import SecondTab from "./tabs/SecondTab";
import { ButtonWithIcon } from "@components/ButtonWithIcon";
import { StatisticRow } from "@components/StatisticRow";

export default () => {
  const { selectedCompany } = useSelectedCompany();
  const { id = "" } = useParams();
  const [data, setData] = useState<typeof employees>([]);

  // Modais
  const [manualReminderVisible, setManualReminderVisible] = useState(false);
  const [manualReminderErrorVisible, setManualReminderErrorVisible] =
    useState(false);
  const [autoReminderVisible, setAutoReminderVisible] = useState(false);
  const [reminderJobEnabled, setReminderJobEnabled] = useState(false);
  const [ownersEditModal, setOwnersEditModal] = useState<{
    open: boolean;
    engagement?: EngagementModel;
  }>({
    open: false,
    engagement: undefined,
  });
  const [recurrenceFilters, setRecurrenceFilters] = useState<FiltersType>([
    {
      id: "sendDate",
      type: "date",
      label: "Data de envio",
      valueInLabel: true,
    },
  ]);

  const [loading, setLoading] = useState(false);

  const updateRecurrenceStatus = trpc?.updateRecurrenceStatus.useMutation({
    onSuccess: (e) => {
      if (e?.recurrence?.status) getEngagementById();
      alert("Recorrência atualizada com sucesso.", "success");
      setLoading(!loading);
    },
    onError: (e) => {
      console.log(e);
      alert("Erro ao atualizar recorrência, tente novamente mais tarde.");
      setLoading(!loading);
    },
  });

  const {
    data: engagement,
    isLoading: getEngagementByIdLoading,
    refetch: getEngagementById,
  } = trpc?.getEngagementById?.useQuery(
    {
      id,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (e: any) => {
        if (e?.code === "EMPLOYEE_NOT_ENGAGEMENT_OWNER_ERROR") {
          alert(
            "Você não tem permissão para visualizar os detalhes desta pesquisa."
          );
        }

        setReminderJobEnabled(e?.notifications?.reminder?.enabled || false);
      },
      onError: (e) => {
        alert("Erro ao buscar pesquisa, tente novamente mais tarde.");
        console.log("err: ", e);
      },
    }
  );

  const {
    isLoading: updateReminderStatusLoading,
    mutate: updateReminderStatus,
  } = trpc.updateReminderStatus.useMutation({
    onSuccess: (e) => {
      setAutoReminderVisible(false);
      setReminderJobEnabled(e?.value?.reminder);
      alert("Atualizado com sucesso", "success");
    },
    onError: (err) => {
      alert("Erro ao atualizar notificações, tente novamente mais tarde.");
      console.log(err?.message);
      setAutoReminderVisible(false);
    },
  });

  const {
    data: insights,
    refetch: getSurveyInsightsRefetch,
    isLoading: getSurveyInsightsRefetchLoading,
  } = trpc?.getSurveyInsights?.useQuery(
    { id },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (e) => {
        alert("Erro na busca de insights, tente novamente mais tarde.");
        console.log("err: ", e);
      },
    }
  );

  const { isLoading: reminderloading, mutate: reminder } =
    trpc.reminder.useMutation({
      onSuccess: () => {
        alert("Enviado com sucesso", "success");
        if (manualReminderVisible) setManualReminderVisible(false);
        if (manualReminderErrorVisible) setManualReminderErrorVisible(false);
      },
      onError: (err) => {
        if (err?.message?.includes("notifications.reminder.lastSentDate")) {
          setManualReminderVisible(false);
          setManualReminderErrorVisible(true);
          return;
        }

        alert("Erro ao enviar notificações, tente novamente mais tarde.");
        console.log(err);
        setManualReminderVisible(false);
      },
    });

  const {
    data: employees,
    isFetching: getEmployeeMonitoringsByEngagementIdLoading,
  } = trpc?.getEmployeeMonitoringsByEngagementId.useQuery(
    { id },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (e) => setData(e),
      onError: (e) => {
        alert("Erro ao buscar destinatários, tente novamente mais tarde.");
        console.log("err: ", e);
      },
    }
  );

  const { data: departments, isFetching: getDepartmentsByCompanyIdLoading } =
    trpc?.getDepartmentsByCompanyId?.useQuery(
      { companyId: selectedCompany?.id || "" },
      {
        retry: false,
        refetchOnWindowFocus: false,

        onError: (e) => {
          alert("Erro ao buscar destinatários, tente novamente mais tarde.");
          console.log("err: ", e);
        },
      }
    );

  const { data: occurrences, isFetching: getRecurrenceByIdLoading } =
    trpc?.getRecurrenceById.useQuery(
      {
        id,
        sendDate: recurrenceFilters?.find(({ id }) => id === "sendDate")
          ?.dateRange?.from
          ? {
              from: dayjs(
                recurrenceFilters?.find(({ id }) => id === "sendDate")
                  ?.dateRange?.from
              )?.format("YYYY-MM-DD"),
              to: dayjs(
                recurrenceFilters?.find(({ id }) => id === "sendDate")
                  ?.dateRange?.to
              )?.format("YYYY-MM-DD"),
            }
          : undefined,
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        onError: (e: any) => {
          alert("Ocorreu um erro, tente novamente mais tarde.");
          console.log("err: ", e);
        },
      }
    );

  const { data: employee, refetch: getEmployeeByIdRefetch } =
    trpc?.getEmployeeById?.useQuery(
      { id: engagement?.createdBy },
      { retry: false, refetchOnWindowFocus: false, enabled: false }
    );

  useEffect(() => {
    if (!employee?.name) getEmployeeByIdRefetch();
  }, [engagement?.createdBy]);

  const getAllMonitorings = () => {
    const result: any[] = [];

    employees?.forEach(({ monitorings }) =>
      monitorings?.forEach((m) => {
        if (m?.type === "react" || m?.type === "enps") result?.push(m);
      })
    );

    return result;
  };

  const totalAnswers = employees?.filter(
    (e) =>
      e?.monitorings?.filter(
        (m) => m?.type && ["enps", "react"]?.includes(m?.type) && !m?.draft
      )?.length
  )?.length;

  const tabItens = [
    {
      label: "Análise",
      component: <FirstTab engagement={engagement} occurrences={occurrences} />,
    },
    {
      label: "Envios",
      component: (
        <SecondTab
          getRecurrenceByIdLoading={getRecurrenceByIdLoading}
          recurrenceFilters={recurrenceFilters}
          setRecurrenceFilters={setRecurrenceFilters}
          occurrences={occurrences}
        />
      ),
    },
    {
      label: `Pessoas (${insights?.selectedEmployees?.length || 0})`,
      component: (
        <ThirdTab
          employees={
            insights?.selectedEmployees?.map((e) => ({
              id: e?.id,
              name: e?.name,
              visualized: e?.visualized,
              status: e?.answerStatus,
            })) || []
          }
        />
      ),
    },
    {
      label: "Detalhes",
      component: (
        <FourthTab
          model={engagement?.model}
          createdAt={engagement?.createdAt}
          name={engagement?.name}
          title={engagement?.title}
          description={engagement?.description}
          startDate={engagement?.startDate}
          interactionDeadline={engagement?.interactionDeadline}
          anonymous={engagement?.anonymous}
          notifications={engagement?.notifications}
          createdBy={employee?.name}
          owners={engagement?.owners || []}
        />
      ),
    },
  ];

  const renderEndRecurrence = () => {
    const recurrence = engagement?.recurrence;

    if (!recurrence) return "-";

    if (recurrence.endDate)
      return dayjs(recurrence.endDate).format("DD/MM/YYYY");

    if (recurrence.occurrenceLimit === -1) return "Nunca";

    if (recurrence.occurrenceLimit > 0)
      return `Depois de ${recurrence.occurrenceLimit} envios`;
  };

  return (
    <PageContainer>
      <Breadcrumbs
        style={{ marginTop: "32px" }}
        separator={<Icons name="IconChevronRight" />}
        breadcrumbs={[
          <Link to={"/engagement/survey"}>Gerenciar pesquisas</Link>,
          <Link to={`/engagement/survey/feeling/recurrence/${id}`}>
            Detalhe da pesquisa
          </Link>,
        ]}
      />
      <Header
        refetch={getEngagementById}
        pendingCount={
          (engagement?.employees?.length || 0) - (totalAnswers || 0)
        }
        recurrence={engagement?.recurrence}
        name={engagement?.name || ""}
        status={engagement?.status || ""}
        data={
          engagement
            ? {
                ...engagement,
                notifications: {
                  email: engagement?.notifications?.email || false,
                  push: engagement?.notifications?.push || false,
                  reminder: {
                    enabled:
                      engagement?.notifications?.reminder?.enabled || false,
                    minRate: engagement?.notifications?.reminder?.minRate || 0,
                    lastSentDate: engagement?.notifications?.reminder
                      ?.lastSentDate
                      ? new Date(
                          engagement?.notifications?.reminder?.lastSentDate
                        )
                      : undefined,
                  },
                },
                createdAt: engagement?.createdAt
                  ? new Date(engagement?.createdAt)
                  : undefined,
                updatedAt: engagement?.updatedAt
                  ? new Date(engagement?.updatedAt)
                  : undefined,
                sendDate: engagement?.sendDate
                  ? new Date(engagement?.sendDate)
                  : undefined,
              }
            : undefined
        }
      />
      <Insights
        recipients={insights?.selectedEmployees?.length || 0}
        occurences={occurrences?.length || 0}
        answers={insights?.answers || 0}
        viewRate={insights?.viewRate || 0}
        answerRate={insights?.answerRate || 0}
      />
      <StatisticRow
        data={[
          {
            label: "Criado em",
            value: engagement?.createdAt
              ? dayjs(engagement?.createdAt)?.format("DD/MM/YYYY")
              : "-",
          },
          {
            label: "Início em",
            value: engagement?.sendDate
              ? dayjs(engagement?.startDate?.date)?.format("DD/MM/YYYY")
              : "-",
          },
          {
            label: "Término em",
            value: renderEndRecurrence(),
          },
        ]}
        rightContent={
          engagement?.status !== "draft" &&
          ["progress", "paused"]?.includes(
            engagement?.recurrence?.status || ""
          ) ? (
            engagement?.recurrence?.status !== "paused" ? (
              <ButtonWithIcon
                loading={loading}
                icon={{
                  name: "IconPlayerPause",
                  position: "right",
                  style: { fill: "transparent" },
                }}
                variant="secondary"
                variantType="error"
                size="medium"
                onClick={() => {
                  setLoading(!loading);
                  updateRecurrenceStatus.mutate({ id, status: "paused" });
                }}
              >
                Pausar recorrência
              </ButtonWithIcon>
            ) : (
              <ButtonWithIcon
                loading={loading}
                icon={{
                  name: "IconPlayerPlay",
                  position: "right",
                  style: { fill: "transparent" },
                }}
                variant="secondary"
                size="medium"
                onClick={() => {
                  setLoading(!loading);
                  updateRecurrenceStatus.mutate({ id, status: "progress" });
                }}
              >
                Retomar recorrência
              </ButtonWithIcon>
            )
          ) : null
        }
      />
      <TabsContainer>
        <div id="tab_container">
          <Tab tabItens={tabItens} />
        </div>
      </TabsContainer>
      <ManualReminderModal
        errorVisible={manualReminderErrorVisible}
        visible={manualReminderVisible}
        loading={reminderloading}
        onClose={() => {
          setManualReminderVisible(false);
          setManualReminderErrorVisible(false);
        }}
        onSubmit={() => reminder({ engagementId: id || "", force: true })}
        notViewed={Math.round(100 - (insights?.viewRate || 0))}
      />
      <AutoReminderModal
        visible={autoReminderVisible}
        loading={updateReminderStatusLoading}
        onClose={() => setAutoReminderVisible(false)}
        onSubmit={() => updateReminderStatus({ engagementId: id })}
        notViewed={Math.round(100 - (insights?.viewRate || 0))}
      />
      <EditOwnersModal
        open={ownersEditModal?.open}
        engagement={ownersEditModal?.engagement}
        onClose={() =>
          setOwnersEditModal({
            open: false,
            engagement: undefined,
          })
        }
        onSuccess={() => getEngagementById()}
      />
    </PageContainer>
  );
};
