import { Typography, dayjs } from "@flash-tecnologia/hros-web-ui-v2"
import {
  InfoPropsWithouIdx,
  InfoByType,
  InfoList,
  InfoWrapper,
} from "../../../../components/common/InfoList"
import { useTheme } from "styled-components"
import { Flow, HiringCard } from "../../../../types"
import { useTranslation } from "react-i18next"
import { PageBody } from "../../../../components/screen/CardPageTemplate/styles"
import React from "react"
import {
  ContentContainer,
  LeftContainer,
  PageDescriptionContainer,
  PageHeaderContainer,
  RightContainer,
} from "./styles"
import { CpfMask } from "../../../../utils"

const PageDescription = ({
  title,
  description,
}: {
  title: string
  description: string
}) => {
  const { colors } = useTheme()
  return (
    <PageDescriptionContainer>
      <Typography variant="headline7" variantColor={colors.secondary50}>
        {title}
      </Typography>
      <Typography variant="body3">{description}</Typography>
    </PageDescriptionContainer>
  )
}

interface PageContentProps {
  title: string
  subtitle: string
  description: string
  card: HiringCard
  flow: Flow
  children?: React.ReactNode
}

export const PageContent = ({
  title,
  subtitle,
  description,
  card,
  flow,
  children,
}: PageContentProps) => {
  const theme = useTheme()
  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const candidateGenericInfo: InfoPropsWithouIdx[] = [
    {
      value: card.candidate.name || "Não cadastrado",
      text: t("personalInfo.name"),
      icon: "IconUser",
      hideCopyIcon: !card.candidate.name,
    },
    {
      value: card.candidate.email || "Não cadastrado",
      text: t("personalInfo.email"),
      icon: "IconMail",
      hideCopyIcon: !card.candidate.email,
    },
    {
      value:
        card.candidate.phone?.replace(
          /(\d{2})(\d{2})(\d{5})(\d{4})/,
          "$1 $2 $3-$4",
        ) || "Não cadastrado",
      text: t("personalInfo.phone"),
      icon: "IconPhone",
      hideCopyIcon: !card.candidate.phone,
    },
    {
      value: CpfMask(card.candidate?.documentNumber) || "Não cadastrado",
      text: t("personalInfo.cpf"),
      icon: "IconId",
      hideCopyIcon: !card.candidate?.documentNumber,
    },
  ]

  const candidateInfoByType: InfoByType = {
    clt: [
      {
        value: card.candidate?.proposalLetterInfo?.hiringDate
          ? dayjs(card.candidate.proposalLetterInfo?.hiringDate).format(
              "DD/MM/YYYY",
            )
          : "Não cadastrado",
        text: t("personalInfo.dateLabel"),
        icon: "IconCalendar",
        hideCopyIcon: !card.candidate.proposalLetterInfo?.hiringDate,
      },
    ],
    pj: [
      {
        value: card.candidate?.cnpj || "Não cadastrado",
        text: "CNPJ",
        icon: "IconReceipt",
        hideCopyIcon: !card.candidate.cnpj,
      },
      {
        value: card.candidate?.legalName || "Não cadastrado",
        text: "Razão social",
        icon: "IconBuildingSkyscraper",
        hideCopyIcon: !card.candidate.legalName,
      },
      {
        value: card.candidate?.proposalLetterInfo?.hiringDate
          ? dayjs(card.candidate.proposalLetterInfo?.hiringDate).format(
              "DD/MM/YYYY",
            )
          : "Não cadastrado",
        text: t("personalInfo.dateLabel"),
        icon: "IconCalendar",
        hideCopyIcon: !card.candidate.proposalLetterInfo?.hiringDate,
      },
    ],
    internship: [
      {
        value: card.candidate?.internship?.startDate
          ? dayjs(card.candidate?.internship?.startDate).format("DD/MM/YYYY")
          : "Não cadastrado",
        text: "Data prevista de início",
        icon: "IconCalendar",
        hideCopyIcon: !card.candidate?.internship?.startDate,
      },
    ],
  }
  return (
    <PageBody
      style={{
        padding: `0 ${theme.spacings.xs}`,
      }}
    >
      <PageHeaderContainer>
        <Typography variant="headline6">{title}</Typography>
      </PageHeaderContainer>
      <ContentContainer style={{ paddingBottom: theme.spacings.m }}>
        <LeftContainer>
          <PageDescription title={subtitle} description={description} />
          <InfoWrapper>
            <InfoList
              data={[
                ...candidateGenericInfo,
                ...candidateInfoByType[flow.subcategory || "clt"],
              ]}
            />
          </InfoWrapper>
        </LeftContainer>
        <RightContainer>{children}</RightContainer>
      </ContentContainer>
    </PageBody>
  )
}

export default PageContent
