import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { createContext, useContext } from "react";
import { useForm, UseFormReturn } from "react-hook-form";

import { ExpenseStatus } from "$serverTypes";
import { getDateRange } from "$utils";

export type ReimbursementsState = {
  filters: {
    status: ExpenseStatus[];
    categoryNanoIds: string[];
    dateRange: { from: Date; to: Date };
    valueRange: { max?: number; min?: number };
  };
  search: string;
  pagination: PaginationState;
};

const defaultValues: ReimbursementsState = {
  filters: {
    status: [],
    categoryNanoIds: [],
    dateRange: getDateRange(),
    valueRange: { min: undefined, max: undefined },
  },
  search: "",
  pagination: { pageNumber: 1, pageSize: 10 },
};

export type ISessionProviderProps = {
  children: React.ReactNode;
};

type ReimbursementsContextProps = {
  methods: UseFormReturn<ReimbursementsState>;
};

const ReimbursementsContext = createContext<ReimbursementsContextProps | undefined>(undefined);

export const ReimbursementsProvider = ({ children }: ISessionProviderProps) => {
  const methods = useForm<ReimbursementsState>({ defaultValues });

  return <ReimbursementsContext.Provider value={{ methods }}>{children}</ReimbursementsContext.Provider>;
};

export const useReimbursementsContext = () => {
  const context = useContext(ReimbursementsContext);
  if (!context) {
    throw new Error("useReimbursementsContext must be used within a ReimbursementsProvider");
  }
  return context;
};
