import { trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';

type Props = {
  multiple: boolean;
  closeModal(): void;
};

export function useReproveRequest({ multiple, closeModal }: Props) {
  const context = trpc.useContext();

  const { isLoading: loading, mutate } =
    trpc.corporateCard.requestBalance.reproveMany.useMutation({
      onSuccess: () => {
        const { success } = getFeedback(multiple);

        setTimeout(() => {
          void context.corporateCard.requestBalance.search.invalidate();
          void context.corporateCard.requestBalance.countPendingApproval.invalidate();
          dispatchToast({
            type: 'success',
            content: success.content,
            description: success.description,
          });
        }, 2_500);
        closeModal();
      },
      onError: () => {
        const { error } = getFeedback(multiple);
        dispatchToast({
          type: 'error',
          content: error.content,
          description: error.description,
        });
      },
    });

  function reproval(data: { orderIds: Array<string>; justification: string }) {
    if (!data.justification) {
      dispatchToast({
        type: 'error',
        content: 'Preencha uma justificativa.',
      });
      return;
    }
    mutate(data);
  }
  return {
    reproval,
    loading,
  };
}

function getFeedback(multiple: boolean) {
  return {
    success: {
      content: multiple
        ? 'Pedidos de saldo reprovados com sucesso!'
        : 'Pedido de saldo reprovado com sucesso!',
      description: multiple
        ? 'Os solicitantes serão notificados sobre a reprovação.'
        : 'O solicitante será notificado sobre a reprovação.',
    },
    error: {
      content: multiple
        ? 'Não foi possível reprovar os pedidos de saldo.'
        : 'Não foi possível reprovar o pedido de saldo.',
      description: 'Por favor, tente novamente dentro de alguns instantes.',
    },
  };
}
