import { CREATE_ACCOUNT_IMAGE_URL } from '@/assets/imageConstants';
import { Icons, TextField } from '@flash-tecnologia/hros-web-ui-v2';
import ReCAPTCHA from 'react-google-recaptcha';
import { Footer, Header } from '../../components/Common';
import * as SC from './styles';
import { usePageCustomFirstAccess } from './usePageCustomFirstAccess';

export const PageCustomFirstAccess = () => {
  const { formik, isLoading } = usePageCustomFirstAccess();

  return (
    <SC.Container>
      <Header />
      <SC.ContentContainer>
        <SC.DataContainer>
          <SC.TextsContainer>
            <SC.CreateAccountText>Criar conta</SC.CreateAccountText>
            <SC.Title variant="headline5">
              Para começar,
              <br />
              informe os seus dados
            </SC.Title>
            <SC.SubTitle variant="body3">
              Precisamos dessa informação para continuarmos com o seu cadastro.
            </SC.SubTitle>
          </SC.TextsContainer>
          <SC.Form onSubmit={formik.handleSubmit}>
            <TextField
              label="CPF*"
              id="cpf"
              name="cpf"
              value={formik.values.cpf}
              fullWidth
              style={{ marginTop: '40px' }}
              onChange={formik.handleChange}
              error={formik.touched.cpf && !!formik.errors.cpf}
              helperText={formik.touched.cpf && formik.errors.cpf}
              imaskProps={{ mask: '000.000.000-00' }}
            />
            <SC.CaptchaContainer>
              <ReCAPTCHA
                sitekey={process.env.CAPTCHA_KEY}
                onChange={(value) =>
                  formik.setFieldValue('captchaToken', value)
                }
              />
              {formik.touched.captchaToken && formik.errors.captchaToken && (
                <SC.CaptchaErrorText>
                  {formik.errors.captchaToken}
                </SC.CaptchaErrorText>
              )}
            </SC.CaptchaContainer>
            <SC.StyledButton
              variant="primary"
              size="large"
              type="submit"
              loading={isLoading}
            >
              Continuar <Icons name="IconArrowRight" />
            </SC.StyledButton>
          </SC.Form>
        </SC.DataContainer>
        <SC.Image src={CREATE_ACCOUNT_IMAGE_URL} />
      </SC.ContentContainer>
      <Footer />
    </SC.Container>
  );
};
