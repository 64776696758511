import { RouterOutputs, trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { useStringSearch } from './useStringSearch';

const statusSchema = z.enum([
  'preview',
  'processing',
  'waiting',
  'paid',
  'cancelled',
  'reproved',
  'expired',
]);

export const searchParamsSchema = z.object({
  status: z
    .string()
    .transform((data) => z.array(statusSchema).catch([]).parse(data.split(',')))
    .optional()
    .catch([]),
  createdAtStartDate: z.coerce.date().optional().catch(undefined),
  createdAtEndDate: z.coerce.date().optional().catch(undefined),
  currentPage: z.coerce.number().nonnegative().default(0).catch(0),
  pageSize: z.coerce.number().positive().default(10).catch(10),
  stringInput: z.string().optional().catch(undefined),
});

type SearchParams = z.infer<typeof searchParamsSchema>;

type Output = RouterOutputs['corporateCard']['requestBalance']['search'];

export function useSearchRequests() {
  const [filters, setFilters] = useState<SearchParams>({
    currentPage: 0,
    pageSize: 10,
  });

  const [stringInput, setStringSearch] = useStringSearch(filters.stringInput);

  useEffect(() => {
    setFilters((prevValue) => ({
      ...prevValue,
      currentPage: 0,
      stringInput,
    }));
  }, [stringInput !== filters.stringInput]);

  const {
    pageSize,
    currentPage,
    createdAtEndDate,
    createdAtStartDate,
    ...otherFilters
  } = filters;

  const filter = {
    ...(!!otherFilters && {
      ...otherFilters,
    }),
    search: filters.stringInput,
    ...(createdAtStartDate &&
      createdAtEndDate && {
        createdAt: {
          from: createdAtStartDate,
          to: createdAtEndDate,
        },
      }),
  };
  const pagination = {
    currentPage,
    pageSize,
  };

  const { data, error, isInitialLoading, isRefetching, isStale } =
    trpc.corporateCard.requestBalance.search.useQuery(
      {
        filter,
        pagination,
      },
      {
        onError: (error) => {
          dispatchToast({
            type: 'error',
            content: error.message,
          });
        },
        keepPreviousData: true,
        retry: false,
        retryOnMount: false,
        refetchOnWindowFocus: true,
        staleTime: 2 * 60 * 1000,
        refetchInterval: 30 * 1000,
      },
    );

  return {
    isFiltered: Object.keys(filter).length > 0,
    setFilters: (data: Omit<SearchParams, 'pageSize' | 'currentPage'>) => {
      setFilters({ ...filters, ...data, currentPage: 0 });
    },
    filters,
    error,
    pagination,
    setPagination: (pagination: { currentPage: number; pageSize: number }) => {
      setFilters({ ...filters, ...pagination });
    },
    data: data || {
      pagination: {
        totalItems: 0,
        currentPage: 0,
        pageSize: 0,
        totalPages: 0,
      },
      entries: [] as Output['entries'],
    },
    setStringSearch,
    loading: isInitialLoading || (isStale && isRefetching),
  };
}
