import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: grid;
  padding-top: ${({ theme }) => theme.spacings.s};
  gap: ${({ theme }) => theme.spacings.m};
`;

export const DescriptionContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  padding: ${({ theme }) => `${theme.spacings.xs4} ${theme.spacings.xs3}`};
  gap: ${({ theme }) => theme.spacings.xs};
  background-color: ${({ theme }) => theme.colors.neutral[95]};
`;
