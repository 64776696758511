import { Button, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { HTMLAttributes, ReactNode, useMemo } from "react";
import { Footer } from "../styled";

type PropsWithChildren = {
  onConfirm: () => void;
  onCancel?: () => void;
  onClose: () => void;
  cancelText?: string;
  confirmText?: string;
  loading?: boolean;
  children?: ReactNode;
  start?: ReactNode;
  middle?: ReactNode;
  end?: ReactNode;
  buttonVariant?: "default" | "error" | "info" | "neutral" | "success";
};

type ModalFooterProps = HTMLAttributes<HTMLDivElement> & (PropsWithChildren | { children: ReactNode });

export function ModalFooter(props: ModalFooterProps) {
  const {
    children,
    start,
    middle,
    end,
    cancelText = "Cancelar",
    confirmText = "Confirmar",
    loading = false,
    onConfirm,
    onCancel,
    onClose,
    buttonVariant,
    ...restProps
  } = props as HTMLAttributes<HTMLDivElement> & PropsWithChildren;

  const render = useMemo(() => {
    if (props.children) {
      return children;
    }

    return (
      <>
        {start || (
          <LinkButton variant={buttonVariant ? buttonVariant : "neutral"} onClick={onCancel ? onCancel : onClose}>
            {cancelText}
          </LinkButton>
        )}

        {middle || null}

        {end || (
          <Button variant="primary" size="large" onClick={onConfirm} loading={loading}>
            {confirmText}
          </Button>
        )}
      </>
    );
  }, [props.children, start, onCancel, onClose, cancelText, middle, end, onConfirm, loading, confirmText, children]);

  return <Footer {...restProps}>{render}</Footer>;
}
