import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 16px 20px;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #ffe0ef;
  background: #fff5fa;
  width: 100%;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

export const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
