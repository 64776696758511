import { colors, Tab as BaseTab } from '@flash-tecnologia/hros-web-ui-v2';
import styled, { css } from 'styled-components';

export const Tab = styled(BaseTab)<{ showBorderBottom?: boolean }>`
  ${({ theme, showBorderBottom }) =>
    showBorderBottom &&
    css`
      .MuiTabs-fixed {
        border-bottom: ${theme.borders.width.xs2} solid ${colors.get('neutral.90')};
      }
    `}
`;
