import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

import { FormControlLabel as FormControlLabelMui, Skeleton } from "@mui/material";

interface ContainerProps {
  isLoading: boolean;
}

interface FormProps {
  actions?: number;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacings.xs3};
  min-width: 100%;
  min-height: 56px;
  border-radius: ${(props) => props.theme.borders.radius.m};
  padding: ${(props) => props.theme.spacings.xs4} ${(props) => props.theme.spacings.xs3};
  border: ${(props) => props.theme.borders.width.xs2} solid ${(props) => props.theme.colors.neutral[90]};
  background: ${(props) => props.theme.colors.neutral[100]};

  &:hover {
    border-color: ${(props) => props.theme.colors.primary};
    box-shadow: 0px 4px 28px rgba(254, 43, 143, 0.12);

    p {
      color: ${(props) => (props.isLoading ? props.theme.colors.neutral[50] : props.theme.colors.primary)};
    }
  }

  .buttons-container {
    display: flex;
    gap: ${(props) => props.theme.spacings.xs3};
  }
`;

export const FormControlLabel = styled(FormControlLabelMui)<FormProps>`
  display: flex;
  gap: ${(props) => props.theme.spacings.xs4};
  padding-left: ${(props) => props.theme.spacings.xs4};
  max-width: ${(props) =>
    props.actions === 0 ? "100%" : props.actions === 2 ? "calc(100% - 108px)" : "calc(100% - 54px)"};
  width: 100%;
  overflow-x: hidden;
  cursor: pointer;
  margin-bottom: 0;

  .MuiSwitch-root {
    margin-right: ${(props) => props.theme.spacings.xs3};
  }

  .MuiFormControlLabel-label {
    max-width: calc(100% - 67px);
    margin-right: 0;
  }
`;

export const Label = styled(Typography)<ContainerProps>`
  color: ${(props) => (props.isLoading ? props.theme.colors.neutral[50] : props.theme.colors.neutral[30])};
  font-weight: 600 !important;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SkeletonCard = styled(Skeleton)`
  min-width: 349px;
  height: 56px;
  border-radius: ${(props) => props.theme.borders.radius.m};
  transform: scale(1);
`;

export const LoaderContainer = styled.div`
  margin: 0 ${(props) => props.theme.spacings.xs2};
  width: 16px;
`;
