import { Typography, Unstable } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledDrawer = styled(Unstable.Drawer)`
  &.MuiModal-root {
    div {
      &.MuiPaper-root {
        width: 30%;
        padding: 40px 45px 0;
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    }
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding-bottom: ${({ theme }) => theme.spacings.xs};
  overflow-y: auto;
  padding-right: ${({ theme }) => theme.spacings.xs2};
`;

export const StyledEmptyStateContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 8px;
`;

export const StyledEmptyStateText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;
