import {
  Import,
  ImportStatus,
  ImportType,
} from '@/pages/PageEmployeesWorksheet/types';
import {
  Card,
  IconContainer,
  StyledIcon,
  TextContainer,
  InfoContainer,
} from '../../styled';
import { StyledText, StyledTitle } from 'src/common/styles/general-styles';
import { worksheetComponentInfo } from '@/pages/PageEmployeesWorksheet/componentsText';
import { GenerateXLSXFile } from '@flash-tecnologia/hros-web-ui-v2';
import { FooterFeedback } from '../Footer/footer-feedback';
import { NavigateFunction } from 'react-router-dom';

interface IErrorFeedback {
  navigate: NavigateFunction;
  operation: ImportType;
  importProcess: Import | null;
  importStatus: ImportStatus;
  newImportUrl?: string;
  redirectBackTo?: string;
}

export const ErroredFeedback = ({
  navigate,
  operation,
  importProcess,
  importStatus,
  newImportUrl,
  redirectBackTo,
}: IErrorFeedback) => {
  return (
    <Card>
      <TextContainer>
        <IconContainer setColor="error90">
          <StyledIcon
            name="IconAlertTriangle"
            fill="none"
            setColor="error50"
            size={32}
          />
        </IconContainer>
        <StyledTitle
          setColor="neutral30"
          variant="headline8"
          children="Processamento interrompido"
        />
        <StyledText
          setColor="neutral50"
          variant="body3"
          children={
            'O processamento foi interrompido devido a um erro estrutural na planilha'
          }
        />
      </TextContainer>
      <InfoContainer>
        <StyledText
          setColor="neutral20"
          variant="body3"
          children={'A planilha contem erro em seu formato.'}
          style={{ fontWeight: 'bolder' }}
        />
        <StyledText
          setColor="neutral50"
          variant="body3"
          children={`${worksheetComponentInfo[operation].processmentStatusPage.mainCard.infoContainer.fileError}`}
        />
      </InfoContainer>
      <GenerateXLSXFile
        fileName="Lista-de-erros.txt"
        icon="IconDownload"
        docIconSize={38}
        style={{ marginBottom: '64px', width: '300px' }}
        data={importProcess!.errors.map(({ row, message }) => {
          return `- Linha: ${row}: ${message}`;
        })}
      />
      <FooterFeedback
        navigate={navigate}
        importStatus={importStatus}
        operation={operation}
        setOpenAbortModal={() => {}}
        newImportUrl={newImportUrl}
        redirectBackTo={redirectBackTo}
      />
    </Card>
  );
};
