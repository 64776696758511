import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useMemo } from "react";
import { ModalStyled } from "../styled";

type ModalHeaderProps = {
  title: string;
  description?: string;
};

export const ModalHeader = ({ title, description, ...restProps }: ModalHeaderProps) => {
  const render = useMemo(() => {
    return (
      <Typography weight={700} variant="headline7">
        {title}
      </Typography>
    );
  }, [title, description]);

  return (
    <ModalStyled {...restProps}>
      <div className="header-modal">{render}</div>
    </ModalStyled>
  );
};
