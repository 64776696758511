import { RouterOutputs, trpc } from '@frontend/trpc';
import { dispatchToast } from '@frontend/utils/dispatchEvents';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { useStringSearch } from '../../RequestBalanceSection/data/useStringSearch';
import { useTableColumns } from '../table/setup';

const statusSchema = z.enum([
  'preview',
  'processing',
  'waiting',
  'paid',
  'cancelled',
  'reproved',
  'expired',
]);

export const searchParamsSchema = z.object({
  status: z
    .string()
    .transform((data) => z.array(statusSchema).catch([]).parse(data.split(',')))
    .optional()
    .catch([]),
  createdAtStartDate: z.coerce.date().optional().catch(undefined),
  createdAtEndDate: z.coerce.date().optional().catch(undefined),
  currentPage: z.coerce.number().nonnegative().default(0).catch(0),
  pageSize: z.coerce.number().positive().default(10).catch(10),
  stringInput: z.string().optional().catch(undefined),
  employeeId: z.array(z.string()).optional(),
  approver: z
    .object({
      status: z.enum(['APPROVED', 'REJECTED', 'PENDING']).optional(),
    })
    .optional(),
});

export type BalanceRequestFilter = z.infer<typeof searchParamsSchema>;

type Output = RouterOutputs['corporateCard']['requestBalance']['search'];

type Props = {
  type: 'approval' | 'deposit';
};
export function useSearchRequests({ type }: Props) {
  const [filters, setFilters] = useState<BalanceRequestFilter>({
    ...resolveFilterStatus(type),
    currentPage: 0,
    pageSize: 10,
  });

  const [stringInput, setStringSearch] = useStringSearch(filters.stringInput);

  useEffect(() => {
    setFilters((prevValue) => ({
      ...prevValue,
      currentPage: 0,
      stringInput,
    }));
  }, [stringInput !== filters.stringInput]);

  const {
    pageSize,
    currentPage,
    createdAtEndDate,
    createdAtStartDate,
    ...otherFilters
  } = filters;

  const filter = {
    ...(!!otherFilters && {
      ...otherFilters,
    }),
    search: filters.stringInput,
    ...(createdAtStartDate &&
      createdAtEndDate && {
        createdAt: {
          from: createdAtStartDate,
          to: createdAtEndDate,
        },
      }),
  };
  const pagination = {
    pageNumber: currentPage + 1,
    pageSize,
  };

  const { data, error, isInitialLoading, isRefetching, isStale } =
    trpc.corporateCard.requestBalance.search.useQuery(
      {
        filter,
        byApprover: type === 'approval',
        pagination: {
          currentPage: pagination.pageNumber - 1,
          pageSize: pagination.pageSize,
        },
      },
      {
        onError: (error) => {
          dispatchToast({
            type: 'error',
            content: error.message,
          });
        },
        keepPreviousData: true,
        retry: false,
        retryOnMount: false,
        refetchOnWindowFocus: true,
        staleTime: 2 * 60 * 1000,
        refetchInterval: 30 * 1000,
      },
    );

  const table = useTableColumns({
    data: data?.entries || [],
    pagination,
    totalItems: data?.pagination?.totalItems || 0,
    selectable: type === 'approval' ? true : false,
    type,
  });

  return {
    isFiltered: isFiltered(filters),
    setFilters: (
      data: Omit<BalanceRequestFilter, 'pageSize' | 'currentPage'>,
    ) => {
      setFilters({ ...filters, ...data, currentPage: 0 });
    },
    filters,
    error,
    pagination,
    setPagination: (pagination: { pageNumber: number; pageSize: number }) => {
      setFilters({
        ...filters,
        ...{
          currentPage: pagination.pageNumber - 1,
          pageSize: pagination.pageSize,
        },
      });
    },
    data: data || {
      pagination: {
        totalItems: 0,
        currentPage: 0,
        pageSize: 0,
        totalPages: 0,
      },
      entries: [] as Output['entries'],
    },
    setStringSearch,
    loading: isInitialLoading || (isStale && isRefetching),
    table,
  };
}

function resolveFilterStatus(
  type: Props['type'],
): Partial<BalanceRequestFilter> {
  if (type === 'approval') {
    return {
      approver: {
        status: 'PENDING',
      },
    };
  }

  return {
    status: ['preview'],
  };
}

function isFiltered(filter: BalanceRequestFilter): boolean {
  const {
    status,
    createdAtStartDate,
    createdAtEndDate,
    stringInput,
    approver,
  } = filter;

  return (
    !!createdAtStartDate ||
    !!createdAtEndDate ||
    !!stringInput ||
    !!(approver && approver?.status !== 'PENDING') ||
    !!(status && (status.length > 1 || !status.includes('preview')))
  );
}
