import { Modal, useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2";
import { Content, StyledIconButton } from "./base-modal.styles";

interface BaseModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const BaseModal = ({ open, onClose, children }: BaseModalProps) => {
  const isCompactModal = useMediaQuery("max-height: 750px");

  return (
    <Modal.Root
      open={open}
      size={isCompactModal ? "full" : "xs"}
      onClose={onClose}
      showClose={false}
      // TODO: Remove after the new modal be implemented in DS
      style={{ maxWidth: 480, margin: "0 auto" }}
    >
      <>
        {onClose && (
          <StyledIconButton
            variant="line"
            size="small"
            icon="IconX"
            onClick={onClose}
          />
        )}
        <Content>{children}</Content>
      </>
    </Modal.Root>
  );
};
