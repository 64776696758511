import Typography from '@frontend/components/Typography';
import { HighlightCard } from '../HighlightCard';

import Spacer from '@frontend/components/Spacer';
import * as SC from './styled';

export function CorporateCardHighlights() {
  return (
    <div>
      <Typography.Headline8 color="neutral_30">
        Você encontrará aqui
      </Typography.Headline8>

      <Spacer y="xs2" />

      <SC.GridContainer>
        <HighlightCard
          icon="IconUserDollar"
          title="Depósitos inteligentes"
          description="Agende depósitos pontuais ou recorrentes e programe a data de expiração ideal caso o saldo disponibilizado não seja utilizado."
        />

        <HighlightCard
          icon="IconCreditCard"
          title="Políticas de uso"
          description="Vincule políticas com regras de uso aos cartões corporativos e evite despesas fora da política de sua empresa."
        />

        <HighlightCard
          icon="IconFileInvoice"
          title="Acompanhamento de despesas"
          description="Monitore o extrato de utilização dos cartões da equipe com os status da prestação de contas e as entradas e saídas na carteira corporativa."
        />
      </SC.GridContainer>
    </div>
  );
}
