import { Avatar as AvatarMui } from "@mui/material";
import { useTheme } from "styled-components";

import { Description } from "./styled";

export type AvatarProps = {
  src?: string;
  alt: string;
  size?: number;
};

export function Avatar({ alt, src = undefined, size = 40 }: AvatarProps) {
  const { colors } = useTheme();

  function getAvatarAlt() {
    const splitAlt = alt?.split(" ");
    return `${splitAlt?.[0][0]}${splitAlt?.[1] ? splitAlt?.[1][0] : ""}`;
  }

  return (
    <AvatarMui
      sx={{
        width: size,
        height: size,
        background: colors.secondary[95],
        color: colors.secondary[50],
        fontWeight: 700,
      }}
      alt={alt}
      src={src}
    >
      <Description variant="body3"> {getAvatarAlt()}</Description>
    </AvatarMui>
  );
}
