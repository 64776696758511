import {
  Dot,
  Icons,
  IconTypes,
  LinkButton,
  ShapeIcon,
  ShapeIconOptions,
  Spinner,
  Tag,
  Tooltip,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { LinearProgress } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import { ConfirmationModalReclassifyTransaction } from "$frontend/components/organisms";
import { isExpenseSubmitted } from "$frontend/shared/functions";
import { getValueWithCurrency } from "$frontend/utils";
import {
  CategorySelect,
  ExpenseAmountTrailingContainer,
  ExpenseDescriptionInput,
  InfoCard,
  InfoCardStatus,
  ReceiptDropzone,
} from "$molecules";
import { ExpenseFormDto } from "$pages";
import { Expense, ExpenseStatus, ExpenseTransactionCardType } from "$serverTypes";
import { useState } from "react";
import {
  Accordion,
  AccordionFooter,
  AccordionHeader,
  BalanceChangeButtonText,
  Card,
  CardTile,
  CardTitle,
  Container,
  Content,
  ExpenseAmmount,
  Form,
  FormContent,
  InfoCardCaption,
  ListItemIcon,
  ListItemTrailing,
  PendingItemCard,
  PendingItemTitle,
  PendingList,
  WarningCard,
  WarningCardSubtitle,
} from "./styled";

type PendingItemsFormProps = {
  expenses: Expense[];
  isLoading: boolean;
  submittedCount: number;
  methods: UseFormReturn<ExpenseFormDto, Record<string, unknown>>;
  onExpenseChange: (expense: Expense) => void;
  currentExpense?: Expense;
};

// TODO: use a generic expense form instead of reimplement it here
export const PendingItemsForm = ({
  expenses,
  isLoading,
  submittedCount,
  methods,
  currentExpense,
  onExpenseChange,
}: PendingItemsFormProps) => {
  const currentTransaction = currentExpense?.transaction;
  const isCurrentExpenseSubmitted = currentExpense && isExpenseSubmitted(currentExpense);
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const { t } = useTranslation("translations", { keyPrefix: "molecules.pendingsItemsForm" });
  const [isReclassifyModalOpen, setIsReclassifyModalOpen] = useState(false);

  const { colors } = useTheme();

  function onExpenseClicked(expense: Expense) {
    setAccordionExpanded(false);
    onExpenseChange(expense);
  }

  // NOTE: setting only to required_changes because
  // this component should only list/edit OPEN and REQUIRED_CHANGES
  const alertBox =
    currentExpense?.status == ExpenseStatus.REQUIRE_CHANGES
      ? {
          title: t("reviewedBy", { name: currentExpense.accountingBy }),
          iconVariant: "error" as ShapeIconOptions,
          icon: "IconX" as IconTypes,
          color: colors.feedback.error[70],
          subtitle: t("reviewReason", { accountingComments: currentExpense?.accountingComments }),
        }
      : undefined;

  return (
    <Container>
      <Content>
        <Controller
          control={methods.control}
          name="attachments"
          rules={{ required: true }}
          render={({ field }) => (
            <ReceiptDropzone isLoading={isLoading} onChange={field.onChange} value={field.value ?? []} />
          )}
        />

        <FormContent>
          <Accordion
            variant="default"
            expanded={accordionExpanded}
            onChange={() => setAccordionExpanded(!accordionExpanded)}
            customHeader={
              <>
                <AccordionHeader>
                  <Tag variant="primary">
                    {isLoading ? (
                      <Spinner size={24} />
                    ) : (
                      <>
                        <Icons name="IconProgress" />
                        {submittedCount}/{expenses.length}
                      </>
                    )}
                  </Tag>

                  <Typography variant="headline9" variantColor={colors.neutral[30]}>
                    {currentTransaction?.description ?? ""}
                  </Typography>
                </AccordionHeader>
              </>
            }
          >
            <LinearProgress
              variant={isLoading ? undefined : "determinate"}
              value={(submittedCount * 100) / expenses.length}
              sx={{
                backgroundColor: colors.neutral[90],
                "& .MuiLinearProgress-bar": {
                  backgroundColor: colors.secondary[50],
                },
              }}
            />

            <PendingList>
              {expenses.map((expense, index) => {
                const transaction = expense.transaction;
                if (!transaction) {
                  return <></>;
                }
                const isSubmited = isExpenseSubmitted(expense);

                return (
                  <PendingItemCard>
                    <InfoCard
                      title={
                        <Tooltip title={transaction.description}>
                          <div>
                            <PendingItemTitle
                              weight={400}
                              variant="body4"
                              variantColor={colors.neutral[30]}
                            >{`${index + 1}. ${transaction.description}`}</PendingItemTitle>
                          </div>
                        </Tooltip>
                      }
                      key={expense.id}
                      trailing={
                        <ListItemTrailing>
                          {expense.id != currentExpense?.id && (
                            <LinkButton variant="default" onClick={() => onExpenseClicked(expense)}>
                              {t("view")}
                            </LinkButton>
                          )}
                          <ListItemIcon>
                            <Icons size={16} name={isSubmited ? "IconCircleCheck" : "IconCircleDashed"} />
                          </ListItemIcon>
                        </ListItemTrailing>
                      }
                    ></InfoCard>
                  </PendingItemCard>
                );
              })}
            </PendingList>
          </Accordion>
          <AccordionFooter variant="body4" color={colors.neutral[40]}>
            {t("accordionFooter")}
          </AccordionFooter>
          <Card>
            <CardTile bottomBorder={true}>
              <InfoCard
                fullWidth={true}
                leading={<ShapeIcon variant={"default"} stroke={"default"} name="IconReceipt" size={40} />}
                title={
                  <Typography variant="caption" variantColor={colors.neutral[40]}>
                    {t("cardTitle")}
                  </Typography>
                }
                subTitle={
                  <Tooltip title={currentTransaction?.description}>
                    <div>
                      <CardTitle weight={700} variant="headline8" variantColor={colors.neutral[30]}>
                        {currentTransaction?.description}
                      </CardTitle>
                    </div>
                  </Tooltip>
                }
                trailing={
                  <ExpenseAmountTrailingContainer>
                    <InfoCard
                      fullWidth={true}
                      alignItems="flex-end"
                      title={
                        <ExpenseAmmount variant="caption" variantColor={colors.neutral[40]}>
                          {t("expenseAmount")}
                        </ExpenseAmmount>
                      }
                      subTitle={
                        <Typography weight={700} variant="headline8" variantColor={colors.neutral[30]}>
                          {getValueWithCurrency({
                            value: currentExpense?.amount ?? 0,
                            currencyPrefix: currentExpense?.currency,
                          })}
                        </Typography>
                      }
                    />
                  </ExpenseAmountTrailingContainer>
                }
              />
              <InfoCardStatus status={currentExpense?.status} />
            </CardTile>
            <Form>
              {alertBox && (
                <WarningCard color={alertBox.color}>
                  <InfoCard
                    leading={
                      <ShapeIcon
                        name={alertBox.icon}
                        variant={alertBox.iconVariant}
                        stroke={alertBox.iconVariant}
                        size={32}
                      />
                    }
                    title={
                      <Typography weight={700} variant="body4" variantColor={colors.neutral[30]}>
                        {alertBox.title}
                      </Typography>
                    }
                    subTitle={
                      <WarningCardSubtitle>
                        <Typography weight={400} variant="caption" variantColor={colors.neutral[30]} tag="p">
                          {alertBox.subtitle}
                        </Typography>
                      </WarningCardSubtitle>
                    }
                  />
                </WarningCard>
              )}
              <Controller
                control={methods.control}
                rules={{ required: true }}
                name="category"
                render={({ field, fieldState }) => (
                  <CategorySelect
                    error={fieldState.invalid}
                    disabled={isCurrentExpenseSubmitted}
                    isLoading={isLoading}
                    onChange={(category) =>
                      field.onChange({
                        nanoId: category.id,
                        description: category.name,
                      })
                    }
                    value={field.value?.nanoId ?? ""}
                  />
                )}
              />

              <Controller
                control={methods.control}
                name="comments"
                render={({ field }) => (
                  <ExpenseDescriptionInput
                    disabled={isCurrentExpenseSubmitted}
                    isLoading={isLoading}
                    onChange={field.onChange}
                    value={field.value ?? ""}
                  />
                )}
              />
            </Form>
          </Card>
          <Card>
            <CardTile bottomBorder={true}>
              <InfoCard
                leading={<ShapeIcon name="IconCalendarEvent" variant="neutral" stroke="neutral" size={32} />}
                title={
                  <Typography weight={600} variant="caption" variantColor={colors.neutral[40]}>
                    {t("expenseDate")}
                  </Typography>
                }
                subTitle={
                  <Typography weight={600} variant="body4" variantColor={colors.neutral[20]}>
                    {currentExpense &&
                      t("expenseDateValue", {
                        date: new Date(currentExpense.date).toLocaleDateString("pt-BR", {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        }),
                        time: new Date(currentExpense.date).toLocaleTimeString("pt-BR", {
                          hour: "2-digit",
                          minute: "2-digit",
                        }),
                      })}
                  </Typography>
                }
              />
            </CardTile>
            <CardTile bottomBorder={true}>
              <InfoCard
                leading={<ShapeIcon name="IconCreditCard" variant="neutral" stroke="neutral" size={32} />}
                title={
                  <Typography weight={600} variant="caption" variantColor={colors.neutral[40]}>
                    {t("payment")}
                  </Typography>
                }
                subTitle={
                  <InfoCardCaption>
                    <Typography weight={600} variant="body4" variantColor={colors.neutral[20]}>
                      {t("corporative")}
                    </Typography>
                    <Dot variant="gray" />
                    <Typography weight={600} variant="body4" variantColor={colors.neutral[20]}>
                      {currentExpense &&
                        t(`cardType.${currentExpense.transaction?.cardType ?? ExpenseTransactionCardType.PLASTIC}`) +
                          (currentExpense.transaction?.cardLastDigits ?? "")}
                    </Typography>
                  </InfoCardCaption>
                }
              />
            </CardTile>
            <CardTile bottomBorder={false}>
              <InfoCard
                leading={<ShapeIcon name="IconRefreshAlert" variant="default" stroke="default" size={32} />}
                title={
                  <Typography weight={600} variant="body4" variantColor={colors.neutral[20]}>
                    {t("balanceChange.title")}
                  </Typography>
                }
                subTitle={
                  <Typography weight={600} variant="caption" variantColor={colors.neutral[40]}>
                    {t("balanceChange.subTitle")}
                  </Typography>
                }
                trailing={
                  <LinkButton variant="default" onClick={() => setIsReclassifyModalOpen(true)}>
                    <BalanceChangeButtonText>{t("balanceChange.submit")}</BalanceChangeButtonText>
                  </LinkButton>
                }
              />
            </CardTile>
          </Card>
        </FormContent>
      </Content>
      <ConfirmationModalReclassifyTransaction
        isOpen={isReclassifyModalOpen}
        transaction={currentTransaction}
        onCloseClick={() => setIsReclassifyModalOpen(false)}
      />
    </Container>
  );
};
