import { Icons } from "@flash-tecnologia/hros-web-ui-v2"; //TODO passar pelo styled ou criar componente
import { useTranslation } from "react-i18next";

import { Alignment } from "$frontend/utils/enum";
import { DangerActionModal } from "$molecules";
import { Container, LabelButtonContent } from "./styled";

type ConfirmationModalDeleteExpenseProps = {
  /** If 'true' open the modal */
  isOpen: boolean;
  /** If 'true' the button shows loading status */
  isLoading?: boolean;
  /** Number of expenses to determine singular or plural form of texts */
  expensesCount: number;
  /** Action event */
  onActionClick(): void;
  /** Close event */
  onCloseClick(): void;
};

export const ConfirmationModalDeleteExpense = ({
  isOpen,
  isLoading,
  expensesCount,
  onActionClick,
  onCloseClick,
}: ConfirmationModalDeleteExpenseProps) => {
  const { t } = useTranslation("translations", { keyPrefix: "organisms.modals.confirmationModalDeleteExpense" });
  const formOfDescription = expensesCount > 1 ? "pluralForm" : "singularForm";

  return (
    <Container>
      <DangerActionModal
        iconPosition={Alignment.left}
        isLoading={isLoading}
        headerIconLabel={t("attention")}
        headerTitle={t(`${formOfDescription}.popupTitle`, {
          expensesSelected: expensesCount,
        })}
        headerSubtitle={t(`${formOfDescription}.popupSubtitle`)}
        labelButtonAction={
          <LabelButtonContent>
            <div>{t(`${formOfDescription}.confirm`)}</div>
            <Icons name="IconTrash" fill="transparent" size={24} />
          </LabelButtonContent>
        }
        onActionClick={onActionClick}
        onCancelClick={onCloseClick}
        onCloseClick={onCloseClick}
        open={isOpen}
      />
    </Container>
  );
};
