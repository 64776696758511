export enum VariantType {
  SECONDARY = "secondary",
  PROGRESS = "progress",
  NEGATIVE = "negative",
  BLUE = "blue",
  GREEN = "green",
  GRAY = "gray",
}

export type GroupedSummary = {
  status: CombinedExpenseStatus;
  amount: number;
  count: number;
};

export enum CombinedExpenseStatus {
  DRAFT = "DRAFT",
  DRAFT_OR_REQUIRE_CHANGES = "DRAFT_OR_REQUIRE_CHANGES",
  PENDING_ACCOUNTING = "PENDING_ACCOUNTING",
  FINISHED = "FINISHED",
  REJECTED = "REJECTED",
}
