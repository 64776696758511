import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { ReactNode } from 'react'

interface IDescription {
  children: ReactNode
}

export const Description = ({ children }: IDescription) => {
  return (
    <Typography variant="body3" color="neutral.50">
      {children}
    </Typography>
  )
}
