import styled from "styled-components"

export const DroppableColumnsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;

  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) => theme.spacings.xs2};

  position: relative;
  overflow: auto hidden;

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    background: transparent;
    height: 8px;
  }
`
