import styled from 'styled-components'

export const EmployeeNameWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;

  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > div {
    display: flex;
    align-items: center;
    flex-direction: row;

    column-gap: ${({ theme }) => theme.spacings.xs5};

    white-space: nowrap;
  }
`
