import { PillButton, Table } from '@flash-tecnologia/hros-web-ui-v2';
import ModalService from '@frontend/services/ModalService';
import ApproveRequestModal from './modals/ApproveRequestModal';
import ReproveRequestModal from './modals/ReproveRequestModal';
import { TableProps } from './table/setup';

type Props = {
  table: TableProps;
};

export function BulkActions({ table }: Props) {
  const selected =
    table.selected.allSelected || table.selected.selected.length > 0;

  function getIds() {
    if (table.selected.allSelected) {
      return table.rows.map((row) => row.original._id);
    }

    return table.selected.selected.map((row) => row.original._id);
  }

  function getTotalSelected() {
    if (table.selected.allSelected) {
      return table.rows.length;
    }

    return table.selected.selected.length;
  }

  return (
    <Table.BulkActions
      open={selected}
      total={table.rows.length}
      totalSelected={getTotalSelected()}
    >
      <>
        <PillButton
          icon="IconX"
          label="Reprovar selecionados"
          size="extra-small"
          variant="error"
          type="primary"
          onClick={() => {
            ModalService.show(ReproveRequestModal, {
              orderIds: getIds(),
            });
          }}
        />
        <PillButton
          icon="IconCheck"
          label="Aprovar selecionados"
          variant="success"
          size="extra-small"
          type="primary"
          onClick={() => {
            ModalService.show(ApproveRequestModal, {
              orderIds: getIds(),
            });
          }}
        />
      </>
    </Table.BulkActions>
  );
}
