import { Navigate, Outlet } from "react-router-dom";

import { trpc } from "$client";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { Routes } from "./routes";

export const ProtectedRoutes = () => {
  const { selectedCompany } = useSelectedCompany();
  const { data: isMyCompanyEnabled, isFetching } = trpc.expense.isCompanyEnabled.useQuery(selectedCompany.id);

  if (isFetching) {
    return <div></div>;
  }
  return isMyCompanyEnabled?.enabled ? <Outlet /> : <Navigate to={Routes.SUBSCRIPTION} replace />;
};
