import { ReactNode, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PillButton,
  Icons,
  Menu,
  MenuOptionType,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import { ExportEmployeeEvaluationModal } from "@components/Modals";
import { routes } from "@routes";

import { Option } from "./styled";

type TOptionKey = "people-detail" | "export";

interface TOption extends MenuOptionType {
  key: TOptionKey;
  onClick: any;
  children: ReactNode;
}
interface PeopleOptionsProps {
  _id: string;
  canExportReport?: boolean;
}

export const PeopleOptions = ({
  _id,
  canExportReport = false,
}: PeopleOptionsProps) => {
  const navigate = useNavigate();
  const { evaluationId = "" } = useParams();

  const [exportModal, setExportModal] = useState(false);

  const options: TOption[] = useMemo(() => {
    const itens = [
      {
        key: "people-detail",
        onClick: () =>
          navigate(
            routes.PagePersonDetails("evaluation-details", _id, evaluationId)
          ),
        children: (
          <Option>
            <Icons name="IconUser" fill="transparent" />
            <Typography variant="body3" style={{ fontWeight: 600 }}>
              Ver detalhes da pessoa
            </Typography>
          </Option>
        ),
      },
    ] as any;

    if (canExportReport) {
      itens.push({
        key: "export",
        onClick: () => {
          setExportModal(true);
        },
        children: (
          <Option>
            <Icons name="IconDownload" fill="transparent" />
            <Typography variant="body3" style={{ fontWeight: 600 }}>
              Exportar relatório
            </Typography>
          </Option>
        ),
      });
    }

    return itens;
  }, [_id, canExportReport]);

  return (
    <>
      <Menu
        type={"select"}
        options={options}
        disableAutoFocusItem={true}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <PillButton
          size="small"
          variant="default"
          type="secondary"
          icon="IconDotsVertical"
        />
      </Menu>
      <ExportEmployeeEvaluationModal
        open={exportModal}
        onClose={() => setExportModal(false)}
        evaluationId={evaluationId}
        employeeId={_id}
      />
    </>
  );
};
