import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: grid;
  gap: ${({ theme }) => theme.spacings.m};
`;

export const SectionContainer = styled.section`
  display: grid;
  grid-template-columns: 100%;
  gap: ${({ theme }) => theme.spacings.xs};
`;
