import { useContext, useState } from 'react';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';
import { showToast } from '@shared/helpers/toast';
import { HttpStatus } from '@shared/hooks/service/status.type';
import { useMutate } from '@shared/hooks/service/useMutate';
import { useTranslate } from '@shared/hooks/translate/useTranslate';
import { useTracking } from '@shared/hooks/useTracking';

import { ContractErrorCodes } from '../context/types/contracts';

export interface IConstractSign {
  contracts: { id: string }[];
  message?: string;
  isNewSAAS: boolean;
}

export function useSignContract(module: string | null) {
  const tracking = useTracking();
  const { contracts, features } = useContext(SessionContext);
  const [isLoading, setIsLoading] = useState(false);
  const t = useTranslate('molecules.acceptSubscriptionCheckbox.feedbacks.error');

  const { mutation } = useMutate(
    '/v1/contracts/sign',
    data => {
      const message: Record<ContractErrorCodes, string> = {
        COMPANY_ACCESS_REVOKED: t('companyRevoked'),
        EMPLOYEE_INACTIVE: t('employeeInactive'),
        CORPORATE_CARD_CONTRACT_NOT_FOUND: t('corporateCardContractNotFound'),
        SAAS_CONTRACT_NOT_FOUND: t('saasContractNotFound'),
      };

      const defaultMessage = !data?.message?.includes(`"host"`) ? data?.message : t('default');

      return showToast({ message: message[data.code] || defaultMessage, type: 'error' });
    },
    undefined,
    {
      disableDefaultErrors: [HttpStatus.FORBIDDEN],
    },
  );

  function hasSignedContractFeedback() {
    let count = 0;

    setIsLoading(true);
    showToast({ message: t('contractsHaveAlreadyBeenSigned'), type: 'warning' });

    const interval = setInterval(() => {
      count++;

      if (count >= 2) {
        setIsLoading(true);
        window.location.assign('/home/admin');
        clearInterval(interval);
      }
    }, 1000);
  }

  async function signExpenseContracts() {
    const hasContracts = contracts?.length && features.length;

    if (hasContracts) {
      hasSignedContractFeedback();
      return;
    }

    const result: IConstractSign = await mutation.trigger({
      options: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    });

    tracking('expenses_activation_contract_signed', { module });

    return result;
  }

  return {
    signExpenseContracts,
    isLoading: mutation.isMutating || isLoading,
    error: mutation.error,
  };
}
