import { ReactNode } from "react";
import { TrpcProvider } from "$client";
import { FeatureFlagProvider } from "../../services";
import { AppContextProvider } from "../../shared/context/AppContextProvider";
import { ThemeProvider } from "@flash-tecnologia/hros-web-ui-v2";

export function ExternalRoot(props: { children: ReactNode }) {
  return (
    <ThemeProvider>
      <TrpcProvider>
        <FeatureFlagProvider>
          <AppContextProvider>{props.children}</AppContextProvider>
        </FeatureFlagProvider>
      </TrpcProvider>
    </ThemeProvider>
  );
}
