import React from "react"
import { useParams } from "react-router-dom"
import { HiringCardPage } from "../../../components"
import { DynamicRoute } from "../../../components/screen/CardPage/types"
import { CardDeliveryConfigByType } from "./CardDelivery/CardDeliveryConfig"

import { WorkshiftConfigByType } from "./Workshift/WorkshiftConfig"
import { trpc } from "@api/client"
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility"
import { BillingManagerModuleNameEnum } from "@customTypes/contractModules"
import {
  BenefitsAdvertisingByType,
  WorkshiftAdvertisingByType,
} from "./advertisings"

const benefitPlanIds = [
  "benefits_plastic_self_default",
  "legacy_migration_benefits",
  "benefits_home_office",
  "benefits_plastic",
  "benefits_awards",
  "active_migration_benefits_points_api",
  "active_migration_benefits_api",
  "active_migration_benefits_points",
  "active_migration_benefits",
]

const InitialConfigPage = () => {
  const { id: cardId = "" } = useParams()

  const { selectedCompany } = useSelectedCompany()

  const { data: benefitsContracts, isInitialLoading: isLoading } =
    trpc.benefit.getBenefitContracts.useQuery(undefined, { enabled: true })

  const hasBenefits = benefitsContracts?.some(({ planIds }) =>
    planIds.some((id) => benefitPlanIds.includes(id)),
  )

  const { data: contractModules, isLoading: isGetContractModulesLoading } =
    trpc.contractModules.getContractModules.useQuery(
      { companyId: selectedCompany.id },
      { refetchOnWindowFocus: false },
    )

  const hasTimeAndAttendanceModule =
    contractModules?.includes(
      BillingManagerModuleNameEnum.TIME_AND_ATTENDANCE,
    ) || false

  const hasFlows =
    contractModules?.includes(BillingManagerModuleNameEnum.FLOWS) || false

  const pageHistory: DynamicRoute[] = [
    { name: "Admissão", href: "/flows/hiring" },
    {
      name: "Configuração inicial",
      href: `/flows/hiring/initial-config/${cardId}`,
    },
  ]

  const contents = React.useMemo(() => {
    const contents: any[] = []

    if (hasBenefits) {
      contents.push(CardDeliveryConfigByType)
    } else {
      contents.push(BenefitsAdvertisingByType)
    }

    if (hasTimeAndAttendanceModule) {
      contents.push(WorkshiftConfigByType)
    } else if (hasFlows) {
      contents.push(WorkshiftAdvertisingByType)
    }

    return contents
  }, [hasTimeAndAttendanceModule, hasFlows])

  return (
    <HiringCardPage
      cardId={cardId || ""}
      pageHistory={pageHistory}
      contents={contents}
      loading={isGetContractModulesLoading}
    />
  )
}

export default React.memo(InitialConfigPage)
