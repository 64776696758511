import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

const Text = styled(Typography)`
  color: var(--color-neutral-40);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400 !important;
  max-height: 40px;
  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all 0.2s cubic-bezier(0, 1, 0, 1);
`;

const MainContainer = styled.div<{ acknowledged?: boolean }>`
  @keyframes colorTransition {
    from {
      background-color: var(--color-secondary-99);
      box-shadow: 0px 8px 32px 0px rgba(254, 43, 143, 0.16);
      border-color: var(--color-secondary-80);
    }
    to {
      background-color: var(--color-neutral-100);
      box-shadow: none;
      border-color: var(--color-neutral-light2);
    }
  }

  display: flex;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid var(--color-neutral-90);
  border-radius: 8px;
  padding: 16px;
  background-color: var(--color-neutral-100);
  animation-name: ${({ acknowledged }) =>
    !acknowledged ? 'colorTransition' : undefined};
  animation-duration: ${({ acknowledged }) => (!acknowledged ? '4s' : 0)};

  :hover ${Text} {
    transition: all 0.4s ease-out;
    max-height: 200px;
    -webkit-line-clamp: 10;
  }
`;

const IconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding-right: 8px;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: inline-flex;
  width: 100%;
  margin-left: auto;
  align-items: center;
  cursor: pointer;
  padding-top: 8px;
`;

const DateContainer = styled.div`
  max-height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
`;

const Title = styled(Typography)`
  color: var(--color-neutral-dark3);
  font-weight: 700 !important;
  word-break: break-word;
`;

const Date = styled(Typography)`
  color: var(--color-neutral-50);
  font-weight: 600 !important;
`;

const Button = styled(Typography)`
  color: var(--color-secondary-dark5);
  font-weight: 700 !important;
  margin-right: 2px;

  ::first-letter {
    text-transform: capitalize;
  }
`;

const Space = styled.div`
  display: inline;
  height: 5px;
  width: 5px;
  border-radius: 100%;
  background-color: var(--color-neutral-dark5);
  margin: 0 5px;
`;

export {
  Button,
  ButtonContainer,
  ContentContainer,
  Date,
  DateContainer,
  IconContainer,
  MainContainer,
  Space,
  Text,
  Title,
};
