import { CREATE_ACCOUNT_IMAGE_URL } from '@/assets/imageConstants';
import { useFlag } from '@flash-tecnologia/feature-flags';
import {
  Icons,
  LinkButton,
  OTPField,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { setEventTracking } from '@utils/analytics/segment';
import { useCallback, useState } from 'react';
import { useAuthNavigation } from 'src/routes';
import { Countdown, Footer } from '../../../../components/Common';
import { Header } from '../../../../components/Common/Header';
import { useLoginContext } from '../../loginContext';
import {
  ButtonsContainer,
  ContentContainer,
  Divider,
  FormContainer,
  GreetingsText,
  LeftContainer,
  OtpTitle,
  PageContainer,
  RightContainer,
  SMSButtonFlex,
  SMSCodeContainer,
  StyledButton,
  SubTitle,
  TextsContainer,
  Title,
  Wrapper,
} from '../styles';
import { useMFAForm, useResendMFACode } from './hooks';

export const MFAStep = () => {
  const navigate = useAuthNavigation();
  const [showCountdown, setShowCountdown] = useState<boolean>(true);
  const { user, setStep, setUser } = useLoginContext();
  const { form, isLoading } = useMFAForm();
  const { resendCode, isLoading: isLoadingResendCode } = useResendMFACode();
  const resendWhatsappFlag = useFlag({
    flagName: 'FLASHOS_RESEND_CODE_WHATSAPP',
  });

  const handleResendCode = useCallback(
    async (mfaMethod: 'sms' | 'whatsapp') => {
      await resendCode(mfaMethod);
      setShowCountdown(true);
    },
    [resendCode],
  );

  const handleFinishCountDown = useCallback(() => {
    setShowCountdown(false);
  }, []);

  const handleHowToAccessMyAccount = () => {
    setEventTracking('signin_login_mfa_howtoaccessmyaccount_button_clicked');
    navigate('MIGRATION');
    setUser(null);
  };

  const onGoBack = () => {
    setStep('PASSWORD');
    setUser(null);
  };

  return (
    <PageContainer>
      <Header />
      <ContentContainer>
        <Wrapper>
          <LeftContainer>
            <TextsContainer>
              <GreetingsText variant="body3">Login</GreetingsText>
              <Title variant="headline5">
                Confirme o código de acesso enviado
              </Title>
              <SubTitle variant="body3">
                Antes de continuar, confirme sua identidade para acessar a sua
                conta.
              </SubTitle>
            </TextsContainer>
            <form onSubmit={form.handleSubmit}>
              <FormContainer>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                  }}
                >
                  <OtpTitle variant="body3">
                    Insira o código de confirmação recebido
                  </OtpTitle>
                  <OTPField
                    style={{ width: '45px' }}
                    numInputs={6}
                    value={form.values.otp}
                    onChange={(e: any) =>
                      form.handleChange({ target: { id: 'otp', value: e } })
                    }
                    error={form.touched.otp && Boolean(form.errors.otp)}
                  />
                </div>
                <SMSCodeContainer>
                  <SMSButtonFlex>
                    <Typography variant="body3">
                      Enviamos seu código para •••••••
                      {user?.challenge?.codeDeliveryDestination.replace(
                        /\D/g,
                        '',
                      )}
                    </Typography>
                  </SMSButtonFlex>
                  <SMSButtonFlex>
                    {showCountdown ? (
                      <Typography variant="body3">
                        Aguarde o recebimento • {''}
                        <Countdown
                          time={60}
                          onFinished={handleFinishCountDown}
                        />
                      </Typography>
                    ) : (
                      <Typography variant="body3">
                        Não recebeu seu código?
                      </Typography>
                    )}
                    <LinkButton
                      variant="default"
                      disabled={showCountdown || isLoadingResendCode}
                      onClick={() => handleResendCode('sms')}
                    >
                      Reenviar <Icons name="IconArrowBack" />
                    </LinkButton>
                    {!showCountdown && resendWhatsappFlag && (
                      <LinkButton
                        variant="default"
                        disabled={showCountdown || isLoadingResendCode}
                        onClick={() => handleResendCode('whatsapp')}
                      >
                        {' '}
                        Reenviar por Whatsapp
                        <Icons name="IconBrandWhatsapp" />
                      </LinkButton>
                    )}
                  </SMSButtonFlex>
                </SMSCodeContainer>
              </FormContainer>
              <ButtonsContainer>
                <LinkButton variant="default" onClick={onGoBack}>
                  Voltar
                </LinkButton>
                <StyledButton
                  variant="primary"
                  size="large"
                  type="submit"
                  loading={isLoading}
                  disabled={isLoading}
                >
                  Continuar <Icons name="IconArrowRight" />
                </StyledButton>
              </ButtonsContainer>
            </form>
            <Divider />
            <LinkButton
              variant="default"
              onClick={handleHowToAccessMyAccount}
              style={{ alignSelf: 'auto', margin: '0 auto' }}
            >
              Como acessar minha conta?
            </LinkButton>
          </LeftContainer>
          <RightContainer imageUrl={CREATE_ACCOUNT_IMAGE_URL} />
        </Wrapper>
      </ContentContainer>
      <Footer />
    </PageContainer>
  );
};
